import React from 'react';

import Button from 'components/Button';
import Img from 'components/Img';

interface Props {
	alreadyMemberButtonText: string;
	alreadyMemberHeading: string;
	alreadyMemberText: string;
	onContinueShopping: () => void;
}

export default function AlreadyMemberView({
	alreadyMemberButtonText,
	alreadyMemberHeading,
	alreadyMemberText,
	onContinueShopping,
}: Props) {
	return (
		<>
			<Img
				src="/assets/images/graphic-congrats-julaclub.png"
				width={80}
				height={80}
				className="mx-auto mt-8 block size-20"
			/>
			<p className="mt-8 font-bold">{alreadyMemberHeading}</p>
			<p className="mt-2">{alreadyMemberText}</p>

			<Button
				variant="primary"
				displayWidth="full"
				className="mt-8"
				onClick={onContinueShopping}
			>
				{alreadyMemberButtonText}
			</Button>
		</>
	);
}
AlreadyMemberView.displayName = 'JulaClubSignupForm_AlreadyMemberView';
