import React from 'react';

import { usePriceContext } from 'contexts';
import type { FormattedPriceResponse, Summary } from 'models/api';
import type { Price } from 'models/price';
import type { HTMLAttributes } from 'types';
import { cn } from 'utils/classNames';
import { formatPrice } from 'utils/format';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';
import { makeSpacesNoBreak } from 'utils/string';

interface BaseProps extends HTMLAttributes<HTMLParagraphElement> {
	className?: string;
}

interface PriceObjectProps extends BaseProps {
	price: Price | FormattedPriceResponse | Summary | undefined;
	symbol?: never;
}

interface PlainPriceProps extends BaseProps {
	price: string | number | undefined;
	symbol: string | undefined;
}

type Props = PriceObjectProps | PlainPriceProps;

/**
 * Formats a price and its symbol as plain text. Includes a more screen reader
 * friendly version.
 */
export default function PriceText({
	className,
	price,
	symbol,
	...attrs
}: Props) {
	const { t } = useI18n();
	const { usePriceExcVat } = usePriceContext();

	if (is.nonZeroFalsy(price)) {
		return null;
	}

	const isPlainPrice = is.string(price) || is.number(price);
	const displayPriceObj = isPlainPrice
		? undefined
		: 'priceType' in price
			? usePriceExcVat
				? price.priceExcVat
				: price.priceIncVat
			: price;
	const priceNum = isPlainPrice ? price : displayPriceObj?.displayValue;
	const priceSymbol = isPlainPrice ? symbol : displayPriceObj?.displaySymbol;
	const formattedPrice = formatPrice(priceNum, 'regular');

	// Price objects aren't caught in the first early return.
	if (!formattedPrice) {
		return null;
	}

	// Position the screen reader text to make the visual screen reader cursor
	// a bit better.
	return (
		<span {...attrs} className={cn('relative', className)}>
			<span className="sr-only left-0 top-[10%]">
				{formattedPrice} {t('screen_reader_currency_symbol')}
			</span>
			<span aria-hidden>
				{makeSpacesNoBreak(`${formattedPrice}${priceSymbol || ''}`)}
			</span>
		</span>
	);
}
PriceText.displayName = 'PriceText';
