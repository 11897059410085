import React, { useEffect, useState } from 'react';
import { FormSpy } from 'react-final-form';

interface OnChangeStateProps<FormValuesT> {
	children: (value: FormValuesT, previous: FormValuesT) => void;
	values: FormValuesT;
}

function OnChangeState<FormValuesT>({
	children,
	values,
}: OnChangeStateProps<FormValuesT>) {
	const [previous, setPrevious] = useState(values);
	useEffect(() => {
		if (values !== previous) {
			setPrevious(values);
			children(values, previous);
		}
	}, [values]);

	return null;
}
OnChangeState.displayName = 'FormOnChange_OnChangeState';

interface Props<FormValuesT> {
	children: (value: FormValuesT, previous: FormValuesT) => void;
}

export default function FormOnChange<FormValuesT extends Record<string, any>>({
	children,
}: Props<Partial<FormValuesT>>) {
	return React.createElement(FormSpy<FormValuesT>, {
		subscription: { values: true },
		render: (renderProps) => (
			<OnChangeState values={renderProps.values}>{children}</OnChangeState>
		),
	});
}
FormOnChange.displayName = 'FormOnChange';
