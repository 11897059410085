// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconShare(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M18 16.08q1.22 0 2.06.87t.85 2.03q0 1.22-.87 2.09t-2.04.87-2.04-.87-.87-2.09q0-.46.05-.65L8.06 14.2Q7.17 15 6 15q-1.22 0-2.1-.9T3 12t.9-2.1T6 9q1.17 0 2.06.8l7.03-4.08q-.09-.47-.09-.7 0-1.22.9-2.11T18 2t2.11.9.9 2.1-.9 2.12-2.1.89q-1.13 0-2.07-.85L8.9 11.3q.1.47.1.7t-.1.7l7.12 4.13q.85-.75 1.97-.75z" />
		</svg>
	);
}
IconShare.displayName = 'IconShare';
