import React, { useState } from 'react';
import { useMachine, useSelector } from '@xstate/react';

import Button from 'components/Button';
import Icon from 'components/Icon';
import InfoBox from 'components/InfoBox';
import { AccountLayoutContainer } from 'components/Layout';
import ListItem from 'components/ListItem';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { useGlobalMachinesContext } from 'contexts';
import { useEffectOnce, useSitecoreContext } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import { selectContactRole } from 'state-machines/authentication';
import {
	referenceManagementMachine,
	selectAddReferenceButtonState,
	selectReferenceList,
	selectStateIsErrorGettingReferenceList,
	selectStateIsLoadingReferenceList,
} from 'state-machines/referenceManagement';
import { useI18n } from 'utils/i18n';

import AddReferenceForm from './AddReferenceForm';

type Props = JulaComponentProps & {};

export default function AccountJulaProReferences({ rendering }: Props) {
	const { t } = useI18n();
	const { userService } = useGlobalMachinesContext();

	const [open, setOpen] = useState(false);
	const [_current, send, referenceManagementService] = useMachine(
		referenceManagementMachine,
		{
			devTools: true,
		},
	);
	// Access route from sitecore context.
	const { sitecoreContext } = useSitecoreContext();
	const { itemPath } = sitecoreContext;

	const contactRole = useSelector(userService, selectContactRole);
	const showReferences = contactRole === 'Admin';

	const isLoadingReferenceList = useSelector(
		referenceManagementService,
		selectStateIsLoadingReferenceList,
	);
	const errorGettingReferenceList = useSelector(
		referenceManagementService,
		selectStateIsErrorGettingReferenceList,
	);
	const referenceList = useSelector(
		referenceManagementService,
		selectReferenceList,
	);
	const addReferenceButtonState = useSelector(
		referenceManagementService,
		selectAddReferenceButtonState,
	);

	useEffectOnce(() => {
		send('GET_REFERENCE_LIST');
	});

	return (
		<AccountLayoutContainer
			rendering={rendering}
			heading={t('julapro_references_heading')}
		>
			{isLoadingReferenceList && !referenceList && (
				<Skeleton>
					<SkeletonItem height="6rem" className="my-4" />
					<SkeletonItem height="6rem" className="my-4" />
					<SkeletonItem height="6rem" className="my-4" />
				</Skeleton>
			)}
			{errorGettingReferenceList && (
				<InfoBox
					icon="error"
					variant="error"
					message={t('account_generic_fetch_error_text')}
				/>
			)}
			{referenceList && (
				<ul className="mb-6 flex flex-col gap-2">
					{referenceList.map((reference) => (
						<ListItem
							as="li"
							href={`${itemPath}${encodeURIComponent(reference.code)}/`}
							title={reference.code}
							key={reference.code}
							endContent={<Icon name="arrow" className="self-center" />}
						>
							<Text as="pSmall">
								{showReferences &&
									t('julapro_references_number_of_users', {
										numberOfUsers: reference.numberOfUsers,
									})}
							</Text>
						</ListItem>
					))}
				</ul>
			)}
			{referenceList?.length === 0 && (
				<InfoBox
					icon="info"
					variant="information"
					message={t('julapro_references_no_references_text')}
					className="mb-6"
				/>
			)}
			<AddReferenceForm
				open={open}
				onClose={() => {
					setOpen(false);
				}}
				onSubmit={(value) => {
					send('ADD_REFERENCE', { value });
				}}
				submitButtonState={addReferenceButtonState}
				referenceManagementActor={referenceManagementService}
				showReferences={showReferences}
			/>
			{!errorGettingReferenceList && !isLoadingReferenceList && (
				<Button
					variant="primary"
					onClick={() => {
						setOpen(true);
					}}
					aria-haspopup="dialog"
					className="mt-6"
				>
					{t('julapro_references_open_add_reference_button_text')}
				</Button>
			)}
		</AccountLayoutContainer>
	);
}
AccountJulaProReferences.displayName = 'AccountJulaProReferences';
