import React, { useState } from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import Button from 'components/Button';
import GdprAccordion from 'components/GdprAccordion';
import GenericForm from 'components/GenericForm';
import { ErrorMessageInfoBox } from 'components/InfoBox';
import RichText from 'components/RichText';
import Text from 'components/Text';
import type {
	JulaComponentProps,
	PartialPopoverProps,
} from 'lib/component-props';
import type { BusinessLogicError } from 'models/api';
import type { FormFields } from 'models/sitecore';
import { API_URL, fetchResult } from 'utils/fetch';
import { getFieldErrors } from 'utils/formHelpers';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

export interface QuestionsAndAnswersFormPartialProps {
	productId: string;
}

type Props = JulaComponentProps & {
	fields?: {
		content: Field<string>;
		description: Field<string>;
		form: FormFields;
		heading: Field<string>;
		name: Field<string>;
		submitText: Field<string>;
	};
	partialProps?: PartialPopoverProps<QuestionsAndAnswersFormPartialProps>;
};

export default function QuestionsAndAnswersForm({
	fields,
	partialProps,
}: Props) {
	const { t } = useI18n();

	const [submitSuccess, setSubmitSuccess] = useState(false);
	const [businessLogicErrors, setBusinessLogicErrors] = useState<
		BusinessLogicError[] | undefined
	>(undefined);
	if (!fields) {
		return null;
	}

	const onSubmit = async (formData) => {
		setBusinessLogicErrors(undefined);

		const response = await fetchResult(
			`${API_URL}ProductQuestions/${partialProps?.productId}`,
			{
				method: 'POST',
				body: JSON.stringify(formData),
			},
		);
		if (response.isSuccess()) {
			setSubmitSuccess(true);
			return undefined;
		}

		if (response.isFailure('ValidationError')) {
			setBusinessLogicErrors(response.error.generalErrors);
		}
		return getFieldErrors(response.error);
	};

	return (
		<>
			{!submitSuccess && (
				<>
					{is.arrayWithLength(businessLogicErrors) && (
						<ErrorMessageInfoBox
							errors={businessLogicErrors}
							className="sticky top-0 z-99 mb-4 text-sm"
						/>
					)}
					<Text className="mb-2" as="h3" field={fields.heading} />
					<Text className="mb-2" as="p" field={fields.description} />
					<RichText field={fields.content} className="mt-4" />
					<GdprAccordion />
					<GenericForm
						inputSpace="mt-4"
						fields={fields?.form}
						className="mt-4"
						fileUploadUrl={`${API_URL}CustomerService/attachments`}
						onSubmitCallback={onSubmit}
						resetForm
						submitButtonClassName="mt-6"
						submitButtonText={fields.submitText.value}
					/>
					{partialProps?.onPopoverClose && (
						<Button
							variant="text"
							displayWidth="full"
							className="mt-4"
							onClick={partialProps.onPopoverClose}
						>
							{t('question_answer_form_abort_button')}
						</Button>
					)}
				</>
			)}
			{submitSuccess && (
				<>
					<Text
						className="mb-4"
						as="h3"
						text={t('question_answer_form_success_heading')}
					/>
					<Text as="p" text={t('question_answer_form_success_text')} />
					{partialProps?.onPopoverClose && (
						<Button
							onClick={partialProps.onPopoverClose}
							displayWidth="full"
							variant="primary"
							className="mt-8"
						>
							{t('question_answer_form_success_button')}
						</Button>
					)}
				</>
			)}
		</>
	);
}
QuestionsAndAnswersForm.displayName = 'QuestionsAndAnswersForm';
