import React, { useRef } from 'react';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';

import InputInfo from './InputInfo';

interface Props {
	className?: string;
	errorMessage?: string | string[];
	helpText?: string;
	id: string;
	label: string;
	onChange: (file: File) => void;
	uploadState: ActionButtonState;
}

export default function File({
	className,
	errorMessage,
	helpText,
	id,
	label,
	onChange,
	uploadState,
}: Props) {
	const ref = useRef<HTMLInputElement>(null);
	const errorMessageId = `${id}-error`;
	const helpTextId = `${id}-help`;

	return (
		<div className={className}>
			<input
				id={id}
				type="file"
				className="hidden"
				ref={ref}
				onChange={(event) => {
					const file = event.target?.files?.[0];
					if (!file) {
						return;
					}
					onChange(file);
				}}
			/>
			<ActionButton
				// Since the input is hidden we associate the help and error messages
				// with the button instead
				aria-describedby={[errorMessageId, helpTextId].join(' ')}
				displayWidth="full"
				onClick={() => {
					ref.current?.click();
				}}
				customState={uploadState}
				variant="primary"
			>
				{label}
			</ActionButton>

			<InputInfo
				className="ml-0"
				errorMessage={errorMessage}
				invalid={Boolean(errorMessage)}
				errorMessageId={errorMessageId}
				helpText={helpText}
				helpTextId={helpTextId}
			/>
		</div>
	);
}
File.displayName = 'FormUi_File';
