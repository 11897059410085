import React from 'react';
import type { ReactNode } from 'react';

import Button from 'components/Button';
import Icon, { type IconName } from 'components/Icon';
import InfoBox from 'components/InfoBox';
import type { LoginMethod } from 'models/connect/oauth';
import { getTestDataAttrFrom } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

export interface LoginOption {
	icon?: IconName;
	method: LoginMethod;
	text: string;
}

interface Props {
	becomeMemberButtonText?: string;
	errorMessage?: string | null;
	importantInformation?: ReactNode;
	introHeading?: string;
	introText?: string;
	loginOptions: LoginOption[];
	memberType: 'club' | 'pro';
	onBecomeMemberClick?: () => void;
	onSelectLoginMethod: (loginMethod: LoginMethod) => void;
}

export default function LoginOptions({
	becomeMemberButtonText,
	errorMessage,
	importantInformation,
	introHeading,
	introText,
	loginOptions,
	memberType,
	onBecomeMemberClick,
	onSelectLoginMethod,
}: Props) {
	const { t } = useI18n();

	return (
		<>
			{introHeading && <p className="mb-2 font-bold">{introHeading}</p>}
			{introText && <p className="mb-6">{introText}</p>}

			{importantInformation}

			{errorMessage && (
				<InfoBox
					icon="error"
					variant="error"
					className="mb-4"
					message={t(errorMessage)}
				/>
			)}

			<div className="space-y-2">
				{loginOptions.map((opt) => (
					<Button
						key={opt.method}
						variant="cta"
						displayWidth="full"
						size="large"
						onClick={() => {
							onSelectLoginMethod(opt.method);
						}}
						data-cy={getTestDataAttrFrom(`login-${opt.method}-${memberType}`)}
					>
						{opt.icon && <Icon name={opt.icon} className="mr-2" />}
						{opt.text}
					</Button>
				))}
			</div>

			{becomeMemberButtonText && onBecomeMemberClick && (
				<Button
					variant="secondary"
					displayWidth="full"
					size="large"
					className="mt-8"
					onClick={onBecomeMemberClick}
					data-cy={getTestDataAttrFrom(`become-member-${memberType}`)}
				>
					{becomeMemberButtonText}
				</Button>
			)}
		</>
	);
}
LoginOptions.displayName = 'LoginOptions';
