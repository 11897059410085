/**
 * StockBalanceDetailed
 */

import React from 'react';

import Button from 'components/Button';
import Icon, { IconName } from 'components/Icon';
import InfoBox from 'components/InfoBox';
import Text from 'components/Text';
import { cn } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

import StockStatus from './StockStatus';

interface StoreInfo {
	articleInfo: {
		artno?: string;
		color?: string;
		department?: string;
		shelf?: string;
	};
	openingHours: string;
}
interface StockStatusInfo {
	inStock: boolean;
	statusInfo?: string;
	statusText: string;
}
interface Props {
	/** Text for the button (e.g. store changer, watchable signup) */
	buttonText?: string;
	/** Stock location header */
	header?: string;
	/** General info about stock */
	infoText?: string;
	/** Stock location icon */
	locationIcon: IconName;
	/** Click handler for the button (e.g. store changer, watchable signup) */
	onClick?: () => void;
	/** Stock status type for debugging */
	statusType?: string;
	/** Stock status info with inStock, stock status text and info about status */
	stockStatus: StockStatusInfo;
	/** Store info if status is a for a store with department, shelf, artno and opening hours */
	storeInfo?: StoreInfo;
}

/** Component to display stock balance in product details sidebar */
export default function StockBalanceDetailed({
	buttonText,
	header,
	infoText,
	locationIcon,
	onClick,
	statusType,
	stockStatus,
	storeInfo,
}: Props) {
	const { t } = useI18n();

	return (
		<div className="p-4" data-status-type={statusType}>
			<p className="mb-4 flex items-center font-bold">
				<Icon name={locationIcon} className="mr-2" />
				{header}
			</p>
			<div className="pl-8">
				<StockStatus
					inStock={stockStatus.inStock}
					text={stockStatus.statusText}
				/>
				{stockStatus.statusInfo && (
					<Text as="pXSmall" text={stockStatus.statusInfo} className="mt-2" />
				)}
				{storeInfo && (
					<>
						<div
							className={cn(
								'my-4',
								storeInfo.articleInfo.color && 'border-l-4 pl-2',
							)}
							style={{
								borderLeftColor: storeInfo.articleInfo.color,
							}}
						>
							<p>
								{t('product_details_store_accordion_department_text')}{' '}
								{storeInfo.articleInfo.department}
							</p>
							<p>
								{t('product_details_store_accordion_shelf_text')}{' '}
								{storeInfo.articleInfo.shelf}
							</p>
							<p>
								{t('product_details_store_accordion_art_no_text')}{' '}
								{storeInfo.articleInfo.artno}
							</p>
						</div>
						<p>{storeInfo.openingHours}</p>
					</>
				)}
				{onClick && (
					<Button
						className="mt-4"
						variant="text"
						size="medium"
						onClick={onClick}
						aria-haspopup="dialog"
					>
						{buttonText}
					</Button>
				)}
			</div>
			{infoText && <InfoBox icon="info" message={infoText} className="mt-4" />}
		</div>
	);
}
StockBalanceDetailed.displayName = 'StockBalanceDetailed';
