/**
 * ProductPopover
 */

import React, { useEffect } from 'react';

import Popover from 'components/Popover';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import { StoreIdName } from 'contexts';
import { useTrackEvent } from 'hooks/useTrackEvent';
import type { ItemStockResponse, ProductResponse } from 'models/api';
import type { RelatedProductCard } from 'models/productCard';

import CampaignViewerContent from './CampaignViewerContent';
import ProductArchiveSearchContent from './ProductArchiveSearchContent';

interface BaseProps {
	isLoadingProduct: boolean;
	isOpen: boolean;
	onClose: () => void;
	product: ProductResponse | undefined;
	title: string;
}

interface CampaignViewerProps extends BaseProps {
	isLoadingProductStock: boolean;
	onSparePartsLoadMore?: never;
	productStock: ItemStockResponse | undefined;
	selectedStore: StoreIdName | undefined;
	spareParts?: never;
	sparePartsHasNextPage?: never;
	sparePartsIsLoading?: never;
	sparePartsIsLoadingMore?: never;
	variant: 'CampaignViewer';
}

interface ProductArchiveSearchProps extends BaseProps {
	isLoadingProductStock?: never;
	onSparePartsLoadMore: () => void;
	productStock?: never;
	selectedStore?: never;
	spareParts: RelatedProductCard[] | undefined;
	sparePartsHasNextPage: boolean;
	sparePartsIsLoading: boolean;
	sparePartsIsLoadingMore: boolean;
	variant: 'ProductArchiveSearch';
}

type Props = CampaignViewerProps | ProductArchiveSearchProps;

/** Popover view for product info */
export default function ProductPopover({
	isLoadingProduct,
	isLoadingProductStock,
	isOpen,
	onClose,
	onSparePartsLoadMore,
	product,
	productStock,
	selectedStore,
	spareParts,
	sparePartsHasNextPage,
	sparePartsIsLoading,
	sparePartsIsLoadingMore,
	title,
	variant,
}: Props) {
	const { sendProductClickEvent } = useTrackEvent();
	useEffect(() => {
		if (product && isOpen) {
			sendProductClickEvent(product.id);
		}
	}, [isOpen, product, sendProductClickEvent]);
	return (
		<Popover
			isOpen={isOpen}
			onClose={onClose}
			title={title}
			hiddenTitle={variant === 'CampaignViewer'}
		>
			{isLoadingProduct && (
				<Skeleton>
					<SkeletonItem height="3rem" width="100%" />
					<SkeletonItem height="1.5rem" className="mt-2" width="30%" />
					<SkeletonItem height="17rem" className="mt-6" width="17rem" />
					<SkeletonItem height="4rem" className="mt-6" width="100%" />
					<div className="mt-6 flex justify-between border-t border-greyDark py-4">
						<SkeletonItem height="2.5rem" width="40%" />
						<SkeletonItem
							height="2.5rem"
							width="2.5rem"
							className="rounded-full"
						/>
					</div>
					<div className="flex justify-between border-y border-greyDark py-4">
						<SkeletonItem height="2.5rem" width="40%" />
						<SkeletonItem
							height="2.5rem"
							width="2.5rem"
							className="rounded-full"
						/>
					</div>
				</Skeleton>
			)}
			{!isLoadingProduct && (
				<>
					{variant === 'ProductArchiveSearch' && (
						<ProductArchiveSearchContent
							product={product}
							spareParts={spareParts}
							sparePartsHasNextPage={sparePartsHasNextPage}
							sparePartsIsLoading={sparePartsIsLoading}
							sparePartsIsLoadingMore={sparePartsIsLoadingMore}
							onSparePartsLoadMore={onSparePartsLoadMore}
						/>
					)}
					{variant === 'CampaignViewer' && (
						<CampaignViewerContent
							isLoadingProductStock={isLoadingProductStock}
							product={product}
							productStock={productStock}
							selectedStore={selectedStore}
						/>
					)}
				</>
			)}
		</Popover>
	);
}
ProductPopover.displayName = 'ProductPopover';
