// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconShareLocation(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M11.09 21.09v-.92a8 8 0 0 1-4.9-2.36 8 8 0 0 1-2.37-4.9h-.9A.92.92 0 0 1 2 12c0-.5.41-.91.91-.91h.92a8 8 0 0 1 2.36-4.9 8 8 0 0 1 4.9-2.37v-.9c0-.5.41-.92.91-.92s.91.41.91.91v.92a8 8 0 0 1 4.9 2.36 8 8 0 0 1 2.37 4.9h.91a.91.91 0 0 1 .01 1.83h-.92a8.27 8.27 0 0 1-7.26 7.26v.91a.92.92 0 0 1-1.83 0m.91-2.7c1.7.03 3.34-.65 4.52-1.87A6.16 6.16 0 0 0 18.4 12a6.16 6.16 0 0 0-1.87-4.52A6.16 6.16 0 0 0 12 5.6a6.16 6.16 0 0 0-4.52 1.87A6.16 6.16 0 0 0 5.6 12a6.16 6.16 0 0 0 1.87 4.52A6.16 6.16 0 0 0 12 18.4m0-2.74c-2 0-3.65-1.65-3.65-3.65S10 8.35 12 8.35 15.65 10 15.65 12 14 15.65 12 15.65" />
		</svg>
	);
}
IconShareLocation.displayName = 'IconShareLocation';
