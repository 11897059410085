import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		description?: Field<string>;
		heading?: Field<string>;
	};
};

export default function ContentIntroduction({ fields, params }: Props) {
	if (!fields) {
		return null;
	}
	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
		>
			<div className="max-w-col-6">
				<Text as="h1" field={fields.heading} />
				<Text as="pLarge" field={fields.description} />
			</div>
		</LayoutContainer>
	);
}
ContentIntroduction.displayName = 'ContentIntroduction';
