import type {
	MinimizedAddToWishlistResponse,
	MinimizedWishlistResponse,
	Wishlist,
} from 'models/api';
import { API_URL, fetchData } from 'utils/fetch';
import { createUrl } from 'utils/url';

export function fetchWishlistMini() {
	return fetchData<MinimizedWishlistResponse>(`${API_URL}Wishlist/mini`);
}

export function addOneToWishlist(variantId: string, returnAccessories: string) {
	return fetchData<MinimizedAddToWishlistResponse>(
		createUrl(`${API_URL}Wishlist/mini/variants/${variantId}`, {
			qty: 1,
			onlySellable: 'false',
			returnAddedProduct: 'true',
			returnAccessories,
		}),
		{
			method: 'POST',
		},
	);
}

export function addMultipleToWishlist(
	variants: { quantity: number; variantId: string }[],
) {
	return fetchData<MinimizedAddToWishlistResponse>(
		`${API_URL}Wishlist/mini/variants`,
		{
			method: 'POST',
			body: JSON.stringify(variants),
		},
	);
}

export function removeVariantFromWishlist(
	variantId: string,
	storeId: string | undefined,
) {
	return fetchData<Wishlist>(
		createUrl(`${API_URL}Wishlist/variants/${variantId}`, { storeId }),
		{
			method: 'DELETE',
		},
	);
}
export function removeAllFromWishlist() {
	return fetchData(`${API_URL}Wishlist`, {
		method: 'DELETE',
	});
}
export function fetchWishlist(storeId: string | undefined) {
	return fetchData<Wishlist>(createUrl(`${API_URL}Wishlist`, { storeId }), {
		method: 'POST',
	});
}

export function fetchWishlistById(
	wishlistId: string,
	storeId: string | undefined,
) {
	return fetchData<Wishlist>(
		createUrl(`${API_URL}Wishlist/${wishlistId}`, { storeId }),
	);
}
export function updateVariantQtyInWishlist({
	qty,
	setDistinctValue = false,
	storeId,
	variantId,
}: {
	qty: number;
	setDistinctValue?: boolean;
	storeId: string | undefined;
	variantId: string;
}) {
	return fetchData<Wishlist>(
		createUrl(`${API_URL}Wishlist/variants/${variantId}`, {
			qty,
			storeId,
			setDistinctValue: setDistinctValue ? 'true' : 'false',
		}),
		{
			method: 'POST',
		},
	);
}
