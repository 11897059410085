import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import type { SearchResults } from 'models/api';
import { filterTruthy } from 'utils/collection';
import { API_URL, fetchData } from 'utils/fetch';
import { pushToGTM } from 'utils/GoogleTagManager';
import { empty } from 'utils/helpers';
import { getLastPathSegment } from 'utils/url';

import { useMaxWidth } from './useMediaQuery';

export function useSearchProductsPageSize() {
	const isSmallOrSmaller = useMaxWidth('md');
	return isSmallOrSmaller ? 4 : 5;
}
export function useSearchTermsPageSize() {
	const isSmallOrSmaller = useMaxWidth('md');
	return isSmallOrSmaller ? 4 : 7;
}

/** Hook for the primary product search. */
export function useSearch(query: string) {
	const productsPageSize = useSearchProductsPageSize();
	const termsPageSize = useSearchTermsPageSize();
	const { data, error, isLoading } = useSWRImmutable(
		query ? `${API_URL}Search/advanced/${query}` : null,
		async (url) => {
			const result = await fetchData<SearchResults>(
				`${API_URL}Search/advanced`,
				{
					method: 'POST',
					body: JSON.stringify({
						directSearch: true,
						searchTerm: getLastPathSegment(url),
						products: { offset: 0, pageSize: productsPageSize },
						brands: { offset: 0, pageSize: 4 },
						categories: { offset: 0, pageSize: 3 },
						stores: { offset: 0, pageSize: 3 },
						inspirationPages: { offset: 0, pageSize: 3 },
					}),
				},
			);
			if (result?.categorySearchResponse?.categories?.length) {
				pushToGTM({ type: 'search_term_category_view' });
			}
			if (result?.brandSearchResponse?.brands?.length) {
				pushToGTM({ type: 'search_term_brand_view' });
			}
			return result;
		},
	);

	const categories = useMemo(
		() => filterTruthy(data?.categorySearchResponse?.categories, 'url'),
		[data?.categorySearchResponse?.categories],
	);
	const brands = useMemo(
		() => filterTruthy(data?.brandSearchResponse?.brands, 'url'),
		[data?.brandSearchResponse?.brands],
	);
	const products = useMemo(
		() => filterTruthy(data?.productSearchResponse?.products, 'url'),
		[data?.productSearchResponse?.products],
	);
	const stores = useMemo(
		() => filterTruthy(data?.storeSearchResponse?.stores, 'url'),
		[data?.storeSearchResponse?.stores],
	);
	const inspirationPages = useMemo(
		() =>
			filterTruthy(
				data?.inspirationPageSearchResponse?.inspirationPages.map((page) => ({
					...page,
					text: page.fields?.navigationTitle?.value,
				})),
				'url',
				'text',
			),
		[data?.inspirationPageSearchResponse?.inspirationPages],
	);

	return {
		searchTerms:
			data?.autocompleteQueries?.slice(0, termsPageSize) ?? empty.array,
		categories,
		brands,
		products,
		stores,
		inspirationPages,
		totalProductCount: data?.productSearchResponse?.total ?? 0,
		spellingSuggestions:
			data?.productSearchResponse?.spellingSuggestions ?? empty.array,
		isLoading,
		error,
	};
}
