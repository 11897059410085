/**
 * ProductList
 */

import React, {
	type ChangeEvent,
	Fragment,
	type MouseEventHandler,
	useEffect,
} from 'react';
import { useRouter } from 'next/router';

import Chip from 'components/Chip';
import ErrorBoundary from 'components/ErrorBoundary';
import ExpandableDescription from 'components/ExpandableDescription';
import { Select } from 'components/FormUi';
import LoadMoreList from 'components/LoadMoreList';
import Meter from 'components/Meter';
import ProductCard from 'components/ProductCard';
import SwipeWrapper from 'components/SwipeWrapper';
import Text from 'components/Text';
import {
	useEffectOnce,
	usePagination,
	useProductListGTMEvents,
	useValueChangeEffect,
} from 'hooks';
import { FacetResponse } from 'models/api';
import type { ProductCard as ProductCardModel } from 'models/productCard';
import type { ProductList as ProductListModel } from 'models/sitecore';
import type { WithRequired } from 'types';
import { cn } from 'utils/classNames';
import { filterObject } from 'utils/collection';
import {
	type GTMItemListId,
	type GTMItemListName,
	pushToGTM,
} from 'utils/GoogleTagManager';
import { asArray, empty, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';
import { isSamePath } from 'utils/url';

import {
	OFFSET_QUERY_VAR,
	PAGE_SIZE_QUERY_VAR,
	SEARCH_QUERY_QUERY_VAR,
	SORTING_QUERY_VAR,
} from './constants';
import { refineMicroContent } from './helpers';
import ProductListActiveFilterChips from './ProductListActiveFilterChips';
import ProductListFacetAccordions from './ProductListFacetAccordions';
import ProductListFiltersPopover from './ProductListFiltersPopover';
import ProductListMicroContentItem from './ProductListMicroContentItem';

type ProductListFacetWithItems = WithRequired<FacetResponse, 'facetItems'>;

interface Props {
	className?: string;
	fields: ProductListModel;
	gtmItemListId: GTMItemListId;
	gtmItemListName: GTMItemListName;
	placeholderComponentName: string;
	searchQuery?: string;
}

/**
 * Product list used for the full product catalog, search results and
 * smaller lists of selected products.
 */
export default function ProductList({
	className,
	fields: initialFields,
	gtmItemListId,
	gtmItemListName,
	placeholderComponentName,
	searchQuery,
}: Props) {
	const { t } = useI18n();
	const router = useRouter();
	const urlPath = router.asPath.split('?')[0] || '';

	const facetIds =
		initialFields.facets?.map((facet) => facet.id) ?? empty.array;

	// Map router `key: [value, value]` object to `[key, value]` tuples, only
	// including facets since sorting and search is handled below.
	const initialQueryVars = Object.entries(router.query)
		.filter(([key]) => facetIds.includes(String(key)))
		.flatMap(([facetId, values]) =>
			asArray(values).map((val): [string, string] => [facetId, val]),
		);
	const initialNonDefaultSort = initialFields.sortOptions?.find(
		(opt) =>
			!opt.default &&
			opt.attribute === router.query[SORTING_QUERY_VAR]?.toString(),
	);
	if (initialNonDefaultSort) {
		initialQueryVars.push([SORTING_QUERY_VAR, initialNonDefaultSort.attribute]);
	}
	if (searchQuery) {
		initialQueryVars.push([SEARCH_QUERY_QUERY_VAR, searchQuery]);
	}

	const microContent =
		initialFields.microContent?.map(refineMicroContent).filter(Boolean) ??
		empty.array;
	const firstMicroContentIndex = 5;
	// Each key is the product index where an item will be included after.
	const microContentItems = filterObject(
		{
			[firstMicroContentIndex]: microContent[0],
			12: microContent[1],
			19: microContent[2],
		},
		(item, key) =>
			Boolean(item) &&
			// Remove items that won't be displayed to make the view_promotion GTM
			// event correct. The first item will always be visible so use its index
			// as the minimum.
			Number.parseInt(key, 10) <=
				Math.max(initialFields.products.length, firstMicroContentIndex),
	);
	const microContentCount = Object.values(microContentItems).length;

	// Count any rendered micro content as viewed. Only run once on mount to not
	// send events every time the list re-renders from stuff like filtering.
	useEffectOnce(() => {
		Object.values(microContentItems).forEach((item) => {
			pushToGTM({ type: 'view_promotion', payload: item });
		});
	});

	const {
		component: productListFields,
		getQueryVarValue,
		hasQueryVar,
		isLoading: isLoadingProducts,
		items: products,
		loadMore: loadMoreProducts,
		queryVarItems,
		updateQueryVars,
	} = usePagination<ProductCardModel, ProductListModel>({
		defaultPageSize: initialFields.initialPageSize,
		initialComponent: initialFields,
		initialItems: initialFields.products,
		initialNextPageOffset: initialFields.nextPageOffset,
		initialQueryVars,
		firstPageItemCountOffset: -microContentCount,
		itemsKey: 'products',
		offsetQueryVarName: OFFSET_QUERY_VAR,
		pageSizeQueryVarName: PAGE_SIZE_QUERY_VAR,
		placeholderComponentName,
	});

	const facets = (productListFields?.facets ?? empty.array).filter(
		(facet): facet is ProductListFacetWithItems =>
			is.arrayWithLength(facet.facetItems),
	);
	const hasFacets = is.arrayWithLength(facets);

	const hasNextPage = Boolean(productListFields?.hasNextPage);
	const sortOptions = productListFields?.sortOptions ?? empty.array;
	const hasSortOptions = is.arrayWithLength(sortOptions);
	const categoryTextUnformatted =
		productListFields?.category?.unformattedLongText?.value;
	const categoryTextFormatted =
		productListFields?.category?.formattedLongText?.value;
	const subcategories = productListFields?.subcategories ?? empty.array;
	const hasSubcategories = is.arrayWithLength(subcategories);
	const totalProductsCount = productListFields?.total ?? 0;
	const visibleProductsCount = products.length;

	const getSortOption = (value: string | undefined) =>
		sortOptions.find((opt) => opt.attribute === value);

	const clearAllFilters = () => {
		updateQueryVars((vars) => {
			facets.forEach((facet) => {
				vars.delete(facet.id);
			});
		});
	};

	const getMicroContentData = (productIndex: number) => {
		const isLastProduct = productIndex === visibleProductsCount - 1;
		// If on the last product and the total number of products are
		// below the first micro content index, force that index to
		// always show at least one micro content.
		const key =
			isLastProduct && visibleProductsCount <= firstMicroContentIndex
				? firstMicroContentIndex
				: productIndex;
		return is.keyOf(microContentItems, key)
			? microContentItems[key]
			: undefined;
	};

	const { sendViewItemListEvent } = useProductListGTMEvents(
		gtmItemListId,
		gtmItemListName,
	);
	useEffect(() => {
		if (products.length > 0) {
			sendViewItemListEvent(
				products,
				router.query[OFFSET_QUERY_VAR]
					? Number.parseInt(router.query[OFFSET_QUERY_VAR].toString(), 10)
					: initialFields.nextPageOffset,
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [products]);

	// Reset filtering when searching for something new.
	useValueChangeEffect(searchQuery, () => {
		clearAllFilters();
	});

	const updateSorting = (value: string) => {
		const selected = getSortOption(value);
		updateQueryVars((vars) => {
			if (selected?.default) {
				vars.delete(SORTING_QUERY_VAR);
			} else {
				vars.set(SORTING_QUERY_VAR, value);
			}
		});
	};

	const updateFacet = (name: string, value: string, checked: boolean) => {
		updateQueryVars((vars) => {
			if (checked) {
				vars.add([name, value]);
			} else {
				vars.delete(name, value);
			}
		});
	};

	const handleSortChange = (
		e: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
	) => {
		updateSorting(e.target.value);
	};

	const handleFacetFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
		updateFacet(e.target.name, e.target.value, e.target.checked);
	};

	const handleFilterChipRemoveClick: MouseEventHandler<HTMLButtonElement> = (
		e,
	) => {
		const { target } = e;
		if (is.instance(target, HTMLButtonElement)) {
			updateQueryVars((vars) => {
				vars.delete(target.name, target.value);
			});
		}
	};

	const handleFilterClear = () => {
		clearAllFilters();
	};

	// Facets that have active items, and only with those active items.
	const activeFacets = facets
		.map(
			(facet): ProductListFacetWithItems => ({
				...facet,
				facetItems: facet.facetItems.filter((item) =>
					hasQueryVar(facet.id, item.item),
				),
			}),
		)
		.filter((facet) => facet.facetItems.length > 0);

	const selectedSort = getSortOption(getQueryVarValue(SORTING_QUERY_VAR));
	const defaultSort = sortOptions.find((opt) => opt.default);

	const hasSidebar = hasSubcategories || hasFacets;

	const productCountText = `${totalProductsCount} ${t(
		'product_list_products_label',
	)}`;

	return (
		<div className={cn('md:grid md:grid-cols-12 md:gap-x-6', className)}>
			<ErrorBoundary>
				<div
					className={cn(
						'col-span-3 lg:col-span-2',
						hasSidebar ? undefined : 'md:hidden',
					)}
				>
					{categoryTextFormatted && (
						<ExpandableDescription
							description={categoryTextFormatted}
							textLengthBreakPoint={250}
							className="mb-6 md:hidden"
							descriptionClassName="text-lg"
							buttonVariant="text"
							buttonPlacement="left"
							textHeight="short"
						/>
					)}
					{hasSubcategories && (
						<div className="md:order-1">
							<SwipeWrapper
								activeClassName="md:hidden mb-4"
								inactiveClassName="max-md:hidden"
								pullGutters
							>
								{subcategories.map((subcategory) => (
									<li key={subcategory.id} className="md:mb-3">
										<Chip
											className={
												isSamePath(urlPath, subcategory.url)
													? 'outline outline-2 outline-greyDarker'
													: undefined
											}
											color="grey"
											text={subcategory.fields.title.value}
											href={
												selectedSort && selectedSort !== defaultSort
													? `${subcategory.url}?${SORTING_QUERY_VAR}=${selectedSort.attribute}`
													: subcategory.url
											}
										/>
									</li>
								))}
							</SwipeWrapper>
						</div>
					)}
					<div className="[grid-area:area-6--mobile] md:hidden">
						{(hasFacets || hasSortOptions) && (
							<div className="flex items-center justify-between">
								<Text
									aria-live="polite"
									aria-atomic
									as="p"
									text={productCountText}
								/>
								<ProductListFiltersPopover
									disabled={isLoadingProducts}
									facets={facets}
									onFacetChange={handleFacetFilterChange}
									onReset={handleFilterClear}
									onSortChange={handleSortChange}
									selectedFacetValues={queryVarItems.filter(([key]) =>
										facetIds.includes(key),
									)}
									selectedSortOptionValue={
										getQueryVarValue(SORTING_QUERY_VAR) ||
										defaultSort?.attribute ||
										sortOptions[0]?.attribute ||
										''
									}
									sortOptions={sortOptions}
									totalProductsCount={totalProductsCount}
								/>
							</div>
						)}
					</div>
					<div className="max-md:hidden md:order-2">
						<ProductListFacetAccordions
							activeFacets={activeFacets}
							disabled={isLoadingProducts}
							facets={facets}
							onChange={handleFacetFilterChange}
						/>
						{categoryTextUnformatted && (
							<p className="mt-10">{categoryTextUnformatted} </p>
						)}
					</div>
				</div>
			</ErrorBoundary>

			<div className={hasSidebar ? 'col-span-9 lg:col-span-10' : 'col-span-12'}>
				<div
					className={cn(
						'mb-4 flex items-start justify-end max-md:hidden',
						// Don't show sorting if there is no filtering.
						!hasSubcategories && !hasFacets && 'md:hidden',
					)}
				>
					<Text
						aria-live="polite"
						aria-atomic
						as="p"
						className="mr-4 self-center whitespace-nowrap"
					>
						{productCountText}
					</Text>
					{hasSortOptions && (
						<Select
							id="product-list-sorting"
							label={t('product_list_sorting_facet_heading')}
							hiddenLabel
							className="ml-4 w-60 shrink-0 font-bold"
							options={sortOptions.map((opt) => ({
								label: opt.name,
								value: opt.attribute,
							}))}
							value={selectedSort?.attribute || defaultSort?.attribute}
							onChange={handleSortChange}
						/>
					)}
				</div>

				<ProductListActiveFilterChips
					activeFacets={activeFacets}
					className="my-6 md:mt-0"
					onClearClick={handleFilterClear}
					onFilterChipClick={handleFilterChipRemoveClick}
				/>

				<LoadMoreList
					isLoading={isLoadingProducts}
					onLoadMoreClick={loadMoreProducts}
					hasLoadMoreButton={hasNextPage}
					buttonAlignment="center"
					buttonClassName="mt-4 max-sm:w-full sm:min-w-72"
					buttonText={t('load_more_products_button')}
					listClassName={cn(
						'mt-4 grid grid-cols-2 items-start gap-x-4 gap-y-8 sm:grid-cols-3 md:gap-x-6 md:gap-y-12',
						'relative transition-opacity',
						isLoadingProducts && 'opacity-50',
						hasSidebar
							? 'lg:grid-cols-4 xl:grid-cols-5'
							: 'md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6',
					)}
					afterListContent={
						<Meter
							alignment="center"
							className="mt-14"
							current={visibleProductsCount}
							max={totalProductsCount}
							labelHasProgress
							label={t('product_list_page_indicator_text', {
								numShown: visibleProductsCount,
								numTotal: totalProductsCount,
							})}
						/>
					}
				>
					{products.map((product, i) => {
						const microContentData = getMicroContentData(i);
						return (
							<Fragment
								key={`${product.productId}-${product.id}-${product.title}`}
							>
								<ProductCard
									product={product}
									productListIndex={i}
									gtmItemListId={gtmItemListId}
									gtmItemListName={gtmItemListName}
								/>
								{microContentData && (
									<ProductListMicroContentItem data={microContentData} />
								)}
							</Fragment>
						);
					})}
				</LoadMoreList>
			</div>
		</div>
	);
}
ProductList.displayName = 'ProductList';
