import { useCallback, useState } from 'react';

import { sendToast } from 'components/Toast';
import { publicRuntimeConfig } from 'config';
import type { ActionButtonState } from 'state-machines/actionButton.machine';
import { API_URL, fetchData } from 'utils/fetch';
import { ignorePromiseRejection, is, sleep } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

type ListType = { type: 'wishlist' } | { type: 'cart'; cartId: string };
export function useShareList(type: ListType) {
	const [shareState, setShareState] = useState<ActionButtonState>('idle');

	const { t } = useI18n();
	const shareList = useCallback(
		async (callback: (url: string) => Promise<unknown>) => {
			if (shareState === 'loading') {
				return;
			}
			setShareState('loading');

			let listShareId: string | undefined;

			try {
				listShareId = await fetchData<string>(
					type.type === 'wishlist'
						? `${API_URL}Wishlist/share`
						: `${API_URL}Wishlist/sharecart/${type.cartId}`,
					{
						method: 'POST',
					},
				);
				if (!listShareId) {
					throw new Error('No list share id');
				}

				// Safari being insane - a setTimeout hack works around permission error.
				// https://stackoverflow.com/q/62327358
				await sleep(0);
			} catch {
				sendToast(t('list_share_error'), 'error');
				setShareState('idle');
				return;
			}
			const url = `${publicRuntimeConfig?.NEXT_PUBLIC_PUBLIC_URL}/wishlist/${listShareId}/`;
			try {
				await callback(url);
			} catch {
				setShareState('idle');
				return;
			}

			sendToast(t('list_share_success'), 'success');
			setTimeout(() => {
				setShareState('idle');
			}, 3000);
		},
		[shareState, t, type],
	);

	const shareListUrl = useCallback(
		() =>
			ignorePromiseRejection(
				shareList((url: string) => {
					// eslint-disable-next-line @typescript-eslint/unbound-method
					if (is.func(globalThis.navigator.share)) {
						return globalThis.navigator.share({
							title: t('list_share_link_button'),
							url,
						});
					}
					return globalThis.navigator.clipboard.writeText(url);
				}),
			),
		[shareList, t],
	);
	const copyListUrl = useCallback(() => {
		ignorePromiseRejection(
			shareList((url: string) => globalThis.navigator.clipboard.writeText(url)),
		);
	}, [shareList]);

	return { shareState, shareListUrl, copyListUrl } as const;
}
