import { assign, createMachine, type StateFrom } from 'xstate';

import type { ActionButtonState } from 'components/ActionButton';
import { sendToast } from 'components/Toast';
import { isError, type ValidationError } from 'errors';
import { isGuiIframe, POST_SIGN_EVENT } from 'pages/api/sign/helpers';
import { isClient } from 'utils/helpers';

import {
	bankIdSignInInNewTab,
	requestCancelSigning,
	requestJulaProApiAuth,
	requestJulaProApiOrganizationSearch,
	requestJulaProApiOrganizationSelect,
	requestJulaProApiRegisterCustomer,
	requestPollCustomerRegistrationStatus,
	signInNewCustomer,
} from './createJulaProCustomer.services';
import type {
	CreateJulaProCustomerMachineContext,
	CreateJulaProCustomerMachineEvents,
	CreateJulaProCustomerServices,
} from './createJulaProCustomer.types';

export const createJulaProCustomerMachine = createMachine(
	{
		id: 'createJulaProCustomerMachine',
		schema: {
			context: {} as CreateJulaProCustomerMachineContext,
			events: {} as CreateJulaProCustomerMachineEvents,
			services: {} as CreateJulaProCustomerServices,
		},
		preserveActionOrder: true,
		tsTypes: {} as import('./createJulaProCustomer.machine.typegen').Typegen0,
		context: {
			pollingNumberFailed: 0,
			selectOrganizationButtonState: 'idle',
			shouldDisplaySignIframeModal: false,
			shouldDisplayAbortButton: false,
			requiresAdditionalInfo: false,
			creditFunctionsEnabled: false,
		},
		initial: 'idle',
		states: {
			idle: {
				description:
					'Entry state, waiting for the event to start create customer process',
				entry: ['resetValidationErrors'],
				on: {
					CREATE_CUSTOMER: 'createCustomer',
				},
			},
			createCustomer: {
				description: 'Starts the customer creation process',
				initial: 'requestJulaProApiAuth',
				exit: 'resetRequiresAdditionalInformation',
				states: {
					requestJulaProApiAuth: {
						tags: 'loading',
						invoke: {
							id: 'createJulaProCustomerService',
							src: 'sendCreateCustomer',
							onDone: [
								{
									target: '#selectOrganization',
									actions: 'setCustomerInfo',
								},
							],
							onError: [
								{
									cond: 'eventRequiresAdditionalInformation',
									target: 'requiresAdditionalInformation',
								},
								{
									cond: 'hasCriticalError',
									target: '#customerCreationError',
								},
								{ target: '#customerCreationError' },
							],
						},
					},
					requiresAdditionalInformation: {
						entry: 'setRequiresAdditionalInformation',
						tags: [
							'requiresAdditionalInformation',
							'requestJulaProApiAuthDone',
						],
						description: 'Customer requires additional information',
						on: {
							CREATE_CUSTOMER: 'requestJulaProApiAuth',
						},
					},
				},
			},
			selectOrganization: {
				id: 'selectOrganization',
				tags: 'requestJulaProApiAuthDone',
				description: 'Search for and selects an organisation',
				initial: 'idle',
				states: {
					idle: {
						description: 'Waiting for a search event',
						on: {
							SEARCH_ORGANIZATION: 'searchingOrganization',
						},
					},
					searchingOrganization: {
						description: 'Searching for an organisation with a search string',
						entry: 'clearSearchResults',
						invoke: {
							id: 'julaProSearchOrganization',
							src: 'sendSearchOrganization',
							onDone: [
								{
									target: 'searchOrganizationSuccess',
									actions: 'setOrganizations',
								},
							],
							onError: [
								{
									cond: 'hasCriticalError',
									target: 'criticalError',
								},
								{
									target: 'errorSearchingOrganization',
								},
							],
						},
					},
					searchOrganizationSuccess: {
						description: 'Search is complete and we have a result',
						on: {
							SELECT_ORGANIZATION: {
								target: 'loadingOrganizationSelection',
							},
							NEW_SEARCH_IN_PROGRESS: 'waitingForNewOrganizationSearch',
						},
					},
					errorSearchingOrganization: {
						description: 'Search failed',
						on: {
							NEW_SEARCH_IN_PROGRESS: 'waitingForNewOrganizationSearch',
							SELECT_ORGANIZATION: {
								target: 'loadingOrganizationSelection',
							},
						},
					},
					waitingForNewOrganizationSearch: {
						description: 'waiting for new search term',
						on: {
							SEARCH_ORGANIZATION: 'searchingOrganization',
							SELECT_ORGANIZATION: {
								target: 'loadingOrganizationSelection',
							},
						},
					},
					loadingOrganizationSelection: {
						description: 'Sending selected organisation to API',
						entry: [
							'setSelectOrganizationButtonStateLoading',
							'resetSelectedWorksite',
						],
						invoke: {
							id: 'julaProSelectOrganization',
							src: 'sendLoadOrganizationSelection',
							onDone: [
								{
									target: 'selectWorksiteSuccess',
									cond: 'worksiteRegistrationStatusIsOk',
									actions: [
										'setSelectedWorksite',
										'setSelectOrganizationButtonStateSuccess',
									],
								},
								{
									target: 'getCustomerIdFromUser',
									cond: 'worksiteRegistrationStatusIsRequiresCustomerId',
									actions: [
										'setSelectedWorksite',
										'setSelectOrganizationButtonStateSuccess',
									],
								},
								{
									target: 'errorSelectingWorksite',
									actions: [
										'setSelectedWorksite',
										'setSelectOrganizationButtonStateFailure',
									],
								},
							],
							onError: [
								{
									target: 'criticalError',
									cond: 'hasCriticalError',
									actions: ['setSelectOrganizationButtonStateFailure'],
								},
								{
									target: 'errorSelectingWorksite',
								},
							],
						},
					},
					getCustomerIdFromUser: {
						description:
							'CustomerId is required in order to select this worksite',
						initial: 'waitingForInput',
						id: 'getCustomerIdFromUser',
						states: {
							waitingForInput: {
								on: {
									ADD_CUSTOMER_ID: {
										target: 'sendCustomerId',
										actions: 'setCustomerId',
									},
								},
							},
							sendCustomerId: {
								invoke: {
									id: 'julaProSendCustomerId',
									src: 'sendLoadOrganizationSelection',
									onDone: [
										{
											target: '#selectWorksiteSuccess',
											cond: 'worksiteRegistrationStatusIsOk',
											actions: ['setSelectedWorksite'],
										},
										{
											target: 'customerIdHasError',
											cond: 'worksiteRegistrationStatusIsRequiresCustomerId',
											actions: ['setSelectedWorksite'],
										},
										{
											target: 'customerIdHasError',
											cond: 'worksiteRegistrationStatusIsSpecifiedCustomerIdNotExist',
											actions: ['setSelectedWorksite'],
										},
										{
											target: '#errorSelectingWorksite',
											actions: ['setSelectedWorksite'],
										},
									],
									onError: [
										{
											target: 'criticalError',
											cond: 'hasCriticalError',
										},
										{
											target: 'customerIdHasError',
										},
									],
								},
							},
							customerIdHasError: {
								id: 'customerIdHasError',
								tags: 'customerIdSent',
								on: {
									ADD_CUSTOMER_ID: {
										target: 'sendCustomerId',
										actions: 'setCustomerId',
									},
								},
							},
							criticalError: {
								entry: ['showCriticalErrorToast'],
							},
						},
					},
					errorSelectingWorksite: {
						id: 'errorSelectingWorksite',
						tags: 'customerIdSent',
						description: 'Something went wrong when selecting an organisation',
						on: {
							SELECT_ORGANIZATION: {
								target: 'loadingOrganizationSelection',
							},
							SEARCH_ORGANIZATION: 'searchingOrganization',
						},
					},
					criticalError: {
						entry: ['showCriticalErrorToast'],
					},
					selectWorksiteSuccess: {
						id: 'selectWorksiteSuccess',
						tags: 'customerIdSent',

						description: 'Selecting organisation succeeded',
						after: {
							100: [
								{
									target: '#createJulaProCustomerMachine.selectCredit',
									cond: 'isCreditEnabled',
								},
								{
									target: '#registerCustomer',
								},
							],
						},
					},
				},
				on: {
					RESET_TO_SEARCH_ORGANIZATION: {
						target: 'selectOrganization.idle',
						actions: 'clearSearchResults',
					},
				},
			},
			selectCredit: {
				description: 'Select credit',
				on: {
					SELECT_CREDIT: {
						target: '#registerCustomer',
						actions: 'setSelectedCredit',
					},
					RESET_TO_SELECT_ORGANIZATION:
						'selectOrganization.searchOrganizationSuccess',
				},
			},
			registerCustomer: {
				id: 'registerCustomer',
				description: 'Register the customer',
				initial: 'editOrganizationDetails',
				invoke: {
					src: 'listenForIframeMessage',
				},
				exit: ['removeSignIframeModal', 'closeSignWindow'],
				states: {
					editOrganizationDetails: {
						description: 'Editing organisation details',
						on: {
							REGISTER_CUSTOMER: {
								target: 'requestRegisterCustomer',
							},
						},
					},
					requestRegisterCustomer: {
						invoke: {
							id: 'julaProRegisterCustomer',
							src: 'sendRegisterCustomer',
							onDone: [
								{
									cond: 'shouldSignBankId',
									target: 'displaySignModalOrWindow',
									actions: 'setTicket',
								},
								{
									cond: 'shouldPollForCustomerCreation',
									target: 'waitingForCustomerToBeCreated',
									actions: 'setTicket',
								},
							],
							onError: [
								{
									cond: 'eventHasValidationErrors',
									target: 'validationError',
									actions: 'setValidationErrors',
								},
								{
									cond: 'eventCustomerNotCreated',
									target: '#customerNotCreated',
								},
								{
									target: 'criticalError',
									cond: 'hasCriticalError',
								},
								{ target: '#customerCreationError' },
							],
						},
					},
					validationError: {
						description: 'Some validation failed',
						tags: 'waitForStepThreeValidation',
						on: {
							REGISTER_CUSTOMER: {
								target: 'requestRegisterCustomer',
							},
						},
					},
					openSignWindowError: {
						description: 'If there are issues opening the window for signing',
						on: {
							REGISTER_CUSTOMER: {
								target: 'displaySignModalOrWindow',
							},
						},
					},

					displaySignModalOrWindow: {
						tags: ['waitForStepThreeValidation', 'signing'],

						after: {
							100: [
								{
									target: 'waitingForCustomerToBeCreated',
									actions: 'openSignIframeModal',
									cond: 'shouldDisplaySignIframeModal',
								},
								{
									target: 'displayBankIdSignInInNewWindow',
								},
							],
						},
					},
					displayBankIdSignInInNewWindow: {
						tags: 'signing',
						invoke: {
							src: 'openBankIdSignInInNewWindow',
							onDone: {
								target: 'waitingForCustomerToBeCreated',
								actions: ['setSignWindowRef', 'showAbortButton'],
							},
							onError: 'openSignWindowError',
						},
					},
					waitingForCustomerToBeCreated: {
						tags: ['signing', 'showLoader'],
						description:
							'Waiting for the customer to be created by polling the API.',
						after: {
							POLL_CUSTOMER_CREATION_DELAY: {
								target: 'checkingCustomerCreationStatus',
							},
						},
					},

					cancelSigning: {
						id: 'cancelSigning',
						description: 'cancel the ticket and abort the signup flow',
						invoke: {
							src: 'cancelSigning',
							onDone: { target: 'checkingCustomerCreationStatus' },
							onError: { target: 'checkingCustomerCreationStatus' },
						},
					},
					checkingCustomerCreationStatus: {
						tags: ['signing', 'showLoader'],
						description: 'Checking the status of the customer creation',
						invoke: {
							id: 'pollCustomerApi',
							src: 'sendCheckCustomerCreationStatus',
							onDone: [
								{
									cond: 'customerCreationIsPending',
									target: 'waitingForCustomerToBeCreated',
								},
								{
									cond: 'customerCreationIsDone',
									target: '#signInNewCustomer',
									actions: 'setResolvedCustomerTicket',
								},
							],
							onError: [
								{
									cond: 'eventCreditCheckDenied',
									target: '#customerNotCreated',
								},
								{
									cond: 'eventSigningCanceled',
									target: '#signingCanceled',
								},
								{
									cond: 'eventHasCustomerCreationError',
									target: '#customerCreationError',
								},

								{
									cond: 'eventCustomerNotCreated',
									target: '#customerNotCreated',
								},
								{ target: '#customerCreationError' },
							],
						},
					},
					criticalError: {
						entry: ['showCriticalErrorToast'],
					},
				},
				on: {
					ABORT_AND_CLOSE_SIGNICAT_WINDOW: { actions: 'closeSignWindow' },
					SIGN_JULA_PRO_BECOME_MEMBER_DONE: {
						actions: [
							'removeSignIframeModal',
							'closeSignWindow',
							'hideAbortButton',
						],
					},
					RESET_TO_PREVIOUS_STEP: [
						{
							cond: 'isCreditEnabled',
							target: '#createJulaProCustomerMachine.selectCredit',
						},
						{
							target:
								'#createJulaProCustomerMachine.selectOrganization.searchOrganizationSuccess',
						},
					],
					ABORT_SIGNING_FROM_MODAL: {
						target: '#cancelSigning',
					},
				},
			},
			signInNewCustomer: {
				id: 'signInNewCustomer',
				tags: ['signing', 'showLoader'],
				invoke: {
					id: 'invokeSignInNewCustomer',
					src: 'signInNewCustomer',
					onDone: {
						target: 'customerCreated',
					},
					onError: {
						target: 'errorRefreshingToken',
					},
				},
			},
			errorRefreshingToken: {
				on: {
					CREATE_CUSTOMER: 'createCustomer',
				},
			},
			customerNotCreated: {
				id: 'customerNotCreated',
				description: 'Customer couldnt be created',
				tags: 'waitForStepThreeValidation',
				on: {
					CREATE_CUSTOMER: 'createCustomer',
					RESET: 'idle',
				},
			},
			signingCanceled: {
				id: 'signingCanceled',
				description: 'Customer canceled signing and aborted the process',
				tags: 'waitForStepThreeValidation',
				on: {
					CREATE_CUSTOMER: 'createCustomer',
					RESET: 'idle',
				},
			},
			customerCreationError: {
				id: 'customerCreationError',
				tags: 'waitForStepThreeValidation',
				description: 'customer creation failed',
			},
			customerCreated: {
				id: 'customerCreated',
				description: 'Customer was created',
				on: {
					RELOAD: { actions: 'refreshBrowser' },
				},
			},
		},
		on: {
			RESET_TO_SELECT_ORGANIZATION:
				'#createJulaProCustomerMachine.selectOrganization.searchOrganizationSuccess',
		},
	},
	{
		actions: {
			setTicket: assign({
				ticket: (_, event) => event.data,
			}),
			setResolvedCustomerTicket: assign({
				resolvedTicket: (_context, event) => event.data,
			}),
			setSelectOrganizationButtonStateSuccess: assign({
				selectOrganizationButtonState: (_context) =>
					'success' as ActionButtonState,
			}),
			setSelectOrganizationButtonStateFailure: assign({
				selectOrganizationButtonState: (_context) =>
					'failure' as ActionButtonState,
			}),
			setSelectOrganizationButtonStateLoading: assign({
				selectOrganizationButtonState: (_context) =>
					'loading' as ActionButtonState,
			}),
			showCriticalErrorToast: () => {
				sendToast('general_critical_error_text', 'error');
			},
			resetValidationErrors: assign({
				errors: (_context, _event) => undefined,
			}),
			setValidationErrors: assign({
				errors: (_context, _event) => _event.data as ValidationError,
			}),
			clearSearchResults: assign({
				organizationList: (_context) => undefined,
			}),
			setOrganizations: assign({
				organizationList: (_context, event) => event.data,
			}),
			setCustomerId: assign({
				customerId: (_context, event) => event.id,
			}),
			setSelectedWorksite: assign({
				selectedWorksite: (_context, event) => event.data,
			}),
			resetSelectedWorksite: assign({
				selectedWorksite: (_context, _event) => undefined,
			}),
			setSelectedCredit: assign({
				selectedCredit: (_context, event) => event.credit,
			}),
			openSignIframeModal: assign({
				shouldDisplaySignIframeModal: (_context, _event) => true,
			}),
			removeSignIframeModal: assign({
				shouldDisplaySignIframeModal: (_context, _event) => false,
			}),
			setSignWindowRef: assign({
				signWindowRef: (_context, event) => event.data,
			}),
			closeSignWindow: assign({
				signWindowRef: (_context, _event) => {
					if (_context.signWindowRef) _context.signWindowRef?.close();
					return null;
				},
			}),
			showAbortButton: assign({
				shouldDisplayAbortButton: (_context, _event) => true,
			}),
			hideAbortButton: assign({
				shouldDisplayAbortButton: (_context, _event) => false,
			}),
			setCustomerInfo: assign({
				customerInfo: (_context, event) => event.data,
			}),

			refreshBrowser: (_context, _event) => {
				globalThis.location.reload();
			},
			setRequiresAdditionalInformation: assign({
				requiresAdditionalInfo: (_context, _event) => true,
			}),
			resetRequiresAdditionalInformation: assign({
				requiresAdditionalInfo: (_context, _event) => false,
			}),
		},
		guards: {
			eventCreditCheckDenied: (_context, event) => {
				const { data } = event;

				if (isError(data, 'ValidationError')) {
					return data.generalErrors.some(
						(e) =>
							e.key === 'CreditCheckHasBeenDenied' || e.key === 'SigningFailed',
					);
				}
				return false;
			},
			eventSigningCanceled: (_context, event) => {
				const { data } = event;

				if (isError(data, 'ValidationError')) {
					return data.generalErrors.some((e) => e.key === 'SigningCanceled');
				}
				return false;
			},
			eventHasCustomerCreationError: (_context, event) => {
				const { data } = event;
				if (isError(data, 'ResponseError')) {
					return data?.status === 400 || data?.status === 500;
				}
				return false;
			},
			eventHasValidationErrors: (_context, event) => {
				const { data } = event;

				return isError(data, 'ValidationError');
			},
			eventRequiresAdditionalInformation: (_context, event) => {
				const { data } = event;

				return isError(data, 'ValidationError');
			},
			eventCustomerNotCreated: (_context, event) => {
				const { data } = event;
				if (isError(data, 'ResponseError')) {
					return data?.status === 404;
				}
				return false;
			},
			hasCriticalError: (_context, event) => {
				const { data } = event;
				if (isError(data, 'ResponseError')) {
					return data?.status === 403 || data?.status === 500;
				}
				return false;
			},
			worksiteRegistrationStatusIsRequiresCustomerId: (_context, event) => {
				const { data } = event;
				return data?.worksiteRegistrationStatus === 'RequiresCustomerId';
			},
			worksiteRegistrationStatusIsSpecifiedCustomerIdNotExist: (
				_context,
				event,
			) => {
				const { data } = event;

				return (
					data?.worksiteRegistrationStatus === 'SpecifiedCustomerIdDontExist'
				);
			},
			worksiteRegistrationStatusIsOk: (_context, event) => {
				const { data } = event;
				return data?.worksiteRegistrationStatus === 'Ok';
			},
			isCreditEnabled: (context, _event) => context.creditFunctionsEnabled,
			shouldSignBankId: (_context, event) =>
				event.data?.clientAction === 'Redirect',
			shouldPollForCustomerCreation: (_context, event) =>
				event.data?.clientAction === 'Polling',
			customerCreationIsPending: (_context, event) =>
				event.data?.clientAction === 'Polling',
			customerCreationIsDone: (_context, event) =>
				event.data?.clientAction === 'None',
			shouldDisplaySignIframeModal: (_context) => isGuiIframe(),
		},
		delays: {
			POLL_CUSTOMER_CREATION_DELAY: (context, _event) => {
				const delayFromTicket = Number(
					context.ticket?.data?.RefreshIntervalSeconds,
				);
				const delay = delayFromTicket > 0 ? delayFromTicket : 2;
				return delay * 1000;
			},
		},
		services: {
			// Subscribe to messages coming from iframes
			listenForIframeMessage: () => (send) => {
				if (!isClient()) {
					return undefined;
				}

				const listener = (event: MessageEvent) => {
					if (event.origin !== globalThis.location.origin) {
						return;
					}

					if (event.data.type === POST_SIGN_EVENT) {
						send({
							type: 'SIGN_JULA_PRO_BECOME_MEMBER_DONE',
						});
					}
				};

				window.addEventListener('message', listener, false);

				return () => {
					window.removeEventListener('message', listener);
				};
			},

			sendCreateCustomer: async (_context, event) =>
				requestJulaProApiAuth({
					mobileNumber: event.mobileNumber,
					token: event.token,
				}),

			sendSearchOrganization: async (_context, event) =>
				requestJulaProApiOrganizationSearch({
					searchString: event.searchString,
					maxSize: event.maxSize,
				}),

			sendLoadOrganizationSelection: async (context, event) => {
				if (event.type === 'SELECT_ORGANIZATION') {
					return requestJulaProApiOrganizationSelect({
						worksiteId: event.bisnodeWorksiteId,
					});
				}
				return requestJulaProApiOrganizationSelect({
					worksiteId: context?.selectedWorksite?.bisnodeWorksiteId,
					customerNumber: event.id,
				});
			},

			sendRegisterCustomer: async (context, event) => {
				let creditLimit: number | undefined;
				if (context.selectedCredit && context.selectedCredit > 0) {
					creditLimit = context.selectedCredit;
				}

				return requestJulaProApiRegisterCustomer({
					customerData: {
						creditLimit,
						...event.form,
					},
				});
			},

			sendCheckCustomerCreationStatus: async (_context, _event) =>
				requestPollCustomerRegistrationStatus(),
			openBankIdSignInInNewWindow: (context, _event) =>
				bankIdSignInInNewTab(context.ticket?.data?.Url),
			signInNewCustomer: async (_context, _event) => signInNewCustomer(),
			cancelSigning: (_context) => requestCancelSigning(),
		},
	},
);
export type CreateJulaProCustomerMachineState = StateFrom<
	typeof createJulaProCustomerMachine
>;
