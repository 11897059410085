import { publicRuntimeConfig } from 'config';
import { isTestmode } from 'hooks';
import type { Ticket } from 'models/api';
import { getPostSigningRedirectUrl } from 'pages/api/sign/helpers';
import type { IntrospectionResponse } from 'pages/api/token/introspection';
import { API_URL, fetchData } from 'utils/fetch';

export const requestCustomerApi = (
	fallBackApiUrl: string | undefined,
	params: Record<string, unknown>,
) => {
	let apiPathString = `${API_URL}Customer/julaclub/${publicRuntimeConfig?.NEXT_PUBLIC_JULA_MARKET_CODE}`;
	if (fallBackApiUrl) {
		apiPathString = `${API_URL}${fallBackApiUrl}`;
	}
	const apiPath = new URL(apiPathString);
	if (isTestmode()) {
		apiPath.searchParams.append('testmode', 'true');
	}
	const body = JSON.stringify({
		...params,
		postSigningRedirectUrl: getPostSigningRedirectUrl(),
	});

	return fetchData<Ticket>(apiPath.href, { method: 'POST', body });
};
export const pollCustomerRequest = () =>
	fetchData<Ticket>(`${API_URL}Ticket/Customer`);

export function signInNewCustomer() {
	return fetch('/api/signin/session/?forceRefresh=true');
}

export async function tokenInfoRequest() {
	const response = await fetch('/api/token/introspection/');
	if (!response.ok) {
		throw new Error('requestTokenData failed');
	}

	const data = (await response.json()) as IntrospectionResponse;
	return {
		username: data.username,
	};
}

export const openSignWindow = async (iframeSrc: string | undefined) =>
	new Promise<Window | null>((resolve, reject) => {
		if (!iframeSrc) {
			reject();
			return;
		}

		const authWindow = window.open(iframeSrc);
		if (authWindow) {
			resolve(authWindow);
		} else {
			reject();
		}
	});
