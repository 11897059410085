import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import Button from 'components/Button';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import Icon from 'components/Icon';
import RichText from 'components/RichText';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Status from 'components/Status';
import { useChat, useGlobalEvent } from 'hooks';
import type {
	JulaComponentProps,
	PartialPopoverProps,
} from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields?: {
		chatBusyMessage: Field<string>;
		chatClosedMessage: Field<string>;
		heading: Field<string>;
	};
	partialProps?: PartialPopoverProps;
};

export default function ChatButton({ fields, params, partialProps }: Props) {
	const { t } = useI18n();
	const {
		activateChatWidget,
		chatHasAvailableAgents,
		chatIsOpen,
		isLoadingChatAvailability,
		isLoadingChatWidget,
		openChat,
	} = useChat(true);

	useGlobalEvent('chat-widget-ready', () => {
		partialProps?.onPopoverClose();
		openChat();
	});

	if (!fields) {
		return null;
	}

	const heading = fields.heading?.value;
	const chatBusyMessage = fields.chatBusyMessage?.value;
	const chatClosedMessage = fields.chatClosedMessage?.value;

	if (!heading || !chatBusyMessage || !chatClosedMessage) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="ChatButton"
				description="Requires a heading"
			/>
		);
	}

	return (
		<div className={getEditorMargin(params)}>
			{isLoadingChatAvailability && (
				<Skeleton>
					<SkeletonItem height="3rem" />
				</Skeleton>
			)}
			{!isLoadingChatAvailability && (
				<>
					{chatIsOpen && chatHasAvailableAgents && (
						<Button
							variant="cta"
							displayWidth="full"
							disabled={isLoadingChatWidget}
							onClick={activateChatWidget}
						>
							{isLoadingChatWidget && (
								<Icon name="spinner" className="animate-spinFast" />
							)}
							<span className={isLoadingChatWidget ? 'sr-only' : undefined}>
								{isLoadingChatWidget ? t('general_loading_text') : heading}
							</span>
						</Button>
					)}
					{chatIsOpen && !chatHasAvailableAgents && (
						<Status color="red" text={<RichText html={chatBusyMessage} />} />
					)}
					{!chatIsOpen && (
						<Status color="red" text={<RichText html={chatClosedMessage} />} />
					)}
				</>
			)}
		</div>
	);
}
ChatButton.displayName = 'ChatButton';
