import React, { useState } from 'react';
import { useMachine, useSelector } from '@xstate/react';
import { useRouter } from 'next/router';

import ActionButton from 'components/ActionButton';
import Button from 'components/Button';
import InfoBox from 'components/InfoBox';
import { AccountLayoutContainer } from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import Popover from 'components/Popover';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { useEffectOnce } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { JulaProCustomerContact } from 'models/api';
import {
	selectInviteButtonState,
	selectRemoveUserButtonState,
	selectStateIsErrorGettingUser,
	selectStateIsLoadingUser,
	selectUser,
	userManagementMachine,
} from 'state-machines/userManagement';
import { formatDate } from 'utils/format';
import { useI18n } from 'utils/i18n';
import { removeTrailingSlash } from 'utils/url';

const getParentPath = (path: string) => {
	const pathArray = path.split('/').filter(Boolean);
	const parentPathArray = pathArray.slice(0, -1);
	return `/${parentPathArray.join('/')}/`;
};

type Props = JulaComponentProps & {};

export default function AccountJulaProUserDetails({ rendering }: Props) {
	const { t } = useI18n();
	const router = useRouter();

	const redirectToUserList = () => {
		const currentPath = globalThis.location.pathname;
		const parentPath = getParentPath(currentPath);
		router.push(parentPath);
	};

	const [removeUserPopoverOpen, setRemoveUserPopoverOpen] =
		useState<boolean>(false);

	const [_current, send, userManagementService] = useMachine(
		userManagementMachine,
		{
			devTools: true,
			actions: {
				redirectToUserList,
			},
		},
	);

	const isLoadingUser = useSelector(
		userManagementService,
		selectStateIsLoadingUser,
	);
	const errorGettingUser = useSelector(
		userManagementService,
		selectStateIsErrorGettingUser,
	);
	const inviteButtonState = useSelector(
		userManagementService,
		selectInviteButtonState,
	);
	const removeUserButtonState = useSelector(
		userManagementService,
		selectRemoveUserButtonState,
	);
	const user = useSelector(userManagementService, selectUser);

	useEffectOnce(() => {
		// Get the last segment in the URL.
		const id = removeTrailingSlash(router.asPath).split('/').pop();
		if (id) {
			send('GET_USER', { customerContactId: id });
		}
	});

	const getTextForRole = (contact: JulaProCustomerContact) => {
		switch (contact.role) {
			case 'Admin':
				return t('jula_pro_myusers_role_admin');
			case 'Authorized':
				return t('jula_pro_myusers_role_authorized');
			case 'EcomBuyer':
				return t('jula_pro_myusers_role_ecombuyer');
			default:
				return '';
		}
	};

	const renderUserDetails = (contact: JulaProCustomerContact) => (
		<div className="mb-10">
			<Text as="p" className="mb-2">
				<strong>{t('julapro_myusers_user_julaid')}:</strong>{' '}
				{contact.customerContactId}
			</Text>
			<Text as="p" className="mb-2">
				<strong>{t('julapro_myusers_user_phonenumber')}:</strong>{' '}
				{contact.phoneNumber.default}
			</Text>
			<Text as="p" className="mb-2">
				<strong>{t('julapro_myusers_user_role')}:</strong>{' '}
				{getTextForRole(contact)}
			</Text>
		</div>
	);

	const renderInvitationToEcom = (contact: JulaProCustomerContact) => (
		<div className="mb-10">
			<Text as="h2" className="mb-2">
				{t('julapro_myusers_user_upgrade_to_ecombuyer_heading')}
			</Text>

			<Text as="p" className="mb-6">
				{t('julapro_myusers_user_upgrade_to_ecombuyer_text')}
			</Text>
			<ActionButton
				variant="primary"
				onClick={() => {
					send('INVITE_TO_ECOM', {
						customerContactId: contact.customerContactId,
					});
				}}
				customState={inviteButtonState}
			>
				{t('julapro_myusers_user_upgrade_to_ecombuyer_button')}
			</ActionButton>
		</div>
	);

	const renderAbortInvitationToEcom = (contact: JulaProCustomerContact) => (
		<div className="mb-10">
			<Text as="h2" className="mb-2">
				{t('julapro_myusers_user_upgrade_to_ecombuyer_heading')}
			</Text>

			<InfoBox
				icon="info"
				variant="success"
				textSize="base"
				message={t(
					'julapro_myusers_user_upgrade_to_ecombuyer_in_progress_info',
					{
						invitationDate: formatDate(contact.invitationDate),
						name: contact.name,
					},
				)}
				className="mb-4 max-w-[25rem]"
			/>
			<Text as="p" className="mb-6">
				{t('julapro_myusers_user_upgrade_to_ecombuyer_in_progress_text')}
			</Text>
			<ActionButton
				variant="primary"
				onClick={() => {
					send('REVOKE_FROM_ECOM', {
						customerContactId: contact.customerContactId,
					});
				}}
				customState={inviteButtonState}
			>
				{t('julapro_myusers_user_upgrade_to_ecombuyer_abort_button')}
			</ActionButton>
		</div>
	);

	const renderChangeRole = (contact: JulaProCustomerContact) => (
		<div className="mb-10">
			<Text as="h2" className="mb-2">
				{t('julapro_myusers_user_revoke_ecombuyer_heading')}
			</Text>
			<Text as="p" className="mb-6">
				{t('julapro_myusers_user_revoke_ecombuyer_text')}
			</Text>
			<ActionButton
				variant="primary"
				onClick={() => {
					send('REVOKE_FROM_ECOM', {
						customerContactId: contact.customerContactId,
					});
				}}
				customState={inviteButtonState}
			>
				{t('julapro_myusers_user_revoke_ecombuyer_button')}
			</ActionButton>
		</div>
	);

	const renderRemoveUser = () => (
		<div className="mb-10">
			<Text as="h2" className="mb-2">
				{t('julapro_myusers_user_remove_user_heading')}
			</Text>
			<Text as="p" className="mb-6">
				{t('julapro_myusers_user_remove_user_text')}
			</Text>
			<Button
				onClick={() => setRemoveUserPopoverOpen(true)}
				aria-haspopup="dialog"
			>
				{t('julapro_myusers_user_remove_user_button')}
			</Button>
		</div>
	);

	const renderRemoveUserConfirmation = (contact: JulaProCustomerContact) => (
		<Popover
			title={t('julapro_myusers_user_remove_user_heading')}
			onClose={() => setRemoveUserPopoverOpen(false)}
			isOpen={removeUserPopoverOpen}
		>
			<Text as="h2" className="mb-6">
				{t('julapro_myusers_user_remove_user_confirmation_heading')}
			</Text>
			<ActionButton
				variant="cta"
				onClick={() => {
					send('REMOVE_USER', {
						customerContactId: contact.customerContactId,
					});
				}}
				customState={removeUserButtonState}
				className="mb-2"
				displayWidth="full"
			>
				{t('julapro_myusers_user_remove_user_submit_button_text')}
			</ActionButton>

			<Button
				onClick={() => setRemoveUserPopoverOpen(false)}
				displayWidth="full"
			>
				{t('julapro_myusers_user_remove_user_abort_button_text')}
			</Button>
		</Popover>
	);

	return (
		<AccountLayoutContainer
			rendering={rendering}
			heading={
				user ? (
					<div className="flex items-center">
						{user.name}
						{isLoadingUser && <LoadingSpinner size="small" className="ml-4" />}
					</div>
				) : null
			}
		>
			{isLoadingUser && !user && (
				<Skeleton>
					<SkeletonItem height="4.5rem" className="mb-6 w-2/3" />
					<SkeletonItem height="1rem" className="mb-2 w-1/2" />
					<SkeletonItem height="1rem" className="mb-2 w-1/2" />
					<SkeletonItem height="1rem" className="mb-2 w-1/2" />
				</Skeleton>
			)}
			{errorGettingUser && (
				<InfoBox
					icon="error"
					heading={t('julapro_myusers_error_loading_user_heading')}
					variant="error"
					message={t('julapro_myusers_error_loading_user_message')}
				/>
			)}
			{user && (
				<>
					{renderUserDetails(user)}
					{user.role === 'Authorized' &&
						!user.invitePendingForRole &&
						renderInvitationToEcom(user)}
					{user.role === 'Authorized' &&
						user.invitePendingForRole &&
						renderAbortInvitationToEcom(user)}
					{user.role === 'EcomBuyer' && renderChangeRole(user)}
					{user.role !== 'Admin' && renderRemoveUser()}
					{user.role !== 'Admin' && renderRemoveUserConfirmation(user)}
				</>
			)}
		</AccountLayoutContainer>
	);
}
AccountJulaProUserDetails.displayName = 'AccountJulaProUserDetails';
