import { createMachine } from 'xstate';
import { type InterpreterFrom } from 'xstate/lib/types';

export interface GlobalFetchLockMachineGlobalEvents {
	lock: undefined;
	unlock: undefined;
}

interface LockEvent {
	type: 'LOCK';
}
interface UnlockEvent {
	type: 'UNLOCK';
}

type Events = LockEvent | UnlockEvent;

export const globalFetchLockMachine = createMachine(
	{
		id: 'globalFetchLock',
		initial: 'locked',
		context: {},
		schema: {} as {
			events: Events;
		},
		tsTypes: {} as import('./globalFetchLock.machine.typegen').Typegen0,
		states: {
			locked: {
				invoke: { src: 'listenForWindowEvents' },
				on: {
					UNLOCK: 'unlocked',
				},
			},
			unlocked: {
				invoke: { src: 'listenForWindowEvents' },
				on: {
					LOCK: 'locked',
				},
			},
		},
	},
	{
		services: {
			listenForWindowEvents: () => (send) => {
				const lock = () => {
					send('LOCK');
				};
				const unlock = () => {
					send('UNLOCK');
				};

				if (typeof addEventListener !== 'undefined') {
					globalThis.addEventListener('lock', lock);
					globalThis.addEventListener('unlock', unlock);
				}
				return () => {
					globalThis.removeEventListener('lock', lock);
					globalThis.removeEventListener('unlock', unlock);
				};
			},
		},
	},
);

export type GlobalFetchLockInterpreter = InterpreterFrom<
	typeof globalFetchLockMachine
>;
