/**
 * Rating
 */

import React, { type MouseEventHandler } from 'react';

import type { IconSize } from 'components/Icon';
import Icon from 'components/Icon';
import LinkOrButton from 'components/LinkOrButton';
import { cn } from 'utils/classNames';
import { formatNumber } from 'utils/format';
import { is, range } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	/** Allow any score or count text to wrap below the stars */
	allowWrapping?: boolean;

	/** Container class names */
	className?: string;

	/** If the container should be inline or block */
	displayMode?: 'block' | 'inline';

	/** is the score visible */
	hasVisibleScore?: boolean;

	/** Link to reviews */
	href?: string;

	/** Click handler, will result in a wrapping button if `href` isn't set */
	onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;

	/** Number of reviews */
	reviewCount?: number | string;

	/** Style of the review count text */
	reviewCountVariant?: 'fullText' | 'numberOnly' | 'hidden';

	/** Number of stars */
	score?: number;

	/** Stars and count text size */
	size?: 'small' | 'medium' | 'large';
}

// Style for each decimal point, e.g. 4.2 will clip its last star 65% from the
// right edge. Doesn't use steps of 10 since the star shape doesn't fill the
// icon box edge to edge.
const PARTIAL_STAR_INSETS: Record<number, string> = {
	1: '[clip-path:inset(0_70%_0_0)]',
	2: '[clip-path:inset(0_65%_0_0)]',
	3: '[clip-path:inset(0_60%_0_0)]',
	4: '[clip-path:inset(0_55%_0_0)]',
	5: '[clip-path:inset(0_50%_0_0)]',
	6: '[clip-path:inset(0_45%_0_0)]',
	7: '[clip-path:inset(0_40%_0_0)]',
	8: '[clip-path:inset(0_35%_0_0)]',
	9: '[clip-path:inset(0_30%_0_0)]',
} as const;

/** Star rating out of five and a text with the total number of reviews. */
export default function Rating({
	allowWrapping = true,
	className = '',
	displayMode = 'inline',
	hasVisibleScore = false,
	href,
	onClick,
	reviewCount = 0,
	reviewCountVariant = 'numberOnly',
	score = 0,
	size = 'medium',
}: Props) {
	const { t } = useI18n();
	const formattedScore = formatNumber(score);
	// E.g. 4.2 (minus floor) → 0.200000018 (toFixed) → "0.2" (get index 2) → 2
	const scoreDecimal = Number((score - Math.floor(score)).toFixed(1)[2]);

	const starTypes = range(5).map((i) =>
		i < Math.floor(score) ? 'full' : i < score ? 'partial' : 'empty',
	);
	const starSizes: Record<typeof size, IconSize> = {
		small: 16,
		medium: 24,
		large: 40,
	};
	const formattedReviewCount = is.number(reviewCount)
		? formatNumber(reviewCount)
		: reviewCount;

	const fullReviewCountText = `${formattedReviewCount} ${
		reviewCount === 1 || reviewCount === '1'
			? t('product_details_review_rating_text')
			: t('product_details_review_ratings_text')
	}`;
	const starCountText = t('product_review_stars_screen_reader_text', {
		// Use the actual score even if it's not visually an exact match.
		starCount: formattedScore,
	});
	const isInteractive = Boolean(href || onClick);

	const content = (
		<>
			<span
				className="inline-flex items-start"
				role="img"
				aria-label={
					reviewCountVariant === 'hidden'
						? starCountText
						: `${starCountText}, ${fullReviewCountText}`
				}
			>
				{starTypes.map((starType, i) => {
					// Will not be sorted and there is no meaningful key.
					/* eslint-disable react/no-array-index-key */
					const mainStar = (
						<Icon
							key={i}
							name={starType === 'full' ? 'star' : 'starHollow'}
							size={starSizes[size]}
							className={starType === 'partial' ? 'align-top' : undefined}
						/>
					);
					if (starType === 'partial') {
						return (
							<span key={i} className="relative">
								{mainStar}
								<Icon
									name="star"
									size={starSizes[size]}
									className={cn(
										'absolute inset-0',
										PARTIAL_STAR_INSETS[scoreDecimal],
									)}
								/>
							</span>
						);
					}
					return mainStar;
				})}
			</span>
			{hasVisibleScore && (
				<span
					aria-hidden
					className={cn(
						'font-bold',
						// Right padding for visual balance with the whitespace inherit in the star icons.
						reviewCountVariant === 'hidden' && 'pr-0.5',
					)}
				>
					{formattedScore}
				</span>
			)}
			{reviewCountVariant !== 'hidden' && (
				<span
					// This text is always part of the stars' alt text.
					aria-hidden="true"
					className={cn(
						// Right padding for visual balance with the whitespace inherit in the star icons.
						'text-nowrap pr-0.5',
						isInteractive && [
							reviewCountVariant === 'fullText' &&
								'underline group-hover/rating:no-underline',
							reviewCountVariant === 'numberOnly' &&
								'group-hover/rating:underline',
						],
					)}
				>
					{reviewCountVariant === 'numberOnly'
						? `(${formattedReviewCount})`
						: fullReviewCountText}
				</span>
			)}
		</>
	);

	const containerClasses = cn(
		className,
		displayMode === 'block' ? 'flex' : 'inline-flex',
		'items-center align-top font-standard',
		allowWrapping && 'flex-wrap',
		size === 'small' && 'gap-x-1.5 text-xs',
		size === 'medium' && 'gap-x-2 text-base',
		size === 'large' && 'gap-x-2.5 text-lg',
		isInteractive && 'group/rating',
	);

	if (href || onClick) {
		return (
			<LinkOrButton href={href} onClick={onClick} className={containerClasses}>
				{content}
			</LinkOrButton>
		);
	}

	return <span className={containerClasses}>{content}</span>;
}
Rating.displayName = 'Rating';
