import { empty } from 'utils/helpers';

import { DeliveryMachineState } from './delivery.machine';

export const selectAvailableDeliveryMethods = (state: DeliveryMachineState) =>
	state.context.availableDeliveryMethods ?? empty.array;

export const selectSelectedDeliveryMethod = (state: DeliveryMachineState) =>
	state.context.selectedDelivery;

export const selectErrors = (state: DeliveryMachineState) =>
	state.context.errors ?? empty.array;
