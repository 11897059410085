/**
 * StoreSelectorButton
 */

import React from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import {
	useFeatureToggle,
	useSelectedStore,
	useSelectedStoreInfo,
} from 'contexts';
import type { HTMLTagName } from 'types';
import { cnm } from 'utils/classNames';
import { getTestDataAttrFrom } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	buttonClassName?: string;
	className?: string;
	container?: HTMLTagName;
	dataCy?: string;
	onClick: () => void;
}

/** Button for the global 'home store' selector in the header */
export default function GlobalStoreSelectorButton({
	buttonClassName,
	className,
	container: Container = 'div',
	dataCy,
	onClick,
}: Props) {
	const { t } = useI18n();
	const { selectedStore } = useSelectedStore();
	const { isLoading, storeInfo } = useSelectedStoreInfo();

	const { storesEnabled } = useFeatureToggle();
	if (!storesEnabled) {
		return null;
	}

	const buttonText = selectedStore ? (
		<>
			<span className="font-bold">{selectedStore?.name}</span>
			{storeInfo?.todaysOpeningHours?.description && (
				<>: {storeInfo?.todaysOpeningHours?.description}</>
			)}
		</>
	) : (
		t('select_store_button_text')
	);

	return (
		<Container className={className}>
			{isLoading && (
				<Skeleton
					// Y padding to match text button.
					className={cnm('inline-block py-1 align-middle', buttonClassName)}
				>
					<SkeletonItem height="1.5rem" width="8rem" />
				</Skeleton>
			)}
			{!isLoading && (
				<Button
					variant="text"
					onClick={onClick}
					aria-haspopup="dialog"
					size="small"
					underline={false}
					className={cnm(
						'text-left align-middle hover:underline sm:text-base',
						buttonClassName,
					)}
					data-cy={getTestDataAttrFrom(dataCy)}
				>
					<Icon name="store" />
					{buttonText}
				</Button>
			)}
		</Container>
	);
}
GlobalStoreSelectorButton.displayName = 'GlobalStoreSelectorButton';
