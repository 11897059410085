import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlockMedia from 'components/BlockMedia';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import type { ColorField, JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import type { ProductCard } from 'models/productCard';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset, is } from 'utils/helpers';

interface Item {
	landscapeImage: Field<DigizuiteAsset[]>;
	portraitImage: Field<DigizuiteAsset[]>;
	portraitVideo?: Field<DigizuiteAsset[]>;
	preventAutoplay?: Field<boolean>;
	products?: ProductCard[];
	video?: Field<DigizuiteAsset[]>;
}

type Props = JulaComponentProps & {
	fields?: {
		backgroundColor?: ColorField;
		datasourceName?: string;
		items?: Item[];
	};
};

export default function MediaDoubleWide({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const items = fields.items
		?.map((item) => ({
			landscapeImage: getAsset('Templated 2:1', item.landscapeImage),
			landscapeVideo: getAsset('Source Copy', item.video),
			portraitImage: getAsset('Templated 3:4', item.portraitImage),
			portraitVideo: getAsset('Source Copy', item.portraitVideo),
			preventAutoplay: Boolean(item.preventAutoplay?.value),
			products: item.products,
		}))
		.filter(
			(item) =>
				(item.portraitImage ?? item.portraitVideo) &&
				(item.landscapeImage ?? item.landscapeVideo),
		);

	if (!is.arrayWithLength(items) || items.length < 2) {
		return (
			<ComponentPlaceholder
				componentName="MediaDoubleWide"
				description="Requires two items with content (image or video) for both mobile and desktop"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			withGrid
			backgroundColor={fields.backgroundColor?.fields?.colorHex?.value}
		>
			{items.map(
				({
					landscapeImage,
					landscapeVideo,
					portraitImage,
					portraitVideo,
					preventAutoplay,
					products,
				}) => (
					<React.Fragment key={`${landscapeImage?.src}-${landscapeVideo?.src}`}>
						<BlockMedia
							className="col-span-6 max-md:hidden"
							variant="landscapeWide"
							imgSrc={landscapeImage?.src}
							imgAlt={landscapeImage?.alt}
							preventAutoplay={preventAutoplay}
							video={landscapeVideo}
							products={products}
							productsDisplayType="shopByMedia"
							gtmItemListId="block"
							gtmItemListName={`Block: ${fields.datasourceName ?? 'MediaDoubleWideUnknown'}`}
						/>
						<BlockMedia
							className="col-span-2 md:hidden"
							variant="portrait"
							imgSrc={portraitImage?.src}
							imgAlt={portraitImage?.alt}
							video={portraitVideo}
							preventAutoplay={preventAutoplay}
							products={products}
							productsDisplayType="shopByMedia"
							gtmItemListId="block"
							gtmItemListName={`Block: ${fields.datasourceName ?? 'MediaDoubleWideUnknown'}`}
						/>
					</React.Fragment>
				),
			)}
		</LayoutContainer>
	);
}
MediaDoubleWide.displayName = 'MediaDoubleWide';
