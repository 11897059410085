import React from 'react';

import BonusChequeQrCode from 'components/BonusChequeQrCode';
import InfoBox from 'components/InfoBox';
import { AccountLayoutContainer } from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import Placeholder from 'components/Placeholder';
import { useBonusChecks, useCustomerInformation, useIsEditing } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps;

export default function AccountCoupons({ rendering }: Props) {
	const { t } = useI18n();
	const isEditing = useIsEditing();
	const {
		customerInformation,
		error: errorCustomerInformation,
		isLoading: isLoadingCustomerInformation,
	} = useCustomerInformation();
	const { fullName, id } = customerInformation ?? {};
	const {
		bonusChecks,
		error: errorBonusChecks,
		isLoading: isLoadingBonusChecks,
	} = useBonusChecks();

	const isLoading = isLoadingCustomerInformation || isLoadingBonusChecks;
	const error = errorCustomerInformation || errorBonusChecks;
	const loadedAndOk = !isLoading && !error;

	if (isEditing) {
		return (
			<AccountLayoutContainer
				heading={`${t('account_coupons_heading')}: Exp editor placeholder`}
				rendering={rendering}
			>
				<Placeholder
					rendering={rendering}
					name="jula-account-details"
					isInNestedPlaceholder
				/>
			</AccountLayoutContainer>
		);
	}
	return (
		<AccountLayoutContainer
			rendering={rendering}
			heading={t('account_coupons_heading')}
		>
			{loadedAndOk && (
				<div className="space-y-6">
					{bonusChecks?.length
						? bonusChecks.map((check, i) => (
								<BonusChequeQrCode
									key={check.checkNumber || i}
									number={check.checkNumber || '—'}
									truncateNumber={false}
									validThru={check.validTo}
									amount={
										check.amountWithCurrencySymbol ||
										String(check.amount) ||
										'0'
									}
									customerNo={id || '—'}
									customerFullName={fullName || ''}
								/>
							))
						: t('account_coupons_no_coupons_text')}
				</div>
			)}
			{isLoading && <LoadingSpinner className="mt-8" />}
			{error && (
				<InfoBox
					icon="error"
					variant="error"
					message={t('account_generic_fetch_error_text')}
					className="mt-4"
				/>
			)}
			<Placeholder
				rendering={rendering}
				name="jula-account-details"
				isInNestedPlaceholder
			/>
		</AccountLayoutContainer>
	);
}
AccountCoupons.displayName = 'AccountCoupons';
