/**
 * OpeningHoursTable
 */

import React from 'react';

import { cn } from 'utils/classNames';

export interface Row {
	description: string;
	key: string;
	title: string;
}

interface Props {
	caption?: string;
	className?: string;
	rows: Row[];
}

export default function OpeningHoursTable({ caption, className, rows }: Props) {
	return (
		<table className={cn('w-full text-sm', className)}>
			{caption && (
				<caption className="mb-2 text-left font-bold">{caption}</caption>
			)}
			<tbody>
				{rows.map((row) => (
					<tr key={row.key}>
						<th scope="row" className="text-left font-normal">
							{row.title}
						</th>
						<td className="text-right">{row.description}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}
OpeningHoursTable.displayName = 'OpeningHoursTable';
