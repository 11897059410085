/**
 * Collection helpers (object/array/Map/Set etc.)
 */

import type { Falsy } from 'types';

import { is } from './helpers';

/**
 * Create an array without duplicate values.
 *
 * @example
 *
 * arrayUnique([1, 1, 2, 3]);
 * // => [1, 2, 3]
 *
 * // Only works on primitives, object equality is based on reference.
 * arrayUnique([{ num: 1 }, { num: 1 }]);
 * // => [{ num: 1 }, { num: 1 }]
 */
export function arrayUnique<T>(arr: T[]): T[] {
	return [...new Set(arr)];
}

/**
 * Filter an array of objects based on properties.
 *
 * @example
 *
 * arrayUniqueBy([{ name: 'John' }, { name: 'Jane' }, { name: 'John' }], 'name');
 * // => [{ name: 'John' }, { name: 'Jane' }]
 */
export function arrayUniqueBy<
	T extends Record<string, unknown>,
	K extends keyof T,
>(objects: T[], ...props: [K, ...K[]]): T[] {
	return objects.filter(
		(val, i, arr) =>
			i === arr.findIndex((obj) => props.every((key) => obj[key] === val[key])),
	);
}

/**
 * Split an array into groups.
 *
 * @example
 *
 * chunkArray([{ title: 'One' }, { title: 'Two' }, { title: 'Three' }], 2);
 * // => [[{ title: 'One' }, { title: 'Two' }], [{ title: 'Three' }]]
 */
export function chunkArray<T>(arr: T[], chunkSize: number): T[][] {
	const result: T[][] = [];
	for (let i = 0, len = arr.length; i < len; i += chunkSize) {
		result.push(arr.slice(i, i + chunkSize));
	}
	return result;
}

/** `Object.keys` wrapper that returns a union of string keys rather than `string[]` */
export function objectKeys<T extends object, K extends keyof T>(obj: T) {
	// There are reasons for the native type to be aware of:
	// - https://stackoverflow.com/a/55012175
	// - https://github.com/Microsoft/TypeScript/pull/30228#issuecomment-469918371
	// - https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208
	// Force strings and exclude symbols to match actual behavior.
	// "An array of a given object's own enumerable string-keyed property names."
	// const sym = Symbol('symbol-key');
	// Object.keys({ 1: 0, '2': 0, [sym]: 0 })
	// => ['1', '2']
	return Object.keys(obj) as `${K extends symbol ? never : K}`[];
}

/** `Object.entries` wrapper that returns typed key-value pairs */
export function objectEntries<T extends object, K extends keyof T>(obj: T) {
	// There are reasons for the native types to be aware of:
	// - https://stackoverflow.com/a/55012175
	// - https://github.com/Microsoft/TypeScript/pull/30228#issuecomment-469918371
	// - https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208
	// Force strings and exclude symbols to match actual behavior.
	// "An array of a given object's own enumerable string-keyed property
	// key-value pairs."
	// const sym = Symbol('symbol-key');
	// Object.entries({ 1: 0, '2': null, [sym]: 'val' })
	// => [['1', 0], ['2', null]]
	return Object.entries(obj) as {
		[Key in keyof T]-?: [`${Key extends symbol ? never : Key}`, T[Key]];
	}[K extends symbol ? never : K][];
}

type ObjectIteratee<T extends object, K extends keyof T, R> = (
	val: T[keyof T],
	key: `${K extends symbol ? never : K}`,
	obj: T,
) => R;

/**
 * Filter an object's properties based on the return value of the passed
 * filter function.
 *
 * @example
 *
 * filterObject({ 'a': 1, 'b': 2, 'c': 3, 'd': 4 }, (val) => val % 2 === 0);
 * // => { 'b': 2, 'd': 4 }
 */
export function filterObject<T extends object, R extends Partial<T>>(
	obj: T,
	filter: ObjectIteratee<T, keyof T, boolean>,
): R {
	const filteredObj = {} as R;
	objectEntries(obj).forEach(([key, val]) => {
		if (filter(val, key, obj)) {
			filteredObj[key as string] = val;
		}
	});
	return filteredObj;
}

/**
 * Create an object with the same values as `obj` and keys generated
 * by running each property through `iteratee`.
 *
 * @example
 *
 * mapKeys({ 'a': 1, 'b': 2 }, (val, key) => `${key}${val}`);
 * // => { 'a1': 1, 'b2': 2 }
 */
export function mapKeys<T extends object, R extends string>(
	obj: T,
	iteratee: ObjectIteratee<T, keyof T, R>,
): Record<R, T[keyof T]> {
	const result = {} as Record<R, T[keyof T]>;
	objectKeys(obj).forEach((key) => {
		result[iteratee(obj[key as keyof T], key, obj)] = obj[key as keyof T];
	});
	return result;
}

/**
 * Create an object with the same keys as `obj` and values generated
 * by running each property through `iteratee`.
 *
 * @example
 *
 * mapValues({ 'a': 1, 'b': 2 }, (val) => val * 2);
 * // => { 'a': 2, 'b': 4 }
 */
export function mapValues<T extends object, R>(
	obj: T,
	iteratee: ObjectIteratee<T, keyof T, R>,
): Record<keyof T, R> {
	const result = {} as Record<keyof T, R>;
	objectKeys(obj).forEach((key) => {
		result[key as keyof T] = iteratee(obj[key as keyof T], key, obj);
	});
	return result;
}

/**
 * Filter an array of objects for truthy properties.
 *
 * @example
 *
 * const items = [{a: 1, b: 2}, {a: 3, b: null}];
 * filterTruthy(items, 'b');
 * // => [{a: 1, b: 2}]
 */
export function filterTruthy<
	T extends object,
	K extends keyof T,
	FilteredT extends T & { [k in K]-?: Exclude<T[K], Falsy> },
>(arr: T[] | undefined | null, ...props: [K, ...K[]]): FilteredT[] {
	return (arr ?? []).filter((obj): obj is FilteredT =>
		props.every((key) => is.truthy(obj[key])),
	);
}

/**
 * Get the passed object if all specified properties are truthy,
 * otherwise undefined.
 *
 * @example
 *
 * interface Link {
 *   href?: string;
 *   text?: string;
 * }
 * const link1: Link = { href: '#', text: 'Hash' };
 * const link2: Link = { href: '', text: 'No href' }; // href can also be omitted
 *
 * withTruthyProps(link1, 'href', 'text');
 * // => { href: '#', text: 'Hash' }
 *
 * withTruthyProps(link2, 'href', 'text');
 * // => undefined
 */
export function withTruthyProps<
	T extends object,
	K extends keyof T,
	FilteredT extends T & { [k in K]-?: Exclude<T[K], Falsy> },
>(obj: T | undefined | null, ...props: [K, ...K[]]): FilteredT | undefined {
	if (!obj) {
		return undefined;
	}
	for (const prop of props) {
		if (is.falsy(obj[prop])) {
			return undefined;
		}
	}

	return obj as unknown as FilteredT;
}

/**
 * Check if all the specified properties on an object are not null or undefined.
 *
 * @example
 *
 * interface Link {
 *   href?: string;
 *   text?: string;
 * }
 * const link: Link = {...};
 * if (hasProp(link, 'href', 'text')) {
 *   // href and text will both be defined, though possibly empty.
 * }
 */
export function hasProps<T extends object, K extends keyof T>(
	obj: T | undefined | null,
	...props: [K, ...K[]]
): obj is T & { [k in K]-?: NonNullable<T[K]> } {
	if (!obj) {
		return false;
	}
	for (const prop of props) {
		if (is.nullish(obj[prop])) {
			return false;
		}
	}

	return true;
}

/**
 * Check if all the specified properties on an object are defined and truthy.
 *
 * @example
 *
 * interface Link {
 *   href?: string;
 *   text?: string;
 * }
 * const link: Link = {...};
 * if (hasTruthyProps(link, 'href', 'text')) {
 *   // href and text will both be non-empty strings.
 * }
 */
export function hasTruthyProps<T extends object, K extends keyof T>(
	obj: T | undefined | null,
	...props: [K, ...K[]]
): obj is T & { [k in K]-?: Exclude<T[K], Falsy> } {
	if (!obj) {
		return false;
	}
	for (const prop of props) {
		if (is.falsy(obj[prop])) {
			return false;
		}
	}

	return true;
}

/**
 * Get the previous or next array index, looping around if needed.
 *
 * Out of bounds indices, like -1 from an Array.findIndex, are set to the first
 * or last index for next and prev direction respectively.
 *
 * @example
 * const arr = ['one', 'two', 'three', 'four'];
 *
 * findNewIndex(arr, 0, 'next');
 * // => 1
 *
 * findNewIndex(arr, 0, 'prev');
 * // => 3
 *
 * findNewIndex(arr, 3, 'next');
 * // => 0
 */
export function findNewIndex(
	list: unknown[],
	currentIndex: number,
	direction: 'next' | 'prev',
): number {
	const size = list.length;
	const isOutOfBounds = currentIndex < 0 || currentIndex > size - 1;
	if (direction === 'prev') {
		return currentIndex === 0 || isOutOfBounds ? size - 1 : currentIndex - 1;
	}
	return currentIndex === size - 1 || isOutOfBounds ? 0 : currentIndex + 1;
}

/**
 * Get the previous or next object key, looping around if needed.
 *
 * Non-existent keys are set to the first or last one for next and prev
 * direction respectively.
 *
 * @example
 * const obj = { one: 1, two: 2, three: 3 };
 *
 * findNewKey(obj, 'one', 'next');
 * // => 'two'
 *
 * findNewKey(obj, 'one', 'prev');
 * // => 'three'
 *
 * findNewKey(obj, 'three', 'next');
 * // => 'one'
 */
export function findNewKey<
	T extends Record<string, unknown>,
	KeyT extends keyof T,
>(obj: T, currentKey: KeyT, direction: 'next' | 'prev'): keyof T {
	const keys = Object.keys(obj);
	// `keyof T` includes number and symbol but object keys are always strings.
	// On an object like `{ 1: 'one' }`, indexOf(1) will not find the key.
	const currentIndex = keys.indexOf(String(currentKey));
	const newIndex = findNewIndex(keys, currentIndex, direction);
	return keys[newIndex] as keyof T;
}

/**
 * Group a list of objects by the specified key.
 *
 * @example
 *
 * groupBy([
 *   { animal: 'Cat', name: 'Tom' },
 *   { animal: 'Dog', name: 'Pluto' },
 *   { animal: 'Cat', name: 'Garfield' },
 * ], 'animal');
 * // => Map {
 * //   'Cat' → [{ animal: 'Cat', name: 'Tom' }, { animal: 'Cat', name: 'Garfield' }],
 * //   'Dog' → [{ animal: 'Dog', name: 'Pluto' }],
 * // }
 */
export function groupBy<T extends object, KeyT extends keyof T>(
	items: T[],
	key: KeyT,
): Map<T[KeyT], T[]> {
	// Using Map instead of plain object to have a guaranteed key order.
	// Can be converted to an object with `Object.fromEntries(result.entries())`.
	const result = new Map<T[KeyT], T[]>();
	for (const item of items) {
		const group = item[key];
		if (!result.has(group)) {
			result.set(group, []);
		}
		result.get(group)!.push(item);
	}
	return result;
}

/**
 * A set of key-value tuples. Tries to mimic the API of native Set/Map.
 *
 * Also very similar to URLSearchParams, with the biggest difference being that
 * this doesn't allow duplicates. The value parameter to URLSearchParams' has()
 * and delete() is also very new as of autumn 2023.
 */
export class TupleSet<
	KeyT,
	ValueT,
	ItemT extends [KeyT, ValueT] = [KeyT, ValueT],
> {
	private data: ItemT[] = [];

	/** Create a new TupleSet with initial tuples or from an existing TupleSet. */
	constructor(...initialItems: ItemT[] | TupleSet<KeyT, ValueT, ItemT>[]) {
		initialItems.forEach((item: (typeof initialItems)[0]) => {
			if (item instanceof TupleSet) {
				this.add(...item.items);
			} else {
				this.add(item);
			}
		});
	}

	// In addition to entries(), to avoid having to convert the iterator back to
	// an array, e.g. for a .map().
	/** Get all tuples in an array. */
	get items() {
		return this.data;
	}

	/** Get the number of tuples. */
	get size() {
		return this.data.length;
	}

	[Symbol.iterator]() {
		return this.entries();
	}

	/** Add new tuples if they don't already exist. */
	add(...items: ItemT[]) {
		items.forEach((item) => {
			if (!this.has(item[0], item[1])) {
				this.data.push(item);
			}
		});
		// Make chaining possible like Set.add/Map.set, even though it's
		// unnecessary here thanks to rest args.
		return this;
	}

	/** Remove all tuples. */
	clear() {
		this.data = [];
	}

	/** Remove the specified tuple, or all tuples with the specified key. */
	delete(key: KeyT, value?: ValueT) {
		if (!this.has(key, value)) {
			return false;
		}
		this.data = this.data.filter(([itemKey, itemValue]) =>
			is.defined(value)
				? itemKey !== key || itemValue !== value
				: itemKey !== key,
		);
		return true;
	}

	/** Get an iterator object for all tuples. */
	entries() {
		return this.data.values();
	}

	/** Run a callback for each tuple. */
	forEach(
		callbackfn: (item: ItemT, index: number, set: this) => void,
		thisArg?: unknown,
	) {
		this.data.forEach((item, index) => {
			callbackfn(item, index, this);
			// eslint-disable-next-line unicorn/no-array-method-this-argument
		}, thisArg);
	}

	/** Check if the specified tuple exists, or any tuple with the specified key. */
	has(key: KeyT, value?: ValueT) {
		return this.data.some(([itemKey, itemValue]) =>
			is.defined(value)
				? key === itemKey && value === itemValue
				: key === itemKey,
		);
	}

	/** Get the first value for the specified key, or undefined. */
	get(key: KeyT) {
		return this.data.find(([k]) => k === key)?.[1];
	}

	/** Get all values for the specified key. */
	getAll(key: KeyT) {
		return this.data.filter(([k]) => k === key).map(([, val]) => val);
	}

	/** Get an array of the first value in every tuple. */
	keys() {
		return this.data.map(([key]) => key);
	}

	/** Add or update the specified key-value. */
	set(key: KeyT, value: ValueT) {
		this.delete(key);
		this.add([key, value] as ItemT);
	}

	/** Sort the tuples in place, first by key then by value. */
	sort() {
		this.data.sort(([keyA, valueA], [keyB, valueB]) => {
			if (keyA === keyB) {
				return String(valueA).localeCompare(String(valueB));
			}
			return String(keyA).localeCompare(String(keyB));
		});
		return this;
	}

	/** Get a cloned set with the tuples sorted, first by key then by value. */
	toSorted() {
		return new TupleSet(this).sort();
	}

	private queryStringEncode(val: KeyT | ValueT) {
		return encodeURIComponent(String(val));
	}

	/** Get a query string from all tuples. */
	toQueryString() {
		return this.data
			.map(
				([key, value]) =>
					`${this.queryStringEncode(key)}=${this.queryStringEncode(value)}`,
			)
			.join('&');
	}

	/** Get an array of the second value in every tuple. */
	values() {
		return this.data.map(([, value]) => value);
	}
}
