import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import Link from 'components/Link';
import TopBannerComponent from 'components/TopBanner';
import type { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import type { SitecoreLink } from 'models/sitecore';
import { getAsset } from 'utils/helpers';

interface ColorField {
	displayName: string;
	fields?: {
		colorHex?: Field<string>;
	};
	id: string;
	name: string;
}

type Props = JulaComponentProps & {
	fields?: {
		color?: ColorField;
		heading?: Field<string>;
		link?: Field<SitecoreLink>;
		primaryImageDesktop?: Field<DigizuiteAsset[]>;
		primaryImageMobile?: Field<DigizuiteAsset[]>;
		secondaryImageDesktop?: Field<DigizuiteAsset[]>;
		secondaryImageMobile?: Field<DigizuiteAsset[]>;
		textColor?: ColorField;
	};
};

export default function TopBanner({ fields }: Props) {
	if (!fields) {
		return null;
	}
	const { href, target, text } = fields?.link?.value ?? {};
	const bgColor = fields.color?.fields?.colorHex?.value;
	const textColor = fields.textColor?.fields?.colorHex?.value || '#fff';

	const primaryImageDesktop = getAsset(
		'Source Copy',
		fields.primaryImageDesktop,
	);
	const primaryImageMobile = getAsset('Source Copy', fields.primaryImageMobile);
	const secondaryImageDesktop = getAsset(
		'Source Copy',
		fields.secondaryImageDesktop,
	);
	const secondaryImageMobile = getAsset(
		'Source Copy',
		fields.secondaryImageMobile,
	);

	return (
		<TopBannerComponent
			backgroundColor={bgColor}
			textColor={textColor}
			leftImageLargeSrc={primaryImageDesktop?.src}
			rightImageLargeSrc={secondaryImageDesktop?.src}
			leftImageSmallSrc={primaryImageMobile?.src}
			rightImageSmallSrc={secondaryImageMobile?.src}
		>
			{fields.heading?.value}
			{text && ' '}
			{href && (
				<Link href={href} target={target} underline>
					{text}
				</Link>
			)}
		</TopBannerComponent>
	);
}
TopBanner.displayName = 'TopBanner_JSS';
