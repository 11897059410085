import React, { useEffect, useRef, useState } from 'react';

import Accordion from 'components/Accordion';
import Button from 'components/Button';
import { PickupLocationMap } from 'components/Map';
import PopoverBase, {
	POPOVER_SCROLL_LOCK_WHITELIST_CLASS,
} from 'components/Popover';
import RadioListRow from 'components/RadioListRow';
import type { DeliveryPickup } from 'models/api';
import { filterTruthy } from 'utils/collection';
import { scrollIntoViewIfNeeded } from 'utils/dom';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface PickupLocation {
	id: string;
	latitude: number;
	longitude: number;
	name: string;
	openingHours?: { col1: string; col2: string }[];
	pickupLocation: string;
}

interface PopoverProps {
	isOpen: boolean;
	locations: PickupLocation[];
	onClose: () => void;
	onSelectCallback: (locationId: string) => void;
	selectedPickupLocationId?: string;
}

export function Popover({
	isOpen,
	locations,
	onClose,
	onSelectCallback,
	selectedPickupLocationId,
}: PopoverProps) {
	const { t } = useI18n();
	const radioListRowRef = useRef<(HTMLLIElement | null)[]>([]);
	const [selectedLocation, setSelectedLocation] = useState(
		selectedPickupLocationId ?? '',
	);

	useEffect(() => {
		if (selectedPickupLocationId) {
			setSelectedLocation(selectedPickupLocationId);
		}
	}, [selectedPickupLocationId]);

	useEffect(() => {
		const rowElement = radioListRowRef.current[selectedLocation];
		if (rowElement) {
			scrollIntoViewIfNeeded(rowElement);
		}
	}, [isOpen, selectedLocation]);

	const onSelectLocation = (locationId: string) => {
		setSelectedLocation(locationId);
	};

	return (
		<PopoverBase
			title={t('pickup_location_popover_title')}
			variant="sidePanelWide"
			padContent={false}
			fullSizeSmall
			isOpen={isOpen}
			onClose={onClose}
			contentClassName="flex flex-col min-h-0 overflow-y-visible pl-4 sm:pl-6"
			padFooter
			footer={
				<Button
					className="mx-auto max-w-col-3"
					displayWidth="full"
					size="large"
					variant="cta"
					onClick={() => {
						onSelectCallback(selectedLocation);
					}}
				>
					{t('pickup_location_popover_select_location_button')}
				</Button>
			}
		>
			<div className="min-h-0 grow gap-x-6 max-sm:flex max-sm:flex-col sm:grid sm:grid-cols-2">
				{/* Alignment and background color for loading state */}
				<div className="flex grow items-center justify-center bg-greyLighter max-sm:mr-4 max-sm:h-[25vh] max-sm:shrink-0">
					<PickupLocationMap
						className="h-full"
						mapClassName="h-full"
						locations={locations}
						selectedPickupId={selectedLocation}
						onMarkerClick={onSelectLocation}
					/>
				</div>
				<div
					className={`h-full overflow-y-auto max-sm:mt-2 ${POPOVER_SCROLL_LOCK_WHITELIST_CLASS}`}
				>
					<ul className="pr-6">
						{locations.map(({ id, name, openingHours, pickupLocation }) => (
							<RadioListRow
								key={id}
								className="last:border-b-0"
								ref={(element) => {
									if (element) {
										radioListRowRef.current[id] = element;
									}
								}}
								label={name}
								name="location"
								checked={selectedLocation === id}
								value={id}
								onChange={() => {
									onSelectLocation(id);
								}}
							>
								<p>{pickupLocation}</p>
								{is.arrayWithLength(openingHours) && (
									<Accordion
										titleTag="p"
										title={t(
											'pickup_location_popover_location_opening_hours_title',
										)}
										size="small"
										color="white"
									>
										{openingHours.map((openingHour) => {
											if (!openingHour.col2) {
												return <p key={openingHour.col1}>{openingHour.col1}</p>;
											}
											return (
												<dl
													key={openingHour.col1}
													className="flex justify-between text-sm"
												>
													<dt>{openingHour.col1}</dt>
													<dd>{openingHour.col2}</dd>
												</dl>
											);
										})}
									</Accordion>
								)}
							</RadioListRow>
						))}
					</ul>
				</div>
			</div>
		</PopoverBase>
	);
}

Popover.displayName = 'Delivery_PickupLocationPopover_Popover';

interface Props {
	className?: string;
	onSelectCallback: (locationId: string) => void;
	pickupLocations: DeliveryPickup[];
	selectedPickupLocationId?: string;
}

export default function PickupLocationPopover({
	className,
	onSelectCallback,
	pickupLocations,
	selectedPickupLocationId,
}: Props) {
	const { t } = useI18n();
	const [isOpen, setIsOpen] = useState(false);
	const locations = filterTruthy(
		pickupLocations.map((location) => ({
			longitude: location.location?.longitude,
			latitude: location.location?.latitude,
			name: location.name,
			id: location.id,
			openingHours: location.openingHours,
			pickupLocation: location.pickupLocation,
		})),
		'latitude',
		'longitude',
		'pickupLocation',
		'name',
		'id',
	);
	if (!is.arrayWithLength(locations)) {
		return null;
	}
	return (
		<>
			<Button
				className={className}
				variant="text"
				onClick={() => setIsOpen(true)}
			>
				{t('pickup_location_popover_see_location_on_map_button')}
			</Button>
			<Popover
				locations={locations}
				isOpen={isOpen}
				selectedPickupLocationId={selectedPickupLocationId}
				onClose={() => setIsOpen(false)}
				onSelectCallback={(locationId) => {
					onSelectCallback(locationId);
					setIsOpen(false);
				}}
			/>
		</>
	);
}
PickupLocationPopover.displayName = 'Delivery_PickupLocationPopover';
