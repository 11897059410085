/**
 * AvailableCreditGauge
 */

import React from 'react';

import { LinearGauge } from 'components/Gauge';

interface Props {
	descriptiveText: string;
	unitCount: number;
	unitMax: number;
}

export default function AvailableCreditGauge({
	descriptiveText,
	unitCount,
	unitMax,
}: Props) {
	const percent = Math.round((unitCount / unitMax) * 100);
	return (
		<div className="w-full">
			<p className="mb-2">
				{descriptiveText} <span className="font-bold"> {unitCount}.-</span>
			</p>
			<LinearGauge percent={percent} />
		</div>
	);
}
AvailableCreditGauge.displayName = 'AvailableCreditGauge';
