import React, { useCallback, useEffect, useRef } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import Img from 'components/Img';
import { ImagePopover } from 'components/Popover';
import Tabs from 'components/Tabs';
import ThumbnailList from 'components/ThumbnailList';
import type { ReviewImage, ReviewImageId } from 'hooks/product-details';
import type { Thumbnail } from 'utils/business-logic';
import { cn } from 'utils/classNames';
import { afterNextPaint, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import ReviewImagesView from './ReviewImagesView';

export type ProductAndSelectedImagesTabId = 'product-images' | 'review-images';

interface Props {
	activeTabId: ProductAndSelectedImagesTabId;
	/** An array of product images */
	images: Thumbnail[];
	/** Whether the layover is open or not */
	isOpen: boolean;
	/** Function to call when the layover should be closed */
	onClose: () => void;
	onReviewImagesSlideChange: (slideIndex: number) => void;
	/** Function to call when the layover tab should be changed */
	onTabChange: (newTabId: ProductAndSelectedImagesTabId) => void;
	onThumbnailClick: (imageIndex: number) => void;
	/** An array of images with related review */
	reviewImages: ReviewImage[] | undefined;
	selectedImageIndex: number;
	/** currently selected review image */
	selectedReviewImageId: ReviewImageId | undefined;
}

export default function ProductAndSelectedImagesPopover({
	activeTabId,
	images,
	isOpen,
	onClose: onCloseProp,
	onReviewImagesSlideChange,
	onTabChange: onTabChangeProp,
	onThumbnailClick,
	reviewImages,
	selectedImageIndex,
	selectedReviewImageId,
}: Props) {
	const { t } = useI18n();
	const productImagesScrollRef = useRef<HTMLDivElement>(null);
	const reviewImagesScrollRef = useRef<HTMLDivElement>(null);
	const largeImages = useRef<(HTMLDivElement | null)[]>([]);

	const scrollToImage = useCallback(
		(imageIndex: number) => {
			const img = largeImages.current[imageIndex];
			if (img && isOpen) {
				afterNextPaint(() => {
					img.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				});
				setTimeout(() => {
					img.focus();
				}, 250);
			}
		},
		[isOpen],
	);

	const onOpen = useCallback(() => {
		if (activeTabId === 'product-images') {
			if (productImagesScrollRef.current) {
				disableBodyScroll(productImagesScrollRef.current, {
					reserveScrollBarGap: true,
				});
			}
			scrollToImage(selectedImageIndex);
		}
		if (activeTabId === 'review-images' && reviewImagesScrollRef.current) {
			disableBodyScroll(reviewImagesScrollRef.current, {
				reserveScrollBarGap: true,
			});
		}
	}, [activeTabId, scrollToImage, selectedImageIndex]);

	const onClose = useCallback(() => {
		onCloseProp();
		clearAllBodyScrollLocks();
	}, [onCloseProp]);

	const onTabChange = (tab: ProductAndSelectedImagesTabId) => {
		if (tab === 'review-images' && reviewImagesScrollRef.current) {
			disableBodyScroll(reviewImagesScrollRef.current, {
				reserveScrollBarGap: true,
			});
		}
		if (tab === 'product-images' && productImagesScrollRef.current) {
			disableBodyScroll(productImagesScrollRef.current, {
				reserveScrollBarGap: true,
			});
		}
		onTabChangeProp(tab);
	};

	useEffect(() => {
		scrollToImage(selectedImageIndex);
	}, [scrollToImage, selectedImageIndex]);

	return (
		<ImagePopover
			label={t('product_details_image_layover_title')}
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
		>
			<Tabs<ProductAndSelectedImagesTabId>
				tabListLabel={t('product_details_images_tab_list_label')}
				className="flex h-full flex-col"
				tabListClassName="sm:self-start mx-4 sm:min-w-[42rem]"
				activeTabId={activeTabId}
				onTabChange={onTabChange}
				items={[
					{
						id: 'product-images',
						title: t('product_details_images_tab_title'),
						contentClassName: 'flex justify-center h-full overflow-hidden',
						content: (
							<>
								{images.length > 0 && (
									<ThumbnailList
										direction="column"
										size={56}
										className={cn(
											'mb-5 ml-5 max-h-full shrink-0 self-center max-sm:hidden',
											// Set a max height depending on image count to get two
											// even (or off by one) columns instead of, for example,
											// 11 images in one and a single one next to them.
											// Should probably move to container queries when support
											// is better.
											[7, 8].includes(images.length) &&
												'[@media(max-height:39rem)]:max-h-[16rem]',
											[9, 10].includes(images.length) &&
												'[@media(max-height:47rem)]:max-h-[20rem]',
											[11, 12].includes(images.length) &&
												'[@media(max-height:55rem)]:max-h-[24rem]',
											[13, 14].includes(images.length) &&
												'[@media(max-height:63rem)]:max-h-[28rem]',
											[15, 16].includes(images.length) &&
												'[@media(max-height:71rem)]:max-h-[32rem]',
										)}
										images={images}
										selectedImageIndex={selectedImageIndex}
										onThumbnailClick={onThumbnailClick}
									/>
								)}

								<div className="relative flex h-full grow">
									<div
										ref={productImagesScrollRef}
										className="flex grow flex-col items-center overflow-y-auto"
									>
										{images.map((image, i) => (
											<div
												key={image.src}
												tabIndex={-1}
												ref={(element) => {
													if (element) {
														largeImages.current[i] = element;
													}
												}}
											>
												<Img
													src={image.src}
													alt={image.alt}
													service="nextjs"
													width={792}
													height={792}
												/>
											</div>
										))}
									</div>

									<div className="absolute bottom-0 left-0 right-6 h-24 bg-gradient-to-t from-white" />
								</div>
							</>
						),
					},
					is.arrayWithLength(reviewImages) && {
						id: 'review-images',
						title: t('product_details_review_images_tab_title'),
						contentClassName: 'h-full min-h-0 min-w-0',
						content: (
							<ReviewImagesView
								ref={reviewImagesScrollRef}
								images={reviewImages}
								isVisible={activeTabId === 'review-images' && isOpen}
								onSlideChange={onReviewImagesSlideChange}
								selectedReviewImageId={selectedReviewImageId}
							/>
						),
					},
				]}
			/>
		</ImagePopover>
	);
}
ProductAndSelectedImagesPopover.displayName = 'ProductAndSelectedImagesPopover';
