import React, { useState } from 'react';

import IconList from 'components/IconList';
import RichText from 'components/RichText';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import { WebStockBalanceDetailed } from 'components/Stock';
import type { WebStock as WebStockModel } from 'models/api';
import { Product } from 'models/product';
import { getProductDetailsWebStockStatus } from 'utils/business-logic/stock';
import { is } from 'utils/helpers';

import { useUspContext } from '../ProductDetailsContext';

import WatchableVariantPopover from './WatchableVariantPopover';

interface WebStockWithoutContextProps {
	isLoadingInitialStock: boolean;
	isLoadingShippingUsps: boolean;
	product: Product;
	shippingUsps: string[] | undefined;
	webStock: WebStockModel | undefined;
}

export function WebStockWithoutContext({
	isLoadingInitialStock,
	isLoadingShippingUsps,
	product,
	shippingUsps,
	webStock,
}: WebStockWithoutContextProps) {
	const [watchablePopoverOpen, setWatchablePopoverOpen] = useState(false);
	const status = getProductDetailsWebStockStatus({
		isSellable: product.salesAttributes.isSellable,
		isSellableOnWeb: product.salesAttributes.isSellableOnWeb,
		isClickAndCollectOnly: product.salesAttributes.isClickAndCollectOnly,
		isWatchableIfOutOfStock: product.salesAttributes.isWatchableIfOutOfStock,
		reStockWithinAcceptableTimespan:
			webStock?.reStockWithinAcceptableTimespan ?? false,
		nextDeliveryInMonth: webStock?.nextDeliveryInMonth ?? '',
		availableStockLevel: webStock?.availableStockLevel ?? 0,
	});
	const isWatchable =
		status === 'ReStock-Watchable' || status === 'ReStock-Watchable-Delayed';
	const isLoadingUsps = isLoadingInitialStock || isLoadingShippingUsps;
	return (
		<div>
			<WebStockBalanceDetailed
				status={status}
				isLoading={isLoadingInitialStock}
				product={product}
				webStock={webStock}
				onWatchableVariantStockClick={() => {
					setWatchablePopoverOpen(true);
				}}
			/>
			{isLoadingUsps && (
				<Skeleton className="pb-6 pl-6 pt-2">
					<SkeletonItem height="text" width="16rem" />
					<SkeletonItem height="text" width="16rem" className="mt-1" />
				</Skeleton>
			)}
			{!isLoadingUsps && is.arrayWithLength(shippingUsps) && (
				<IconList
					backgroundColor="grey"
					className="mx-4 mb-4"
					iconName="check"
					items={shippingUsps.map((usp) => (
						<RichText inheritStyle key={usp} markdown={usp} />
					))}
					size="small"
				/>
			)}
			{isWatchable && (
				<WatchableVariantPopover
					productId={product.id}
					isOpen={watchablePopoverOpen}
					onClose={() => {
						setWatchablePopoverOpen(false);
					}}
				/>
			)}
		</div>
	);
}
WebStockWithoutContext.displayName =
	'ProductDetails_StockInformation_WebStockWithoutContext';

interface Props {
	isLoadingInitialStock: boolean;
	product: Product;
	webStock: WebStockModel | undefined;
}

export default function WebStock({
	isLoadingInitialStock,
	product,
	webStock,
}: Props) {
	const { isLoadingShippingUsps, shippingUsps } = useUspContext();
	return (
		<WebStockWithoutContext
			product={product}
			webStock={webStock}
			isLoadingInitialStock={isLoadingInitialStock}
			shippingUsps={shippingUsps}
			isLoadingShippingUsps={isLoadingShippingUsps}
		/>
	);
}
WebStock.displayName = 'ProductDetails_StockInformation_WebStock';
