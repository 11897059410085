import React from 'react';
import { Field } from 'react-final-form';

import {
	Checkbox as StyledCheckbox,
	CheckboxProps as StyledCheckboxProps,
} from 'components/FormUi';

import { composeValidators, type FieldValidator } from './validation';

interface Props extends StyledCheckboxProps {
	className?: string;
	name: string;
	validate?: FieldValidator | FieldValidator[];
}

export default function Checkbox({
	className,
	name,
	validate,
	...rest
}: Props) {
	return (
		<Field
			type="checkbox"
			name={name}
			validate={composeValidators(validate)}
			render={({ input, meta }) => (
				<StyledCheckbox
					valid={meta.valid && meta.touched}
					invalid={meta.invalid && meta.touched}
					errorMessage={meta.error || meta.submitError}
					className={className}
					{...input}
					{...rest}
				/>
			)}
		/>
	);
}
Checkbox.displayName = 'FinalForm_Checkbox';
