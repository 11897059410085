// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconFlagNo(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<circle cx="12" cy="12" r="12" fill="#ba0c2f" />
			<path
				d="M10.5 9.1h13.14a12 12 0 0 1-.06 6.07H10.5v8.74a11.97 11.97 0 0 1-6-2.55v-6.2H.42a12 12 0 0 1-.07-6.08H4.5V2.63a12 12 0 0 1 6-2.54v9Z"
				fill="#fff"
			/>
			<path
				d="M9 10.6h14.92c.12 1.02.1 2.04-.04 3.04H9v9.98a12 12 0 0 1-3-1.22v-8.75H.1a12 12 0 0 1-.03-3.04H6v-9A11.7 11.7 0 0 1 9 .38z"
				fill="#00205b"
			/>
		</svg>
	);
}
IconFlagNo.displayName = 'IconFlagNo';
