/**
 * TotalWithVat
 */

import React from 'react';

import Price, { PriceText } from 'components/Price';
import Text from 'components/Text';
import { cn } from 'utils/classNames';
import { getTestDataAttrFrom, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	/** Extra container class names */
	className?: string;

	/** Larger, bold title? */
	hasLargeTitle?: boolean;

	/** The price number */
	price?: string | number;

	/** Currency symbol for the price */
	priceSymbol?: string;

	/** Text before the price */
	title?: string;

	/** The VAT portion of the price */
	vat?: string | number;

	/** Currency symbol for the VAT */
	vatCurrencySymbol?: string;
}

/** Summary price with a title. */
export default function TotalWithVat({
	className,
	hasLargeTitle = false,
	price,
	priceSymbol,
	title,
	vat,
	vatCurrencySymbol,
}: Props) {
	const { t } = useI18n();

	if (!price) {
		return null;
	}

	return (
		<div className={cn('flex break-inside-avoid', className)}>
			<Text
				as={hasLargeTitle ? 'pLarge' : 'p'}
				className={cn('mb-2', hasLargeTitle && 'font-bold')}
			>
				{title}
			</Text>
			<div
				data-cy={getTestDataAttrFrom('price-summary')}
				className="ml-auto text-right"
			>
				<Price
					price={price}
					currency={priceSymbol}
					theme="regular"
					size="small"
				/>
				{is.truthyOrZero(vat) && (
					<Text as="pXSmall">
						{t('cart_summary_vat_amount_label')}{' '}
						<PriceText price={vat} symbol={vatCurrencySymbol} />
					</Text>
				)}
			</div>
		</div>
	);
}
TotalWithVat.displayName = 'TotalWithVat';
