import React, { useEffect, useState } from 'react';
import Script from 'next/script';

import IconButton from 'components/IconButton';
import { useChat, useGlobalEvent } from 'hooks';
import { cn } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

import { initSmooch } from './helpers';

interface Props {
	zendeskKey: string;
}

export default function ZendeskChat({ zendeskKey }: Props) {
	const { t } = useI18n();
	const {
		chatWidgetIsActive,
		chatWidgetIsReady,
		isLoadingChatWidget,
		messageCount,
		openChat,
		openChatOrPopover,
		Smooch,
	} = useChat();
	const [isWidgetOpen, setIsWidgetOpen] = useState(false);
	const [triedOpeningWidgetBeforeReady, setTriedOpeningWidgetBeforeReady] =
		useState(false);
	// Must set the active text after first render to avoid hydration error.
	const [chatButtonText, setChatButtonText] = useState(
		t('floating_chat_button_popover_text'),
	);

	useGlobalEvent('chat-widget-ready', () => {
		if (triedOpeningWidgetBeforeReady) {
			openChat();
			setTriedOpeningWidgetBeforeReady(false);
		}
	});
	useGlobalEvent('chat-widget-open', () => {
		setIsWidgetOpen(true);
	});
	useGlobalEvent('chat-widget-close', () => {
		setIsWidgetOpen(false);
	});
	useGlobalEvent('chat-widget-reset', () => {
		setIsWidgetOpen(false);
		setChatButtonText(t('floating_chat_button_popover_text'));
	});

	useEffect(() => {
		// Also check if already ready to avoid running init on navigation.
		if (!Smooch || !chatWidgetIsActive || chatWidgetIsReady) {
			return;
		}
		initSmooch({
			resetButtonText: t('reset_chat_button_text'),
			Smooch,
			title: t('chat_widget_title'),
			zendeskKey,
		});
		setChatButtonText(t('floating_chat_button_chat_text'));
		// Only want to run for two cases.
		// 1. Smooch object becomes available after script tag has first been added.
		// 2. Active state changes after a reset + start new chat, in which case
		//    Smooch object is already available but active will go false -> true.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Smooch, chatWidgetIsActive]);

	return (
		<>
			{/* If the chat has been reset the active state will be false but, Smooch
			will still exist since it's a global object. Let the snippet stay in that
			case to avoid potential issues with duplicate stuff. */}
			{(chatWidgetIsActive || Smooch) && (
				<Script
					id="zendesk-loader"
					dangerouslySetInnerHTML={{
						__html: [
							'!function(o,p,s,e,c){',
							'var i,a,h,u=[],d=[];function t(){var t="You must provide a supported major version.";try{if(!c)throw new Error(t);var e,n="https://cdn.smooch.io/",r="smooch";if((e="string"==typeof this.response?JSON.parse(this.response):this.response).url){var o=p.getElementsByTagName("script")[0],s=p.createElement("script");s.async=!0;var i=c.match(/([0-9]+).?([0-9]+)?.?([0-9]+)?/),a=i&&i[1];if(i&&i[3])s.src=n+r+"."+c+".min.js";else{if(!(4<=a&&e["v"+a]))throw new Error(t);s.src=e["v"+a]}o.parentNode.insertBefore(s,o)}}catch(e){e.message===t&&console.error(e)}}o[s]={init:function(){i=arguments;var t={then:function(e){return d.push({type:"t",next:e}),t},catch:function(e){return d.push({type:"c",next:e}),t}};return t},on:function(){u.push(arguments)},render:function(){a=arguments},destroy:function(){h=arguments}},o.__onWebMessengerHostReady__=function(e){if(delete o.__onWebMessengerHostReady__,o[s]=e,i)for(var t=e.init.apply(e,i),n=0;n<d.length;n++){var r=d[n];t="t"===r.type?t.then(r.next):t.catch(r.next)}a&&e.render.apply(e,a),h&&e.destroy.apply(e,h);for(n=0;n<u.length;n++)e.on.apply(e,u[n])};var n=new XMLHttpRequest;n.addEventListener("load",t),n.open("GET","https://"+e+".webloader.smooch.io/",!0),n.responseType="json",n.send()',
							`}(window,document,"Smooch","${zendeskKey}","5");`,
						].join(''),
					}}
				/>
			)}
			<IconButton
				icon="chat"
				iconColor="white"
				hoverClasses="[@media(hover:hover)]:hover:bg-julaRedDarken"
				text={chatButtonText}
				badgeProps={{
					color: 'black',
					className: 'absolute -top-1 -right-0.5',
				}}
				badge={messageCount > 0 ? messageCount : undefined}
				className={cn(
					'fixed bottom-4 right-4 z-chatButton border-2 border-white bg-julaRed transition-opacity focus-visible:focus-outline-inverted hover:bg-julaRedDarker forced-colors:border print:hidden [&:not(:focus-visible)]:shadow-lg',
					isWidgetOpen && 'opacity-0',
				)}
				isLoading={triedOpeningWidgetBeforeReady}
				onClick={() => {
					// Trying to open widget before it's ready is a no-op, show loading
					// feedback and trigger the opening when ready.
					if (isLoadingChatWidget) {
						setTriedOpeningWidgetBeforeReady(true);
					} else if (!triedOpeningWidgetBeforeReady) {
						openChatOrPopover();
					}
				}}
			/>
		</>
	);
}
ZendeskChat.displayName = 'ZendeskChat';
