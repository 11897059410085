import React, { SelectHTMLAttributes } from 'react';

import Icon from 'components/Icon';
import { cn } from 'utils/classNames';

import FloatingLabel from './FloatingLabel';
import { getInputClassName, isLabelFloating } from './helpers';
import InputInfo from './InputInfo';

export interface Option {
	label: string;
	value: string;
}

export interface Props
	extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'multiple'> {
	errorMessage?: string | string[];
	helpText?: string;
	hiddenLabel?: boolean;
	id: string;
	invalid?: boolean;
	label: string;
	options: Option[];
	valid?: boolean;
}

export default function Select({
	className,
	defaultValue,
	disabled,
	errorMessage,
	helpText,
	hiddenLabel = false,
	id,
	invalid = false,
	label,
	options,
	required,
	valid = false,
	value,
	...rest
}: Props) {
	const errorMessageId = `${id}-error`;
	const helpTextId = `${id}-help`;
	const hasFloatingLabel = isLabelFloating(value, defaultValue);

	return (
		<div className={className}>
			<div className={cn('relative text-greyDarker', disabled && 'opacity-50')}>
				<div className="relative">
					<select
						{...rest}
						className={getInputClassName({
							disabled,
							extra: cn(
								'h-14 appearance-none py-0 transition-colors',

								// Handle long options sticking out beneath the floating label
								// by setting transparent text.
								// Pseudo classes match those in FloatingLabel.
								!hasFloatingLabel &&
									'text-transparent forced-colors:text-[Field]',
								'focus:text-inherit',
								'autofill:text-inherit',
								'placeholder-shown:text-inherit',
								// Outline in forced colors mode uses the text color by default
								// and since that is set to the field background above the
								// outline can be invisible if that is the same as the page
								// background. Set a specific one here to fix. Use !important
								// to override valid/invalid !important.
								'forced-colors:hover:!outline-[ButtonText] forced-colors:focus:!outline-[ButtonText]',
							),
							invalid,
							valid,
						})}
						aria-describedby={[errorMessageId, helpTextId].join(' ')}
						// Support for aria-errormessage isn't good enough as of autumn 2024
						// so stick with aria-describedby for now.
						// aria-errormessage={errorMessageId}
						aria-invalid={invalid}
						defaultValue={defaultValue}
						disabled={disabled}
						id={id}
						required={required}
						value={value}
					>
						{options.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
					<FloatingLabel
						// Compensate for the arrow icon.
						className="max-w-[calc(100%-3.5rem)]"
						htmlFor={id}
						invalid={invalid}
						isFloating={hasFloatingLabel}
						isHidden={hiddenLabel}
						required={required}
					>
						{label}
					</FloatingLabel>
				</div>
				<Icon
					name="arrow"
					direction="down"
					size={40}
					className="pointer-events-none absolute right-px top-1/2 -translate-y-1/2 border-b border-greyLight bg-white p-2"
				/>
			</div>
			<InputInfo
				errorMessage={errorMessage}
				errorMessageId={errorMessageId}
				helpText={helpText}
				helpTextId={helpTextId}
				invalid={invalid}
			/>
		</div>
	);
}
Select.displayName = 'FormUI_Select';
