/**
 * VideoBackground
 */

import React, { useEffect, useRef } from 'react';

import type { DigizuiteAsset } from 'models/asset';
import { cn } from 'utils/classNames';
import { ignorePromiseRejection } from 'utils/helpers';

interface Props {
	asset: DigizuiteAsset;
	className?: string;
	cover?: boolean;
	shouldPlay?: boolean;
}

/** A looping, muted video. */
export default function VideoBackground({
	asset,
	className,
	cover = false,
	shouldPlay,
}: Props) {
	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (shouldPlay && videoRef.current) {
			ignorePromiseRejection(videoRef.current.play());
		} else if (videoRef.current) {
			videoRef.current.pause();
		}
	}, [shouldPlay]);

	return (
		<video
			ref={videoRef}
			autoPlay
			loop
			muted
			playsInline
			aria-hidden="true"
			tabIndex={-1}
			className={cn(className, cover && 'h-full w-full object-cover')}
		>
			<source type={asset.contentType} src={asset.src} />
		</video>
	);
}
VideoBackground.displayName = 'VideoBackground';
