import React, { useMemo } from 'react';

import StorePopover from 'components/StorePopover';
import { type StoreIdName } from 'contexts';
import type { Stock } from 'models/api';
import { filterTruthy } from 'utils/collection';
import { pushToGTM } from 'utils/GoogleTagManager';
import { sendGlobalEvent } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	allStoresStock: Stock[] | undefined;
	isLoading: boolean;
	isOpen: boolean;
	nearbyStoresStock: Stock[] | undefined;
	onClose: () => void;
	onUpdateSelectedStore: (store: StoreIdName) => void;
	selectedStore: StoreIdName | undefined;
}

export default function StockStoreSelectorPopover({
	allStoresStock,
	isLoading,
	isOpen,
	nearbyStoresStock,
	onClose,
	onUpdateSelectedStore,
	selectedStore,
}: Props) {
	const { t } = useI18n();

	const flattenedAllStores = useMemo(
		() =>
			filterTruthy(
				allStoresStock?.map((storeStock) => ({
					...storeStock.store,
					availableStockLevel: storeStock.availableStockLevel,
					inStock: storeStock.inStock,
				})),
				'id',
				'name',
			),
		[allStoresStock],
	);
	const flattenedNearbyStores = useMemo(
		() =>
			filterTruthy(
				nearbyStoresStock?.map((storeStock) => ({
					...storeStock.store,
					availableStockLevel: storeStock.availableStockLevel,
					inStock: storeStock.inStock,
				})),
				'id',
				'name',
			),
		[nearbyStoresStock],
	);

	return (
		<StorePopover
			baseId="stock-store"
			title={
				selectedStore?.id
					? t('store_stock_popover_change_store_title')
					: t('store_stock_popover_select_store_title')
			}
			allStores={flattenedAllStores}
			nearbyStores={flattenedNearbyStores}
			selectedStoreId={selectedStore?.id}
			onStoreSelectClick={(store) => {
				onUpdateSelectedStore(store);
				sendGlobalEvent('engagement', {
					type: 'checkAvailabilityInStore',
					data: {
						storeId: store?.id,
					},
				});
				if (
					store.name &&
					'availableStockLevel' in store &&
					store.availableStockLevel
				) {
					pushToGTM({
						type: 'select_store_choice',
						payload: {
							storeName: store.name,
							storeStock: store.availableStockLevel,
						},
					});
				}

				onClose();
			}}
			onClose={onClose}
			isLoading={isLoading}
			isOpen={isOpen}
		/>
	);
}

StockStoreSelectorPopover.displayName = 'StockStoreSelectorPopover';
