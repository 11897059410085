import React from 'react';

import ArrowLink from 'components/ArrowLink';
import { LayoutContainer } from 'components/Layout/';
import RichText from 'components/RichText';
import Text from 'components/Text';
import type { JulaComponentProps } from 'lib/component-props';
import type { FaqArticle } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields?: FaqArticle['fields'];
};

export default function FAQArticle({ fields, params }: Props) {
	const { t } = useI18n();
	if (!fields) {
		return null;
	}
	const { content, relatedArticles, title } = fields;
	return (
		<LayoutContainer className={getEditorMargin(params)} id={params?.anchor}>
			<div className="max-w-col-6">
				<Text field={title} className="mb-4" as="h1" />

				<RichText className="richtext--faq" field={content} />
				{is.arrayWithLength(relatedArticles) && (
					<>
						<Text
							as="h2"
							text={t('faq_article_related_articles_heading')}
							className="mt-8"
						/>
						<ul className="mt-4 flex flex-col gap-4">
							{relatedArticles.map((article) => (
								<li key={article.url}>
									<ArrowLink
										text={article.fields.summary.value}
										href={article.url}
									/>
								</li>
							))}
						</ul>
					</>
				)}
			</div>
		</LayoutContainer>
	);
}
FAQArticle.displayName = 'FAQArticle';
