/**
 * ProductFit
 */

import React from 'react';

import WeightedAverageScale, {
	type InfoText,
	WeightedAverageInfoText,
} from 'components/WeightedAverageScale';
import type { QuestionSummary } from 'models/api';
import { cn } from 'utils/classNames';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

export const MIN_ANSWER_COUNT = 5;
export const TOO_SMALL_PERCENTAGE = 33;
export const TOO_LARGE_PERCENTAGE = 66;

interface Props {
	className?: string;
	fit3Summary: QuestionSummary | undefined;
	infoTextClassName?: string;
	scaleClassName?: string;
	showScale?: boolean;
}

/** Visual product fit scale */
export default function ProductFit({
	className,
	fit3Summary,
	infoTextClassName,
	scaleClassName,
	showScale = true,
}: Props) {
	const { t } = useI18n();

	if (
		!fit3Summary ||
		!is.arrayWithLength(fit3Summary.options) ||
		fit3Summary.count < MIN_ANSWER_COUNT
	) {
		return null;
	}

	const points = fit3Summary.options.map((option) => ({
		text: t(`question_summary_fit3_${option.value}_text`),
		count: option.count,
	}));
	const infoTexts: InfoText[] = [
		{
			text: t('question_summary_fit_is_small_text'),
			isActive: (percentage) => percentage < TOO_SMALL_PERCENTAGE,
		},
		{
			text: t('question_summary_fit_is_perfect_sr_text'),
			isActive: (percentage) =>
				percentage >= TOO_SMALL_PERCENTAGE &&
				percentage <= TOO_LARGE_PERCENTAGE,
			srOnly: true,
		},
		{
			text: t('question_summary_fit_is_large_text'),
			isActive: (percentage) => percentage > TOO_LARGE_PERCENTAGE,
		},
	];

	return (
		<div className={cn('space-y-6', className)}>
			{showScale && (
				<WeightedAverageScale
					className={scaleClassName}
					points={points}
					title={t('question_summary_fit3_title')}
					description={t('question_summary_fit3_description', {
						count: fit3Summary.count,
					})}
				/>
			)}
			<WeightedAverageInfoText
				className={infoTextClassName}
				points={points}
				texts={infoTexts}
			/>
		</div>
	);
}
ProductFit.displayName = 'ProductFit';
