import React from 'react';

import ProductFit from 'components/ProductFit';
import type { StoreIdName } from 'contexts';
import type { ItemStockResponse, QuestionSummary } from 'models/api';
import type { Product } from 'models/product';
import type { ProductCard } from 'models/productCard';
import { filterTruthy } from 'utils/collection';
import { useI18n } from 'utils/i18n';

import BasePicker from './BasePicker';

interface Props {
	className?: string;
	fit3Summary: QuestionSummary | undefined;
	onOpen?: () => void;
	product: Product;
	selectedStore: StoreIdName | undefined;
	stockData: ItemStockResponse[] | undefined;
	stockIsLoading: boolean;
	variants: ProductCard[] | undefined;
}

export default function SizePicker({
	className,
	fit3Summary,
	onOpen,
	product,
	selectedStore,
	stockData,
	stockIsLoading,
	variants,
}: Props) {
	const { t } = useI18n();

	return (
		<BasePicker
			className={className}
			buttonLabel={t('product_details_pick_size_button')}
			popoverTitle={t('popover_choose_size_header')}
			onOpen={onOpen}
			shouldScrollToTopOnSelect={false}
			selectedText={product.variantText}
			selectedStore={selectedStore}
			stockData={stockData}
			stockIsLoading={stockIsLoading}
			popoverStartContent={
				<ProductFit
					infoTextClassName="mb-6"
					fit3Summary={fit3Summary}
					showScale={false}
				/>
			}
			variants={filterTruthy(variants, 'url').map((variant) => ({
				isSelected: variant.id === product.id,
				isSellable: variant.isSellable,
				isSellableOnWeb: variant.isSellableOnWeb,
				key: variant.id,
				price: variant.displayPrice,
				title: variant.variantText,
				url: variant.url,
				variantId: variant.id,
			}))}
		/>
	);
}
SizePicker.displayName = 'ProductDetails_SizePicker';
