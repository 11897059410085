/**
 * MicroContent - Chip links
 */

import React, { type MouseEventHandler } from 'react';

import Chip from 'components/Chip';
import Text from 'components/Text';
import type { SimpleLink } from 'models/sitecore';
import type { HTMLAttributes } from 'types';

interface Props extends HTMLAttributes<HTMLDivElement> {
	links: SimpleLink[];
	onChipClick?: MouseEventHandler<HTMLAnchorElement>;
	title: string;
}

/** Title and linked chips */
export default function MicroContentChipLinks({
	links,
	onChipClick,
	title,
	...attrs
}: Props) {
	return (
		<div {...attrs}>
			<Text as="p" className="my-6 font-bold">
				{title}
			</Text>
			<div className="flex flex-col items-start gap-2">
				{links.map((link) => (
					<Chip
						key={`${link.href}-${link.text}`}
						color="grey"
						href={link.href}
						text={link.text}
						target={link.target}
						onClick={onChipClick}
					/>
				))}
			</div>
		</div>
	);
}
MicroContentChipLinks.displayName = 'MicroContentChipLinks';
