import React from 'react';

import Button from 'components/Button';
import type { ExistingPlacement } from 'hooks/product-details';
import type { CreateCustomizationRequest } from 'models/api';

import PrintPlacement from './PrintPlacement';

interface ExistingPrintPlacementsViewProps {
	addNewPrintPlacementButtonText: string | undefined;
	description: string | undefined;
	onCreateNewPrintPlacementClick: () => void;
	onSelectExistingPrintPlacementClick: (
		newPrint: CreateCustomizationRequest,
	) => void;
	printPlacements: ExistingPlacement[] | undefined;
	printPlacementsLabel: string | undefined;
	selectExistingPrintPlacementButtonText: string | undefined;
}

export default function ExistingPrintPlacementsView({
	addNewPrintPlacementButtonText,
	description,
	onCreateNewPrintPlacementClick,
	onSelectExistingPrintPlacementClick,
	printPlacements,
	printPlacementsLabel,
	selectExistingPrintPlacementButtonText,
}: ExistingPrintPlacementsViewProps) {
	return (
		<>
			<p className="font-bold">{printPlacementsLabel}</p>
			<p>{description}</p>

			<ul className="mt-6 flex flex-col gap-2">
				{printPlacements?.map((printPlacement) => (
					<PrintPlacement
						key={`${printPlacement.customizationId}-${printPlacement.id}`}
						placement={printPlacement.title || ''}
						logotypeName={printPlacement.printImageFilename?.originalFileName}
						size={printPlacement.option?.text}
						price={printPlacement.option?.price?.priceIncVat}
						onClick={() => {
							onSelectExistingPrintPlacementClick({
								placementId: printPlacement.id,
								printImageFilename: printPlacement.printImageFilename?.fileName,
								optionKey: printPlacement.option?.key,
							});
						}}
						buttonText={selectExistingPrintPlacementButtonText}
					/>
				))}
			</ul>
			<Button
				onClick={onCreateNewPrintPlacementClick}
				variant="cta"
				displayWidth="full"
				className="mt-6"
			>
				{addNewPrintPlacementButtonText}
			</Button>
		</>
	);
}
ExistingPrintPlacementsView.displayName =
	'ProductPrintPopover_ExistingPrintPlacementsView';
