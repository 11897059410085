import React from 'react';

import ArrowLink from 'components/ArrowLink';
import Text from 'components/Text';
import type { HeadingLevel } from 'lib/component-props';
import type { FaqArticle, FaqCategoryArticle } from 'models/sitecore';
import { filterTruthy } from 'utils/collection';

interface Props {
	articles: FaqArticle[];
	className?: string;
	heading?: string;
	headingLevel?: HeadingLevel;
	items: FaqCategoryArticle[] | undefined;
}

export default function FAQAccordionArticles({
	articles,
	className,
	heading,
	headingLevel = 'h4',
	items,
}: Props) {
	const itemsData = filterTruthy(
		items?.map((item) => ({
			id: item.articleId,
			text: item.shortDesc,
			url: articles.find(
				(article) => article.fields?.id?.value === item.articleId,
			)?.url,
		})),
		'id',
		'text',
		'url',
	);

	if (itemsData.length === 0) {
		return null;
	}

	return (
		<div className={className}>
			{heading && (
				<Text as={headingLevel} className="mb-4">
					{heading}
				</Text>
			)}
			<ul>
				{itemsData.map(({ id, text, url }) => (
					<li key={id} className="mb-4 last:mb-0">
						<ArrowLink text={text} href={url} />
					</li>
				))}
			</ul>
		</div>
	);
}
FAQAccordionArticles.displayName = 'FAQOverview_FAQAccordionArticles';
