/**
 * SwipeWrapper
 */

import React from 'react';

import {
	GUTTER_PADDING_CLASSES,
	PULL_GUTTER_MARGIN_CLASSES,
} from 'components/Layout';
import type { HTMLTagName } from 'types';
import { cn } from 'utils/classNames';

export type SwipeWrapperSpacing = 0.5 | 1 | 1.5;

interface Props {
	/** Classes for the container in active state. */
	activeClassName?: string;

	/** Container tag, defaults to ul */
	as?: HTMLTagName;

	/** React children */
	children?: React.ReactNode;

	/** Extra class names for the container, for both active and inactive state. */
	className?: string;

	/** Classes for the container in inactive state. */
	inactiveClassName?: string;

	/** Pull the wrapper over horizontal gutters to the edge of the screen */
	pullGutters?: boolean;

	/** Spacing between child components */
	spacing?: SwipeWrapperSpacing;
}

/**
 * A wrapper that can be used to list components on one row and allow users
 * to swipe/scroll horizontally.
 *
 * Pass an inactive class name to render an additional container without any
 * scrolling classes.
 */
export default function SwipeWrapper({
	activeClassName,
	as: Container = 'ul',
	children,
	className,
	inactiveClassName,
	pullGutters = false,
	spacing = 0.5,
}: Props) {
	return (
		<>
			<Container
				className={cn(
					className,
					activeClassName,
					'scrolling-touch flex overflow-x-scroll whitespace-nowrap pb-2 pt-0.5',
					spacing === 0.5 && 'gap-2',
					spacing === 1 && 'gap-4',
					spacing === 1.5 && 'gap-6',
					pullGutters && [PULL_GUTTER_MARGIN_CLASSES, GUTTER_PADDING_CLASSES],
				)}
			>
				{children}
			</Container>
			{inactiveClassName && (
				<Container className={cn(className, inactiveClassName)}>
					{children}
				</Container>
			)}
		</>
	);
}
SwipeWrapper.displayName = 'SwipeWrapper';
