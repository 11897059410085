// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconTiktok(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M19.37 4.81A5.75 5.75 0 0 1 16.75 0h-4.12v16.54a3.5 3.5 0 0 1-3.48 3.37 3.5 3.5 0 0 1-3.48-3.48 3.5 3.5 0 0 1 4.51-3.33V8.88a7.64 7.64 0 0 0-8.62 7.53c0 4.17 3.43 7.6 7.6 7.6s7.6-3.43 7.6-7.6V8.02a9.82 9.82 0 0 0 5.74 1.85V5.74a5.7 5.7 0 0 1-3.13-.93" />
		</svg>
	);
}
IconTiktok.displayName = 'IconTiktok';
