/**
 * BlockInspirationLandscape
 */

import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ArrowLink from 'components/ArrowLink';
import BlockMedia, {
	type BlockMediaProductsDisplayType,
} from 'components/BlockMedia';
import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import type { HeadingLevel } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import type { ProductCard } from 'models/productCard';
import type { SimpleLink } from 'models/sitecore';
import { cn } from 'utils/classNames';
import type { GTMItemListId, GTMItemListName } from 'utils/GoogleTagManager';
import { is } from 'utils/helpers';

export interface Props {
	backgroundColor?: string;
	basePriceTextBackground?: string;
	campaignBubbleLabel?: string;
	campaignBubbleText?: string;
	className?: string;
	gtmItemListId: GTMItemListId;
	gtmItemListName: GTMItemListName;
	heading?: Field<string>;
	headingLevel?: HeadingLevel;
	id?: string;
	isMirrored?: boolean;
	links?: SimpleLink[];
	preventAutoplay?: boolean;
	primaryImageAlt: string | undefined;
	primaryImageSrc: string | undefined;
	primaryLinkHref?: string;
	products?: ProductCard[];
	productsDisplayType: BlockMediaProductsDisplayType;
	text?: Field<string>;
	video?: DigizuiteAsset;
}

export default function BlockInspirationLandscape({
	backgroundColor,
	basePriceTextBackground,
	campaignBubbleLabel,
	campaignBubbleText,
	className,
	gtmItemListId,
	gtmItemListName,
	heading,
	headingLevel = 'h2',
	id,
	isMirrored,
	links,
	preventAutoplay,
	primaryImageAlt,
	primaryImageSrc,
	primaryLinkHref,
	products,
	productsDisplayType,
	text,
	video,
}: Props) {
	const isShopByMediaWithProducts =
		productsDisplayType === 'shopByMedia' && is.arrayWithLength(products);
	const primaryPart = (
		<BlockMedia
			className="col-span-full md:col-span-6"
			href={isShopByMediaWithProducts ? undefined : primaryLinkHref}
			variant="landscape"
			imgSrc={primaryImageSrc}
			imgAlt={primaryImageAlt}
			video={video}
			campaignBubbleLabel={campaignBubbleLabel}
			campaignBubbleText={campaignBubbleText}
			products={products}
			productsDisplayType={productsDisplayType}
			preventAutoplay={preventAutoplay}
			gtmItemListId={gtmItemListId}
			gtmItemListName={gtmItemListName}
			basePriceTextBackground={basePriceTextBackground}
			productAnimationPlacementClassName="absolute inset-y-[15%] right-12 flex justify-end md:inset-y-20 md:right-36"
		/>
	);

	const renderSecondaryPart = (extraClassName?: string) => (
		<div
			className={cn(
				extraClassName,
				'col-span-full md:col-span-6',
				// Padding to compensate for LayoutContainer's removed margin below.
				'max-sm:px-4',
				backgroundColor && [
					'pb-4 sm:px-4 md:py-6',
					isMirrored ? 'md:pl-6 md:pr-0' : 'md:pl-0 md:pr-6',
				],
			)}
		>
			{heading && <Text as={headingLevel} field={heading} />}
			{text && <Text as="p" className="mt-2 md:mt-4" field={text} />}
			{is.arrayWithLength(links) && (
				<div
					className={cn(
						'flex flex-col items-start justify-start gap-4',
						text?.value && 'mt-4 md:mt-6',
						!text?.value && 'mt-2 md:mt-4',
					)}
				>
					{links.map((link) => (
						<ArrowLink
							key={link.href}
							href={link.href}
							text={link.text}
							target={link.target}
						/>
					))}
				</div>
			)}
		</div>
	);

	return (
		<LayoutContainer
			id={id}
			outerClassName={cn(className, 'max-sm:mx-0')}
			className="items-center"
			innerBackgroundColor={backgroundColor}
			withGrid
			withVerticalGridGap
		>
			{/* The secondary text should always be below the primary part on small screens. */}
			{isMirrored && (
				<>
					{renderSecondaryPart('max-md:hidden')}
					{primaryPart}
					{renderSecondaryPart('md:hidden')}
				</>
			)}
			{!isMirrored && (
				<>
					{primaryPart}
					{renderSecondaryPart()}
				</>
			)}
		</LayoutContainer>
	);
}
BlockInspirationLandscape.displayName = 'BlockInspirationLandscape';
