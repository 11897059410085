import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlogDetailsBlock from 'components/BlogDetails';
import { LayoutContainer } from 'components/Layout';
import type { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import { Tag } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields?: {
		blogRootUrl: string;
		content: Field<string>;
		date: Field<string>;
		heading: Field<string>;
		images: {
			value: {
				assets: DigizuiteAsset[][];
			};
		};
		landscapeImage: Field<DigizuiteAsset[]>;
		portraitImage: Field<DigizuiteAsset[]>;
		squareImage: Field<DigizuiteAsset[]>;
		tags: Tag[];
	};
};

export default function BlogDetails({ fields, params }: Props) {
	const { t } = useI18n();
	if (!fields) {
		return null;
	}

	return (
		<LayoutContainer withGrid className={getEditorMargin(params)}>
			<BlogDetailsBlock
				viewType="details"
				className="col-span-4 flex flex-col md:col-span-6 md:col-start-4"
				content={fields.content.value}
				heading={fields.heading.value}
				images={fields.images.value.assets}
				date={fields.date?.value}
				blogRootUrl={fields.blogRootUrl}
				backToOverviewText={t('blog_details_return_to_overview_text')}
				backToOverviewUrl={fields.blogRootUrl}
				tags={fields.tags}
			/>
		</LayoutContainer>
	);
}
BlogDetails.displayName = 'BlogDetails';
