/**
 * ActionLink
 */

import React, { useState } from 'react';

import Button, { type ButtonVariant } from 'components/Button';
import Icon from 'components/Icon';
import { PartialPopover } from 'components/Popover';
import { useGlobalMemberPopoversContext } from 'contexts';
import { useChat } from 'hooks';
import type { ActionLink as ActionLinkModel } from 'models/sitecore';
import { is } from 'utils/helpers';

const NON_NUMBERS_REGEXP = /\D/g;

export function getPhoneNumberFromText(text: string | undefined): string {
	return text?.replace(NON_NUMBERS_REGEXP, '') || '';
}
export function isValidPhoneNumberText(text: string | undefined): boolean {
	return getPhoneNumberFromText(text).length >= 5;
}

function openOneTrustSettings() {
	if (is.func(globalThis.OneTrust?.ToggleInfoDisplay)) {
		globalThis.OneTrust.ToggleInfoDisplay();
	}
}

interface Props extends ActionLinkModel {
	buttonVariant?: ButtonVariant;
	className?: string;
	underline?: boolean;
}

export default function ActionLink({
	buttonVariant = 'secondary',
	className,
	href: hrefProp,
	icon,
	target,
	targetTitle,
	text,
	type,
	underline = false,
}: Props) {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const { openChatOrPopover } = useChat();
	const { openJulaClubLogin, openJulaProLogin } =
		useGlobalMemberPopoversContext();

	if (!type || !text) {
		return null;
	}

	const href =
		type === 'Phone'
			? `tel:${getPhoneNumberFromText(text) || getPhoneNumberFromText(hrefProp)}`
			: hrefProp;
	const isPopover = type === 'Popover' && href;
	const children = (
		<>
			{icon && <Icon name={icon} />}
			{text}
		</>
	);

	// TypeScript doesn't like href + target when both are potentially undefined,
	// be explicit about the link case.
	if (is.oneOf(type, 'Phone', 'Link') && href) {
		return (
			<Button
				className={className}
				href={href}
				target={target}
				underline={underline}
				variant={buttonVariant}
			>
				{children}
			</Button>
		);
	}

	return (
		<>
			<Button
				className={className}
				underline={underline}
				variant={buttonVariant}
				aria-haspopup={isPopover ? 'dialog' : undefined}
				onClick={() => {
					if (isPopover) {
						setIsPopoverOpen(true);
					}
					if (type === 'Cookies') {
						openOneTrustSettings();
					}
					if (type === 'Chat') {
						openChatOrPopover();
					}
					if (is.oneOf(type, 'LoginJulaClub', 'LoginJulaPro')) {
						if (type === 'LoginJulaClub') {
							openJulaClubLogin();
						} else if (type === 'LoginJulaPro') {
							openJulaProLogin();
						}
					}
				}}
			>
				{children}
			</Button>
			{isPopover && (
				<PartialPopover
					heading={targetTitle || text}
					isOpen={isPopoverOpen}
					item={href}
					onClose={() => {
						setIsPopoverOpen(false);
					}}
				/>
			)}
		</>
	);
}
ActionLink.displayName = 'ActionLink';
