/**
 * ProductDetailsImage
 */

import React, { useState } from 'react';

import ImageSlider from 'components/ImageSlider';
import Img from 'components/Img';
import ProductTag from 'components/ProductTag';
import Skiplink from 'components/Skiplink';
import type { ReviewImage, ReviewImageId } from 'hooks/product-details';
import type { Product } from 'models/product';
import { getAllImages } from 'utils/business-logic';
import { cn } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

import AllReviewImagesPopover from './AllReviewImagesPopover';
import ProductAndSelectedImagesPopover, {
	ProductAndSelectedImagesTabId,
} from './ProductAndSelectedImagesPopover';

interface Props {
	activeTabId: ProductAndSelectedImagesTabId;
	allReviewImages: ReviewImage[] | undefined;
	allReviewImagesIsOpen: boolean;
	className?: string;
	onAllReviewImagesSlideChange: (slideIndex: number) => void;
	onCloseAllReviewImages: () => void;
	onCloseProductAndSelectedImages: () => void;
	onOpenProductAndSelectedImages: () => void;
	onSelectedReviewImagesSlideChange: (slideIndex: number) => void;
	onTabChange: (newTabId: ProductAndSelectedImagesTabId) => void;
	product: Product;
	productAndSelectedImagesIsOpen: boolean;
	selectedAllReviewImageId?: ReviewImageId | undefined;
	selectedTopScoreReviewImageId: ReviewImageId | undefined;
	topScoreReviewImages: ReviewImage[] | undefined;
}

const PLACEHOLDER_SRC = '/assets/images/placeholder-image.png';

/** Image component for Product Details. */
export default function ProductDetailsImage({
	activeTabId,
	allReviewImages,
	allReviewImagesIsOpen,
	className,
	onAllReviewImagesSlideChange,
	onCloseAllReviewImages,
	onCloseProductAndSelectedImages,
	onOpenProductAndSelectedImages,
	onSelectedReviewImagesSlideChange,
	onTabChange,
	product,
	productAndSelectedImagesIsOpen,
	selectedAllReviewImageId,
	selectedTopScoreReviewImageId,
	topScoreReviewImages,
}: Props) {
	const { t } = useI18n();
	const [selectedPopoverImageIndex, setSelectedPopoverImageIndex] = useState(0);

	const flag = product.productTags?.find(({ section }) => section === 'Flag');
	const allProductImages = getAllImages(product) ?? [];
	const hasProductImages = allProductImages.length > 0;
	const afterImagesId = 'skip-images';
	const maxVisibleSliderImages = 5;

	return (
		<>
			<div className={cn('relative', className)}>
				{flag && (
					<ProductTag
						type={flag.type}
						text={flag.text}
						size="large"
						className={cn(
							'absolute left-0 top-0 z-1',
							// ImageSlider will have the thumbnails to the left on md+.
							// Left for the thumbnail size and margin for the grid gap.
							allProductImages.length > 1 && 'md:left-20 md:ml-6',
						)}
					/>
				)}
				{hasProductImages && (
					// Only need a skiplink for the slider, the placeholder image has
					// no alt text and will thus be practically invisible.
					<>
						<Skiplink
							target={afterImagesId}
							text={t('product_details_images_skiplink_text')}
							className="left-0 top-0 z-10 focus:absolute"
						/>
						<ImageSlider
							images={allProductImages}
							maxVisibleImages={maxVisibleSliderImages}
							sliderButtonLabel={t('product_details_show_large_image_label')}
							onSlideClick={(imageIndex) => {
								setSelectedPopoverImageIndex(imageIndex);
								onOpenProductAndSelectedImages();
							}}
							onShowAllClick={() => {
								// Start on the first image not included in the slider.
								// The show all button takes the last image slot so go back
								// an index.
								setSelectedPopoverImageIndex(maxVisibleSliderImages - 1);
								onOpenProductAndSelectedImages();
							}}
							triggersDialog
						/>
						<div id={afterImagesId} tabIndex={-1} />
					</>
				)}
				{!hasProductImages && (
					// Smaller than a regular main image on purpose to get the customer
					// closer to content that actually matters.
					<Img
						src={PLACEHOLDER_SRC}
						width={128}
						height={128}
						className="mx-auto size-32"
					/>
				)}
			</div>

			<ProductAndSelectedImagesPopover
				activeTabId={activeTabId}
				images={allProductImages}
				isOpen={productAndSelectedImagesIsOpen}
				onClose={onCloseProductAndSelectedImages}
				onReviewImagesSlideChange={onSelectedReviewImagesSlideChange}
				onTabChange={onTabChange}
				onThumbnailClick={(imageIndex) => {
					setSelectedPopoverImageIndex(imageIndex);
				}}
				reviewImages={topScoreReviewImages}
				selectedImageIndex={selectedPopoverImageIndex}
				selectedReviewImageId={selectedTopScoreReviewImageId}
			/>
			{allReviewImages && (
				<AllReviewImagesPopover
					images={allReviewImages}
					isOpen={allReviewImagesIsOpen}
					onClose={onCloseAllReviewImages}
					onSlideChange={onAllReviewImagesSlideChange}
					selectedAllReviewImageId={selectedAllReviewImageId}
				/>
			)}
		</>
	);
}
ProductDetailsImage.displayName = 'ProductDetailsImage';
