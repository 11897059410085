import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import Img from 'components/Img';
import { LayoutContainer } from 'components/Layout';
import RichText from 'components/RichText';
import Text from 'components/Text';
import type { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import type { HTMLString } from 'types';
import { getEditorMargin } from 'utils/business-logic';
import { cn } from 'utils/classNames';
import { getAsset } from 'utils/helpers';

interface CreditWarningBlockProps {
	className?: string;
	content: Field<HTMLString>;
	heading: Field<string>;
	iconSrc: string | undefined;
}

function CreditWarningBlock({
	className,
	content,
	heading,
	iconSrc,
}: CreditWarningBlockProps) {
	return (
		<div className={cn('flex', className)}>
			{iconSrc && (
				<Img
					src={iconSrc}
					alt=""
					className="mr-2 h-[4.5em] w-auto shrink-0"
					width={67}
					height={67}
					service="nextjs"
					jpgOptimized={false}
				/>
			)}
			<div>
				<Text className="font-bold" as="pSmall" field={heading} />
				<RichText className="text-sm" field={content} />
			</div>
		</div>
	);
}

CreditWarningBlock.displayName = 'CreditWarningBlock';

type Props = JulaComponentProps & {
	fields?: {
		content: Field<HTMLString>;
		heading: Field<string>;
		svgImage?: Field<DigizuiteAsset[]>;
	};
};

export default function CreditWarning({
	fields,
	isInNestedPlaceholder,
	params,
}: Props) {
	if (!fields) {
		return null;
	}
	const iconSrc = getAsset('Source Copy', fields.svgImage?.value)?.src;

	if (isInNestedPlaceholder) {
		return (
			<CreditWarningBlock
				content={fields.content}
				heading={fields.heading}
				iconSrc={iconSrc}
			/>
		);
	}

	return (
		<LayoutContainer className={getEditorMargin(params)}>
			<CreditWarningBlock
				className="max-w-col-6"
				content={fields.content}
				heading={fields.heading}
				iconSrc={iconSrc}
			/>
		</LayoutContainer>
	);
}
CreditWarning.displayName = 'CreditWarning';
