/**
 * Text
 */

import React, { type HTMLAttributes, type ReactNode } from 'react';
import { type Field, Text as SCText } from '@sitecore-jss/sitecore-jss-nextjs';

import type { HTMLTagName } from 'types';
import { cn } from 'utils/classNames';

export type Tags =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'p'
	| 'pXSmall'
	| 'pSmall'
	| 'pLarge'
	| 'div'
	| 'span'
	| 'caption'
	| 'screenReader'
	| 'cursiveHeader';

// Can technically be other tags like headings and spans, but the available
// attributes between them all are the same.
export interface Props extends HTMLAttributes<HTMLParagraphElement> {
	/** The html-tag/Component that will be outputted */
	as: Tags;

	/** If overflow-wrap: break-word; should be set */
	breakWord?: boolean;

	/** The text to render (takes precedence over the text prop) */
	children?: ReactNode;

	/** Pass optional classnames for the html */
	className?: string;

	/** Sitecore field for handling Sitecore Experience Editor */
	field?: Partial<Field<string | number>>;

	/** Sometimes we need the tag to be present, although empty, for aria id to not reference nothing */
	renderEmpty?: boolean;

	/** The style that will be used (instead of the tag default style) */
	styleAs?: Tags;

	/** The text to render (if no children are present) */
	text?: string;
}

const tagMapper: Record<Tags, HTMLTagName> = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
	h5: 'h5',
	h6: 'h6',
	p: 'p',
	pLarge: 'p',
	pSmall: 'p',
	pXSmall: 'p',
	div: 'div',
	span: 'span',
	caption: 'caption',
	screenReader: 'span',
	cursiveHeader: 'h2',
} as const;

export const FONT_CLASSES: Record<Tags, string> = {
	// Match heading sizes with richtext.js
	h1: 'font-alt font-bold text-h1-small md:text-h1-large',
	h2: 'font-alt font-bold text-h2-small md:text-h2-large',
	h3: 'font-alt font-bold text-h3-small md:text-h3-large',
	h4: 'font-standard font-bold text-h4-small md:font-alt md:text-h4-large',
	h5: 'font-standard font-bold text-h5-small md:text-h5-large',
	h6: 'font-standard font-bold text-h6-small md:text-h6-large',
	p: 'font-standard text-base',
	pXSmall: 'font-standard text-xs',
	pSmall: 'font-standard text-sm',
	pLarge: 'font-standard text-lg',
	div: 'font-standard text-base',
	span: 'font-standard text-base',
	caption: 'font-standard text-base font-bold',
	screenReader: 'sr-only',
	cursiveHeader: 'font-caveat text-[2.25rem]',
} as const;

/** Text component to render texts in all different variations */
export default function Text({
	as,
	breakWord = true,
	children,
	className = '',
	field,
	renderEmpty = false,
	styleAs,
	text,
	...htmlAttributes
}: Props) {
	const hasFieldWithValue = Boolean(
		field?.value !== undefined && field.value !== '',
	);
	// Field.editable means it's currently the experience editor, where fields
	// can be displayed as placeholders when empty.
	const hasContent = Boolean(
		children || text || hasFieldWithValue || field?.editable,
	);
	if (!hasContent && !renderEmpty) {
		return null;
	}
	const content = children || text;

	const Component = tagMapper[as];
	const appearanceTag = styleAs ?? as;
	const isScreenReader = appearanceTag === 'screenReader';

	return (
		<Component
			className={cn(
				FONT_CLASSES[appearanceTag],
				className,
				breakWord && !isScreenReader && 'break-words',
			)}
			{...htmlAttributes}
		>
			{field && <SCText field={field} />}
			{!field && <>{content} </>}
		</Component>
	);
}
Text.displayName = 'Text';
