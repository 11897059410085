/**
 * StorePopover
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';
import InfoBox from 'components/InfoBox';
import { mapStockStoreMarker, mapStoreMarker, StoreMap } from 'components/Map';
import Popover from 'components/Popover';
import ScreenReaderAnnouncementText from 'components/ScreenReaderAnnouncementText';
import SearchField from 'components/SearchField';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import StoreInfo from 'components/StoreInfo';
import StoreListItem from 'components/StoreListItem';
import Text from 'components/Text';
import { useRelativeDistance } from 'hooks';
import { StockStore, Store as ApiStore } from 'models/api';
import { formatDistance } from 'utils/format';
import { getTestDataAttrFrom, is, range } from 'utils/helpers';
import { useI18n } from 'utils/i18n';
import { Coordinate } from 'utils/math';

interface ExtendedStore extends StockStore {
	availableStockLevel: number;
	inStock: boolean;
}
interface ExtendedStoreWithDistance extends ExtendedStore {
	distance: number;
}

type Store = (
	| StockStore
	| ExtendedStore
	| ExtendedStoreWithDistance
	| ApiStore
) & {
	id: string;
	name: string;
};

interface Props {
	allStores: Store[] | undefined;
	baseId: string;
	isLoading: boolean;
	isOpen: boolean;
	nearbyStores?: Store[];
	onBackClick?: () => void;
	onClose: () => void;
	onStoreSelectClick: (store: Store) => void;
	selectedStoreId?: string;
	storeDetails?: ApiStore;
	title: string;
}

function StoreList({
	className,
	heading,
	isLoading,
	loaderAmount,
	onStoreSelectClick,
	selectedStoreId,
	stores,
}: {
	className?: string;
	heading?: string;
	isLoading?: boolean;
	loaderAmount?: number;
	onStoreSelectClick: (store: Store) => void;
	selectedStoreId: string | undefined;
	stores?: Store[] | undefined;
}) {
	const { t, tPlural } = useI18n();

	return (
		<div className={className}>
			<ScreenReaderAnnouncementText
				as="p"
				text={
					stores
						? tPlural('stores_count_text', stores.length, {
								num: stores.length,
							})
						: ''
				}
				hidden
				atomic
			/>
			{isLoading && loaderAmount && (
				<Skeleton>
					{range(loaderAmount).map((i) => (
						<SkeletonItem key={i} height="4.8rem" className="mt-2" />
					))}
				</Skeleton>
			)}
			{!isLoading && (
				<>
					<Text as="h3" className="mb-4" text={heading} />
					<ul className="flex flex-col gap-y-2">
						{stores?.map((store) => {
							const distance =
								'distance' in store
									? t('general_distance_away_text', {
											distance: formatDistance(store.distance / 1000),
										})
									: undefined;
							const isSelected = store.id === selectedStoreId;

							if ('availableStockLevel' in store) {
								return (
									<StoreListItem
										key={store?.id}
										name={store?.name}
										isSelected={isSelected}
										inStock={store.inStock}
										availableStockLevel={store.availableStockLevel}
										address={`${store.streetAddress}, ${store.postalCode}, ${store.city}`}
										storeArea={store.storeArea || ''}
										distance={distance}
										onClick={() => onStoreSelectClick(store)}
									/>
								);
							}
							return (
								<StoreListItem
									key={store.id}
									name={store?.name}
									storeArea={store.storeArea || ''}
									address={`${store.streetAddress}, ${store.postalCode}, ${store.city}`}
									isSelected={isSelected}
									openHours={store.todaysOpeningHours?.description || ''}
									isOpen={
										store.todaysOpeningHours?.state?.toUpperCase() === 'OPEN'
									}
									distance={distance}
									onClick={() => onStoreSelectClick(store)}
								/>
							);
						})}
					</ul>
				</>
			)}
		</div>
	);
}
StoreList.displayName = 'StorePopover_StoreList';

function ListView({
	allStores,
	isLoading,
	nearbyStores,
	onStoreSelectClick,
	selectedStoreId,
	storesWithDistance,
	userPosition,
}: {
	allStores: Store[] | undefined;
	isLoading: boolean;
	nearbyStores: Store[] | undefined;
	onStoreSelectClick: (store: Store) => void;
	selectedStoreId: string | undefined;
	storesWithDistance: Store[] | undefined;
	userPosition: Coordinate | undefined;
}) {
	const { t } = useI18n();
	return (
		<>
			{isLoading && (
				<Skeleton>
					{range(15).map((i) => (
						<SkeletonItem key={i} height="4.8rem" className="mt-2" />
					))}
				</Skeleton>
			)}
			{!isLoading && (
				<>
					{userPosition && is.arrayWithLength(storesWithDistance) && (
						<StoreList
							selectedStoreId={selectedStoreId}
							onStoreSelectClick={onStoreSelectClick}
							heading={t('stores_for_position_header')}
							stores={storesWithDistance}
						/>
					)}
					{!userPosition && (
						<>
							{is.arrayWithLength(nearbyStores) && (
								<StoreList
									onStoreSelectClick={onStoreSelectClick}
									selectedStoreId={selectedStoreId}
									className="mb-10"
									heading={t('store_popover_nearby_stores_header')}
									stores={nearbyStores}
								/>
							)}
							<StoreList
								selectedStoreId={selectedStoreId}
								onStoreSelectClick={onStoreSelectClick}
								heading={t('store_popover_all_stores_header')}
								stores={allStores}
							/>
						</>
					)}
				</>
			)}
		</>
	);
}
ListView.displayName = 'StorePopover_ListView';

/** Shows a filterable list of stores with a map view and store info view */
export default function StorePopover({
	allStores,
	baseId,
	isLoading: isLoadingProp,
	isOpen,
	nearbyStores,
	onBackClick,
	onClose: onCloseProp,
	onStoreSelectClick,
	selectedStoreId,
	storeDetails,
	title,
}: Props) {
	const { t } = useI18n();

	const id = `store-popover-${baseId}`;
	const titleId = `${id}-title`;

	const [searchValue, setSearchValue] = useState('');
	const [isMapVisible, setIsMapVisible] = useState(false);

	const filterStores = (stores: Store[] | undefined) =>
		stores?.filter((store) =>
			store.name.toLowerCase().includes(searchValue.toLowerCase()),
		);

	const onClose = useCallback(() => {
		setSearchValue('');
		onCloseProp();
	}, [onCloseProp]);

	useEffect(() => {
		if (storeDetails) {
			setSearchValue('');
		}
	}, [storeDetails]);

	const {
		clearUserPosition,
		error: userPositionError,
		getUserPosition,
		isLoading: isLoadingUserPosition,
		itemsWithDistance: allStoresWithDistance,
		userPosition,
	} = useRelativeDistance({
		items: allStores,
	});

	const allStoreMarkers = allStores?.map((store) => {
		if ('availableStockLevel' in store) {
			return mapStockStoreMarker({
				store,
				isSelected: selectedStoreId === store.id,
				onSelectClick: () => {
					onStoreSelectClick(store);
				},
			});
		}
		return mapStoreMarker({
			store,
			isSelected: selectedStoreId === store.id,
			onSelectClick: () => {
				onStoreSelectClick(store);
			},
		});
	});

	const nearbyStoreMarkers = (
		is.arrayWithLength(allStoresWithDistance)
			? allStoresWithDistance?.slice(0, 2)
			: nearbyStores
	)?.map((store) => {
		if ('availableStockLevel' in store) {
			return mapStockStoreMarker({
				store,
				isSelected: selectedStoreId === store.id,
				onSelectClick: () => {
					onStoreSelectClick(store);
				},
			});
		}
		return mapStoreMarker({
			store,
			isSelected: selectedStoreId === store.id,
			onSelectClick: () => {
				onStoreSelectClick(store);
			},
		});
	});

	const filteredStores = filterStores(
		userPosition ? allStoresWithDistance : allStores,
	);

	const isLoading = isLoadingProp || isLoadingUserPosition;
	const searchInputRef = useRef<HTMLInputElement>(null);

	return (
		<Popover
			isOpen={isOpen}
			id={id}
			titleId={titleId}
			onClose={onClose}
			// Force first page if loading since it has the skeleton.
			currentPageIndex={isLoading ? 0 : storeDetails ? 1 : 0}
			contentClassName={isMapVisible ? 'flex flex-col' : undefined}
			pages={[
				{
					title,
					content: (
						<>
							<ScreenReaderAnnouncementText
								as="div"
								atomic
								text={
									userPositionError ? (
										<InfoBox
											className="mb-6 mt-4"
											icon="error"
											variant="error"
											message={
												userPositionError?.PERMISSION_DENIED
													? t('stores_geolocation_permission_error_text')
													: t('stores_geolocation_general_error_text')
											}
										/>
									) : searchValue ? (
										<p className="sr-only">
											{t('stores_count_text', { num: filteredStores?.length })}
										</p>
									) : userPosition ? (
										<p className="sr-only">
											{t('stores_for_position_header')}
											{t('stores_count_text', {
												num: allStoresWithDistance?.length,
											})}
										</p>
									) : undefined
								}
							/>
							<form
								onSubmit={(event) => {
									event.preventDefault();
									searchInputRef.current?.blur();
								}}
							>
								<SearchField
									ref={searchInputRef}
									inputLabel={t('store_popover_search_input_label')}
									className="mb-6"
									hasSubmitButton={false}
									hasSearchIcon
									id="store-search"
									inputClassName="text-greyDarker border-grey"
									value={searchValue}
									onChange={(event) => {
										setSearchValue(event.target.value);
									}}
									handleInputClearClick={() => setSearchValue('')}
									placeholder={t('store_popover_search_input_label')}
								/>
							</form>
							{searchValue && (
								<StoreList
									selectedStoreId={selectedStoreId}
									onStoreSelectClick={onStoreSelectClick}
									isLoading={isLoading}
									stores={filteredStores}
									loaderAmount={15}
								/>
							)}
							{!searchValue && (
								<>
									<div className="mb-6 flex justify-between">
										<Button
											variant="text"
											onClick={
												userPosition ? clearUserPosition : getUserPosition
											}
										>
											{userPosition
												? t('general_forget_position_button')
												: t('general_use_position_button')}
											{isLoadingUserPosition && (
												<Icon
													className="ml-2 animate-spinnerRotate"
													name="spinner"
												/>
											)}
											{!isLoadingUserPosition && (
												<Icon
													className="ml-2"
													name={
														userPosition ? 'shareLocationOff' : 'shareLocation'
													}
												/>
											)}
										</Button>

										<Button
											variant="text"
											onClick={() => setIsMapVisible((current) => !current)}
										>
											{isMapVisible
												? t('stores_show_as_list_button')
												: t('stores_show_on_map_button')}
											<Icon
												className="ml-2"
												name={isMapVisible ? 'rows' : 'location'}
											/>
										</Button>
									</div>

									{!isMapVisible && (
										<ListView
											selectedStoreId={selectedStoreId}
											onStoreSelectClick={onStoreSelectClick}
											isLoading={isLoading}
											userPosition={userPosition}
											storesWithDistance={allStoresWithDistance}
											nearbyStores={nearbyStores}
											allStores={allStores}
										/>
									)}
									{isMapVisible && is.arrayWithLength(allStoreMarkers) && (
										<StoreMap
											stores={allStoreMarkers}
											nearbyStores={nearbyStoreMarkers}
											userPosition={userPosition}
											className="h-auto grow"
											mapClassName="h-full"
										/>
									)}
								</>
							)}
						</>
					),
				},
				{
					title: storeDetails?.name || title,
					content: storeDetails && (
						<>
							<StoreInfo
								headingLevel="h2"
								todaysOpeningHours={storeDetails.todaysOpeningHours}
								storeArea={storeDetails.storeArea}
								streetAddress={storeDetails.streetAddress}
								postalCode={storeDetails.postalCode}
								city={storeDetails.city}
								regularOpeningHours={storeDetails.regularOpeningHours}
								specialOpeningHours={storeDetails.specialOpeningHours}
								addressSectionClassName="flex justify-between"
								afterAddressContent={
									onBackClick ? (
										<Button
											onClick={onBackClick}
											variant="text"
											className="mb-10 mt-2"
										>
											{t('store_popover_choose_other_store_button')}
										</Button>
									) : undefined
								}
							/>
							<Button
								className="mt-10"
								variant="secondary"
								displayWidth="full"
								target="_blank"
								href={`https://www.google.com/maps/search/?api=1&query=${storeDetails.latitude},${storeDetails.longitude}`}
								data-cy={getTestDataAttrFrom(`${id}-directions`)}
							>
								{t('stores_directions_button')}
							</Button>
							<Button
								className="mt-4"
								displayWidth="full"
								variant="primary"
								href={storeDetails.url}
								data-cy={getTestDataAttrFrom(`${id}-details`)}
							>
								{t('store_popover_visit_store_button')}
							</Button>
						</>
					),
				},
			]}
		/>
	);
}
StorePopover.displayName = 'StorePopover';
