import type {
	JulaProCustomerContact,
	JulaProReference,
	JulaProReferenceListItem,
	JulaProUpdatedReference,
} from 'models/api';
import { API_URL, fetchData } from 'utils/fetch';

/** Gets the reference list */
export const requestGetReferences = () =>
	fetchData<JulaProReferenceListItem[]>(`${API_URL}Customer/julapro/reference`);

/** Gets a single reference by id */
export const requestGetReference = (code = '') =>
	fetchData<JulaProReference>(
		`${API_URL}Customer/julapro/reference/${encodeURIComponent(code)}`,
	);

/** Updates a reference */
export const requestUpdateReference = (
	code: string,
	reference: JulaProUpdatedReference,
) =>
	fetchData(
		`${API_URL}Customer/julapro/reference/${encodeURIComponent(code)}`,
		{ method: 'PUT', body: JSON.stringify(reference) },
	);

/** Adds a new reference */
export const requestAddReference = (value: JulaProUpdatedReference) =>
	fetchData(
		`${API_URL}Customer/julapro/reference?referenceName=${encodeURIComponent(
			value.newReferenceName,
		)}`,
		{
			method: 'POST',
			body: JSON.stringify(value.contactPermissions),
		},
	);

/** Remove reference */
export const requestRemoveReference = (code: string) =>
	fetchData(
		`${API_URL}Customer/julapro/reference/${encodeURIComponent(code)}`,
		{
			method: 'DELETE',
		},
	);

/** Gets the contact list */
export const requestGetContacts = () =>
	fetchData<JulaProCustomerContact[]>(`${API_URL}Customer/julapro/contact`);
