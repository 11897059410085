import { type ErrorName, ERRORS, type Errors } from 'errors';

interface ResultBase<T, Err extends Error> {
	isFailure<ErrName extends ErrorName>(
		this: Result<T, Err>,
		errorName?: ErrName,
	): this is Failure<T, InstanceType<Errors[ErrName]>>;
	isSuccess(this: Result<T, Err>): this is Success<T, Err>;
}
interface Failure<T, Err extends Error> extends ResultBase<T, Err> {
	readonly error: Err;
}
interface Success<T, Err extends Error> extends ResultBase<T, Err> {
	readonly value: T;
}

export type Result<T, Err extends Error> = Failure<T, Err> | Success<T, Err>;

/** Create a successful result with a value. */
export function success<T, Err extends Error>(value: T): Result<T, Err> {
	return {
		value,
		isFailure: <ErrName extends ErrorName>(): this is Failure<
			T,
			InstanceType<Errors[ErrName]>
		> => false,
		isSuccess: (): this is Success<T, Err> => true,
	};
}

/** Create a failed result with an error. */
export function failure<T, Err extends Error>(error: Err): Result<T, Err> {
	return {
		error,
		isFailure: <ErrName extends ErrorName>(
			errorName?: ErrName,
		): this is Failure<T, InstanceType<Errors[ErrName]>> =>
			errorName ? error instanceof ERRORS[errorName] : true,
		isSuccess: (): this is Success<T, Err> => false,
	};
}
