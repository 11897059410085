import React from 'react';

import JulaLogo from 'components/JulaLogo';
import Link from 'components/Link';
import RichText from 'components/RichText';

interface Props {
	className?: string;
	text?: string;
}

/** Site logo and a short info text. */
export default function PageFooterInfo({ className, text }: Props) {
	return (
		<div className={className}>
			<Link
				href="/"
				// Pointless but pretty — match focus outline with logo radius.
				className="inline-block shrink-0 rounded-[10px] focus-visible:focus-outline-inverted"
			>
				<JulaLogo className="w-[120px]" />
			</Link>
			{text && <RichText html={text} className="mt-3" inheritStyle />}
		</div>
	);
}
PageFooterInfo.displayName = 'PageFooterInfo';
