import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlockMedia from 'components/BlockMedia';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import { useIsEditing } from 'hooks';
import type { ColorField, JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import type { ProductCard } from 'models/productCard';
import { getEditorMargin } from 'utils/business-logic';
import { cn } from 'utils/classNames';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		backgroundColor?: ColorField;
		datasourceName?: string;
		description?: Field<string>;
		heading?: Field<string>;
		mirror?: Field<boolean>;
		products?: ProductCard[];
		squareImage: Field<DigizuiteAsset[]>;
	};
};

export default function ContentGuide({ fields, params }: Props) {
	const isEditing = useIsEditing();
	if (isEditing) {
		return (
			<ComponentPlaceholder
				componentName="ContentGuide"
				deprecated="Deadline Aug 25, 2025 [DP-7854]"
			/>
		);
	}

	if (!fields) {
		return null;
	}

	const {
		datasourceName,
		description,
		heading,
		mirror,
		products,
		squareImage,
	} = fields;
	const image = getAsset('Templated 1:1', squareImage);
	const isMirrored = Boolean(mirror?.value);

	if (!image) {
		return (
			<ComponentPlaceholder
				componentName="ContentGuide"
				description="Requires an image"
				className={getEditorMargin(params)}
			/>
		);
	}
	const renderMediaBlock = (className?: string) => (
		<BlockMedia
			variant="squareSmall"
			imgSrc={image?.src}
			imgAlt={image?.alt}
			products={products}
			productsDisplayType="shopByMedia"
			gtmItemListId="block"
			gtmItemListName={`Block: ${datasourceName ?? 'ContentGuideUnknown'}`}
			className={cn(className, 'shrink-0 sm:w-1/3')}
		/>
	);

	const textBlock = (
		<div className="sm:mt-6">
			<Text as={params?.heading ?? 'h2'} field={heading} className="mb-2" />
			<Text as="p" field={description} />
		</div>
	);

	return (
		<LayoutContainer
			backgroundColor={fields.backgroundColor?.fields?.colorHex?.value}
			outerClassName={getEditorMargin(params)}
			id={params?.anchor}
			className="items-start"
		>
			<div className="flex max-w-col-6 gap-4 max-sm:flex-col md:gap-6">
				{isMirrored ? (
					<>
						{renderMediaBlock('sm:hidden')}
						{textBlock}
						{renderMediaBlock('max-sm:hidden')}
					</>
				) : (
					<>
						{renderMediaBlock()}
						{textBlock}
					</>
				)}
			</div>
		</LayoutContainer>
	);
}
ContentGuide.displayName = 'ContentGuide';
