import type { ActorRefFrom, StateFrom } from 'xstate';
import { createMachine, sendParent } from 'xstate';

import type { Variants } from './toast.machine';

interface ToastItemMachineContext {
	id: string;
	link?: string;
	linkText?: string;
	message?: string;
	variant?: Variants;
}

export type ToastItemMachineState = StateFrom<typeof toastItemMachine>;

export const selectIsMovingIn = (state: ToastItemMachineState) =>
	state.matches('moveIn');
export const selectIsMovingOut = (state: ToastItemMachineState) =>
	state.matches('moveOut');

export const toastItemMachine = createMachine(
	{
		id: 'ToastItemMachine',
		schema: {
			context: {} as ToastItemMachineContext,
		},
		tsTypes: {} as import('./toastItem.machine.typegen').Typegen0,
		context: {
			id: '',
			message: '',
			variant: 'information',
		},
		preserveActionOrder: true,
		initial: 'moveIn',
		states: {
			moveIn: {
				description: 'Moves in state, to allow css animations to be runned.',
				after: {
					'500': {
						target: '#ToastItemMachine.showMessage',
						actions: [],
						internal: false,
					},
				},
			},
			showMessage: {
				description: 'Shows the message of X seconds, then goes to moveOut.',
				after: {
					'5000': {
						target: '#ToastItemMachine.moveOut',
						actions: [],
						internal: false,
					},
				},
			},
			moveOut: {
				description: 'Moves out state, to allow css animations to be runned.',
				after: {
					'500': {
						target: '#ToastItemMachine.done',
						actions: [],
						internal: false,
					},
				},
			},
			done: {
				description: 'All is done',
				entry: ['notifyParentDone'],
				type: 'final',
			},
		},
	},
	{
		actions: {
			notifyParentDone: sendParent((context) => ({
				type: 'TOAST.DONE',
				id: context.id,
			})),
		},
	},
);
export type ToastItemMachineActor = ActorRefFrom<typeof toastItemMachine>;
