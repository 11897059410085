import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import CreditApplication from 'components/CreditApplication';
import type {
	JulaComponentProps,
	PartialPopoverProps,
} from 'lib/component-props';
import type { FormFields } from 'models/sitecore';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields: {
		content: Field<string>;
		description: Field<string>;
		form: FormFields;
		heading: Field<string>;
		name: Field<string>;
		submitText: Field<string>;
	};
	partialProps?: PartialPopoverProps;
};

export default function AccountJulaProRaiseCreditForm({
	fields,
	partialProps,
}: Props) {
	const { content, description, form, heading, submitText } = fields;

	const { t } = useI18n();

	return (
		<CreditApplication
			onClose={() => partialProps?.onPopoverClose()}
			customerType="julapro"
			heading={heading.value}
			description={description.value}
			content={content?.value}
			form={form}
			eventType="JULA_PRO_REQUEST_CREDIT"
			submitText={submitText?.value}
			successHeading={t('julapro_account_raise_credit_success_heading')}
			successCreditLimitKey="julapro_account_raise_credit_success_text"
			failHeading={t('julapro_account_raise_credit_fail_heading')}
			failTextKey="julapro_account_raise_credit_fail_text"
			signingCancelText={t('account_details_apply_credit_signing_cancel_text')}
			signingCancelHeading={t('account_details_apply_credit_fail_heading')}
			cancelButtonText={t('account_details_cancel_button')}
			continueButtonText={t('account_details_continue_button')}
			loadingText={t('julapro_account_raise_credit_load_text')}
		/>
	);
}
AccountJulaProRaiseCreditForm.displayName = 'AccountJulaProRaiseCreditForm';
