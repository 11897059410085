import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import Chip from 'components/Chip';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import SwipeWrapper from 'components/SwipeWrapper';
import Text from 'components/Text';
import { useSitecoreContext } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { SitecoreLink } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { cn } from 'utils/classNames';
import { filterTruthy } from 'utils/collection';
import { is } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		heading?: Field<string>;
		isCarousel?: Field<boolean>;
		links?: SitecoreLink[];
	};
};

export default function ShortcutChips({ fields, params }: Props) {
	const { sitecoreContext } = useSitecoreContext();

	if (!fields) {
		return null;
	}

	const { heading } = fields;
	const headingLevel = params?.heading || 'h2';
	const isCarousel = Boolean(fields.isCarousel?.value);
	const links = filterTruthy(fields.links || [], 'href', 'text');
	const hasLinks = is.arrayWithLength(links);

	if (sitecoreContext?.pageEditing && !hasLinks && !heading?.value) {
		return (
			<ComponentPlaceholder
				componentName="ShortcutChips"
				className={getEditorMargin(params)}
			/>
		);
	}

	if (!hasLinks) {
		return null;
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
		>
			<Text as={headingLevel} field={heading} className="mb-4" />
			<SwipeWrapper
				activeClassName={cn(isCarousel && 'md:hidden', !isCarousel && 'hidden')}
				inactiveClassName={cn(
					'flex flex-wrap gap-4',
					isCarousel && 'max-md:hidden',
				)}
				pullGutters
			>
				{links.map((link) => (
					<li key={`${link.href}-${link.text}`}>
						<Chip color="grey" href={link.href} text={link.text} />
					</li>
				))}
			</SwipeWrapper>
		</LayoutContainer>
	);
}
ShortcutChips.displayName = 'ShortcutChips';
