import { formatTemplate, type FormatTemplateValue, LOCALE } from 'utils/format';

export type Dictionary = Record<string, string>;

type TParams = Record<string, FormatTemplateValue>;

export class I18n {
	private dict: Dictionary;

	private pluralRules: Intl.PluralRules;

	constructor(dictionary: Dictionary, locale: string | undefined = LOCALE) {
		this.dict = dictionary;
		this.pluralRules = new Intl.PluralRules(locale);
	}

	setDictionary = (dictionary: Dictionary) => {
		this.dict = dictionary;
	};

	/**
	 * Retrieve a translation, returning the key if it's missing.
	 *
	 * @example
	 *
	 * t('hello_text')
	 * // => 'Hi there'
	 *
	 * t('missing_key')
	 * // => 'missing_key'
	 *
	 * t('welcome_message', { name: 'Joe' })
	 * // With key text `Welcome {{name}}!`
	 * // => 'Welcome Joe!'
	 */
	t = (key: string, params?: TParams): string => {
		const text = this.dict[key] || key;
		return params ? formatTemplate(text, params) : text;
	};

	/**
	 * Retrieve a translation, returning an empty string if it's missing.
	 *
	 * @example
	 *
	 * tEmpty('hello_text')
	 * // => 'Hi there'
	 *
	 * tEmpty('missing_key')
	 * // => ''
	 */
	tEmpty = (key: string, params?: TParams): string => {
		const text = this.dict[key] || '';
		return params ? formatTemplate(text, params) : text;
	};

	/**
	 * Retrieve a plural translation, falling back to the base translation and
	 * finally returning the key if it's missing.
	 *
	 * The number will be supplied as the template variable `number`.
	 *
	 * Possible key suffixes, depending on language: `zero`, `one`, `two`, `few`,
	 * `many` and `other`.
	 *
	 * @example
	 *
	 * // With the following keys:
	 * {
	 *   products: `{{number}} products`,
	 *   products_one: `{{number}} product`,
	 * }
	 * tPlural('products', 18)
	 * // => '18 products'
	 * tPlural('products', 1)
	 * // => '1 product'
	 *
	 * // With additional template values:
	 * {
	 *   products: `{{name}} has {{number}} products in the cart`,
	 *   products_one: `{{name}} has one product in the cart`,
	 * }
	 * tPlural('products', 3, { name: 'Joe' })
	 * // => 'Joe has 3 products in the cart'
	 * tPlural('products', 1, { name: 'Joe' })
	 * // => 'Joe has one product in the cart'
	 */
	tPlural = (key: string, num: number, params: TParams = {}): string => {
		const pluralKey = `${key}_${this.pluralRules.select(num)}`;
		const text = this.dict[pluralKey] || this.dict[key] || key;
		return formatTemplate(text, { number: num, ...params });
	};
}
