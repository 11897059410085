/**
 * FAQSearch
 */

import React, { useEffect, useRef, useState } from 'react';

import ArrowLink from 'components/ArrowLink';
import Button from 'components/Button';
import { ComboboxOption } from 'components/Combobox';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenReaderAnnouncementText from 'components/ScreenReaderAnnouncementText';
import SearchCombobox, {
	type SearchComboboxChildProps,
} from 'components/SearchCombobox';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { useFaqSearch } from 'hooks';
import { cn } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

interface Props {
	className?: string;
}

/** Text for search result text. */
export function FAQResultText({
	itemCount,
	query,
	showNoResultsText,
	showResultsText,
	visibleResultsText = true,
}: {
	itemCount: number;
	query: string;
	showNoResultsText: boolean;
	showResultsText: boolean;
	visibleResultsText?: boolean;
}) {
	const { t, tPlural } = useI18n();
	const resultText = showResultsText
		? tPlural('faq_search_full_results_text', itemCount, {
				amount: itemCount,
				query,
			})
		: showNoResultsText
			? t('faq_search_no_search_results_text', { query })
			: '';
	return (
		<>
			<ScreenReaderAnnouncementText
				as="p"
				atomic
				level="assertive"
				text={resultText}
				hidden={!visibleResultsText}
			/>
			{showNoResultsText && (
				<Text as="p" className="mt-4">
					{t('faq_search_no_search_results_recommendation_text')}
				</Text>
			)}
		</>
	);
}
FAQResultText.displayName = 'FAQSearch_FAQResultText';

/** The first few results displayed in the search combobox dropdown. */
function DropdownResults({
	baseId,
	hasOpened,
	hasSearchQuery,
	isOpen,
	listboxProps,
	searchQuery,
	selectedOptionId,
}: SearchComboboxChildProps) {
	// Add overlay to page if search is active
	useEffect(() => {
		if (isOpen) {
			document.body.classList.add('faq-search-overlay');
		}
		return () => {
			document.body.classList.remove('faq-search-overlay');
		};
	}, [isOpen]);

	// Checking hasOpened instead of isOpen to have the results render even
	// if closed, when they have been loaded. Then they can be focused when
	// pressing down arrow from a closed state.
	const { isLoading, items } = useFaqSearch(hasOpened ? searchQuery : '', {
		limit: 4,
	});
	const hasResults = hasSearchQuery && items.length > 0;
	const showResults = hasSearchQuery && !isLoading && hasResults;
	const showNoResults = hasSearchQuery && !isLoading && !hasResults;
	const hasContent = isLoading || showResults || showNoResults;

	return (
		<div className={cn(hasContent && 'p-2 pt-4 md:p-4 md:pt-6')}>
			{isLoading && (
				<Skeleton className="flex flex-col gap-4">
					<SkeletonItem width="10rem" height="1.5rem" />
					<SkeletonItem width="13rem" height="1.5rem" />
				</Skeleton>
			)}

			<FAQResultText
				showNoResultsText={showNoResults}
				visibleResultsText={false}
				showResultsText={showResults}
				query={searchQuery}
				itemCount={items.length}
			/>

			<div {...listboxProps} className={cn(showResults && '-mt-4')}>
				{showResults &&
					items.map((item, i) => (
						<ComboboxOption
							key={item.url}
							as="p"
							id={`${baseId}-option-${i + 1}`}
							selectedId={selectedOptionId}
							className="mt-4 w-fit pr-1"
						>
							<ArrowLink href={item.url} text={item.summary} />
						</ComboboxOption>
					))}
			</div>
		</div>
	);
}
DropdownResults.displayName = 'FAQSearch_DropdownResults';

/** The full result displayed below the search field. */
function FullResults({
	closeCombobox,
	focusAfterSearch,
	rawInputValue,
	useFormSubmit,
}: SearchComboboxChildProps) {
	const { t } = useI18n();
	const firstExpandedItemRef = useRef<HTMLAnchorElement | null>(null);

	const [submittedQuery, setSubmittedQuery] = useState('');
	const { isLoading, items } = useFaqSearch(submittedQuery);
	const itemCount = items.length;

	const initialLimit = 10;
	const [showAll, setShowAll] = useState(false);

	const hasQuery = submittedQuery.length > 0;
	const hasResults = hasQuery && itemCount > 0;
	const showResults = hasQuery && !isLoading && hasResults;
	const showNoResults = hasQuery && !isLoading && !hasResults;

	const handleShowAllClick = () => {
		setShowAll(true);
	};
	// Focus the first new item when it has been rendered.
	useEffect(() => {
		if (showAll) {
			firstExpandedItemRef.current?.focus();
		}
	}, [showAll]);

	useFormSubmit(() => {
		if (rawInputValue) {
			setSubmittedQuery(rawInputValue);
			closeCombobox();
			focusAfterSearch();
		}
	});

	useEffect(() => {
		// Clear results when clearing the search input.
		if (!rawInputValue) {
			setShowAll(false);
			setSubmittedQuery('');
		}
	}, [rawInputValue]);

	return (
		<div className="mt-4">
			{isLoading && (
				<div className="mt-8 flex flex-col items-center">
					<Text className="mb-2" as="p">
						{t('faq_search_loading_text')}
					</Text>
					<LoadingSpinner size="small" spinnerColor="julaRed" />
				</div>
			)}

			<FAQResultText
				showNoResultsText={showNoResults}
				query={submittedQuery}
				showResultsText={showResults}
				itemCount={items.length}
			/>

			{showResults && (
				<>
					<ul>
						{items.slice(0, showAll ? 999_999 : initialLimit).map((item, i) => (
							<li key={item.url} className="mt-4">
								<ArrowLink
									ref={i === initialLimit ? firstExpandedItemRef : null}
									href={item.url}
									text={item.summary}
								/>
							</li>
						))}
					</ul>
					{itemCount > initialLimit && !showAll && (
						<Button
							variant="primary"
							className="mt-8"
							onClick={handleShowAllClick}
						>
							{t('faq_search_show_all_results_button_text')}
						</Button>
					)}
				</>
			)}
		</div>
	);
}
FullResults.displayName = 'FAQSearch_FullResults';

export default function FAQSearch({ className }: Props) {
	const { t } = useI18n();

	return (
		<SearchCombobox
			id="faq-search"
			className={className}
			inputLabel={t('faq_search_input_label')}
			inputPlaceholder={t('faq_search_placeholder_text')}
			submitButtonLabel={t('search_show_all_button')}
			resultsComponent={DropdownResults}
			afterComboboxComponent={FullResults}
			alwaysShowClearButton
			controlsContainerClassName="z-faqSearchField"
			resultsContainerClassName="z-faqSearchResults"
		/>
	);
}
FAQSearch.displayName = 'FAQSearch';
