import {
	Cart,
	type CartRequest,
	MinimizedAddToCartResponse,
	VariantMetaData,
} from 'models/api';
import { formatCartForRequest } from 'utils/business-logic';
import { API_URL, fetchData } from 'utils/fetch';
import { createUrl } from 'utils/url';

export function addMultipleToCart(
	variants: { quantity: number; variantId: string }[],
) {
	return fetchData<MinimizedAddToCartResponse>(`${API_URL}Cart/mini/variants`, {
		method: 'POST',
		body: JSON.stringify(variants),
	});
}

export function addRemoveCart({
	customizationId,
	distinctValue,
	metaData,
	qty,
	variantId,
}: {
	customizationId?: string;
	distinctValue?: boolean;
	metaData?: VariantMetaData;
	qty: number;
	variantId: string;
}) {
	return fetchData<Cart>(
		createUrl(`${API_URL}Cart/variants/${variantId}`, {
			qty,
			setDistinctValue: distinctValue ? 'true' : 'false',
			customizationId,
		}),
		{
			method: 'POST',
			body: metaData ? JSON.stringify(metaData) : undefined,
		},
	);
}

export function addRemoveCartMini({
	customizationId,
	metaData,
	requestAdditionalSales = false,
	returnAddedProducts = false,
	variantId,
}: {
	customizationId?: string;
	metaData?: VariantMetaData;
	requestAdditionalSales?: boolean;
	returnAddedProducts?: boolean;
	variantId: string;
}) {
	return fetchData<MinimizedAddToCartResponse>(
		createUrl(`${API_URL}Cart/mini/variants/${variantId}`, {
			qty: 1,
			setDistinctValue: 'false',
			returnAccessories: requestAdditionalSales ? 'true' : 'false',
			returnAddedProducts: returnAddedProducts ? 'true' : 'false',
			customizationId,
		}),
		{
			method: 'POST',
			body: metaData ? JSON.stringify(metaData) : undefined,
		},
	);
}

export function fetchCartMini() {
	return fetchData<MinimizedAddToCartResponse>(`${API_URL}Cart/mini`);
}

export async function updateCart(
	cart: Cart | undefined,
	updatedCartProperties: { cartDiscountCode: string | undefined } | undefined,
) {
	if (!cart) {
		throw new Error('Cart is undefined');
	}
	const updatedCart: CartRequest = formatCartForRequest(
		cart,
		updatedCartProperties,
	);

	return fetchData<Cart>(`${API_URL}Cart/${cart.id}`, {
		method: 'PUT',
		body: JSON.stringify(updatedCart),
	});
}
