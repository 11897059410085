import React from 'react';

import type {
	DiscountCode,
	GiftCard,
	Summary,
	TotalSummary,
	Variant,
} from 'models/api';

import PurchaseSummary from './PurchaseSummary';

interface Props {
	bonusDiscounts: DiscountCode[] | undefined;
	giftCards: GiftCard[] | undefined;
	heading?: string;
	savingTotalSumSummary: Summary | undefined;
	totalSummary: TotalSummary | undefined;
	variants: Variant[] | undefined;
}

/** Product and price summaries used on the order confirmation page. */
export default function ConfirmationSummary({
	bonusDiscounts,
	giftCards,
	heading,
	savingTotalSumSummary,
	totalSummary,
	variants,
}: Props) {
	return (
		<PurchaseSummary
			heading={heading}
			giftCards={giftCards}
			bonusDiscounts={bonusDiscounts}
			savingTotalSumSummary={savingTotalSumSummary}
			totalSummary={totalSummary}
			variants={variants}
		/>
	);
}
ConfirmationSummary.displayName = 'ConfirmationSummary';
