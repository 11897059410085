import React, { useEffect, useState } from 'react';

import StorePopover from 'components/StorePopover';
import {
	type StoreIdName,
	useSelectedStore,
	useSelectedStoreInfo,
} from 'contexts';
import { useAllStores, useNearbyStores } from 'hooks';
import type { Store } from 'models/api';
import { useI18n } from 'utils/i18n';

interface Props {
	isOpen: boolean;
	onClose: () => void;
}

/** Popover for the 'home store' selector */
export default function StoreSelectorPopover({ isOpen, onClose }: Props) {
	const { t } = useI18n();

	const { selectedStore, setSelectedStore } = useSelectedStore();

	const { storeInfo } = useSelectedStoreInfo();
	const [storeDetails, setStoreDetails] = useState<Store | undefined>(
		undefined,
	);
	const [shouldFetchNearbyStores, setShouldFetchNearbyStores] = useState(false);
	const [shouldFetchAllStores, setShouldFetchAllStores] = useState(false);
	const onStoreSelect = (store: StoreIdName) => {
		setSelectedStore(store);
		onClose();
	};

	const { isLoading: isLoadingAllStores, stores: allStores } =
		useAllStores(shouldFetchAllStores);
	const { isLoading: isLoadingNearbyStores, stores: nearbyStores } =
		useNearbyStores(selectedStore?.id, shouldFetchNearbyStores);

	useEffect(() => {
		if (isOpen) {
			if (selectedStore) {
				setShouldFetchNearbyStores(true);
				setShouldFetchAllStores(true);
			} else {
				setShouldFetchAllStores(true);
			}
		}
	}, [isOpen, selectedStore]);

	useEffect(() => {
		if (storeInfo) setStoreDetails(storeInfo);
	}, [storeInfo]);

	const isLoading =
		!storeDetails && (isLoadingAllStores || isLoadingNearbyStores);
	return (
		<StorePopover
			baseId="store-selctor-popover"
			title={
				selectedStore?.id
					? t('store_popover_change_store_title')
					: t('store_popover_select_store_title')
			}
			selectedStoreId={selectedStore?.id}
			isLoading={isLoading}
			isOpen={isOpen}
			onClose={onClose}
			allStores={allStores}
			onStoreSelectClick={(store) => {
				onStoreSelect({ id: store.id, name: store.name });
			}}
			nearbyStores={nearbyStores}
			storeDetails={storeDetails}
			onBackClick={() => {
				setStoreDetails(undefined);
			}}
		/>
	);
}
StoreSelectorPopover.displayName = 'StoreSelectorPopover';
