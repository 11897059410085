/**
 * ProductListActiveFilterChips
 */

import React, { type MouseEventHandler } from 'react';

import Button from 'components/Button';
import Chip from 'components/Chip';
import { FacetResponse } from 'models/api';
import { cn } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

interface Props {
	activeFacets: FacetResponse[];
	className?: string;
	onClearClick: MouseEventHandler<HTMLButtonElement>;
	onFilterChipClick: MouseEventHandler<HTMLButtonElement>;
}

/** Currently active product list filter chips. */
export default function ProductListActiveFilterChips({
	activeFacets,
	className,
	onClearClick,
	onFilterChipClick,
}: Props) {
	const { t } = useI18n();

	if (activeFacets.length === 0) {
		return null;
	}

	return (
		<div className={cn(className, 'flex flex-wrap gap-4')}>
			{activeFacets.flatMap((facet) =>
				facet.facetItems?.map((item) => (
					<Chip
						key={`${facet.id}-${item.item}`}
						color="red"
						text={item.item}
						aria-label={t('remove_filter_screenreader', {
							item: `${facet.title} ${item.item}`,
						})}
						name={facet.id}
						value={item.item}
						onClick={onFilterChipClick}
						isRemovable
					/>
				)),
			)}
			<Button variant="text" className="ml-2" onClick={onClearClick}>
				{t('product_list_clear_filters_button')}
			</Button>
		</div>
	);
}
ProductListActiveFilterChips.displayName = 'ProductListActiveFilterChips';
