/**
 * AccountMenuLinks
 */

import React, { type MouseEventHandler } from 'react';

import Icon from 'components/Icon';
import {
	IconMenu,
	IconMenuItem,
	type IconMenuItemProps,
} from 'components/IconMenu';
import { AccountMenuItem } from 'models/sitecore';
import { cn } from 'utils/classNames';
import { filterTruthy } from 'utils/collection';
import { getTestDataAttrFrom } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	className?: string;
	isLogoutLoading?: boolean;
	items: AccountMenuItem[];
	logoutClassName?: string;
	logoutSize?: IconMenuItemProps['size'];
	onLogoutClick?: MouseEventHandler<HTMLButtonElement>;
	size?: IconMenuItemProps['size'];
	withBorder?: IconMenuItemProps['withBorder'];
}

/** List of account links for logged in users. */
export default function AccountMenuLinks({
	className,
	isLogoutLoading,
	items,
	logoutClassName,
	logoutSize,
	onLogoutClick,
	size,
	withBorder,
}: Props) {
	const { t } = useI18n();

	// Flatten objects and filter empty items.
	const itemsData =
		items.map((link) => ({
			iconName: link?.fields.iconName,
			...link?.fields.link,
		})) || [];
	const visibleItems = filterTruthy(itemsData, 'href', 'text');

	if (visibleItems.length === 0) {
		return null;
	}

	return (
		<IconMenu className={className}>
			{visibleItems.map(({ href, iconName, text }) => (
				<IconMenuItem
					key={`${href}-${text}`}
					icon={iconName}
					href={href}
					text={text}
					size={size}
					withBorder={withBorder}
					dataCy={getTestDataAttrFrom(iconName)}
				/>
			))}
			{onLogoutClick && (
				<IconMenuItem
					itemClassName={cn(logoutClassName, isLogoutLoading && 'opacity-50')}
					text={
						<>
							{t('account_logout_button')}
							{isLogoutLoading && (
								<Icon name="spinner" className="ml-2 animate-spinFast" />
							)}
						</>
					}
					onClick={onLogoutClick}
					size={logoutSize || size}
					withBorder={withBorder}
				/>
			)}
		</IconMenu>
	);
}
AccountMenuLinks.displayName = 'AccountMenuLinks';
