/**
 * GdprAccordion
 */

import React from 'react';
import { useSelector } from '@xstate/react';

import Accordion from 'components/Accordion';
import RichText from 'components/RichText';
import { useGlobalMachinesContext } from 'contexts';
import { useSitecoreContext } from 'hooks';
import { selectIsAuthenticatedUser } from 'state-machines/authentication';

interface Props {
	/** Additional class names. */
	className?: string;
}

interface AccordionProps extends Props {
	heading: string;
	text: string;
}

export function GdprAccordion({ className, heading, text }: AccordionProps) {
	if (!heading || !text) {
		return null;
	}

	return (
		<Accordion
			id="gdpr-accordion"
			color="white"
			size="small"
			title={heading}
			className={className}
		>
			<RichText html={text} className="richtext--gdpr bg-greyLighter p-4" />
		</Accordion>
	);
}
GdprAccordion.displayName = 'GdprAccordion';

/** Component that looks at Sitecore context and renders different GDPR texts based on if user is signed in or not. */
export default function GdprAccordionState(props: Props) {
	const { sitecoreContext } = useSitecoreContext();
	const { userService } = useGlobalMachinesContext();

	const isAuthenticatedUser = useSelector(
		userService,
		selectIsAuthenticatedUser,
	);
	const { gdpr } = sitecoreContext;
	const text = isAuthenticatedUser ? gdpr.identifiedText : gdpr.anonymousText;
	const heading = isAuthenticatedUser
		? gdpr.identifiedHeading
		: gdpr.anonymousHeading;

	return <GdprAccordion {...props} heading={heading} text={text} />;
}
GdprAccordionState.displayName = 'GdprAccordionState';
