/**
 * ComboboxOption
 */

import React from 'react';
import type { ReactNode } from 'react';

import { HTMLAttributes, HTMLTagName } from 'types';
import { cn } from 'utils/classNames';

interface Props extends HTMLAttributes<HTMLElement> {
	/** Tag to render, defaults to li. */
	as?: HTMLTagName;

	/** Option text */
	children: ReactNode;

	/** Extra class names */
	className?: string;

	/** HTML ID */
	id: string;

	/** HTML ID of the currently selected option */
	selectedId: string;
}

/** A single option in a combobox. */
export default function ComboboxOption({
	as: Component = 'li',
	children,
	className,
	id,
	selectedId,
	...attrs
}: Props) {
	return (
		<Component
			{...attrs}
			className={cn(className, id === selectedId && 'focus-outline rounded-sm')}
			role="option"
			id={id}
			aria-selected={id === selectedId ? true : undefined}
		>
			{children}
		</Component>
	);
}
ComboboxOption.displayName = 'ComboboxOption';
