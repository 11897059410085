import React from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';
import InfoBox from 'components/InfoBox';
import { type LoginOption, LoginOptions } from 'components/Login';
import Text from 'components/Text';
import type { LoginMethod } from 'models/connect/oauth';
import { useI18n } from 'utils/i18n';

interface Props {
	customerEmail: string | null | undefined;
	customerFirstName: string | undefined;
	errorMessage: string | undefined;
	loginOptions: LoginOption[];
	onChangeEmailClick: () => void;
	onSelectLoginMethod: (customerType: LoginMethod) => void;
}

export default function CheckoutClubLogin({
	customerEmail,
	customerFirstName,
	errorMessage,
	loginOptions,
	onChangeEmailClick,
	onSelectLoginMethod,
}: Props) {
	const { t } = useI18n();

	return (
		<>
			<Text as="h1" className="mb-4">
				{t('account_intro_heading')} {customerFirstName}!
			</Text>
			<Text as="p">{t('checkout_login_identification_intro_text')}</Text>
			<div className="mb-8 mt-4 inline-flex min-h-12 items-center rounded-full border border-greyLight px-4 py-2">
				<Icon color="cta" className="mr-2" name="check" />
				<Text as="p">{customerEmail}</Text>
			</div>

			<Text as="h2" styleAs="h6" className="mb-4">
				{t('account_login_button_heading')}
			</Text>
			{errorMessage && (
				<InfoBox
					icon="error"
					variant="error"
					message={errorMessage}
					className="mb-8 mt-6"
				/>
			)}
			<LoginOptions
				memberType="club"
				loginOptions={loginOptions}
				onSelectLoginMethod={onSelectLoginMethod}
			/>

			<div className="mt-4 flex justify-center">
				<Button variant="text" onClick={onChangeEmailClick}>
					{t('checkout_login_change_email_button')}
				</Button>
			</div>
		</>
	);
}
CheckoutClubLogin.displayName = 'CheckoutClubLogin';
