// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconFlagDe(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path
				fill="#de0000"
				d="M23.32 8c.92 2.59.92 5.41 0 8H.68a11.95 11.95 0 0 1 0-8z"
			/>
			<path
				fill="#010101"
				d="M.68 8C2.38 3.22 6.93 0 12 0s9.63 3.22 11.32 8z"
			/>
			<path
				fill="#fc0"
				d="M.68 16c1.7 4.78 6.25 8 11.32 8s9.63-3.22 11.32-8z"
			/>
		</svg>
	);
}
IconFlagDe.displayName = 'IconFlagDe';
