import { publicRuntimeConfig } from 'config';
import { isTestmode } from 'hooks';
import type { Ticket } from 'models/api';
import { getPostSigningRedirectUrl } from 'pages/api/sign/helpers';
import { API_URL, fetchData } from 'utils/fetch';

import { CreditRequestParams } from './credit.types';
// A getter instead of just a variable for Storybook, isTestmode is initially
// undefined there for some reason.
const getQueryParams = () => (isTestmode() ? '?testmode=true' : '');
const postSigningRedirectUrl = getPostSigningRedirectUrl();

export function requestJulaProCreditApi(params: CreditRequestParams) {
	return fetchData<Ticket>(
		`${API_URL}Customer/julapro/credit${getQueryParams()}`,
		{
			method: 'POST',
			body: JSON.stringify({ ...params, postSigningRedirectUrl }),
		},
	);
}

export function requestTokenRefresh() {
	return fetch('/api/signin/session/?forceRefresh=true');
}

export function requestJulaClubApplyForCredit(params: CreditRequestParams) {
	return fetchData<Ticket>(
		`${API_URL}Customer/julaclub/${publicRuntimeConfig?.NEXT_PUBLIC_JULA_MARKET_CODE}/credit${getQueryParams()}`,
		{
			method: 'POST',
			body: JSON.stringify({ ...params, postSigningRedirectUrl }),
		},
	);
}

export function requestJulaClubRaiseCredit(params: CreditRequestParams) {
	return fetchData<Ticket>(
		`${API_URL}Customer/julaclub/raiseCredit${getQueryParams()}`,
		{
			method: 'POST',
			body: JSON.stringify({ ...params, postSigningRedirectUrl }),
		},
	);
}

export function requestPollCredit() {
	return fetchData<Ticket>(`${API_URL}Ticket/Customer`);
}

export const openSignWindow = async (iframeSrc: string | undefined) =>
	new Promise<Window | null>((resolve, reject) => {
		if (!iframeSrc || iframeSrc === '') {
			reject();
			return;
		}

		const authWindow = window.open(iframeSrc);

		if (authWindow) {
			resolve(authWindow);
		} else {
			reject();
		}
	});
