import type { InputHTMLAttributes } from 'react';
import type { FieldState } from 'final-form';

import { FormInput } from 'models/sitecore';
import { i18n } from 'utils/i18n';

type InputValue = InputHTMLAttributes<unknown>['value'];
type ValidatorReturn =
	| string
	| string[]
	| Promise<string | undefined>
	| Promise<string[] | undefined>
	| undefined;
export type FieldValidator = (
	value: InputValue,
	allValues: object,
	meta?: FieldState<string | number | readonly string[] | undefined>,
) => ValidatorReturn;

export function required(errorMessage?: string): FieldValidator {
	const error = errorMessage || i18n.t('FieldIsRequired');
	return (value) => (value ? undefined : error);
}

export function pattern({
	errorMessage,
	patternString,
	required,
}: {
	errorMessage: string | undefined;
	patternString: string;
	required: boolean;
}): FieldValidator {
	const pattern = new RegExp(patternString);
	const message = errorMessage || i18n.t('FieldInvalidPattern');
	return (value) => {
		const fieldValue = value?.toString() ?? '';
		const isValid = pattern.test(fieldValue);

		if (required) {
			return isValid ? undefined : message;
		}
		if (value) {
			return isValid ? undefined : message;
		}
		return undefined;
	};
}

export function composeValidators(
	validators: FieldValidator | FieldValidator[] | undefined,
) {
	if (!validators) {
		return undefined;
	}
	return (
		value: InputValue,
		allValues: object,
		meta?: FieldState<InputValue>,
	) => {
		if (!Array.isArray(validators)) {
			return validators(value, allValues, meta);
		}
		let error: ValidatorReturn;
		for (const validator of validators) {
			error = validator(value, allValues, meta);
			if (error) {
				break;
			}
		}
		return error;
	};
}
