/**
 * BonusCheque
 */

import React, { type ChangeEventHandler } from 'react';

import { Checkbox } from 'components/FormUi';
import Text from 'components/Text';
import { formatDate } from 'utils/format';
import { useI18n } from 'utils/i18n';
import { slugify } from 'utils/string';

interface Props {
	amount: string;
	checkNumber: string;
	isUsed: boolean;
	onChange: ChangeEventHandler<HTMLInputElement>;
	validThru: string;
}

export default function BonusCheque({
	amount,
	checkNumber,
	isUsed,
	onChange,
	validThru,
}: Props) {
	const { t } = useI18n();
	return (
		<div className="relative flex justify-between rounded-border bg-julaRedDark p-4 text-white md:p-6 forced-colors:border">
			<div className="grow">
				<Checkbox
					label={`${t('checkout_bonus_check_id_label')} ...${checkNumber.slice(
						Math.max(0, checkNumber.length - 3),
					)}`}
					id={`bonus-${slugify(checkNumber)}`}
					checked={isUsed}
					className="font-bold"
					color="white"
					inputClassName="mr-2"
					labelClassName="hover:before:forced-colors-outline before:absolute before:inset-0 before:z-1 before:rounded-border hover:before:outline-offset-1"
					onChange={onChange}
				/>
				<p className="ml-10 mt-1 md:ml-9">
					{t('checkout_bonus_check_valid_thru_label')} {formatDate(validThru)}
				</p>
			</div>

			<div className="text-right">
				<Text as="pSmall">{t('checkout_bonus_check_balance_label')}</Text>
				<Text as="p" styleAs="h2" className="whitespace-nowrap">
					{amount}
				</Text>
			</div>
		</div>
	);
}
BonusCheque.displayName = 'BonusCheque';
