import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';

interface GlobalPopoversContextType {
	closeContactForm: () => void;
	closeCustomerChat: () => void;
	closeStoreSelector: () => void;
	isContactFormOpen: boolean;
	isCustomerChatOpen: boolean;
	isStoreSelectorOpen: boolean;
	openContactForm: () => void;
	openCustomerChat: () => void;
	openStoreSelector: (productId: string) => void;
	storeSelectorProductId: string;
}
const GlobalPopoversContext = createContext<GlobalPopoversContextType>(
	{} as GlobalPopoversContextType,
);
interface Props {
	children: React.ReactNode;
}
export function GlobalPopoversContextProvider({ children }: Props) {
	const [isStoreSelectorOpen, setIsStoreSelectorOpen] = useState(false);
	const [storeSelectorProductId, setStoreSelectorProductId] = useState('');

	const [isContactFormOpen, setIsContactFormOpen] = useState(false);
	const [isCustomerChatOpen, setIsCustomerChatOpen] = useState(false);

	const openContactForm = useCallback(() => {
		setIsContactFormOpen(true);
	}, []);
	const closeContactForm = useCallback(() => {
		setIsContactFormOpen(false);
	}, []);
	const openCustomerChat = useCallback(() => {
		setIsCustomerChatOpen(true);
	}, []);
	const closeCustomerChat = useCallback(() => {
		setIsCustomerChatOpen(false);
	}, []);

	const openStoreSelector = useCallback((productId: string) => {
		setIsStoreSelectorOpen(true);
		setStoreSelectorProductId(productId);
	}, []);
	const closeStoreSelector = useCallback(() => {
		setIsStoreSelectorOpen(false);
		setStoreSelectorProductId('');
	}, []);
	const value = useMemo(
		() => ({
			openStoreSelector,
			closeStoreSelector,
			isStoreSelectorOpen,
			storeSelectorProductId,
			openContactForm,
			openCustomerChat,
			closeContactForm,
			closeCustomerChat,
			isContactFormOpen,
			isCustomerChatOpen,
		}),
		[
			openStoreSelector,
			closeStoreSelector,
			isStoreSelectorOpen,
			storeSelectorProductId,
			openContactForm,
			openCustomerChat,
			closeContactForm,
			closeCustomerChat,
			isContactFormOpen,
			isCustomerChatOpen,
		],
	);
	return (
		<GlobalPopoversContext.Provider value={value}>
			{children}
		</GlobalPopoversContext.Provider>
	);
}

GlobalPopoversContextProvider.displayName = 'GlobalPopoversContextProvider';

export function useGlobalPopoversContext() {
	return useContext(GlobalPopoversContext);
}
