import React from 'react';

import Img from 'components/Img';
import { ProgressStepsVertical } from 'components/ProgressSteps';
import Text from 'components/Text';
import { useI18n } from 'utils/i18n';

interface Props {
	className?: string;
	email?: string;
	heading: string;
	orderNumber?: string;
}

export default function OrderConfirmationIntro({
	className,
	email,
	heading,
	orderNumber,
}: Props) {
	const { t } = useI18n();

	return (
		<div className={className}>
			<Img
				src="/images/graphics/confirmation-graphics.svg"
				className="mb-4 md:mb-8"
			/>
			<Text as="h1" className="mb-4">
				{heading}
			</Text>
			{orderNumber && (
				<p className="mt-2">
					{t('order_confirmation_order_number_text')}{' '}
					<strong>{orderNumber}</strong>
				</p>
			)}
			{email && (
				<p className="mt-2">
					{t('order_confirmation_delivery_message_text')}{' '}
					<strong>{email}</strong>
				</p>
			)}
			<ProgressStepsVertical
				className="mt-8 md:mt-14"
				steps={[
					{
						text: t('order_confirmation_stepper_processing_order_label'),
						completed: true,
					},
					{ text: t('order_confirmation_stepper_delivering_order_label') },
					{
						text: t('order_confirmation_stepper_ready_for_pickup_order_label'),
					},
					{ text: t('order_confirmation_stepper_delivered_order_label') },
				]}
			/>
		</div>
	);
}
OrderConfirmationIntro.displayName = 'OrderConfirmationIntro';
