import React from 'react';
import { useSelector } from '@xstate/react';

import AuthJulaConnect from 'components/AuthJulaConnect';
import Popover from 'components/Popover';
import Tabs from 'components/Tabs';
import {
	useFeatureToggle,
	useGlobalMachinesContext,
	useGlobalMemberPopoversContext,
} from 'contexts';
import { useRouteChange } from 'hooks';
import { LoginMethod } from 'models/connect/oauth';
import {
	selectErrorMessage,
	selectIsAuthenticatedUser,
	selectIsAwaitingAuthAction,
} from 'state-machines/authentication';
import { LoginPanelType } from 'types';
import { getTestDataAttrFrom } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import ClubLogin from './ClubLogin';
import ProLogin from './ProLogin';

/** Login popover panel. */
export default function Login() {
	const { authService, userService } = useGlobalMachinesContext();
	const isAwaitingAuthAction = useSelector(
		authService,
		selectIsAwaitingAuthAction,
	);

	const {
		activeTab,
		closeLogin,
		isLoginOpen,
		openJulaClubLogin,
		openSignUpJulaClub,
		openSignUpJulaPro,
		setActiveTab,
	} = useGlobalMemberPopoversContext();

	const { julaClubEnabled, julaProEnabled } = useFeatureToggle();

	const errorMessage = useSelector(authService, selectErrorMessage);

	const isAuthenticatedUser = useSelector(
		userService,
		selectIsAuthenticatedUser,
	);
	useRouteChange('routeChangeComplete', (url) => {
		if (url.includes('returnPath')) {
			openJulaClubLogin();
		}
	});
	const { t } = useI18n();

	const id = 'page-header-login';
	const popoverHeaderId = `popover-${id}-title`;

	return (
		<Popover
			isOpen={isLoginOpen}
			title={t('account_login_button')}
			id={`popover-${id}`}
			titleId={popoverHeaderId}
			onClose={() => {
				closeLogin();
				if (!isAuthenticatedUser) {
					authService.send('RELOAD_IFRAME');
				}
			}}
			contentClassName="flex flex-col"
		>
			{!isAuthenticatedUser && !isAwaitingAuthAction && (
				<AuthJulaConnect className="grow" />
			)}
			{isAwaitingAuthAction && (
				<Tabs<LoginPanelType>
					data-cy={getTestDataAttrFrom('login-tabs')}
					tabListLabelledBy={popoverHeaderId}
					activeTabId={activeTab}
					onTabChange={(tab) => {
						setActiveTab(tab);
					}}
					items={[
						julaClubEnabled && {
							id: 'LoginJulaClub',
							title: 'JulaClub',
							content: (
								<ClubLogin
									showHeading={false}
									errorMessage={errorMessage}
									onSelectLoginMethod={(loginMethod: LoginMethod) => {
										authService.send({
											type: 'SELECT_LOGIN_CLUB_STAFF',
											loginMethod,
										});
									}}
									onBecomeMemberClick={() => {
										openSignUpJulaClub();
										closeLogin();
									}}
								/>
							),
						},
						julaProEnabled && {
							id: 'LoginJulaPro',
							title: 'JulaPro',
							content: (
								<ProLogin
									showHeading={false}
									errorMessage={errorMessage}
									onSelectLoginMethod={(loginMethod: LoginMethod) => {
										authService.send({
											type: 'SELECT_LOGIN_PRO',
											loginMethod,
										});
									}}
									onBecomeMemberClick={() => {
										openSignUpJulaPro();
										closeLogin();
									}}
								/>
							),
						},
					]}
				/>
			)}
		</Popover>
	);
}
Login.displayName = 'Login';
