import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import type { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import { MicroContentMediaLink } from 'components/MicroContent';
import type { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		heading: Field<string>;
		link: LinkField;
		squareImage: Field<DigizuiteAsset[]>;
	};
};

export default function MediaLink({ fields, params }: Props) {
	if (!fields) {
		return null;
	}
	const { heading, link, squareImage } = fields;
	const image = getAsset('Templated 1:1', squareImage);
	if (!heading.value || !link.value.href || !image?.src) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="MediaLink"
				description="Requires an image, heading and link"
			/>
		);
	}
	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
		>
			<div className="max-w-col-6">
				<MicroContentMediaLink
					className="align-middle"
					href={link.value.href}
					imgSrc={image?.src}
					target={link.value.target}
					text={heading.value}
				/>
			</div>
		</LayoutContainer>
	);
}
MediaLink.displayName = 'MediaLink';
