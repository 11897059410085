interface Logger {
	/** Log a message when not running tests. */
	(...message: unknown[]): void;
	/** Log an error when not running tests. */
	error(...message: unknown[]): void;
	/** Log a warning when not running tests. */
	warning(...message: unknown[]): void;
}

function logMessage(level: 'log' | 'warn' | 'error', ...message: unknown[]) {
	if (process.env.NODE_ENV !== 'test') {
		console[level](...message);
	}
}

export const log: Logger = (...message) => {
	logMessage('log', ...message);
};
log.error = (...message) => {
	logMessage('error', ...message);
};
log.warning = (...message) => {
	logMessage('warn', ...message);
};
