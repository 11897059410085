/**
 * CircularGauge
 */

import React from 'react';

import { cn } from 'utils/classNames';

interface Props {
	className?: string;
	percent: number;
}

/** A circular percentage gauge. */
export default function CircularGauge({ className, percent }: Props) {
	const r = 24;
	const circ = 2 * Math.PI * r;
	const strokePercent = ((100 - Math.min(percent ?? 0, 100)) * circ) / 100;

	return (
		<svg
			viewBox="25 25 50 50"
			className={cn('inline-block -rotate-90', className)}
		>
			<circle
				cx="50"
				cy="50"
				fill="none"
				r={r}
				stroke="currentColor"
				strokeLinecap="round"
				strokeWidth="2"
				className="text-greyLight"
			/>
			<circle
				cx="50"
				cy="50"
				fill="none"
				r={r}
				stroke="currentColor"
				strokeLinecap="square"
				strokeWidth="2"
				strokeDasharray={circ}
				strokeDashoffset={strokePercent}
				className="text-julaRed"
			/>
		</svg>
	);
}
CircularGauge.displayName = 'Gauge';
