import React from 'react';

import ListItem from 'components/ListItem';
import Price from 'components/Price';
import type { Option } from 'models/api';

interface Props {
	onSizeClick: (sizeOption: Option) => void;
	sizes: Option[] | undefined;
}

export default function SizeView({ onSizeClick, sizes }: Props) {
	return (
		<ul className="flex flex-col gap-2">
			{sizes?.map((size) => (
				<ListItem
					onClick={() => onSizeClick(size)}
					as="li"
					title={size.text || ''}
					key={size.key}
					className="rounded-border border border-grey p-4"
					endContent={
						size.price ? <Price price={size.price} size="micro" /> : null
					}
				/>
			))}
		</ul>
	);
}
SizeView.displayName = 'ProductPrintPopover_SizeView';
