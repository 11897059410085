import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import Img from 'components/Img';
import InfoBox from 'components/InfoBox';
import { LayoutContainer } from 'components/Layout';
import Link from 'components/Link';
import Text from 'components/Text';
import { useIsEditing } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { Asset } from 'models/asset';
import {
	getEditorMargin,
	getShortcutBrandLogoImage,
} from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';
import { is } from 'utils/helpers';

interface Brand {
	displayName: string;
	fields?: {
		/** JSON string in the shape of `Asset[]`. */
		brandAssetsJson?: Field<string>;
		hasProductsWithShowListing?: Field<boolean>;
		id?: Field<string>;
		title?: Field<string>;
	};
	id: string;
	name: string;
	url: string;
}

type Props = JulaComponentProps & {
	fields?: {
		brands: Brand[];
		heading: Field<string>;
	};
};

const MIN_COUNT = 6;

export default function ShortcutBrands({ fields, params }: Props) {
	const isEditing = useIsEditing();

	if (!fields) {
		return null;
	}

	const { heading } = fields;
	const brands = filterTruthy(
		fields.brands
			.filter((brand) => brand.fields?.hasProductsWithShowListing?.value)
			.map((brand) => ({
				url: brand.url,
				imageSrc: getShortcutBrandLogoImage(
					JSON.parse(brand.fields?.brandAssetsJson?.value || 'null') as
						| Asset[]
						| null,
				),
				imageAlt: brand.fields?.title?.value,
			})),
		'imageSrc',
		'imageAlt',
	);

	if (!is.arrayWithLength(brands)) {
		return (
			<ComponentPlaceholder
				componentName="ShortcutBrands"
				description="Requires brands with images"
				className={getEditorMargin(params)}
			/>
		);
	}

	const hasEnoughBrands =
		is.arrayWithLength(brands) && brands.length >= MIN_COUNT;

	if (!isEditing && !hasEnoughBrands) {
		return null;
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			withGrid
		>
			{isEditing && !hasEnoughBrands && (
				<div className="col-span-full">
					<InfoBox
						icon="info"
						className="inline-flex"
						message={`Only ${brands.length} valid brands (with image + title) added, minimum amount is ${MIN_COUNT}`}
					/>
				</div>
			)}
			<Text
				as={params?.heading ?? 'h2'}
				field={heading}
				styleAs="h1"
				className="col-span-full mb-4 md:mb-6"
			/>
			<div className="col-span-full grid grid-cols-4 justify-items-center gap-x-6 gap-y-8 md:flex md:flex-wrap md:gap-14 xl:gap-20">
				{/* The minimum amount is also the maximum actually displayed. */}
				{brands.slice(0, MIN_COUNT).map(({ imageAlt, imageSrc, url }) => (
					<Link
						key={url}
						href={url}
						className="col-span-2 flex items-center hover:opacity-70 md:mr-6"
					>
						<Img
							src={imageSrc}
							alt={imageAlt}
							useFallbackOnError
							className="h-auto max-h-12 min-[400px]:max-w-[10rem] md:max-h-16 xl:max-w-[12.5rem]"
						/>
					</Link>
				))}
			</div>
		</LayoutContainer>
	);
}
ShortcutBrands.displayName = 'ShortcutBrands';
