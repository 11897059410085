import React from 'react';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import Button from 'components/Button';
import CartListItem from 'components/CartListItem';
import Icon from 'components/Icon';
import { LayoutContainer } from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import { hasReducedPrice } from 'components/Price';
import { PriceSummaries } from 'components/Summary';
import Text from 'components/Text';
import type { StoreIdName } from 'contexts';
import type { Wishlist as WishlistModel, WishlistVariant } from 'models/api';
import { getProductImageSrc } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';

export interface Props {
	heading: string;
	isLoading: boolean;
	moveAllVariantsToCartButtonText: string;
	moveAllVariantsToWishlistButtonText?: string;
	moveVariantToCartButtonText: string;
	moveVariantToWishlistButtonText?: string;
	onCopy?: () => unknown;
	onEmptyWishlist?: (() => void) | undefined;
	onlineCommerceEnabled: boolean;
	onMoveAllVariantsToCart: (() => void) | undefined;
	onMoveAllVariantsToWishlist?: () => void;
	onMoveVariantToCart: (variant: WishlistVariant) => void;
	onMoveVariantToWishlist?: (variant: WishlistVariant) => void;
	onRemoveVariant?: (variant: WishlistVariant) => void;
	onShare?: () => unknown;
	onUpdateVariantQty?: (variant: WishlistVariant, newQuantity: number) => void;
	selectedStore: StoreIdName | undefined;
	sharedText?: string;
	shareState?: ActionButtonState;
	storesEnabled: boolean;
	wishlist: WishlistModel | undefined;
	wishlistEnabled?: boolean;
}

/** Wishlist layout component */
export default function Wishlist({
	heading,
	isLoading,
	moveAllVariantsToCartButtonText,
	moveAllVariantsToWishlistButtonText,
	moveVariantToCartButtonText,
	moveVariantToWishlistButtonText,
	onCopy,
	onEmptyWishlist,
	onlineCommerceEnabled,
	onMoveAllVariantsToCart,
	onMoveAllVariantsToWishlist,
	onMoveVariantToCart,
	onMoveVariantToWishlist,
	onRemoveVariant,
	onShare,
	onUpdateVariantQty,
	selectedStore,
	sharedText,
	shareState,
	storesEnabled,
	wishlist,
	wishlistEnabled,
}: Props) {
	const { t } = useI18n();
	const { categories, savingSummaries, totalSummary } = wishlist ?? {};

	const hasUnsellableItems = wishlist?.categories?.some((category) =>
		category.variants?.some((variant) => !variant.webStock?.inStock),
	);

	return (
		<LayoutContainer withGrid className="mt-4 md:mt-10">
			<Text className="col-span-full" as="h1">
				{heading}
			</Text>
			{sharedText && (
				<Text as="p" className="col-span-full mt-4">
					{sharedText}
				</Text>
			)}

			<div className="col-span-full mt-6 flex items-start gap-4 md:mt-8 print:hidden">
				{onShare && (
					<ActionButton
						customState={shareState}
						showSuccess={false}
						rounded
						onClick={onShare}
						className="md:hidden"
					>
						<Icon name="share" className="mr-2" />
						{t('list_share_link_button')}
					</ActionButton>
				)}
				{onCopy && (
					<ActionButton
						customState={shareState}
						showSuccess={false}
						rounded
						onClick={onCopy}
						className="max-md:hidden"
					>
						<Icon name="copyLink" className="mr-2" />

						{t('list_copy_link_button')}
					</ActionButton>
				)}
				<Button
					className="max-md:hidden"
					onClick={() => globalThis.print()}
					rounded
				>
					<Icon name="printer" className="mr-2" />
					{t('wishlist_print_wishlist_button')}
				</Button>
				{onEmptyWishlist && (
					<div className="place-self-center">
						<Button variant="text" size="small" onClick={onEmptyWishlist}>
							{t('wishlist_empty_wishlist_button')}
						</Button>
					</div>
				)}
			</div>

			<div className="relative col-span-full mt-6 md:mt-14 print:block">
				{isLoading && (
					<LoadingSpinner
						variant="dashing"
						spinnerColor="julaRed"
						trackColor="transparent"
						className="z-1000 absolute left-1/2 top-1/2 -ml-20 -mt-20"
					/>
				)}
				<div className="divide-y divide-grey border-y border-grey">
					{categories?.flatMap((category) =>
						category.variants?.map((variant) => {
							const getSelectedStoreStock = () => {
								const stock = variant.storeStock?.stocks?.find(
									(store) => store.store.id === selectedStore?.id,
								);
								if (!stock) {
									return undefined;
								}
								return {
									name: selectedStore?.name ?? 'Unknown',
									categoryName: category.name,
									categoryColor: category.categoryColor,
									...stock,
								};
							};

							return (
								<CartListItem
									key={variant.id}
									link={variant.url}
									imageSrc={getProductImageSrc(variant.listImageFormats, true)}
									imageAlt="image"
									heading={variant.title}
									id={variant.id}
									price={variant.price}
									reducedPrice={hasReducedPrice(variant.rowSummary.priceType)}
									rowSummary={variant.rowSummary}
									quantity={variant.qty}
									onUpdateVariantQty={
										onlineCommerceEnabled && onUpdateVariantQty
											? (qty) => onUpdateVariantQty(variant, qty)
											: undefined
									}
									selectedStoreStock={
										storesEnabled ? getSelectedStoreStock() : undefined
									}
									allStoresInStockCount={
										storesEnabled ? variant.storeStock?.inStockCount : undefined
									}
									webStock={variant.webStock}
									moveVariantToCartButtonText={moveVariantToCartButtonText}
									onMoveVariantToCart={
										onlineCommerceEnabled && variant?.webStock?.inStock
											? () => onMoveVariantToCart(variant)
											: undefined
									}
									onMoveVariantToWishlist={
										wishlistEnabled && onMoveVariantToWishlist
											? () => onMoveVariantToWishlist(variant)
											: undefined
									}
									moveVariantToWishlistButtonText={
										moveVariantToWishlistButtonText
									}
									onRemoveVariant={
										onlineCommerceEnabled && onRemoveVariant
											? () => onRemoveVariant(variant)
											: undefined
									}
									removeVariantButtonText={t(
										'wishlist_summary_product_remove_product_button',
									)}
									volumePrice={variant.volumePrice}
								/>
							);
						}),
					)}
				</div>

				<PriceSummaries
					className="mt-8 md:mt-14"
					totalSummary={totalSummary}
					savingTotalSumSummary={savingSummaries?.find(
						(sum) => sum.sumType === 'SavingTotalSum',
					)}
				/>
			</div>
			<div className="col-span-full mb-4 mt-6 flex gap-4 max-md:flex-col md:justify-end print:hidden">
				{onMoveAllVariantsToWishlist && (
					<Button
						variant="secondary"
						size="large"
						onClick={onMoveAllVariantsToWishlist}
					>
						{moveAllVariantsToWishlistButtonText}
					</Button>
				)}
				{!hasUnsellableItems && onMoveAllVariantsToCart && (
					<Button
						variant="cta"
						size="large"
						disabled={!onlineCommerceEnabled}
						onClick={onMoveAllVariantsToCart}
					>
						{moveAllVariantsToCartButtonText}
					</Button>
				)}
			</div>
		</LayoutContainer>
	);
}

Wishlist.displayName = 'Wishlist';
