import React from 'react';
import { useRouter } from 'next/router';
import { OrderDetails } from 'src/components/Order';

import Button from 'components/Button';
import Icon from 'components/Icon';
import InfoBox from 'components/InfoBox';
import { AccountLayoutContainer } from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import Placeholder from 'components/Placeholder';
import Text from 'components/Text';
import { publicRuntimeConfig } from 'config';
import {
	useCustomerInformation,
	useGlobalLinks,
	useIsEditing,
	useOrderHistoryDetails,
} from 'hooks';
import { JulaComponentProps } from 'lib/component-props';
import { formatDate } from 'utils/format';
import { useI18n } from 'utils/i18n';
import { createUrl, removeTrailingSlash } from 'utils/url';

type Props = JulaComponentProps & {};

export default function AccountOrderDetails({ rendering }: Props) {
	const { t } = useI18n();
	const router = useRouter();
	const isEditing = useIsEditing();
	const { track } = useGlobalLinks();

	const { error, isLoading, orderDetails } = useOrderHistoryDetails(
		removeTrailingSlash(router.asPath).split('/').pop() || '',
	);
	const { customerInformation } = useCustomerInformation();

	const loadedAndOk = !isLoading && !error;
	const {
		discounts,
		lines,
		shippingCost,
		totalSum,
		transactionHead,
		transactionInformation,
		vatAmount,
	} = orderDetails ?? {};

	const formattedPurchaseDate =
		transactionHead?.dateOfPurchase &&
		formatDate(transactionHead?.dateOfPurchase);
	const trackingUrl =
		track && transactionHead?.orderId && customerInformation?.id
			? createUrl(track, {
					id: transactionHead?.orderId,
					c: customerInformation?.id,
				})
			: undefined;
	const showContactReferences =
		publicRuntimeConfig?.NEXT_PUBLIC_SHOW_REFERENCE?.toLowerCase() === 'true';
	if (isEditing) {
		return (
			<AccountLayoutContainer
				heading={`${t('account_purchases_order')}: Exp editor placeholder`}
				rendering={rendering}
			>
				<Placeholder
					rendering={rendering}
					name="jula-account-details"
					isInNestedPlaceholder
				/>
			</AccountLayoutContainer>
		);
	}

	return (
		<AccountLayoutContainer
			rendering={rendering}
			heading={
				loadedAndOk ? (
					<div className="flex items-start justify-between">
						{formattedPurchaseDate &&
							`${t('account_purchases_order')}: ${formattedPurchaseDate}`}
						<Button
							size="small"
							className="max-lg:hidden print:hidden"
							onClick={() => globalThis.print()}
							rounded
						>
							<Icon name="printer" className="mr-2" />
							{t('print_button')}
						</Button>
					</div>
				) : null
			}
		>
			{error && (
				<InfoBox icon="error" variant="error">
					{t('jula_pro_order_fetch_error_text')}
				</InfoBox>
			)}
			{isLoading && (
				<div className="flex items-center justify-center">
					<LoadingSpinner />
				</div>
			)}
			{loadedAndOk && (
				<OrderDetails
					trackingUrl={trackingUrl}
					isEcomOrder={transactionHead?.type === 'EcomOrder'}
					isResursBankOrder={transactionHead?.primaryPaymentMethodId === 'RES'}
					formattedPurchaseDate={formattedPurchaseDate}
					rendering={rendering}
					showContactReferences={showContactReferences}
					lines={lines}
					shippingCost={shippingCost}
					totalSum={totalSum}
					vatAmount={vatAmount}
					bonusDiscount={discounts?.bonusDiscount}
					buyer={transactionInformation?.buyer}
					campaignDiscount={discounts?.campaignDiscount}
					customerDiscount={discounts?.customerDiscount}
					miscDiscount={discounts?.miscDiscount}
					phoneNumber={transactionInformation?.contactDetails?.phoneNumber}
					email={transactionInformation?.contactDetails?.email}
					deliveryAddress={transactionInformation?.deliveryAddress}
					invoiceAddress={transactionInformation?.invoiceAddress}
					deliveryMethod={transactionHead?.deliveryMethod}
					fixedReferenceText={transactionInformation?.fixedReferenceText}
					freeReferenceText={transactionInformation?.freeReferenceText}
					orderNumber={transactionHead?.orderNumber}
					pointOfPurchase={transactionHead?.pointOfPurchase}
					primaryPaymentMethod={transactionHead?.primaryPaymentMethod}
					statusText={transactionHead?.status.text}
				/>
			)}
			<div className="mx-auto mt-10 flex justify-center lg:hidden print:hidden">
				<Button size="small" onClick={() => globalThis.print()} rounded>
					<Icon name="printer" className="mr-2" />
					{t('print_button')}
				</Button>
			</div>
		</AccountLayoutContainer>
	);
}
AccountOrderDetails.displayName = 'AccountOrderDetails';
