/**
 * Tab
 */

import React from 'react';

import PlainButton from 'components/PlainButton';
import { cn } from 'utils/classNames';

import { getPanelId, getTabId } from './helpers';

interface Props {
	/** If the tab is currently selected */
	isSelected: boolean;

	/** Tab click handler */
	onClick: (tabId: string) => void;

	/** Tab base ID */
	tabId: string;

	/** Tab title */
	title: string;
}

/** A single tab in a Tablist. */
const Tab = React.forwardRef<HTMLButtonElement, Props>(
	({ isSelected, onClick, tabId, title }, ref) => (
		<PlainButton
			ref={ref}
			role="tab"
			id={getTabId(tabId)}
			aria-controls={getPanelId(tabId)}
			aria-selected={isSelected}
			tabIndex={isSelected ? undefined : -1}
			onClick={() => onClick(tabId)}
			className={cn(
				'min-h-[3.5rem] min-w-0 flex-1 px-4 py-2 text-center hover:forced-colors-outline hover:bg-greyLighter',
				'break-words font-standard text-base font-bold leading-tight',
				isSelected && 'text-greyDarker',
				!isSelected && 'text-greyDark',
			)}
		>
			{title}
		</PlainButton>
	),
);
Tab.displayName = 'Tab';

export default Tab;
