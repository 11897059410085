import { CreateJulaProCustomerMachineState } from './createJulaProCustomer.machine';

export const selectRequiresAdditionalInformation = (
	state: CreateJulaProCustomerMachineState,
) => state.context.requiresAdditionalInfo;

export const selectIsLoading = (state: CreateJulaProCustomerMachineState) =>
	state.hasTag('loading');

export const selectIsSearchingOrganization = (
	state: CreateJulaProCustomerMachineState,
) => state.matches('selectOrganization.searchingOrganization');

export const selectErrorSearchingOrganization = (
	state: CreateJulaProCustomerMachineState,
) => state.matches('selectOrganization.errorSearchingOrganization');

export const selectSerchingOrganizationSuccess = (
	state: CreateJulaProCustomerMachineState,
) => state.matches('selectOrganization.searchOrganizationSuccess');

export const selectWaitingForNewOrganizationSearch = (
	state: CreateJulaProCustomerMachineState,
) => state.matches('selectOrganization.waitingForNewOrganizationSearch');

export const selectIsGetCustomerIdFromUser = (
	state: CreateJulaProCustomerMachineState,
) => state.matches('selectOrganization.getCustomerIdFromUser');

export const selectIsInStepOne = (state: CreateJulaProCustomerMachineState) =>
	state.matches('selectOrganization');

export const selectIsInStepTwo = (state: CreateJulaProCustomerMachineState) =>
	state.matches('selectCredit');

export const selectIsCheckingCustomerCreationStatus = (
	state: CreateJulaProCustomerMachineState,
) => state.hasTag('showLoader');

export const selectIsInStepThree = (state: CreateJulaProCustomerMachineState) =>
	state.matches('registerCustomer.editOrganizationDetails') ||
	state.matches('registerCustomer') ||
	state.matches('registerCustomer.validationError');

export const selectIsCustomerCreated = (
	state: CreateJulaProCustomerMachineState,
) => state.matches('customerCreated');

export const selectIsCustomerCreationFailed = (
	state: CreateJulaProCustomerMachineState,
) => state.matches('customerNotCreated');

export const selectIsSigningCanceled = (
	state: CreateJulaProCustomerMachineState,
) => state.matches('signingCanceled');

export const selectIsCustomerCreationError = (
	state: CreateJulaProCustomerMachineState,
) => state.matches('customerCreationError');

export const selectOrganizationList = (
	state: CreateJulaProCustomerMachineState,
) => state.context.organizationList;

export const selectSelectOrganizationButtonState = (
	state: CreateJulaProCustomerMachineState,
) => state.context?.selectOrganizationButtonState;

export const selectSelectedWorksite = (
	state: CreateJulaProCustomerMachineState,
) => state.context.selectedWorksite;

export const selectSelectedCredit = (
	state: CreateJulaProCustomerMachineState,
) => state.context.selectedCredit;

export const selectDisplayBankIdSignInModal = (
	state: CreateJulaProCustomerMachineState,
) => state.context.shouldDisplaySignIframeModal;

export const selectIsSigning = (state: CreateJulaProCustomerMachineState) =>
	state.hasTag('signing');

export const selectBankIdSignUrl = (state: CreateJulaProCustomerMachineState) =>
	state.context.ticket?.data?.Url;

export const selectCompanyName = (state: CreateJulaProCustomerMachineState) =>
	state.context.selectedWorksite?.companyName;

export const selectName = (state: CreateJulaProCustomerMachineState) =>
	state.context?.customerInfo?.name;

export const selectOpenSignTabError = (
	state: CreateJulaProCustomerMachineState,
) => state.matches('registerCustomer.openSignWindowError');

export const selectShouldDisplayAbortButton = (
	state: CreateJulaProCustomerMachineState,
) => state.context.shouldDisplayAbortButton;

export const selectCustomerInfo = (state: CreateJulaProCustomerMachineState) =>
	state.context.customerInfo;

export const selectCreditApplicationData = (
	state: CreateJulaProCustomerMachineState,
) => state.context.resolvedTicket?.data;
