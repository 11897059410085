import React, { useState } from 'react';
import { useSelector } from '@xstate/react';

import AccountOverviewCreditInformation from 'components/AccountOverviewCreditInformation';
import BonusGauge from 'components/BonusGauge';
import InfoBox from 'components/InfoBox';
import { AccountLayoutContainer } from 'components/Layout';
import Link from 'components/Link';
import LoadingSpinner from 'components/LoadingSpinner';
import { OrderList } from 'components/Order';
import { PartialPopover } from 'components/Popover';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { useFeatureToggle, useGlobalMachinesContext } from 'contexts';
import {
	useAvailableCreditBalance,
	useBonusBalance,
	useCustomerInformation,
	useGlobalLinks,
	useOrderHistory,
} from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import { selectCustomerType } from 'state-machines/authentication';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps;

export default function AccountJulaClubOverview({ rendering }: Props) {
	const { t } = useI18n();
	const { userService } = useGlobalMachinesContext();
	const { julaClubCreditFunctionsEnabled } = useFeatureToggle();
	const { accountOrders, applyCredit, raiseCredit } = useGlobalLinks();
	const [isApplyForCreditOpen, setIsApplyForCreditOpen] = useState(false);
	const [isRaiseCreditOpen, setIsRaiseCreditOpen] = useState(false);
	const {
		customerInformation,
		error: errorCustomerInformation,
		isLoading: isLoadingCustomerInformation,
	} = useCustomerInformation();
	const {
		cellPhoneNumberFormatted,
		creditLimit,
		emailAddress,
		fullName,
		isCreditCustomer,
		isValidCreditCustomer,
		postalAddress,
	} = customerInformation ?? {};
	const customerType = useSelector(userService, selectCustomerType);
	const isStaff = customerType === 'Staff';
	const {
		bonus,
		error: errorBonusBalance,
		isLoading: isLoadingBonusBalance,
	} = useBonusBalance(!isStaff);
	const {
		availableCreditBalance,
		error: errorAvailableCreditBalance,
		isLoading: isLoadingAvailableCreditBalance,
	} = useAvailableCreditBalance(
		Boolean(
			julaClubCreditFunctionsEnabled && customerInformation?.isCreditCustomer,
		),
	);
	const {
		error: errorOrders,
		isLoading: isLoadingOrders,
		items: orders,
	} = useOrderHistory(true, 3);
	const hasOrders = orders && orders.length > 0;
	const isLoadingCreditInformation =
		(Boolean(isCreditCustomer) && isLoadingAvailableCreditBalance) ||
		isLoadingCustomerInformation;
	const errorCreditInformation =
		(Boolean(isCreditCustomer) && errorAvailableCreditBalance) ||
		errorCustomerInformation;

	const loadingOrdersOk = !isLoadingOrders && !errorOrders;
	return (
		<AccountLayoutContainer
			rendering={rendering}
			heading={t('account_pages_heading')}
			sidebarContent={
				<div className="mt-8 md:mt-24">
					<div className="flex justify-between">
						<Text className="mb-2" as="h2">
							{t('account_details_heading')}
						</Text>
					</div>
					{isLoadingCustomerInformation && (
						<Skeleton>
							<SkeletonItem height="1rem" />
							<SkeletonItem height="1rem" className="mb-8 mt-2" />
							<SkeletonItem height="1rem" className="mt-4" />
							<SkeletonItem height="1rem" className="mb-8 mt-2" />
						</Skeleton>
					)}
					{!isLoadingCustomerInformation && !errorCustomerInformation && (
						<>
							<Text as="p">{fullName}</Text>
							<div className="mb-4">
								<Text as="p">
									{postalAddress?.street}, {postalAddress?.postalCode},{' '}
									{postalAddress?.city}
								</Text>
							</div>
							<Text as="p">{emailAddress}</Text>
							<Text as="p" className="mb-2">
								{cellPhoneNumberFormatted?.default}
							</Text>
						</>
					)}
					{errorCustomerInformation && (
						<InfoBox
							icon="error"
							variant="error"
							message={t('account_generic_fetch_error_text')}
						/>
					)}
					{julaClubCreditFunctionsEnabled && (
						<AccountOverviewCreditInformation
							availableCreditBalance={availableCreditBalance}
							creditLimit={creditLimit}
							isValidCreditCustomer={isValidCreditCustomer}
							isCreditCustomer={isCreditCustomer}
							heading={t('account_membership_heading')}
							loading={isLoadingCreditInformation}
							hasError={errorCreditInformation}
							notCreditCustomerInfoText={t('account_details_invoice_text')}
							applyForCreditButtonOnClick={() => setIsApplyForCreditOpen(true)}
							raiseCreditButtonOnClick={() => setIsRaiseCreditOpen(true)}
						/>
					)}
				</div>
			}
		>
			<div className="hidden md:mt-6 md:grid">
				<Text as="h2">{t('account_bonus_heading')}</Text>
				<div className="shrink-0 md:mt-4 md:flex">
					{!isStaff && (
						<>
							{isLoadingBonusBalance && <LoadingSpinner className="mt-4" />}
							{!isLoadingBonusBalance && !errorBonusBalance && (
								<>
									<div>
										<BonusGauge
											bonusCheckLevels={bonus?.bonusCheckLevels}
											bonusBalance={bonus?.bonusBalance}
										/>
									</div>
									<div className="ml-10 flex flex-col">
										<Text as="p" className="mb-8">
											{t('account_bonus_tracking_label')}
										</Text>
									</div>
								</>
							)}
							{errorBonusBalance && (
								<InfoBox
									icon="error"
									variant="error"
									message={t('account_generic_fetch_error_text')}
								/>
							)}
						</>
					)}
					{isStaff && (
						<InfoBox
							icon="info"
							message={t('account_staff_no_bonus_info_text')}
						/>
					)}
				</div>
			</div>
			<div className="mb-4 mt-6 flex items-center justify-between">
				<Text as="h2">{t('account_purchases_heading')}</Text>
				{accountOrders && (
					<Link href={accountOrders} underline>
						{t('account_pages_show_all_orders_button')}
					</Link>
				)}
			</div>
			<div>
				{isLoadingOrders && (
					<div className="flex items-center justify-center">
						<LoadingSpinner />
					</div>
				)}
				{loadingOrdersOk && hasOrders && (
					<OrderList orders={orders} rendering={rendering} />
				)}
				{loadingOrdersOk && !hasOrders && (
					<InfoBox icon="info" message={t('jula_pro_orders_no_orders_text')} />
				)}
				{errorOrders && (
					<InfoBox
						icon="error"
						message={t('account_generic_fetch_error_text')}
					/>
				)}
			</div>
			<PartialPopover
				heading={t('account_apply_for_credit_button')}
				isOpen={isApplyForCreditOpen}
				item={applyCredit}
				onClose={() => setIsApplyForCreditOpen(false)}
			/>
			<PartialPopover
				heading={t('account_raise_credit_button')}
				isOpen={isRaiseCreditOpen}
				item={raiseCredit}
				onClose={() => setIsRaiseCreditOpen(false)}
			/>
		</AccountLayoutContainer>
	);
}
AccountJulaClubOverview.displayName = 'AccountJulaClubOverview';
