/**
 * ProductList
 */

import React from 'react';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import Placeholder from 'components/Placeholder';
import ProductListComponent from 'components/ProductList';
import Text from 'components/Text';
import { withRequiredProps } from 'hoc';
import { useIsEditing } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { ProductList as ProductListModel } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields: ProductListModel;
};

function ProductList({ fields, params, rendering }: Props) {
	const { brand, category, datasourceName, heading, total } = fields;
	const isEditing = useIsEditing();
	const categoryName = category?.title?.value;
	if (isEditing && total === 0) {
		return (
			<ComponentPlaceholder
				componentName="ProductList"
				className={getEditorMargin(params?.margin, 'Half')}
			/>
		);
	}
	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params?.margin, 'Half')}
		>
			{rendering.placeholders && (
				<Placeholder
					name="breadcrumbs"
					rendering={rendering}
					render={(components) => <div className="mb-8">{components}</div>}
				/>
			)}
			<Text as="h1" text={heading?.value || categoryName} />
			<ProductListComponent
				// This is deliberately a fallback stack rather than a template string
				// with every value. Having them all at the same time can result in the
				// list resetting when certain facet items are selected.
				key={category?.id.value || brand?.id.value || heading?.value}
				fields={fields}
				className="mt-6"
				gtmItemListId={categoryName || brand ? 'category' : 'block'}
				gtmItemListName={
					categoryName
						? `Category: ${categoryName}`
						: brand
							? `Category: ${brand.title.value}`
							: `Block: ${datasourceName}`
				}
				placeholderComponentName="ProductList"
			/>
		</LayoutContainer>
	);
}
ProductList.displayName = 'ProductList_JSS';

export default withRequiredProps(ProductList, 'fields');
