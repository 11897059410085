import React from 'react';

import type { Product } from 'models/product';

interface Props {
	product: Product;
}

export default function ProductDetailsVideoly({ product }: Props) {
	return (
		<>
			<div id="videoly-videobox-placeholder" />
			<div className="productPage" />
			<div className="hidden">
				<div id="videoly-product-brand">{product.brand?.title}</div>
				<div id="videoly-product-id">{product.id}</div>
				<div id="videoly-product-sku">{product.productId}</div>
			</div>
		</>
	);
}
ProductDetailsVideoly.displayName = 'ProductDetailsVideoly';
