import React from 'react';
import { Field } from 'react-final-form';

import {
	Select as StyledSelect,
	type SelectProps as StyledSelectProps,
} from 'components/FormUi';

import { composeValidators, type FieldValidator } from './validation';

export interface Props extends StyledSelectProps {
	className?: string;
	name: string;
	validate?: FieldValidator | FieldValidator[];
}

export default function Select({ className, name, validate, ...rest }: Props) {
	return (
		<Field
			type="select"
			name={name}
			validate={composeValidators(validate)}
			render={({ input, meta }) => (
				<StyledSelect
					valid={meta.valid && meta.touched}
					invalid={meta.invalid && meta.touched}
					errorMessage={meta.error || meta.submitError}
					className={className}
					{...input}
					{...rest}
				/>
			)}
		/>
	);
}
Select.displayName = 'FinalForm_Select';
