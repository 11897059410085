import React, {
	type AnchorHTMLAttributes,
	type MouseEventHandler,
	type ReactNode,
} from 'react';

import { cn } from 'utils/classNames';
import { goToElement } from 'utils/dom';

interface Props extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
	/** Element ID to target */
	anchor: string;

	/** Link text */
	children: ReactNode;

	/** Link class names */
	className?: string;

	/** Scroll target element to the top of the window? */
	scroll?: boolean;

	/** Throw an error if the anchor target is missing? */
	strict?: boolean;

	/** Underline the text. */
	underline?: boolean;
}

/** Link for scrolling to anchors on the same page. */
const AnchorLink = React.forwardRef<HTMLAnchorElement, Props>(
	(
		{
			anchor,
			children,
			className,
			onClick,
			scroll = true,
			strict,
			underline = false,
			...props
		},
		ref,
	) => {
		const target = anchor.replaceAll('#', '');

		const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
			e.preventDefault();
			onClick?.(e);
			// Let the click event finish bubbling before focusing.
			// A specific issue otherwise is that 'click outside' handlers get
			// triggered from this click event for the target just after focusing,
			// causing it to blur/close immediately.
			setTimeout(() => {
				goToElement(target, { scroll, strict });
			});
		};

		// No need for next Link here since the event is always prevented.
		return (
			<a
				{...props}
				ref={ref}
				href={`#${target}`}
				onClick={handleClick}
				className={cn(className, underline && 'underline hover:no-underline')}
			>
				{children}
			</a>
		);
	},
);
AnchorLink.displayName = 'AnchorLink';

export default AnchorLink;
