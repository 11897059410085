import React from 'react';
import type {
	RichTextField,
	TextField,
} from '@sitecore-jss/sitecore-jss-react';

import { LayoutContainer } from 'components/Layout';
import RichText from 'components/RichText';
import Text from 'components/Text';
import { useIsEditing } from 'hooks';
import type { ColorField, JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		backgroundColor?: ColorField;
		content?: RichTextField;
		heading?: TextField;
	};
};

/**
 * Content box with a colored background. Heading and rich text.
 */
export default function ContentBlockHighlight({ fields, params }: Props) {
	const isEditing = useIsEditing();

	if (!fields) {
		return null;
	}

	// No ComponentPlaceholder for the editor since it should be editable inline.
	if (!isEditing && !fields.heading?.value && !fields.content?.value) {
		return null;
	}

	const backgroundColor = fields.backgroundColor?.fields?.colorHex?.value;

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			withPopoverLayout
		>
			<div
				style={backgroundColor ? { backgroundColor } : undefined}
				// Keep the background color class as a default color.
				className="max-w-col-6 rounded-lg bg-informationLighter p-6 md:rounded-2xl md:p-12 forced-colors:border"
			>
				<Text
					as={params?.heading || 'h2'}
					field={fields.heading}
					className="mb-2 md:mb-4"
				/>
				<RichText field={fields.content} />
			</div>
		</LayoutContainer>
	);
}
ContentBlockHighlight.displayName = 'ContentBlockHighlight';
