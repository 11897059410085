import React from 'react';
import { useSelector } from '@xstate/react';

import WishlistBlock, {
	formatWishListDataForGTM,
	mapWishlistToIdQuantity,
} from 'components/Wishlist';
import {
	useFeatureToggle,
	useGlobalMachinesContext,
	useSelectedStore,
} from 'contexts';
import { useProductGTMEvents, useShareList } from 'hooks';
import type { Wishlist as WishlistModel, WishlistVariant } from 'models/api';
import { selectIsLoading, selectWishlist } from 'state-machines/wishlist';
import { useI18n } from 'utils/i18n';

export default function Wishlist() {
	const { cartService, wishlistService } = useGlobalMachinesContext();
	const { onlineCommerceEnabled, storesEnabled } = useFeatureToggle();
	const { selectedStore } = useSelectedStore();
	const { t } = useI18n();

	const isLoading = useSelector(wishlistService, selectIsLoading);
	const wishlistData = useSelector(wishlistService, selectWishlist);
	const { copyListUrl, shareListUrl, shareState } = useShareList({
		type: 'wishlist',
	});
	const { sendAddMultipleToCartEvent, sendAddToCartEvent } =
		useProductGTMEvents();
	const moveAllVariantsToCart = (list: WishlistModel | undefined) => {
		if (!list) return;
		cartService.send({
			type: 'ADD_MULTIPLE_VARIANTS_MINI',
			variants: mapWishlistToIdQuantity(list),
		});
		sendAddMultipleToCartEvent({ products: formatWishListDataForGTM(list) });
		wishlistService.send({ type: 'REMOVE_ALL' });
	};
	const moveVariantToCart = (variant: WishlistVariant) => {
		if (!variant.id) return;
		cartService.send({
			type: 'ADD_REMOVE_VARIANT',
			variantId: variant.id,
			qty: variant.qty,
			distinctValue: false,
		});
		sendAddToCartEvent({ product: variant, quantity: variant.qty });
		wishlistService.send({
			type: 'REMOVE_VARIANT',
			variantId: variant.id,
		});
	};

	return (
		<WishlistBlock
			isLoading={isLoading}
			onMoveVariantToCart={moveVariantToCart}
			onMoveAllVariantsToCart={() => moveAllVariantsToCart(wishlistData)}
			heading={t('wishlist_wishlist_heading')}
			shareState={shareState}
			onShare={shareListUrl}
			onCopy={copyListUrl}
			wishlist={wishlistData}
			onEmptyWishlist={() => wishlistService.send({ type: 'REMOVE_ALL' })}
			moveVariantToCartButtonText={t('wishlist_move_product_to_cart_button')}
			moveAllVariantsToCartButtonText={t(
				'wishlist_move_all_products_to_cart_button',
			)}
			onRemoveVariant={(variant) => {
				if (!variant.id) return;
				wishlistService.send({
					type: 'REMOVE_VARIANT',
					variantId: variant.id,
				});
			}}
			onUpdateVariantQty={(variant, newQuantity) => {
				if (!variant.id) return;
				wishlistService.send({
					type: 'SET_VARIANT_QTY',
					variantId: variant.id,
					qty: newQuantity,
					GTMData:
						variant.qty - newQuantity < 0
							? {
									type: 'add_to_wishlist',
									payload: {
										product: variant,
										quantity: newQuantity - variant.qty,
									},
								}
							: undefined,
				});
			}}
			onlineCommerceEnabled={onlineCommerceEnabled}
			selectedStore={selectedStore}
			storesEnabled={storesEnabled}
		/>
	);
}
Wishlist.displayName = 'Wishlist_JSS';
