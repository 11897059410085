/**
 * ProductCard updated
 */

import React from 'react';
import type { MouseEventHandler } from 'react';

import type { ActionButtonState } from 'components/ActionButton';
import ErrorBoundary from 'components/ErrorBoundary';
import {
	useFeatureToggle,
	useGlobalMachinesContext,
	usePriceContext,
	useSelectedStore,
} from 'contexts';
import { useProductListGTMEvents } from 'hooks';
import { useTrackEvent } from 'hooks/useTrackEvent';
import type {
	ProductCard as ProductCardType,
	RelatedProductCard,
} from 'models/productCard';
import { getProductImageSrc } from 'utils/business-logic';
import type { GTMItemListId, GTMItemListName } from 'utils/GoogleTagManager';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import ProductCardColumn, {
	type Props as ProductCardColumnProps,
} from './ProductCardColumn';
import ProductCardRow, {
	type Props as ProductCardRowProps,
} from './ProductCardRow';

interface BaseProps {
	/** CTA button text. ONLY concerns orientation 'row'. */
	buttonText?: string;

	/** Extra container class names */
	className?: string;

	/** List ID for GTM events */
	gtmItemListId: GTMItemListId;

	/** List name for GTM events */
	gtmItemListName: GTMItemListName;

	/** Link click callback */
	onLinkClick?: MouseEventHandler<HTMLAnchorElement>;

	/** Sets the orientation of the component */
	orientation?: 'column' | 'row';

	/** Product data */
	product: ProductCardType | RelatedProductCard;

	/** Zero based index in list */
	productListIndex: number;
}
interface WithAddToCartButton {
	/** Control the ActionButton from the 'outside' state */
	actionButtonState?: ActionButtonState;

	/** Should we open the additional sales modal? */
	openAddedProductPopover?: boolean;

	/** Should the button be rendered? */
	showAddToCartButton: true;
}
interface WithoutAddToCartButton {
	/** Control the ActionButton from the 'outside' state */
	actionButtonState?: never;

	/** Should we open the additional sales modal? */
	openAddedProductPopover?: never;

	/** Should the button be rendered? */
	showAddToCartButton?: undefined | false;
}
type AddToCartButtonProps = WithAddToCartButton | WithoutAddToCartButton;
interface WithPurchaseButtonCallback extends BaseProps {
	onAddToCart?: never;
	/** Custom add to cart button click handler */
	onPurchaseButtonClick?: () => void;
}
interface WithDefaultButtonCallback extends BaseProps {
	/** Add to cart callback for default button click handler */
	onAddToCart?: () => void;
	onPurchaseButtonClick?: never;
}

type Props =
	| (WithPurchaseButtonCallback & AddToCartButtonProps)
	| (WithDefaultButtonCallback & AddToCartButtonProps);

export default function ProductCard({
	actionButtonState,
	buttonText,
	className,
	gtmItemListId,
	gtmItemListName,
	onAddToCart,
	onLinkClick,
	onPurchaseButtonClick,
	openAddedProductPopover = true,
	orientation = 'column',
	product,
	productListIndex,
	showAddToCartButton = false,
}: Props) {
	const { t } = useI18n();
	const { cartService } = useGlobalMachinesContext();
	const { canTogglePriceVat, usePriceExcVat } = usePriceContext();
	const { selectedStore } = useSelectedStore();
	const { sendProductClickEvent } = useTrackEvent();
	const { onlineCommerceEnabled, storesEnabled } = useFeatureToggle();
	const { sendAddToCartEvent, sendSelectItemEvent } = useProductListGTMEvents(
		gtmItemListId,
		gtmItemListName,
	);

	if (!product) {
		return null;
	}

	const handleAddToCartClick =
		onPurchaseButtonClick ??
		(() => {
			cartService.send({
				type: 'ADD_ONE_FROM_BUY_BUTTON',
				variantId: product.id,
				openAddedProductPopover,
				metaData: {
					gtmItemListId,
					gtmItemListName,
				},
			});
			sendAddToCartEvent(product, 1);
			onAddToCart?.();
		});

	const handleLinkClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
		sendSelectItemEvent(product, productListIndex);
		onLinkClick?.(e);
		sendProductClickEvent(product.id);
	};

	const energySymbolSrc = product.energySymbol?.[0]?.location;

	const flag = product.productTags?.find(({ section }) => section === 'Flag');
	// Up to one of each Product/Price tag.
	const tags = [
		product.productTags?.find(({ section }) => section === 'Product'),
		product.productTags?.find(({ section }) => section === 'Price'),
	].filter(is.truthy);

	const uspTags = product.productTags?.filter(
		({ section }) => section === 'Usp',
	);

	const stockInfo =
		'productInStockAtStores' in product
			? {
					storeStock: {
						name: selectedStore?.name,
						inStock: Boolean(
							product.productInStockAtStores?.some(
								({ id }) => id.toString() === selectedStore?.id,
							),
						),
						isSellableInStore: product.isSellableInStore,
						productInStockAtStores: product.productInStockAtStoresCount ?? 0,
						isSellableOnWeb: product.isSellableOnWeb,
					},
					webStock: {
						isSellable: product.isSellable,
						isSellableOnWeb: product.isSellableOnWeb,
						productReStockWeb: product.productReStockWeb,
						isSellableInStore: product.isSellableInStore,
					},
				}
			: undefined;

	const isExpired = 'expired' in product ? product.expired : false;
	const isSellable = 'isSellable' in product ? product.isSellable : true;
	const usps = 'usPs' in product ? product.usPs : undefined;
	const basePrice = 'basePrice' in product ? product.basePrice : undefined;
	const hasMultipleVariants =
		'hasMultipleVariants' in product ? product.hasMultipleVariants : false;

	const heading =
		'titleExclModel' in product
			? product.subTitle && product.titleExclModel
				? `${product.titleExclModel} ${product.subTitle}`
				: (product.titleExclModel ?? product.title)
			: product.title;
	const subheading =
		'model' in product
			? product.model
				? `${product.brand?.title} | ${product.model}`
				: product.brand?.title
			: product.brand?.title;

	const commonProps: ProductCardRowProps | ProductCardColumnProps = {
		actionButtonState,
		className,
		disabled: !isSellable || !onlineCommerceEnabled,
		energySymbolSrc,
		flag,
		heading,
		href: product.url || '#',
		imageSrc: getProductImageSrc(product, true),
		onAddToCartClick: handleAddToCartClick,
		onLinkClick: handleLinkClick,
		price: isExpired ? undefined : product.displayPrice,
		productSheetLink: product.productSheetLink,
		productSheetLinkDescription: product.productSheetLinkDescription,
		reviews: product.reviewScore?.count,
		score: product.reviewScore?.score ?? 0,
		showAddToCartButton: !isExpired && showAddToCartButton,
		subHeading: subheading,
		tags,
		vatPriceText: canTogglePriceVat
			? t(
					usePriceExcVat
						? 'price_vat_excluded_text'
						: 'price_vat_included_text',
				)
			: undefined,
	};

	return (
		<ErrorBoundary>
			{orientation === 'row' ? (
				<ProductCardRow {...commonProps} buttonText={buttonText} />
			) : (
				<ProductCardColumn
					{...commonProps}
					basePrice={basePrice}
					checklist={usps}
					hasMultipleVariants={hasMultipleVariants}
					productId={product.id}
					stockInfo={stockInfo}
					storesEnabled={storesEnabled}
					uspTags={uspTags}
				/>
			)}
		</ErrorBoundary>
	);
}
ProductCard.displayName = 'ProductCard';
