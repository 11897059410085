// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconFlagPl(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path
				fill="#dd0c39"
				d="M0 11.96V12c0 6.58 5.42 12 12 12 6.57 0 11.98-5.4 12-11.96v-.08z"
			/>
			<path
				fill="#fff"
				d="M0 12v-.04c0-6.58 5.42-12 12-12 6.57 0 11.98 5.4 12 11.96V12z"
			/>
		</svg>
	);
}
IconFlagPl.displayName = 'IconFlagPl';
