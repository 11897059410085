/**
 * BonusGauge
 */

import React from 'react';

import { CircularGauge } from 'components/Gauge';
import Text from 'components/Text';
import type { BonusCheckLevel } from 'models/api';
import { cn } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

type Size = 'small' | 'large';

interface Props {
	bonusBalance: number | undefined;
	bonusCheckLevels: BonusCheckLevel[] | undefined;
	className?: string;
	leftToNextBonusCheck?: number;
	size?: Size;
}

export default function BonusGauge({
	bonusBalance,
	bonusCheckLevels,
	className = '',
	leftToNextBonusCheck,
	size = 'small',
}: Props) {
	const { t } = useI18n();
	const { points: nextLevelPoints, value: nextLevelCheckValue } =
		bonusCheckLevels
			?.slice()
			?.sort((a, b) => a.points - b.points)
			?.find((level) => level.points > (bonusBalance ?? 0)) ?? {};
	const percent = ((bonusBalance ?? 0) / (nextLevelPoints ?? 1)) * 100;
	return (
		<div className={cn('inline-block', className)}>
			{nextLevelCheckValue && size === 'large' && (
				<Text
					as="pLarge"
					className="mb-2 text-center"
					text={`${nextLevelCheckValue}.-`}
				/>
			)}
			<div className="relative">
				<CircularGauge
					className={size === 'small' ? 'size-32' : 'size-64'}
					percent={percent}
				/>
				<div
					className={cn(
						'absolute inset-x-0 top-1/2 -translate-y-1/2 text-center',
						size === 'small' ? 'px-2' : 'px-8',
					)}
				>
					<Text
						as={size === 'small' ? 'pSmall' : 'p'}
						text={t('account_bonus_points_label')}
						className="font-bold"
					/>
					<Text
						as="p"
						styleAs={size === 'small' ? 'h2' : 'h1'}
						text={bonusBalance?.toString()}
						className="font-bold tracking-tight text-julaRed"
					/>
					{leftToNextBonusCheck && size === 'large' && (
						<Text
							as="p"
							text={`${leftToNextBonusCheck} ${t('account_bonus_gauge_text')}`}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
BonusGauge.displayName = 'BonusGauge';
