/**
 * Product
 */

import React from 'react';

import { SummaryProductLine } from 'components/ProductLine';
import { usePriceContext } from 'contexts';
import type { RowSummary } from 'models/api';

interface Props {
	/** The amount per product in the summary */
	amount: number;

	/** The article number in the component */
	articleNumber?: string;

	/** Is there a discount? If there is, then render red color on the price */
	discount: boolean;

	/** The heading in the component */
	heading: string;

	/** Image src attribute */
	imageSrc?: string;

	/** The link to the product page */
	link?: string;

	placements?: string[];

	/** The total sum of the product row */
	rowSummary: RowSummary;
}

/** The product card. */
export default function SummaryProduct({
	amount,
	articleNumber,
	discount,
	heading,
	imageSrc,
	link,
	placements,
	rowSummary,
}: Props) {
	const { usePriceExcVat } = usePriceContext();
	const rowSum = usePriceExcVat
		? rowSummary?.priceExcVat
		: rowSummary?.priceIncVat;

	return (
		<SummaryProductLine
			link={link}
			heading={heading}
			placements={placements}
			imageSrc={imageSrc}
			articleNumber={articleNumber}
			rowSum={rowSum}
			amount={amount}
			discount={discount}
		/>
	);
}
SummaryProduct.displayName = 'SummaryProduct';
