/**
 * CategoryIntro
 */

import React from 'react';

import ExpandableDescription from 'components/ExpandableDescription';
import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import type { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		formattedLongText?: string;
		title?: string;
		unformattedLongText?: string;
	};
};

export default function CategoryIntro({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const { formattedLongText, title } = fields;

	if (!title && !formattedLongText) {
		return null;
	}

	return (
		<LayoutContainer id={params?.anchor} className={getEditorMargin(params)}>
			{title && (
				<Text className="mb-2" as="h1">
					{title}
				</Text>
			)}
			{formattedLongText && (
				<ExpandableDescription
					description={formattedLongText}
					textLengthBreakPoint={400}
					className="max-w-col-6 text-lg"
					descriptionClassName="text-lg"
					buttonVariant="text"
					buttonPlacement="left"
					textHeight="short"
				/>
			)}
		</LayoutContainer>
	);
}
CategoryIntro.displayName = 'CategoryIntro';
