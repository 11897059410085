import { createElement, Fragment, type ReactNode } from 'react';

import { publicRuntimeConfig } from 'config';
import { removeTrailingSlash } from 'utils/url';

import { is } from './helpers';
import { round } from './math';
import {
	ENTITIES,
	makeSpacesNoBreak,
	type NoBreakSpaceVariant,
	parseNumber,
} from './string';

// Available units: https://tc39.es/ecma402/#table-sanctioned-single-unit-identifiers

// An undefined locale will fall back to 'the implementation's default' so
// force Swedish for tests and stories to not depend on the current environment.
export const LOCALE =
	process.env.NODE_ENV === 'test' || process.env.STORYBOOK
		? 'sv-SE'
		: publicRuntimeConfig?.NEXT_PUBLIC_JULA_MARKET_LANGUAGE;

const REGEXP = {
	// Optional leading minus or hyphen for negative numbers, then a digit
	// followed by zero or more digits, whitespace, commas or periods, followed
	// by a final digit. This won't match a single digit but that doesn't need
	// any formatting anyway.
	priceNumbers: new RegExp(
		String.raw`([${ENTITIES.minus}-]?\d[\d\s,.]*\d)`,
		'g',
	),
	// Same as above but with a negative lookahead at the end, disallowing any
	// numbers to follow the match. The number also has to be at the very end.
	lastPriceNumber: new RegExp(
		String.raw`[${ENTITIES.minus}-]?\d[\d\s,.]*\d(?!.*\d)$`,
		'g',
	),
	templateParts: /{{(.*?)}}/g,
	nodeTemplateParts: /({{.*?}})/g,
	templatePartBraces: /^{+|}+$/g,
	protocolAndWWW: /^(?:[a-z0-9]{2,}:\/\/)?(?:www\.)?/,
};

const INTEGER_FORMATTER = new Intl.NumberFormat(LOCALE, {
	style: 'decimal',
	maximumFractionDigits: 0,
});
const SINGLE_DECIMAL_FORMATTER = new Intl.NumberFormat(LOCALE, {
	style: 'decimal',
	maximumFractionDigits: 1,
});

/**
 * Format a number according to the current locale. Affects things like decimal
 * and thousand separators.
 */
export function formatNumber(
	num: number,
	type: 'decimal' | 'integer' = 'decimal',
): string {
	const formatter =
		type === 'decimal' ? SINGLE_DECIMAL_FORMATTER : INTEGER_FORMATTER;
	return formatter.format(num);
}

const CENTIMETER_FORMATTER = new Intl.NumberFormat(LOCALE, {
	style: 'unit',
	unit: 'centimeter',
});

/**
 * Format a length in centimeters.
 */
export function formatLength(value: number | undefined): string {
	return value ? makeSpacesNoBreak(CENTIMETER_FORMATTER.format(value)) : '';
}

const KILOMETER_FORMATTER = new Intl.NumberFormat(LOCALE, {
	style: 'unit',
	unit: 'kilometer',
});

/**
 * Format a distance in kilometers.
 */
export function formatDistance(value: number | undefined): string {
	return value
		? makeSpacesNoBreak(KILOMETER_FORMATTER.format(round(value, 1)))
		: '';
}

const GRAM_FORMATTER = new Intl.NumberFormat(LOCALE, {
	style: 'unit',
	unit: 'gram',
});
const KILOGRAM_FORMATTER = new Intl.NumberFormat(LOCALE, {
	style: 'unit',
	unit: 'kilogram',
});

/**
 * Format a weight in grams or kilograms.
 */
export function formatWeight(value: number | undefined): string {
	if (value === undefined) {
		return '';
	}
	return makeSpacesNoBreak(
		value < 1
			? GRAM_FORMATTER.format(value * 1000)
			: KILOGRAM_FORMATTER.format(value),
	);
}

const NUMERIC_DATE_FORMATTER = new Intl.DateTimeFormat(LOCALE, {
	dateStyle: 'short',
});

/**
 * Format a numeric date, e.g. '2012-12-20' for sv-SE or '2/20/2012' for en-US.
 */
export function formatDate(date: string | Date | undefined): string {
	if (!date) {
		return '';
	}
	const dateObj = new Date(date);
	// Force noon time to handle time zone shifts. A real world example is a
	// user that had a tab open without reloading and a time zone shift occurred
	// (either through travel or DST change). The browser then somehow takes both
	// the previous and new time zone into account when parsing and can shift
	// a date that has a time close to midnight. This resulted in an offer
	// displaying as valid to one day longer than it should.
	// Setting to noon should handle everything except extremes like traveling
	// from New Zealand to Europe.
	dateObj.setHours(12, 0, 0, 0);
	return NUMERIC_DATE_FORMATTER.format(dateObj);
}

const NUMERIC_DATE_TIME_FORMATTER = new Intl.DateTimeFormat(LOCALE, {
	dateStyle: 'short',
	timeStyle: 'short',
});

/**
 * Format a numeric date and time, e.g. '2012-12-20 04:00' for sv-SE or
 * '12/20/12, 4:00 AM' for en-US.
 */
export function formatDateAndTime(date: string | Date | undefined): string {
	return date ? NUMERIC_DATE_TIME_FORMATTER.format(new Date(date)) : '';
}

const RELATIVE_TIME_FORMATTER = new Intl.RelativeTimeFormat(LOCALE, {
	numeric: 'auto',
});

/**
 * Format a date relative to now, like 'tomorrow' or '2 weeks ago'.
 */
export function formatElapsedTime(pastDate: string | Date): string {
	const diffInMilliseconds = Date.now() - new Date(pastDate).getTime();
	const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

	if (diffInDays < 7) {
		return RELATIVE_TIME_FORMATTER.format(-round(diffInDays), 'day');
	}
	if (diffInDays < 30) {
		return RELATIVE_TIME_FORMATTER.format(-round(diffInDays / 7), 'week');
	}
	if (diffInDays < 365) {
		return RELATIVE_TIME_FORMATTER.format(-round(diffInDays / 30), 'month');
	}
	return RELATIVE_TIME_FORMATTER.format(-round(diffInDays / 365), 'year');
}

const LANGUAGE_FORMATTER = new Intl.DisplayNames(LOCALE, { type: 'language' });

/**
 * Format a language code to its full name according to local, e.g. 'en' to 'Engelska'.
 */
export function formatLanguage(language: string): string {
	return LANGUAGE_FORMATTER.of(language) || '';
}

const REGION_FORMATTER = new Intl.DisplayNames(LOCALE, { type: 'region' });

/**
 * Format a region code to its full name according to local, e.g. 'SE' to 'Sverige'.
 */
export function formatRegion(region: string): string {
	return REGION_FORMATTER.of(region) || '';
}

/**
 * Format a raw byte value to a human friendly string.
 *
 * Core logic from https://github.com/sindresorhus/pretty-bytes.
 *
 * @example
 *
 * formatBytes(123456)
 * // => '123 kB'
 */
export function formatBytes(bytes: number | undefined | null): string {
	if (!is.number(bytes)) {
		return '';
	}
	const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const isNegative = bytes < 0;
	const prefix = isNegative ? ENTITIES.minus : '';

	let num = bytes;
	if (isNegative) {
		num = -num;
	}

	if (num < 1) {
		return prefix + formatNumber(num) + ENTITIES.noBreakSpace + units[0];
	}

	const exponent = Math.min(Math.floor(Math.log10(num) / 3), units.length - 1);
	num /= 1000 ** exponent;
	const unit = units[exponent];

	return (
		prefix +
		formatNumber(Number(num.toPrecision(3)), 'integer') +
		ENTITIES.noBreakSpace +
		unit
	);
}

const EUR_CURRENCY_FORMATTER = new Intl.NumberFormat(LOCALE, {
	style: 'currency',
	// Doesn't have to match the current market, the currency is not used.
	// It could be supplied as an environment variable like locale but it
	// would as of sep 2024 be risky since Norwegian Krone could have different
	// output if the server and client use different versions of ICU. This
	// would lead to hydration errors.
	// https://github.com/nodejs/node/issues/52376
	currency: 'EUR',
});

/**
 * Format a price number according to the current locale. Affects things
 * like decimal and thousand separators.
 */
function formatPriceNumber(
	price: string | number | undefined,
	noBreakSpaceVariant: NoBreakSpaceVariant = 'narrow',
): string {
	if (is.nonZeroFalsy(price)) {
		return '';
	}
	const priceNum = parseNumber(price);
	// The currency formatter always has two fractions, so a price of 49 will
	// be formatted as 49,00. Use integer formatter to avoid this.
	const formatted = is.integer(priceNum)
		? formatNumber(priceNum, 'integer')
		: EUR_CURRENCY_FORMATTER.formatToParts(priceNum)
				// The currency symbol can't be disabled so filter it out,
				// only the number itself is of interest.
				.filter((part) => part.type !== 'currency')
				.map((part) => part.value)
				.join('')
				.trim();
	return makeSpacesNoBreak(formatted, noBreakSpaceVariant);
}

/**
 * Format price numbers according to the current locale.
 *
 * Affects all numbers in the supplied price, so a string like "1234 för 1234"
 * will have both numbers formatted.
 *
 * @example
 *
 * formatPrice(1234)
 * // => '1 234'
 *
 * formatPrice('3 för 12599.-')
 * // => '3 för 12 599.-'
 */
export function formatPrice(
	price: string | number | undefined,
	noBreakSpaceVariant: NoBreakSpaceVariant = 'narrow',
): string {
	if (is.nonZeroFalsy(price)) {
		return '';
	}
	return String(price)
		.replaceAll(REGEXP.priceNumbers, (match) =>
			formatPriceNumber(match, noBreakSpaceVariant),
		)
		.trim();
}

/**
 * Format a price with separate properties for the main number and the decimal.
 *
 * @example
 *
 * formatPriceParts(123)
 * // => { main: '123', decimal: '' }
 *
 * formatPriceParts(1234.5)
 * // => { main: '1 234', decimal: '50' }
 *
 * formatPriceParts('Köp 3 för 1234,5')
 * // => { main: 'Köp 3 för 1 234', decimal: '50' }
 */
export function formatPriceParts(
	price: string | number | undefined,
	noBreakSpaceVariant: NoBreakSpaceVariant = 'narrow',
): {
	decimal: string;
	main: string;
} {
	if (!price) {
		return {
			main: price === 0 ? '0' : '',
			decimal: '',
		};
	}
	let decimal = '';
	// Find the last price number, e.g. 9.99 in 'Buy 3 for 9.99', and extract the
	// decimal from that if there is one. The price then only contains the base
	// number, e.g. 'Buy 3 for 9'.
	const cleanedPrice = String(price).replace(
		REGEXP.lastPriceNumber,
		(match) => {
			// Parsing a string to a number then converting back to string looks
			// stupid, but it's to handle any existing formatting; the number could
			// use a comma for either decimal or thousand separator depending on
			// locale, stringifying a JS number ensures a period.
			const parts = String(parseNumber(match)).split('.');
			decimal = parts[1] || '';
			return parts[0];
		},
	);
	return {
		main: formatPrice(cleanedPrice, noBreakSpaceVariant),
		decimal: decimal ? decimal.slice(0, 2).padEnd(2, '0') : '',
	};
}

/**
 * Format a price and its symbol.
 *
 * @example
 *
 * formatPriceText(1234, '.-')
 * // => '1 234.-'
 */
export function formatPriceText(
	price: string | number | undefined,
	symbol: string | undefined,
): string {
	if (is.nonZeroFalsy(price)) {
		return '';
	}
	const formattedPrice = is.number(price) ? formatNumber(price) : price;
	return makeSpacesNoBreak(`${formattedPrice}${symbol || ''}`);
}

export type FormatTemplateValue = string | number | null | undefined;

/**
 * Format a string with mustache placeholders.
 *
 * @example
 *
 * formatTemplate('{{name}} is {{years}} years old', {
 *   name: 'Joe',
 *   years: 97,
 * })
 * // => 'Joe is 97 years old'
 */
export function formatTemplate(
	str: string,
	params: Record<string, FormatTemplateValue>,
): string {
	return str.replaceAll(REGEXP.templateParts, (fullMatch, group1: string) => {
		const key = group1.trim();
		return key && is.defined(params[key]) ? String(params[key]) : '';
	});
}

/**
 * Format a string with mustache placeholders to React nodes.
 *
 * @example
 *
 * formatTemplateToNodes('{{name}} is {{years}} years old', {
 *   name: <strong>Joe</strong>,
 *   years: <FancyNumber num={97} />,
 * })
 * // => [<strong>Joe</strong>, ' is ', <FancyNumber num={97} />, ' years old']
 */
export function formatTemplateToNodes(
	str: string,
	params: Record<string, ReactNode>,
): ReactNode[] {
	return str
		.split(REGEXP.nodeTemplateParts)
		.map((part, i) => {
			const isTemplatePart = REGEXP.nodeTemplateParts.test(part);
			const key = part.replaceAll(REGEXP.templatePartBraces, '').trim();
			if (key && is.truthyOrZero(params[key])) {
				// Can't use the part string as key since the same placeholder
				// can be included multiple times in the string.
				// eslint-disable-next-line react/no-array-index-key
				return createElement(Fragment, { key: i }, params[key]);
			}
			return isTemplatePart ? null : part;
		})
		.filter(is.truthyOrZero);
}

/**
 * Make a URL a bit more pretty to read.
 *
 * @example
 *
 * formatDisplayUrl('https://www.jula.se/')
 * // => 'jula.se'
 */
export function formatDisplayUrl(url: string): string {
	return removeTrailingSlash(url.replace(REGEXP.protocolAndWWW, ''));
}
