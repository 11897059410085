import React from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import Button from 'components/Button';
import { LayoutContainer } from 'components/Layout';
import { mapStoreMarker, StoreMap } from 'components/Map';
import StoreListItem from 'components/StoreListItem';
import Text from 'components/Text';
import { useSelectedStore } from 'contexts';
import { useAllStores } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { Store } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields?: {
		heading: Field<string>;
		storeListUrl: string;
		stores: Store[];
	};
};

export default function NearbyStores({ fields, params }: Props) {
	const { t } = useI18n();
	const { selectedStore, setSelectedStore } = useSelectedStore();
	const { stores: allStores } = useAllStores();

	if (!fields) {
		return null;
	}

	const { storeListUrl, stores: nearbyStores } = fields;

	const allStoreMarkers = allStores?.map((store) =>
		mapStoreMarker({
			store,
			isSelected: selectedStore?.id === store.id,
			onSelectClick: () => setSelectedStore(store),
		}),
	);
	const nearbyStoreMarkers = nearbyStores?.map((store) =>
		mapStoreMarker({
			store,
			isSelected: selectedStore?.id === store.id,
			onSelectClick: () => setSelectedStore(store),
		}),
	);

	return (
		<LayoutContainer
			id={params?.anchor}
			withGrid
			className={getEditorMargin(params)}
		>
			<Text
				as="h2"
				text={t('stores_nearby_stores_heading')}
				className="col-span-full mb-4 md:mb-6"
			/>
			<div className="col-span-4 md:col-span-6">
				{is.arrayWithLength(nearbyStores) && (
					<ul className="flex flex-col gap-y-2">
						{nearbyStores.map((store) => (
							<StoreListItem
								key={store.id}
								name={store.name}
								url={store.url}
								openHours={store.todaysOpeningHours?.description || ''}
								isOpen={
									store.todaysOpeningHours?.state?.toUpperCase() === 'OPEN'
								}
								address={`${store.streetAddress}, ${store.postalCode}, ${store.city}`}
								storeArea={store.storeArea}
							/>
						))}
					</ul>
				)}
				<Button variant="primary" className="mt-6" href={storeListUrl}>
					{t('stores_all_stores_button')}
				</Button>
			</div>
			<div className="hidden md:col-span-6 md:block">
				<StoreMap
					stores={allStoreMarkers}
					nearbyStores={nearbyStoreMarkers}
					mapClassName="h-[500px] max-h-[calc(100vh-10rem)]"
				/>
			</div>
		</LayoutContainer>
	);
}
NearbyStores.displayName = 'NearbyStores';
