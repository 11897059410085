import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { serialize } from 'cookie';

export interface StoreIdName {
	id: string;
	name: string;
}
export const StoreContext = createContext<{
	selectedStore: StoreIdName | undefined;
	setSelectedStore: React.Dispatch<
		React.SetStateAction<StoreIdName | undefined>
	>;
}>({ selectedStore: undefined, setSelectedStore: () => {} });

interface Props {
	children: React.ReactNode;
	initialSelectedStore: StoreIdName | undefined;
}
export function SelectedStoreContextProvider({
	children,
	initialSelectedStore,
}: Props) {
	const [selectedStore, setSelectedStore] = useState<StoreIdName | undefined>(
		initialSelectedStore,
	);

	useEffect(() => {
		if (selectedStore) {
			document.cookie = serialize(
				'selectedStore',
				JSON.stringify(selectedStore),
				{
					path: '/',
					expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
				},
			);
		}
	}, [selectedStore]);

	const value = useMemo(
		() => ({
			selectedStore,
			setSelectedStore,
		}),
		[selectedStore],
	);

	return (
		<StoreContext.Provider value={value}>{children}</StoreContext.Provider>
	);
}
SelectedStoreContextProvider.displayName = 'SelectedStoreContextProvider';
export function useSelectedStore() {
	return useContext(StoreContext);
}
