import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlockMedia from 'components/BlockMedia';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import { useIsEditing } from 'hooks';
import type { ColorField, JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import type { ProductCard } from 'models/productCard';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset, is } from 'utils/helpers';

interface Item {
	portraitImage: Field<DigizuiteAsset[]>;
	portraitVideo?: Field<DigizuiteAsset[]>;
	preventAutoplay?: Field<boolean>;
	products?: ProductCard[];
	squareImage: Field<DigizuiteAsset[]>;
	video?: Field<DigizuiteAsset[]>;
}

type Props = JulaComponentProps & {
	fields?: {
		backgroundColor?: ColorField;
		datasourceName?: string;
		items?: Item[];
	};
};

export default function MediaDouble({ fields, params }: Props) {
	const isEditing = useIsEditing();
	if (isEditing) {
		return (
			<ComponentPlaceholder
				componentName="MediaDouble"
				deprecated="Deadline Aug 25, 2025 [DP-7854]"
			/>
		);
	}

	if (!fields) {
		return null;
	}

	const items = fields.items
		?.map((item) => ({
			portraitImage: getAsset('Templated 3:4', item.portraitImage),
			portraitVideo: getAsset('Source Copy', item.portraitVideo),
			preventAutoplay: Boolean(item.preventAutoplay?.value),
			products: item.products,
			squareImage: getAsset('Templated 1:1', item.squareImage),
			squareVideo: getAsset('Source Copy', item.video),
		}))
		.filter(
			(item) =>
				(item.portraitImage ?? item.portraitVideo) &&
				(item.squareImage ?? item.squareVideo),
		);

	if (!is.arrayWithLength(items) || items.length < 2) {
		return (
			<ComponentPlaceholder
				componentName="MediaDouble"
				description="Requires two items with content (image or video) for both mobile and desktop"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			backgroundColor={fields.backgroundColor?.fields?.colorHex?.value}
		>
			<div className="grid max-w-col-6 grid-cols-2 gap-x-4 md:gap-x-6">
				{items.map(
					({
						portraitImage,
						portraitVideo,
						preventAutoplay,
						products,
						squareImage,
						squareVideo,
					}) => (
						<React.Fragment key={`${squareImage?.src}-${squareVideo?.src}`}>
							<BlockMedia
								className="max-md:hidden"
								variant="square"
								imgSrc={squareImage?.src}
								imgAlt={squareImage?.alt}
								preventAutoplay={preventAutoplay}
								video={squareVideo}
								products={products}
								productsDisplayType="shopByMedia"
								gtmItemListId="block"
								gtmItemListName={`Block: ${fields.datasourceName ?? 'MediaDoubleUnknown'}`}
							/>
							<BlockMedia
								className="max-w-col-2 md:hidden"
								variant="portrait"
								imgSrc={portraitImage?.src}
								imgAlt={portraitImage?.alt}
								video={portraitVideo}
								preventAutoplay={preventAutoplay}
								products={products}
								productsDisplayType="shopByMedia"
								gtmItemListId="block"
								gtmItemListName={`Block: ${fields.datasourceName ?? 'MediaDoubleUnknown'}`}
							/>
						</React.Fragment>
					),
				)}
			</div>
		</LayoutContainer>
	);
}
MediaDouble.displayName = 'MediaDouble';
