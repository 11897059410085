import React, { type ReactNode } from 'react';

import { PriceText } from 'components/Price';
import Text from 'components/Text';
import { cn } from 'utils/classNames';

interface Props {
	children?: ReactNode;
	className?: string;
	price?: string;
	priceSymbol?: string;
	subtitle?: string;
	title?: string;
	variant?: 'minusPrice' | 'regular';
}

export default function PriceRow({
	children,
	className,
	price,
	priceSymbol,
	subtitle,
	title,
	variant = 'regular',
}: Props) {
	return (
		<div
			className={cn(
				'flex break-inside-avoid flex-col justify-between',
				className,
			)}
		>
			<div className="flex justify-between">
				<Text as="p">{title}</Text>
				<PriceText
					price={price}
					symbol={priceSymbol}
					className={cn(
						'font-bold',
						variant === 'minusPrice' && 'text-julaRed',
					)}
				/>
			</div>
			<div className="ml-4">
				<Text as="pSmall" className="text-greyDarker">
					{subtitle}
				</Text>
				{children}
			</div>
		</div>
	);
}
PriceRow.displayName = 'PriceRow';
