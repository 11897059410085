import { stringify } from 'query-string';

import type { Ticket } from 'models/api';
import { getPostSigningRedirectUrl } from 'pages/api/sign/helpers';
import { API_URL, fetchData } from 'utils/fetch';

import type {
	CustomerInfo,
	JulaProAuthRequestParams,
	JulaProOrganizationSearchParams,
	JulaProOrganizationSelectParams,
	JulaProRegisterCustomerParams,
	Organization,
	Worksite,
} from './createJulaProCustomer.types';

export const requestJulaProApiAuth = (params: JulaProAuthRequestParams) =>
	fetchData<CustomerInfo>(`${API_URL}Customer/julapro/auth`, {
		method: 'POST',
		body: params,
	});

export const requestJulaProApiOrganizationSearch = (
	params: JulaProOrganizationSearchParams,
) =>
	fetchData<Organization[]>(
		`${API_URL}Customer/julapro/search/company?${stringify(params)}`,
	);

export const requestJulaProApiOrganizationSelect = (
	params: JulaProOrganizationSelectParams,
) => {
	const { worksiteId, ...otherParams } = params;
	return fetchData<Worksite>(
		`${API_URL}Customer/julapro/worksite/${worksiteId}?${stringify(
			otherParams,
		)}`,
	);
};

export const requestJulaProApiRegisterCustomer = ({
	customerData,
}: JulaProRegisterCustomerParams) => {
	const apiPath = `${API_URL}Customer/julapro?termsAndConditionsAccepted=${customerData.termsAndConditionsAccepted}`;
	const postSigningRedirectUrl = getPostSigningRedirectUrl();
	const body = JSON.stringify({ postSigningRedirectUrl, ...customerData });
	return fetchData<Ticket>(apiPath, { method: 'POST', body });
};

export const requestPollCustomerRegistrationStatus = () =>
	fetchData<Ticket>(`${API_URL}Ticket/Customer`);
export const requestCancelSigning = () =>
	fetchData<Ticket>(`${API_URL}Ticket/Customer/cancel`);

export const bankIdSignInInNewTab = async (iframeSrc?: string) =>
	new Promise<Window>((resolve, reject) => {
		if (!iframeSrc) {
			reject();
			return;
		}

		const authWindow = window.open(iframeSrc);
		if (authWindow) {
			resolve(authWindow);
		} else {
			reject();
		}
	});

export const signInNewCustomer = () =>
	fetch('/api/signin/session/?forceRefresh=true');
