import React from 'react';

import { StoreIdName, useFeatureToggle } from 'contexts';
import { ItemStockResponse, Stock } from 'models/api';
import { Product } from 'models/product';
import { cn } from 'utils/classNames';

import StoreStock from './StoreStock';
import WebStock from './WebStock';

interface Props {
	allStoresStock: Stock[] | undefined;
	className?: string;
	currentProductStock: ItemStockResponse | undefined;
	currentStoreStock: Stock | undefined;
	isLoadingAllStoresStock: boolean;
	isLoadingInitialStock: boolean;
	isLoadingNearbyStoresStock: boolean;
	isLoadingProductStock: boolean;
	nearbyStoresStock: Stock[] | undefined;
	onOpenCallBack: () => void;
	onUpdateSelectedStore: (store: StoreIdName) => void;
	product: Product;
	selectedStore: StoreIdName | undefined;
}

export default function StockInformation({
	allStoresStock,
	className,
	currentProductStock,
	currentStoreStock,
	isLoadingAllStoresStock,
	isLoadingInitialStock,
	isLoadingNearbyStoresStock,
	isLoadingProductStock,
	nearbyStoresStock,
	onOpenCallBack,
	onUpdateSelectedStore,
	product,
	selectedStore,
}: Props) {
	const { storesEnabled } = useFeatureToggle();
	return (
		<div
			className={cn(
				className,
				'divide-y divide-grey rounded-border border border-grey',
			)}
		>
			<WebStock
				product={product}
				webStock={currentProductStock?.webStock}
				isLoadingInitialStock={isLoadingInitialStock}
			/>
			{storesEnabled && (
				<StoreStock
					currentProductStock={currentProductStock}
					currentStoreStock={currentStoreStock}
					allStoresStock={allStoresStock}
					nearbyStoresStock={nearbyStoresStock}
					onOpenCallBack={onOpenCallBack}
					onUpdateSelectedStore={onUpdateSelectedStore}
					product={product}
					selectedStore={selectedStore}
					isLoadingInitialStock={isLoadingInitialStock}
					isLoadingProductStock={isLoadingProductStock}
					isLoadingAllStoresStock={isLoadingAllStoresStock}
					isLoadingNearbyStoresStock={isLoadingNearbyStoresStock}
				/>
			)}
		</div>
	);
}
StockInformation.displayName = 'ProductDetails_StockInformation';
