import { ReferenceManagementMachineState } from './referenceManagement.machine';

export const selectStateIsLoadingReferenceList = (
	state: ReferenceManagementMachineState,
) => state.matches('listingReferences.referenceListFlow.loadingReferenceList');

export const selectStateIsErrorGettingReferenceList = (
	state: ReferenceManagementMachineState,
) =>
	state.matches(
		'listingReferences.referenceListFlow.errorGettingReferenceList',
	);

export const selectReferenceList = (state: ReferenceManagementMachineState) =>
	state.context.referenceList;

export const selectStateIsLoadingReference = (
	state: ReferenceManagementMachineState,
) => state.matches('viewingReference.viewReferenceFlow.loadingReference');

export const selectStateIsErrorGettingReference = (
	state: ReferenceManagementMachineState,
) => state.matches('viewingReference.viewReferenceFlow.errorGettingReference');

export const selectReference = (state: ReferenceManagementMachineState) =>
	state.context.selectedReference;

export const selectUpdateButtonState = (
	state: ReferenceManagementMachineState,
) => state.context.updateButtonState;

export const selectRemoveReferenceButtonState = (
	state: ReferenceManagementMachineState,
) => state.context.removeReferenceButtonState;

export const selectAddReferenceButtonState = (
	state: ReferenceManagementMachineState,
) => state.context.addReferenceButtonState;

export const selectContactList = (state: ReferenceManagementMachineState) =>
	state.context.contactList;

export const selectAddReferenceError = (
	state: ReferenceManagementMachineState,
) => state.context.addReferenceError;

export const selectEditReferenceError = (
	state: ReferenceManagementMachineState,
) => state.context.editReferenceError;

export const selectStateIsErrorRemovingReference = (
	state: ReferenceManagementMachineState,
) =>
	state.matches('viewingReference.removeReferenceFlow.errorRemovingReference');
