import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlockAccordion from 'components/BlockAccordion';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import type { JulaComponentProps } from 'lib/component-props';
import type { FaqArticle } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';

type Props = JulaComponentProps & {
	fields?: {
		articles?: FaqArticle[];
		heading?: Field<string>;
	};
};

export default function FAQs({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const items = filterTruthy(
		fields.articles?.map((article) => ({
			content: article.fields.content.value,
			heading: article.fields.summary.value,
		})),
		'content',
		'heading',
	);

	if (items.length === 0) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="FAQs"
				description="Requires at least one article that has a heading and content"
			/>
		);
	}

	return (
		<BlockAccordion
			className={getEditorMargin(params)}
			items={items}
			heading={fields.heading}
			headingLevel={params?.heading}
		/>
	);
}
FAQs.displayName = 'FAQs';
