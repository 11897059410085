import useSWRImmutable from 'swr/immutable';

import type {
	CustomLayoutServiceData,
	LayoutServicePlaceholder,
} from 'lib/page-props';
import type { WithDisallowedKeys } from 'types';
import { fetchData } from 'utils/fetch';
import { getLayoutServiceComponentFields } from 'utils/helpers';
import { createUrl, type URLParams } from 'utils/url';

export type PlaceholderQueryVars = WithDisallowedKeys<
	URLParams,
	'item' | 'sc_plh'
>;

interface Params {
	/** Get a single component in the placeholder. */
	componentName?: string;

	/** If the hook is active, does nothing if false. */
	isActive?: boolean;

	/** URL path to fetch placeholder from, defaults to root. */
	path?: string;

	/** Any additional query parameters. */
	queryVars?: PlaceholderQueryVars;
}

/**
 * Fetch data for a Sitecore placeholder via proxy.
 */
export function useLayoutServicePlaceholder<T = unknown>(
	placeholder: LayoutServicePlaceholder,
	{ componentName, isActive = true, path = '/', queryVars }: Params = {},
) {
	const { data, error, isLoading } = useSWRImmutable(
		isActive
			? createUrl('/api/proxy', {
					...queryVars,
					item: path.split('#')[0],
					sc_plh: placeholder,
				})
			: null,
		fetchData<CustomLayoutServiceData>,
		{ keepPreviousData: true },
	);

	return {
		route: data?.sitecore.route,
		placeholder: data?.sitecore.route?.placeholders?.[placeholder],
		component: componentName
			? getLayoutServiceComponentFields<T>(data, placeholder, componentName)
			: undefined,
		isLoading,
		error,
	};
}
