import React from 'react';

import OptionalContainer from 'components/OptionalContainer';
import ProductTag from 'components/ProductTag';
import type { ProductTag as ProductTagModel } from 'models/product';
import { cn } from 'utils/classNames';

interface Props {
	className?: string;
	tags: ProductTagModel[];
}

export default function ProductCardTags({ className, tags }: Props) {
	const hasMultipleTags = tags.length > 1;

	if (tags.length === 0) {
		return null;
	}

	// Normally an unnecessary optimization to be stringent about a
	// wrapping div, but there can be a lot of product cards on a page
	// and the site's DOM size is a problem.
	return (
		<OptionalContainer
			as="div"
			hasContainer={hasMultipleTags}
			className={cn('flex flex-wrap gap-2', className)}
		>
			{tags.map((tag) => (
				<ProductTag
					key={tag.text}
					type={tag.type}
					text={tag.text}
					size="small"
					className={cn(!hasMultipleTags && className)}
				/>
			))}
		</OptionalContainer>
	);
}
ProductCardTags.displayName = 'ProductCardTags';
