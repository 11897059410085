import React from 'react';
import dynamic from 'next/dynamic';

import LoadingSpinner from 'components/LoadingSpinner';

import type { Props } from './PickupLocationMap';

const PickupLocationMapSuspense = dynamic(() => import('./PickupLocationMap'), {
	loading: () => <LoadingSpinner size="medium" />,
});

export default function DynamicPickupLocationMap(props: Props) {
	return <PickupLocationMapSuspense {...props} />;
}
DynamicPickupLocationMap.displayName = 'DynamicPickupLocationMap';
