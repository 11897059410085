import React from 'react';

import Wishlist, {
	formatWishListDataForGTM,
	mapWishlistToIdQuantity,
} from 'components/Wishlist';
import {
	useFeatureToggle,
	useGlobalMachinesContext,
	useSelectedStore,
} from 'contexts';
import { useProductGTMEvents, useSharedWishlist } from 'hooks';
import { type Wishlist as WishlistModel, WishlistVariant } from 'models/api';
import { useI18n } from 'utils/i18n';

export default function SharedWishlist() {
	const { t } = useI18n();
	const { cartService, wishlistService } = useGlobalMachinesContext();
	const { selectedStore } = useSelectedStore();
	const { onlineCommerceEnabled, storesEnabled, wishlistEnabled } =
		useFeatureToggle();
	const { sharedText, wishlistData } = useSharedWishlist();
	const { sendAddMultipleToCartEvent, sendAddToCartEvent } =
		useProductGTMEvents();
	const moveAllVariantsToCart = (list: WishlistModel | undefined) => {
		if (!list) return;
		cartService.send({
			type: 'ADD_MULTIPLE_VARIANTS_MINI',
			variants: mapWishlistToIdQuantity(list),
		});
		sendAddMultipleToCartEvent({ products: formatWishListDataForGTM(list) });
	};
	const moveAllVariantsToWishlist = (list: WishlistModel | undefined) => {
		if (!list) return;
		wishlistService.send({
			type: 'ADD_MULTIPLE_VARIANTS_MINI',
			variants: mapWishlistToIdQuantity(list),
		});
	};
	const moveVariantToCart = (variant: WishlistVariant) => {
		if (!variant.id) return;
		cartService.send({
			type: 'ADD_REMOVE_VARIANT',
			variantId: variant.id,
			qty: variant.qty,
			distinctValue: false,
		});
		sendAddToCartEvent({ product: variant, quantity: variant.qty });
	};

	const moveVariantToWishlist = (variant: WishlistVariant) => {
		if (!variant.id) return;
		wishlistService.send({
			type: 'CHANGE_VARIANT_QTY',
			qty: variant.qty,
			variantId: variant.id,
			GTMData: {
				type: 'add_to_wishlist',
				payload: { product: variant, quantity: variant.qty },
			},
		});
	};

	return (
		<Wishlist
			wishlistEnabled={wishlistEnabled}
			storesEnabled={storesEnabled}
			onlineCommerceEnabled={onlineCommerceEnabled}
			heading={t('wishlist_shared_wishlist_heading')}
			wishlist={wishlistData}
			sharedText={sharedText}
			moveVariantToCartButtonText={t('wishlist_add_product_to_cart_button')}
			moveAllVariantsToCartButtonText={t(
				'wishlist_add_all_products_to_cart_button',
			)}
			isLoading={false}
			moveVariantToWishlistButtonText={t('cart_move_to_wishlist_button')}
			moveAllVariantsToWishlistButtonText={t('wishlist_move_all_to_wishlist')}
			onMoveAllVariantsToCart={() => moveAllVariantsToCart(wishlistData)}
			onMoveAllVariantsToWishlist={() =>
				moveAllVariantsToWishlist(wishlistData)
			}
			onMoveVariantToCart={moveVariantToCart}
			selectedStore={selectedStore}
			onMoveVariantToWishlist={moveVariantToWishlist}
		/>
	);
}

SharedWishlist.displayName = 'SharedWishlist';
