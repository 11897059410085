import React, { type Key, type ReactNode } from 'react';

import { cn } from 'utils/classNames';

interface Cell {
	colSpan?: number;
	content: ReactNode;
	isHeader?: boolean;
	key?: Key;
	rowSpan?: number;
}

interface Row {
	cells: Cell[];
	isHeader?: boolean;
	key?: Key;
}

interface Props {
	className?: string;
	fullWidth?: boolean;
	rows: Row[];
}

interface TrProps {
	row: Row;
	stripe: 'odd' | 'even' | 'always';
}

function Tr({ row, stripe }: TrProps) {
	return (
		<tr
			className={
				stripe === 'always'
					? 'bg-tableStripe'
					: stripe === 'odd'
						? 'odd:bg-tableStripe'
						: 'even:bg-tableStripe'
			}
		>
			{row.cells.map((cell, i) => {
				const isHeader = Boolean(row.isHeader || cell.isHeader);
				const Element = isHeader ? 'th' : 'td';
				return (
					<Element
						key={cell.key ?? i}
						className={cn(
							'h-10 border border-greyLight px-2 py-1 text-sm',
							isHeader && 'text-left font-bold',
						)}
						scope={row.isHeader ? 'col' : cell.isHeader ? 'row' : undefined}
						colSpan={cell.colSpan}
						rowSpan={cell.rowSpan}
					>
						{cell.content}
					</Element>
				);
			})}
		</tr>
	);
}
Tr.displayName = 'Table_Tr';

/**
 * A striped table that can handle overflow.
 *
 * Keep styles in sync with tables in richtext.
 */
export default function Table({ className, fullWidth, rows }: Props) {
	const headerRows = rows.filter((row) => row.isHeader);
	const nonHeaderRows = rows.filter((row) => !row.isHeader);

	return (
		<div className={cn('overflow-x-auto', className)}>
			<table className={cn('border-collapse', fullWidth && 'w-full')}>
				{headerRows.length > 0 && (
					<thead>
						{headerRows.map((row, i) => (
							<Tr key={row.key ?? i} row={row} stripe="always" />
						))}
					</thead>
				)}
				<tbody>
					{nonHeaderRows.map((row, i) => (
						<Tr
							key={row.key ?? i}
							row={row}
							stripe={headerRows.length > 0 ? 'even' : 'odd'}
						/>
					))}
				</tbody>
			</table>
		</div>
	);
}
Table.displayName = 'Table';
