import { waitFor } from 'xstate/lib/waitFor';

import { ignorePromiseRejection } from 'utils/helpers';

import type { GTMHelperInput } from './GTMHelperInputs';
import { formatGTMData } from './helpers';

async function dataLayerPush(helperEvent: GTMHelperInput) {
	// The fetch lock is unlocked by the user machine
	// the same machine also sets the user that we need to get here
	// this is a convenient way to ensure that the user is set before we push to GTM
	await waitFor(
		globalThis.globalFetchLockInterpreter,
		(state) => state.matches('unlocked'),
		{ timeout: 20_000 },
	);

	const gtmEvent = formatGTMData(helperEvent, globalThis.userType);
	if (!gtmEvent) {
		return;
	}

	if ('ecommerce' in gtmEvent) {
		globalThis.dataLayer?.push({ ecommerce: null });
	}
	globalThis.dataLayer?.push(gtmEvent);
}
export function pushToGTM(helperEvent: GTMHelperInput) {
	ignorePromiseRejection(dataLayerPush(helperEvent));
}
