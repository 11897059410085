/**
 * SearchResultsPage
 */

import React from 'react';
import { useRouter } from 'next/router';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import Link, { AnchorLink } from 'components/Link';
import { MicroContentMedia } from 'components/MicroContent';
import ProductListComponent from 'components/ProductList';
import StoreListItem from 'components/StoreListItem';
import Text from 'components/Text';
import { withRequiredProps } from 'hoc';
import { useIsEditing } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { Store } from 'models/api';
import type {
	InspirationPage,
	ProductList as ProductListModel,
} from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';
import { getAsset, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

const SEARCH_RESULTS_PAGE_INSPIRATION_PAGES_ANCHOR = 'inspiration-pages';

type Props = JulaComponentProps & {
	fields: ProductListModel & {
		inspirationPages?: InspirationPage[];
		spellingSuggestions?: string[];
		stores?: Store[];
	};
};

function SearchResultsPage({ fields, params }: Props) {
	const { t, tPlural } = useI18n();
	const router = useRouter();
	const query = router.query.query?.toString() || '';
	const { spellingSuggestions, stores, total: totalProductCount } = fields;

	const isEditing = useIsEditing();
	const inspirationPages = filterTruthy(
		fields.inspirationPages?.map((item) => ({
			href: item.url,
			imgSrc: getAsset('Templated 2:1', item.fields?.landscapeImage)?.src,
			key: item.id,
			text: item.fields?.navigationTitle?.value,
		})),
		'href',
		'imgSrc',
		'text',
	);

	const hasInspirationPages = is.arrayWithLength(inspirationPages);
	const hasStores = is.arrayWithLength(stores);
	const isEmptySearch =
		totalProductCount === 0 && !hasInspirationPages && !hasStores;

	if (isEditing && isEmptySearch) {
		return (
			<ComponentPlaceholder
				componentName="ProductList"
				className={getEditorMargin(params?.margin, 'Half')}
			/>
		);
	}

	if (!query) {
		return (
			<div className="mx-4 my-20 text-center">
				<Text as="h1" text={t('search_no_search_results_text')} />
			</div>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params?.margin, 'Half')}
		>
			<Text as="h1">
				<>
					{t('search_query_label')}{' '}
					<span className="[quotes:auto] before:content-[open-quote] after:content-[close-quote]">
						{query}
					</span>
				</>
			</Text>
			{is.arrayWithLength(spellingSuggestions) && (
				<Text as="p" className="mt-4">
					{t('search_results_spelling_suggestion_text')}{' '}
					{spellingSuggestions.map((suggestion, index) => (
						<React.Fragment key={suggestion}>
							<Link
								href={`/search/?query=${suggestion}`}
								className="underline hover:no-underline"
							>
								{suggestion}
							</Link>
							{index < spellingSuggestions.length - 1 ? ', ' : ''}
						</React.Fragment>
					))}
					?
				</Text>
			)}
			{isEmptySearch && (
				<>
					<Text
						as="p"
						className="mt-2"
						text={`${totalProductCount} ${t('search_number_of_hits_label')}`}
					/>
					<Text
						as="p"
						className="mt-6"
						text={t('search_page_no_search_results_text')}
					/>
				</>
			)}
			{!isEmptySearch && (
				<>
					{hasStores && (
						<ul className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 md:gap-6">
							{stores.map((store) => (
								<StoreListItem
									className="col-span-1"
									key={store.id}
									name={store.name}
									url={store.url}
									openHours={store.todaysOpeningHours?.description ?? ''}
									isOpen={
										store.todaysOpeningHours?.state?.toUpperCase() === 'OPEN'
									}
									address={`${store.streetAddress}, ${store.postalCode}, ${store.city}`}
									storeArea={store.storeArea ?? ''}
								/>
							))}
						</ul>
					)}
					{hasInspirationPages && (
						<p className="mt-6">
							{t('search_page_result_description_text')}{' '}
							<AnchorLink
								underline
								anchor={SEARCH_RESULTS_PAGE_INSPIRATION_PAGES_ANCHOR}
							>
								{tPlural(
									'inspiration_item_count_text',
									inspirationPages.length,
								)}
							</AnchorLink>
						</p>
					)}
					<ProductListComponent
						key={query}
						className="mt-6"
						fields={fields}
						gtmItemListId="search"
						gtmItemListName="Search result"
						placeholderComponentName="SearchResultsPage"
						searchQuery={query}
					/>
					{hasInspirationPages && (
						<>
							<Text
								as="h2"
								className="mb-4 mt-14 md:mt-20"
								text={t('inspiration_heading')}
								id={SEARCH_RESULTS_PAGE_INSPIRATION_PAGES_ANCHOR}
							/>
							<ul className="grid grid-cols-1 items-start gap-4 sm:grid-cols-2 md:grid-cols-4 md:gap-6">
								{inspirationPages.map((page) => (
									<li key={page.key} className="col-span-1">
										<MicroContentMedia
											href={page.href}
											imgSrc={page.imgSrc}
											imgWidth={393}
											imgHeight={197}
											text={page.text}
										/>
									</li>
								))}
							</ul>
						</>
					)}
				</>
			)}
		</LayoutContainer>
	);
}
SearchResultsPage.displayName = 'SearchResultsPage';

export default withRequiredProps(SearchResultsPage, 'fields');
