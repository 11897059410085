import React, { Fragment, useEffect } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useSelector } from '@xstate/react';
import type { FormApi } from 'final-form';
import { waitFor } from 'xstate/lib/waitFor';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import {
	Checkbox,
	FieldOnChange,
	required,
	TextInput,
} from 'components/FinalForm';
import InfoBox from 'components/InfoBox';
import Popover from 'components/Popover';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import { isError } from 'errors';
import type { JulaProUpdatedReference } from 'models/api';
import {
	type ReferenceManagementMachineActor,
	selectAddReferenceError,
	selectContactList,
} from 'state-machines/referenceManagement';
import { getFieldErrors } from 'utils/formHelpers';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	/** onClose callback */
	onClose: () => void;

	/** onSubmit callback */
	onSubmit: (newReference: JulaProUpdatedReference) => void;

	/** Should Popover be opened */
	open: boolean;

	/** reference manager machine actor */
	referenceManagementActor: ReferenceManagementMachineActor;

	showReferences?: boolean;

	/** submit button state */
	submitButtonState: ActionButtonState;
}

export default function AddReferenceForm({
	onClose,
	onSubmit,
	open,
	referenceManagementActor,
	showReferences = true,
	submitButtonState,
}: Props) {
	const { t } = useI18n();

	const contactList = useSelector(referenceManagementActor, selectContactList);
	const addReferenceError = useSelector(
		referenceManagementActor,
		selectAddReferenceError,
	);

	useEffect(() => {
		if (open) {
			referenceManagementActor.send('GET_CONTACTS');
		}
	}, [open, referenceManagementActor]);

	const submitAddReference = async (
		values: Record<string, unknown>,
		form: FormApi,
	) => {
		if (!is.string(values.referenceName)) {
			return { FORM_ERROR: 'badInput' };
		}

		onSubmit({
			newReferenceName: values.referenceName,
			contactPermissions:
				contactList?.map((contact) => ({
					customerContactId: contact.customerContactId,
					canUseReference: Boolean(
						values[`contact-${contact.customerContactId}`],
					),
				})) ?? [],
		});

		const doneData = await waitFor(
			referenceManagementActor,
			(state) => state.hasTag('addReferenceRequestEnded'),
			{
				// 20 seconds in ms
				timeout: 20_000,
			},
		);

		if (doneData?.context?.addReferenceError) {
			return getFieldErrors(doneData.context.addReferenceError);
		}

		setTimeout(() => {
			form.reset();
			onClose();
		}, 2500);
		return undefined;
	};

	return (
		<Popover
			isOpen={open}
			onClose={onClose}
			title={`${t('julapro_references_add_reference_overlay_heading')}`}
		>
			<p>{t('julapro_references_add_reference_form_description')}</p>

			{isError(addReferenceError, 'ValidationError') &&
				is.arrayWithLength(addReferenceError?.generalErrors) && (
					<div className="sticky top-0 z-99 mt-4 space-y-2">
						{addReferenceError.generalErrors.map((error) => (
							<InfoBox
								key={error.key}
								icon="error"
								variant="error"
								message={error.text}
							/>
						))}
					</div>
				)}

			<Form
				onSubmit={submitAddReference}
				render={({ form, handleSubmit }) => (
					<form
						onSubmit={handleSubmit}
						id="addJulaProReference"
						className="mt-6"
					>
						<TextInput
							id="code"
							name="referenceName"
							label={t('julapro_references_edit_form_code_label')}
							placeholder={t('julapro_references_edit_form_code_placeholder')}
							validate={required()}
						/>
						{showReferences && (
							<>
								{!contactList && (
									<Skeleton>
										<SkeletonItem height="3.5rem" className="my-4" />
									</Skeleton>
								)}
								{contactList && (
									<fieldset className="mb-8 mt-4 space-y-4">
										<legend>
											{t('julapro_references_edit_form_users_label')}
										</legend>

										<Checkbox
											id="checkAll"
											name="checkAll"
											label={t('julapro_references_form_check_all_users')}
										/>
										<FieldOnChange<boolean> name="checkAll">
											{(value) => {
												if (!value) return;
												contactList?.forEach((contact) => {
													form.change(
														`contact-${contact.customerContactId}`,
														value,
													);
												});
											}}
										</FieldOnChange>
										{contactList?.map((contact) => (
											<Fragment key={contact.customerContactId}>
												<Checkbox
													key={contact.customerContactId}
													id={`contact-${contact.customerContactId}`}
													name={`contact-${contact.customerContactId}`}
													label={contact.name}
												/>
												<FieldOnChange<boolean>
													name={`contact-${contact.customerContactId}`}
												>
													{(value) => {
														if (!value) {
															form.change('checkAll', false);
														}
													}}
												</FieldOnChange>
											</Fragment>
										))}
									</fieldset>
								)}
							</>
						)}
						<FormSpy subscription={{ values: true }}>
							{({ values }) => {
								const valuesArray = Object.values(values);
								return (
									<ActionButton
										displayWidth="full"
										type="submit"
										size="large"
										variant="cta"
										disabled={showReferences && !valuesArray.includes(true)}
										customState={submitButtonState}
										className="m-0"
									>
										{t('julapro_references_add_reference_button_text')}
									</ActionButton>
								);
							}}
						</FormSpy>
					</form>
				)}
			/>
		</Popover>
	);
}
AddReferenceForm.displayName = 'AccountJulaProReferences_AddReferenceForm';
