import React from 'react';

import Placeholder from 'components/Placeholder';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import { type PlaceholderQueryVars, useLayoutServicePlaceholder } from 'hooks';
import { PartialPopoverProps } from 'lib/component-props';

import Popover from './Popover';

interface Props<T> {
	heading: string;
	isOpen: boolean;
	item: string | undefined;
	onClose: () => void;
	partialProps?: T;
	partialQueryVars?: PlaceholderQueryVars;
}

export default function PartialPopover<T extends Record<keyof T, unknown>>({
	heading,
	isOpen,
	item,
	onClose,
	partialProps,
	partialQueryVars,
}: Props<T>) {
	const { error, isLoading, route } = useLayoutServicePlaceholder('jula-main', {
		isActive: Boolean(item) && isOpen,
		path: item,
		queryVars: partialQueryVars,
	});
	return (
		<Popover
			isOpen={isOpen}
			title={heading}
			onClose={onClose}
			contentClassName="flex flex-col"
		>
			{isLoading && (
				<Skeleton>
					<div className="space-y-3">
						<SkeletonItem height="3rem" className="mb-6" />
						<SkeletonItem height="1.5rem" />
						<SkeletonItem height="1.5rem" />
						<SkeletonItem height="1.5rem" className="w-1/2" />
					</div>
				</Skeleton>
			)}
			{!isLoading && !error && route && (
				<div className="grow [&>*:first-child]:mt-0">
					<Placeholder<PartialPopoverProps>
						name="jula-main"
						rendering={route}
						partialProps={{ onPopoverClose: onClose, ...partialProps }}
					/>
				</div>
			)}
		</Popover>
	);
}
PartialPopover.displayName = 'PartialPopover';
