import React from 'react';

import {
	StoreStockBalanceConcise,
	WebStockBalanceConcise,
} from 'components/Stock/';
import { useGlobalPopoversContext, useSelectedStore } from 'contexts';
import {
	getProductCardStoreStockStatus,
	getProductCardWebStockStatus,
} from 'utils/business-logic/stock';
import { cnm } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

export interface StoreStock {
	inStock: boolean;
	isSellableInStore: boolean;
	isSellableOnWeb: boolean;
	name: string | undefined;
	productInStockAtStores: number;
}

export interface WebStock {
	isSellable: boolean;
	isSellableInStore: boolean;
	isSellableOnWeb: boolean;
	productReStockWeb: boolean;
}

export interface StockInfo {
	storeStock: StoreStock | undefined;
	webStock: WebStock;
}

interface Props {
	className?: string;
	productId: string;
	stockInfo: StockInfo;
	storeStockVisible: boolean;
}

export default function ProductCardStockInfo({
	className,
	productId,
	stockInfo: { storeStock, webStock },
	storeStockVisible,
}: Props) {
	const { t } = useI18n();
	const { selectedStore } = useSelectedStore();
	const { openStoreSelector } = useGlobalPopoversContext();
	const hasSelectedStore = Boolean(selectedStore);

	return (
		<div
			className={cnm(
				'flex flex-col gap-1',
				!hasSelectedStore && 'relative z-3',
				className,
			)}
		>
			<WebStockBalanceConcise status={getProductCardWebStockStatus(webStock)} />
			{storeStockVisible && storeStock && (
				<StoreStockBalanceConcise
					storeName={storeStock.name || ''}
					productInStockAtStores={storeStock.productInStockAtStores}
					onOpenStoreSelector={() => {
						openStoreSelector(productId);
					}}
					buttonText={t('select_store_button_text')}
					status={getProductCardStoreStockStatus({
						storeSelected: Boolean(storeStock?.name),
						...storeStock,
					})}
				/>
			)}
		</div>
	);
}
ProductCardStockInfo.displayName = 'ProductCard_ProductCardStockInfo';
