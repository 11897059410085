import React, { type ReactNode } from 'react';
import {
	type ComponentPropsCollection,
	ComponentPropsContext,
	SitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { SWRConfig } from 'swr';

import type { CustomLayoutServiceData, UserData } from 'lib/page-props';
import { componentBuilder } from 'temp/componentBuilder';
import { type Dictionary, I18nProvider } from 'utils/i18n';

import { ChatContextProvider } from './ChatContextProvider';
import { FeatureToggleContextProvider } from './FeatureToggleContextProvider';
import { GlobalMachinesContextProvider } from './GlobalMachinesContextProvider';
import { GlobalMemberPopoversContextProvider } from './GlobalMemberPopoversContextProvider';
import { GlobalPopoversContextProvider } from './GlobalPopoversContextProvider';
import { PriceContextProvider } from './PriceContextProvider';
import { SelectedStoreContextProvider } from './SelectedStoreContextProvider';
import { SelectedStoreInfoContextProvider } from './SelectedStoreInfoContextProvider';
import { UserContextProvider } from './UserContextProvider';

interface Props {
	children: ReactNode;
	componentProps: ComponentPropsCollection;
	dictionary: Dictionary | undefined;
	layoutData: CustomLayoutServiceData;
	userData: UserData | undefined;
}

export default function GlobalContexts({
	children,
	componentProps,
	dictionary,
	layoutData,
	userData,
}: Props) {
	const isEditing = layoutData.sitecore.context.pageEditing;
	const sitecoreContext = layoutData.sitecore.context;
	return (
		<SitecoreContext
			componentFactory={componentBuilder.getComponentFactory({
				isEditing,
			})}
			layoutData={layoutData}
		>
			<GlobalPopoversContextProvider>
				<GlobalMemberPopoversContextProvider>
					<SelectedStoreContextProvider
						initialSelectedStore={userData?.selectedStore}
					>
						<SelectedStoreInfoContextProvider>
							<FeatureToggleContextProvider
								featureToggles={sitecoreContext.featureToggles}
							>
								<GlobalMachinesContextProvider
									sitecoreContext={sitecoreContext}
								>
									<ChatContextProvider>
										<SWRConfig value={{ loadingTimeout: 10_000 }}>
											<I18nProvider dictionary={dictionary}>
												<ComponentPropsContext value={componentProps}>
													<UserContextProvider
														userInfo={{
															userType: userData?.userType,
															customerType: userData?.customerType,
															loginMethod: userData?.loginMethod,
														}}
													>
														<PriceContextProvider
															initialUsePriceExcVat={
																userData?.usePriceExcVat ?? false
															}
														>
															{children}
														</PriceContextProvider>
													</UserContextProvider>
												</ComponentPropsContext>
											</I18nProvider>
										</SWRConfig>
									</ChatContextProvider>
								</GlobalMachinesContextProvider>
							</FeatureToggleContextProvider>
						</SelectedStoreInfoContextProvider>
					</SelectedStoreContextProvider>
				</GlobalMemberPopoversContextProvider>
			</GlobalPopoversContextProvider>
		</SitecoreContext>
	);
}
GlobalContexts.displayName = 'GlobalContexts';
