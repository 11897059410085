import React, { type RefObject, useRef, useState } from 'react';

import ErrorBoundary from 'components/ErrorBoundary';
import { GlobalStoreSelectorButton } from 'components/GlobalStoreSelector';
import IconButton from 'components/IconButton';
import JulaLogo from 'components/JulaLogo';
import { LayoutContainer } from 'components/Layout';
import Link from 'components/Link';
import {
	HEADER_MAIN_NAVIGATION_ID,
	HEADER_SEARCH_FIELD_ID,
	HEADER_SEARCH_FIELD_MOBILE_ID,
} from 'constants/ids';
import { useFeatureToggle } from 'contexts';
import { useIsStickyStuck, useMinWidth, useValueChangeEffect } from 'hooks';
import { cn } from 'utils/classNames';
import { goToElement } from 'utils/dom';
import { getTestDataAttrFrom, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import PageHeaderSearch from './PageHeaderSearch';

interface Props {
	/** Number of products in the cart */
	cartQuantity?: number;

	/** If the search bar should be hidden */
	hideSearchBar?: boolean;

	/** If the user is logged in */
	isAuthenticatedUser?: boolean;

	/** If the user is being initialised */
	isInitialisingUser?: boolean;

	/** If the viewport is scrolled past the entire header */
	isScrolledPastHeader: boolean;

	/** If the small screen navigation menu is open */
	isToggledMenuOpen: boolean;

	/** Small screen navigation menu toggle button ref */
	menuToggleRef: RefObject<HTMLButtonElement>;

	/** Click handler for logged in account button */
	onAccountClick: () => void;

	/** Click handler for login button */
	onLoginClick: () => void;

	/** Click handler for the small screen navigation menu button */
	onMenuToggleClick: () => void;

	/** Mousedown handler for the small screen navigation menu button */
	onMenuToggleMouseDown: () => void;

	/** Click handler for the store selector on small screens */
	onStoreSelectorClick: () => void;

	/** Wishlist product quantity */
	wishlistQuantity?: number;
}

/** Header main part with logo, search and icon buttons */
export default function PageHeaderMain({
	cartQuantity,
	hideSearchBar = false,
	isAuthenticatedUser = false,
	isInitialisingUser = false,
	isScrolledPastHeader,
	isToggledMenuOpen,
	menuToggleRef,
	onAccountClick,
	onLoginClick,
	onMenuToggleClick,
	onMenuToggleMouseDown,
	onStoreSelectorClick,
	wishlistQuantity = 0,
}: Props) {
	const { t, tPlural } = useI18n();
	const isMinMd = useMinWidth('md');
	const { julaClubEnabled, julaProEnabled, wishlistEnabled } =
		useFeatureToggle();

	const hasWishlistButton = wishlistEnabled;
	const hasAccountButton = julaClubEnabled || julaProEnabled;

	const runAccountClickCallback = () => {
		if (isAuthenticatedUser) {
			onAccountClick();
		} else {
			onLoginClick();
		}
	};

	// Since there is a single user button for the login panel and account menu,
	// the user info must be loaded to know which callback to trigger (i.e. is
	// the current user logged in or not). If the user presses the button before
	// that info is loaded, set a loading state to show UI feedback and trigger
	// the relevant callback when the info becomes available.
	const [hasVisualAccountLoading, setHasVisualAccountLoading] = useState(false);
	const handleAccountClick = () => {
		if (isInitialisingUser) {
			if (!hasVisualAccountLoading) {
				setHasVisualAccountLoading(true);
			}
			return;
		}
		runAccountClickCallback();
	};
	useValueChangeEffect(isInitialisingUser, (prevIsLoadingUser) => {
		if (prevIsLoadingUser && !isInitialisingUser && hasVisualAccountLoading) {
			runAccountClickCallback();
			setHasVisualAccountLoading(false);
		}
	});

	// Would prefer rem. Each Tailwind unit is 4 px. Match with top/padding diff
	// on container below.
	const [containerRef, isContainerStuck] = useIsStickyStuck<HTMLDivElement>(
		isMinMd ? -12 : -8,
	);

	const openSearchButtonRef = useRef<HTMLButtonElement>(null);
	const isSearchButtonVisible = isScrolledPastHeader;
	const [didUseOpenSearchButton, setDidUseOpenSearchButton] = useState(false);
	const handleOpenSearchClick = () => {
		setDidUseOpenSearchButton(true);
		// Let the 'click outside' logic in the search combobox finish before
		// moving focus to it. Otherwise it will focus → open → this button was
		// a click outside → close.
		setTimeout(() => {
			goToElement(HEADER_SEARCH_FIELD_MOBILE_ID, { scroll: false });
		});
	};
	const handleSmallSearchClose = () => {
		if (didUseOpenSearchButton) {
			setDidUseOpenSearchButton(false);
			// The combobox handles focus on close in several different ways, let
			// it finish before stealing focus to the button.
			setTimeout(() => {
				// Ensure the button is still available. It could be used to open
				// search and then disappear due to scrolling in the background.
				if (isSearchButtonVisible) {
					openSearchButtonRef.current?.focus();
				}
			}, 30);
		}
	};

	return (
		<>
			<div
				ref={containerRef}
				// No page-header-part class here, the stickyPageHeader z-index does
				// what's needed.
				className={cn(
					// Match top/padding with stuck hook above.
					'sticky z-stickyPageHeader bg-white transition-shadow',
					'-top-2 pb-2 pt-4',
					'headerRow:pb-3',
					'max-md:mb-1',
					'md:-top-3 md:pb-3 md:pt-6',
					isContainerStuck && 'forced-colors-outline shadow-xl',
				)}
			>
				<LayoutContainer className="flex flex-wrap items-center sm:flex-nowrap">
					<Link
						href="/"
						data-cy={getTestDataAttrFrom('header-logo-link')}
						// Pointless but pretty rounded — match focus outline with logo radius.
						className="inline-block shrink-0 rounded-[8px] sm:mr-8 headerRow:rounded-[10px]"
					>
						<JulaLogo className="max-[370px]:w-[80px] headerRow:w-[120px]" />
					</Link>

					{!hideSearchBar && (
						<ErrorBoundary className="grow self-center max-headerRow:hidden">
							<PageHeaderSearch
								id={HEADER_SEARCH_FIELD_ID}
								className="grow max-headerRow:hidden"
							/>
						</ErrorBoundary>
					)}

					<div
						className={cn(
							// Pull right edge to visually align icon.
							'-mr-3 ml-auto flex max-headerRow:-my-1 min-[390px]:gap-1 sm:gap-2',
							!hideSearchBar && 'headerRow:ml-5',
						)}
					>
						<IconButton
							ref={openSearchButtonRef}
							className={cn(
								'transition-fadeTransform max-[350px]:hidden headerRow:hidden',
								!isSearchButtonVisible && 'invisible opacity-0',
							)}
							icon="search"
							text={t('search_open_search_button')}
							onClick={handleOpenSearchClick}
						/>
						{hasAccountButton && (
							<IconButton
								onClick={handleAccountClick}
								aria-haspopup="dialog"
								icon="account"
								isLoading={hasVisualAccountLoading}
								text={
									isInitialisingUser
										? t('general_loading_text')
										: isAuthenticatedUser
											? t('account_account_heading')
											: t('account_login_button')
								}
								data-cy={getTestDataAttrFrom(
									isAuthenticatedUser
										? 'page-header-my-account-button'
										: 'page-header-login-button',
								)}
							/>
						)}
						{hasWishlistButton && (
							<IconButton
								icon="shoppinglist"
								href="/wishlist"
								text={
									is.positiveNumber(wishlistQuantity)
										? `${tPlural('page_header_wishlist_button', wishlistQuantity)}. ${t('wishlist_button_screenreader')}`
										: t('wishlist_button_screenreader')
								}
								badge={wishlistQuantity || undefined}
								data-cy={getTestDataAttrFrom('page-header-wishlist-button')}
							/>
						)}
						<IconButton
							icon="cart"
							href="/cart"
							text={
								is.positiveNumber(cartQuantity)
									? `${tPlural('page_header_cart_button', cartQuantity)}. ${t('cart_button_screenreader')}`
									: t('cart_button_screenreader')
							}
							badge={is.positiveNumber(cartQuantity) ? cartQuantity : undefined}
							data-cy={getTestDataAttrFrom('page-header-cart')}
						/>
						<IconButton
							ref={menuToggleRef}
							className="md:hidden"
							icon="menu"
							text={t('screenreader_text_mobile_menu_link')}
							onClick={onMenuToggleClick}
							onMouseDown={onMenuToggleMouseDown}
							aria-haspopup="dialog"
							aria-controls={HEADER_MAIN_NAVIGATION_ID}
							aria-expanded={isToggledMenuOpen}
						/>
					</div>
				</LayoutContainer>

				{/* Internal overlay to isolate search field when that's open, see globals.css. */}
				<div className="page-header-overlay" />
			</div>

			{!hideSearchBar && (
				<ErrorBoundary className="mx-4 mb-4 block headerRow:hidden">
					<PageHeaderSearch
						id={HEADER_SEARCH_FIELD_MOBILE_ID}
						// Sneaks along behind the actual sticky header to keep the scroll
						// position when focus moves to it via the 'open search' button.
						className="sticky top-0 z-belowStickyPageHeader mb-4 headerRow:hidden"
						onClose={handleSmallSearchClose}
					/>
				</ErrorBoundary>
			)}
			<GlobalStoreSelectorButton
				onClick={onStoreSelectorClick}
				className="mx-4 -mt-1 mb-2 md:hidden"
				dataCy="global-select-store-small"
			/>

			{/* Ugly use of presentational div, but doing it properly on existing
			    elements got really clunky. */}
			<div
				className={cn(
					'border-b border-b-greyLighter transition-border-color md:hidden',
					isContainerStuck && 'headerRow:border-b-transparent',
				)}
			/>
		</>
	);
}
PageHeaderMain.displayName = 'PageHeaderMain';
