import React, { Fragment, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useInterpret, useSelector } from '@xstate/react';
import { useRouter } from 'next/router';
import { waitFor } from 'xstate/lib/waitFor';

import ActionButton from 'components/ActionButton';
import Button from 'components/Button';
import { Checkbox, FieldOnChange, TextInput } from 'components/FinalForm';
import InfoBox from 'components/InfoBox';
import { AccountLayoutContainer } from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import Popover from 'components/Popover';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { useGlobalMachinesContext } from 'contexts';
import { isError } from 'errors';
import { useEffectOnce } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { JulaProReference, JulaProUpdatedReference } from 'models/api';
import { selectContactRole } from 'state-machines/authentication';
import {
	referenceManagementMachine,
	selectEditReferenceError,
	selectReference,
	selectRemoveReferenceButtonState,
	selectStateIsErrorGettingReference,
	selectStateIsErrorRemovingReference,
	selectStateIsLoadingReference,
	selectUpdateButtonState,
} from 'state-machines/referenceManagement';
import { getFieldErrors } from 'utils/formHelpers';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

const getParentPath = (path: string) => {
	const pathArray = path.split('/').filter(Boolean);
	const parentPathArray = pathArray.slice(0, -1);
	return `/${parentPathArray.join('/')}/`;
};

const getCodeFromUrl = () => {
	const { pathname } = globalThis.location;
	// Get as array and filter out nullish values
	const pathArray = pathname.split('/').filter(Boolean);
	const code = pathArray.slice(-1).pop();
	return decodeURIComponent(code || '');
};

type Props = JulaComponentProps;

export default function AccountJulaProReferenceDetails({ rendering }: Props) {
	const { t } = useI18n();
	const router = useRouter();
	const { userService } = useGlobalMachinesContext();

	const contactRole = useSelector(userService, selectContactRole);
	const showReferences = contactRole === 'Admin';

	const redirectToReferenceList = () => {
		const currentPath = globalThis.location.pathname;
		const parentPath = getParentPath(currentPath);
		router.push(parentPath);
	};

	const updateUrlWithUpdatedReferenceCode = (context) => {
		if (context?.selectedReference?.code) {
			const code = decodeURIComponent(getCodeFromUrl() || '');
			const currentPath = globalThis.location.pathname;
			const parentPath = getParentPath(currentPath);
			if (context.selectedReference.code !== code) {
				router.replace(`${parentPath}${context.selectedReference.code}/`);
			}
		}
	};

	const [removeReferencePopoverOpen, setRemoveReferencePopoverOpen] =
		useState<boolean>(false);

	const referenceManagementService = useInterpret(referenceManagementMachine, {
		devTools: true,
		actions: {
			redirectToReferenceList,
			updateUrlWithUpdatedReferenceCode,
		},
	});

	const isLoadingReference = useSelector(
		referenceManagementService,
		selectStateIsLoadingReference,
	);
	const errorGettingReference = useSelector(
		referenceManagementService,
		selectStateIsErrorGettingReference,
	);
	const errorRemovingReference = useSelector(
		referenceManagementService,
		selectStateIsErrorRemovingReference,
	);
	const updateButtonState = useSelector(
		referenceManagementService,
		selectUpdateButtonState,
	);
	const removeReferenceButtonState = useSelector(
		referenceManagementService,
		selectRemoveReferenceButtonState,
	);
	const editReferenceError = useSelector(
		referenceManagementService,
		selectEditReferenceError,
	);
	const reference = useSelector(referenceManagementService, selectReference);

	useEffectOnce(() => {
		const code = getCodeFromUrl();
		referenceManagementService.send({ type: 'GET_REFERENCE', code });
	});

	const submitEditForm = async (props) => {
		if (!reference) return;
		const updatedReference = {
			newReferenceName: props.newReferenceName,
		} as JulaProUpdatedReference;

		updatedReference.contactPermissions = reference.contacts?.map(
			(contact) => ({
				customerContactId: contact.customerContactId,
				canUseReference: props[`contact-${contact.customerContactId}`],
			}),
		);
		referenceManagementService.send({
			type: 'UPDATE_REFERENCE',
			code: reference.code,
			reference: updatedReference,
		});

		const state = await waitFor(
			referenceManagementService,
			(referenceMachineState) =>
				referenceMachineState.hasTag('editReferenceRequestEnded'),
			{
				timeout: 20_000,
			},
		);

		if (state?.context?.editReferenceError) {
			return getFieldErrors(state.context.editReferenceError);
		}
		return undefined;
	};

	const renderEditForm = (proReference: JulaProReference) => {
		const initialValues = { newReferenceName: proReference.code };
		proReference.contacts?.forEach((contact) => {
			initialValues[`contact-${contact.customerContactId}`] =
				contact.canUseReference;
		});

		return (
			<Form
				onSubmit={submitEditForm}
				initialValues={initialValues}
				render={({ form, handleSubmit }) => (
					<form
						onSubmit={handleSubmit}
						id="editJulaProReference"
						className="max-w-[25rem]"
					>
						<TextInput
							id="code"
							name="newReferenceName"
							label={t('julapro_references_edit_form_code_label')}
						/>
						{showReferences && (
							<fieldset className="mb-8 mt-4 space-y-4">
								<legend>{t('julapro_references_edit_form_users_label')}</legend>

								<Checkbox
									id="checkAll"
									name="checkAll"
									label={t('julapro_references_form_check_all_users')}
								/>
								<FieldOnChange<boolean> name="checkAll">
									{(value) => {
										if (!value) return;
										proReference.contacts?.forEach((contact) => {
											form.change(
												`contact-${contact.customerContactId}`,
												value,
											);
										});
									}}
								</FieldOnChange>
								{proReference.contacts?.map((contact) => (
									<Fragment key={contact.customerContactId}>
										<Checkbox
											key={contact.customerContactId}
											id={`contact-${contact.customerContactId}`}
											name={`contact-${contact.customerContactId}`}
											label={contact.contactName}
										/>
										<FieldOnChange<boolean>
											name={`contact-${contact.customerContactId}`}
										>
											{(value) => {
												if (!value) {
													form.change('checkAll', false);
												}
											}}
										</FieldOnChange>
									</Fragment>
								))}
							</fieldset>
						)}
						<FormSpy subscription={{ values: true }}>
							{(props) => (
								<ActionButton
									type="submit"
									size="large"
									displayWidth="full"
									disabled={
										showReferences &&
										!Object.values(props.values).includes(true)
									}
									variant="cta"
									customState={updateButtonState}
									className="m-0"
								>
									{t('julapro_references_edit_form_submit_button_text')}
								</ActionButton>
							)}
						</FormSpy>
					</form>
				)}
			/>
		);
	};

	return (
		<AccountLayoutContainer
			rendering={rendering}
			heading={
				reference ? (
					<div className="flex items-center">
						{reference.code}
						{isLoadingReference && (
							<LoadingSpinner size="small" className="ml-4" />
						)}
					</div>
				) : null
			}
		>
			{isLoadingReference && !reference && (
				<Skeleton>
					<SkeletonItem height="4.5rem" className="mb-6 w-2/3" />
				</Skeleton>
			)}
			{errorGettingReference && (
				<InfoBox
					icon="error"
					heading={t('julapro_references_error_loading_reference_heading')}
					variant="error"
					message={t('julapro_references_error_loading_reference_message')}
				/>
			)}
			{reference && (
				<>
					{isError(editReferenceError, 'ValidationError') &&
						is.arrayWithLength(editReferenceError.generalErrors) &&
						editReferenceError.generalErrors?.map((error) => (
							<InfoBox
								key={error.key}
								icon="error"
								variant="error"
								message={error.text}
								className="my-2"
							/>
						))}

					<div className="mb-10 mt-6">{renderEditForm(reference)}</div>

					{errorRemovingReference && (
						<InfoBox
							icon="error"
							variant="error"
							heading={t('julapro_references_error_removing_reference_heading')}
							message={t('julapro_references_error_removing_reference_message')}
							className="my-6"
						/>
					)}

					<div className="mb-10">
						<Text as="h2" className="mb-2">
							{t('julapro_references_reference_remove_reference_heading')}
						</Text>
						<Text as="p" className="mb-6">
							{t('julapro_references_reference_remove_reference_text')}
						</Text>
						<Button
							onClick={() => setRemoveReferencePopoverOpen(true)}
							aria-haspopup="dialog"
						>
							{t('julapro_references_reference_remove_reference_button')}
						</Button>
					</div>

					<Popover
						title={t('julapro_references_reference_remove_reference_heading')}
						isOpen={removeReferencePopoverOpen}
						onClose={() => setRemoveReferencePopoverOpen(false)}
					>
						<p className="mb-6">
							{t(
								'julapro_references_reference_remove_reference_confirmation_heading',
							)}
						</p>
						<ActionButton
							variant="cta"
							onClick={() => {
								referenceManagementService.send({
									type: 'REMOVE_REFERENCE',
									code: reference.code,
								});
							}}
							customState={removeReferenceButtonState}
							className="mb-2"
							displayWidth="full"
						>
							{t(
								'julapro_references_reference_remove_reference_submit_button_text',
							)}
						</ActionButton>

						<Button
							onClick={() => setRemoveReferencePopoverOpen(false)}
							displayWidth="full"
						>
							{t(
								'julapro_references_reference_remove_reference_abort_button_text',
							)}
						</Button>
					</Popover>
				</>
			)}
		</AccountLayoutContainer>
	);
}
AccountJulaProReferenceDetails.displayName = 'AccountJulaProReferenceDetails';
