import { MutableState, Mutator } from 'final-form';

import { isError } from 'errors';
import { FormFields } from 'models/sitecore';
import { is } from 'utils/helpers';

export function initSelectFields(fields: FormFields | undefined) {
	const selectFields = {};
	if (!fields) {
		return undefined;
	}
	const allSelects = fields?.filter((field) => field.type === 'SelectField');
	if (allSelects.length === 0) {
		return undefined;
	}
	allSelects.forEach((selectField) => {
		selectFields[selectField.name] = selectField.options[0]?.value;
	});
	return selectFields;
}
export const setFieldData: Mutator = function setfieldData<FormValues>(
	args: any[],
	state: MutableState<FormValues>,
) {
	const [name, data] = args;
	const field = state.fields[name];
	if (field) {
		field.data = { ...field.data, ...data };
	}
};

export function getFieldErrors(
	err: Error | undefined,
	fallback?: Record<string, string | string[]>,
) {
	const fb = fallback ?? { FORM_ERROR: 'businessLogicErrors' };
	return isError(err, 'ValidationError') && is.objectWithKeys(err.fieldErrors)
		? err.fieldErrors
		: fb;
}
