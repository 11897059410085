/**
 * Img
 */

import React, { type ImgHTMLAttributes, useState } from 'react';
import Image from 'next/image';

import { is } from 'utils/helpers';

type ImgAttrs = ImgHTMLAttributes<HTMLImageElement>;

interface BaseProps extends Omit<ImgAttrs, 'srcSet'> {
	/** Alt-text for image */
	alt?: string;

	/** Optional className */
	className?: string;

	/** Src for image */
	src?: string;

	/** Optional custom srcset, or null to disable srcset generation */
	srcSet?: string | null;

	/** Should an fallback image be displayed if the image fails to load? */
	useFallbackOnError?: boolean;
}

interface RegularImageProps extends BaseProps {
	height?: ImgAttrs['height'];
	jpgOptimized?: never;
	priority?: never;
	service?: never;
	width?: ImgAttrs['width'];
}

interface NextProps extends BaseProps {
	height: string | number;

	/** If the image should be requested as an optimized JPEG */
	jpgOptimized?: boolean;

	/** Make the image high priority and preload it, only use when the image is visible above the fold. */
	priority?: boolean;

	/** Use Next image component */
	service: 'nextjs';

	width: string | number;
}

type Props = RegularImageProps | NextProps;

/**
 * An image component that handles retina images and various settings.
 *
 * https://nextjs.org/docs/pages/api-reference/components/image
 */
export default function Img(props: Props) {
	const [imgError, setImgError] = useState(false);

	const {
		alt = '',
		className,
		height,
		jpgOptimized = true,
		loading,
		priority,
		service,
		src: baseSrc,
		srcSet: baseSrcSet,
		useFallbackOnError = false,
		width,
		...regularImageAttrs
	} = props;

	let src = baseSrc;
	let srcSet = baseSrcSet;

	if (!src) {
		return null;
	}

	if (srcSet !== null) {
		if (src === 'assets/images/placeholder-image.png') {
			srcSet = `/assets/images/placeholder-image.png, /assets/images/placeholder-image2x.png`;
			src = '/assets/images/placeholder-image.png';
		} else if (imgError) {
			srcSet = `/assets/images/placeholder-image.png, /assets/images/placeholder-image2x.png`;
		} else if (!srcSet) {
			const lastDot = src.lastIndexOf('.');
			const fileName = src.slice(0, lastDot);
			const ext = src.slice(lastDot + 1);

			if (fileName.includes('http') && ext !== 'svg') {
				srcSet = `${fileName}.${ext} 1x`;
			} else {
				srcSet =
					ext === 'svg'
						? undefined
						: `${fileName}.${ext} 1x, ${fileName}@2x.${ext} 2x`;
			}
		}
	}

	if (service === 'nextjs' && src.includes('http')) {
		return (
			<Image
				src={src}
				alt={alt}
				aria-hidden={alt === '' ? true : undefined}
				width={is.string(width) ? Number.parseInt(width, 10) : width}
				height={is.string(height) ? Number.parseInt(height, 10) : height}
				className={className}
				loader={(params) => {
					let { src: loaderSrc } = params;
					if (loaderSrc.includes('{imgproxy_params}')) {
						loaderSrc = loaderSrc.replace(
							'{imgproxy_params}',
							`w:${params.width}`,
						);
					}
					if (jpgOptimized) {
						const loaderUrl = new URL(loaderSrc);
						loaderUrl.pathname = `/preset:jpgoptimized${loaderUrl.pathname}`;
						loaderSrc = loaderUrl.href;
					}
					return loaderSrc;
				}}
				priority={priority}
				loading={loading}
				onError={() => {
					if (useFallbackOnError) {
						setImgError(true);
					}
				}}
			/>
		);
	}

	return (
		// eslint-disable-next-line @next/next/no-img-element
		<img
			src={imgError ? '/images/placeholder-image.png' : src}
			srcSet={srcSet || undefined}
			alt={alt}
			aria-hidden={alt === '' ? true : undefined}
			width={width}
			height={height}
			loading={loading ?? 'lazy'}
			className={className}
			onError={() => {
				if (useFallbackOnError) {
					setImgError(true);
				}
			}}
			{...regularImageAttrs}
		/>
	);
}
Img.displayName = 'Img';
