/**
 * Reviews
 */

import React, { useState } from 'react';

import Button from 'components/Button';
import Chip from 'components/Chip';
import { Select } from 'components/FormUi';
import Img from 'components/Img';
import Link from 'components/Link';
import LoadMoreList from 'components/LoadMoreList';
import PlainButton from 'components/PlainButton';
import ProductFit from 'components/ProductFit';
import Rating from 'components/Rating';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import type { ReviewImage, ReviewImageId } from 'hooks/product-details';
import type { QuestionSummary, Review as ReviewModel } from 'models/api';
import { formatNumber } from 'utils/format';
import { is, range } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import Review from './Review';
import ReviewsRatingFilter from './ReviewsRatingFilter';

interface Props {
	/** Container class names. */
	className?: string;
	/** Has more reviews */
	hasMoreReviews: boolean;
	/** Are the reviews loading */
	isLoading?: boolean;
	/** Is review machine state = loading */
	isLoadingMoreReviews: boolean;
	/** Callback for selecting a single review grade (1–5) to display */
	onGradeClick: (score: number) => void;
	/** Callback for resetting grade selection */
	onGradeResetClick: () => void;
	/** Callback for 'load more' button */
	onLoadMoreClick: () => void;
	/** Callback for clicking on a review image */
	onReviewImageClick: (id: ReviewImageId) => void;
	/** Callback for viewing all review photos */
	onShowTopScoreReviewImages?: () => void;
	/** Callback for changing the current sort option */
	onSortOptionChange: (value: string | undefined) => void;
	/** Callback for clicking on a top score review image */
	onTopScoreReviewImageClick: (id: ReviewImageId) => void;
	/** questionSummary */
	questionsSummary: QuestionSummary[] | undefined;
	/** List of ratings for each number of stars */
	ratingsPerGrade: number[];
	/** Written user review objects */
	reviewItems?: ReviewModel[];
	/** URL to review policy page. */
	reviewPolicyUrl?: string;
	/** Total rating score */
	score?: number;
	/** Currently selected sort option */
	sortOption: string | undefined;
	/** Filtered reviews with images */
	topScoreReviewImages?: ReviewImage[];
	/** Total number of reviews */
	totalReviews: number;
}

/** The reviews component in the product details component. */
export default function Reviews({
	className,
	hasMoreReviews,
	isLoading,
	isLoadingMoreReviews,
	onGradeClick,
	onGradeResetClick,
	onLoadMoreClick,
	onReviewImageClick,
	onShowTopScoreReviewImages,
	onSortOptionChange,
	onTopScoreReviewImageClick,
	questionsSummary,
	ratingsPerGrade,
	reviewItems,
	reviewPolicyUrl,
	score = 0,
	sortOption,
	topScoreReviewImages,
	totalReviews,
}: Props) {
	const { t, tPlural } = useI18n();
	const [selectedGradeIndex, setSelectedGradeIndex] = useState<number>(-1);

	return (
		<div className={className}>
			<Text as="p" className="mb-8">
				{t('reviews_review_policy_text')}{' '}
				{reviewPolicyUrl && (
					<Link href={reviewPolicyUrl} className="underline hover:no-underline">
						{t('reviews_review_policy_link_text')}
					</Link>
				)}
			</Text>
			<div className="mb-8 mt-2 flex flex-col">
				<div className="flex items-center">
					<Text as="span" styleAs="h2" className="mr-4">
						{formatNumber(score)}
					</Text>
					<Rating score={score} size="large" reviewCountVariant="hidden" />
				</div>
				<Text as="pSmall" className="mt-2">
					{t('product_details_based_on_reviews_text', {
						reviewCount: totalReviews,
					})}
				</Text>
			</div>

			<ReviewsRatingFilter
				className="mb-12 md:mb-14"
				onGradeClick={(grade) => {
					onGradeClick(grade);
					setSelectedGradeIndex(5 - grade);
				}}
				onGradeResetClick={() => {
					onGradeResetClick();
					setSelectedGradeIndex(-1);
				}}
				ratingsPerGrade={ratingsPerGrade}
				selectedGradeIndex={selectedGradeIndex}
				totalReviews={totalReviews}
			/>

			{is.arrayWithLength(topScoreReviewImages) && (
				<div className="mb-12 md:mb-14">
					<Text as="h4" text={t('reviews_images_heading')} />
					<div className="mt-4 flex flex-wrap gap-4">
						{topScoreReviewImages.slice(0, 3).map((reviewImage) => (
							<PlainButton
								key={reviewImage.image.icon}
								onClick={() => onTopScoreReviewImageClick(reviewImage.id)}
								aria-label={t('review_image_button')}
								aria-haspopup="dialog"
							>
								<Img
									src={reviewImage.image.icon}
									className="size-16"
									width={reviewImage.image.iconWidth}
									height={reviewImage.image.iconHeight}
								/>
							</PlainButton>
						))}
					</div>
					{onShowTopScoreReviewImages && (
						<Button
							className="mt-4"
							onClick={onShowTopScoreReviewImages}
							variant="text"
							aria-haspopup="dialog"
						>
							{tPlural(
								'reviews_show_all_images_button',
								topScoreReviewImages.length,
							)}
						</Button>
					)}
				</div>
			)}

			<ProductFit
				className="mb-12 md:mb-14"
				fit3Summary={questionsSummary?.find(({ name }) => name === 'fit3')}
			/>

			<Text as="h4" text={t('reviews_all_reviews_heading')} className="mb-4" />
			<div className="sm:flex">
				<Select
					className="sm:w-1/2"
					id="review-sort"
					label={t('reviews_sorting_label')}
					hiddenLabel
					value={sortOption ?? 'default'}
					onChange={(e) => {
						if (e.target.value === 'default') {
							onSortOptionChange(undefined);
						} else {
							onSortOptionChange(e.target.value);
						}
					}}
					options={[
						{
							value: 'default',
							label: t('reviews_sorting_option_default_label'),
						},
						{
							value: 'Relevance',
							label: t('reviews_sorting_option_relevance_label'),
						},
						{
							value: 'ScoreAsc',
							label: t('reviews_sorting_option_score_ascending_label'),
						},
						{
							value: 'ScoreDesc',
							label: t('reviews_sorting_option_score_descending_label'),
						},
						{
							value: 'DateAsc',
							label: t('reviews_sorting_option_date_ascending_label'),
						},
						{
							value: 'DateDesc',
							label: t('reviews_sorting_option_date_descending_label'),
						},
					]}
				/>
			</div>
			{selectedGradeIndex > -1 && (
				<Chip
					className="mt-6"
					color="red"
					isRemovable
					text={t('reviews_selected_grade_chip_text', {
						grade: 5 - selectedGradeIndex,
					})}
					onClick={() => {
						setSelectedGradeIndex(-1);
						onGradeResetClick();
					}}
				/>
			)}
			{isLoading && (
				<div className="mt-8 divide-y divide-greyDark">
					{range(2).map((index) => (
						<Skeleton key={index} className="py-8">
							{/* Flag and name */}
							<div className="mb-4 flex items-center gap-2">
								<SkeletonItem
									height="1.5rem"
									width="1.5rem"
									className="rounded-full"
								/>
								<SkeletonItem height="1rem" width="5rem" />
							</div>
							{/* date */}
							<SkeletonItem height="1rem" width="13rem" className="mt-1" />
							{/* Stars */}
							<SkeletonItem height="1.5rem" width="7.5rem" className="mt-4" />
							{/* Main text */}
							<SkeletonItem height="6rem" width="100%" className="my-4" />
							{/* Like/report buttons */}
							<SkeletonItem height="2rem" width="12rem" className="mt-6" />
						</Skeleton>
					))}
				</div>
			)}
			{!isLoading && (
				<LoadMoreList
					onLoadMoreClick={onLoadMoreClick}
					isLoading={isLoadingMoreReviews}
					buttonVariant="secondary"
					className="mt-8"
					buttonAlignment="center"
					buttonClassName="min-w-[50%] mb-6 mt-10"
					itemCountScreenReaderText={tPlural(
						'reviews_item_count_text',
						reviewItems?.length ?? 0,
					)}
					buttonText={t('product_details_review_load_more_button')}
					hasLoadMoreButton={hasMoreReviews}
				>
					{reviewItems?.map((review) => (
						<Review
							className="border-b border-b-greyDark py-8"
							key={`${review.author}-${review.date}-${review.score}`}
							onReviewImageClick={onReviewImageClick}
							review={review}
						/>
					))}
				</LoadMoreList>
			)}
			<p className="mb-2 mt-8 text-right">
				<a
					href="https://www.testfreaks.com"
					rel="nofollow"
					target="_blank"
					className="inline-flex items-center gap-x-1 text-grey hover:underline"
				>
					Powered by TestFreaks
					<Img
						src="/assets/images/icon-testfreaks.svg"
						width={18}
						height={14}
					/>
				</a>
			</p>
		</div>
	);
}
Reviews.displayName = 'Reviews';
