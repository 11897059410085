import React from 'react';

import Button from 'components/Button';
import Img from 'components/Img';
import InfoBox from 'components/InfoBox';

interface Props {
	creditApplicationApprovedText: string;
	creditApplicationCanceledText: string;
	creditApplicationFailedOrDeniedText: string;
	creditLimit: string | undefined;
	creditResult: 'Approved' | 'Denied' | 'Canceled' | 'Failed' | undefined;
	membershipCreatedButtonText: string;
	membershipCreatedHeading: string;
	membershipCreatedText: string;
	onAcceptMembershipAndContinue: () => void;
}

export default function CustomerCreationDoneView({
	creditApplicationApprovedText,
	creditApplicationCanceledText,
	creditApplicationFailedOrDeniedText,
	creditLimit,
	creditResult,
	membershipCreatedButtonText,
	membershipCreatedHeading,
	membershipCreatedText,
	onAcceptMembershipAndContinue,
}: Props) {
	const infoText =
		creditResult === 'Denied' || creditResult === 'Failed'
			? creditApplicationFailedOrDeniedText
			: creditResult === 'Canceled'
				? creditApplicationCanceledText
				: undefined;

	return (
		<>
			<Img
				src="/assets/images/graphic-congrats-julaclub.png"
				width={80}
				height={80}
				className="mx-auto mt-8 block size-20"
			/>
			<p className="mt-8 font-bold">{membershipCreatedHeading}</p>

			{creditResult === 'Approved' && creditLimit && (
				<p className="my-4">{creditApplicationApprovedText}</p>
			)}

			{infoText && <InfoBox icon="info" className="my-4" message={infoText} />}

			<p className="mt-4">{membershipCreatedText}</p>

			<Button
				variant="primary"
				displayWidth="full"
				className="mt-8"
				onClick={onAcceptMembershipAndContinue}
			>
				{membershipCreatedButtonText}
			</Button>
		</>
	);
}
CustomerCreationDoneView.displayName =
	'JulaClubSignupForm_CustomerCreationDoneView';
