import React, { type ReactNode } from 'react';

import Icon from 'components/Icon';
import Text, { type Tags } from 'components/Text';
import { cn, cnm } from 'utils/classNames';

export type TextSize = 'base' | 'small';
const textSizeMap: Record<TextSize, Tags> = {
	base: 'p',
	small: 'pSmall',
};

interface Props {
	className?: string;
	color: 'green' | 'red';
	text: ReactNode;
	textSize?: 'base' | 'small';
}

/** Status icon with text */
export default function Status({
	className,
	color,
	text,
	textSize = 'base',
}: Props) {
	return (
		<Text
			as="div"
			styleAs={textSizeMap[textSize]}
			className={cnm('inline-flex items-start align-top', className)}
		>
			<Icon
				size={16}
				name={color === 'green' ? 'check' : 'closeOutline'}
				color={color === 'green' ? 'white' : 'julaRed'}
				backgroundColor={color === 'green' ? 'cta' : 'white'}
				className={cn(
					'inline-block shrink-0',
					textSize === 'base' && 'mr-2 mt-0.75',
					textSize === 'small' && 'mr-1.5 mt-0.5',
				)}
			/>
			{text}
		</Text>
	);
}
Status.displayName = 'Status';
