import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';

import { LoginPanelType } from 'types';

interface GlobalMemberPopoversContextType {
	activeTab: LoginPanelType;
	closeLogin: () => void;
	closeMenu: () => void;
	closeSignUpJulaClub: () => void;
	closeSignUpJulaPro: () => void;
	closeUserInformationValidation: () => void;
	isLoginOpen: boolean;
	isMenuOpen: boolean;
	isSignUpJulaClubOpen: boolean;
	isSignUpJulaProOpen: boolean;
	isUserInformationValidationOpen: boolean;
	openJulaClubLogin: () => void;
	openJulaProLogin: () => void;
	openMenu: () => void;
	openSignUpJulaClub: () => void;
	openSignUpJulaPro: () => void;
	openUserInformationValidation: () => void;
	setActiveTab: (tab: LoginPanelType) => void;
}
const GlobalMemberPopoversContext =
	createContext<GlobalMemberPopoversContextType>(
		{} as GlobalMemberPopoversContextType,
	);
interface Props {
	children: React.ReactNode;
}
export function GlobalMemberPopoversContextProvider({ children }: Props) {
	const [activeTab, setActiveTab] = useState<LoginPanelType>('LoginJulaClub');
	const [isLoginOpen, setIsLoginOpen] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isSignUpJulaClubOpen, setIsSignUpJulaClubOpen] = useState(false);
	const [isSignUpJulaProOpen, setIsSignUpJulaProOpen] = useState(false);
	const [isUserInformationValidationOpen, setIsUserInformationValidationOpen] =
		useState(false);
	const openSignUpJulaClub = useCallback(() => {
		setIsSignUpJulaClubOpen(true);
	}, []);
	const closeSignUpJulaClub = useCallback(() => {
		setIsSignUpJulaClubOpen(false);
	}, []);
	const openSignUpJulaPro = useCallback(() => {
		setIsSignUpJulaProOpen(true);
	}, []);
	const closeSignUpJulaPro = useCallback(() => {
		setIsSignUpJulaProOpen(false);
	}, []);

	const openJulaClubLogin = useCallback(() => {
		setActiveTab('LoginJulaClub');
		setIsLoginOpen(true);
	}, []);
	const openJulaProLogin = useCallback(() => {
		setActiveTab('LoginJulaPro');
		setIsLoginOpen(true);
	}, []);
	const closeLogin = useCallback(() => {
		setIsLoginOpen(false);
	}, []);
	const openMenu = useCallback(() => {
		setIsMenuOpen(true);
	}, []);
	const closeMenu = useCallback(() => {
		setIsMenuOpen(false);
	}, []);
	const openUserInformationValidation = useCallback(() => {
		setIsUserInformationValidationOpen(true);
	}, []);
	const closeUserInformationValidation = useCallback(() => {
		setIsUserInformationValidationOpen(false);
	}, []);

	const value = useMemo(
		() => ({
			activeTab,
			closeLogin,
			openJulaClubLogin,
			openJulaProLogin,
			isLoginOpen,
			setActiveTab,
			openSignUpJulaClub,
			openSignUpJulaPro,
			closeSignUpJulaClub,
			closeSignUpJulaPro,
			isSignUpJulaClubOpen,
			isSignUpJulaProOpen,
			openMenu,
			closeMenu,
			isMenuOpen,
			isUserInformationValidationOpen,
			openUserInformationValidation,
			closeUserInformationValidation,
		}),
		[
			activeTab,
			closeLogin,
			openJulaClubLogin,
			openJulaProLogin,
			isLoginOpen,
			openSignUpJulaClub,
			openSignUpJulaPro,
			closeSignUpJulaClub,
			closeSignUpJulaPro,
			isSignUpJulaClubOpen,
			isSignUpJulaProOpen,
			openMenu,
			closeMenu,
			isMenuOpen,
			isUserInformationValidationOpen,
			openUserInformationValidation,
			closeUserInformationValidation,
		],
	);
	return (
		<GlobalMemberPopoversContext.Provider value={value}>
			{children}
		</GlobalMemberPopoversContext.Provider>
	);
}

GlobalMemberPopoversContextProvider.displayName =
	'GlobalMemberPopoversContextProvider';

export function useGlobalMemberPopoversContext() {
	return useContext(GlobalMemberPopoversContext);
}
