import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlockAccordion from 'components/BlockAccordion';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import type { JulaComponentProps } from 'lib/component-props';
import type { HTMLString } from 'types';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';

interface Item {
	content?: Field<HTMLString>;
	heading?: Field<string>;
}

type Props = JulaComponentProps & {
	fields?: {
		heading?: Field<string>;
		items?: Item[];
	};
};

export default function Accordion({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const items = filterTruthy(
		fields.items?.map((item) => ({
			content: item.content?.value,
			heading: item.heading?.value,
		})),
		'content',
		'heading',
	);

	if (items.length === 0) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="Accordion"
				description="Requires at least one item that has a heading and content"
			/>
		);
	}

	return (
		<BlockAccordion
			className={getEditorMargin(params)}
			items={items}
			heading={fields.heading}
			headingLevel={params?.heading}
		/>
	);
}
Accordion.displayName = 'Accordion_JSS';
