import { useCallback, useEffect, useState } from 'react';

import { empty } from 'utils/helpers';
import { Coordinate, getDistanceBetween } from 'utils/math';

/** returns items with distance from user position, sorted from nearest to farthest */
export function useRelativeDistance<
	T extends { latitude: number | string; longitude: number | string },
>({ items }: { items: T[] | undefined }) {
	const [userPosition, setUserPosition] = useState<Coordinate>();
	const [itemsWithDistance, setItemsWithDistance] = useState<
		(T & { distance: number })[] | undefined
	>();
	const [isLoading, setIsLoading] = useState(false);
	const [userPositionError, setUserPositionError] =
		useState<GeolocationPositionError>();
	const getUserPosition = useCallback(() => {
		setUserPositionError(undefined);
		setIsLoading(true);
		navigator.geolocation.getCurrentPosition(
			(pos) => {
				setIsLoading(false);
				setUserPosition({
					lat: pos.coords.latitude,
					long: pos.coords.longitude,
				});
			},
			(positionError) => {
				setIsLoading(false);
				setUserPositionError(positionError);
			},
		);
	}, []);

	const clearUserPosition = useCallback(() => {
		setUserPosition(undefined);
		setItemsWithDistance(undefined);
	}, []);

	useEffect(() => {
		if (userPosition) {
			setItemsWithDistance(
				items
					?.map((item) => ({
						...item,
						distance: getDistanceBetween(userPosition, {
							lat: Number(item.latitude),
							long: Number(item.longitude),
						}),
					}))
					.sort((a, b) => a.distance - b.distance),
			);
		}
	}, [items, userPosition]);

	return {
		error: userPositionError,
		isLoading,
		getUserPosition,
		clearUserPosition,
		userPosition,
		itemsWithDistance: itemsWithDistance ?? empty.array,
	};
}
