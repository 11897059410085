import { publicRuntimeConfig } from 'config';
import type {
	ChangeDeliveryMethod,
	GetCart,
	GetFaqSearch,
	GetNearbyStores,
	GetOrder,
	GetUsps,
	PostSearch,
	PutCart,
	VerifyRedirectPayment,
} from 'models/api';
import { mapValues } from 'utils/collection';
import { formatTemplate } from 'utils/format';
import { createUrl } from 'utils/url';

export const TEST_API_URL = 'https://apigw-test.se/v1/';
export const API_URL: string =
	(process.env.NODE_ENV === 'development'
		? process.env.NEXT_PUBLIC_LOCAL_API_URL
		: process.env.NODE_ENV === 'test'
			? TEST_API_URL
			: publicRuntimeConfig?.NEXT_PUBLIC_DIGITAL_PLATFORM_API_URL) ?? '/';

type Endpoint =
	| GetCart
	| GetOrder
	| GetFaqSearch
	| ChangeDeliveryMethod
	| VerifyRedirectPayment
	| GetNearbyStores
	| PostSearch
	| PutCart
	| GetUsps;

type Endpoints = { [T in Endpoint as T['name']]: T['pathMethod'] };

const endpoints: Endpoints = {
	GetCart: { path: 'Cart/{{cartId}}', method: 'GET' },
	GetOrder: { path: 'Customer/order', method: 'GET' },
	GetFaqSearch: { path: 'FaqSearch/{{searchString}}', method: 'GET' },
	ChangeDeliveryMethod: {
		method: 'PUT',
		path: 'Customer/{{customerId}}/order/{{orderId}}',
	},
	VerifyRedirectPayment: {
		method: 'POST',
		path: 'Cart/{{cartId}}/verifyredirectpayment',
	},
	GetNearbyStores: {
		method: 'GET',
		path: 'Stores/nearbyStores',
	},
	PostSearch: {
		method: 'POST',
		path: 'Search',
	},
	PutCart: {
		method: 'PUT',
		path: 'Cart/{{cartId}}',
	},
	GetUsps: {
		method: 'GET',
		path: 'variant/{{variantId}}/shippingusps',
	},
};

function buildUrl(
	path: string,
	pathParams: Record<string, string> | undefined,
	queryParams:
		| Record<string, string | number | (string | number)[] | undefined | null>
		| undefined,
) {
	return createUrl(
		API_URL +
			formatTemplate(
				path,
				mapValues(pathParams ?? {}, (v) => encodeURIComponent(v)),
			),
		queryParams ?? {},
	);
}

type ExtractUrlParams<T> = T extends { params: infer P }
	? Omit<P, 'body'>
	: never;
type EndpointWithParams = Extract<Endpoint, { params: unknown }>;

export function getEndpointUrl<T extends Endpoint['name']>(
	name: T,
	...params: T extends EndpointWithParams['name']
		? [ExtractUrlParams<Extract<Endpoint, { name: T }>>]
		: []
): string {
	const _params = params[0];
	const pathParams =
		_params && 'pathParams' in _params ? _params.pathParams : undefined;
	const queryParams =
		_params && 'queryParams' in _params ? _params.queryParams : undefined;
	return buildUrl(endpoints[name].path, pathParams, queryParams);
}
type ExtractParams<T> = T extends { params: infer P } ? P : never;

export function getEndpoint<T extends Endpoint['name']>(
	name: T,
	...params: T extends EndpointWithParams['name']
		? [ExtractParams<Extract<Endpoint, { name: T }>>]
		: []
): Request {
	const _params = params[0];
	const body =
		_params && 'body' in _params ? JSON.stringify(_params.body) : undefined;
	return new Request(getEndpointUrl(name, ...params), {
		method: endpoints[name].method,
		body,
	});
}
