import React, { useState } from 'react';

import ArrowLink from 'components/ArrowLink';
import { Range } from 'components/FormUi';
import InfoBox from 'components/InfoBox';
import Popover from 'components/Popover';
import { PriceText } from 'components/Price';
import RichText from 'components/RichText';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text, { Tags } from 'components/Text';
import { useCreditSimulation } from 'hooks';
import type { CreditSimulation as CreditSimulationModel } from 'models/api';
import { useI18n } from 'utils/i18n';

function PriceRow({
	displaySymbol,
	displayValue,
	priceStyle = 'p',
	title,
}: {
	displaySymbol?: string;
	displayValue: string | undefined;
	priceStyle?: Tags;
	title: string;
}) {
	return (
		<li className="flex min-h-[3rem] items-center justify-between px-4 py-1">
			<Text as="p">{title}</Text>
			<Text as="p" styleAs={priceStyle}>
				<PriceText price={displayValue} symbol={displaySymbol} />
			</Text>
		</li>
	);
}
PriceRow.displayName = 'PriceRow';

type CreditSimulationProps = CreditSimulationModel;

export function CreditSimulation({
	content,
	defaultMonth,
	example,
	heading,
	link,
	maxMonth,
	minMonth,
	monthInterval,
	months,
}: CreditSimulationProps) {
	const { t } = useI18n();
	const monthKeys = months?.map((month) => month.key);
	const [selectedMonth, setSelectedMonth] = useState(
		months?.find((month) => month.key === defaultMonth),
	);

	return (
		<>
			<p className="mb-2 font-bold">{heading}</p>
			{content && <RichText className="mb-8" html={content} checkMarkList />}

			<Text className="mb-2" as="p">
				{t('credit_simulation_possible_monthly_cost_text')}
			</Text>
			<div className="mt-4 border border-greyLight">
				<div className="p-4">
					<Range
						label={t('credit_simulation_number_of_months_text')}
						onChange={(e) => {
							const month = months?.find(
								(m) => m.key === Number.parseInt(e.target.value, 10),
							);
							if (month) {
								setSelectedMonth(month);
							}
						}}
						steps={monthKeys}
						max={maxMonth}
						min={minMonth}
						step={monthInterval}
						value={selectedMonth?.key}
						id="credit-simulation-months"
					/>
				</div>
				<ul className="divide-y divide-greyLight border-t border-t-greyLight">
					<PriceRow
						title={t('credit_simulation_monthly_cost_text')}
						displayValue={selectedMonth?.value.monthlyCost.displayValue}
						displaySymbol={selectedMonth?.value.monthlyCost.displaySymbol}
						priceStyle="h3"
					/>
					<PriceRow
						title={t('credit_simulation_startup_fee_text')}
						displayValue={selectedMonth?.value.startupFee.displayValue}
						displaySymbol={selectedMonth?.value.startupFee.displaySymbol}
					/>
					<PriceRow
						title={t('credit_simulation_invoice_fee_text')}
						displayValue={selectedMonth?.value.invoiceFee.displayValue}
						displaySymbol={selectedMonth?.value.invoiceFee.displaySymbol}
					/>
					<PriceRow
						title={t('credit_simulation_intrest_rate_text')}
						displayValue={selectedMonth?.value.effectiveInterestRate}
					/>
					<PriceRow
						title={t('credit_simulation_total_amount_text')}
						displayValue={selectedMonth?.value.totalAmount.displayValue}
						displaySymbol={selectedMonth?.value.totalAmount.displaySymbol}
					/>
				</ul>
			</div>
			{link && (
				<ArrowLink
					className="mt-6"
					target={link.target}
					href={link.href}
					text={link.text}
				/>
			)}
			<Text as="pSmall" className="mb-2 mt-4">
				{example}
			</Text>
		</>
	);
}
CreditSimulation.displayName = 'CreditSimulation';

interface CreditSimulationContentProps {
	price: number | undefined;
}
function CreditSimulationContent({ price }: CreditSimulationContentProps) {
	const { t } = useI18n();

	const { creditSimulationData, error, isLoading } = useCreditSimulation(
		price ?? 0,
	);

	return (
		<>
			{!error && !isLoading && creditSimulationData && (
				<CreditSimulation
					heading={creditSimulationData.heading}
					content={creditSimulationData.content}
					example={creditSimulationData.example}
					defaultMonth={creditSimulationData.defaultMonth}
					months={creditSimulationData.months}
					maxMonth={creditSimulationData.maxMonth}
					minMonth={creditSimulationData.minMonth}
					monthInterval={creditSimulationData.monthInterval}
					link={creditSimulationData.link}
				/>
			)}
			{!error && isLoading && (
				<Skeleton>
					<SkeletonItem height="5rem" className="mt-4" />
					<SkeletonItem height="10rem" className="mt-4" />
					<SkeletonItem height="20rem" className="mt-4" />
					<SkeletonItem height="3rem" className="mt-4" />
					<SkeletonItem height="6rem" className="mt-4" />
				</Skeleton>
			)}
			{error && (
				<InfoBox
					icon="error"
					variant="error"
					message={t('general_critical_error_text')}
				/>
			)}
		</>
	);
}
CreditSimulationContent.displayName = 'CreditSimulationContent';

interface CreditSimulationPopoverProps {
	isOpen: boolean;
	onClose: () => void;
	price: number | undefined;
}
export default function CreditSimulationPopover({
	isOpen,
	onClose,
	price,
}: CreditSimulationPopoverProps) {
	const { t } = useI18n();

	return (
		<Popover
			title={t('credit_simulation_popver_heading')}
			isOpen={isOpen}
			onClose={onClose}
		>
			{isOpen && <CreditSimulationContent price={price} />}
		</Popover>
	);
}
CreditSimulationPopover.displayName = 'CreditSimulationPopover';
