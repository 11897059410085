import React, {
	type AnchorHTMLAttributes,
	type KeyboardEvent,
	type KeyboardEventHandler,
	type MouseEventHandler,
} from 'react';

import ButtonLink from 'components/ButtonLink';
import Icon from 'components/Icon';
import type { MainLinkFields } from 'models/sitecore';
import { cn } from 'utils/classNames';
import { is } from 'utils/helpers';

export type DropdownLinkClickHandler = (linkId: string) => void;

export type LinkListItemTabHandler = (
	linkId: string,
	level: number,
	event: KeyboardEvent<HTMLAnchorElement>,
) => void;

interface Props {
	baseId: string;
	isSelected?: boolean;
	level: number;
	link: MainLinkFields;
	linkBarColor?: string;
	onClick?: DropdownLinkClickHandler;
	onTab?: LinkListItemTabHandler;
}

/** A single link in the dropdown tree */
const PageHeaderLinkListItem = React.forwardRef<HTMLAnchorElement, Props>(
	(
		{ baseId, isSelected = false, level, link, linkBarColor, onClick, onTab },
		ref,
	) => {
		const handleClick: MouseEventHandler<HTMLAnchorElement> = () => {
			onClick?.(link.id);
		};

		const handleKeydown: KeyboardEventHandler<HTMLAnchorElement> = (e) => {
			if (e.key === 'Tab') {
				onTab?.(link.id, level, e);
			}
		};

		let linkProps: AnchorHTMLAttributes<HTMLAnchorElement> = {};
		if (onClick) {
			linkProps = {
				'onClick': handleClick,
				'aria-controls': `${baseId}-${level + 1}`,
				'aria-expanded': isSelected,
			};
		}
		if (onTab) {
			linkProps.onKeyDown = handleKeydown;
		}

		const isTopLevel = level === 1;
		const isNestedLevel = level === 2 || level === 3;

		return (
			<ButtonLink
				ref={ref}
				href={link.href}
				className={cn(
					'flex items-center py-3 pl-8 pr-4 text-base hover:underline md:px-6',
					isTopLevel &&
						'font-bold md:py-3 md:text-lg md:hover:bg-white md:hover:no-underline forced-colors:hover:underline',
					isTopLevel && isSelected && 'bg-white forced-colors:underline',
					isNestedLevel && 'md:py-2',
					isNestedLevel && isSelected && 'font-bold',
				)}
				{...linkProps}
			>
				{(linkBarColor || link.color) && (
					<div
						className={cn(
							'mr-4 min-h-[24px] w-1 shrink-0 self-stretch rounded-sm forced-color-adjust-none',
							isTopLevel && 'md:min-h-[32px]',
						)}
						style={{ backgroundColor: linkBarColor || link.color }}
					/>
				)}
				{link.text}
				{Boolean(is.arrayWithLength(link.links) && onClick) && (
					<Icon className="ml-auto shrink-0" direction="right" name="arrow" />
				)}
			</ButtonLink>
		);
	},
);
PageHeaderLinkListItem.displayName = 'LinkItem';

export default PageHeaderLinkListItem;
