import React from 'react';

import Img from 'components/Img';
import PlainButton from 'components/PlainButton';
import Text from 'components/Text';
import type { Placement } from 'models/api';
import { cn } from 'utils/classNames';

export type SelectablePlacement = Placement & {
	isSelected: boolean;
};

interface Props {
	onPlacementClick: (placement: Placement) => void;
	placementDisabledText: string | undefined;
	placements: SelectablePlacement[] | undefined;
}

export default function PlacementView({
	onPlacementClick,
	placementDisabledText,
	placements,
}: Props) {
	return (
		<ul className="grid grid-cols-4 gap-2">
			{placements?.map((placement) => (
				<li key={placement.id} className="col-span-2">
					<PlainButton
						onClick={() => onPlacementClick(placement)}
						disabled={placement.isSelected}
						className={cn(
							'relative flex w-full flex-col items-center rounded-border border border-greyDark p-4',
							placement.isSelected && 'cursor-not-allowed',
							!placement.isSelected && 'hover:bg-greyLight',
						)}
					>
						<Img
							src={
								placement.asset?.location ||
								'/assets/images/placeholder-image.png'
							}
							service="nextjs"
							useFallbackOnError
							width={128}
							height={128}
							className="size-32"
						/>
						<Text as="p" className="mt-4 font-bold" text={placement.title} />
						{placement.isSelected && (
							<div className="absolute inset-0 bg-white bg-opacity-50 p-2">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth="0.1"
									stroke="CurrentColor"
									aria-hidden="true"
									className="h-full w-full"
								>
									<path d="M 0 0 L24 24 M24 0 L0 24" />
								</svg>
								<Text
									as="span"
									styleAs="pSmall"
									text={placementDisabledText}
									className="absolute inset-x-4 top-1/2 -translate-y-1/2 bg-white"
								/>
							</div>
						)}
					</PlainButton>
				</li>
			))}
		</ul>
	);
}

PlacementView.displayName = 'ProductPrintPopover_PlacementView';
