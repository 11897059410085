import { stringifyUrl } from 'query-string';

import { publicRuntimeConfig } from 'config';
import { isTestmode } from 'hooks';
import {
	ConnectCustomerType,
	GuiType,
	LoginMethod,
	OauthQueryParams,
} from 'models/connect/oauth';
import { State } from 'pages/api/signin/oidc';
import {
	AuthCustomerType,
	getAccountReturnPath,
	OauthParams,
	shouldUseGuiFull,
} from 'state-machines/authentication';

export const julaConnectBaseConfig = {
	client_id:
		publicRuntimeConfig?.NEXT_PUBLIC_JULA_CONNECT_CLIENT_ID || 'NextJS',
	scope: 'openid offline_access digitalplatform.basic',
};

const getJulaConnectIframeBaseConfig = ({
	customerType,
	loginMethod,
}: {
	customerType: AuthCustomerType;
	loginMethod: LoginMethod;
}) => {
	const guiType: GuiType = shouldUseGuiFull(loginMethod)
		? 'gui_full'
		: 'gui_iframe';

	const state: State = {
		guiType: shouldUseGuiFull(loginMethod) ? 'gui_full' : 'gui_iframe',
		redirectPath: globalThis.location.pathname,
		customerType,
		accountReturnPath: getAccountReturnPath(),
	};

	return {
		redirect_uri: `${globalThis.location.origin}/api/signin/oidc/`,
		response_type: 'code id_token',
		response_mode: 'form_post',
		nonce:
			'637568344348596225.NWExMzI0M2EtOWYyYi00ZTUyLWFmYTUtZmU2YmU2YWNmNGI1ODVkYzRjMTgtNWZiMC00MTcyLWJjZTctMmI3YmQyMzc4MjFm',
		platform: 'web',
		language: publicRuntimeConfig?.NEXT_PUBLIC_JULA_MARKET_LANGUAGE,
		market: publicRuntimeConfig?.NEXT_PUBLIC_JULA_MARKET_CODE,
		preferred_type: loginMethod,
		test_mode: isTestmode(),
		gui_type: guiType,
		state: btoa(JSON.stringify(state)),
		iframeType: guiType,
	};
};

export const getJulaConnectIframeConfig = ({
	customerType,
	loginMethod,
}: {
	customerType: AuthCustomerType;
	loginMethod: LoginMethod;
}) => ({
	...julaConnectBaseConfig,
	...getJulaConnectIframeBaseConfig({ loginMethod, customerType }),
});

export const getUnregisteredTokenOauthParams = ({
	customerType,
	loginMethod,
}: {
	customerType: AuthCustomerType;
	loginMethod: LoginMethod;
}): OauthParams => {
	const params: OauthQueryParams = {
		...getJulaConnectIframeConfig({ loginMethod, customerType }),
		customer_type: 'Unregistered',
		scope: 'customer.identification-number openid profile',
	};
	const oauthUrl =
		publicRuntimeConfig?.NEXT_PUBLIC_JULA_CONNECT_OAUTH_URL ||
		'https://test.jula.com/identity';
	const oauthPath = '/connect/authorize';
	return {
		oauthUrl,
		oauthPath,
		...params,
		iframeSrc: stringifyUrl({
			url: `${oauthUrl}${oauthPath}`,
			query: { ...params },
		}),
	};
};
export const getRegisteredTokenOauthParams = ({
	customerNumber,
	customerType,
	loginMethod,
}: {
	customerNumber?: string;
	customerType: Exclude<ConnectCustomerType, 'Unregistered'>;
	loginMethod: LoginMethod;
}): OauthParams => {
	const oauthUrl =
		publicRuntimeConfig?.NEXT_PUBLIC_JULA_CONNECT_OAUTH_URL ||
		'https://test.jula.com/identity';
	const oauthPath = '/connect/authorize';
	const params: OauthQueryParams = {
		...getJulaConnectIframeConfig({ loginMethod, customerType }),
		customer_type: customerType,
		login_hint: customerNumber,
	};

	return {
		oauthUrl,
		oauthPath,
		...params,
		iframeSrc: stringifyUrl({
			url: `${oauthUrl}${oauthPath}`,
			query: { ...params },
		}),
	};
};
