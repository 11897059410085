import React from 'react';

import type {
	DiscountCode,
	GiftCard,
	Summary,
	TotalSummary,
	Variant,
} from 'models/api';

import PurchaseSummary from './PurchaseSummary';

interface Props {
	bonusDiscounts: DiscountCode[] | undefined;
	giftCards: GiftCard[] | undefined;
	heading?: string;
	savingTotalSumSummary: Summary | undefined;
	totalSummary: TotalSummary | undefined;
	variants: Variant[] | undefined;
}

/** Product and price summaries used on the checkout page. */
export default function CheckoutSummary({
	bonusDiscounts,
	giftCards,
	heading,
	savingTotalSumSummary,
	totalSummary,
	variants,
}: Props) {
	return (
		<PurchaseSummary
			className="md:forced-colors-outline max-md:mt-20 md:sticky md:top-8 md:rounded-lg md:p-6 md:shadow-summary"
			heading={heading}
			hasCartEditLink
			giftCards={giftCards}
			bonusDiscounts={bonusDiscounts}
			savingTotalSumSummary={savingTotalSumSummary}
			totalSummary={totalSummary}
			variants={variants}
		/>
	);
}
CheckoutSummary.displayName = 'CheckoutSummary';
