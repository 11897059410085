import { extendTailwindMerge, validators } from 'tailwind-merge';

type ClassValue =
	| ClassArray
	| string
	| number
	| bigint
	| boolean
	| null
	| undefined;
type ClassArray = ClassValue[];

function toValue(val: ClassValue): string {
	let i: number;
	let part: string;
	let result = '';

	if (typeof val === 'string') {
		result += val;
	} else if (Array.isArray(val)) {
		const len = val.length;
		for (i = 0; i < len; i++) {
			if (val[i]) {
				part = toValue(val[i]);
				if (part) {
					if (result) {
						result += ' ';
					}
					result += part;
				}
			}
		}
	}

	return result;
}

/**
 * Construct a `className` string from any number of string or arrays.
 *
 * A slightly smaller version of https://github.com/lukeed/clsx
 *
 * @example
 *
 * const variant = 'success';
 * const isDisabled = false;
 * cn(
 *   'flex',
 *   direction === 'vertical' && 'flex-col',
 *   variant === 'success' && [
 *     'bg-green',
 *     !isDisabled && 'shadow-green',
 *     isDisabled && 'border-greenLight',
 *   ],
 * );
 * // => 'flex bg-green shadow-green'
 */
export function cn(...args: ClassValue[]): string | undefined {
	let i = 0;
	let part: string;
	let result = '';
	const len = args.length;
	for (; i < len; i++) {
		if (args[i]) {
			part = toValue(args[i]);
			if (part) {
				if (result) {
					result += ' ';
				}
				result += part;
			}
		}
	}
	return result || undefined;
}

const { isArbitraryValue, isInteger } = validators;

function isName(value: unknown) {
	return typeof value === 'string' && value.length > 0;
}

const customTwMerge = extendTailwindMerge({
	extend: {
		classGroups: {
			// Add isName validator to recognize custom z-index values
			// that aren't numbers.
			z: [{ z: ['auto', isInteger, isArbitraryValue, isName] }],
		},
	},
});

/**
 * Construct a `className` string from any number of string or arrays,
 * resolving conflicting Tailwind classes.
 *
 * @example
 *
 * const isLarge = true;
 * cnm('block h-4', isLarge && 'h-6')
 * // => 'block h-6'
 */
export function cnm(...args: ClassValue[]): string | undefined {
	return customTwMerge(cn(...args)) || undefined;
}
