/**
 * BlockAccordion
 */

import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import Accordion from 'components/Accordion';
import { LayoutContainer } from 'components/Layout';
import RichText from 'components/RichText';
import Text from 'components/Text';
import type { HeadingLevel } from 'lib/component-props';

interface Item {
	content: string;
	heading: string;
}

interface Props {
	className?: string;
	heading?: Field<string>;
	headingLevel?: HeadingLevel;
	items: Item[];
}

/** Accordions with an optional heading. */
export default function BlockAccordion({
	className,
	heading,
	headingLevel = 'h2',
	items,
}: Props) {
	return (
		<LayoutContainer outerClassName={className} withPopoverLayout>
			<div className="relative max-w-col-6">
				<Text as={headingLevel} field={heading} className="mb-4 md:mb-6" />
				{items.map((item) => (
					<Accordion key={item.heading} title={item.heading} size="medium">
						<RichText
							html={item.content}
							inheritStyle
							className="py-1 md:py-3"
						/>
					</Accordion>
				))}
			</div>
		</LayoutContainer>
	);
}
BlockAccordion.displayName = 'BlockAccordion';
