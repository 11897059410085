import React, { useRef } from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';

import BlogDetails from 'components/BlogDetails';
import Chip from 'components/Chip';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import LoadMoreList from 'components/LoadMoreList';
import Meter from 'components/Meter';
import Text from 'components/Text';
import { useIsEditing, usePagination } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import {
	OffsetQueryVar,
	PageSizeQueryVar,
	Tag,
	TagQueryVar,
} from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { scrollTo } from 'utils/dom';
import { asArray, empty, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

const OFFSET_QUERY_VAR: OffsetQueryVar = 'bo-offset';
const PAGE_SIZE_QUERY_VAR: PageSizeQueryVar = 'bo-pageSize';
const TAG_QUERY_VAR: TagQueryVar = 'bo-tag';

interface BlogItemType {
	displayName: string;
	fields: {
		content: Field<string>;
		date: Field<string>;
		heading: Field<string>;
		images: {
			value: { assets: DigizuiteAsset[][] };
		};
		landscapeImage: Field<DigizuiteAsset[]>;
		portraitImage: Field<DigizuiteAsset[]>;
		squareImage: Field<DigizuiteAsset[]>;
		tags: Tag[];
	};
	id: string;
	name: string;
	url: string;
}

interface Fields {
	description: Field<string>;
	hasNextPage: boolean;
	heading: Field<string>;
	items: BlogItemType[];
	nextPageOffset: number;
	pageSize: Field<number>;
	tags: Tag[];
	total: number;
}

type Props = JulaComponentProps & {
	fields: Fields;
};

export default function BlogOverview({ fields: initialFields, params }: Props) {
	const { t, tPlural } = useI18n();
	const isEditing = useIsEditing();
	const router = useRouter();

	const urlTags = asArray(router.query[TAG_QUERY_VAR]).filter(is.truthy);
	const path = router.asPath.split('?')[0];
	const filtersRef = useRef<HTMLUListElement>(null);
	const {
		component: blogOverviewFields,
		isLoading: isLoadingMoreItems,
		items,
		loadMore: loadMoreItems,
		queryVarItems,
		updateQueryVars,
	} = usePagination<BlogItemType, Fields>({
		initialComponent: initialFields,
		initialItems: initialFields.items,
		initialNextPageOffset: initialFields.nextPageOffset,
		initialQueryVars: urlTags.map((tag) => [TAG_QUERY_VAR, tag]),
		itemsKey: 'items',
		offsetQueryVarName: OFFSET_QUERY_VAR,
		pageSizeQueryVarName: PAGE_SIZE_QUERY_VAR,
		placeholderComponentName: 'BlogOverview',
	});

	const visibleItemsCount = items.length;
	const totalItemsCount = blogOverviewFields?.total ?? 0;
	const tags = blogOverviewFields?.tags ?? empty.array;
	const activeTagIds = queryVarItems
		.filter(([key]) => key === TAG_QUERY_VAR)
		.map(([, val]) => val);
	if (isEditing && visibleItemsCount === 0 && !initialFields.heading?.value) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="BlogOverview"
				description="Requires a heading"
			/>
		);
	}
	const toggleTag = (tagId: string) => {
		updateQueryVars((vars) => {
			if (vars.has(TAG_QUERY_VAR, tagId)) {
				vars.delete(TAG_QUERY_VAR, tagId);
			} else {
				vars.add([TAG_QUERY_VAR, tagId]);
			}
		});
	};
	const isTagActive = (tagId: string) => {
		if (!tagId && activeTagIds.length === 0) {
			return true;
		}
		return activeTagIds.includes(tagId);
	};
	const clearAllTags = () => {
		updateQueryVars((vars) => {
			vars.delete(TAG_QUERY_VAR);
		});
	};

	const filterTags: Tag[] = [
		{ id: '', name: t('inspiration_filter_all_chip_text') },
		...tags,
	];

	return (
		<LayoutContainer
			withGrid
			id={params?.anchor}
			className={getEditorMargin(params)}
		>
			<div className="col-span-4 flex flex-col md:col-span-6 md:col-start-4">
				<Text as={params?.heading || 'h1'} field={initialFields.heading} />
				<Text as="pLarge" field={initialFields.description} />
				<ul className="mt-8 flex flex-wrap gap-4" ref={filtersRef}>
					{filterTags.map((tag) => (
						<li key={`${tag.name}-${tag.id}`}>
							<Chip
								color={isTagActive(tag.id) ? 'red' : 'white'}
								aria-pressed={isTagActive(tag.id)}
								text={tag.name}
								onClick={() => {
									if (tag.id) {
										toggleTag(tag.id);
									} else {
										clearAllTags();
									}
								}}
							/>
						</li>
					))}
				</ul>
				<LoadMoreList
					isLoading={isLoadingMoreItems}
					onLoadMoreClick={loadMoreItems}
					hasLoadMoreButton={blogOverviewFields?.hasNextPage}
					buttonAlignment="center"
					buttonClassName="mt-4 max-sm:w-full sm:min-w-72"
					itemCountScreenReaderText={tPlural(
						'blog_item_count_text',
						visibleItemsCount,
					)}
					buttonText={t('load_more_inspiration_items_button')}
					listTag="ul"
					listClassName="mt-8 divide-y divide-grey border-y border-grey"
					afterListContent={
						<Meter
							alignment="center"
							className="mt-14"
							current={visibleItemsCount}
							max={totalItemsCount}
							labelHasProgress
							label={t('inspiration_overview_page_indicator_text', {
								numShown: visibleItemsCount,
								numTotal: totalItemsCount,
							})}
						/>
					}
				>
					{items.map((item) => (
						<li key={item.id}>
							<BlogDetails
								className="py-8"
								content={item.fields.content.value}
								heading={item.fields.heading.value}
								images={item.fields.images.value.assets}
								date={item.fields.date?.value}
								url={item.url}
								tags={item.fields.tags}
								blogRootUrl={path}
								onTagClick={(tagId) => {
									updateQueryVars((vars) => {
										vars.set(TAG_QUERY_VAR, tagId);
									});
									if (filtersRef.current) {
										scrollTo(filtersRef.current);
									}
								}}
							/>
						</li>
					))}
				</LoadMoreList>
			</div>
		</LayoutContainer>
	);
}
BlogOverview.displayName = 'BlogOverview';
