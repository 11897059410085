import type { Wishlist } from 'models/api';
import { filterTruthy } from 'utils/collection';

export function mapWishlistToIdQuantity(wishlist: Wishlist) {
	// TODO filterTruthy returns a weird type, something to look into
	return filterTruthy(
		wishlist.categories.flatMap((category) =>
			category.variants.map((variant) => ({
				variantId: variant.id,
				quantity: variant.qty,
			})),
		),
		'variantId',
		'quantity',
	);
}

export function formatWishListDataForGTM(wishlist: Wishlist) {
	return filterTruthy(
		wishlist.categories.flatMap((category) =>
			category.variants.map((variant) => ({
				product: variant,
				quantity: variant.qty,
			})),
		),
		'product',
		'quantity',
	);
}
