import { useCallback } from 'react';
import { useRouter } from 'next/router';
import useSWR, { useSWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';

import { getEndpointUrl } from 'api';
import { useSelectedStore } from 'contexts';
import type {
	Cart,
	CreditSimulation,
	FaqSearchItem,
	ItemStockResponse,
	ProductQuestions,
	ProductResponse,
	RelatedProducts,
	RelationType,
	ResursBankPaymentSignage,
	ReviewResponse,
	ShippingUspsResponse,
	SimilarProducts,
	Store,
	Wishlist,
} from 'models/api';
import type { ProductId, VariantId } from 'models/product';
import type { ProductCard } from 'models/productCard';
import { API_URL, fetchData } from 'utils/fetch';
import { formatDateAndTime } from 'utils/format';
import { empty } from 'utils/helpers';
import { useI18n } from 'utils/i18n';
import { createUrl, getLastPathSegment } from 'utils/url';

import { usePagedData } from './internal';

export function usePopularProducts({
	category,
	isIdle,
	itemLimit,
}: { category?: string; isIdle?: boolean; itemLimit?: number } = {}) {
	const params = {
		'page-size': itemLimit || 5,
		'categoryId': category,
	};
	const { data, error, isLoading } = useSWRImmutable(
		isIdle ? null : createUrl(`${API_URL}Search/popularproducts`, params),
		fetchData<ProductCard[]>,
	);

	return { products: data || empty.array, isLoading, error };
}

export function usePopularSearchTerms({
	isIdle,
	itemLimit,
}: { isIdle?: boolean; itemLimit?: number } = {}) {
	const { data, error, isLoading } = useSWRImmutable(
		isIdle ? null : `${API_URL}Search/popularterms`,
		fetchData<string[]>,
	);

	return {
		terms: data ? data.slice(0, itemLimit || 999) : empty.array,
		isLoading,
		error,
	};
}

export function useCreditSimulation(price: number) {
	const { data, error, isLoading } = useSWRImmutable(
		`${API_URL}Customer/CreditSimulation?price=${price}`,
		fetchData<CreditSimulation>,
	);

	return { creditSimulationData: data, isLoading, error };
}

export function useFaqSearch(
	query: string,
	{
		includeContent = false,
		limit = 0,
	}: { includeContent?: boolean; limit?: number } = {},
) {
	const { data, error, isLoading } = useSWRImmutable(
		query
			? createUrl(`${API_URL}FaqSearch/${encodeURIComponent(query)}`, {
					numberOfResults: limit || null,
					includeContent: includeContent ? 'true' : null,
				})
			: null,
		fetchData<FaqSearchItem[]>,
	);

	return { items: data || empty.array, isLoading, error };
}

export function useAllStores(isActive: boolean = true) {
	const { data, error, isLoading } = useSWRImmutable(
		isActive ? `${API_URL}Stores` : null,
		fetchData<Store[]>,
	);

	return { stores: data || empty.array, isLoading, error };
}

export function useNearbyStores(
	storeId: string | undefined,
	isActive: boolean = true,
) {
	const { data, error, isLoading } = useSWRImmutable(
		isActive && storeId
			? createUrl(`${API_URL}Stores/nearbyStores`, { storeId })
			: null,
		fetchData<Store[]>,
	);

	return { stores: data || empty.array, isLoading, error };
}

export function useSWRMutate(key: string) {
	const { mutate } = useSWRConfig();
	return useCallback(() => mutate(key), [key, mutate]);
}

export function useOrderInformation(cartId: string | undefined) {
	const { data, error, isLoading } = useSWRImmutable(
		cartId ? `${API_URL}Cart/${cartId}` : null,
		fetchData<Cart>,
	);

	return { orderInformation: data, isLoading, error };
}

export function useReviews(
	productId: string,
	reviewScore: number | undefined,
	reviewSorting: string | undefined,
) {
	return usePagedData<ReviewResponse, 'reviews'>({
		baseUrl: createUrl(`${API_URL}Reviews/${productId}`, {
			reviewScore,
			reviewSorting,
		}),
		itemsKey: 'reviews',
	});
}

export function useFilteredReviews({
	filter = 'HasImage',
	productId,
	reviewScore,
}: {
	filter: 'HasImage';
	productId: string;
	reviewScore?: 1 | 2 | 3 | 4 | 5 | undefined;
}) {
	const { data, error, isLoading } = useSWRImmutable(
		createUrl(`${API_URL}Reviews/${productId}`, {
			filter,
			pageSize: 100,
			reviewSorting: 'ScoreDesc',
			reviewScore,
		}),
		fetchData<ReviewResponse>,
	);
	return {
		reviewResponse: data,
		isLoading,
		error,
	};
}

export function useRelatedProducts({
	isActive,
	pageSize = 15,
	relationType,
	variantId,
}: {
	isActive: boolean;
	pageSize?: number;
	relationType: RelationType;
	variantId: string | undefined;
}) {
	return usePagedData<RelatedProducts, 'variants'>({
		baseUrl: createUrl(`${API_URL}Variant/${variantId}/relatedproducts`, {
			relationType,
		}),
		itemsKey: 'variants',
		isActive,
		pageSize,
	});
}

export function useSimilarProducts({
	isActive,
	pageSize = 15,
	productId,
}: {
	isActive: boolean;
	pageSize?: number;
	productId: ProductId;
}) {
	return usePagedData<SimilarProducts, 'products'>({
		baseUrl: `${API_URL}Product/${productId}/similar`,
		itemsKey: 'products',
		isActive,
		pageSize,
	});
}

export function useCampaignProducts(
	campaignId: string | undefined,
	isActive: boolean,
) {
	return usePagedData<RelatedProducts, 'variants'>({
		baseUrl: `${API_URL}Campaign/${campaignId}`,
		itemsKey: 'variants',
		isActive,
		pageSize: 15,
	});
}

export function useProductQuestions(productId: string) {
	return usePagedData<ProductQuestions, 'questions'>({
		baseUrl: `${API_URL}ProductQuestions/${productId}`,
		itemsKey: 'questions',
	});
}
export function useResursBankPriceSignage(
	paymentMethodIdProvider: string | undefined,
	amount: number | undefined,
	isActive: boolean,
) {
	return useSWRImmutable(
		isActive && paymentMethodIdProvider && amount
			? createUrl(`${API_URL}payment/resursbank/price-signage`, {
					paymentMethodIdProvider,
					amount,
				})
			: null,
		fetchData<ResursBankPaymentSignage>,
	);
}
export function useProductVariant(productId: string) {
	return useSWRImmutable(
		productId ? `${API_URL}Variant/${productId}` : null,
		fetchData<ProductResponse>,
	);
}

export function useChatAvailability(isActive: boolean) {
	const { data, error, isLoading } = useSWR(
		isActive ? `${API_URL}CustomerService/availability` : null,
		fetchData<{
			available: boolean;
			open: boolean;
			zendeskJwt: string | undefined;
		}>,
	);

	return {
		hasAvailableAgents: Boolean(data?.available),
		isOpen: Boolean(data?.open),
		zendeskJwt: data?.zendeskJwt,
		isLoading,
		error,
	};
}

export function useStock({
	isActive,
	keepPreviousData = false,
	storeIds,
	variantIds: variantIdsProp,
}: {
	isActive: boolean;
	keepPreviousData?: boolean;
	storeIds?: string | string[];
	variantIds: string | string[] | undefined;
}) {
	const { data, error, isLoading, isValidating } = useSWRImmutable(
		isActive
			? createUrl(`${API_URL}ProductStock`, {
					storeId: storeIds,
					variantIds: variantIdsProp,
				})
			: null,
		(key) => {
			// the key here is used to have a sane way to get params that need to be in the body
			// into the fetcher, therefore we need to rebuild the request url
			const url = new URL(key);
			const variantIds = url.searchParams.getAll('variantIds');
			url.searchParams.delete('variantIds');
			return fetchData<ItemStockResponse[]>(url.toString(), {
				method: 'POST',
				body: JSON.stringify(variantIds ?? []),
			});
		},
		{ keepPreviousData },
	);

	return { itemsStock: data, isLoading, error, isValidating };
}

export function useSharedWishlist(isActive: boolean = true) {
	const { t } = useI18n();
	const router = useRouter();
	const wishlistId = getLastPathSegment(router.asPath);

	const { selectedStore } = useSelectedStore();
	const { data, error, isLoading } = useSWRImmutable(
		isActive && wishlistId
			? createUrl(`${API_URL}Wishlist/${wishlistId}`, {
					storeId: selectedStore?.id,
				})
			: null,
		fetchData<Wishlist>,
	);

	const sharedText = data?.dateCreated
		? t('wishlist_shared_date_label', {
				date: formatDateAndTime(data.dateCreated),
			})
		: undefined;

	return { isLoading, error, wishlistData: data, sharedText };
}

export function useShippingUsps(variantId: VariantId) {
	const { selectedStore } = useSelectedStore();
	const { data, error, isLoading } = useSWRImmutable(
		variantId
			? getEndpointUrl('GetUsps', {
					pathParams: { variantId },
					queryParams: { storeId: selectedStore?.id },
				})
			: null,
		fetchData<ShippingUspsResponse>,
	);

	return { usps: data, isLoading, error };
}
