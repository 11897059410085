import React, {
	createContext,
	type ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { serialize } from 'cookie';

import { useUserContext } from './UserContextProvider';

interface PriceContextType {
	canTogglePriceVat: boolean;
	togglePriceExcVat?: () => void;
	usePriceExcVat: boolean;
}

const PriceContext = createContext<PriceContextType>({} as any);

interface Props {
	children: ReactNode;
	initialUsePriceExcVat: boolean;
}

export function PriceContextProvider({
	children,
	initialUsePriceExcVat,
}: Props) {
	const userInfo = useUserContext();
	const canTogglePriceVat = userInfo?.customerType === 'Pro';
	const [usePriceExcVat, setUsePriceExcVat] = useState(
		Boolean(canTogglePriceVat ? initialUsePriceExcVat : false),
	);

	useEffect(() => {
		document.cookie = serialize('usePriceExcVat', 'true', {
			path: '/',
			expires: usePriceExcVat
				? new Date(Date.now() + 1000 * 60 * 60 * 24 * 365)
				: new Date(0),
		});
	}, [usePriceExcVat]);

	const togglePriceExcVat = useCallback(() => {
		setUsePriceExcVat((prev) => !prev);
	}, []);

	const value = useMemo(
		() => ({
			usePriceExcVat,
			togglePriceExcVat: canTogglePriceVat ? togglePriceExcVat : undefined,
			canTogglePriceVat,
		}),
		[usePriceExcVat, canTogglePriceVat, togglePriceExcVat],
	);

	return (
		<PriceContext.Provider value={value}>{children}</PriceContext.Provider>
	);
}

PriceContextProvider.displayName = 'PriceContextProvider';

export function usePriceContext() {
	return useContext(PriceContext);
}
