import React from 'react';

import InfoBox from 'components/InfoBox';
import RichText from 'components/RichText';
import type { FormInfo as FormInfoType } from 'models/sitecore';
import { cn } from 'utils/classNames';
import { assertUnreachable } from 'utils/helpers';

import { useFieldDependencyStatus } from './helpers';

// TODO: hopefully the weird usage of info fields in JulaProIdentification can be removed
// but for now we export the 'mapper' function to be able to use it in the JulaProIdentification component
// as we cant have a generic form there, and having just a final-form form would be weird, ie, it's temporary
export function InfoField({
	formInfo,
	inputSpace,
}: {
	formInfo: FormInfoType;
	inputSpace: string | undefined;
}) {
	switch (formInfo.type) {
		case 'InfoField':
			return (
				<RichText
					key={formInfo.id}
					className={cn(inputSpace, formInfo.text.length > 200 && 'text-sm')}
					html={formInfo.text}
					tightSpacing
				/>
			);
		case 'HighlightField':
			return (
				<InfoBox
					key={formInfo.id}
					icon={formInfo?.icon?.value?.[0]?.src || 'info'}
					variant="information"
					className={inputSpace}
				>
					<RichText html={formInfo.text} className="text-sm" tightSpacing />
				</InfoBox>
			);
		default:
			return assertUnreachable(formInfo.type, 'formInfo.type');
	}
}

InfoField.displayName = 'GenericForm_FormInfo_InfoField';

interface Props {
	formInfo: FormInfoType;
	inputSpace?: string;
}

export default function FormInfo({ formInfo, inputSpace }: Props) {
	const { dependencyFieldHasValue } = useFieldDependencyStatus(
		formInfo.requires,
	);

	if (!dependencyFieldHasValue) {
		return null;
	}
	return <InfoField formInfo={formInfo} inputSpace={inputSpace} />;
}
FormInfo.displayName = 'GenericForm_FormInfo';
