/**
 * SearchField
 */

import React, { forwardRef, InputHTMLAttributes, MouseEvent } from 'react';

import Icon from 'components/Icon';
import PlainButton from 'components/PlainButton';
import { cn, cnm } from 'utils/classNames';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface BaseProps extends InputHTMLAttributes<HTMLInputElement> {
	className?: string;
	handleInputClearClick: (e: MouseEvent<HTMLButtonElement>) => void;
	hasInputClearButton?: boolean;
	hasSearchIcon?: boolean;
	id: string;
	inputClassName?: string;
	inputLabel: React.ReactNode;
	isLabelVisible?: boolean;
	isLoading?: boolean;
}

interface WithSubmitButtonProps extends BaseProps {
	hasSubmitButton?: true;
	submitButtonLabel: string;
}
interface WithoutSubmitButtonProps extends BaseProps {
	hasSubmitButton: false;
	submitButtonLabel?: never;
}

export type Props = WithSubmitButtonProps | WithoutSubmitButtonProps;

/** A search field with clear button, placeholder and button with icon */
const SearchField = forwardRef<HTMLInputElement, Props>(
	(
		{
			className,
			handleInputClearClick,
			hasInputClearButton,
			hasSearchIcon,
			hasSubmitButton = true,
			id,
			inputClassName,
			inputLabel,
			isLabelVisible = false,
			isLoading = false,
			submitButtonLabel,
			...inputAttrs
		},
		ref,
	) => {
		const { t } = useI18n();
		const hasClearButton = is.bool(hasInputClearButton)
			? hasInputClearButton
			: // Stringify to make zero count.
				Boolean(inputAttrs.value?.toString());

		return (
			<>
				<label className={cn(!isLabelVisible && 'sr-only')} htmlFor={id}>
					{inputLabel}
				</label>
				<div className={cnm('group/search-field flex w-full', className)}>
					<div className="relative w-full">
						{hasSearchIcon && (
							<Icon
								name="search"
								className="absolute left-4 top-1/2 -translate-y-1/2"
							/>
						)}
						<input
							{...inputAttrs}
							type="text"
							id={id}
							autoCapitalize="none"
							autoComplete="off"
							autoCorrect="off"
							enterKeyHint="search"
							inputMode="search"
							ref={ref}
							className={cn(
								'h-12 w-full px-4',
								'border border-greyDark group-hover/search-field:border-greyDarker',
								'focus:outline-offset-0 group-hover/search-field:outline group-hover/search-field:outline-1 group-hover/search-field:outline-greyDarker',
								'focus-outline-none',
								hasSubmitButton && 'rounded-r-none border-r-0',
								'rounded',
								// Prevent iOS from zooming on focus by having the text at 16px+.
								'text-[1rem]',
								'focus:outline-none',
								'placeholder:text-inherit',
								// Match right padding with clear button width below
								hasClearButton && 'pr-10',
								hasSearchIcon && 'pl-12',
								inputClassName,
							)}
						/>
						<PlainButton
							className={cn(
								// Match width with input right padding above
								'w-10',
								'absolute right-0 top-1/2 flex h-12 -translate-y-1/2 items-center justify-center border-none bg-none hover:opacity-80',
								!hasClearButton && 'hidden',
							)}
							onClick={handleInputClearClick}
						>
							<span className="sr-only">{t('screenreader_text_clear')}</span>
							<Icon name="clear" color="grey" />
						</PlainButton>
					</div>
					{hasSubmitButton && (
						<button
							type="submit"
							className={cn(
								'relative size-12 shrink-0 rounded-r bg-julaRed text-white hover:bg-julaRedDark',
								'forced-colors:border',
								'group-hover/search-field:border-y group-hover/search-field:border-r group-hover/search-field:border-greyDarker group-hover/search-field:outline group-hover/search-field:outline-1 group-hover/search-field:outline-greyDarker',
							)}
						>
							<span className="sr-only">
								{isLoading ? t('general_loading_text') : submitButtonLabel}
							</span>
							{isLoading && (
								<Icon name="spinner" className="animate-spinFast" />
							)}
							{!isLoading && <Icon name="search" />}
						</button>
					)}
				</div>
			</>
		);
	},
);

export default SearchField;

SearchField.displayName = 'SearchField';
