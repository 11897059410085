/**
 * PageFooter
 */

import React from 'react';

import type {
	Column,
	FooterLinkField,
	IconLinkField,
	ImageColumn,
} from 'models/sitecore';
import { useI18n } from 'utils/i18n';

import PageFooterImages from './PageFooterImages';
import PageFooterNav from './PageFooterNav';
import PageFooterSecondary from './PageFooterSecondary';

interface Props {
	/* Columns with small images, optionally linked. */
	imageColumns?: ImageColumn[];

	/** Information next to the logo. */
	infoText?: string;

	/* Columns of primary menu links. */
	menuColumns: Column[];

	/* Secondary links. */
	secondaryLinks: FooterLinkField[];

	/* Social media icons. */
	socialMediaLinks: IconLinkField[];
}

/** Site page footer */
export default function PageFooter({
	imageColumns,
	infoText,
	menuColumns,
	secondaryLinks,
	socialMediaLinks,
}: Props) {
	const { t } = useI18n();

	return (
		<footer className="global__footer-wrapper sticky top-[100vh] mt-14 bg-julaRed font-standard text-base text-white md:mt-20 forced-colors:border-t print:hidden">
			<h2 className="sr-only">{t('page_footer_screen_reader_heading')}</h2>
			<PageFooterNav columns={menuColumns} infoText={infoText} />
			<PageFooterImages columns={imageColumns} infoText={infoText} />
			<PageFooterSecondary
				links={secondaryLinks}
				socialMediaLinks={socialMediaLinks}
			/>
		</footer>
	);
}
PageFooter.displayName = 'PageFooter';
