import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ActionLink, { isValidPhoneNumberText } from 'components/ActionLink';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import type { JulaComponentProps } from 'lib/component-props';
import type { ActionLink as ActionLinkModel } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		action?: ActionLinkModel;
		isPrimary?: Field<boolean>;
	};
};

export default function ActionButton({ fields, params }: Props) {
	const action = fields?.action;
	const containerMargin = params?.margin ? getEditorMargin(params) : 'mt-4';

	if (!action?.type || !action?.text) {
		return (
			<ComponentPlaceholder
				className={containerMargin}
				componentName="ActionButton"
				description="Requires an action type and text"
			/>
		);
	}

	if (action.type === 'Phone' && !isValidPhoneNumberText(action.text)) {
		return (
			<ComponentPlaceholder
				className={containerMargin}
				componentName="Phone ActionButton"
				description="Text must contain a phone number of at least 5 digits"
			/>
		);
	}

	return (
		<LayoutContainer id={params?.anchor} outerClassName={containerMargin}>
			<ActionLink
				{...action}
				buttonVariant={fields?.isPrimary?.value ? 'primary' : undefined}
				className="min-w-64 max-sm:w-full"
			/>
		</LayoutContainer>
	);
}
ActionButton.displayName = 'ActionButton';
