import { API_URL, fetchData } from 'utils/fetch';
import { createUrl } from 'utils/url';

import type {
	JulaClubCustomerInformationUpdateRequestParams,
	JulaProContactInformationUpdateRequestParams,
} from './account.types';

export const requestJulaProAccountApiUpdateContactInformation = (
	params: JulaProContactInformationUpdateRequestParams,
) =>
	fetchData(
		createUrl(`${API_URL}Customer/julapro/contact/`, {
			...params,
			allowMarketingCommunication: params.allowMarketingCommunication
				? 'true'
				: 'false',
		}),
		{
			method: 'PUT',
		},
	);

export const requestJulaClubAccountApiUpdateCustomerInformation = (
	params: JulaClubCustomerInformationUpdateRequestParams,
) =>
	fetchData(`${API_URL}Customer/julaclub`, {
		method: 'PUT',
		body: JSON.stringify(params),
	});
