import React from 'react';
import { useSelector } from '@xstate/react';

import AuthJulaConnect from 'components/AuthJulaConnect';
import { InfoField } from 'components/GenericForm';
import { ProLogin } from 'components/Login';
import { useGlobalMachinesContext } from 'contexts';
import type { FormFields, FormInfo as FormInfoType } from 'models/sitecore';
import {
	selectErrorMessage,
	selectIsAwaitingAuthAction,
} from 'state-machines/authentication';
import { getLoginOptions } from 'utils/business-logic';

interface Props {
	authButtonText: string;
	heading: string;
	importantInformation?: FormFields;
	message: string;
}

export default function JulaProIdentification({
	authButtonText,
	heading,
	importantInformation,
	message,
}: Props) {
	const { authService } = useGlobalMachinesContext();

	const infoFields =
		importantInformation?.filter(
			(field): field is FormInfoType =>
				field.type === 'InfoField' || field.type === 'HighlightField',
		) ?? [];

	const isAwaitingAuthAction = useSelector(
		authService,
		selectIsAwaitingAuthAction,
	);

	const errorMessage = useSelector(authService, selectErrorMessage);

	return (
		<div className="flex h-full flex-col">
			{!isAwaitingAuthAction && <AuthJulaConnect className="grow" />}
			{isAwaitingAuthAction && (
				<ProLogin
					introHeading={heading}
					introText={message}
					errorMessage={errorMessage}
					importantInformation={
						<>
							{infoFields.map((infoField) => (
								<InfoField
									key={infoField.id}
									formInfo={infoField}
									inputSpace="mb-4"
								/>
							))}
						</>
					}
					onSelectLoginMethod={(loginMethod) => {
						authService.send({
							type: 'SELECT_AUTH_JULA_PRO_BECOME_MEMBER',
							loginMethod,
						});
					}}
					loginOptions={getLoginOptions('Pro').map((option) => ({
						...option,
						text: authButtonText,
					}))}
				/>
			)}
		</div>
	);
}
JulaProIdentification.displayName =
	'AccountJulaProSignupForm_JulaProIdentification';
