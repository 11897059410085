import React, { useMemo } from 'react';

import StorePopover from 'components/StorePopover';
import {
	type StoreIdName,
	useGlobalPopoversContext,
	useSelectedStore,
} from 'contexts';
import { useProductStock } from 'hooks';
import { filterTruthy } from 'utils/collection';
import { useI18n } from 'utils/i18n';

/** Popover for the global product card store selector */
export default function GlobalStockStoreSelectorPopover() {
	const { t } = useI18n();
	const { closeStoreSelector, isStoreSelectorOpen, storeSelectorProductId } =
		useGlobalPopoversContext();
	const { selectedStore, setSelectedStore } = useSelectedStore();

	const onStoreSelect = (store: StoreIdName) => {
		setSelectedStore(store);
		closeStoreSelector();
	};

	const { isLoadingProductStock, productStock } = useProductStock({
		productId: storeSelectorProductId,
	});

	const productStoresStock = productStock?.storeStock?.stocks;

	const flattenedStores = useMemo(
		() =>
			productStoresStock?.map((storeStock) => ({
				...storeStock.store,
				availableStockLevel: storeStock.availableStockLevel,
				inStock: storeStock.inStock,
			})),
		[productStoresStock],
	);

	if (selectedStore) {
		return null;
	}

	return (
		<StorePopover
			baseId="global-stock-store"
			title={t('store_stock_popover_select_store_title')}
			isLoading={isLoadingProductStock}
			isOpen={isStoreSelectorOpen}
			onClose={() => {
				closeStoreSelector();
			}}
			onStoreSelectClick={(store) => {
				onStoreSelect({ id: store.id, name: store.name });
			}}
			allStores={filterTruthy(flattenedStores, 'id', 'name')}
		/>
	);
}
GlobalStockStoreSelectorPopover.displayName = 'GlobalStoreSelectorPopover';
