import { assign, createMachine, type StateFrom } from 'xstate';

import type { ActionButtonState } from 'components/ActionButton';
import type { ErrorObject } from 'errors';

import {
	requestAddUser,
	requestGetUser,
	requestGetUsers,
	requestInviteUserToEcom,
	requestRemoveUser,
	requestRevokeUserFromEcom,
} from './userManagement.services';
import type {
	UserManagementMachineContext,
	UserManagementMachineEvents,
	UserManagementMachineServices,
} from './userManagement.types';

export const userManagementMachine = createMachine(
	{
		id: 'userManagementMachine',
		schema: {
			context: {} as UserManagementMachineContext,
			events: {} as UserManagementMachineEvents,
			services: {} as UserManagementMachineServices,
		},
		context: {
			invitationUpdated: false,
			userWasAdded: false,
			inviteButtonState: 'idle',
			removeUserButtonState: 'idle',
		},
		tsTypes: {} as import('./userManagement.machine.typegen').Typegen0,
		// predictableActionArguments: true,
		preserveActionOrder: true,
		type: 'parallel',
		states: {
			listingUsers: {
				description: 'Listing users state',
				initial: 'loadUserList',
				states: {
					idle: {
						on: {
							GET_USER_LIST: {
								target: 'loadUserList',
								description: 'Get user list',
							},
						},
					},
					loadUserList: {
						description: 'State which loads the user list',
						id: 'loadingUserList',
						invoke: {
							src: 'getUsers',
							id: 'getUsers',
							onDone: {
								target: 'idle',
								actions: ['setUserList'],
							},
							onError: {
								target: 'idle',
							},
						},
					},
				},
			},
			addUserFlow: {
				initial: 'idle',
				description: 'State for handeling adding new users',
				states: {
					idle: {
						description: 'Waiting for events',
						on: {
							ADD_USER: {
								target: 'addingUser',
								actions: ['resetValidationErrors'],
							},
						},
					},
					addingUser: {
						invoke: {
							src: 'addUser',
							id: 'addUser',
							onDone: [
								{
									target: 'doneAddingUser',
								},
							],
							onError: [
								{
									target: 'validationError',
									actions: 'setValidationErrors',
								},
							],
						},
					},
					doneAddingUser: {
						entry: ['setUserWasAdded'],
						description: 'Successfully added the user',
						tags: 'addUserRequestCompleted',
						after: {
							'500': {
								target: 'idle',
								internal: false,
							},
						},
					},
					validationError: {
						description: 'Some validation failed',
						tags: 'addUserRequestCompleted',
						on: {
							ADD_USER: {
								target: 'addingUser',
								actions: ['resetValidationErrors'],
							},
						},
					},
				},
			},
			viewingUser: {
				description: 'Viewing user state',
				initial: 'idle',
				states: {
					idle: {
						on: {
							GET_USER: {
								target: 'viewUserFlow',
								description: 'Get user by ID',
							},
						},
					},
					viewUserFlow: {
						initial: 'loadingUser',
						id: 'viewUserFlow',
						description: 'State which loads a single user',
						states: {
							loadingUser: {
								invoke: {
									src: 'getUser',
									id: 'getUser',
									onDone: [
										{
											target: 'doneGettingUser',
											actions: ['setSelectedUser'],
										},
									],
									onError: [
										{
											target: 'errorGettingUser',
										},
									],
								},
								description: 'Loads a single user',
							},
							doneGettingUser: {
								initial: 'idle',
								states: {
									idle: {
										on: {
											REMOVE_USER: {
												target: 'removeUserFlow',
											},
											INVITE_TO_ECOM: {
												target: 'inviteToEcomFlow',
											},
											REVOKE_FROM_ECOM: {
												target: 'revokeFromEcomFlow',
											},
										},
									},
									removeUserFlow: {
										description: 'Removing a user',
										entry: ['setRemoveUserButtonStateLoading'],
										invoke: {
											src: 'removeUser',
											id: 'removeUser',
											onDone: {
												target: 'idle',
												actions: [
													'setRemoveUserButtonStateSuccess',
													'redirectToUserList',
												],
											},
											onError: {
												target: 'idle',
												actions: 'setRemoveUserButtonStateError',
											},
										},
									},
									inviteToEcomFlow: {
										entry: ['setInviteButtonStateLoading'],
										invoke: {
											src: 'inviteUserToEcom',
											id: 'inviteUserToEcom',
											onDone: {
												target: 'reFetchUser',
												actions: [
													'setInvitationUpdated',
													'setInviteButtonStateSuccess',
												],
											},
											onError: {
												target: 'idle',
												actions: 'setInviteButtonStateError',
											},
										},
										description: 'Invites a user to ecom',
									},
									revokeFromEcomFlow: {
										description: 'Remove user permission to use ecom',
										entry: ['setInviteButtonStateLoading'],
										invoke: {
											src: 'revokeUserFromEcom',
											id: 'revokeUserFromEcom',
											onDone: {
												target: 'reFetchUser',
												actions: [
													'setInvitationUpdated',
													'setInviteButtonStateSuccess',
												],
											},
											onError: {
												target: 'idle',
												actions: ['setInviteButtonStateError'],
											},
										},
									},
									reFetchUser: {
										after: {
											'500': {
												target: '#viewUserFlow',
											},
										},
									},
								},
							},
							errorGettingUser: {
								description: 'The request for getting the user failed',
							},
						},
					},
				},
			},
		},
	},
	{
		services: {
			getUsers: async (_context, _event) => requestGetUsers(),
			addUser: async (_context, event) => requestAddUser(event),
			getUser: async (context, event) => {
				const customerContactId =
					event.customerContactId ?? context.selectedUser?.customerContactId;
				return requestGetUser(customerContactId);
			},
			removeUser: async (_context, event) =>
				requestRemoveUser(event.customerContactId),
			inviteUserToEcom: async (_context, event) =>
				requestInviteUserToEcom(event.customerContactId),
			revokeUserFromEcom: async (_context, event) =>
				requestRevokeUserFromEcom(event.customerContactId),
		},
		actions: {
			setUserList: assign({
				userList: (_context, event) => event.data,
			}),
			setSelectedUser: assign({
				selectedUser: (_context, event) => event.data,
			}),
			setUserWasAdded: assign({
				userWasAdded: (_context, _event) => true,
			}),
			setInvitationUpdated: assign({
				invitationUpdated: (_context, _event) => true,
			}),
			setInviteButtonStateSuccess: assign({
				inviteButtonState: (_context, _event) => 'success' as ActionButtonState,
			}),
			setInviteButtonStateError: assign({
				inviteButtonState: (_context, _event) => 'failure' as ActionButtonState,
			}),
			setInviteButtonStateLoading: assign({
				inviteButtonState: (_context, _event) => 'loading' as ActionButtonState,
			}),
			setRemoveUserButtonStateSuccess: assign({
				removeUserButtonState: (_context, _event) =>
					'success' as ActionButtonState,
			}),
			setRemoveUserButtonStateError: assign({
				removeUserButtonState: (_context, _event) =>
					'failure' as ActionButtonState,
			}),
			setRemoveUserButtonStateLoading: assign({
				removeUserButtonState: (_context, _event) =>
					'loading' as ActionButtonState,
			}),
			redirectToUserList: () => {},
			resetValidationErrors: assign({
				errors: (_context, _event) => undefined,
			}),
			setValidationErrors: assign({
				errors: (_context, event) => event.data as ErrorObject,
			}),
		},
	},
);
export type UserManagementMachineState = StateFrom<
	typeof userManagementMachine
>;
