import React from 'react';
import { Field } from 'react-final-form';

import { composeValidators, type FieldValidator } from 'components/FinalForm';
import {
	Textarea as StyledTextarea,
	type TextareaProps,
} from 'components/FormUi';

interface Props extends TextareaProps {
	name: string;
	validate?: FieldValidator | FieldValidator[];
}

export default function Textarea({ name, validate, ...rest }: Props) {
	return (
		<Field
			name={name}
			validate={composeValidators(validate)}
			render={({ input, meta }) => {
				const hasStaleError = meta.dirtySinceLastSubmit && meta.submitError;
				const { type, ...fieldInputProps } = input;
				return (
					<StyledTextarea
						invalid={meta.invalid && meta.touched && !hasStaleError}
						valid={meta.valid && meta.touched && !hasStaleError}
						errorMessage={meta.error || meta.submitError}
						{...fieldInputProps}
						{...rest}
					/>
				);
			}}
		/>
	);
}
Textarea.displayName = 'FinalForm_Textarea';
