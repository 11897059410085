import React from 'react';
import { Field, useField } from 'react-final-form';

import {
	Radio,
	RadioGroupFieldset,
	type RadioGroupFieldsetProps as StyledRadioGroupFieldsetProps,
} from 'components/FormUi';
import type { FormOption } from 'models/sitecore';
import { slugify } from 'utils/string';

interface Props extends Omit<StyledRadioGroupFieldsetProps, 'id' | 'children'> {
	className?: string;
	name: string;
	options: FormOption[];
}

export default function RadioGroup({
	className,
	legend,
	name,
	options,
	required,
}: Props) {
	const { meta } = useField(name);
	return (
		<RadioGroupFieldset
			className={className}
			errorMessage={meta?.error || meta?.submitError}
			id={name}
			invalid={meta?.invalid && meta?.touched}
			legend={legend}
			required={required}
		>
			{options.map((option) => (
				<Field
					key={option.value}
					id={slugify(`${name}-${option.label}`)}
					type="radio"
					name={name}
					value={option.value}
					render={({ input }) => (
						<Radio
							label={option.label}
							id={slugify(`${name}-opt-${option.label}`)}
							required={required}
							{...input}
							className="mt-4"
						/>
					)}
				/>
			))}
		</RadioGroupFieldset>
	);
}
RadioGroup.displayName = 'FinalForm_RadioGroup';
