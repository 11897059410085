/**
 * ScreenReaderAnnouncementText
 */

import React, {
	type AriaAttributes,
	type ReactElement,
	type ReactFragment,
} from 'react';

import type { HTMLTagName } from 'types';
import { cn } from 'utils/classNames';

interface Props {
	as: HTMLTagName;

	/**
	 * If the entire text should be read again even if only parts are updated.
	 * Set if the updated text doesn't make sense on its own. Something like
	 * "X search results" where only X is changed when searching will only have
	 * the number announced without atomic: "23 search results... 18... 31..."
	 */
	atomic?: boolean;

	className?: string;

	/** If the text is visible rather than screen reader only */
	hidden?: boolean;

	/**
	 * The aggressiveness level, defaults to polite. Only use assertive for
	 * critical or time-sensitive notifications that requires the user's
	 * immediate attention, since they interrupt any announcement a screen
	 * reader is currently making.
	 */
	level?: 'assertive' | 'polite';

	/** What modifications to announce, defaults to 'additions text'. */
	relevant?: AriaAttributes['aria-relevant'];

	/** The text to announce. */
	text: string | ReactElement | ReactFragment | undefined;
}

/**
 * Text that's announced to screen readers when updated.
 *
 * IMPORTANT! The component should always be rendered even when there is no
 * text, otherwise the text may not be announced when it's added.
 *
 * @example
 *
 *     const text: string | undefined;
 *
 *     // Incorrect
 *     {text && <ScreenReaderAnnouncementText />}
 *
 *     // Correct
 *     <ScreenReaderAnnouncementText text={text} />
 */
export default function ScreenReaderAnnouncementText({
	as: Tag,
	atomic,
	className,
	hidden,
	level = 'polite',
	relevant,
	text = '',
}: Props) {
	return (
		<Tag
			aria-live={level}
			aria-atomic={atomic ? true : undefined}
			aria-relevant={relevant}
			className={cn(className, hidden && 'sr-only')}
		>
			{text}
		</Tag>
	);
}
ScreenReaderAnnouncementText.displayName = 'ScreenReaderAnnouncementText';
