/**
 * OptionalLink
 */

import React, { type ReactNode } from 'react';

import type { HTMLTagName } from 'types';
import { cn } from 'utils/classNames';

import Link, { type Props as LinkProps } from './Link';

interface Props extends Omit<LinkProps, 'href'> {
	/** Link text. */
	children: ReactNode;

	/** Classes for both link and fallback. */
	className?: string;

	/** Tag to use when href isn't set. */
	fallback?: HTMLTagName | null;

	/** Classes for only the fallback. */
	fallbackClassName?: string;

	/** Link URL. */
	href: LinkProps['href'] | undefined;

	/** Classes for only the link. */
	linkClassName?: string;
}

/** Render a link if `href` prop is truthy, otherwise a non-link tag. */
export default function OptionalLink({
	children,
	className,
	fallback: Fallback = 'div',
	fallbackClassName,
	href,
	linkClassName,
	...linkProps
}: Props) {
	if (href) {
		return (
			<Link href={href} className={cn(className, linkClassName)} {...linkProps}>
				{children}
			</Link>
		);
	}
	if (Fallback !== null) {
		return (
			<Fallback className={cn(className, fallbackClassName)}>
				{children}
			</Fallback>
		);
	}
	return children;
}
OptionalLink.displayName = 'OptionalLink';
