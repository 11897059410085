/**
 * Link
 */

import React, {
	type AnchorHTMLAttributes,
	type ReactNode,
	type RefAttributes,
} from 'react';
import NextLink, { type LinkProps as NextLinkProps } from 'next/link';

import { cn } from 'utils/classNames';

import AnchorLink from './AnchorLink';

export interface Props
	extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
		Omit<NextLinkProps, 'legacyBehavior' | 'href'>,
		RefAttributes<HTMLAnchorElement> {
	/** Element ID, is appended to `href` with #. */
	anchor?: string;

	/** Link text/content. */
	children: ReactNode;

	/** Link class names. */
	className?: string;

	/** Stretch the link to cover everything to its nearest relative ancestor. */
	cover?: boolean;

	/** Link URL. */
	href: string;

	/** Hide the link from screen readers. */
	isScreenReaderHidden?: boolean;

	/** Underline the text. */
	underline?: boolean;
}

/** Wrapped Next.js Link */
const Link = React.forwardRef<HTMLAnchorElement, Props>(
	(
		{
			anchor,
			children,
			className,
			cover = false,
			href: hrefProp,
			isScreenReaderHidden = false,
			target,
			underline = false,

			// Extract Next link props to not pass them to AnchorLink
			// eslint-disable-next-line perfectionist/sort-objects
			passHref,
			prefetch = false,
			replace,
			scroll,
			shallow,
			...props
		},
		ref,
	) => {
		// Let the anchor prop override any href anchors.
		const href = anchor
			? `${hrefProp.split('#')[0]}#${anchor.replace('#', '')}`
			: hrefProp;

		if (!href || !children) {
			return null;
		}

		const commonProps: AnchorHTMLAttributes<HTMLAnchorElement> = {
			'aria-hidden': isScreenReaderHidden ? true : props['aria-hidden'],
			'tabIndex': isScreenReaderHidden ? -1 : props.tabIndex,
			'className':
				cn(
					className,
					underline && 'underline hover:no-underline',
					cover && 'before:absolute before:inset-0 before:z-2',
				) || undefined,
		};
		const nextProps = {
			replace,
			scroll,
			shallow,
			passHref,
			prefetch,
		};

		if (href.startsWith('#')) {
			// Don't include target here since we should stay on the same page.
			return (
				<AnchorLink {...props} {...commonProps} ref={ref} anchor={href}>
					{children}
				</AnchorLink>
			);
		}

		return (
			<NextLink
				{...props}
				{...commonProps}
				{...nextProps}
				ref={ref}
				href={href}
				// Target could be an empty string
				target={target || undefined}
			>
				{children}
			</NextLink>
		);
	},
);
Link.displayName = 'Link';

export default Link;
