import {
	type ActorRefFrom,
	assign,
	createMachine,
	type StateFrom,
} from 'xstate';

import { isError, ValidationError } from 'errors';
import { isTestmode } from 'hooks';
import { isGuiIframe, POST_SIGN_EVENT } from 'pages/api/sign/helpers';
import { isClient } from 'utils/helpers';

import {
	openSignWindow,
	pollCustomerRequest,
	requestCustomerApi,
	signInNewCustomer,
	tokenInfoRequest,
} from './createJulaClubCustomer.services';
import type {
	CreateJulaClubCustomerMachineContext,
	CreateJulaClubCustomerMachineEvents,
	CreateJulaClubCustomerMachineServices,
} from './createJulaClubCustomer.types';

export const createJulaClubCustomerMachine = createMachine(
	{
		id: 'createJulaClubCustomerMachine',
		schema: {
			context: {} as CreateJulaClubCustomerMachineContext,
			events: {} as CreateJulaClubCustomerMachineEvents,
			services: {} as CreateJulaClubCustomerMachineServices,
		},
		tsTypes: {} as import('./createJulaClubCustomer.machine.typegen').Typegen0,
		context: {
			pollingNumberFailed: 0,
			shouldDisplaySignIframeModal: false,
			signWindow: null,
			errorOpeningSignWindow: false,
		},
		initial: 'createCustomer',
		states: {
			createCustomer: {
				id: 'createCustomer',
				entry: ['resetValidationErrors'],
				invoke: {
					src: 'listenForIframeMessage',
				},
				initial: 'waitingForCreateCustomerEvent',
				states: {
					waitingForCreateCustomerEvent: {
						on: {
							CREATE_CUSTOMER: 'requestCreateCustomer',
						},
					},
					requestCreateCustomer: {
						invoke: {
							id: 'createCustomerService',
							src: 'createCustomerService',
							onDone: [
								{
									cond: 'shouldSignBankId',
									target: 'displayBankIdSignIn',
									actions: ['setBankIdSignUrl'],
								},
								{
									cond: 'shouldSubmitAdditionalCustomerData',
									target: 'waitingForCreateCustomerEvent',
									actions: ['setFallBackApiUrl', 'setSection'],
								},
								{
									cond: 'shouldPollForCustomerCreation',
									target: 'waitingForCustomerToBeCreated',
									actions: ['setPendingCustomerTicket'],
								},
							],
							onError: [
								{
									cond: 'eventHasValidationErrors',
									target: 'validationError',
									actions: ['setValidationErrors'],
								},
								{
									cond: 'eventCustomerNotCreated',
									target: '#customerNotCreated',
								},
								{
									target: '#unknownError',
								},
							],
						},
					},
					displayBankIdSignIn: {
						tags: ['customerCreationStartedOrFailed', 'loading'],
						always: [
							{
								target: 'loadingSignFrameModal',
								cond: 'shouldDisplaySignIframeModal',
							},
							{
								target: 'loadingSignWindow',
							},
						],
					},

					loadingSignFrameModal: {
						entry: 'openSignIframeModal',
						tags: ['customerCreationStartedOrFailed', 'loading'],
						on: {
							SIGN_FRAME_LOAD_SUCCESS: {
								target: 'waitingForCustomerToBeCreated',
							},
						},
					},
					loadingSignWindow: {
						tags: ['customerCreationStartedOrFailed', 'loading'],
						invoke: {
							id: 'openSignWindow',
							src: 'openSignWindow',
							onDone: {
								target: 'waitingForCustomerToBeCreated',
								actions: 'setSignWindowRef',
							},
							onError: {
								target: 'waitingForCustomerToBeCreated',
								actions: 'setErrorOpeningSignWindow',
							},
						},
					},
					waitingForCustomerToBeCreated: {
						tags: ['customerCreationStartedOrFailed', 'loading'],
						after: {
							POLL_CUSTOMER_CREATION_DELAY: {
								target: 'checkingCustomerCreationStatus',
							},
						},
						on: {
							RETRY_OPEN_SIGN_WINDOW: {
								target: 'loadingSignWindow',
								actions: 'resetErrorOpeningSignWindow',
							},
						},
					},
					checkingCustomerCreationStatus: {
						tags: 'loading',
						invoke: {
							id: 'pollCustomerApi',
							src: 'pollCustomerApi',
							onDone: [
								{
									cond: 'customerCreationIsPending',
									target: 'waitingForCustomerToBeCreated',
									actions: 'setPendingCustomerTicket',
								},
								{
									cond: 'customerCreationIsDone',
									target: '#signInNewCustomer',
									actions: 'setResolvedCustomerTicket',
								},
							],
							onError: [
								{
									cond: 'eventCustomerNotCreated',
									target: '#customerNotCreated',
								},
								{
									target: 'waitingForCustomerToBeCreated',
									actions: 'setFailedPollingNumber',
								},
								{ target: '#unknownError' },
							],
						},
					},
					validationError: {
						tags: 'customerCreationStartedOrFailed',
						on: {
							CREATE_CUSTOMER: 'requestCreateCustomer',
							RESET: '#createCustomer',
						},
					},
				},
				on: {
					SIGN_JULA_CLUB_BECOME_MEMBER_DONE: {
						actions: ['removeSignIframeModal', 'closeSignWindow'],
					},
				},
			},
			signInNewCustomer: {
				tags: 'loading',
				id: 'signInNewCustomer',
				invoke: {
					id: 'signInNewCustomer',
					src: 'signInNewCustomer',
					onDone: {
						target: 'getCustomerTokenInfo',
					},
					onError: {
						target: 'customerCreated',
					},
				},
			},
			getCustomerTokenInfo: {
				tags: 'loading',
				invoke: {
					id: 'getCustomerTokenInfo',
					src: 'getCustomerTokenInfo',
					onDone: {
						target: 'customerCreated',
						actions: 'setFirstName',
					},
					onError: {
						target: 'customerCreated',
					},
				},
			},
			customerCreated: {
				tags: 'doneCreatingMember',
				on: {
					CONTINUE: {
						target: 'success',
					},
				},
			},
			success: {
				tags: 'doneCreatingMember',
				entry: ['refreshBrowser'],
			},
			unknownError: {
				id: 'unknownError',
				tags: 'customerCreationStartedOrFailed',
				on: {
					CREATE_CUSTOMER: 'createCustomer',
					RESET: 'createCustomer',
				},
			},
			customerNotCreated: {
				id: 'customerNotCreated',
				tags: 'customerCreationStartedOrFailed',
				on: {
					CREATE_CUSTOMER: 'createCustomer',
					RESET: 'createCustomer',
				},
			},
		},
	},
	{
		actions: {
			setFirstName: assign({
				customerFirstName: (_, event) => event?.data?.username,
			}),
			setPendingCustomerTicket: assign({
				pendingTicket: (_context, event) => event.data,
			}),
			setResolvedCustomerTicket: assign({
				resolvedTicket: (_context, event) => event.data,
			}),
			setBankIdSignUrl: assign({
				bankIdSignUrl: (_context) => undefined,
			}),
			resetValidationErrors: assign({
				errors: (_context) => undefined,
			}),
			setValidationErrors: assign({
				errors: (_context, event) => event.data as ValidationError,
			}),
			setFailedPollingNumber: assign({
				pollingNumberFailed: (context) => context.pollingNumberFailed + 1,
			}),
			// Action to refresh the browser
			refreshBrowser: (_context) => {
				globalThis.location.reload();
			},
			openSignIframeModal: assign({
				shouldDisplaySignIframeModal: (_context) => true,
			}),
			removeSignIframeModal: assign({
				shouldDisplaySignIframeModal: (_context) => false,
			}),
			setSignWindowRef: assign({
				signWindow: (_context, event) => event.data,
			}),
			setErrorOpeningSignWindow: assign({
				errorOpeningSignWindow: (_context) => true,
			}),
			resetErrorOpeningSignWindow: assign({
				errorOpeningSignWindow: (_context) => false,
			}),
			closeSignWindow: assign({
				signWindow: (context) => {
					if (context.signWindow) context.signWindow.close();

					return null;
				},
			}),
			setSection: assign({
				currentSection: (_, event) => event.data?.data?.NextSectionIdentifier,
			}),
			setFallBackApiUrl: assign({
				fallBackApiUrl: (_context, event) => event.data?.data?.Url,
			}),
		},
		guards: {
			shouldDisplaySignIframeModal: (_context) => isGuiIframe(),
			eventHasValidationErrors: (_context, event) =>
				isError(event.data, 'ValidationError'),
			eventCustomerNotCreated: (_context, event) =>
				isError(event.data, 'ResponseError') &&
				(event.data?.status === 404 || event.data?.status === 500),
			shouldSignBankId: (_context, event) => {
				if (isTestmode()) {
					return false;
				}
				return event.data?.clientAction === 'Redirect';
			},
			shouldPollForCustomerCreation: (_context, event) => {
				if (isTestmode()) {
					return true;
				}

				return event.data?.clientAction === 'Polling';
			},
			customerCreationIsPending: (_context, event) =>
				event.data?.clientAction === 'Polling',
			customerCreationIsDone: (_context, event) =>
				event.data?.clientAction === 'None',
			shouldSubmitAdditionalCustomerData: (_context, event) =>
				event.data?.clientAction === 'UserInput',
		},
		delays: {
			POLL_CUSTOMER_CREATION_DELAY: (context) => {
				const delay =
					Number(context.pendingTicket?.data?.RefreshIntervalSeconds) || 2;
				return delay * 1000;
			},
		},
		services: {
			openSignWindow: (context) => openSignWindow(context?.bankIdSignUrl),
			getCustomerTokenInfo: (_context) => tokenInfoRequest(),
			signInNewCustomer: (_context) => signInNewCustomer(),
			pollCustomerApi: (_context) => pollCustomerRequest(),
			createCustomerService: (context, event) =>
				requestCustomerApi(context?.fallBackApiUrl, event.formData),
			// Subscribe to messages coming from iframes
			listenForIframeMessage: () => (send) => {
				if (!isClient()) {
					return undefined;
				}

				const listener = (event: MessageEvent) => {
					if (event.origin !== globalThis.location.origin) {
						return;
					}
					if (event.data.type === POST_SIGN_EVENT) {
						send({
							type: 'SIGN_JULA_CLUB_BECOME_MEMBER_DONE',
						});
					}
				};

				window.addEventListener('message', listener, false);

				return () => {
					window.removeEventListener('message', listener);
				};
			},
		},
	},
);
export type CreateJulaClubCustomerMachineState = StateFrom<
	typeof createJulaClubCustomerMachine
>;
export type CreateJulaClubCustomerMachineActor = ActorRefFrom<
	typeof createJulaClubCustomerMachine
>;
