import React, { useEffect, useState } from 'react';
import { useInterpret, useSelector } from '@xstate/react';
import { waitFor } from 'xstate/lib/waitFor';

import Button from 'components/Button';
import GenericForm, { getValuesForSubmit } from 'components/GenericForm';
import Img from 'components/Img';
import InfoBox from 'components/InfoBox';
import LoadingSpinner from 'components/LoadingSpinner';
import Popover from 'components/Popover';
import RichText from 'components/RichText';
import Text from 'components/Text';
import { isError } from 'errors';
import {
	useCustomerInformation,
	useEffectOnce,
	useRevalidateAvailableCreditBalance,
	useRevalidateCustomerInformation,
} from 'hooks';
import type { BusinessLogicError } from 'models/api';
import type { FormFields } from 'models/sitecore';
import {
	creditMachine,
	selectCreditNotCreatedState,
	selectCreditRequestDone,
	selectErrorOpeningSignWindow,
	selectIframeSrc,
	selectIsOpen,
	selectRequestingCredit,
	selectSigningCanceledState,
} from 'state-machines/credit';
import { getFieldErrors } from 'utils/formHelpers';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface CreditApplicationViewProps {
	businessLogicErrors: BusinessLogicError[] | undefined;
	cancelButtonText: string;
	content?: string | undefined;
	continueButtonText: string;
	creditNotCreated: boolean;
	creditRequestDone: boolean;
	customerType?: 'julaclub' | 'julapro';
	description: string;
	errorOpeningSignWindowText: string;
	failHeading: string;
	failText: string;
	form: FormFields;
	hasErrorOpeningSignWindow: boolean;
	heading: string;
	loadingText: string;
	onCancel: () => void;
	onDone: () => void;
	onRetryOpenSignWindow: () => void;
	onRetryOpenSignWindowButtonText: string;
	onSubmit: (formData: Record<string, unknown>) => Promise<unknown>;
	onTryAgain: () => void;
	requestingCredit: boolean;
	signingCanceled: boolean;
	signingCancelHeading: string;
	signingCancelText: string;
	signModalHandleCancellation: () => void;
	signModalIframeSrc: string | undefined;
	signModalIsOpen: boolean;
	signModalOnLoad: () => void;
	submitText: string;
	successCreditLimit: string;
	successHeading: string;
	successText?: string;
	tryAgainButtonText: string;
	waitingForInput: boolean;
}

function CreditApplicationView({
	businessLogicErrors,
	cancelButtonText,
	content,
	continueButtonText,
	creditNotCreated,
	creditRequestDone,
	customerType = 'julaclub',
	description,
	errorOpeningSignWindowText,
	failHeading,
	failText,
	form,
	hasErrorOpeningSignWindow,
	heading,
	loadingText,
	onCancel,
	onDone,
	onRetryOpenSignWindow,
	onRetryOpenSignWindowButtonText,
	onSubmit,
	onTryAgain,
	requestingCredit,
	signingCanceled,
	signingCancelHeading,
	signingCancelText,
	signModalHandleCancellation,
	signModalIframeSrc,
	signModalIsOpen,
	signModalOnLoad,
	submitText,
	successCreditLimit,
	successHeading,
	successText,
	tryAgainButtonText,
	waitingForInput,
}: CreditApplicationViewProps) {
	const { t } = useI18n();

	return (
		<>
			{waitingForInput && (
				<div>
					<p className="mb-2 font-bold">{heading}</p>
					<p className="mb-2">{description}</p>
					{content && (
						<RichText className="mt-6" checkMarkList html={content} />
					)}
					{businessLogicErrors && businessLogicErrors.length > 0 && (
						<InfoBox className="mb-8" icon="error" variant="error">
							{businessLogicErrors.map((error) => (
								<Text
									key={error.text || error.key}
									as="p"
									className="text-sm"
									text={t(error.text || error.key)}
								/>
							))}
						</InfoBox>
					)}
					<div className="mt-8">
						<GenericForm
							fields={form}
							submitButtonText={submitText}
							onSubmitCallback={onSubmit}
							className="space-y-6"
						/>
					</div>
					<Button
						variant="text"
						displayWidth="full"
						className="mt-4"
						onClick={onCancel}
					>
						{cancelButtonText}
					</Button>
				</div>
			)}
			{creditRequestDone && (
				<div className="flex flex-col items-center">
					<Img
						src={
							customerType === 'julapro'
								? '/assets/images/graphic-congrats-julapro.png'
								: '/assets/images/graphic-congrats-julaclub.png'
						}
						className="my-8"
					/>
					<div className="mb-2">
						<Text className="mb-2" as="h2" text={successHeading} />
						<Text className="mb-2" as="p" text={successCreditLimit} />
						<Text className="mb-2" as="p" text={successText} />
					</div>
					<div className="mt-8 w-full">
						<Button
							variant="primary"
							displayWidth="full"
							className="my-4"
							onClick={onDone}
						>
							{continueButtonText}
						</Button>
					</div>
				</div>
			)}
			{creditNotCreated && (
				<div className="flex flex-col items-center">
					<Img
						src={
							customerType === 'julapro'
								? '/assets/images/graphic-congrats-julapro.png'
								: '/assets/images/graphic-congrats-julaclub.png'
						}
						className="my-8"
					/>
					<div className="mb-2">
						<Text className="mb-2" as="h2" text={failHeading} />
						<Text className="mb-2" as="p" text={failText} />
					</div>
					<div className="mt-8 w-full">
						<Button
							variant="primary"
							displayWidth="full"
							className="my-4"
							onClick={onTryAgain}
						>
							{tryAgainButtonText}
						</Button>
					</div>
				</div>
			)}
			{signingCanceled && (
				<div className="flex flex-col items-center">
					<Img
						src={
							customerType === 'julapro'
								? '/assets/images/graphic-congrats-julapro.png'
								: '/assets/images/graphic-congrats-julaclub.png'
						}
						className="my-8"
					/>
					<div className="mb-2">
						<Text className="mb-2" as="h2" text={signingCancelHeading} />
						<Text className="mb-2" as="p" text={signingCancelText} />
					</div>
					<div className="mt-8 w-full">
						<Button
							variant="primary"
							displayWidth="full"
							className="my-4"
							onClick={onTryAgain}
						>
							{tryAgainButtonText}
						</Button>
					</div>
				</div>
			)}
			{requestingCredit && (
				<div className="mt-16 flex w-full flex-col items-center">
					<div className="mb-8">
						<Text className="mb-2" as="h2" text={loadingText} />
					</div>
					<LoadingSpinner
						variant="dashing"
						spinnerColor="julaRed"
						trackColor="transparent"
						size="medium"
					/>
					{hasErrorOpeningSignWindow && (
						<>
							<p className="mt-8 font-bold">{errorOpeningSignWindowText}</p>
							<Button
								onClick={onRetryOpenSignWindow}
								className="mt-4"
								variant="primary"
							>
								{onRetryOpenSignWindowButtonText}
							</Button>
						</>
					)}
				</div>
			)}
			<Popover
				isOpen={signModalIsOpen}
				onClose={signModalHandleCancellation}
				variant="window"
				title={t('sign_frame_heading')}
				headerColor="red"
				padContent={false}
				contentClassName="min-h-48"
			>
				{signModalIframeSrc && (
					<iframe
						src={signModalIframeSrc}
						title={t('sign_frame_heading')}
						className="h-full w-full border-0 sm:min-h-[39rem]"
						onLoad={signModalOnLoad}
					/>
				)}
			</Popover>
		</>
	);
}
CreditApplicationView.displayName = 'CreditApplicationView';

interface Props {
	cancelButtonText: string;
	content?: string | undefined;
	continueButtonText: string;
	customerType?: 'julaclub' | 'julapro';
	description: string;
	eventType:
		| 'JULA_CLUB_APPLY_FOR_CREDIT'
		| 'JULA_CLUB_RAISE_CREDIT'
		| 'JULA_PRO_REQUEST_CREDIT';
	failHeading: string;
	failTextKey: string;
	form: FormFields;
	heading: string;
	loadingText: string;
	onClose: () => void;
	signingCancelHeading: string;
	signingCancelText: string;
	submitText: string;
	successCreditLimitKey: string;
	successHeading: string;
	successText?: string;
}
export default function CreditApplication({
	cancelButtonText,
	content,
	continueButtonText,
	customerType,
	description,
	eventType,
	failHeading,
	failTextKey,
	form,
	heading,
	loadingText,
	onClose,
	signingCancelHeading,
	signingCancelText,
	submitText,
	successCreditLimitKey,
	successHeading,
	successText,
}: Props) {
	const { t } = useI18n();
	const { customerInformation } = useCustomerInformation();
	const revalidateCustomerInformation = useRevalidateCustomerInformation();
	const revalidateAvailableCreditBalance =
		useRevalidateAvailableCreditBalance();
	const [businessLogicErrors, setBusinessLogicErrors] = useState<
		BusinessLogicError[] | undefined
	>();
	const creditService = useInterpret(creditMachine, { devTools: true });
	const { send: creditSend } = creditService;
	const onDone = () => {
		creditSend('RESET');
		onClose();
	};
	const onCancel = () => {
		onClose();
	};
	const onTryAgain = () => {
		creditSend('RESET');
	};
	useEffectOnce(() => {
		creditSend('RESET');
	});
	const onSubmit = async (genericFormData: Record<string, unknown>) => {
		creditSend({
			type: eventType,
			formData: {
				...getValuesForSubmit(form, genericFormData),
			},
		});

		const doneData = await waitFor(
			creditService,
			(state) => state.hasTag('creditRequestStartedOrFailed'),
			{ timeout: 120_000 },
		);

		if (doneData.context?.errors) {
			if (
				isError(doneData.context.errors, 'ValidationError') &&
				is.arrayWithLength(doneData.context.errors.generalErrors)
			) {
				setBusinessLogicErrors(doneData.context.errors.generalErrors);
			}
			return getFieldErrors(doneData.context.errors);
		}
		return undefined;
	};

	const requestingCredit = useSelector(creditService, selectRequestingCredit);
	const creditRequestDone = useSelector(creditService, selectCreditRequestDone);
	const creditNotCreated = useSelector(
		creditService,
		selectCreditNotCreatedState,
	);
	const signingCanceled = useSelector(
		creditService,
		selectSigningCanceledState,
	);
	const signModalIframeSrc = useSelector(creditService, selectIframeSrc);
	const signModalIsOpen = useSelector(creditService, selectIsOpen);
	const errorOpeningSignWindow = useSelector(
		creditService,
		selectErrorOpeningSignWindow,
	);
	const waitingForInput =
		!creditRequestDone &&
		!creditNotCreated &&
		!requestingCredit &&
		!signingCanceled;
	useEffect(() => {
		revalidateAvailableCreditBalance();
		revalidateCustomerInformation();
	}, [creditRequestDone]);

	return (
		<CreditApplicationView
			customerType={customerType}
			heading={heading}
			description={description}
			content={content}
			form={form}
			submitText={submitText}
			onSubmit={onSubmit}
			onDone={onDone}
			onCancel={onCancel}
			onTryAgain={onTryAgain}
			businessLogicErrors={businessLogicErrors}
			waitingForInput={waitingForInput}
			creditRequestDone={creditRequestDone}
			creditNotCreated={creditNotCreated}
			signingCanceled={signingCanceled}
			requestingCredit={requestingCredit}
			successHeading={successHeading}
			successCreditLimit={t(successCreditLimitKey, {
				creditLimit: customerInformation?.creditLimit,
			})}
			successText={successText}
			failHeading={failHeading}
			failText={t(failTextKey, {
				creditLimit: customerInformation?.creditLimit,
			})}
			signingCancelText={signingCancelText}
			signingCancelHeading={signingCancelHeading}
			cancelButtonText={cancelButtonText}
			continueButtonText={continueButtonText}
			tryAgainButtonText={t('account_try_again_button')}
			loadingText={loadingText}
			signModalIsOpen={signModalIsOpen}
			signModalIframeSrc={signModalIframeSrc}
			signModalHandleCancellation={() => creditSend('CLOSE')}
			signModalOnLoad={() => creditSend('SIGN_FRAME_LOAD_SUCCESS')}
			hasErrorOpeningSignWindow={errorOpeningSignWindow}
			errorOpeningSignWindowText={t('account_error_opening_sign_window_text')}
			onRetryOpenSignWindowButtonText={t('account_try_again_button')}
			onRetryOpenSignWindow={() => creditSend('RETRY_OPEN_SIGN_WINDOW')}
		/>
	);
}
CreditApplication.displayName = 'CreditApplication';
