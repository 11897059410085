import React from 'react';

import Button from 'components/Button';
import Img from 'components/Img';
import RichText from 'components/RichText';
import Text from 'components/Text';
import type { Product } from 'models/product';
import { getBrandLogoImage } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';

interface Props {
	className?: string;
	product: Product;
}

export default function ProductDetailsBrandInfo({ className, product }: Props) {
	const { t } = useI18n();
	const { brand } = product;
	const brandLogoSrc = getBrandLogoImage(product);

	if (!brand) {
		return null;
	}

	return (
		<div className={className}>
			<div className="mb-6">
				{brandLogoSrc && (
					<Img
						src={brandLogoSrc}
						alt={brand.title}
						useFallbackOnError
						className="mb-6 max-h-16 max-w-[10rem]"
					/>
				)}
				<Text as="h3" styleAs="h4" className="mb-2">
					{brand.longTitle}
				</Text>
				<RichText html={brand.descriptionFormatted} />
			</div>
			{brand.relativeUrl && (
				<Button href={brand.relativeUrl} variant="primary">
					{t('product_details_read_more_about_brand_button', {
						brand: brand.title,
					})}
				</Button>
			)}
		</div>
	);
}
ProductDetailsBrandInfo.displayName = 'ProductDetailsBrandInfo';
