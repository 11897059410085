import React from 'react';

import GdprAccordion from 'components/GdprAccordion';
import GenericForm, { type FormSubmitCallback } from 'components/GenericForm';
import type { FormFields } from 'models/sitecore';

interface Props {
	className?: string;
	description: string;
	fields: FormFields;
	heading?: string;
	onSubmitCallback: FormSubmitCallback;
	submitButtonText: string;
}

export default function FormView({
	className,
	description,
	fields,
	heading,
	onSubmitCallback,
	submitButtonText,
}: Props) {
	return (
		<div className={className}>
			{heading && <p className="mb-2 font-bold">{heading}</p>}
			<p className="mb-4">{description}</p>

			<GdprAccordion className="mb-4" />

			<GenericForm
				fields={fields}
				submitButtonText={submitButtonText}
				onSubmitCallback={onSubmitCallback}
				className="space-y-6"
			/>
		</div>
	);
}
FormView.displayName = 'JulaClubSignupForm_FormView';
