import { useEffect, useRef, useState } from 'react';
import type { RefObject } from 'react';

/**
 * Observe an element's intersection changes.
 */
export function useIntersectionObserver<T extends HTMLElement>(
	callback: (entry: IntersectionObserverEntry) => void,
	options?: IntersectionObserverInit,
	dependencies: unknown[] = [],
): RefObject<T> {
	const observedElementRef = useRef<T>(null);
	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => {
			if (entry) {
				callback(entry);
			}
		}, options);
		if (observedElementRef.current) {
			observer.observe(observedElementRef.current);
		}
		return () => {
			observer.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dependencies);

	return observedElementRef;
}

/**
 * Observe if a `position: sticky` element is currently stuck. Assumes it's
 * positioned from the top of the screen.
 *
 * @example
 *
 * const [containerRef, isStuck] = useIsStickyStuck<HTMLDivElement>();
 *
 * return (
 *   <div ref={containerRef} className={cn('sticky top-0', isStuck && 'stuck')}>
 *     ...
 *   </div>
 * );
 */
export function useIsStickyStuck<T extends HTMLElement>(
	topPos: number = 0,
): [RefObject<T>, boolean] {
	const [isStuck, setIsStuck] = useState<boolean>(false);
	// Invert positive/negative and add the pixel that makes it work.
	const topVal = -(topPos + 1);
	const observedElementRef = useIntersectionObserver<T>(
		(entry) => {
			setIsStuck(entry.intersectionRatio < 1);
		},
		{ threshold: [1], rootMargin: `${topVal}px 0px 0px 0px` },
		[topVal],
	);

	return [observedElementRef, isStuck] as const;
}

/**
 * Observe if scrolling has reached an element. Can check for the top or the
 * bottom of the element, i.e. 'scrolled to' or 'scrolled past'.
 *
 * @example
 *
 * const [elementRef, isScrolledTo] = useIsScrolledToElement<HTMLDivElement>();
 *
 * return (
 *   <div ref={elementRef} className={isScrolledTo ? 'highlight' : undefined}>
 *     ...
 *   </div>
 * );
 */
export function useIsScrolledToElement<T extends HTMLElement>(
	elementEdge: 'top' | 'bottom' = 'top',
): [RefObject<T>, boolean] {
	const [isScrolledTo, setIsScrolledTo] = useState<boolean>(false);
	const observedElementRef = useIntersectionObserver<T>(
		(entry) => {
			setIsScrolledTo(!entry.isIntersecting);
		},
		{ threshold: [elementEdge === 'bottom' ? 0 : 1] },
		[elementEdge],
	);

	return [observedElementRef, isScrolledTo] as const;
}
