/**
 * TopBanner
 */

import React, { type ReactNode } from 'react';

import Img from 'components/Img';
import { cn } from 'utils/classNames';

interface Props {
	backgroundColor?: string;
	children?: ReactNode;
	className?: string;
	leftImageLargeSrc?: string;
	leftImageSmallSrc?: string;
	rightImageLargeSrc?: string;
	rightImageSmallSrc?: string;
	textColor?: string;
}

/** The top banner with graphics like images */
export default function TopBanner({
	backgroundColor,
	children,
	className,
	leftImageLargeSrc,
	leftImageSmallSrc,
	rightImageLargeSrc,
	rightImageSmallSrc,
	textColor,
}: Props) {
	// Keep children padding in sync with widths.
	const smallWidth = 64;
	const smallHeight = 64;
	const largeWidth = 192;
	const largeHeight = 48;

	return (
		<div
			className={cn(
				'relative flex min-h-12 items-center text-center forced-colors:border-b',
				className,
			)}
			style={{ backgroundColor, color: textColor }}
		>
			{(leftImageSmallSrc || leftImageLargeSrc) && (
				<div className="absolute inset-y-0 left-0">
					{leftImageSmallSrc && (
						<Img
							src={leftImageSmallSrc}
							width={smallWidth}
							height={smallHeight}
							service="nextjs"
							jpgOptimized={false}
							className="h-full object-cover object-right md:hidden"
						/>
					)}
					{leftImageLargeSrc && (
						<Img
							src={leftImageLargeSrc}
							width={largeWidth}
							height={largeHeight}
							service="nextjs"
							jpgOptimized={false}
							className="h-full object-cover object-right max-md:hidden"
						/>
					)}
				</div>
			)}

			<div
				className={cn(
					'grow p-2',
					(leftImageSmallSrc || rightImageSmallSrc) && 'mx-16',
					(leftImageLargeSrc || rightImageLargeSrc) && 'md:mx-48',
				)}
			>
				{children}
			</div>

			{(rightImageSmallSrc || rightImageLargeSrc) && (
				<div className="absolute inset-y-0 right-0 text-right">
					{rightImageSmallSrc && (
						<Img
							src={rightImageSmallSrc}
							width={smallWidth}
							height={smallHeight}
							service="nextjs"
							jpgOptimized={false}
							className="inline-block h-full object-cover object-left md:hidden"
						/>
					)}
					{rightImageLargeSrc && (
						<Img
							src={rightImageLargeSrc}
							width={largeWidth}
							height={largeHeight}
							service="nextjs"
							jpgOptimized={false}
							className="inline-block h-full object-cover object-left max-md:hidden"
						/>
					)}
				</div>
			)}
		</div>
	);
}
TopBanner.displayName = 'TopBanner';
