import React from 'react';

import { OptionalLink } from 'components/Link';
import ProductTag from 'components/ProductTag';
import Rating from 'components/Rating';
import type {
	Brand,
	ProductTag as ProductTagModel,
	ReviewScore,
} from 'models/product';
import { is } from 'utils/helpers';

import { PRODUCT_REVIEWS_ID } from './helpers/constants';

interface Props {
	brand: Brand | undefined;
	onRatingClick: () => void;
	productModel: string | undefined;
	reviewScore: ReviewScore | undefined;
	tags: ProductTagModel[] | undefined;
	variantModel: string | undefined;
}

export default function ProductDetailsIntro({
	brand,
	onRatingClick,
	productModel,
	reviewScore,
	tags,
	variantModel,
}: Props) {
	const productTags = tags?.filter(({ section }) => section === 'Product');
	const model = variantModel || productModel;

	return (
		<>
			{brand?.title && (
				<>
					{model && (
						<p>
							<OptionalLink underline href={brand.relativeUrl} fallback={null}>
								{brand.title}
							</OptionalLink>
							{' | '}
							{model}
						</p>
					)}
					{!model && (
						<OptionalLink underline href={brand.relativeUrl} fallback="p">
							{brand.title}
						</OptionalLink>
					)}
				</>
			)}
			<div className="mt-2">
				{reviewScore?.score && reviewScore.score > 0 ? (
					<Rating
						reviewCountVariant="fullText"
						href={`#${PRODUCT_REVIEWS_ID}`}
						onClick={onRatingClick}
						score={reviewScore.score}
						reviewCount={reviewScore.count}
					/>
				) : (
					<div className="h-6" />
				)}
			</div>
			{is.arrayWithLength(productTags) && (
				<div className="mt-4 flex flex-wrap gap-2">
					{productTags.map((tag) => (
						<ProductTag
							key={tag.text}
							type={tag.type}
							text={tag.text}
							size="large"
						/>
					))}
				</div>
			)}
		</>
	);
}
ProductDetailsIntro.displayName = 'ProductDetailsIntro';
