import React from 'react';
import { useSelector } from '@xstate/react';

import InfoBox from 'components/InfoBox';
import Login from 'components/Login';
import PageHeaderComponent from 'components/PageHeader';
import RichText from 'components/RichText';
import Skiplink from 'components/Skiplink';
import {
	HEADER_SEARCH_FIELD_ID,
	HEADER_SEARCH_FIELD_MOBILE_ID,
	MAIN_CONTENT_CONTAINER_ID,
} from 'constants/ids';
import {
	useGlobalMachinesContext,
	useGlobalMemberPopoversContext,
} from 'contexts';
import type { JulaComponentProps } from 'lib/component-props';
import type { PageHeaderFields } from 'models/sitecore';
import {
	selectIsAuthenticatedUser,
	selectIsInitialisingUser,
} from 'state-machines/authentication';
import { selectCartQuantity } from 'state-machines/cart';
import { selectWishlistQuantity } from 'state-machines/wishlist';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields?: PageHeaderFields;
};

export default function PageHeader({ fields }: Props) {
	const { cartService, userService, wishlistService } =
		useGlobalMachinesContext();
	const { openJulaClubLogin, openMenu } = useGlobalMemberPopoversContext();

	const { t } = useI18n();

	// Auth
	const isInitialisingUser = useSelector(userService, selectIsInitialisingUser);

	// Auth user
	const isAuthenticatedUser = useSelector(
		userService,
		selectIsAuthenticatedUser,
	);

	// Cart
	const cartQuantity = useSelector(cartService, selectCartQuantity);

	// Wishlist
	const wishlistQuantity = useSelector(wishlistService, selectWishlistQuantity);

	const handleLoginClick = () => {
		openJulaClubLogin();
	};

	const handleAccountClick = () => {
		openMenu();
	};

	if (!fields) {
		return null;
	}

	return (
		<>
			{fields.systemMessage?.activated && (
				<InfoBox
					className="print:hidden"
					icon="error"
					variant="error"
					type="wide"
					heading={fields.systemMessage.heading}
				>
					{fields.systemMessage.message && (
						<RichText html={fields.systemMessage.message} className="text-sm" />
					)}
				</InfoBox>
			)}
			<Skiplink
				target={MAIN_CONTENT_CONTAINER_ID}
				text={t('skiplink_to_content_text')}
			/>
			{!fields.hideSearchBar && (
				<>
					<Skiplink
						target={HEADER_SEARCH_FIELD_ID}
						text={t('skiplink_to_search_text')}
						className="max-headerRow:hidden"
						scroll={false}
					/>
					<Skiplink
						target={HEADER_SEARCH_FIELD_MOBILE_ID}
						text={t('skiplink_to_search_text')}
						className="headerRow:hidden"
						scroll={false}
					/>
				</>
			)}
			<PageHeaderComponent
				cartQuantity={cartQuantity}
				hideSearchBar={fields.hideSearchBar}
				isInitialisingUser={isInitialisingUser}
				isAuthenticatedUser={isAuthenticatedUser}
				mainMenu={fields.mainMenu}
				onAccountClick={handleAccountClick}
				onLoginClick={handleLoginClick}
				primaryTopLinks={fields.primaryTopLinks}
				secondaryTopLinks={fields.secondaryTopLinks}
				uspLinks={fields.uspLinks}
				review={fields.review}
				wishlistQuantity={wishlistQuantity}
			/>
			<Login />
		</>
	);
}
PageHeader.displayName = 'PageHeader';
