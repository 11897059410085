import { ActorRefFrom, assign, createMachine, sendParent } from 'xstate';

import { UpdateCartEvent } from 'state-machines/checkout';

import type { Context, Events } from './contactReferences.types';

export const contactReferencesMachine = createMachine(
	{
		id: 'contactReferences',
		initial: 'idle',
		schema: {
			context: {} as Context,
			events: {} as Events,
		},
		tsTypes: {} as import('./contactReferences.machine.typegen').Typegen0,
		context: {
			contactReferencesErrorTypes: ['ContactReferenceNotSelected'],
		},
		states: {
			idle: {
				on: {
					CART_UPDATED: {
						target: 'idle',
						actions: 'updateData',
					},
					SELECT_CONTACT_REFERENCE: {
						target: 'loading',
						actions: ['saveData', 'updateDataOptimistic'],
					},
				},
			},
			loading: {
				on: {
					CART_UPDATED: {
						target: 'idle',
						actions: 'updateData',
					},
				},
			},
		},
		on: {
			RESET_TO_OLD: {
				target: 'idle',
				actions: 'resetToOldContactPerson',
			},
		},
	},
	{
		actions: {
			updateData: assign({
				contactReferences: (context, event) =>
					event?.value?.contactReferences ?? context?.contactReferences,
				selectedContactReference: (context, event) =>
					event?.value?.selectedContactReference ??
					context?.selectedContactReference,
				errors: (context, event) =>
					event?.value?.errorList?.filter(
						(error) =>
							error.type &&
							context.contactReferencesErrorTypes.includes(error.type),
					) ?? [],
			}),

			updateDataOptimistic: assign({
				selectedContactReference: (context, event) =>
					event?.value || context?.selectedContactReference,
				oldSelectedContactReference: (_, event) => event?.value,
			}),

			resetToOldContactPerson: assign({
				selectedContactReference: (context) =>
					context.oldSelectedContactReference,
			}),

			saveData: sendParent((_, event) => {
				const updateCartEvent: UpdateCartEvent = {
					type: 'UPDATE_CART',
					updatedCartProperties: { selectedContactReference: event.value },
				};
				return updateCartEvent;
			}),
		},
	},
);
export type ContactReferencesMachineActor = ActorRefFrom<
	typeof contactReferencesMachine
>;
