/**
 * CampaignPackage
 */

import React from 'react';

import ActionButton, { ActionButtonState } from 'components/ActionButton';
import Icon from 'components/Icon';
import Img from 'components/Img';
import Link from 'components/Link';
import Price from 'components/Price';
import Text from 'components/Text';
import type { CampaignResponse } from 'models/product';
import { getProductImageSrc } from 'utils/business-logic';
import { cnm } from 'utils/classNames';
import { formatDate } from 'utils/format';
import { useI18n } from 'utils/i18n';

interface Props
	extends Pick<CampaignResponse, 'price' | 'title' | 'validTo' | 'variants'> {
	addToCartButtonDisabled: boolean;
	addToCartButtonDisabledText: string;
	buttonState: ActionButtonState;
	className?: string;
	onAddToCartClick: (() => void) | undefined;
}

/** A campaign package with the products, package price and buy button. */
export default function CampaignPackage({
	addToCartButtonDisabled,
	addToCartButtonDisabledText,
	buttonState,
	className,
	onAddToCartClick,
	price,
	title,
	validTo,
	variants,
}: Props) {
	const { t } = useI18n();
	return (
		<div
			className={cnm('rounded-border border border-grey p-4 md:p-6', className)}
		>
			<Text as="h3" text={title} className="mb-4" />
			<div className="mb-4 flex flex-col justify-between gap-y-4">
				<div>
					{variants.map((variant) => (
						<p key={variant.id} className="flex items-center">
							<span className="mr-4 shrink-0 font-bold">
								{t('product_details_in_stock_label', {
									amount: variant.quantity,
								})}
							</span>
							<Link
								href={variant.url}
								className="flex items-center underline hover:no-underline"
							>
								<Img
									src={getProductImageSrc(variant.listImageFormats)}
									width={40}
									height={40}
									service="nextjs"
									useFallbackOnError
									className="mr-2 size-10 shrink-0"
								/>
								{variant.title}
							</Link>
						</p>
					))}
				</div>
				<Price
					price={price.displayValue}
					currency={price.displaySymbol}
					label={t('mix_and_match_fixed_price_label')}
					labelAlign="left"
					size="small"
				/>
			</div>
			{validTo && (
				<Text
					as="p"
					className="mb-4 text-julaRed"
					text={t('product_details_campaign_valid_to_text', {
						validTo: formatDate(validTo),
					})}
				/>
			)}
			{onAddToCartClick && (
				<>
					<ActionButton
						displayWidth="full"
						size="large"
						variant="cta"
						customState={buttonState}
						onClick={onAddToCartClick}
						disabled={addToCartButtonDisabled}
					>
						<Icon
							name={addToCartButtonDisabled ? 'cartDisabled' : 'cart'}
							className="mr-2"
						/>
						{t('product_details_buy_online_button')}
					</ActionButton>
					{addToCartButtonDisabled && addToCartButtonDisabledText && (
						<div className="mt-4 flex items-start">
							<Icon
								name="closeOutline"
								size={16}
								color="julaRed"
								className="mr-2 mt-0.75"
							/>
							<Text as="p" text={addToCartButtonDisabledText} />
						</div>
					)}
				</>
			)}
		</div>
	);
}
CampaignPackage.displayName = 'CampaignPackage';
