// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconAddPrint(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path
				fill="#0077c8"
				d="M4 4h4V2H4a1.93 1.93 0 0 0-1.41.59A1.93 1.93 0 0 0 2 4v4h2z"
			/>
			<path
				fill="#cb333b"
				d="M21.41 2.59A1.93 1.93 0 0 0 20 2h-4v2h4v4h2V4a1.93 1.93 0 0 0-.59-1.41"
			/>
			<path fill="#ff8200" d="M20 20h-4v2h4a2 2 0 0 0 2-2v-4h-2z" />
			<path
				fill="#509e2f"
				d="M4 16H2v4a1.92 1.92 0 0 0 .59 1.41A1.92 1.92 0 0 0 4 22h4v-2H4z"
			/>
			<path d="M17.56 6.44A1.44 1.44 0 0 0 16.5 6h-9A1.5 1.5 0 0 0 6 7.5v9A1.5 1.5 0 0 0 7.5 18h9a1.5 1.5 0 0 0 1.5-1.5v-9a1.44 1.44 0 0 0-.44-1.06M8.25 15l2.02-2.63 1.17 1.5 1.72-2.24L15.75 15zM15 10a1 1 0 1 1 1-1 1 1 0 0 1-1 1" />
		</svg>
	);
}
IconAddPrint.displayName = 'IconAddPrint';
