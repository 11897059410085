import React, { type LabelHTMLAttributes, type ReactNode } from 'react';

import { cnm } from 'utils/classNames';

import LabelText from './LabelText';

interface Props extends LabelHTMLAttributes<HTMLLabelElement> {
	children: ReactNode;
	className?: string;
	htmlFor: string;
	invalid?: boolean;
	isHidden?: boolean;
	isValid?: boolean;
	required?: boolean;
}

/**
 * A regular form field label.
 */
export default function InlineLabel({
	children,
	className,
	htmlFor,
	invalid = false,
	isHidden = false,
	isValid = false,
	required = false,
}: Props) {
	return (
		<label
			htmlFor={htmlFor}
			className={cnm(
				'inline-block',
				isHidden && 'sr-only',

				// Color
				'text-greyDark',
				invalid && 'text-julaRed',
				isValid && 'text-success',

				className,
			)}
		>
			<LabelText required={required}>{children}</LabelText>
		</label>
	);
}
InlineLabel.displayName = 'InlineLabel';
