import React, { useState } from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import { LayoutContainer } from 'components/Layout';
import ProductArchiveSearchBlock, {
	EMPTY_SELECT_VALUE,
} from 'components/ProductArchiveSearch';
import ProductPopover from 'components/ProductPopover';
import {
	useProductArchiveSearch,
	useProductVariant,
	useRelatedProducts,
} from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import { FacetResponse } from 'models/api';
import { getEditorMargin, getProductImageSrc } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';
import { ignorePromiseRejection, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Category {
	categories?: Category[];
	id: string;
	name: string;
}
interface SortOption {
	attribute: string;
	default: boolean;
	name: string;
}
type Props = JulaComponentProps & {
	fields?: {
		categories: Category[];
		category1Selector: Field<string>;
		category2Selector: Field<string>;
		category3Selector: Field<string>;
		category4Selector: Field<string>;
		sortOptions: SortOption[];
	};
};

function mapCategoryOptions(
	defaultValue: string,
	categories: Category[] | undefined,
) {
	const categoryOptions = [{ label: defaultValue, value: EMPTY_SELECT_VALUE }];
	if (!categories) {
		return categoryOptions;
	}
	categories.forEach((category) => {
		categoryOptions.push({
			label: category.name,
			value: category.id,
		});
	});
	return categoryOptions;
}

const DEFAULT_FACET_SELECT_VALUE = 'default';

function mapFacetOptions(facet: FacetResponse | undefined) {
	if (!facet) {
		return [];
	}
	const { facetItems } = facet;
	const facetOptions = [
		{ label: facet?.title, value: DEFAULT_FACET_SELECT_VALUE },
	];
	if (!facetItems || !is.arrayWithLength(facetOptions)) {
		return facetOptions;
	}
	facetItems.forEach((facetItem) => {
		facetOptions.push({
			label: facetItem.item,
			value: facetItem.item,
		});
	});
	return facetOptions;
}

export default function ProductArchiveSearch({ fields, params }: Props) {
	const { t } = useI18n();
	const [collection, setCollection] = useState<Category | undefined>(undefined);
	const [category, setCategory] = useState<Category | undefined>(undefined);
	const [subCategory, setSubCategory] = useState<Category | undefined>(
		undefined,
	);
	const [query, setQuery] = useState('');

	const [selectedBrandFacet, setSelectedBrandFacet] = useState(
		DEFAULT_FACET_SELECT_VALUE,
	);
	const [sortOption, setSortOption] = useState('');
	const [selectedCategoryFacet, setSelectedCategoryFacet] = useState<string>(
		DEFAULT_FACET_SELECT_VALUE,
	);

	const resetFilteringAndSorting = () => {
		setSelectedCategoryFacet(DEFAULT_FACET_SELECT_VALUE);
		setSelectedBrandFacet(DEFAULT_FACET_SELECT_VALUE);
		setSortOption('');
	};

	const {
		facets,
		hasNextPage,
		isLoading,
		isLoadingMore,
		items,
		loadMore,
		total,
	} = useProductArchiveSearch(
		{
			searchString: query,
			categoryId: subCategory?.id,
			Brand:
				selectedBrandFacet === DEFAULT_FACET_SELECT_VALUE
					? undefined
					: selectedBrandFacet,
			sortOption,
			Category:
				selectedCategoryFacet === DEFAULT_FACET_SELECT_VALUE
					? undefined
					: selectedCategoryFacet,
		},
		Boolean(query || subCategory),
	);

	const brandFacet = facets?.find((facet) => facet.facetType === 'Brand');
	const categoryFacet = facets?.find((facet) => facet.facetType === 'Category');
	const brandFacetOptions = mapFacetOptions(brandFacet);
	const categoryFacetOptions = mapFacetOptions(categoryFacet);

	// ProductArchiveSearchItemPopover
	const [productVariantId, setProductVariantId] = useState('');
	const { data: productVariant, isLoading: isLoadingProductVariant } =
		useProductVariant(productVariantId);
	const {
		hasNextPage: productVariantSparePartsHasNextPage,
		isLoading: isLoadingProductVariantSpareParts,
		isLoadingMore: isLoadingMoreProductVariantSpareParts,
		items: productVariantSpareParts,
		loadMore: loadMoreProductVariantSpareParts,
	} = useRelatedProducts({
		variantId: productVariantId,
		relationType: 'SpareParts',
		isActive: Boolean(productVariantId),
	});

	const selectedProduct = productVariantId
		? items.find((item) => item.id === productVariantId)
		: undefined;

	if (!fields) {
		return null;
	}

	return (
		<LayoutContainer outerClassName={getEditorMargin(params)}>
			<ProductArchiveSearchBlock
				collectionSelectLabel={fields.category1Selector.value}
				categorySelectLabel={fields.category2Selector.value}
				subCategorySelectLabel={fields.category3Selector.value}
				collectionOption={collection?.id ?? EMPTY_SELECT_VALUE}
				collectionOptions={mapCategoryOptions(
					fields.category1Selector.value,
					fields.categories,
				)}
				onCollectionOptionChange={(option) => {
					const categoryOptions = fields.categories.find(
						(collectionCategory) => collectionCategory.id === option,
					);
					setCollection(categoryOptions);
					setCategory(undefined);
					setSubCategory(undefined);
				}}
				categorySelectDisabled={!collection}
				categoryOption={category?.id ?? EMPTY_SELECT_VALUE}
				categoryOptions={mapCategoryOptions(
					fields.category2Selector.value,
					collection?.categories,
				)}
				onCategoryOptionChange={(option) => {
					const categoryOptions = collection?.categories?.find(
						(categoryCategory) => categoryCategory.id === option,
					);
					setCategory(categoryOptions);
					setSubCategory(undefined);
				}}
				subCategoryOption={subCategory?.id ?? EMPTY_SELECT_VALUE}
				subCategoryOptions={mapCategoryOptions(
					fields.category3Selector.value,
					category?.categories,
				)}
				subCategorySelectDisabled={!category}
				onSubCategoryOptionChange={(option) => {
					const categoryOptions = category?.categories?.find(
						(subCategoryCategory) => subCategoryCategory.id === option,
					);
					setSubCategory(categoryOptions);
					setQuery('');
					resetFilteringAndSorting();
				}}
				query={query}
				onSearchSubmit={({ search }) => {
					setQuery(search);
					setCollection(undefined);
					setCategory(undefined);
					setSubCategory(undefined);
					resetFilteringAndSorting();
				}}
				hasNextPage={hasNextPage}
				isLoadingMoreProducts={Boolean(isLoadingMore)}
				isLoadingProducts={isLoading}
				categoryFacetSelectLabel={categoryFacet?.title ?? ''}
				categoryFacetOption={selectedCategoryFacet}
				categoryFacetOptions={categoryFacetOptions}
				onCategoryFacetOptionChange={(option) => {
					setSelectedCategoryFacet(option);
				}}
				brandFacetSelectLabel={brandFacet?.title ?? ''}
				brandFacetOption={selectedBrandFacet}
				brandFacetOptions={brandFacetOptions}
				onBrandFacetOptionChange={(option) => {
					setSelectedBrandFacet(option);
				}}
				onLoadMoreProductsClick={() => {
					ignorePromiseRejection(loadMore());
				}}
				onProductClick={(id) => {
					setProductVariantId(id);
				}}
				selectedCategoryName={subCategory?.name}
				products={filterTruthy(
					items?.map((product) => ({
						brand: product.brand?.name ?? '—',
						id: product.id,
						imgSrc: getProductImageSrc(product, true),
						title: product.title,
					})),
					'id',
					'title',
				)}
				sortingOption={
					sortOption ||
					fields.sortOptions.find((option) => option.default)?.attribute ||
					''
				}
				sortingOptions={fields.sortOptions.map((option) => ({
					label: option.name,
					value: option.attribute,
				}))}
				onSortingOptionChange={(option) => {
					setSortOption(option);
				}}
				toResultsLinkVisible={Boolean(subCategory)}
				totalProductsCount={total}
				visibleProductsCount={items.length}
			/>
			<ProductPopover
				title={
					selectedProduct?.title ||
					t('product_archive_search_item_popover_heading')
				}
				variant="ProductArchiveSearch"
				isLoadingProduct={isLoadingProductVariant}
				isOpen={Boolean(productVariantId)}
				onClose={() => {
					setProductVariantId('');
				}}
				onSparePartsLoadMore={() => {
					ignorePromiseRejection(loadMoreProductVariantSpareParts());
				}}
				product={productVariant}
				spareParts={productVariantSpareParts}
				sparePartsHasNextPage={productVariantSparePartsHasNextPage}
				sparePartsIsLoading={isLoadingProductVariantSpareParts}
				sparePartsIsLoadingMore={Boolean(isLoadingMoreProductVariantSpareParts)}
			/>
		</LayoutContainer>
	);
}
ProductArchiveSearch.displayName = 'ProductArchiveSearch';
