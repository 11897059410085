import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlockTeaserCarousel from 'components/BlockTeaserCarousel';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import type { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import type { SitecoreLink } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy, withTruthyProps } from 'utils/collection';
import { getAsset } from 'utils/helpers';

interface Item {
	campaignBubbleLabel?: Field<string>;
	campaignBubbleText?: Field<string>;
	heading?: Field<string>;
	link?: Field<SitecoreLink>;
	squareImage?: Field<DigizuiteAsset[]>;
}

type Props = JulaComponentProps & {
	fields?: {
		heading?: Field<string>;
		items?: Item[];
	};
};

export default function TeaserCarousel({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const items = filterTruthy(
		(fields.items ?? []).map((item) => ({
			campaignBubbleLabel: item.campaignBubbleLabel?.value,
			campaignBubbleText: item.campaignBubbleText?.value,
			heading: item.heading,
			image: getAsset('Templated 1:1', item.squareImage),
			link: withTruthyProps(item.link?.value, 'href', 'text'),
		})),
		'link',
		'image',
	);

	if (items.length === 0) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="TeaserCarousel"
				description="Requires at least one item with a heading, link and image"
			/>
		);
	}

	return (
		<BlockTeaserCarousel
			className={getEditorMargin(params)}
			heading={fields.heading}
			headingLevel={params?.heading}
			id={params?.anchor}
			items={items}
		/>
	);
}
TeaserCarousel.displayName = 'TeaserCarousel';
