import React from 'react';
import { useRouter } from 'next/router';

import InfoBox from 'components/InfoBox';
import { LayoutContainer } from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import OrderConfirmationIntro from 'components/OrderConfirmationIntro';
import { ConfirmationSummary } from 'components/Summary';
import { useGlobalMachinesContext } from 'contexts';
import { useEffectOnce, useOrderInformation } from 'hooks';
import { ignorePromiseRejection, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

export default function OrderConfirmation() {
	const router = useRouter();
	const { cartService } = useGlobalMachinesContext();
	useEffectOnce(() => {
		cartService.send('GET_CART');
	});
	const { t } = useI18n();
	const orderId = is.arrayWithLength(router?.query.orderId)
		? router?.query.orderId[0]
		: router?.query.orderId;
	const { error, isLoading, orderInformation } = useOrderInformation(orderId);
	const {
		bonusDiscounts,
		email,
		giftCards,
		orderNumber,
		savingSummaries,
		totalSummary,
		variants,
	} = orderInformation ?? {};

	const savingTotalSumSummary = savingSummaries?.find(
		(summary) => summary.sumType === 'SavingTotalSum',
	);
	useEffectOnce(() => {
		router.beforePopState(() => {
			ignorePromiseRejection(router.push('/Cart'));
			return false;
		});
		window?.scrollTo(0, 0);
		return () => {
			router.beforePopState(() => true);
		};
	});

	return (
		<LayoutContainer maxWidth="extraNarrow" outerClassName="mt-8 md:mt-12">
			{isLoading && <LoadingSpinner className="mx-auto" />}
			{error && (
				<InfoBox icon="error" variant="error">
					{t('order_confirmation_failure_loading_order')}
				</InfoBox>
			)}
			{!isLoading && !error && (
				<>
					<OrderConfirmationIntro
						className="mb-14 md:mb-20"
						heading={t('order_confirmation_heading', {
							firstName: orderInformation?.deliveryAddress?.firstName,
						})}
						orderNumber={orderNumber}
						email={email}
					/>
					<ConfirmationSummary
						heading={t('order_confirmation_order_summary_heading')}
						totalSummary={totalSummary}
						giftCards={giftCards}
						bonusDiscounts={bonusDiscounts}
						savingTotalSumSummary={savingTotalSumSummary}
						variants={variants}
					/>
				</>
			)}
		</LayoutContainer>
	);
}
OrderConfirmation.displayName = 'OrderConfirmation';
