/**
 * Button
 */

import React from 'react';
import type { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';

import LinkOrButton from 'components/LinkOrButton';
import { cnm } from 'utils/classNames';
import { is } from 'utils/helpers';

export type ButtonSize = 'xSmall' | 'small' | 'medium' | 'large' | 'xl';
export type ButtonVariant =
	| 'primary'
	| 'secondary'
	| 'cta'
	| 'text'
	| 'text-inline';
export type ButtonDisplayWidth = 'dynamic' | 'full';

export interface BaseProps {
	/** Extra class names */
	className?: string;

	/** Should the button be disabled */
	disabled?: boolean;

	/** Button width */
	displayWidth?: ButtonDisplayWidth;

	/** If the button's hit area should be invisibly expanded outside its borders. */
	hasExpandedHitArea?: boolean;

	/** If the button should be fully rounded */
	rounded?: boolean;

	/** Button size */
	size?: ButtonSize;

	/** If the text variant should have an underline */
	underline?: boolean;

	/** Button style variant */
	variant?: ButtonVariant;
}

type AnchorAttrs = AnchorHTMLAttributes<HTMLAnchorElement>;
type ButtonAttrs = ButtonHTMLAttributes<HTMLButtonElement>;

export interface AnchorProps extends BaseProps, AnchorAttrs {
	type?: never;

	/** Link URL, results in an anchor instead of a button */
	href: string;

	/** Link target */
	target?: AnchorAttrs['target'];
}

export interface ButtonProps extends BaseProps, ButtonAttrs {
	href?: never;

	/** Button click handler, required for non-submit button */
	onClick: NonNullable<ButtonAttrs['onClick']>;

	target?: never;
}

export interface SubmitProps extends BaseProps, ButtonAttrs {
	type: 'submit';

	href?: never;

	/** Button click handler, optional for submit button */
	onClick?: ButtonAttrs['onClick'];

	target?: never;
}

export type Props = AnchorProps | ButtonProps | SubmitProps;

/**
 * Button in different variants and sizes.
 *
 * Requires different props depending on the kind of button:
 * - `href` for links.
 * - `onClick` for 'button buttons'.
 * - `type="submit"` for submit buttons.
 */
const Button = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, Props>(
	(
		{
			children,
			className = '',
			disabled = false,
			displayWidth = 'dynamic',
			hasExpandedHitArea = false,
			href,
			onClick,
			rounded = false,
			size = 'medium',
			target,
			type = 'button',
			underline = true,
			variant = 'secondary',
			...attrs
		},
		ref,
	) => {
		const isTextButton = is.oneOf(variant, 'text', 'text-inline');
		return (
			<LinkOrButton
				{...attrs}
				ref={ref}
				href={href}
				disabled={disabled}
				type={type}
				target={target}
				onClick={onClick}
				className={cnm(
					!rounded && '[&>svg:first-child]:mr-2',
					rounded ? 'rounded-full' : 'rounded-button py-1',
					displayWidth === 'full' && 'w-full',

					'font-standard',
					size === 'xSmall' && 'text-sm',
					size === 'small' && 'text-sm',
					size === 'medium' && 'text-base',
					size === 'large' && 'text-base',
					size === 'xl' && 'text-base',

					!isTextButton && [
						displayWidth === 'dynamic' ? 'inline-flex' : 'flex',
						'items-center justify-center',
						'font-bold',

						// Forced colors mode
						'border border-transparent',
						!disabled && 'forced-colors:hover:outline',

						size === 'xSmall' && [
							'min-h-[1.5rem]',
							rounded ? 'min-w-[1.5rem] px-2' : 'px-3',
						],
						size === 'small' && [
							'min-h-[2rem]',
							rounded ? 'min-w-[2rem] px-2' : 'px-4',
						],
						size === 'medium' && [
							'min-h-[3rem]',
							rounded ? 'min-w-[3rem] px-3' : 'px-5',
						],
						size === 'large' && [
							'min-h-[3.5rem]',
							rounded ? 'min-w-[3.5rem] px-4' : 'px-6',
						],
						size === 'xl' && [
							'min-h-[4rem]',
							rounded ? 'min-w-[4rem] px-5' : 'px-7',
						],
					],

					disabled && [
						'text-grey',
						'opacity-50',
						!isTextButton && 'bg-greyLight',
					],

					variant === 'secondary' && [
						!disabled &&
							'border-greyDark bg-white text-greyDarker hover:border-greyDarker hover:outline hover:outline-1 hover:outline-greyDarker',
					],

					variant === 'primary' && [
						!disabled && 'bg-julaRed text-white hover:bg-julaRedDark',
					],

					variant === 'cta' && [
						!disabled && 'bg-cta text-white hover:bg-ctaDarker',
					],

					isTextButton && [
						variant === 'text' && 'inline-flex items-center',
						variant === 'text-inline' && 'inline-block text-left',
						'text-inherit',
						size === 'xSmall' && 'min-h-[1.5rem]',
						size !== 'xSmall' && 'min-h-[2rem]',
						underline && 'underline hover:no-underline',
						displayWidth === 'full' && 'justify-center',
					],

					// Only expand vertically, most buttons should naturally be wide enough.
					hasExpandedHitArea &&
						'relative after:absolute after:inset-x-0 after:-bottom-2 after:-top-2',

					className,
				)}
			>
				{children}
			</LinkOrButton>
		);
	},
);
Button.displayName = 'Button';

export default Button;
