import { useCallback, useState } from 'react';

import type { StoredFileResponse } from 'models/api';
import { fetchResult } from 'utils/fetch';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

export function useFileUpload({ url }: { url: string }) {
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState<string[] | undefined>(undefined);
	const [uploadedFiles, setUploadedFiles] = useState<StoredFileResponse[]>([]);
	const { t } = useI18n();

	const uploadFile = useCallback(
		async (file: File | undefined) => {
			if (!file) {
				return;
			}
			const formData = new FormData();
			formData.append('file', file);
			setIsLoading(true);

			const res = await fetchResult<StoredFileResponse>(url, {
				method: 'POST',
				body: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
					'accept': 'application/json',
				},
			});
			setIsLoading(false);
			if (res.isSuccess() && res.value) {
				setUploadedFiles([res.value]);
			} else if (res.isFailure('ResponseError') && res.error.status === 413) {
				setErrors([t('FileSizeTooLarge')]);
			} else if (res.isFailure('ValidationError')) {
				setErrors(
					[
						...(Object.values<string[]>(res.error.fieldErrors).flat() ?? []),
						...(res.error.generalErrors?.map((error) => t(error.key)) ?? []),
					].filter(is.truthy),
				);
			} else {
				setErrors([t('UnknownError')]);
			}
		},
		[t, url],
	);

	return {
		uploadFile,
		uploadedFiles,
		isLoading,
		errors,
		hasError: is.arrayWithLength(errors),
	};
}
