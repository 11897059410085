import React, { type ReactNode } from 'react';

import Status, { type TextSize } from 'components/Status';
import { cn } from 'utils/classNames';

interface Props {
	className?: string;
	inStock: boolean;
	text: ReactNode;
	textSize?: TextSize;
}

export default function StockStatus({
	className,
	inStock,
	text,
	textSize,
}: Props) {
	return (
		<Status
			color={inStock ? 'green' : 'red'}
			className={cn('flex', className)}
			text={text}
			textSize={textSize}
		/>
	);
}
StockStatus.displayName = 'StockStatus';
