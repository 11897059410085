/**
 * PopularSearchTerms
 */

import React from 'react';
import type { HTMLAttributes, KeyboardEventHandler, RefObject } from 'react';

import Chip from 'components/Chip';
import Icon from 'components/Icon';
import Link from 'components/Link';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { usePopularSearchTerms } from 'hooks';
import { cn } from 'utils/classNames';
import { range } from 'utils/helpers';

type SearchTermsVariant = 'chips' | 'iconLinks';

interface Props extends HTMLAttributes<HTMLDivElement> {
	/** Heading text. */
	heading?: string;

	/** Only display up to this number of links. */
	itemLimit?: number;

	/** Ref for the last term link. */
	lastLinkRef?: RefObject<HTMLAnchorElement>;

	/** Tab key handler for the last term link. */
	onLastLinkTab?: KeyboardEventHandler<HTMLAnchorElement>;

	/** Style for the search term links. */
	variant: SearchTermsVariant;
}

interface ListProps extends Props {
	terms: string[];
}

export function PopularSearchTermsList({
	heading,
	itemLimit,
	lastLinkRef,
	onLastLinkTab,
	terms,
	variant,
	...props
}: ListProps) {
	const visibleTerms = terms.slice(0, itemLimit || 999);
	const hasTerms = visibleTerms.length > 0;
	const lastTermIndex = visibleTerms.length - 1;

	const handleLastLinkKeyDown: KeyboardEventHandler<HTMLAnchorElement> = (
		e,
	) => {
		if (onLastLinkTab && e.key === 'Tab' && !e.shiftKey) {
			onLastLinkTab(e);
		}
	};

	const items = visibleTerms.map((term, i) => ({
		href: `/search/?query=${term}`,
		text: term,
		ref: i === lastTermIndex ? lastLinkRef : undefined,
		onKeyDown: i === lastTermIndex ? handleLastLinkKeyDown : undefined,
	}));

	const itemMarginClasses = cn(
		'first:mt-0',
		variant === 'chips' && 'mt-2 md:mt-4',
		variant === 'iconLinks' && 'mt-6 md:mt-4',
	);

	return (
		<div {...props}>
			{heading && (
				<Text as="h3" className="mb-4 sm:mb-6">
					{heading}
				</Text>
			)}
			{hasTerms && (
				<ul>
					{items.map(({ href, onKeyDown, ref, text }) => (
						<li key={text} className={itemMarginClasses}>
							{variant === 'chips' && (
								<Chip
									ref={ref}
									color="grey"
									href={href}
									onKeyDown={onKeyDown}
									text={text}
								/>
							)}
							{variant === 'iconLinks' && (
								<Link
									href={href}
									ref={ref}
									onKeyDown={onKeyDown}
									className="group/popular-term inline-flex items-center align-top"
								>
									<Icon name="search" className="mr-2" />
									<span className="group-hover/popular-term:underline">
										{text}
									</span>
								</Link>
							)}
						</li>
					))}
				</ul>
			)}
			{!hasTerms && (
				<Skeleton>
					{range(itemLimit || 5).map((i) => (
						<SkeletonItem
							key={i}
							width={`${50 + (7 % (i + 1)) * 7}px`}
							height={variant === 'chips' ? '2.5rem' : '24px'}
							className={itemMarginClasses}
						/>
					))}
				</Skeleton>
			)}
		</div>
	);
}
PopularSearchTermsList.displayName = 'PopularSearchTermsList';

/** List of X popular search terms, optinally with a heading. */
export default function PopularSearchTerms({ itemLimit, ...props }: Props) {
	const { terms } = usePopularSearchTerms({ itemLimit });

	return <PopularSearchTermsList terms={terms} {...props} />;
}
PopularSearchTerms.displayName = 'PopularSearchTerms';
