/**
 * RichText
 */

import React, { useId } from 'react';
import { RichText as SitecoreRichText } from '@sitecore-jss/sitecore-jss-nextjs';
import type { RichTextField } from '@sitecore-jss/sitecore-jss-react';

import { useEffectOnce } from 'hooks';
import { cn } from 'utils/classNames';
import { parseMarkdown } from 'utils/string';

interface BaseProps {
	checkMarkList?: boolean;
	className?: string;
	inheritStyle?: boolean;
	onlyInternalMargin?: boolean;
	tightSpacing?: boolean;
}
interface PropsWithField extends BaseProps {
	field: RichTextField | undefined;
	html?: never;
	markdown?: never;
}
interface PropsWithHtml extends BaseProps {
	field?: never;
	html: string | undefined;
	markdown?: never;
}
interface PropsWithMarkdown extends BaseProps {
	field?: never;
	html?: never;
	markdown: string | undefined;
}
type Props = PropsWithField | PropsWithHtml | PropsWithMarkdown;

/** Wrapper for Sitecore's rich text compnent that makes sure that internalLinksSelector and styling is consistent for places where richtext is used. */
export default function RichText({
	checkMarkList = false,
	className,
	field,
	html,
	inheritStyle,
	markdown,
	onlyInternalMargin = true,
	tightSpacing = false,
}: Props) {
	const uniqueId = useId();
	const id = `richtext${uniqueId}`;
	const classes = cn(
		'richtext',
		onlyInternalMargin && 'richtext--onlyInternalMargin',
		tightSpacing && 'richtext--tightSpacing',
		checkMarkList && 'richtext--checkMarkList',
		inheritStyle && 'richtext--inherit',
		className,
	);

	// Move each table into a wrapper.
	useEffectOnce(() => {
		// Would prefer selecting from a ref instead of globally with ID but
		// Sitecore's RichText sets its own ref, ignoring any ref passed via props.
		// Using querySelector requires escaping due to useId generating IDs with
		// colon so use getElementById instead.

		const root = document.getElementById(id);
		if (!root) {
			return;
		}
		root
			.querySelectorAll<HTMLTableElement>('table:not(.has-wrapper)')
			.forEach((table) => {
				const wrapper = document.createElement('div');
				wrapper.classList.add('table-scrollable');
				table.classList.add('has-wrapper');
				table.before(wrapper);
				wrapper.append(table);
			});
	});

	if (field !== undefined) {
		return (
			<SitecoreRichText
				id={id}
				className={classes}
				field={field}
				internalLinksSelector='a[href^="/"]:not([target="_blank"])'
				prefetchLinks={false}
			/>
		);
	}
	const htmlString = markdown ? parseMarkdown(markdown) : html;

	if (!htmlString) {
		return null;
	}

	return (
		<div
			id={id}
			className={classes}
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{ __html: htmlString }}
		/>
	);
}
RichText.displayName = 'RichText';
