import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import { usePriceContext } from 'contexts';
import { useShippingUsps } from 'hooks';
import type { VariantId } from 'models/product';

interface UspContextType {
	isLoadingShippingUsps: boolean;
	shippingUsps: string[] | undefined;
}

const UspContext = createContext<UspContextType | undefined>(undefined);

interface Props {
	children: ReactNode;
	variantId: VariantId;
}

export function UspContextProvider({ children, variantId }: Props) {
	const { usePriceExcVat } = usePriceContext();

	const { isLoading: isLoadingShippingUsps, usps } = useShippingUsps(variantId);

	const shippingUsps = usePriceExcVat
		? usps?.shippingUspsExcVat
		: usps?.shippingUspsIncVat;

	const value = useMemo(
		() => ({
			isLoadingShippingUsps,
			shippingUsps,
		}),
		[isLoadingShippingUsps, shippingUsps],
	);

	return <UspContext.Provider value={value}>{children}</UspContext.Provider>;
}
UspContextProvider.displayName = 'UspContextProvider';

export function useUspContext(): UspContextType {
	const context = useContext(UspContext);

	if (context === undefined) {
		throw new Error('useUspContext must be used within a UspContextProvider');
	}

	return context;
}
