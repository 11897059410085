import React from 'react';

import Button from 'components/Button';
import Img from 'components/Img';

interface Props {
	buttonText: string;
	onClick: () => void;
	texts?: (string | undefined)[];
	title: string;
}

export default function JulaProInfo({
	buttonText,
	onClick,
	texts,
	title,
}: Props) {
	return (
		<>
			<Img
				src="/assets/images/graphic-congrats-julapro.png"
				width={156}
				height={80}
				className="mx-auto mb-6"
			/>
			<p className="mb-4 font-bold">{title}</p>
			{texts?.filter(Boolean).map((text) => (
				<p key={text} className="mt-4">
					{text}
				</p>
			))}
			<Button
				variant="primary"
				displayWidth="full"
				className="mt-6"
				onClick={onClick}
			>
				{buttonText}
			</Button>
		</>
	);
}
JulaProInfo.displayName = 'AccountJulaProSignupForm_JulaProInfo';
