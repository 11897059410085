/**
 * Delivery
 */

import React from 'react';
import { useSelector } from '@xstate/react';
import { waitFor } from 'xstate/lib/waitFor';

import { Select } from 'components/FormUi';
import InfoBox, { InfoboxVariants } from 'components/InfoBox';
import { SelectBox } from 'components/SelectBox';
import Text from 'components/Text';
import { useCheckoutContext } from 'contexts';
import type { CartError, DeliveryMethodId, DeliveryPickup } from 'models/api';
import { selectDeliveryActor } from 'state-machines/checkout';
import {
	selectAvailableDeliveryMethods,
	selectErrors,
	selectSelectedDeliveryMethod,
} from 'state-machines/checkout/delivery';
import { filterTruthy } from 'utils/collection';
import { getTestDataAttrFrom, ignorePromiseRejection, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';
import { slugify } from 'utils/string';

import PickupLocationPopover from './PickupLocationPopover';

type GetErrorBoxProps = (error: CartError) => {
	error: CartError;
	icon: string;
	variant: InfoboxVariants;
} | null;

/** Helper to get error box props */
const getErrorBoxProps: GetErrorBoxProps = (error) => {
	switch (error.type) {
		case 'DeliveryMethodsRestricted_Dimensions':
			return { icon: 'info', variant: 'information', error };
		case 'DeliveryMethodInvalid':
			return { icon: 'error', variant: 'error', error };
		case 'DeliveryMethodNotSelected':
			return { icon: 'error', variant: 'error', error };
		case 'DeliveryMethodsRestricted_PostalCodeNotExist':
			return { icon: 'error', variant: 'error', error };
		default:
			return null;
	}
};
function getDeliveryOptions(options: DeliveryPickup[] | undefined) {
	const filteredOptions = filterTruthy(options || [], 'id', 'pickupLocation');
	const deliveryOptions = [{ value: '', label: '' }];
	filteredOptions.forEach((option) => {
		deliveryOptions.push({
			value: option.id,
			label: option.pickupLocation,
		});
	});
	return deliveryOptions;
}

interface Props {
	className?: string;
	onDeliveryMethodChange: () => void;
}

/** Component for delivery methods, uses the deliveryActor from the checkout machine. */
export default function Delivery({ className, onDeliveryMethodChange }: Props) {
	const { t } = useI18n();
	const { checkoutService } = useCheckoutContext();

	const deliveryActor = useSelector(checkoutService, selectDeliveryActor);
	const { send } = deliveryActor;
	const availableDeliveryMethods = useSelector(
		deliveryActor,
		selectAvailableDeliveryMethods,
	);
	const selectedDeliveryMethod = useSelector(
		deliveryActor,
		selectSelectedDeliveryMethod,
	);
	const errors = useSelector(deliveryActor, selectErrors);
	const extendedShippingTimeForCustomizationMessage = errors?.find(
		(error) => error.type === 'ExtendedShippingTimeForCustomization',
	)?.description;

	const errorData = errors
		.filter(
			(error) =>
				error.type === 'DeliveryMethodNotSelected' ||
				error.type === 'DeliveryMethodInvalid' ||
				error.type === 'DeliveryMethodsRestricted_Dimensions' ||
				error.type === 'DeliveryMethodsRestricted_PostalCodeNotExist',
		)
		.map((error) => ({
			...error,
			...getErrorBoxProps(error),
		}));

	/** Handle change of delivery method */
	const deliveryMethodOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const deliveryPickupId =
			selectedDeliveryMethod?.deliveryPickupId &&
			selectedDeliveryMethod?.deliveryMethodId === e.target.value
				? selectedDeliveryMethod?.deliveryPickupId
				: '';
		onDeliveryMethodChange();
		send({
			type: 'SELECT_DELIVERY_METHOD',
			value: {
				deliveryMethodId: e.target.value as DeliveryMethodId,
				deliveryPickupId,
			},
		});
	};

	/** Handle change of pickup location */
	const pickupLocationOnChange = async (deliveryPickupId: string) => {
		await waitFor(deliveryActor, (state) => state.matches('idle'), {
			timeout: 20_000,
		});
		if (selectedDeliveryMethod) {
			onDeliveryMethodChange();
			send({
				type: 'SELECT_DELIVERY_PICKUP',
				value: {
					deliveryMethodId: selectedDeliveryMethod.deliveryMethodId,
					deliveryPickupId,
				},
			});
		}
	};

	return (
		<div className={className}>
			<Text as="h2" className="mb-4 font-bold">
				{t('checkout_delivery_method_heading')}
			</Text>
			{extendedShippingTimeForCustomizationMessage && (
				<InfoBox icon="info" variant="information" className="mb-4">
					{extendedShippingTimeForCustomizationMessage}
				</InfoBox>
			)}
			<div className="flex flex-col gap-2 md:gap-4">
				{availableDeliveryMethods.map(
					(
						{
							description,
							id,
							name,
							pickupLocations,
							price,
							primaryLogos,
							secondaryLogos,
						},
						i,
					) => (
						<SelectBox
							key={slugify(`${id}-${name}`)}
							id={`delivery-${id}-${i}`}
							name="deliveryMethod"
							primaryImages={primaryLogos?.filter(is.truthy)}
							secondaryImages={secondaryLogos?.filter(is.truthy)}
							imagesWidthType="wide"
							value={id || name || ''}
							heading={name || ''}
							imagesBelowTextOnSmallScreen
							description={description}
							onChange={deliveryMethodOnChange}
							price={price}
							isSelected={selectedDeliveryMethod?.deliveryMethodId === id}
							dataCy={getTestDataAttrFrom(slugify(name || ''))}
						>
							{pickupLocations && pickupLocations.length > 0 && (
								<>
									<Select
										className="relative z-5 mt-3"
										id="deliveryOptions"
										name="deliveryOptions"
										label={
											id === 'PAS' || id === 'COC'
												? t('checkout_choose_warehouse_select_label')
												: t('checkout_choose_dealivery_agent_select_label')
										}
										value={selectedDeliveryMethod?.deliveryPickupId}
										onChange={(e) => {
											ignorePromiseRejection(
												pickupLocationOnChange(e.target.value),
											);
										}}
										options={getDeliveryOptions(pickupLocations)}
										data-cy={getTestDataAttrFrom('pickup-selection-select')}
									/>
									<PickupLocationPopover
										className="relative z-5"
										pickupLocations={pickupLocations}
										selectedPickupLocationId={
											selectedDeliveryMethod?.deliveryPickupId
										}
										onSelectCallback={(locationId) =>
											ignorePromiseRejection(pickupLocationOnChange(locationId))
										}
									/>
								</>
							)}
						</SelectBox>
					),
				)}
			</div>
			{errorData.map((data) => (
				<div key={`${data.variant}-${data.description}`} className="mt-4">
					<InfoBox
						icon={data.icon}
						variant={data.variant}
						message={data.description}
					/>
				</div>
			))}
		</div>
	);
}
Delivery.displayName = 'Delivery';
