import {
	Cart,
	type CartAddress,
	CartRequest,
	type ContactReference,
	type DeliveryOption,
	type GiftCard,
	type PaymentOption,
	type UnregisteredCompanyInfo,
} from 'models/api';
import { filterObject } from 'utils/collection';
import { is } from 'utils/helpers';

/** Helper function to extract existing discount codes and merge old and new ones, remove ones existing
 * in both existing and incoming, add incoming ones. */
export function getDiscountCodes(
	cart: Cart,
	updateCartProperties?: UpdatableCartProperties,
): string[] {
	let existingCodes = [
		cart.freightDiscount?.checkNumber,
		cart.campaignDiscount?.checkNumber,
		...(cart.bonusDiscounts?.map((b) => b.checkNumber) ?? []),
	];

	[
		updateCartProperties?.cartDiscountCode,
		...(updateCartProperties?.bonusDiscounts ?? []),
	].forEach((checkNum) => {
		existingCodes = existingCodes.includes(checkNum)
			? existingCodes.filter((checkNr) => checkNr !== checkNum)
			: [...existingCodes, checkNum];
	});

	return existingCodes.filter(is.truthy);
}

interface UpdatableCartProperties {
	allowAnonymousReviews?: boolean;
	alternativeDeliveryAddress?: boolean;
	bonusDiscounts?: string[];
	// A freight, campaign discount code added in the cart
	cartDiscountCode?: string;
	customerAddress?: CartAddress;
	deliveryAddress?: CartAddress;
	email?: string;
	giftCards?: GiftCard[];
	selectedContactReference?: ContactReference;
	selectedDelivery?: DeliveryOption;
	selectedPayment?: PaymentOption;
	telephoneNumber?: string;
	unregisteredCompanyInfo?: UnregisteredCompanyInfo;
}

/** Returns a Cart object formatted for request (removes unnecessary attributes). */
export function formatCartForRequest(
	cart: Cart,
	updateCartProperties?: UpdatableCartProperties,
): CartRequest {
	return filterObject(
		{
			variants: cart.variants?.map((variant) => ({
				variantId: variant.id,
				quantity: variant.qty,
				customizationId: variant.customization?.id,
				metaData: variant.metaData,
			})),
			selectedContactReferenceId:
				updateCartProperties?.selectedContactReference?.id ??
				cart.selectedContactReference?.id,
			giftCards: updateCartProperties?.giftCards ?? cart.giftCards,
			selectedDelivery:
				updateCartProperties?.selectedDelivery ?? cart.selectedDelivery,
			selectedPayment:
				updateCartProperties?.selectedPayment ?? cart.selectedPayment,
			email: updateCartProperties?.email ?? cart.email,
			useAlternativeDeliveryAddress:
				updateCartProperties?.alternativeDeliveryAddress ??
				cart.alternativeDeliveryAddress,
			telephoneNumber:
				updateCartProperties?.telephoneNumber ?? cart.telephoneNumber,
			deliveryAddress:
				updateCartProperties?.deliveryAddress ?? cart.deliveryAddress,
			customerAddress:
				updateCartProperties?.customerAddress ?? cart.customerAddress,
			unregisteredCompanyInfo:
				updateCartProperties?.unregisteredCompanyInfo ??
				cart.unregisteredCompanyInfo,
			discountCodes: getDiscountCodes(cart, updateCartProperties),
			allowAnonymousReviews:
				updateCartProperties?.allowAnonymousReviews ??
				cart.allowAnonymousReviews,
		},
		(val) => val !== undefined,
	);
}
