import React, { useEffect, useState } from 'react';

import Button from 'components/Button';
import Popover from 'components/Popover';
import ProductCard from 'components/ProductCard';
import ProductTag from 'components/ProductTag';
import ScreenReaderAnnouncementText from 'components/ScreenReaderAnnouncementText';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import { useCampaignProducts, useProductListGTMEvents } from 'hooks';
import type { CampaignResponse } from 'models/product';
import { RelatedProductCard } from 'models/productCard';
import type { ButtonStateWithId } from 'state-machines/cart';
import { formatDate } from 'utils/format';
import { range } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import { CAMPAIGN_PACKAGE_DISCOUNT_TYPES } from '../helpers/constants';

interface Props {
	campaigns: CampaignResponse[] | undefined;
	onAddToCartClick: (
		variant: RelatedProductCard,
		campaign: CampaignResponse,
	) => void;
	purchaseButtonState: ButtonStateWithId;
}

/**
 * Campaign info and a popover to show the included products.
 */
export default function ProductDetailsCampaignProductsPopover({
	campaigns,
	onAddToCartClick,
	purchaseButtonState,
}: Props) {
	const filteredCampaigns = campaigns?.filter(
		({ discountType }) =>
			!CAMPAIGN_PACKAGE_DISCOUNT_TYPES.includes(discountType),
	);

	const { t, tPlural } = useI18n();
	const [selectedCampaign, setSelectedCampaign] = useState<
		CampaignResponse | undefined
	>();

	const { isLoading, items } = useCampaignProducts(
		selectedCampaign?.id,
		Boolean(selectedCampaign),
	);

	const { gtmItemListId, gtmItemListName, sendViewItemListEvent } =
		useProductListGTMEvents(
			'pdp_mix_match',
			`Mix and Match: [${selectedCampaign?.discountType}] - [${selectedCampaign?.title}] ([${selectedCampaign?.id}])`,
		);
	const itemIds = items.map(({ id }) => id).join(',');
	useEffect(() => {
		if (items.length > 0) {
			sendViewItemListEvent(items, items.length);
		}
		// Only check IDs in case the results array changes but it still contains
		// the same products.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemIds]);

	if (!filteredCampaigns?.length) {
		return null;
	}

	return (
		<div className="mt-4 space-y-4">
			{filteredCampaigns.map((campaign) => (
				<div key={campaign.id}>
					{campaign.subtitle && (
						<ProductTag
							size="large"
							type="QtyBasedOffer"
							text={campaign.subtitle}
						/>
					)}
					<p className="mt-0.5">
						<Button
							variant="text-inline"
							onClick={() => {
								setSelectedCampaign(campaign);
							}}
							aria-haspopup="dialog"
							className="group/campaign-button font-bold"
							underline={false}
						>
							{t('product_details_campaign_button')}{' '}
							<span className="font-normal underline group-hover/campaign-button:no-underline">
								{campaign.title}
							</span>
						</Button>
					</p>
					{campaign.validTo && (
						<p className="text-julaRed">
							{t('product_details_campaign_valid_to_text', {
								validTo: formatDate(campaign.validTo),
							})}
						</p>
					)}
				</div>
			))}
			<Popover
				isOpen={Boolean(selectedCampaign)}
				onClose={() => setSelectedCampaign(undefined)}
				id="product-details-campaign-products"
				title={selectedCampaign?.title || t('product_list_products_label')}
			>
				<>
					<ScreenReaderAnnouncementText
						as="p"
						text={
							items
								? tPlural(
										'product_details_campaign_items_count_text',
										items.length,
									)
								: ''
						}
						hidden
						atomic
					/>
					{isLoading && (
						<Skeleton className="space-y-8">
							{range(3).map((i) => (
								<div key={i} className="flex items-start gap-4">
									<SkeletonItem width="96px" height="96px" />
									<SkeletonItem height="7.5rem" className="grow" />
								</div>
							))}
						</Skeleton>
					)}
					{!isLoading &&
						items?.map((product, i) => (
							<ProductCard
								key={product.id}
								orientation="row"
								product={product}
								showAddToCartButton
								gtmItemListId={gtmItemListId}
								gtmItemListName={gtmItemListName}
								productListIndex={i}
								actionButtonState={
									product.id === purchaseButtonState.buttonId
										? purchaseButtonState.state
										: undefined
								}
								onPurchaseButtonClick={() => {
									if (selectedCampaign) {
										onAddToCartClick(product, selectedCampaign);
									}
								}}
							/>
						))}
				</>
			</Popover>
		</div>
	);
}
ProductDetailsCampaignProductsPopover.displayName =
	'ProductDetailsCampaignProductsPopover';
