import { useCallback } from 'react';

import { useChatContext, useGlobalPopoversContext } from 'contexts';
import { useChatAvailability } from 'hooks';

import { useLazyGlobalValue } from './useLazyGlobalValue';

export function useChat(isAvailabilityActive = false) {
	const {
		activateChatWidget,
		chatWidgetIsActive,
		chatWidgetIsReady,
		messageCount,
	} = useChatContext();

	const { openCustomerChat } = useGlobalPopoversContext();
	const { hasAvailableAgents, isLoading, isOpen, zendeskJwt } =
		useChatAvailability(isAvailabilityActive);
	const Smooch = useLazyGlobalValue('Smooch', chatWidgetIsActive);

	const openChat = useCallback(() => {
		// Calling Smooch methods before init will throw errors.
		if (Smooch && chatWidgetIsReady) {
			Smooch.open?.();
		}
	}, [Smooch, chatWidgetIsReady]);

	return {
		activateChatWidget,
		/** Chat system has agents available to respond */
		chatHasAvailableAgents: hasAvailableAgents,
		/** Chat system itself is open at all */
		chatIsOpen: isOpen,
		/** Chat widget should be loaded */
		chatWidgetIsActive,
		/** Chat widget is initialized and ready to use */
		chatWidgetIsReady,
		/** Loading status for chat system available/open */
		isLoadingChatAvailability: isLoading,
		/** Chat widget script is loading */
		isLoadingChatWidget: chatWidgetIsActive && !chatWidgetIsReady,
		messageCount,
		openChat,
		openChatOrPopover: chatWidgetIsActive ? openChat : openCustomerChat,
		Smooch,
		zendeskJwt,
	};
}
