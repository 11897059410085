/**
 * LinearGauge
 */

import React from 'react';

interface Props {
	percent: number;
}

export default function LinearGauge({ percent }: Props) {
	return (
		<div className="h-4 w-full overflow-hidden rounded-full bg-greyLight forced-colors:border">
			<div
				style={{
					width: `${Math.round(Math.min(percent ?? 0, 100)).toString()}%`,
				}}
				className="h-full rounded-full bg-julaRed forced-colors:bg-[CanvasText]"
			/>
		</div>
	);
}
LinearGauge.displayName = 'LinearGauge';
