import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import { BlockShortcutImages } from 'components/BlockShortcutImages';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import { useIsEditing } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import type { SitecoreLink } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';
import { getAsset } from 'utils/helpers';

interface ShortcutLink {
	landscapeImage?: Field<DigizuiteAsset[]>;
	link?: Field<SitecoreLink>;
}

type Props = JulaComponentProps & {
	fields?: {
		heading?: Field<string>;
		links?: ShortcutLink[];
	};
};

export default function ShortcutIcons({ fields, params }: Props) {
	const isEditing = useIsEditing();

	if (!fields) {
		return null;
	}

	const { heading } = fields;
	const items = filterTruthy(
		fields.links?.map(({ landscapeImage, link }) => ({
			href: link?.value.href,
			imgSrc: getAsset('Templated 2:1', landscapeImage)?.src,
			target: link?.value.target,
			text: link?.value.text,
		})),
		'href',
		'imgSrc',
		'text',
	);
	const itemCount = 6;

	if (
		(isEditing && !heading?.value && items.length === 0) ||
		(!isEditing && items.length < itemCount)
	) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="ShortcutIcons"
				description={`Requires at least ${itemCount} functional links with images`}
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
		>
			<Text as={params?.heading || 'h2'} field={heading} className="mb-6" />
			<BlockShortcutImages items={items.slice(0, itemCount)} />
		</LayoutContainer>
	);
}
ShortcutIcons.displayName = 'ShortcutIcons';
