import { ListImageFormat } from 'models/asset';

import { BaseSearchResponse, FacetResponse } from './shared';

export interface ArchivedProductResponse {
	assets?: ListImageFormat[];
	brand?: { name?: string };
	id?: string;
	title?: string;
}

export const facetTypes = ['Brand', 'Category'] as const;
export type FacetType = (typeof facetTypes)[number];

export interface ProductArchiveSearchResponse extends BaseSearchResponse {
	facets: FacetResponse<FacetType>[];
	products: ArchivedProductResponse[];
}
