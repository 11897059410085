/**
 * ArrowLink
 */

import React, { type AnchorHTMLAttributes } from 'react';

import Icon from 'components/Icon';
import Link from 'components/Link';
import Text from 'components/Text';
import { cn } from 'utils/classNames';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
	/** Extra class names */
	className?: string;

	/** Link url */
	href: string;

	/** Extra class names for the arrow icon */
	iconClassName?: string;

	/** Link text */
	text: string;
}

/** Link component with arrow icon */
const ArrowLink = React.forwardRef<HTMLAnchorElement, Props>(
	({ className, href, iconClassName, text, ...attrs }, ref) => {
		if (!href || !text) {
			return null;
		}
		return (
			<Link
				{...attrs}
				ref={ref}
				href={href}
				className={cn(
					className,
					'group/arrow-link inline-flex items-start align-top',
				)}
			>
				<Icon
					name="arrow"
					align="top"
					color="white"
					backgroundColor="julaRed"
					className={cn(
						'mr-2 group-hover/arrow-link:bg-julaRedDark forced-colors:border',
						iconClassName,
					)}
				/>
				<Text as="span" className="mt-px group-hover/arrow-link:underline">
					{text}
				</Text>
			</Link>
		);
	},
);
ArrowLink.displayName = 'ArrowLink';

export default ArrowLink;
