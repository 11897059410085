import React, { type ReactNode, useState } from 'react';

import Img from 'components/Img';
import ListItem from 'components/ListItem';
import Popover from 'components/Popover';
import Price from 'components/Price';
import SelectButton from 'components/SelectButton';
import type { StoreIdName } from 'contexts';
import type { ItemStockResponse } from 'models/api';
import type { Price as PriceModel } from 'models/price';

import StockInformation from './StockInformation';

interface BaseVariant {
	imageSrc?: string;
	isSelected: boolean;
	key: string;
	price?: PriceModel;
	title: string | undefined;
	url: string;
}

interface VariantWithoutStock extends BaseVariant {
	isSellable?: never;
	isSellableOnWeb?: never;
	variantId?: never;
}

interface VariantWithStock extends BaseVariant {
	isSellable: boolean;
	isSellableOnWeb: boolean;
	variantId: string;
}

interface BaseProps {
	buttonLabel: string;
	className?: string;
	onOpen?: () => void;
	popoverStartContent?: ReactNode;
	popoverTitle: string;
	selectedImageUrl?: string;
	selectedText: string | undefined;
	shouldScrollToTopOnSelect?: boolean;
}

interface PropsWithoutStock extends BaseProps {
	selectedStore?: never;
	stockData?: never;
	stockIsLoading?: never;
	variants: VariantWithoutStock[];
}

interface PropsWithStock extends BaseProps {
	selectedStore: StoreIdName | undefined;
	stockData: ItemStockResponse[] | undefined;
	stockIsLoading: boolean;
	variants: VariantWithStock[];
}

type Props = PropsWithoutStock | PropsWithStock;

export default function BasePicker({
	buttonLabel,
	className,
	onOpen,
	popoverStartContent,
	popoverTitle,
	selectedImageUrl,
	selectedStore,
	selectedText,
	shouldScrollToTopOnSelect,
	stockData,
	stockIsLoading = false,
	variants,
}: Props) {
	const [isOpen, setIsOpen] = useState(false);

	if (variants.length < 2) {
		return null;
	}

	return (
		<>
			<SelectButton
				label={buttonLabel}
				className={className}
				onClick={() => {
					onOpen?.();
					setIsOpen(true);
				}}
				aria-haspopup="dialog"
				invertArrow={isOpen}
				text={selectedText || '—'}
				imageUrl={selectedImageUrl}
			/>
			<Popover
				isOpen={isOpen}
				title={popoverTitle}
				onClose={() => {
					setIsOpen(false);
				}}
			>
				{popoverStartContent}
				<ul className="space-y-2">
					{variants.map((variant: VariantWithoutStock | VariantWithStock) => (
						<ListItem
							key={variant.key}
							as="li"
							title={variant.title || '—'}
							href={variant.url}
							shouldScrollToTop={shouldScrollToTopOnSelect}
							// Center if there is no stock information shown.
							alignment={variant.variantId ? undefined : 'center'}
							startContent={
								variant.imageSrc ? (
									<Img
										src={variant.imageSrc}
										className="size-10 shrink-0"
										service="nextjs"
										width={40}
										height={40}
									/>
								) : null
							}
							endContent={
								variant.price ? (
									<Price price={variant.price} size="microCompact" />
								) : null
							}
							isSelected={variant.isSelected}
						>
							{variant.variantId && (
								<StockInformation
									isSellable={variant.isSellable}
									isSellableOnWeb={variant.isSellableOnWeb}
									stock={stockData?.find(
										(stockItem) => stockItem.variantId === variant.variantId,
									)}
									selectedStore={selectedStore}
									isLoading={stockIsLoading}
								/>
							)}
						</ListItem>
					))}
				</ul>
			</Popover>
		</>
	);
}
BasePicker.displayName = 'ProductDetails_BasePicker';
