/**
 * OrderDetails
 */

import React from 'react';
import type { ComponentRendering } from '@sitecore-jss/sitecore-jss-nextjs';

import Link from 'components/Link';
import Placeholder from 'components/Placeholder';
import PriceRow from 'components/PriceRow';
import { SummaryProductLine } from 'components/ProductLine';
import Text from 'components/Text';
import TotalWithVat from 'components/TotalWithVat';
import type {
	FormattedPriceResponse,
	InvoiceAddress,
	OrderDeliveryAddress,
	OrderLine,
} from 'models/api';
import { getProductImageSrc } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';

interface Props {
	bonusDiscount: FormattedPriceResponse | undefined;
	buyer: string | undefined;
	campaignDiscount: FormattedPriceResponse | undefined;
	className?: string;
	customerDiscount: FormattedPriceResponse | undefined;
	deliveryAddress: OrderDeliveryAddress | undefined;
	deliveryMethod: string | undefined;
	email: string | undefined;
	fixedReferenceText: string | undefined;
	formattedPurchaseDate?: string;
	freeReferenceText: string | undefined;
	invoiceAddress: InvoiceAddress | undefined;
	isEcomOrder: boolean;
	isResursBankOrder: boolean;
	lines: OrderLine[] | undefined;
	miscDiscount: FormattedPriceResponse | undefined;
	orderNumber: string | undefined;
	phoneNumber: string | undefined;
	pointOfPurchase?: string;
	primaryPaymentMethod: string | undefined;
	rendering: ComponentRendering;
	shippingCost: FormattedPriceResponse | undefined;
	showContactReferences: boolean;
	statusText: string | undefined;
	totalSum: FormattedPriceResponse | undefined;
	trackingUrl: string | undefined;
	vatAmount: FormattedPriceResponse | undefined;
}

interface AddressProps {
	city: string | undefined;
	className?: string;
	co: string | undefined;
	heading: string;
	name: string | undefined;
	postalCode: string | undefined;
	street: string | undefined;
	vatRegistrationNumber?: string;
}

function Address({
	city,
	className,
	co,
	heading,
	name,
	postalCode,
	street,
	vatRegistrationNumber,
}: AddressProps) {
	return (
		<div className={className}>
			<Text as="p" className="mb-2 font-bold" text={heading} />
			<p className="break-inside-avoid">
				{vatRegistrationNumber && (
					<>
						{vatRegistrationNumber}
						<br />
					</>
				)}
				{name}
				<br />
				{co && (
					<>
						{co}
						<br />
					</>
				)}
				{street}
				<br />
				{`${postalCode}, ${city}`}
			</p>
		</div>
	);
}
Address.displayName = 'OrderDetails_Address';

/** Order details layout */
export default function OrderDetails({
	bonusDiscount,
	buyer,
	campaignDiscount,
	className,
	customerDiscount,
	deliveryAddress,
	deliveryMethod,
	email,
	fixedReferenceText,
	formattedPurchaseDate,
	freeReferenceText,
	invoiceAddress,
	isEcomOrder,
	isResursBankOrder,
	lines,
	miscDiscount,
	orderNumber,
	phoneNumber,
	pointOfPurchase,
	primaryPaymentMethod,
	rendering,
	shippingCost,
	showContactReferences,
	statusText,
	totalSum,
	trackingUrl,
	vatAmount,
}: Props) {
	const { t } = useI18n();
	return (
		<div className={className}>
			<Text
				as="h2"
				styleAs="h3"
				className="mb-4 font-bold"
				text={t('purchases_order_information_heading')}
			/>
			<Text
				as="p"
				text={
					orderNumber && `${t('purchases_order_number_text')}: ${orderNumber}`
				}
			/>
			<Text
				as="p"
				text={
					formattedPurchaseDate &&
					`${t('purchases_purchase_date_text')}: ${formattedPurchaseDate}`
				}
			/>
			<Text
				as="p"
				text={
					pointOfPurchase &&
					`${t('purchases_point_of_purchase_text')}: ${pointOfPurchase}`
				}
			/>
			<Text
				as="p"
				text={
					primaryPaymentMethod &&
					`${t('purchases_payment_method_text')}: ${primaryPaymentMethod}`
				}
			/>

			{isEcomOrder && (
				<>
					<div>
						<Text
							as="p"
							text={
								deliveryMethod &&
								`${t('purchases_delivery_method_text')}: ${deliveryMethod}`
							}
						/>
						<Text
							as="p"
							text={
								statusText && `${t('purchases_status_text')}: ${statusText}`
							}
						/>

						{trackingUrl && (
							<Link
								href={trackingUrl}
								underline
								target="_blank"
								rel="noopener noreferrer"
								className="print:hidden"
							>
								{`${t('purchase_track_order_button_text')}`}
							</Link>
						)}
					</div>
					{isResursBankOrder && (
						<Placeholder
							rendering={rendering}
							name="jula-account-details"
							isInNestedPlaceholder
						/>
					)}
					{/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
					{(invoiceAddress || deliveryAddress) && (
						<div className="mb-9 sm:mb-10">
							<Text
								as="h2"
								styleAs="h3"
								className="mb-4 mt-8 font-bold"
								text={t('purchases_your_information_heading')}
							/>
							<div className="md:flex">
								{deliveryAddress && (
									<Address
										className="max-md:mb-4 md:mb-0 md:w-1/2"
										heading={t('purchases_order_will_send_to_text')}
										name={deliveryAddress.name}
										co={deliveryAddress.co}
										street={deliveryAddress.street}
										postalCode={deliveryAddress.postalCode}
										city={deliveryAddress.city}
									/>
								)}
								{invoiceAddress && (
									<Address
										className="md:w-1/2"
										heading={t('purchases_order_invoice_address_text')}
										vatRegistrationNumber={invoiceAddress.vatRegistrationNumber}
										name={invoiceAddress.name}
										co={invoiceAddress.co}
										street={invoiceAddress.street}
										postalCode={invoiceAddress.postalCode}
										city={invoiceAddress.city}
									/>
								)}
							</div>
						</div>
					)}
					{(phoneNumber || email) && (
						<div className="my-4">
							<Text as="p" className="mb-2 font-bold">
								{t('purchases_contact_info_heading')}
							</Text>
							<Text as="p" text={email} />
							<Text as="p" text={phoneNumber} />
						</div>
					)}
				</>
			)}

			{(fixedReferenceText || freeReferenceText || buyer) && (
				<div className="mb-9 sm:mb-10">
					<div className="md:flex">
						{buyer && (
							<div className="mb-4 md:mb-0 md:w-1/2">
								<Text as="p" className="mb-2 font-bold">
									{t('purchases_order_buyer_heading')}
								</Text>
								<p>{buyer}</p>
							</div>
						)}
						{showContactReferences &&
							(fixedReferenceText || freeReferenceText) && (
								<div className="md:w-1/2">
									<Text as="p" className="mb-2 font-bold">
										{t('purchases_order_reference_heading')}
									</Text>
									<Text as="p" text={fixedReferenceText} />
									<Text as="p" text={freeReferenceText} />
								</div>
							)}
					</div>
				</div>
			)}
			{lines && (
				<div className="mb-9 sm:mb-10">
					<Text as="h2" styleAs="h3" className="mb-4 mt-8 font-bold">
						{t('purchases_your_order_heading')}
					</Text>
					{lines?.map((line) => (
						<SummaryProductLine
							link={line?.url}
							amount={line?.quantity}
							heading={line.title}
							imageSrc={getProductImageSrc(line?.mainImage?.formats, true)}
							articleNumber={line.variantId}
							rowSum={line?.price}
							discount={false}
							key={line.variantId}
						/>
					))}
				</div>
			)}
			{bonusDiscount && (
				<PriceRow
					className="mb-4"
					variant="minusPrice"
					title={t('purchases_bonus_discount_text')}
					price={bonusDiscount.displayValue}
					priceSymbol={bonusDiscount.displaySymbol}
				/>
			)}
			{campaignDiscount && (
				<PriceRow
					className="mb-4"
					variant="minusPrice"
					title={t('purchases_campaign_discount_text')}
					price={campaignDiscount.displayValue}
					priceSymbol={campaignDiscount.displaySymbol}
				/>
			)}
			{customerDiscount && (
				<PriceRow
					variant="minusPrice"
					className="mb-4"
					title={t('purchases_customer_discount_text')}
					price={customerDiscount.displayValue}
					priceSymbol={customerDiscount.displaySymbol}
				/>
			)}
			{miscDiscount && (
				<PriceRow
					className="mb-4"
					variant="minusPrice"
					title={t('purchases_misc_discount_text')}
					price={miscDiscount.displayValue}
					priceSymbol={miscDiscount.displaySymbol}
				/>
			)}
			{shippingCost && (
				<PriceRow
					className="mb-4"
					variant="regular"
					title={t('purchases_shippingcost_text')}
					price={shippingCost.displayValue}
					priceSymbol={shippingCost.displaySymbol}
				/>
			)}
			{totalSum && (
				<TotalWithVat
					className="mt-3"
					title={t('purchases_your_total_text')}
					price={totalSum.displayValue}
					priceSymbol={totalSum.displaySymbol}
					vat={vatAmount?.displayValue}
					vatCurrencySymbol={vatAmount?.displaySymbol}
				/>
			)}
		</div>
	);
}
OrderDetails.displayName = 'OrderDetails';
