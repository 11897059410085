/**
 * ActionButton
 */

import React, {
	type MouseEventHandler,
	type ReactNode,
	useEffect,
	useState,
} from 'react';
import { useInterpret, useSelector } from '@xstate/react';

import Button, { type ButtonProps, type ButtonSize } from 'components/Button';
import {
	actionButtonMachine,
	type ActionButtonState,
	selectCurrentActionButtonState,
} from 'state-machines/actionButton.machine';
import type { WithOptional } from 'types';
import { cn } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

// Re-export to have both the button and its types from the same place.
export type { ActionButtonState } from 'state-machines/actionButton.machine';

interface Props extends WithOptional<ButtonProps, 'onClick'> {
	/** React children */
	children?: ReactNode;

	/** Custom state to show. */
	customState?: ActionButtonState;

	/** Aria label for when the button is in a failure state */
	failureLabel?: string;

	/** Aria label for when the button is in a loading state */
	loadingLabel?: string;

	/** Number of milliseconds the spinner must be shown (set to 0 to not use a minimum time) */
	minimunLoadingTime?: number;

	/** Callback for when onClick triggers an error */
	onError?: (error?: unknown) => void;

	/** Callback for when onClick completed successfully */
	onSuccess?: () => void;

	/** Should the success checkmark be shown? */
	showSuccess?: boolean;

	/** Aria label for when the button is in a success state */
	successLabel?: string;

	/** Should the button use a label in the failure state? */
	useFailureLabel?: boolean;

	/** Should the button use a label in the loading state? */
	useLoadingLabel?: boolean;

	/** Should the button use a label in the success state? */
	useSuccessLabel?: boolean;
}

/** Button with a spinner and feedback message. */
const ActionButton = React.forwardRef<HTMLButtonElement, Props>(
	(
		{
			children,
			className,
			customState,
			failureLabel,
			loadingLabel,
			minimunLoadingTime = 1400,
			onClick,
			onError,
			onSuccess,
			showSuccess = true,
			successLabel,
			useFailureLabel = true,
			useLoadingLabel = true,
			useSuccessLabel = true,
			variant = 'secondary',
			...buttonProps
		},
		ref,
	) => {
		const { t } = useI18n();

		const actionButtonService = useInterpret(actionButtonMachine, {
			context: { showSuccess },
		});
		const { send } = actionButtonService;
		const currentState = useSelector(
			actionButtonService,
			selectCurrentActionButtonState,
		);

		const [customStateCanTransition, setCustomStateCanTransition] =
			useState<boolean>(true);

		const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
			if (customState) {
				onClick?.(e);
				return;
			}

			// Only allow click if the state is idle or failure
			if (currentState === 'idle') {
				send('LOAD');
			} else if (currentState === 'failure') {
				send('RETRY');
			} else {
				return;
			}

			if (onClick) {
				const minTimeout = new Promise((resolve) => {
					setTimeout(resolve, minimunLoadingTime);
				});

				Promise.all([minTimeout, onClick(e)])
					.then(() => {
						onSuccess?.();
						send('RESOLVE');
					})
					.catch((error) => {
						if (onError) {
							onError(error);
						}
						send('REJECT');
					});
			}
		};

		useEffect(() => {
			if (!customStateCanTransition) {
				return;
			}
			switch (customState) {
				case 'idle':
					send('RESOLVE');
					break;
				case 'loading':
					send('LOAD');
					// Make sure we wait the minimunLoadingTime before allowing to transition to other state
					setCustomStateCanTransition(false);
					setTimeout(
						() => setCustomStateCanTransition(true),
						minimunLoadingTime,
					);
					break;
				case 'failure':
					send('REJECT');
					break;
				case 'success':
					send('RESOLVE');
					break;
				default:
				// No default
			}
		}, [customState, customStateCanTransition, minimunLoadingTime, send]);

		const iconSizeClasses: Record<ButtonSize, string> = {
			xSmall: 'size-4',
			small: 'size-5',
			medium: 'size-7',
			large: 'size-8',
			xl: 'size-9',
		};
		const iconSizeClass = iconSizeClasses[buttonProps.size ?? 'medium'];

		const buttonVariant = currentState === 'failure' ? 'primary' : variant;
		const iconPositionClasses =
			'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2';
		const iconBaseClasses = cn('!m-0 block', iconSizeClass);

		const buttonLoadingLabel = useLoadingLabel
			? (loadingLabel ?? t('general_loading_text'))
			: undefined;
		const buttonFailureLabel = useFailureLabel
			? (failureLabel ?? t('general_action_failure_text'))
			: undefined;
		const buttonSuccessLabel = useSuccessLabel
			? (successLabel ?? t('general_action_success_text'))
			: undefined;

		return (
			<Button
				{...buttonProps}
				ref={ref}
				onClick={handleClick}
				variant={buttonVariant}
				aria-label={
					currentState === 'loading'
						? buttonLoadingLabel
						: currentState === 'failure'
							? buttonFailureLabel
							: currentState === 'success'
								? buttonSuccessLabel
								: undefined
				}
				// Could possibly add aria-disabled as well, if all action buttons should
				// be treated as disabled when loading? The custom state prop makes it
				// a bit tricky.
				aria-busy={
					currentState === 'loading' || customState === 'loading'
						? 'true'
						: undefined
				}
				className={cn('relative', className)}
			>
				{currentState === 'loading' && (
					<span
						className={cn('inline-block', iconPositionClasses, iconSizeClass)}
					>
						<svg
							viewBox="25 25 50 50"
							// On its way https://github.com/facebook/react/pull/26130
							// eslint-disable-next-line react/no-unknown-property
							transform-origin="center center 0px"
							className={cn(iconBaseClasses, 'animate-spinnerRotate')}
						>
							<circle
								cx="50"
								cy="50"
								r="20"
								fill="none"
								strokeWidth="3"
								strokeMiterlimit="10"
								strokeDasharray="1px, 200px"
								strokeDashoffset="0px"
								stroke="currentColor"
								strokeLinecap="round"
								className="animate-spinnerDash"
							/>
						</svg>
					</span>
				)}
				{currentState === 'success' && (
					<svg
						viewBox="0 0 26 18"
						className={cn(iconPositionClasses, iconBaseClasses)}
					>
						<path
							fill="none"
							stroke="currentColor"
							// On its way https://github.com/facebook/react/pull/26130
							// eslint-disable-next-line react/no-unknown-property
							transform-origin="50% 50% 0px"
							strokeDasharray="3rem"
							strokeDashoffset="3rem"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="3"
							d="M1 8.98L9.01 17 25 1"
							className="animate-spinnerStroke"
						/>
					</svg>
				)}
				{currentState === 'failure' && (
					<svg
						viewBox="0 0 40 40"
						className={cn(iconPositionClasses, iconBaseClasses)}
					>
						<path
							fill="none"
							// On its way https://github.com/facebook/react/pull/26130
							// eslint-disable-next-line react/no-unknown-property
							transform-origin="50% 50% 0px"
							stroke="currentColor"
							strokeDasharray="3rem"
							strokeDashoffset="3rem"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="3"
							d="M 10,10 L 30,30 M 30,10 L 10,30"
							className="animate-spinnerStroke"
						/>
					</svg>
				)}
				<span
					className={cn(
						'flex items-center justify-center',
						currentState !== 'idle' && 'invisible',
					)}
				>
					{children}
				</span>
			</Button>
		);
	},
);
ActionButton.displayName = 'ActionButton';

export default ActionButton;
