import React, { useRef, useState } from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import Script from 'next/script';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import IconButton from 'components/IconButton';
import ProductPopover from 'components/ProductPopover';
import { useSelectedStore } from 'contexts';
import { useIsEditing, useProductStock, useProductVariant } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { IPaper } from 'models/vendor';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields?: {
		datasourceName: string;
		publicationUrl?: Field<string>;
		title?: Field<string>;
	};
};

export default function CampaignViewer({ fields }: Props) {
	const { t } = useI18n();
	const isEditing = useIsEditing();

	const iframeRef = useRef<HTMLIFrameElement>(null);
	const { publicationUrl, title } = fields ?? {};
	const campaignTitle = title?.value;
	const campaignPublicationUrl = publicationUrl?.value;
	const [currentPaper, setCurrentPaper] = useState<IPaper | null>(null);
	const [isFirstPage, setIsFirstPage] = useState(true);
	const [isLastPage, setIsLastPage] = useState(false);

	const [productVariantId, setProductVariantId] = useState('');

	const { data: productVariant, isLoading: isLoadingProductVariant } =
		useProductVariant(productVariantId);

	const { selectedStore } = useSelectedStore();

	const { isLoadingProductStock, productStock } = useProductStock({
		productId: productVariantId,
	});

	if (!campaignPublicationUrl) {
		return (
			<ComponentPlaceholder
				componentName="CampaignViewer"
				description="CampaignViewer requires an campaignPublicationUrl"
			/>
		);
	}

	function handlePageChange(direction: 'next' | 'prev' = 'next') {
		if (currentPaper) {
			if (direction === 'next') {
				currentPaper.paging.goToNextPage();
			} else {
				currentPaper.paging.goToPrevPage();
			}
		}
	}

	async function onPageChange(paper: IPaper) {
		const { currentSpread, spreads } = await paper.paging.getState();
		setIsFirstPage(currentSpread[0] === 1);
		setIsLastPage(currentSpread[0] === spreads.length);
	}

	return (
		<>
			<Script
				src="https://cdn.ipaper.io/flipbooks/api/v3/latest.js"
				strategy="afterInteractive"
				onLoad={() => {
					if (globalThis.iPaperJsApi && iframeRef.current) {
						const iPaper = globalThis.iPaperJsApi(iframeRef.current, 3);
						setCurrentPaper(iPaper);
						iPaper.paging.onChange(() => onPageChange(iPaper));
						iPaper.publication.onPageElementClick((result) => {
							setProductVariantId(result.data?.productID);
						});
					}
				}}
			/>
			<div className={isEditing ? 'pt-12' : undefined}>
				<iframe
					title={campaignTitle || ''}
					src={campaignPublicationUrl}
					data-ipaper
					className="h-[73vh] w-full lg:h-[96vh]"
					ref={iframeRef}
				/>
			</div>
			<div className="mt-4 flex items-center justify-center sm:hidden">
				<IconButton
					disabled={isFirstPage}
					size="small"
					icon="arrow"
					iconColor="white"
					iconDirection="left"
					text={t('slider_prev_button')}
					hoverClasses="[@media(hover:hover)]:hover:bg-julaRedDark"
					className="bg-julaRed"
					onClick={() => handlePageChange('prev')}
				/>
				<span className="mx-4">{t('ipaper_pagination_text')}</span>
				<IconButton
					disabled={isLastPage}
					size="small"
					icon="arrow"
					iconColor="white"
					text={t('slider_next_button')}
					hoverClasses="[@media(hover:hover)]:hover:bg-julaRedDark"
					className="bg-julaRed"
					onClick={() => handlePageChange('next')}
				/>
			</div>
			<ProductPopover
				title={t('campaign_viewer_popover_heading')}
				variant="CampaignViewer"
				isLoadingProduct={isLoadingProductVariant}
				isOpen={Boolean(productVariantId)}
				onClose={() => {
					setProductVariantId('');
				}}
				product={productVariant}
				isLoadingProductStock={isLoadingProductStock}
				productStock={productStock}
				selectedStore={selectedStore}
			/>
		</>
	);
}

CampaignViewer.displayName = 'CampaignViewer';
