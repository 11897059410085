import {
	type ActorRefFrom,
	assign,
	createMachine,
	sendParent,
	type StateFrom,
} from 'xstate';
import { pure } from 'xstate/lib/actions';

import type { GiftCard } from 'models/api';
import type { UpdateCartEvent } from 'state-machines/checkout';
import { is } from 'utils/helpers';

import type {
	GiftCardBonusMachineContext,
	GiftCardBonusMachineEvents,
} from './giftCardBonus.types';

export const giftCardBonusMachine = createMachine(
	{
		id: 'giftCard',
		initial: 'idle',
		schema: {
			context: {} as GiftCardBonusMachineContext,
			events: {} as GiftCardBonusMachineEvents,
		},
		tsTypes: {} as import('./giftCardBonus.machine.typegen').Typegen0,
		context: {
			giftCards: [],
			errorList: [],
			availableBonusDiscounts: [],
			bonusDiscounts: [],
		},
		states: {
			idle: {
				on: {
					CART_UPDATED: {
						target: 'idle',
						actions: 'updateData',
					},
					ADD_REMOVE_GIFT_CARD: {
						target: 'loading',
						actions: 'addRemoveGiftCard',
					},
				},
			},
			debounceUpdateBonusCheck: {
				after: {
					250: {
						target: 'loading',
						actions: 'addRemoveBonusCheque',
					},
				},
			},
			loading: {
				on: {
					CART_UPDATED: {
						target: 'idle',
						actions: 'updateData',
					},
				},
			},
		},
		on: {
			ADD_REMOVE_BONUS_CHECK: {
				target: '.debounceUpdateBonusCheck',
				internal: false,
				actions: 'updateBonusDiscounts',
			},
		},
	},
	{
		actions: {
			updateData: assign({
				cart: (context, event) => event.value,
				giftCards: (context, event) =>
					event.value?.giftCards ?? context.giftCards,
				bonusDiscounts: (context, event) =>
					event.value?.bonusDiscounts ?? context.bonusDiscounts,
				availableBonusDiscounts: (_context, event) =>
					event.value?.availableBonusDiscounts ?? [],
				errorList: (context, event) =>
					event.value?.errorList ?? context.errorList,
			}),

			addRemoveGiftCard: sendParent((context, event) => {
				let updatedGiftCards: GiftCard[] = [];
				if (event.variant === 'add') {
					updatedGiftCards = [...context.giftCards, event.giftCard];
				} else if (event.variant === 'remove') {
					updatedGiftCards = context.giftCards.filter(
						(giftCard) => giftCard.id !== event.giftCard.id,
					);
				}
				const updateCartEvent: UpdateCartEvent = {
					type: 'UPDATE_CART',
					updatedCartProperties: { giftCards: updatedGiftCards },
				};
				return updateCartEvent;
			}),
			updateBonusDiscounts: assign({
				bonusDiscounts: (context, event) => {
					if (
						context.bonusDiscounts.some(
							(d) => d.checkNumber === event.bonusChequeId,
						)
					) {
						return context.bonusDiscounts.filter(
							(d) => d.checkNumber !== event.bonusChequeId,
						);
					}
					return [
						...(context.bonusDiscounts || []),
						{ checkNumber: event.bonusChequeId },
					];
				},
			}),
			addRemoveBonusCheque: pure((context) => {
				const updateCartEvent: UpdateCartEvent = {
					type: 'UPDATE_CART',
					updatedCartProperties: {
						bonusDiscounts: context.bonusDiscounts
							.map((d) => d.checkNumber)
							.filter(is.truthy),
					},
				};
				return sendParent(updateCartEvent);
			}),
		},
	},
);
export type GiftCardBonusMachineState = StateFrom<typeof giftCardBonusMachine>;
export type GiftCardBonusMachineActor = ActorRefFrom<
	typeof giftCardBonusMachine
>;
