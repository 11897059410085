import React from 'react';

import type { Product, ProductLink } from 'models/product';
import { getMainImageDetail, getProductImageSrc } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';
import { useI18n } from 'utils/i18n';

import BasePicker from './BasePicker';

interface Props {
	className?: string;
	product: Product;
	variants: ProductLink[] | undefined;
}

export default function StylePicker({ className, product, variants }: Props) {
	const { t } = useI18n();

	return (
		<BasePicker
			className={className}
			buttonLabel={t('product_details_pick_variant_button')}
			popoverTitle={t('popover_choose_variant_header')}
			selectedText={
				variants?.find((variant) => variant.productId === product.productId)
					?.title
			}
			selectedImageUrl={getMainImageDetail(product)?.src}
			variants={filterTruthy(variants, 'url').map((variant) => ({
				imageSrc: getProductImageSrc(variant),
				isSelected: variant.productId === product.productId,
				key: variant.productId,
				title: variant.title,
				url: variant.url,
			}))}
		/>
	);
}
StylePicker.displayName = 'ProductDetails_StylePicker';
