import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ArrowLink from 'components/ArrowLink';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import DiscountBubble from 'components/DiscountBubble';
import Img from 'components/Img';
import { LayoutContainer } from 'components/Layout';
import Link from 'components/Link';
import Text from 'components/Text';
import type { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import type { SitecoreLink } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';
import { getAsset } from 'utils/helpers';

interface TeaserItem {
	campaignBubbleLabel?: Field<string>;
	campaignBubbleText?: Field<string>;
	heading?: Field<string>;
	landscapeImage?: Field<DigizuiteAsset[]>;
	link?: Field<SitecoreLink>;
	video?: Field<DigizuiteAsset[]>;
}

type Props = JulaComponentProps & {
	fields?: {
		heading?: Field<string>;
		items?: TeaserItem[];
	};
};

export default function DoubleTeaser({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const teasers = filterTruthy(
		fields.items?.map((item) => ({
			campaignBubbleLabel: item.campaignBubbleLabel?.value,
			campaignBubbleText: item.campaignBubbleText?.value,
			heading: item?.heading,
			href: item?.link?.value.href,
			image: getAsset('Templated 2:1', item?.landscapeImage),
			linkTarget: item?.link?.value.target,
			linkText: item?.link?.value.text,
		})),
		'href',
		'image',
		'linkText',
	);

	if (teasers.length < 2) {
		return (
			<ComponentPlaceholder
				componentName="DoubleTeaser"
				className={getEditorMargin(params)}
				description="Requires two teaser items with link, link text and image"
			/>
		);
	}

	return (
		<LayoutContainer
			outerClassName={getEditorMargin(params)}
			id={params?.anchor}
		>
			<Text
				className="mb-4 md:mb-6"
				as={params?.heading ?? 'h2'}
				field={fields.heading}
			/>
			<div className="grid gap-6 md:grid-cols-2">
				{teasers.map(
					({
						campaignBubbleLabel,
						campaignBubbleText,
						heading: teaserHeading,
						href,
						image,
						linkTarget,
						linkText,
					}) => (
						<div
							key={`${href}-${linkText}-${teaserHeading?.value}`}
							className="relative col-span-1"
						>
							<Link href={href} isScreenReaderHidden>
								<Img
									src={image.src}
									alt={image.alt}
									width={810}
									height={404}
									service="nextjs"
									className="h-auto w-full object-cover"
								/>
								{campaignBubbleText && (
									<DiscountBubble
										label={campaignBubbleLabel}
										text={campaignBubbleText}
										isPositioned
									/>
								)}
							</Link>
							<Text as="h3" field={teaserHeading} className="mt-4 md:mt-6" />
							<ArrowLink
								className="mt-4"
								href={href}
								text={linkText}
								target={linkTarget}
							/>
						</div>
					),
				)}
			</div>
		</LayoutContainer>
	);
}
DoubleTeaser.displayName = 'DoubleTeaser';
