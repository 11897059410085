import React from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlockHero from 'components/BlockHero';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import { useEffectOnce, useProductListGTMEvents } from 'hooks';
import type { ColorField, JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import type { ProductCard } from 'models/productCard';
import type { SitecoreLink } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { cn } from 'utils/classNames';
import { filterTruthy } from 'utils/collection';
import { getAsset, is } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		campaignBubbleLabel?: Field<string>;
		campaignBubbleText?: Field<string>;
		datasourceName?: string;
		largeImage?: Field<DigizuiteAsset[]>;
		preventAutoplay?: Field<boolean>;
		primaryLink?: Field<SitecoreLink>;
		products?: ProductCard[];
		secondaryLink?: Field<SitecoreLink>;
		smallImage?: Field<DigizuiteAsset[]>;
		tertiaryLink?: Field<SitecoreLink>;
		textBackgroundColor?: ColorField;
		video?: Field<DigizuiteAsset[]>;
	};
};

export default function PromoHero({ fields, params }: Props) {
	const { gtmItemListId, gtmItemListName, sendViewItemListEvent } =
		useProductListGTMEvents(
			'block',
			`Block: ${fields?.datasourceName ?? 'PromoHeroUnknown'}`,
		);
	useEffectOnce(() => {
		if (fields?.products?.length) {
			sendViewItemListEvent(fields.products, fields.products.length);
		}
	});

	if (!fields) {
		return null;
	}

	const {
		largeImage,
		preventAutoplay,
		primaryLink,
		products,
		secondaryLink,
		smallImage,
		tertiaryLink,
		video,
	} = fields;
	const smallImg = getAsset('Templated 4:3', smallImage);
	const largeImg = getAsset('Templated 4:1', largeImage);
	const videoAsset = getAsset('Source Copy', video);

	const links = filterTruthy(
		[primaryLink, secondaryLink, tertiaryLink]
			.filter(is.truthy)
			.map((link) => link.value),
		'href',
		'text',
	);

	if ((!smallImg || !largeImg) && !video) {
		return (
			<ComponentPlaceholder
				componentName="PromoHero"
				className={getEditorMargin(params)}
				description="This component requires a 'Small Image' and a 'Large Image' or a 'Video' asset."
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={cn(getEditorMargin(params), 'max-md:mx-0')}
		>
			{largeImg && smallImg && (
				<BlockHero
					basePriceTextBackground={
						fields.textBackgroundColor?.fields?.colorHex?.value
					}
					campaignBubbleLabel={fields.campaignBubbleLabel?.value}
					campaignBubbleText={fields.campaignBubbleText?.value}
					gtmItemListId={gtmItemListId}
					gtmItemListName={gtmItemListName}
					largeImageAlt={largeImg.alt}
					largeImageSrc={largeImg.src}
					links={links}
					preventAutoplay={preventAutoplay?.value}
					products={products}
					primaryLinkHref={primaryLink?.value.href}
					smallImageAlt={smallImg.alt}
					smallImageSrc={smallImg.src}
					video={videoAsset}
				/>
			)}
		</LayoutContainer>
	);
}
PromoHero.displayName = 'PromoHero';
