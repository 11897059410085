import React from 'react';

import ErrorBoundary from 'components/ErrorBoundary';
import { LayoutContainer } from 'components/Layout';
import ProductCard from 'components/ProductCard';
import { SkeletonItem } from 'components/Skeleton';
import Slider from 'components/Slider';
import Text from 'components/Text';
import { ProductCard as ProductCardType } from 'models/productCard';
import { is, range } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import { PRODUCT_SIMILAR_PRODUCTS_ID } from './helpers/constants';

interface Props {
	className?: string;
	isLoadingInitialSimilarProducts: boolean;
	productId: string;
	similarProducts: ProductCardType[] | undefined;
}

export default function ProductDetailsSimilarProducts({
	className,
	isLoadingInitialSimilarProducts,
	productId,
	similarProducts,
}: Props) {
	const { t } = useI18n();

	if (
		!isLoadingInitialSimilarProducts &&
		!is.arrayWithLength(similarProducts)
	) {
		return null;
	}

	return (
		<ErrorBoundary isPageWidth>
			<LayoutContainer
				id={PRODUCT_SIMILAR_PRODUCTS_ID}
				outerClassName={className}
			>
				<Text as="h2" className="mb-2 md:mb-6">
					{t('product_details_similar_products_heading')}
				</Text>
				<Slider
					items={
						isLoadingInitialSimilarProducts
							? range(5).map((i) => <SkeletonItem key={i} height="24rem" />)
							: (similarProducts?.map((similarProduct, i) => (
									<ProductCard
										key={similarProduct.id}
										product={similarProduct}
										productListIndex={i}
										gtmItemListId="pdp_similar_products"
										gtmItemListName={`List: Similar products ${productId}`}
										className="pr-4 md:pr-6"
									/>
								)) ?? [])
					}
				/>
			</LayoutContainer>
		</ErrorBoundary>
	);
}
ProductDetailsSimilarProducts.displayName = 'ProductDetailsSimilarProducts';
