import React, { useCallback, useEffect } from 'react';
import { useSelector } from '@xstate/react';
import { useRouter } from 'next/router';
import { waitFor } from 'xstate/lib/waitFor';

import AuthJulaConnect from 'components/AuthJulaConnect';
import { LayoutContainer } from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import { ProLogin } from 'components/Login';
import Popover from 'components/Popover';
import { CheckoutProgressSteps } from 'components/ProgressSteps';
import Tabs from 'components/Tabs';
import {
	useCheckoutContext,
	useFeatureToggle,
	useGlobalMachinesContext,
	useGlobalMemberPopoversContext,
} from 'contexts';
import type { LoginMethod } from 'models/connect/oauth';
import {
	selectErrorMessage,
	selectIsAuthenticatedUser,
	selectIsAwaitingAuthAction,
	selectIsInitialisingUser,
	selectIsWaitingForRefresh,
} from 'state-machines/authentication';
import {
	selectCartHasLoaded,
	selectIsInErrorState as selectCheckoutIsInErrorState,
	selectUserInfoActor,
} from 'state-machines/checkout/';
import {
	selectCustomerInfo,
	selectEmail,
	selectPostalCode,
} from 'state-machines/checkout/userInfo/';
import { LoginPanelType } from 'types';
import { getLoginOptions } from 'utils/business-logic';
import { getTestDataAttrFrom, ignorePromiseRejection } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import CheckoutClubLogin from './CheckoutClubLogin';
import CheckoutCustomerIdentification from './CheckoutCustomerIdentification';

export default function CheckoutIdentificationPage() {
	const { t } = useI18n();
	const { julaProEnabled } = useFeatureToggle();

	const { authService, userService } = useGlobalMachinesContext();
	const { checkoutService } = useCheckoutContext();
	const userInfoActor = useSelector(checkoutService, selectUserInfoActor);
	const {
		activeTab,
		closeLogin,
		isLoginOpen,
		openJulaClubLogin,
		openJulaProLogin,
		openSignUpJulaPro,
		setActiveTab,
	} = useGlobalMemberPopoversContext();

	const userInfoActorEmail = useSelector(userInfoActor, selectEmail);
	const userInfoActorPostalCode = useSelector(userInfoActor, selectPostalCode);

	const customerInfo = useSelector(userInfoActor, selectCustomerInfo);
	const isAuthenticatedUser = useSelector(
		userService,
		selectIsAuthenticatedUser,
	);
	const errorMessage = useSelector(authService, selectErrorMessage);
	const isAwaitingAuthAction = useSelector(
		authService,
		selectIsAwaitingAuthAction,
	);
	const router = useRouter();

	// if a user wants to switch login type and triggers a cancel
	// we use the machine going back to 'awaitingAuthAction'
	// as a way to know that the auth popover should be closed
	useEffect(() => {
		if (isAwaitingAuthAction) {
			closeLogin();
		}
	}, [closeLogin, isAwaitingAuthAction]);

	const goToCheckout = useCallback(() => {
		ignorePromiseRejection(router.replace('/checkout/'));
	}, [router]);

	useEffect(() => {
		if (isAuthenticatedUser) {
			goToCheckout();
		}
	}, [goToCheckout, isAuthenticatedUser]);

	const handleSubmit = async (values: {
		email: string;
		postalCode: string;
	}) => {
		const { email } = values;
		const postalCode: string =
			values.postalCode || userInfoActorPostalCode || '';

		userInfoActor.send({
			type: 'UPDATE_USER_INFO_FROM_STEP_TWO_SIGN_IN',
			email,
			postalCode: postalCode.replaceAll(/\s+/g, ''),
		});

		const userInfoMachineState = await waitFor(
			userInfoActor,
			(state) => state.matches('idle'),
			{
				timeout: 120_000,
			},
		);

		const formErrors: { email?: string[]; postalCode?: string[] } = {};
		if (
			userInfoMachineState.context?.error?.find(
				(error) => error.key === 'customerAddress.postalCode',
			)
		) {
			formErrors.postalCode = userInfoMachineState.context?.error
				?.filter((error) => error.key === 'customerAddress.postalCode')
				?.map((error) => error.description);
		}
		if (formErrors?.postalCode || formErrors?.email) {
			return formErrors;
		}
		if (!userInfoMachineState.context.customer?.isCustomer) {
			goToCheckout();
		}
		return undefined;
	};

	// if we're not done with authentication
	const isInitialisingUser = useSelector(userService, selectIsInitialisingUser);
	// if we're signing in on this page we need to show the loading spinner
	const isWaitingForRefresh = useSelector(
		authService,
		selectIsWaitingForRefresh,
	);
	const isCheckoutInErrorState = useSelector(
		checkoutService,
		selectCheckoutIsInErrorState,
	);
	const hasCartLoaded = useSelector(checkoutService, selectCartHasLoaded);

	const isLoading =
		isInitialisingUser ||
		isWaitingForRefresh ||
		isCheckoutInErrorState ||
		!hasCartLoaded;

	if (isLoading) {
		return (
			<LoadingSpinner className="fixed left-1/2 top-1/2 z-pageLoadingSpinner -mb-20 -ml-20 -mt-20 mr-0" />
		);
	}

	return (
		<LayoutContainer maxWidth="narrow" outerClassName="my-4 md:my-8">
			<Popover
				isOpen={isLoginOpen}
				title={t('account_login_button')}
				onClose={() => {
					closeLogin();
					if (!isAuthenticatedUser) {
						authService.send('RELOAD_IFRAME');
					}
				}}
				contentClassName="flex flex-col"
			>
				<AuthJulaConnect className="grow" />
			</Popover>

			<CheckoutProgressSteps currentStep={2} />

			<div className="mx-auto mt-8 max-w-[30rem] rounded-md border-grey sm:mt-12 sm:border sm:p-8">
				<Tabs<LoginPanelType>
					data-cy={getTestDataAttrFrom('checkout-identification-tabs')}
					tabListLabel={t('checkout_stepper_second_step_label')}
					activeTabId={activeTab}
					onTabChange={(tab) => {
						setActiveTab(tab);
					}}
					items={[
						{
							id: 'LoginJulaClub',
							title: t('checkout_login_identification_individual_heading'),
							content: customerInfo?.isCustomer ? (
								<CheckoutClubLogin
									customerEmail={userInfoActorEmail}
									customerFirstName={customerInfo?.firstName}
									errorMessage={errorMessage && t(errorMessage)}
									loginOptions={getLoginOptions('Club Staff')}
									onSelectLoginMethod={(loginMethod: LoginMethod) => {
										authService.send({
											type: 'SELECT_LOGIN_CLUB_STAFF',
											loginMethod,
											customerNumber: customerInfo?.customerNumber,
										});
										openJulaClubLogin();
									}}
									onChangeEmailClick={() => {
										userInfoActor.send({
											type: 'RESET_USER_INFO_FROM_STEP_TWO_SIGN_IN',
										});
									}}
								/>
							) : (
								<CheckoutCustomerIdentification
									userInfoActorEmail={userInfoActorEmail}
									userInfoActorPostalCode={userInfoActorPostalCode}
									onSubmit={handleSubmit}
								/>
							),
						},
						julaProEnabled &&
							!customerInfo?.isCustomer && {
								id: 'LoginJulaPro',
								title: t('checkout_login_identification_business_heading'),
								content: (
									<ProLogin
										errorMessage={errorMessage}
										onSelectLoginMethod={(loginMethod: LoginMethod) => {
											authService.send({
												type: 'SELECT_LOGIN_PRO',
												loginMethod,
											});
											openJulaProLogin();
										}}
										onBecomeMemberClick={() => openSignUpJulaPro()}
									/>
								),
							},
					]}
				/>
			</div>
		</LayoutContainer>
	);
}
CheckoutIdentificationPage.displayName = 'CheckoutIdentificationPage';
