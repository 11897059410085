import type { CartError } from 'models/api';

import type { CartMachineState } from './cart.machine';
import { isAnonUserWithRequiredDetails } from './helpers';

export const selectCartQuantity = (state: CartMachineState) =>
	state.context.totalQty;

export const selectVariants = (state: CartMachineState) =>
	state.context.cart?.variants;

export const selectCheckoutStatus = (state: CartMachineState) =>
	state.context.cart?.checkoutStatus;

export const selectBonusDiscounts = (state: CartMachineState) =>
	state.context.cart?.bonusDiscounts;

export const selectGiftCards = (state: CartMachineState) =>
	state.context.cart?.giftCards;

export const selectCampaignDiscount = (state: CartMachineState) =>
	state.context.cart?.campaignDiscount;

export const selectErrorList = (
	state: CartMachineState,
): CartError[] | undefined => state.context.cart?.errorList;

export const selectPurchaseButtonState = (state: CartMachineState) =>
	state.context.purchaseButtonState;

export const selectFreightDiscount = (state: CartMachineState) =>
	state.context.cart?.freightDiscount;

export const selectAdditionalSalesProducts = (state: CartMachineState) =>
	state.context.additionalSalesProducts;

export const selectCartMiniMessage = (state: CartMachineState) =>
	state.context.cartMiniMessage;

export const selectCartId = (state: CartMachineState) => state.context.id;

export const selectAddedProductPopoverIsOpen = (state: CartMachineState) =>
	state.context.addedProductPopoverIsOpen;

export const selectIsLoadingCart = (state: CartMachineState) =>
	state.hasTag('loadingCart');

export const selectIsInitialLoading = (state: CartMachineState) =>
	state.hasTag('loadingCart') && !state.context.cart;

export const selectAnonUserIsReadyForCheckout = (
	state: CartMachineState,
): boolean => isAnonUserWithRequiredDetails(state.context.cart);

export const selectTotalSummary = (state: CartMachineState) =>
	state.context.cart?.totalSummary;

export const selectSavingTotalSumSummary = (state: CartMachineState) =>
	state.context.cart?.savingSummaries.find(
		(summary) => summary.sumType === 'SavingTotalSum',
	);

export const selectSavingSummaries = (state: CartMachineState) =>
	state.context.cart?.savingSummaries;

export const selectAddedProduct = (state: CartMachineState) =>
	state.context.addedProduct;
