import React, { type FieldsetHTMLAttributes } from 'react';

import type { WithOptional, WithRequired } from 'types';
import { cnm } from 'utils/classNames';
import { slugify } from 'utils/string';

import type { RadioCheckboxColor } from './helpers';
import Radio, { type Props as RadioProps } from './Radio';
import RadioGroupFieldset from './RadioGroupFieldset';

export interface Props
	extends Omit<FieldsetHTMLAttributes<HTMLFieldSetElement>, 'onChange'> {
	className?: string;
	color?: RadioCheckboxColor;
	disabled?: boolean;
	errorMessage?: string | string[];
	helpText?: string;
	hiddenLegend?: boolean;
	id: string;
	invalid?: boolean;
	legend: string;
	legendClassName?: string;
	name: string;
	onChange: RadioProps['onChange'];
	// Omit name since it's set on the group, make id optional since it can be
	// generated by the group and make value required so the group's value can
	// be based on something.
	options: Omit<
		WithOptional<WithRequired<RadioProps, 'value'>, 'id'>,
		'name'
	>[];
	required?: boolean;
	value: string;
}

export default function RadioGroup({
	className,
	color,
	disabled = false,
	errorMessage,
	helpText,
	hiddenLegend,
	id,
	invalid = false,
	legend,
	legendClassName,
	name,
	onChange,
	options,
	required,
	value,
}: Props) {
	return (
		<RadioGroupFieldset
			className={className}
			disabled={disabled}
			errorMessage={errorMessage}
			helpText={helpText}
			hiddenLegend={hiddenLegend}
			id={id}
			invalid={invalid}
			legend={legend}
			legendClassName={legendClassName}
			required={required}
		>
			{options.map(
				(
					{ className: radioClassName, id: inputId, label, ...radioProps },
					i,
				) => {
					const radioId = inputId || slugify(`${name}-${label}`);
					return (
						<Radio
							key={radioId}
							checked={value === radioProps.value}
							className={cnm(
								hiddenLegend && i === 0 ? undefined : 'mt-4',
								radioClassName,
							)}
							color={color}
							disabled={disabled}
							id={radioId}
							label={label}
							name={name}
							onChange={onChange}
							{...radioProps}
						/>
					);
				},
			)}
		</RadioGroupFieldset>
	);
}
RadioGroup.displayName = 'FormUi_RadioGroup';
