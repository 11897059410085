import React from 'react';

import Button from 'components/Button';
import ExpandableDescription from 'components/ExpandableDescription';
import Img from 'components/Img';
import InfoBox from 'components/InfoBox';
import { LayoutContainer } from 'components/Layout';
import StoreInfo from 'components/StoreInfo';
import { useSelectedStore } from 'contexts';
import { JulaComponentProps } from 'lib/component-props';
import type { Store } from 'models/sitecore';
import { getAsset } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields: { store: Store };
};

export default function StoreIntro({ fields }: Props) {
	const { selectedStore, setSelectedStore } = useSelectedStore();
	const { t } = useI18n();
	if (!fields) {
		return null;
	}
	const {
		city,
		id,
		information,
		latitude,
		longitude,
		name,
		postalCode,
		regularOpeningHours,
		specialOpeningHours,
		storeArea,
		streetAddress,
		todaysOpeningHours,
	} = fields.store;

	const { description, header, storeHeaderImage } = information ?? {};
	const storeImg =
		storeHeaderImage && getAsset('Templated 2:1', storeHeaderImage);
	const isSelected = selectedStore?.id === id;
	return (
		<LayoutContainer>
			<div className="grid grid-cols-4 gap-4 sm:grid-flow-col sm:grid-cols-8 sm:gap-6 lg:grid-cols-12">
				<div className="col-span-4 md:col-span-3">
					<StoreInfo
						name={name}
						headingLevel="h1"
						todaysOpeningHours={todaysOpeningHours}
						storeArea={storeArea}
						streetAddress={streetAddress}
						postalCode={postalCode}
						city={city}
						regularOpeningHours={regularOpeningHours}
						specialOpeningHours={specialOpeningHours}
						afterAddressContent={
							<div className="mb-8 mt-6 flex flex-col gap-2 md:mb-14">
								{!isSelected && (
									<Button
										variant="cta"
										onClick={() => setSelectedStore({ id, name })}
									>
										{t('stores_pick_as_store_button')}
									</Button>
								)}
								{isSelected && (
									<InfoBox
										variant="success"
										icon="check"
										message={t('stores_store_picked_as_store_text', {
											storeName: name,
										})}
									/>
								)}
								<Button
									variant="secondary"
									target="_blank"
									href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
								>
									{t('stores_directions_button')}
								</Button>
							</div>
						}
					/>
				</div>

				<div className="col-span-4 sm:col-span-5 sm:col-start-4 lg:col-span-7 lg:col-start-6">
					{storeImg && (
						<div className="mt-8 max-sm:-mx-4 sm:mt-14">
							<Img
								src={storeImg.src}
								alt={storeImg.alt}
								width={949}
								height={475}
								service="nextjs"
							/>
						</div>
					)}
					{(header?.value || description?.value) && (
						<ExpandableDescription
							className="mt-4"
							heading={header?.value}
							showHeading
							description={description?.value}
							textLengthBreakPoint={600}
						/>
					)}
				</div>
			</div>
		</LayoutContainer>
	);
}
StoreIntro.displayName = 'StoreIntro';
