import React, {
	createContext,
	type ReactNode,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';

import { hasZendeskSession } from 'components/ZendeskChat';
import { useGlobalEvent, useValueChangeEffect } from 'hooks';
import { sendGlobalEvent } from 'utils/helpers';

export interface ChatContextType {
	activateChatWidget: () => void;
	/** If the chat widget should be loaded at all. */
	chatWidgetIsActive: boolean;
	/** If the chat widget is loaded and ready to be interacted with. */
	chatWidgetIsReady: boolean;
	messageCount: number;
}
export const ChatContext = createContext<ChatContextType>({} as any);
ChatContext.displayName = 'ChatContext';

export function ChatContextProvider({ children }: { children: ReactNode }) {
	const [messageCount, setMessageCount] = useState(0);
	const [chatWidgetIsActive, setIsChatWidgetActive] =
		useState(hasZendeskSession());
	const [chatWidgetIsReady, setIsChatWidgetReady] = useState(false);

	useGlobalEvent('chat-widget-unread-count', ({ detail }) => {
		setMessageCount(detail.count);
	});

	useGlobalEvent('chat-widget-init', () => {
		setIsChatWidgetReady(true);
	});

	// Separate from the init event and triggered in an effect to ensure the
	// chatWidgetIsReady state is set when listeners run.
	useValueChangeEffect(chatWidgetIsReady, () => {
		if (chatWidgetIsReady) {
			sendGlobalEvent('chat-widget-ready');
		}
	});

	useGlobalEvent('chat-widget-reset', () => {
		setMessageCount(0);
		setIsChatWidgetActive(false);
		setIsChatWidgetReady(false);
	});

	const activateChatWidget = useCallback(() => {
		setIsChatWidgetActive(true);
	}, []);

	const value = useMemo(
		() => ({
			chatWidgetIsActive,
			chatWidgetIsReady,
			messageCount,
			activateChatWidget,
		}),
		[activateChatWidget, chatWidgetIsActive, chatWidgetIsReady, messageCount],
	);

	return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}
ChatContextProvider.displayName = 'ChatContextProvider';

export function useChatContext() {
	return useContext(ChatContext);
}
