/**
 * ProductLine
 */

import React from 'react';
import type { AnchorHTMLAttributes } from 'react';

import HighlightedText from 'components/HighlightedText';
import Img from 'components/Img';
import { OptionalLink } from 'components/Link';
import Price from 'components/Price';
import Text from 'components/Text';
import { ProductCard } from 'models/productCard';
import { getProductImageSrc } from 'utils/business-logic';
import { cn } from 'utils/classNames';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
	/** Additional class names on the anchor. */
	className?: string;

	/** Text to highlight in the product title. */
	highlight?: string;

	/** Product data. */
	product: ProductCard;
}

/** A slim line for a product with image, title and price. */
export default function ProductLine({
	className,
	highlight,
	product,
	...attrs
}: Props) {
	return (
		<OptionalLink
			key={product.id}
			href={product.url}
			className={cn('group flex items-center', className)}
			{...attrs}
		>
			<Img
				src={getProductImageSrc(product)}
				width={40}
				height={40}
				service="nextjs"
				loading="eager"
				className="mr-2 size-10 shrink-0"
				useFallbackOnError
			/>
			<Text as="span" className="mr-6 grow group-hover:underline">
				{highlight ? (
					<HighlightedText text={product.title} highlight={highlight} />
				) : (
					product.title
				)}
			</Text>
			{!product.expired && product.displayPrice && (
				<Price price={product.displayPrice} size="microCompact" />
			)}
		</OptionalLink>
	);
}
ProductLine.displayName = 'ProductLine';
