import React from 'react';

import Icon, { type IconName } from 'components/Icon';
import { cn } from 'utils/classNames';

interface Step {
	className?: string;
	completed?: boolean;
	icon?: IconName;
	text?: string;
}

interface Props {
	className?: string;
	equalSizeSteps?: boolean;
	steps: Step[];
	stepsClassName?: string;
}

export default function ProgressStepsHorizontal({
	className,
	equalSizeSteps = true,
	steps,
	stepsClassName,
}: Props) {
	const currentStep = steps.findIndex((s) => !s.completed);
	const totalStepCount = steps.length;
	const hasText = steps.some((s) => s.text);

	return (
		<ol className={cn('relative flex w-full justify-between', className)}>
			{steps.map(({ className: stepClassName, completed, icon, text }, i) => {
				const isFirst = i === 0;
				const isLast = i === totalStepCount - 1;
				const isCurrent = i === currentStep;
				return (
					// eslint-disable-next-line react/no-array-index-key
					<React.Fragment key={i}>
						{!isFirst && (
							// Line between steps
							<li
								aria-hidden
								className="mt-[calc(1rem-1px)] grow self-start border-t-2 border-t-greyDark"
							/>
						)}
						<li
							className={cn(
								stepsClassName,
								stepClassName,
								'relative flex flex-col items-center',
								equalSizeSteps && hasText && 'min-w-16 flex-1',
								equalSizeSteps && !hasText && 'min-w-10 flex-shrink basis-0',
								// Connecting lines from the step circle to the lines between steps
								!isFirst && [
									'before:left-0',
									'before:absolute',
									'before:top-0',
									'before:block',
									// Minus half the circle width and its margin,
									// keep in sync sizing below.
									'before:w-[calc(50%-1rem-0.25rem)]',
									'before:mt-[calc(1rem-1px)]',
									'before:border-t-2',
									'before:border-t-greyDark',
								],
								!isLast && [
									'after:right-0',
									'after:absolute',
									'after:top-0',
									'after:block',
									// Minus half the circle width and its margin,
									// keep in sync sizing below.
									'after:w-[calc(50%-1rem-0.25rem)]',
									'after:mt-[calc(1rem-1px)]',
									'after:border-t-2',
									'after:border-t-greyDark',
								],
							)}
							aria-current={isCurrent ? 'step' : undefined}
						>
							<span
								aria-hidden={Boolean(text)}
								className={cn(
									// Size and spacing, keep in sync with lines above.
									'mx-1 size-8',
									'flex items-center justify-center rounded-full border border-transparent font-bold',
									(isCurrent || completed) && 'bg-julaRed text-white',
									!completed && 'bg-greyLighter',
								)}
							>
								<span className={completed || icon ? 'sr-only' : undefined}>
									{i + 1}
								</span>
								{!completed && icon && <Icon name={icon} color="grey" />}
								{completed && <Icon name="check" />}
							</span>
							{text && (
								<span
									className={cn(
										'mx-1 mt-2 inline-block text-center max-sm:text-sm',
										isCurrent && 'font-bold',
										steps.length >= 4 && 'max-[400px]:text-xs',
									)}
								>
									{text}
								</span>
							)}
						</li>
					</React.Fragment>
				);
			})}
		</ol>
	);
}
ProgressStepsHorizontal.displayName = 'ProgressStepsHorizontal';
