import React from 'react';
import dynamic from 'next/dynamic';

import LoadingSpinner from 'components/LoadingSpinner';

import type { Props } from './StoreMap';

const StoreMapSuspense = dynamic(() => import('./StoreMap'), {
	loading: () => <LoadingSpinner size="medium" />,
});

export default function DynamicStoreMap(props: Props) {
	return <StoreMapSuspense {...props} />;
}
DynamicStoreMap.displayName = 'DynamicStoreMap';
