import React from 'react';

interface Props {
	brandTitle: string | undefined;
	model: string | undefined;
}

export default function ProductPopoverSubtitle({ brandTitle, model }: Props) {
	const subheading = model ? `${brandTitle} | ${model}` : brandTitle;
	return <p>{subheading}</p>;
}
ProductPopoverSubtitle.displayName = 'ProductPopoverSubtitle';
