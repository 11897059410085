/**
 * TabList
 */

import React from 'react';
import type { ReactNode } from 'react';

import { HTMLAttributes } from 'types';
import { cn, cnm } from 'utils/classNames';

export type Orientation = 'horizontal' | 'vertical';

export type NavigationDirection = 'back' | 'forward';

interface Props extends HTMLAttributes<HTMLDivElement> {
	/** The currently active tab's position in the tab list */
	activeTabIndex: number;

	/** Tab elements */
	children: ReactNode;

	/** Event handler for arrow key navigation */
	onArrowKey: (direction: NavigationDirection) => void;

	/** Currently unused. Orientation of the tab elements, decides the arrow key navigation direction */
	orientation?: Orientation;

	/** Total number of tabs */
	tabCount: number;
}

const WIDTH_CLASS = ['w-full', 'w-1/2', 'w-1/3', 'w-1/4', 'w-1/5', 'w-1/6'];

/** Container for the Tab elements. */
export default function TabList({
	activeTabIndex,
	children,
	className,
	onArrowKey,
	orientation = 'horizontal',
	tabCount,
	...attrs
}: Props) {
	// Handling bubbling keydown event here, it comes from real buttons.
	/* eslint-disable jsx-a11y/interactive-supports-focus */

	return (
		<div
			{...attrs}
			role="tablist"
			aria-orientation={orientation}
			className={cnm('relative mb-8 flex', className)}
			onKeyDown={(e) => {
				const prevArrow =
					orientation === 'horizontal' ? 'ArrowLeft' : 'ArrowUp';
				const nextArrow =
					orientation === 'horizontal' ? 'ArrowRight' : 'ArrowDown';
				if ([nextArrow, prevArrow].includes(e.key)) {
					onArrowKey(e.key === prevArrow ? 'back' : 'forward');
				}
			}}
		>
			{children}
			{/* Line for the active tab. Using border for Windows high contrast mode support. */}
			<div
				className={cn(
					'absolute bottom-0 left-0 h-1 rounded-full border-2 border-julaRed bg-julaRed transition-transform',
					WIDTH_CLASS[tabCount - 1],
				)}
				style={{ transform: `translateX(${100 * activeTabIndex}%)` }}
			/>
		</div>
	);
}
TabList.displayName = 'TabList';
