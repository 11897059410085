import React, { type FieldsetHTMLAttributes, type ReactNode } from 'react';

import { cnm } from 'utils/classNames';

import InputInfo from './InputInfo';
import LabelText from './LabelText';

export interface Props extends FieldsetHTMLAttributes<HTMLFieldSetElement> {
	children: ReactNode;
	className?: string;
	disabled?: boolean;
	errorMessage?: string | string[];
	helpText?: string;
	hiddenLegend?: boolean;
	id: string;
	invalid?: boolean;
	legend: string;
	legendClassName?: string;
	required?: boolean;
}

export default function RadioGroupFieldset({
	children,
	className,
	disabled = false,
	errorMessage,
	helpText,
	hiddenLegend,
	id,
	invalid = false,
	legend,
	legendClassName,
	required,
}: Props) {
	const errorMessageId = `${id}-error`;
	const helpTextId = `${id}-help`;

	return (
		<fieldset
			className={className}
			// https://adrianroselli.com/2022/02/support-for-marking-radio-buttons-required-invalid.html
			// False positive, radiogroup is allowed on fieldset.
			// eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
			role="radiogroup"
			aria-required={required}
			aria-invalid={invalid}
			aria-describedby={helpTextId}
		>
			<legend
				className={cnm(
					'font-bold',
					disabled && 'opacity-50',
					hiddenLegend && 'sr-only',
					legendClassName,
				)}
			>
				<LabelText required={required}>{legend}</LabelText>
			</legend>
			{children}
			<InputInfo
				invalid={invalid}
				errorMessage={errorMessage}
				errorMessageId={errorMessageId}
				helpText={helpText}
				helpTextId={helpTextId}
				className="ml-8 md:ml-7"
			/>
		</fieldset>
	);
}
RadioGroupFieldset.displayName = 'FormUi_RadioGroupFieldset';
