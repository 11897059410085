/**
 * Skiplink
 */

import React from 'react';

import { AnchorLink } from 'components/Link';
import { cn } from 'utils/classNames';

interface Props {
	/** Extra class names */
	className?: string;

	/** Scroll to target element? */
	scroll?: boolean;

	/** Element ID to target */
	target: string;

	/** Link text */
	text: string;
}

/** Hidden block link that becomes visible on focus. */
export default function Skiplink({
	className,
	scroll = true,
	target,
	text,
}: Props) {
	return (
		<AnchorLink
			anchor={target}
			scroll={scroll}
			className={cn(
				className,
				'sr-only flex items-center justify-center bg-greyDarker text-center font-bold text-white',
				'focus:not-sr-only focus:min-h-[3.5rem] focus:w-full focus:p-3 focus:underline',
				'md:text-lg',
			)}
		>
			{text}
		</AnchorLink>
	);
}
Skiplink.displayName = 'Skiplink';
