/**
 * Services
 */

import { waitFor } from 'xstate/lib/waitFor';

import type { Cart, CartRequest, VerifyPaymentRequest } from 'models/api';
import type { UpdatedCartProperties } from 'state-machines/checkout/checkout.machine.types';
import type { UserInfoMachineActor } from 'state-machines/checkout/userInfo';
import { formatCartForRequest } from 'utils/business-logic';
import { API_URL, fetchData } from 'utils/fetch';

export function fetchNewCart() {
	return fetchData<Cart>(`${API_URL}Cart`, { method: 'POST' });
}

export function fetchSpecificCart(cartId: string | undefined) {
	return fetchData<Cart>(`${API_URL}Cart/${cartId}`, { method: 'GET' });
}

export function updateCart(
	cart: Cart | undefined,
	updatedCartProperties: UpdatedCartProperties | undefined,
) {
	if (!cart) {
		throw new Error('Cart is undefined');
	}
	const updatedCart: CartRequest = formatCartForRequest(
		cart,
		updatedCartProperties,
	);

	return fetchData<Cart>(`${API_URL}Cart/${cart?.id}`, {
		method: 'PUT',
		body: JSON.stringify(updatedCart),
	});
}

/**
 * Verify redirect payment
 * - Verifies a redirect payment and returns the full cart.
 * - @see: https://apigw-fe-dev01.juladev.se/api/v1/docs/index.html#operations-Cart-post_api_v1_Cart__cartId__verifyredirectpayment
 */
export function verifyRedirectPayment(
	cartId: string | undefined,
	payload: VerifyPaymentRequest | undefined,
) {
	return fetchData<Cart>(`${API_URL}Cart/${cartId}/verifyredirectpayment`, {
		method: 'POST',
		body: payload ? JSON.stringify(payload) : undefined,
	});
}

/**
 * Copy cart
 * - Copies cart (e.g. after requesting /Cart/checkout the cart is locked and can't be modified,
 *   so if a payment failes we need to copy it to be able to make changes to it).
 * - @see: https://apigw-fe-dev01.juladev.se/api/v1/docs/index.html#operations-Cart-post_api_v1_Cart__cartId__copy
 */
export function copyCart(cartId: string | undefined) {
	return fetchData<Cart>(`${API_URL}Cart/${cartId}/copy`, { method: 'POST' });
}

export function checkoutCart(cart: Cart | undefined) {
	if (!cart) {
		throw new Error('Cart is undefined');
	}
	const updatedCart: CartRequest = formatCartForRequest(cart);

	return fetchData<Cart>(`${API_URL}Cart/checkout`, {
		method: 'POST',
		body: JSON.stringify(updatedCart),
	});
}

export function waitForUserInfoActor(actor: UserInfoMachineActor) {
	return waitFor(actor, (state) => state.matches('idle'), {
		timeout: 120_000,
	});
}
