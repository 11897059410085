import React from 'react';

import Button from 'components/Button';
import Img from 'components/Img';
import Popover from 'components/Popover';
import Price from 'components/Price';
import ProductCard from 'components/ProductCard';
import Text from 'components/Text';
import { useProductListGTMEvents } from 'hooks';
import { Price as PriceModel } from 'models/price';
import { RelatedProductCard } from 'models/productCard';
import { ActionButtonState } from 'state-machines/actionButton.machine';
import { getProductImageSrc } from 'utils/business-logic';
import type { GTMItemListId, GTMItemListName } from 'utils/GoogleTagManager';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface ProductCardRowSlimProps {
	heading: string;
	imageSrc: string;
	price: PriceModel | undefined;
	subHeading: string | undefined;
}

function ProductCardRowSlim({
	heading,
	imageSrc,
	price,
	subHeading,
}: ProductCardRowSlimProps) {
	return (
		<article className="flex w-full">
			<Img
				src={imageSrc}
				width={96}
				height={96}
				service="nextjs"
				useFallbackOnError
				className="mr-4 size-24 object-contain"
			/>
			<div className="grow">
				<div>
					<Text as="h3" styleAs="h6" className="mb-2">
						{heading}
					</Text>
					{subHeading}
				</div>
				{price && <Price size="small" price={price} />}
			</div>
		</article>
	);
}

ProductCardRowSlim.displayName = 'ProductCardRowSlim';

interface Props {
	addedLocationLink: string;
	addedProduct: RelatedProductCard | undefined;
	buttonState: { buttonId?: string; state: ActionButtonState };
	buttonText?: string;
	goToAddedLocationButtonText?: string;
	gtmItemListId: GTMItemListId;
	gtmItemListName: GTMItemListName;
	id: string;
	isOpen: boolean;
	onAddItem: (product: RelatedProductCard) => void;
	onClose: () => void;
	products: RelatedProductCard[] | undefined;
	testId: string;
	title: string;
}

export default function AddedProductPopover({
	addedLocationLink,
	addedProduct,
	buttonState,
	buttonText,
	goToAddedLocationButtonText,
	gtmItemListId,
	gtmItemListName,
	id,
	isOpen,
	onAddItem,
	onClose,
	products,
	testId,
	title,
}: Props) {
	const { sendViewItemListEvent } = useProductListGTMEvents(
		gtmItemListId,
		gtmItemListName,
	);
	const { t } = useI18n();
	return (
		<Popover
			isOpen={isOpen}
			id={id}
			title={title}
			onOpen={() => {
				if (products?.length) {
					sendViewItemListEvent(products, products.length);
				}
			}}
			onClose={onClose}
			padFooter
			footer={
				<>
					<Button
						href={addedLocationLink}
						displayWidth="full"
						variant="cta"
						className="mb-2"
						data-cy={`${testId}-continue`}
					>
						{goToAddedLocationButtonText}
					</Button>
					<Button
						onClick={onClose}
						displayWidth="full"
						variant="secondary"
						data-cy={`${testId}-close`}
					>
						{t('additional_sales_popover_continue_shopping_button')}
					</Button>
				</>
			}
		>
			{addedProduct && (
				<ProductCardRowSlim
					heading={addedProduct.title}
					subHeading={addedProduct.brand?.title}
					price={addedProduct.displayPrice}
					imageSrc={getProductImageSrc(addedProduct, true)}
				/>
			)}

			{is.arrayWithLength(products) && (
				<>
					<Text
						className="mb-4 mt-6"
						as="h3"
						text={t('additional_sales_popover_related_products_heading')}
					/>
					{products?.map((product, i) => (
						<ProductCard
							showAddToCartButton
							orientation="row"
							key={product.id}
							onPurchaseButtonClick={() => onAddItem(product)}
							product={product}
							productListIndex={i}
							gtmItemListId={gtmItemListId}
							gtmItemListName={gtmItemListName}
							actionButtonState={
								product.id === buttonState.buttonId
									? buttonState.state
									: undefined
							}
							buttonText={buttonText}
						/>
					))}
				</>
			)}
		</Popover>
	);
}

AddedProductPopover.displayName = 'AddedProductPopover';
