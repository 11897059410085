import { assign, createMachine, type StateFrom } from 'xstate';

import type { ActionButtonState } from 'components/ActionButton';
import { sendToast } from 'components/Toast';
import type { ErrorObject } from 'errors';

import {
	requestJulaClubAccountApiUpdateCustomerInformation,
	requestJulaProAccountApiUpdateContactInformation,
} from './account.services';
import type {
	AccountMachineContext,
	AccountMachineEvents,
} from './account.types';

export const accountMachine = createMachine(
	{
		id: 'accountMachine',
		type: 'parallel',
		schema: {
			context: {} as AccountMachineContext,
			events: {} as AccountMachineEvents,
		},
		tsTypes: {} as import('./account.machine.typegen').Typegen0,
		context: {
			submitButtonState: 'idle',
		},
		initial: 'idle',
		states: {
			updateContactInformation: {
				id: 'updateContactInformation',
				description: 'Update information about the current contact',
				initial: 'idle',
				states: {
					idle: {
						description:
							'Idle state waiting for contact information update event',
						on: {
							UPDATE_CONTACT_INFORMATION: {
								target: 'updateContactInformation',
							},
						},
					},
					updateContactInformation: {
						description: 'Updating contact information',
						entry: 'setSubmitButtonStateLoading',
						invoke: {
							id: 'julaProUpdatingContactInformation',
							src: 'sendUpdateContactInformation',
							onDone: [
								{
									target: 'updateContactInformationSuccess',
								},
							],
							onError: [
								{
									target: 'errorUpdatingContactInformation',
								},
							],
						},
					},
					updateContactInformationSuccess: {
						tags: ['submitDone'],
						entry: ['setSubmitButtonStateSuccess', 'showToastMessageSuccess'],
						description: 'Contact information was successfully updated',
						on: {
							UPDATE_CONTACT_INFORMATION: 'updateContactInformation',
						},
					},
					errorUpdatingContactInformation: {
						tags: ['submitDone'],
						entry: ['setSubmitButtonStateFailure', 'showToastMessageFailure'],
						description:
							'An error occurred while updating the contact information.',
						on: {
							UPDATE_CONTACT_INFORMATION: 'updateContactInformation',
						},
					},
				},
			},
			updateJulaClubCustomerInformation: {
				id: 'updateCustomerInformation',
				description: 'Update information about the current contact',
				initial: 'idle',
				states: {
					idle: {
						description:
							'Idle state waiting for contact information update event',
						on: {
							UPDATE_JULA_CLUB_CUSTOMER_INFORMATION: {
								target: 'updateJulaClubCustomerInformation',
							},
						},
					},
					updateJulaClubCustomerInformation: {
						description: 'Updating contact information',
						entry: [
							'setSubmitButtonStateLoading',
							'reSetUpdateJulaClubCustomerErrors',
						],
						invoke: {
							id: 'updateJulaClubCustomerInformation',
							src: 'sendUpdateJulaClubCustomerInformation',
							onDone: [
								{
									target: 'updateJulaClubCustomerInformationSuccess',
								},
							],
							onError: [
								{
									target: 'errorUpdatingJulaClubCustomerInformation',
									actions: 'setUpdateJulaClubCustomerErrors',
								},
							],
						},
					},
					updateJulaClubCustomerInformationSuccess: {
						tags: ['submitDone'],
						entry: ['setSubmitButtonStateSuccess', 'showToastMessageSuccess'],
						description: 'Contact information was successfully updated',
						on: {
							UPDATE_JULA_CLUB_CUSTOMER_INFORMATION:
								'updateJulaClubCustomerInformation',
						},
					},
					errorUpdatingJulaClubCustomerInformation: {
						tags: ['submitDone'],
						entry: ['setSubmitButtonStateFailure', 'showToastMessageFailure'],
						description:
							'An error occurred while updating the contact information.',
						on: {
							UPDATE_JULA_CLUB_CUSTOMER_INFORMATION:
								'updateJulaClubCustomerInformation',
						},
					},
				},
			},
		},
	},
	{
		actions: {
			setSubmitButtonStateSuccess: assign({
				submitButtonState: (_context) => 'success' as ActionButtonState,
			}),
			setSubmitButtonStateFailure: assign({
				submitButtonState: (_context) => 'failure' as ActionButtonState,
			}),
			setSubmitButtonStateLoading: assign({
				submitButtonState: (_context) => 'loading' as ActionButtonState,
			}),
			showToastMessageSuccess: () => {
				sendToast('account_login_update_information_success_text', 'success');
			},
			showToastMessageFailure: () => {
				sendToast('account_login_update_information_error_text', 'error');
			},
			setUpdateJulaClubCustomerErrors: assign({
				updateJulaClubCustomerErrors: (_, event) => event.data as ErrorObject,
			}),
			reSetUpdateJulaClubCustomerErrors: assign({
				updateJulaClubCustomerErrors: (_context) => undefined,
			}),
		},
		services: {
			sendUpdateContactInformation: async (_context, event) =>
				requestJulaProAccountApiUpdateContactInformation(
					event.updateContactInformationData,
				),
			sendUpdateJulaClubCustomerInformation: async (_context, event) =>
				requestJulaClubAccountApiUpdateCustomerInformation(
					event.updateCustomerInformationData,
				),
		},
	},
);
export type AccountMachineState = StateFrom<typeof accountMachine>;
