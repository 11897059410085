import type { ProductResponse } from 'models/api';
import { Asset } from 'models/asset';
import type { AdditionalText, Product } from 'models/product';
import { filterTruthy } from 'utils/collection';

export interface Document {
	fileSize: number | undefined;
	fileType: string | undefined;
	name: string;
	url: string;
}
export function getDocuments(product: Product | ProductResponse): Document[] {
	return filterTruthy(
		product.assets
			?.find((asset) => asset.type === 'ProductDocument')
			?.versions?.map((version) => {
				const format =
					version.formats?.find((f) => f.type === 'PDF') ??
					version.defaultFormat;
				return {
					fileSize: format?.fileSize,
					fileType: format?.type,
					name: version.description,
					url: format?.url?.location,
				};
			}),
		'name',
		'url',
	);
}

export function filterAdditionalTexts(
	texts: (AdditionalText | null | undefined)[] | undefined,
): AdditionalText[] {
	return (
		(texts ?? [])
			.filter((text) => text?.title || text?.textFormatted)
			// TypeScript is too dumb to figure out that text is defined without this
			// separate check, can't be done in the previous filter as of 5.6.
			.filter((text) => !!text)
	);
}

export function getExternalTestReviewLogo(logo: Asset | undefined):
	| {
			alt: string | undefined;
			height: number | undefined;
			src: string;
			width: number | undefined;
	  }
	| undefined {
	// Don't want zeroes for width/height
	/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */

	const version = logo?.versions?.find(
		// Shouldn't be needed if this is ever added to TS.
		// https://github.com/microsoft/TypeScript/issues/15048
		(v): v is typeof v & { subType: 'TestersLogo' } =>
			v.subType === 'TestersLogo',
	);
	const format =
		version?.formats?.find((f) => f.type === 'ODL 320px 160px transparent') ??
		version?.defaultFormat;

	return format?.url.location
		? {
				alt: version?.description,
				height: format.dimensions?.height || undefined,
				src: format.url.location,
				width: format.dimensions?.width || undefined,
			}
		: undefined;
}
