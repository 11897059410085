import { assign, createMachine, type StateFrom } from 'xstate';

import { type ErrorObject, isError } from 'errors';
import { isTestmode } from 'hooks';
import { isGuiIframe, POST_SIGN_EVENT } from 'pages/api/sign/helpers';
import { isClient } from 'utils/helpers';

import {
	openSignWindow,
	requestJulaClubApplyForCredit,
	requestJulaClubRaiseCredit,
	requestJulaProCreditApi,
	requestPollCredit,
	requestTokenRefresh,
} from './credit.services';
import type {
	CreditMachineContext,
	CreditMachineEvents,
	CreditMachineServices,
} from './credit.types';

export const creditMachine = createMachine(
	{
		id: 'creditMachine',
		schema: {
			context: {} as CreditMachineContext,
			events: {} as CreditMachineEvents,
			services: {} as CreditMachineServices,
		},
		tsTypes: {} as import('./credit.machine.typegen').Typegen0,
		context: {
			signFrameOpen: false,
			pollingNumberFailed: 0,
			signWindow: null,
			errorOpeningSignWindow: false,
		},
		initial: 'creditRequest',
		states: {
			creditRequest: {
				id: 'creditRequest',
				entry: ['resetErrors'],
				exit: 'removeSignIframeModal',
				invoke: {
					src: 'listenForIframeMessage',
				},
				initial: 'waitingForCreditRequest',
				states: {
					waitingForCreditRequest: {
						on: {
							JULA_CLUB_APPLY_FOR_CREDIT: {
								target: 'requestNew',
							},
							JULA_CLUB_RAISE_CREDIT: {
								target: 'requestRaise',
							},
							JULA_PRO_REQUEST_CREDIT: {
								target: 'julaProCreditRequest',
							},
						},
					},
					requestRaise: {
						id: 'requestRaise',
						invoke: {
							id: 'requestRaiseCreditService',
							src: 'requestRaiseCreditService',
							onDone: [
								{
									cond: 'shouldSignBankId',
									target: 'displayBankIdSignIn',
									actions: ['setBankIdSignUrl'],
								},
								{
									cond: 'shouldPollForCreditRequest',
									target: 'waitingForCreditToBeCreated',
									actions: ['setPendingCustomerTicket'],
								},
							],
							onError: [
								{
									target: 'creditRequestFailed',
									actions: ['setErrors'],
								},
							],
						},
					},
					requestNew: {
						id: 'requestNew',
						invoke: {
							id: 'requestNewCreditService',
							src: 'requestNewCreditService',
							onDone: [
								{
									cond: 'shouldSignBankId',
									target: 'displayBankIdSignIn',
									actions: ['setBankIdSignUrl'],
								},
								{
									cond: 'shouldPollForCreditRequest',
									target: 'waitingForCreditToBeCreated',
									actions: ['setPendingCustomerTicket'],
								},
							],
							onError: [
								{
									target: 'creditRequestFailed',
									actions: ['setErrors'],
								},
							],
						},
					},
					julaProCreditRequest: {
						id: 'julaProCreditRequest',
						invoke: {
							id: 'applyOrRaiseCreditJulaProService',
							src: 'applyOrRaiseCreditJulaPro',
							onDone: [
								{
									cond: 'shouldSignBankId',
									target: 'displayBankIdSignIn',
									actions: ['setBankIdSignUrl'],
								},
								{
									cond: 'shouldPollForCreditRequest',
									target: 'waitingForCreditToBeCreated',
									actions: ['setPendingCustomerTicket'],
								},
							],
							onError: [
								{
									target: 'creditRequestFailed',
									actions: ['setErrors'],
								},
							],
						},
					},
					displayBankIdSignIn: {
						tags: ['creditRequestStartedOrFailed', 'loading'],
						always: [
							{
								target: 'loadingSignFrameModal',
								cond: 'shouldDisplaySignIframeModal',
							},
							{
								target: 'loadingSignWindow',
							},
						],
					},
					loadingSignFrameModal: {
						entry: 'openSignIframeModal',
						tags: ['creditRequestStartedOrFailed', 'loading'],
						on: {
							SIGN_FRAME_LOAD_SUCCESS: {
								target: 'waitingForCreditToBeCreated',
							},
						},
					},
					loadingSignWindow: {
						tags: ['creditRequestStartedOrFailed', 'loading'],
						invoke: {
							id: 'openSignWindow',
							src: 'openSignWindow',
							onDone: {
								target: 'waitingForCreditToBeCreated',
								actions: 'setSignWindowRef',
							},
							onError: {
								target: 'waitingForCreditToBeCreated',
								actions: 'setErrorOpeningSignWindow',
							},
						},
					},
					waitingForCreditToBeCreated: {
						tags: ['creditRequestStartedOrFailed', 'loading'],
						on: {
							CLOSE: {
								target: 'waitingForCreditRequest',
								actions: ['removeSignIframeModal', 'closeSignWindow'],
							},
							RETRY_OPEN_SIGN_WINDOW: {
								target: 'loadingSignWindow',
								actions: 'resetErrorOpeningSignWindow',
							},
						},
						after: {
							POLL_CREDIT_REQUEST_DELAY: {
								target: 'checkingCreditRequestStatus',
							},
						},
					},
					checkingCreditRequestStatus: {
						tags: 'loading',
						invoke: {
							id: 'pollCustomerApi',
							src: 'pollCustomerApi',
							onDone: [
								{
									cond: 'creditRequestIsPending',
									target: 'waitingForCreditToBeCreated',
									actions: 'setPendingCustomerTicket',
								},
								{
									cond: 'creditRequestSuccessful',
									target: '#success',
									actions: ['setResolvedCustomerTicket'],
								},
								{
									cond: 'creditRequestDenied',
									target: '#creditNotCreated',
									actions: 'setResolvedCustomerTicket',
								},
								{
									cond: 'signingCanceled',
									target: '#signingCanceled',
									actions: 'setResolvedCustomerTicket',
								},
							],
							onError: [
								{
									cond: 'hasServerError',
									target: '#creditNotCreated',
								},
								{
									target: 'waitingForCreditToBeCreated',
									actions: 'setFailedPollingNumber',
								},
							],
						},
					},
					creditRequestFailed: {
						tags: 'creditRequestStartedOrFailed',
						on: {
							JULA_CLUB_APPLY_FOR_CREDIT: {
								target: 'requestNew',
							},
							JULA_CLUB_RAISE_CREDIT: {
								target: 'requestRaise',
							},
							JULA_PRO_REQUEST_CREDIT: {
								target: 'julaProCreditRequest',
							},
						},
					},
				},
				on: {
					SIGN_CREDIT_DONE: {
						actions: ['removeSignIframeModal', 'closeSignWindow'],
					},
				},
			},
			success: {
				tags: 'doneCreatingCredit',
				id: 'success',

				initial: 'refreshToken',
				states: {
					refreshToken: {
						invoke: {
							src: 'requestTokenRefresh',
							onDone: 'creditApplicationDone',
						},
					},
					creditApplicationDone: {
						on: {
							RESET: {
								target: '#creditRequest',
							},
						},
					},
				},
			},
			creditNotCreated: {
				tags: 'creditRequestStartedOrFailed',
				id: 'creditNotCreated',
				on: {
					RESET: {
						target: '#creditRequest',
					},
				},
			},
			signingCanceled: {
				tags: 'creditRequestStartedOrFailed',
				id: 'signingCanceled',
				on: {
					RESET: {
						target: '#creditRequest',
					},
				},
			},
		},
	},
	{
		guards: {
			shouldDisplaySignIframeModal: (_context) => isGuiIframe(),
			shouldSignBankId: (_context, event) => {
				if (isTestmode()) {
					return false;
				}
				return event.data?.clientAction === 'Redirect';
			},
			shouldPollForCreditRequest: (_context, event) => {
				if (isTestmode()) {
					return true;
				}
				return event.data?.clientAction === 'Polling';
			},
			creditRequestIsPending: (_context, event) =>
				event.data?.clientAction === 'Polling',
			creditRequestSuccessful: (_context, event) =>
				event.data?.ticketStatus === 'Resolved' &&
				event.data?.data?.CreditResult === 'Approved',
			creditRequestDenied: (_context, event) =>
				event.data?.ticketStatus === 'Resolved' &&
				(event.data?.data?.CreditResult === 'Denied' ||
					event.data?.data?.CreditResult === 'Failed'),
			signingCanceled: (_context, event) =>
				event.data?.ticketStatus === 'Resolved' &&
				event.data?.data?.CreditResult === 'Canceled',
			hasServerError: (_context, event) => {
				const { data } = event;
				if (isError(data, 'ResponseError')) {
					return data.status === 404 || data.status === 500;
				}
				return false;
			},
		},
		delays: {
			POLL_CREDIT_REQUEST_DELAY: (context, _event) => {
				const delay =
					Number(context.pendingTicket?.data?.RefreshIntervalSeconds) || 2;
				return delay * 1000;
			},
		},
		actions: {
			setPendingCustomerTicket: assign({
				pendingTicket: (_context, event) => event.data,
			}),
			setResolvedCustomerTicket: assign({
				resolvedTicket: (_context, event) => event.data,
			}),
			setBankIdSignUrl: assign({
				bankIdSignUrl: (_context, event) => event.data?.data?.Url,
			}),
			openSignIframeModal: assign({
				signFrameOpen: (_context) => true,
			}),
			removeSignIframeModal: assign({
				signFrameOpen: (_context) => false,
			}),
			setSignWindowRef: assign({
				signWindow: (_context, event) => event.data,
			}),
			closeSignWindow: assign({
				signWindow: (context, _event) => {
					if (context.signWindow) context.signWindow.close();

					return null;
				},
			}),
			setErrorOpeningSignWindow: assign({
				errorOpeningSignWindow: (_context) => true,
			}),
			resetErrorOpeningSignWindow: assign({
				errorOpeningSignWindow: (_context) => false,
			}),
			setFailedPollingNumber: assign({
				pollingNumberFailed: (context) => context.pollingNumberFailed + 1,
			}),
			resetErrors: assign({
				errors: (_context, _event) => undefined,
			}),
			setErrors: assign({
				errors: (_context, event) => event.data as ErrorObject | undefined,
			}),
		},
		services: {
			listenForIframeMessage: () => (send) => {
				if (!isClient()) {
					return undefined;
				}

				const listener = (event: MessageEvent) => {
					if (event.origin !== globalThis.location.origin) {
						return;
					}

					if (event.data.type === POST_SIGN_EVENT) {
						send({ type: 'SIGN_CREDIT_DONE' });
					}
				};

				window.addEventListener('message', listener, false);

				return () => {
					window.removeEventListener('message', listener);
				};
			},
			requestNewCreditService: (_, event) =>
				requestJulaClubApplyForCredit(event.formData),
			requestRaiseCreditService: (_, event) =>
				requestJulaClubRaiseCredit(event.formData),
			applyOrRaiseCreditJulaPro: (_, event) =>
				requestJulaProCreditApi(event.formData),
			pollCustomerApi: (_context) => requestPollCredit(),
			requestTokenRefresh: (_context) => requestTokenRefresh(),
			openSignWindow: (context) => openSignWindow(context?.bankIdSignUrl),
		},
	},
);
export type CreditMachineState = StateFrom<typeof creditMachine>;
