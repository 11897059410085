import { useField } from 'react-final-form';

import { type FieldValidator, pattern, required } from 'components/FinalForm';
import type { FormFields, FormInput } from 'models/sitecore';
import { filterObject } from 'utils/collection';
import { is } from 'utils/helpers';

// A helper that checks the values submitted from the generic form
// and makes sure everything is in order
// for now it checks that fields that have dependencies are correct, that the dependent field has a value
// so that we don't send something just because it's in the forms state
// it can also be passed an allow list to filter the values so that only those
// that should be passed on to the api are sent
export const getValuesForSubmit = (
	form: FormFields,
	values: Record<string, unknown>,
	allowList?: string[],
): Record<string, unknown> => {
	const inputFields = form.filter((field) => field.input);
	const currentValues = filterObject(values, (_val, key, obj) => {
		const field = inputFields.find(({ name }) => name === key);
		if (!field || (field.requires && !obj[field.requires])) {
			return false;
		}
		return true;
	});

	if (is.arrayWithLength(allowList)) {
		return filterObject(currentValues, (_val, key) => allowList.includes(key));
	}
	return filterObject(currentValues, (_val, key) =>
		Boolean(inputFields.find((field) => field.name === key)?.useData),
	);
};

export function getValidationForField(field: FormInput) {
	const validation: FieldValidator[] = [];

	if (field.required) {
		validation.push(required(field.error));
	}
	if (field.validation) {
		validation.push(
			pattern({
				patternString: field.validation,
				required: field.required,
				errorMessage: field.error,
			}),
		);
	}
	return validation;
}

// If a field is dependent on another field to have a value to render it has 'requires' set
// this hook checks if the dependent field has a value
export function useFieldDependencyStatus(
	dependencyFieldName: string | undefined,
) {
	const { input } = useField(dependencyFieldName ?? '');
	return {
		dependencyFieldHasValue: dependencyFieldName ? Boolean(input.value) : true,
	};
}
