import React from 'react';

import Img from 'components/Img';
import { LayoutContainer } from 'components/Layout';
import Link, { OptionalLink } from 'components/Link';
import { ImageColumn } from 'models/sitecore';
import { cn } from 'utils/classNames';

import { flattenImageColumns } from './helpers';
import PageFooterInfo from './PageFooterInfo';

interface Props {
	columns: ImageColumn[] | undefined;
	infoText?: string;
}

/** Columns of small images, possibly linked. */
export default function PageFooterImages({ columns, infoText }: Props) {
	const visibleColumns = flattenImageColumns(columns);
	const colCount = visibleColumns.length;

	if (colCount === 0) {
		return null;
	}

	return (
		<LayoutContainer
			gutterType="padding"
			outerClassName="py-10 md:py-6 md:border-t md:border-t-julaRedDark"
			withGrid
		>
			<PageFooterInfo
				text={infoText}
				className="col-span-full max-md:max-w-[30rem] md:col-span-5 lg:hidden"
			/>

			<div className="col-span-full md:col-span-6 md:col-start-7 lg:col-span-full lg:flex lg:gap-6">
				{visibleColumns.map((col) => (
					<section
						key={col.title}
						className="mt-10 md:first:mt-0 lg:mt-0 lg:flex-1"
					>
						<h3 className="mb-4 font-standard text-lg font-bold">
							{col.title}
						</h3>
						<p className="flex flex-wrap gap-3">
							{col.images.map((img) => (
								<OptionalLink
									key={img.src}
									href={img.href}
									fallback="span"
									target={img.target}
									className={cn(
										'inline-block min-w-[1rem] shrink-0 rounded-button',
										img.padding && 'bg-white p-2',
										!img.large && 'h-10',
										img.large && 'h-20',
									)}
									linkClassName="hover:outline hover:outline-1 hover:outline-offset-2 hover:outline-white"
								>
									<Img
										src={img.src}
										alt={img.alt || img.text}
										className="h-full"
									/>
								</OptionalLink>
							))}
						</p>
					</section>
				))}
			</div>
		</LayoutContainer>
	);
}
PageFooterImages.displayName = 'PageFooterImages';
