import React from 'react';

import Accordion from 'components/Accordion';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import type { JulaComponentProps } from 'lib/component-props';
import type { FaqArticle, FaqCategory } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';

import FAQAccordionArticles from './FAQAccordionArticles';

type Props = JulaComponentProps & {
	fields?: {
		articles?: FaqArticle[];
		categories?: FaqCategory;
	};
};

export default function FAQOverview({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const { articles, categories } = fields;

	if (!categories?.categories?.length || !articles?.length) {
		return (
			<ComponentPlaceholder
				componentName="FAQOverview"
				description="Missing categories or articles"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
		>
			<div className="max-w-col-6">
				{categories?.categories?.map(
					({
						articles: categoryArticles,
						categories: subCategories,
						id,
						name,
					}) => (
						<Accordion
							key={id}
							title={name}
							id={`faq-accordion-${id}`}
							size="heading"
						>
							<FAQAccordionArticles
								articles={articles}
								items={categoryArticles}
								className="mb-4 mt-2"
							/>
							{subCategories?.map((subCategory) => (
								<FAQAccordionArticles
									key={subCategory.name}
									articles={articles}
									heading={subCategory.name}
									items={subCategory.articles}
									className="mt-8 first:mt-2 last:mb-4"
								/>
							))}
						</Accordion>
					),
				)}
			</div>
		</LayoutContainer>
	);
}
FAQOverview.displayName = 'FAQOverview';
