import React from 'react';

import Img from 'components/Img';
import Link from 'components/Link';
import Text from 'components/Text';
import { cn } from 'utils/classNames';
import { formatDisplayUrl } from 'utils/format';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	className?: string;
	features?: string[];
	heading: string;
	logoAlt?: string;
	logoHeight?: number;
	logoSrc?: string;
	logoWidth?: number;
	source: string;
	sourceUrl?: string;
}

export default function ProductDetailsExternalReview({
	className,
	features,
	heading,
	logoAlt,
	logoHeight,
	logoSrc,
	logoWidth,
	source,
	sourceUrl,
}: Props) {
	const { t } = useI18n();

	return (
		<div className={cn('inline-block border border-greyDark p-6', className)}>
			<Text as="h2" text={heading} />
			<p className="mb-6">
				{t('product_details_external_review_source_text', { source })}
			</p>
			{logoSrc && (
				<Img
					alt={logoAlt}
					src={logoSrc}
					width={logoWidth}
					height={logoHeight}
					className="mb-6 max-h-6 w-auto max-w-[10rem]"
				/>
			)}
			{is.arrayWithLength(features) && (
				<ul className="mb-6 inline-flex list-inside list-disc flex-col gap-2 font-bold">
					{features.map((feature) => (
						<li key={feature}>{feature}</li>
					))}
				</ul>
			)}
			{sourceUrl && (
				<p>
					{`${t('product_details_external_review_source_link_label')}: `}
					<Link
						underline
						className="break-all"
						target="_blank"
						href={sourceUrl}
					>
						{formatDisplayUrl(sourceUrl)}
					</Link>
				</p>
			)}
		</div>
	);
}

ProductDetailsExternalReview.displayName = 'ProductDetailsExternalReview';
