/**
 * TechnicalSpecification
 */

import React from 'react';

import IconLink from 'components/IconLink';
import Img from 'components/Img';
import Text from 'components/Text';
import {
	PackageSize,
	TechnicalAttribute,
	TechnicalAttributeCategory,
} from 'models/api';
import { cn } from 'utils/classNames';
import { formatBytes, formatLength, formatWeight } from 'utils/format';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

const sortByIndex = (
	a: TechnicalAttributeCategory | TechnicalAttribute,
	b: TechnicalAttributeCategory | TechnicalAttribute,
) => (a.index || 0) - (b.index || 0);

interface TableProps {
	rows: { id: string; title: string; value: string | number }[];
	title: string;
}
function Table({ rows, title }: TableProps) {
	const cellClasses = 'border-b border-t border-greyLighter px-0 py-3 text-sm';

	return (
		<table className="w-full">
			<Text as="caption" styleAs="h4" className="pb-2 text-left">
				{title}
			</Text>
			<tbody>
				{rows.map((row) => (
					<tr key={row.id}>
						<th
							scope="row"
							className={cn(cellClasses, 'text-left font-normal')}
						>
							{row.title}
						</th>
						<td className={cn(cellClasses, 'text-right')}>{row.value}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}
Table.displayName = 'TechnicalSpecification_Table';

export interface TechnicalDocument {
	fileSize?: number;
	fileType?: string;
	name: string;
	url: string;
}

interface Props {
	/** Groups of technical data values. */
	categories?: TechnicalAttributeCategory[];

	/** Container class names. */
	className?: string;

	/** List of documents for download. */
	documents?: TechnicalDocument[];

	/** Energy label image URL. */
	energyLabelSrc?: string;

	/** Product packaging information. */
	packageSize?: PackageSize;
}

/** Component for table of a product's technical specifications. */
export default function TechnicalSpecification({
	categories,
	className,
	documents,
	energyLabelSrc,
	packageSize,
}: Props) {
	const { t } = useI18n();

	return (
		<div className={cn(className, energyLabelSrc && 'md:flex md:items-start')}>
			<div className={cn('space-y-10', energyLabelSrc && 'md:grow')}>
				{is.arrayWithLength(categories) &&
					[...categories].sort(sortByIndex).map((category) => (
						<Table
							key={category.id}
							title={category.title}
							rows={[...category.attributes].sort(sortByIndex).map((attr) => ({
								id: attr.id,
								title: attr.title,
								value:
									attr.values.length === 0 ||
									(attr.values.length === 1 && attr.values[0] === '')
										? '—'
										: attr.values.join(', '),
							}))}
						/>
					))}

				{packageSize && (
					<Table
						title={t('product_details_package_size_heading')}
						rows={[
							{
								id: 'width',
								title: t('product_details_package_size_width_title'),
								value: formatLength(packageSize.width),
							},
							{
								id: 'height',
								title: t('product_details_package_size_height_title'),
								value: formatLength(packageSize.height),
							},
							{
								id: 'depth',
								title: t('product_details_package_size_depth_title'),
								value: formatLength(packageSize.depth),
							},
							{
								id: 'weight',
								title: t('product_details_package_size_weight_title'),
								value: formatWeight(packageSize.freightWeight),
							},
						]}
					/>
				)}

				{is.arrayWithLength(documents) && (
					<div>
						<Text as="h4" className="mb-4 font-bold">
							{t('product_details_documents_heading')}
						</Text>
						<ul>
							{documents.map((doc) => {
								const suffix = [formatBytes(doc.fileSize), doc.fileType]
									.filter(Boolean)
									.join(' - ');
								return (
									<li key={doc.url} className="mt-2">
										<IconLink
											icon="file"
											href={doc.url}
											target="_blank"
											rel="nofollow"
											text={suffix ? `${doc.name} (${suffix})` : doc.name}
											className="align-middle"
										/>
									</li>
								);
							})}
						</ul>
					</div>
				)}
			</div>

			{energyLabelSrc && (
				<Img
					src={energyLabelSrc}
					className="max-w-[230px] max-md:mt-10 md:ml-10 md:shrink-0"
				/>
			)}
		</div>
	);
}
TechnicalSpecification.displayName = 'TechnicalSpecification';
