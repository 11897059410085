import React, { type MouseEventHandler, useId } from 'react';

import Icon from 'components/Icon';
import Img from 'components/Img';
import PlainButton from 'components/PlainButton';
import type { HTMLAttributes } from 'types';
import { cn } from 'utils/classNames';

interface Props extends HTMLAttributes<HTMLButtonElement> {
	hiddenLabel?: boolean;
	imageUrl?: string;
	invertArrow?: boolean;
	label: string;
	labelVariant?: 'standalone' | 'floating' | 'hidden';
	onClick: MouseEventHandler<HTMLButtonElement>;
	text: string;
}

/** A button that looks like a select */
export default function SelectButton({
	className,
	hiddenLabel,
	imageUrl,
	invertArrow = false,
	label,
	labelVariant = 'standalone',
	onClick,
	text,
	...attrs
}: Props) {
	const id = useId();
	const labelId = `select-button-${id}-label`;
	return (
		<div className={cn('relative', className)}>
			<p
				id={labelId}
				className={cn(
					labelVariant === 'standalone' && 'mb-2 font-bold',
					labelVariant === 'floating' && [
						// Add an additional pixel via margin for exact alignment with the
						// button (padding + border).
						'ml-px inline-block rounded-border px-1',
						'pointer-events-none absolute -top-2.5 left-3 z-1',
						'max-w-[calc(100%-3.5rem)] overflow-hidden text-ellipsis whitespace-nowrap',
						'bg-white text-sm text-greyDark',
					],
					labelVariant === 'hidden' && 'sr-only',
				)}
			>
				{label}
			</p>
			<PlainButton
				{...attrs}
				aria-describedby={labelId}
				onClick={onClick}
				className={cn(
					'relative flex h-14 w-full pl-4 pr-12',
					labelVariant === 'floating' && 'mt-2',
					'items-center text-left',
					'rounded-button border border-grey bg-white transition-colors',
					'hover:border-greyDarker hover:outline hover:outline-1 hover:outline-greyDarker',
				)}
			>
				{imageUrl && (
					<Img
						src={imageUrl}
						useFallbackOnError
						className="mr-2 size-8"
						service="nextjs"
						width={32}
						height={32}
					/>
				)}
				<span className="overflow-hidden text-ellipsis whitespace-nowrap">
					{text}
				</span>
				<div className="absolute inset-y-2 right-0 flex w-10 items-center justify-center border-l border-greyLight">
					<Icon
						name="arrow"
						direction={invertArrow ? 'up' : 'down'}
						className="md:hidden"
					/>
					<Icon
						name="arrow"
						direction={invertArrow ? 'left' : 'right'}
						className="hidden md:block"
					/>
				</div>
			</PlainButton>
		</div>
	);
}
SelectButton.displayName = 'SelectButton';
