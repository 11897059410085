import React, { useState } from 'react';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-nextjs';

import LoadMoreList from 'components/LoadMoreList';
import { Order } from 'models/api';
import { getProductImageSrc } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';
import { formatDate } from 'utils/format';
import { afterNextPaint, empty } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import OrderListItem from './OrderListItem';

interface Props {
	hasLoadMoreButton?: boolean;
	isLoadingMore?: boolean;
	onLoadMoreClick?: () => void;
	orders: Order[] | undefined;
	rendering: ComponentRendering;
}

export default function OrderList({
	hasLoadMoreButton = false,
	isLoadingMore,
	onLoadMoreClick,
	orders,
	rendering,
}: Props) {
	const { t, tPlural } = useI18n();
	const [openIds, setOpenIds] = useState<string[]>([]);
	const [printId, setPrintId] = useState<string>('');

	const handleOpen = (orderId: string) => {
		setOpenIds((ids) => [...ids, orderId]);
	};
	const handleClose = (orderId: string) => {
		if (orderId === printId) {
			setPrintId('');
		}
		setOpenIds((ids) => ids.filter((id) => id !== orderId));
	};

	const handlePrintClick = (orderId: string) => {
		setPrintId(orderId);
		afterNextPaint(() => {
			globalThis.print();
			// Reset to allow the list to be printed without reloading the page.
			setTimeout(() => {
				setPrintId('');
			}, 1000);
		});
	};

	if (!orders || orders.length === 0) {
		return null;
	}

	return (
		<LoadMoreList
			buttonAlignment="center"
			buttonClassName="min-w-60 mt-6 print:hidden"
			listClassName="flex flex-col gap-4"
			buttonText={t('load_more_orders_button')}
			itemCountScreenReaderText={tPlural(
				'order_list_number_of_orders_text',
				orders.length,
			)}
			buttonVariant="secondary"
			hasLoadMoreButton={hasLoadMoreButton}
			isLoading={isLoadingMore}
			listTag="ul"
			onLoadMoreClick={onLoadMoreClick ?? empty.func}
		>
			{orders.map((order) => {
				const images = filterTruthy(
					order.lines.map((line) => ({
						url: getProductImageSrc(line.mainImage?.formats, true),
						alt: line.mainImage?.description,
					})),
					'url',
				);
				return (
					<OrderListItem
						key={order.id}
						isSelectedForPrint={
							// If there are no open orders and none selected for print,
							// allow the entire list to be printed.
							!printId && openIds.length === 0
								? true
								: // If there is a single selected for print, show only that.
									printId
									? order.id === printId
									: // Otherwise show all open ones.
										openIds.includes(order.id)
						}
						onOpen={handleOpen}
						onClose={handleClose}
						onPrintClick={handlePrintClick}
						id={order.id}
						purchaseDate={formatDate(order.purchaseDate)}
						totalSum={order.totalSum}
						amountOfProducts={order.totalQuantities}
						images={images.slice(0, 3)}
						pointOfPurchase={order.pointOfPurchase}
						rendering={rendering}
					/>
				);
			})}
		</LoadMoreList>
	);
}
OrderList.displayName = 'AccountPurchases_OrderList';
