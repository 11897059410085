/**
 * ProductTag
 */

import React from 'react';

import type { ProductTagType } from 'models/product';
import { cnm } from 'utils/classNames';
import { is } from 'utils/helpers';

export type Size = 'small' | 'medium' | 'large';

interface Props {
	type: ProductTagType;
	className?: string;
	size: Size;
	text: string;
}

export default function ProductTag({ className, size, text, type }: Props) {
	const isFlag = is.oneOf(type, 'News', 'Outlet', 'Sale');

	return (
		<p
			className={cnm(
				'inline-block align-top font-bold',

				// Colors
				type === 'Upsale' && 'bg-julaRed text-white',
				type === 'TestWinner' && 'bg-errorLighter text-julaRedDark',
				is.oneOf(type, 'Bundle', 'QtyBasedOffer') && 'bg-white text-greyDarker',
				type === 'News' && 'bg-news text-informationDarker',
				is.oneOf(type, 'JulaHero', 'Outlet', 'Sale') &&
					'bg-campaign text-greyDarker',

				isFlag && [
					'relative rounded-bl-none',
					'after:absolute after:left-0 after:box-content after:border-transparent',
					'forced-colors:border forced-colors:after:hidden',

					type === 'News' && 'after:border-l-news',
					is.oneOf(type, 'Outlet', 'Sale') && 'after:border-l-campaign',

					size === 'small' &&
						'rounded-sm px-1 py-px text-sm after:-bottom-[5px] after:border-[5px]',
					size === 'medium' &&
						'rounded-border px-2 py-0.5 text-base after:-bottom-[7px] after:border-[7px] md:text-lg',
					size === 'large' &&
						'rounded px-3 py-1 font-alt text-h3-small after:-bottom-[10px] after:border-[10px] md:text-h3-large',
				],
				!isFlag && [
					'border px-2 py-px',

					type === 'Upsale' && 'border-julaRed',
					type === 'TestWinner' && 'border-errorLighter',
					is.oneOf(type, 'Bundle', 'QtyBasedOffer') && 'border-greyDark',
					type === 'JulaHero' && 'border-campaign',

					size === 'small' && 'text-sm',
					size === 'medium' && 'text-base',
					size === 'large' && 'text-base md:text-lg',
				],

				className,
			)}
		>
			{text}
		</p>
	);
}
ProductTag.displayName = 'ProductTag';
