import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlockMedia from 'components/BlockMedia/';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import { ProductCard as ProductCardType } from 'models/productCard';
import { getEditorMargin } from 'utils/business-logic';
import { cn } from 'utils/classNames';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		datasourceName?: string;
		isWide?: Field<boolean>;
		landscapeImage?: Field<DigizuiteAsset[]>;
		products?: ProductCardType[];
	};
};

export default function MediaLandscape({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const { datasourceName, landscapeImage, products } = fields;
	const isWide = Boolean(fields.isWide?.value);
	const landscapeImg = getAsset('Templated 2:1', landscapeImage);

	if (!landscapeImg) {
		return (
			<ComponentPlaceholder
				componentName="MediaLandscape"
				description="Requires an image"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			gutters
			withGrid
			outerClassName={getEditorMargin(params)}
		>
			<div
				className={cn(
					'col-span-full',
					!isWide && 'md:col-span-6 md:col-start-4',
				)}
			>
				<BlockMedia
					variant="landscapeWide"
					imgSrc={landscapeImg?.src}
					imgAlt={landscapeImg?.alt}
					products={products}
					productsDisplayType="shopByMedia"
					gtmItemListId="block"
					gtmItemListName={`Block: ${datasourceName ?? 'MediaLandscapeUnknown'}`}
				/>
			</div>
		</LayoutContainer>
	);
}
MediaLandscape.displayName = 'MediaLandscape';
