import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlockThemedProductCarousel from 'components/BlockThemedProductCarousel';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { withRequiredProps } from 'hoc';
import { useEffectOnce, useProductListGTMEvents } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import type { ProductCard } from 'models/productCard';
import type { SitecoreLink, Theme } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy, withTruthyProps } from 'utils/collection';
import { getAsset, is } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields: {
		datasourceName?: string;
		description?: Field<string>;
		heading?: Field<string>;
		landscapeImage?: Field<DigizuiteAsset[]>;
		link?: Field<SitecoreLink>;
		linksHeading?: Field<string>;
		primaryLink?: Field<SitecoreLink>;
		products?: ProductCard[];
		quaternaryLink?: Field<SitecoreLink>;
		secondaryLink?: Field<SitecoreLink>;
		squareImage?: Field<DigizuiteAsset[]>;
		tertiaryLink?: Field<SitecoreLink>;
		theme?: Theme;
	};
};

function ThemedProductCarousel({ fields, params }: Props) {
	const {
		datasourceName,
		heading,
		landscapeImage,
		primaryLink,
		products,
		quaternaryLink,
		secondaryLink,
		squareImage,
		tertiaryLink,
		theme,
	} = fields;
	const hasProducts = is.arrayWithLength(products);
	const link = withTruthyProps(fields.link?.value, 'href', 'text');
	const linksHeading = fields.linksHeading?.value;
	const links = filterTruthy(
		[primaryLink, secondaryLink, tertiaryLink, quaternaryLink]
			.filter(is.truthy)
			.map((scLink) => scLink.value),
		'href',
		'text',
	);

	const { gtmItemListId, gtmItemListName, sendViewItemListEvent } =
		useProductListGTMEvents(
			'block',
			`Block: ${datasourceName ?? 'ThemedProductCarouselUnknown'}`,
		);
	useEffectOnce(() => {
		if (hasProducts) {
			sendViewItemListEvent(products, products.length);
		}
	});

	if (!hasProducts || !theme?.backgroundColor) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="ThemedProductCarousel"
				description="Requires products and a theme with a color"
			/>
		);
	}

	const topLeftImage = getAsset('Source Copy', theme.topLeftImageLandscape);
	const centerImageLarge = getAsset('Source Copy', squareImage);
	const centerImageSmall = getAsset('Source Copy', landscapeImage);

	return (
		<BlockThemedProductCarousel
			// Assume both images will have the same meaning.
			centerImageAlt={centerImageLarge?.alt || centerImageSmall?.alt}
			centerImageLargeSrc={centerImageLarge?.src}
			centerImageSmallSrc={centerImageSmall?.src}
			className={getEditorMargin(params)}
			color={theme.backgroundColor}
			gtmItemListId={gtmItemListId}
			gtmItemListName={gtmItemListName}
			heading={heading}
			headingLevel={params?.heading}
			id={params?.anchor}
			link={link}
			products={products}
			text={fields.description}
			topLeftImageSrc={topLeftImage?.src}
			linksHeading={linksHeading}
			links={links}
		/>
	);
}
ThemedProductCarousel.displayName = 'ThemedProductCarousel';

export default withRequiredProps(ThemedProductCarousel, 'fields');
