import React from 'react';

import { usePriceContext } from 'contexts';
import type { PriceSize, PriceTheme } from 'models/price';
import { cn, cnm } from 'utils/classNames';
import { formatPrice, formatPriceParts } from 'utils/format';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import {
	getPropsFromPrice,
	type LabelAlign,
	type PriceProp,
	removeCurrencySymbols,
} from './helpers';

export interface Props {
	/** Additional container class names. */
	className?: string;

	/** The currency symbol of the price. */
	currency?: string;

	/** Skip rendering of any savings text, even if there is one. */
	hideSavings?: boolean;

	/** The text for the price label (displayed above the price). */
	label?: string;

	/** Alignment of the label */
	labelAlign?: LabelAlign;

	/** The main price of the component */
	price: PriceProp;

	/** The size of the price. */
	size?: PriceSize;

	/** The text for the bottom tag (displayed below the "plate"). */
	tagBottom?: string;

	/** The text for the top tag (displayed above the "plate"). */
	tagTop?: string;

	/** The theme of the component. */
	theme?: PriceTheme;
}

/**
 * Price component with different themes and sizes.
 *
 * Passing a price object to `price` will set things like theme and label
 * automatically depending on the object's data.
 */
export default function Price({
	className,
	hideSavings = false,
	size = 'large',
	...props
}: Props) {
	const { t } = useI18n();
	const { usePriceExcVat } = usePriceContext();
	const { currency, label, labelAlign, priceNum, tagBottom, tagTop, theme } =
		getPropsFromPrice({
			...props,
			hideSavings,
			useExcVat: usePriceExcVat,
		});

	const priceParts = formatPriceParts(priceNum);
	const hasDecimal = priceParts.decimal.length > 0;
	const screenReaderPrice =
		`${label} ${formatPrice(priceNum)} ${t('screen_reader_currency_symbol')}`.trim();

	const hasTagTop = is.truthy(tagTop) && is.oneOf(theme, 'julaPro', 'julaClub');
	const hasTagBottom =
		is.truthy(tagBottom) &&
		is.oneOf(size, 'large', 'medium', 'small', 'mini', 'micro');

	const isRotated =
		theme !== 'regular' &&
		is.oneOf(size, 'micro', 'mini', 'small', 'medium', 'large');

	const mainPartClasses = cn(
		'inline-block min-w-[1em] whitespace-nowrap font-alt font-bold leading-none',

		// Text size
		size === 'extraNano' && 'text-[0.9375rem]',
		size === 'nano' && 'text-[1.125rem]',
		size === 'microCompact' && 'text-[1.5rem]',
		size === 'micro' && 'text-[1.5rem]',
		size === 'mini' && 'text-[2rem]',
		size === 'small' && 'text-[2.5rem]',
		size === 'medium' && 'text-[3.75rem]',
		size === 'large' && 'text-[4.5rem]',

		// Colors
		theme === 'regular' && 'text-greyDarker',
		theme === 'campaign' && 'bg-campaign text-greyDarker',
		theme === 'julaClub' && 'bg-julaRedDarker text-white',
		theme === 'julaPro' && 'bg-greyDarker text-white',

		// Plate dimensions
		theme !== 'regular' && [
			'forced-colors:border',

			// Padding
			is.oneOf(size, 'extraNano', 'nano', 'microCompact', 'micro') &&
				'px-[0.25em] py-[0.2222em]',
			is.oneOf(size, 'mini', 'small') && 'px-[0.2em] py-[0.2222em]',
			is.oneOf(size, 'medium', 'large') && 'px-[0.1666em] py-[0.18em]',

			// Radius
			is.oneOf(size, 'extraNano', 'nano') && 'rounded',
			is.oneOf(size, 'microCompact', 'micro', 'mini') && 'rounded-md',
			size === 'small' && 'rounded-lg',
			size === 'medium' && 'rounded-xl',
			size === 'large' && 'rounded-2xl',
		],
	);

	const mainText = (
		<>
			{priceParts.main}
			{hasDecimal && (
				<sup className="top-0 text-[50%] leading-none">
					{priceParts.decimal}
				</sup>
			)}
			{currency && !hasDecimal && (
				<span
					className={cn(
						'pr-[0.08em] -tracking-[0.125em]',
						is.oneOf(size, 'mini', 'small', 'medium', 'large') &&
							'-ml-[0.02em]',
					)}
				>
					{currency}
				</span>
			)}
		</>
	);

	// To reduce the amount of DOM nodes, skip the outer containers when it's
	// just a regular price without any extra themes or labels.
	if (theme === 'regular' && !hasTagTop && !label && !hasTagBottom) {
		// Position the screen reader text to make the visual
		// screen reader cursor a bit better.
		return (
			<p className="relative">
				<span className="sr-only left-0 top-[10%]">{screenReaderPrice}</span>
				<span
					aria-hidden
					className={cn('text-right', className, mainPartClasses)}
				>
					{mainText}
				</span>
			</p>
		);
	}

	const tagBaseClasses = cn(
		'pointer-events-auto inline-block whitespace-nowrap font-standard leading-none',
		is.oneOf(size, 'medium', 'large')
			? 'rounded font-bold'
			: 'rounded-sm font-normal',

		// For tags with background
		theme !== 'regular' && [
			'text-white forced-colors:border',
			is.oneOf(size, 'medium', 'large')
				? 'px-[0.5em] py-[0.3333em]'
				: 'px-[0.4em] py-[0.25em]',
		],

		// Text size
		is.oneOf(size, 'extraNano', 'nano') && 'text-[0.4375rem]',
		is.oneOf(size, 'microCompact', 'micro') && 'text-[0.5625rem]',
		size === 'mini' && 'text-[0.6875rem]',
		size === 'small' && 'text-[0.8125rem]',
		is.oneOf(size, 'medium', 'large') && 'text-[0.9375rem]',
	);

	return (
		<div
			className={cnm(
				// Disable pointer events to prevent the plate from ever stopping
				// text selection close to the price. Events are restored on the
				// actual text parts.
				'pointer-events-none relative inline-flex flex-col items-start align-middle',
				isRotated && [
					'origin-top-left rotate-4',
					// Rotation doesn't affect layout so compensate with some padding.
					size === 'micro' && 'pt-0.5',
					size === 'mini' && 'pt-[0.1875rem]',
					size === 'small' && 'pt-1.5',
					size === 'medium' && 'pt-2',
					size === 'large' && 'pt-[0.625rem]',
				],
				className,
			)}
		>
			<span
				// Size and position the screen reader text to make the visual
				// screen reader cursor a bit better.
				className={cn(
					'sr-only left-0 text-sm',
					isRotated ? 'top-1/4' : 'top-2',
				)}
			>
				{[
					hasTagTop && tagTop,
					screenReaderPrice,
					// Maybe the component knows a bit too much here, but the bottom
					// tag is often used to display savings where the string contains
					// the price symbol (e.g. .-) that can be problematic for screen
					// readers. Just remove instead of replacing with a proper currency;
					// the context from the main price should make it clear that this
					// is related and having the full 'euros and cents' equivalent read
					// again is needlessly verbose anyway.
					hasTagBottom && removeCurrencySymbols(tagBottom),
				]
					.filter(Boolean)
					.join(', ')}
			</span>

			{hasTagTop && (
				<p
					aria-hidden
					className={cn(
						tagBaseClasses,
						theme === 'julaPro' && 'bg-greyDarker',
						theme === 'julaClub' && 'bg-julaRedDarker',
						is.oneOf(size, 'medium', 'large') ? 'mb-1' : 'mb-0.5',
					)}
				>
					{tagTop}
				</p>
			)}

			<div aria-hidden className={cn(mainPartClasses, 'pointer-events-auto')}>
				{label && (
					<p
						className={cn(
							'block whitespace-nowrap leading-none',
							// Place above the main text to handle text backplate in
							// forced colors mode.
							'relative z-1',
							is.oneOf(size, 'medium', 'large')
								? '-mb-[0.3333em] -mt-[0.1em]'
								: '-my-[0.15em]',

							// Text style
							labelAlign === 'right' && 'text-right',
							is.oneOf(size, 'medium', 'large') ? 'font-alt' : 'font-standard',
							is.oneOf(size, 'small', 'medium', 'large')
								? 'font-bold'
								: 'font-normal',

							// Text size
							is.oneOf(size, 'extraNano', 'nano') && 'text-[0.4375rem]',
							is.oneOf(size, 'microCompact', 'micro') && 'text-[0.5625rem]',
							size === 'mini' && 'text-[0.6875rem]',
							size === 'small' && 'text-[0.8125rem]',
							size === 'medium' && 'text-[1.3125rem]',
							size === 'large' && 'text-[1.5rem]',
						)}
					>
						{label}
					</p>
				)}

				{mainText}
			</div>

			{hasTagBottom && (
				<p
					aria-hidden
					className={cn(
						tagBaseClasses,
						'relative z-1 self-end',
						theme === 'regular' && [
							'-mr-[0.25em]',
							is.oneOf(size, 'medium', 'large')
								? '-mt-[0.5em]'
								: '-mt-[0.3125em]',
						],
						theme !== 'regular' && [
							'-mr-[0.5em] ml-[0.625em] bg-julaRedDark',
							is.oneOf(size, 'medium', 'large')
								? '-mt-[0.875em]'
								: '-mt-[0.625em]',
						],
					)}
				>
					{tagBottom}
				</p>
			)}
		</div>
	);
}
Price.displayName = 'Price';
