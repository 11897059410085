import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlockContactHeading from 'components/BlockContactHeading';
import { type IconName } from 'components/Icon';
import { useIsEditing } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic/getEditorMargin';

type Props = JulaComponentProps & {
	fields?: {
		description?: Field<string>;
		heading?: Field<string>;
		icon?: {
			fields?: {
				iconName: Field<IconName>;
			};
		};
	};
};

export default function ContactHeading({ fields, params }: Props) {
	const isEditing = useIsEditing();

	if (!fields) {
		return null;
	}

	if (!isEditing && !fields.heading) {
		return null;
	}

	return (
		<BlockContactHeading
			className={getEditorMargin(params)}
			description={fields.description}
			heading={fields.heading}
			headingIcon={fields.icon?.fields?.iconName.value}
			headingLevel={params?.heading}
			id={params?.anchor}
		/>
	);
}
ContactHeading.displayName = 'ContactHeading';
