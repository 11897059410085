import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import Script from 'next/script';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import { useEffectOnce } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { Flowbox as FlowboxApi } from 'models/vendor';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		id: Field<string>;
		key: Field<string>;
		locale?: Field<string>;
	};
};

export default function Flowbox({ fields, params }: Props) {
	const id = fields?.id?.value;
	const key = fields?.key?.value;
	const locale = fields?.locale?.value || 'sv-SE';

	useEffectOnce(() => {
		if (globalThis.flowbox && key && id) {
			globalThis.flowbox('init', {
				container: `#${id}`,
				key,
				locale,
			});
		}
	});

	if (!id || !key) {
		return (
			<ComponentPlaceholder
				componentName="Flowbox"
				description="Requires an id and a key"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer outerClassName={getEditorMargin(params)}>
			{/* Negative margin to align with the edge, items have outer padding. */}
			<div className="-mx-2 min-h-[60rem]" id={id} />
			<Script
				src="https://connect.getflowbox.com/flowbox.js"
				id="flowbox-js-embed"
				strategy="afterInteractive"
				onLoad={() => {
					if (!globalThis.flowbox) {
						const flowbox: FlowboxApi = (...args) => {
							flowbox.q.push(args);
						};
						flowbox.q = [];
						flowbox('init', {
							container: `#${id}`,
							key,
							locale,
						});
						globalThis.flowbox = flowbox;
					}
				}}
			/>
		</LayoutContainer>
	);
}
Flowbox.displayName = 'Flowbox';
