import React, { ReactNode } from 'react';

import type { VariantId } from 'models/product';

import { UspContextProvider } from './UspContextProvider';

interface Props {
	children: ReactNode;
	productId: VariantId;
}

export default function ProductDetailsContexts({ children, productId }: Props) {
	return (
		<UspContextProvider variantId={productId}>{children}</UspContextProvider>
	);
}
ProductDetailsContexts.displayName = 'ProductDetailsContexts';
