import type { JulaProCustomerContact } from 'models/api';
import { API_URL, fetchData } from 'utils/fetch';

import type { AddUserRequestParams } from './userManagement.types';

/** Gets the user list */
export const requestGetUsers = () =>
	fetchData<JulaProCustomerContact[]>(`${API_URL}Customer/julapro/contact`);

/** Gets a single user by id */
export const requestGetUser = (customerContactId: string) =>
	fetchData<JulaProCustomerContact>(
		`${API_URL}Customer/julapro/contact/${customerContactId}`,
	);

export const requestAddUser = (addUserRequestParams: AddUserRequestParams) =>
	fetchData(`${API_URL}Customer/julapro/contact`, {
		method: 'POST',
		body: JSON.stringify(addUserRequestParams),
	});

/** Invite user to ecom */
export const requestInviteUserToEcom = (customerContactId: string) =>
	fetchData(
		`${API_URL}Customer/julapro/contact/${customerContactId}?allowOnlinePurchase=true`,
		{
			method: 'PUT',
		},
	);

/** Revoke user from ecom */
export const requestRevokeUserFromEcom = (customerContactId: string) =>
	fetchData(
		`${API_URL}Customer/julapro/contact/${customerContactId}?allowOnlinePurchase=false`,
		{
			method: 'PUT',
		},
	);

/** Remove user */
export const requestRemoveUser = (customerContactId: string) =>
	fetchData(`${API_URL}Customer/julapro/contact/${customerContactId}`, {
		method: 'DELETE',
	});
