// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconShareLocationOff(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<path d="M22 12c0 .24-.09.48-.26.65a.88.88 0 0 1-.65.26h-.92a8.5 8.5 0 0 1-.57 2.2.76.76 0 0 1-.5.45.83.83 0 0 1-.68-.07.93.93 0 0 1-.44-.53c-.07-.24-.06-.49.03-.72a7.3 7.3 0 0 0 .38-2.24 6.16 6.16 0 0 0-1.87-4.52A6.16 6.16 0 0 0 12 5.61a6.4 6.4 0 0 0-2.24.38 1 1 0 0 1-.72.02.92.92 0 0 1-.53-.43.83.83 0 0 1-.07-.68.76.76 0 0 1 .46-.5 7 7 0 0 1 2.19-.57V2.9c0-.24.09-.48.26-.65s.4-.27.65-.26a.88.88 0 0 1 .91.91v.92a8 8 0 0 1 4.9 2.36 8 8 0 0 1 2.37 4.9h.91A.88.88 0 0 1 22 12m-10.91 9.09v-.92a8 8 0 0 1-4.9-2.36 8 8 0 0 1-2.37-4.9h-.9a.88.88 0 0 1-.66-.26A.88.88 0 0 1 2 12a.88.88 0 0 1 .91-.91h.92c.07-.62.21-1.23.43-1.82.22-.57.48-1.13.8-1.65l-2.1-2.1a.87.87 0 0 1-.25-.64.85.85 0 0 1 .89-.89c.24-.01.47.08.64.25l15.5 15.5c.18.17.28.4.27.65a.9.9 0 0 1-.27.65.98.98 0 0 1-.65.26.85.85 0 0 1-.65-.26l-2.06-2.1c-.52.32-1.08.58-1.65.8-.59.22-1.2.36-1.82.43v.92c0 .24-.09.48-.26.65A.88.88 0 0 1 12 22a.88.88 0 0 1-.65-.26.88.88 0 0 1-.26-.65m.91-2.7a6.16 6.16 0 0 0 3.06-.77L6.38 8.94A5.8 5.8 0 0 0 5.62 12a6.16 6.16 0 0 0 1.87 4.52A6.17 6.17 0 0 0 12 18.4Z" />
		</svg>
	);
}
IconShareLocationOff.displayName = 'IconShareLocationOff';
