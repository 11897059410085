import React from 'react';

import Button from 'components/Button';
import CreditSimulationPopover from 'components/CreditSimulation';
import Img from 'components/Img';
import InfoBox from 'components/InfoBox';
import { AnchorLink } from 'components/Link';
import PaymentMethodLogos from 'components/PaymentMethodLogos';
import Price from 'components/Price';
import {
	MIN_ANSWER_COUNT,
	TOO_LARGE_PERCENTAGE,
	TOO_SMALL_PERCENTAGE,
} from 'components/ProductFit';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Staffling from 'components/Staffling';
import Text from 'components/Text';
import { type StoreIdName } from 'contexts';
import type { ValidationError } from 'errors';
import type { ExistingPlacement } from 'hooks/product-details';
import type {
	CreateCustomizationRequest,
	CustomizationPlacement,
	ItemStockResponse,
	Placement,
	QuestionSummary,
	Stock,
	StoredFileResponse,
} from 'models/api';
import type { Price as PriceModel } from 'models/price';
import type { CampaignResponse, Product } from 'models/product';
import type { RelatedProductCard } from 'models/productCard';
import type { ActionButtonState } from 'state-machines/actionButton.machine';
import type { ButtonStateWithId } from 'state-machines/cart';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';
import { getWeightedPercentage } from 'utils/math';

import {
	PRODUCT_REVIEWS_ID,
	PRODUCT_TECHNICAL_ATTRIBUTES_ID,
} from './helpers/constants';
import ButtonPanel from './ButtonPanel';
import { SizePicker, StylePicker, VariantPicker } from './Pickers';
import Prices from './Prices';
import { ProductDetailsCampaignProductsPopover } from './ProductDetailsCampaign';
import ProductPrint from './ProductPrint';
import StockInformation from './StockInformation';

export interface Props {
	addPrintPlacementButtonState: ActionButtonState;
	addPrintPlacementErrors: ValidationError | undefined;
	allStoresStock: Stock[] | undefined;
	creditSimulationPopoverIsOpen: boolean;
	currentProductStock: ItemStockResponse | undefined;
	currentStoreStock: Stock | undefined;
	existingPrintPlacements: ExistingPlacement[] | undefined;
	fileUploadButtonState: ActionButtonState;
	fit3Summary?: QuestionSummary;
	isInitialLoadingProductPrint: boolean;
	isLoadingAllStoresStock: boolean;
	isLoadingFit?: boolean;
	isLoadingInitialStock: boolean;
	isLoadingNearbyStoresStock: boolean;
	isLoadingProductStock: boolean;
	isLoadingVariantsStock: boolean;
	isPurchasable: boolean;
	nearbyStoresStock: Stock[] | undefined;
	onAddPrintPlacementClick: (newPrint: CreateCustomizationRequest) => void;
	onCampaignPopoverProductAddToCartClick: (
		variant: RelatedProductCard,
		campaign: CampaignResponse,
	) => void;
	onCreditSimulationClick: () => void;
	onCreditSimulationPopoverClose: () => void;
	onEnergyLabelClick: () => void;
	onMainPurchaseButtonClick: () => void;
	onRemovePrintClick: (id: string) => void;
	onRemovePrintPlacementClick: (placementId: string) => void;
	onReusePrintClick: (id: string) => void;
	onSizeFitClick: () => void;
	onSizeGuideClick: () => void;
	onStockInformationOpen: () => void;
	onUpdateSelectedStore: (store: StoreIdName) => void;
	onUpLoadPrint: (file: File) => void;
	onVariantPickerOpen: () => void;
	onWishlistButtonClick: () => void;
	placements: Placement[] | undefined;
	print: StoredFileResponse | undefined;
	printPlacements: CustomizationPlacement[] | undefined;
	printUploadErrors?: string[] | undefined;
	product: Product;
	productPrintHasError: boolean;
	productPrintTotalCost: PriceModel | undefined;
	purchaseButtonState: ButtonStateWithId;
	selectedStore: StoreIdName | undefined;
	storedPrints: StoredFileResponse[] | undefined;
	variantsStock: ItemStockResponse[] | undefined;
	wishlistButtonState: ButtonStateWithId;
}

export default function ProductDetailsSidebar({
	addPrintPlacementButtonState,
	addPrintPlacementErrors,
	allStoresStock,
	creditSimulationPopoverIsOpen,
	currentProductStock,
	currentStoreStock,
	existingPrintPlacements,
	fileUploadButtonState,
	fit3Summary,
	isInitialLoadingProductPrint,
	isLoadingAllStoresStock,
	isLoadingFit = false,
	isLoadingInitialStock,
	isLoadingNearbyStoresStock,
	isLoadingProductStock,
	isLoadingVariantsStock,
	isPurchasable,
	nearbyStoresStock,
	onAddPrintPlacementClick,
	onCampaignPopoverProductAddToCartClick,
	onCreditSimulationClick,
	onCreditSimulationPopoverClose,
	onEnergyLabelClick,
	onMainPurchaseButtonClick,
	onRemovePrintClick,
	onRemovePrintPlacementClick,
	onReusePrintClick,
	onSizeFitClick,
	onSizeGuideClick,
	onStockInformationOpen,
	onUpdateSelectedStore,
	onUpLoadPrint,
	onVariantPickerOpen,
	onWishlistButtonClick,
	placements,
	print,
	printPlacements,
	printUploadErrors,
	product,
	productPrintHasError,
	productPrintTotalCost,
	purchaseButtonState,
	selectedStore,
	storedPrints,
	variantsStock,
	wishlistButtonState,
}: Props) {
	const { t } = useI18n();
	const {
		customization,
		energyInformation,
		productSheetLink,
		shippingUspExcVat,
		shippingUspIncVat,
		showCreditSimulationLink,
		sizeGuideUrl,
		staffPrice,
		svgImages,
		stylesAndColors,
		variants,
		volumePrices,
	} = product;
	const energySymbolSrc = energyInformation?.symbol?.versions?.find(
		(x) => x.subType === 'EfficiencySymbol',
	)?.formats?.[0]?.url?.location;

	const perceivedFitPercentage =
		fit3Summary?.options?.length && fit3Summary.count >= MIN_ANSWER_COUNT
			? getWeightedPercentage(...fit3Summary.options.map((opt) => opt.count))
			: undefined;
	const perceivedFitText =
		// Explicitly check undefined to not exclude zero.
		perceivedFitPercentage === undefined
			? undefined
			: perceivedFitPercentage < TOO_SMALL_PERCENTAGE
				? t('question_summary_fit_is_small_title')
				: perceivedFitPercentage > TOO_LARGE_PERCENTAGE
					? t('question_summary_fit_is_large_title')
					: t('question_summary_fit_is_perfect_title');

	return (
		<>
			<Prices
				displayPrice={product.displayPrice}
				basePrice={product.basePrice}
			/>

			{Boolean(energySymbolSrc || productSheetLink) && (
				<div className="my-8 flex items-center">
					{energySymbolSrc && (
						<AnchorLink
							anchor={PRODUCT_TECHNICAL_ATTRIBUTES_ID}
							onClick={onEnergyLabelClick}
							className="mr-2 hover:opacity-80"
						>
							<Img
								src={energySymbolSrc}
								alt={t('product_details_technical_specification_heading')}
								className="h-12 object-contain"
							/>
						</AnchorLink>
					)}
					{productSheetLink && (
						<Text as="pSmall">
							<a
								href={productSheetLink}
								target="_blank"
								rel="nofollow noopener noreferrer"
								className="underline hover:no-underline"
							>
								{product.productSheetLinkDescription ||
									t('product_details_product_sheet_button')}
							</a>
						</Text>
					)}
				</div>
			)}

			{staffPrice && (
				<div className="mb-3 sm:mb-6">
					<InfoBox
						heading={t('product_details_staff_price_heading')}
						icon="info"
						className="!mt-8"
					>
						<div className="mb-4 mt-2 text-sm">
							{t('product_details_staff_price_text')}
						</div>
						<Price price={staffPrice} hideSavings size="mini" />
					</InfoBox>
				</div>
			)}

			{is.arrayWithLength(volumePrices) && (
				<div className="space-between mt-6 flex flex-col gap-4 md:mt-8">
					{volumePrices.map((price, i) => (
						// Replacing index for key would require most price properties
						// to guarantee uniqueness. Index is unlikely a problem here.
						// eslint-disable-next-line react/no-array-index-key
						<Staffling key={i} volumePrice={price} />
					))}
				</div>
			)}
			<ProductDetailsCampaignProductsPopover
				campaigns={product.campaigns}
				purchaseButtonState={purchaseButtonState}
				onAddToCartClick={onCampaignPopoverProductAddToCartClick}
			/>

			{showCreditSimulationLink && (
				<>
					<CreditSimulationPopover
						isOpen={creditSimulationPopoverIsOpen}
						onClose={onCreditSimulationPopoverClose}
						price={product.displayPrice?.priceIncVat?.value}
					/>
					<Button
						variant="text"
						className="mt-6"
						onClick={onCreditSimulationClick}
						aria-haspopup="dialog"
					>
						{t('product_details_credit_simulation_link_button')}
					</Button>
				</>
			)}

			<StylePicker
				className="mt-8"
				product={product}
				variants={stylesAndColors}
			/>
			{!sizeGuideUrl && (
				<VariantPicker
					className={stylesAndColors?.length ? 'mt-4' : 'mt-8'}
					onOpen={onVariantPickerOpen}
					product={product}
					selectedStore={selectedStore}
					stockData={variantsStock}
					stockIsLoading={isLoadingVariantsStock}
					variants={variants}
				/>
			)}
			{sizeGuideUrl && (
				<>
					<SizePicker
						className={stylesAndColors?.length ? 'mt-4' : 'mt-8'}
						fit3Summary={fit3Summary}
						onOpen={onVariantPickerOpen}
						product={product}
						selectedStore={selectedStore}
						stockData={variantsStock}
						stockIsLoading={isLoadingVariantsStock}
						variants={variants}
					/>
					{variants.length > 1 && (
						<div className="flex">
							{isLoadingFit && (
								<Skeleton>
									<SkeletonItem
										height="1.5rem"
										className="my-1 mr-2 w-[7rem] sm:max-md:w-[11rem] lg:w-[11rem]"
									/>
								</Skeleton>
							)}
							{!isLoadingFit && perceivedFitText && (
								<AnchorLink
									anchor={PRODUCT_REVIEWS_ID}
									onClick={onSizeFitClick}
									// Match size guide button height
									className="group mr-4 inline-flex min-h-[2rem] flex-wrap items-center gap-x-1 py-1"
								>
									{`${t('product_details_size_fit')}: `}
									<span className="underline group-hover:no-underline">
										{perceivedFitText}
									</span>
								</AnchorLink>
							)}
							<Button
								variant="text"
								onClick={onSizeGuideClick}
								aria-haspopup="dialog"
								className="ml-auto text-right"
							>
								{t('product_details_sizeguide_button')}
							</Button>
						</div>
					)}
				</>
			)}

			{isPurchasable && (
				<ProductPrint
					className="mt-3"
					// Print availability depends on stock status
					isLoading={isInitialLoadingProductPrint || isLoadingInitialStock}
					hasError={productPrintHasError}
					addPrintPlacementButtonState={addPrintPlacementButtonState}
					fileUploadButtonState={fileUploadButtonState}
					existingPrintPlacements={existingPrintPlacements}
					onAddPrintPlacementClick={onAddPrintPlacementClick}
					onRemovePrintPlacementClick={onRemovePrintPlacementClick}
					onReusePrintClick={onReusePrintClick}
					onSelectExistingPrintPlacementClick={onAddPrintPlacementClick}
					onUpLoadPrint={onUpLoadPrint}
					placements={placements}
					print={print}
					productId={product.id}
					printPlacements={printPlacements}
					storedPrints={storedPrints}
					total={productPrintTotalCost}
					printOnClothes={customization}
					onRemovePrintClick={onRemovePrintClick}
					printUploadErrors={printUploadErrors}
					addPrintPlacementErrors={addPrintPlacementErrors}
				/>
			)}

			<ButtonPanel
				onPurchaseButtonClick={onMainPurchaseButtonClick}
				onWishlistButtonClick={onWishlistButtonClick}
				purchaseButtonDisabled={!isPurchasable}
				purchaseButtonState={
					purchaseButtonState.buttonId === product.id
						? purchaseButtonState.state
						: 'idle'
				}
				wishlistButtonDisabled={Boolean(printPlacements?.length)}
				wishlistButtonState={
					wishlistButtonState.buttonId === product.id
						? wishlistButtonState.state
						: 'idle'
				}
			/>
				<PaymentMethodLogos
					className="mt-4"
					images={svgImages?.value.assets.flat()}
				/>
			<StockInformation
				className="mt-6 md:mt-8"
				onOpenCallBack={onStockInformationOpen}
				onUpdateSelectedStore={onUpdateSelectedStore}
				product={product}
				currentStoreStock={currentStoreStock}
				allStoresStock={allStoresStock}
				nearbyStoresStock={nearbyStoresStock}
				currentProductStock={currentProductStock}
				selectedStore={selectedStore}
				isLoadingProductStock={isLoadingProductStock}
				isLoadingInitialStock={isLoadingInitialStock}
				isLoadingAllStoresStock={isLoadingAllStoresStock}
				isLoadingNearbyStoresStock={isLoadingNearbyStoresStock}
			/>
		</>
	);
}
ProductDetailsSidebar.displayName = 'ProductDetailsSidebar';
