/**
 * BlogItem
 */

import React from 'react';

import ArrowLink from 'components/ArrowLink';
import Img from 'components/Img';
import Link, { OptionalLink } from 'components/Link';
import RichText from 'components/RichText';
import Text from 'components/Text';
import { DigizuiteAsset } from 'models/asset';
import { Tag, TagQueryVar } from 'models/sitecore';
import { formatDate } from 'utils/format';
import { getAsset, is, isHoldingNewTabKey } from 'utils/helpers';
import { createUrl } from 'utils/url';

interface Props {
	backToOverviewText?: string;
	backToOverviewUrl?: string;
	blogRootUrl: string;
	className?: string;
	content: string;
	date: string;
	heading: string;
	images: DigizuiteAsset[][] | undefined;
	onTagClick?: (tagId: string) => void;
	tags: Tag[] | undefined;
	url?: string;
	viewType?: 'overview' | 'details';
}

const TAG_QUERY_VAR: TagQueryVar = 'bo-tag';

/** A blog item used for blog overview and blog page */
export default function BlogDetails({
	backToOverviewText,
	backToOverviewUrl,
	blogRootUrl,
	className,
	content,
	date,
	heading,
	images,
	onTagClick,
	tags,
	url,
	viewType,
}: Props) {
	const filteredImages = images
		?.map(
			(assetList) =>
				getAsset('Templated 2:1', assetList) ??
				getAsset('Templated 1:1', assetList),
		)
		.filter(is.truthy);

	return (
		<div className={className}>
			<Text as={viewType === 'details' ? 'h1' : 'h2'}>
				<OptionalLink
					href={url}
					fallback={null}
					linkClassName="hover:underline"
				>
					{heading}
				</OptionalLink>
			</Text>
			<Text
				as="p"
				className={viewType === 'details' ? 'mt-4' : 'mt-2'}
				text={formatDate(date)}
			/>
			<RichText className="mt-6" html={content} />
			{is.arrayWithLength(filteredImages) && (
				<div className="mt-6 flex flex-wrap gap-6">
					{filteredImages.map((image) => (
						<Img
							key={image.src}
							src={image.src}
							service="nextjs"
							height={image.formatName === 'Templated 1:1' ? 393 : 405}
							width={image.formatName === 'Templated 1:1' ? 393 : 810}
							alt={image.alt}
						/>
					))}
				</div>
			)}
			{is.arrayWithLength(tags) && (
				<ul className="mt-6 flex flex-wrap gap-4">
					{tags.map((tag) => (
						<li key={tag.id}>
							<Link
								onClick={(e) => {
									if (onTagClick && !isHoldingNewTabKey(e)) {
										e.preventDefault();
										onTagClick(tag.id);
									}
								}}
								underline
								href={createUrl(blogRootUrl, { [TAG_QUERY_VAR]: tag.id })}
							>
								{tag.name}
							</Link>
						</li>
					))}
				</ul>
			)}
			{backToOverviewUrl && backToOverviewText && (
				<div className="mt-6 border-t border-grey pt-6">
					<ArrowLink href={backToOverviewUrl} text={backToOverviewText} />
				</div>
			)}
		</div>
	);
}

BlogDetails.displayName = 'BlogDetails';
