import React, {
	createContext,
	type ReactNode,
	useContext,
	useMemo,
} from 'react';

import type { CustomerType } from 'types';

export interface UserInfo {
	customerType?: CustomerType;
	loginMethod?: string;
	userType?: string;
}
const UserContext = createContext<UserInfo | undefined>({} as any);

interface Props {
	children: ReactNode;
	userInfo: UserInfo | undefined;
}

export function UserContextProvider({ children, userInfo }: Props) {
	const value = useMemo(
		() => userInfo,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[userInfo?.userType, userInfo?.customerType, userInfo?.loginMethod],
	);

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

UserContextProvider.displayName = 'UserContextProvider';

export function useUserContext() {
	return useContext(UserContext);
}
