/**
 * ProductCardRow
 */

import React, { type MouseEventHandler } from 'react';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import Img from 'components/Img';
import Link from 'components/Link';
import OptionalContainer from 'components/OptionalContainer';
import Price from 'components/Price';
import {
	PRODUCT_REVIEWS_ID,
	PRODUCT_TECHNICAL_ATTRIBUTES_ID,
} from 'components/ProductDetails/';
import ProductTag from 'components/ProductTag';
import Rating from 'components/Rating';
import Text from 'components/Text';
import type { Price as PriceModel } from 'models/price';
import type { ProductTag as ProductTagModel } from 'models/product';
import { cn } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

import ProductCardTags from './ProductCardTags';

export interface Props {
	actionButtonState?: ActionButtonState;

	/** The text inside of the button */
	buttonText?: string;

	/** Extra container class names */
	className?: string;

	/** Is product disabled? */
	disabled?: boolean;

	/** Energy Symbol */
	energySymbolAlt?: string;

	/** Energy Symbol */
	energySymbolSrc?: string;

	/** Product tag in the Flag section */
	flag?: ProductTagModel;

	/** The heading in the component */
	heading?: string;

	/** The link to the product page */
	href: string;

	/** Image alt text */
	imageAlt?: string;

	/** Image src attribute */
	imageSrc: string;

	/** Add to cart callback */
	onAddToCartClick?: MouseEventHandler<HTMLButtonElement>;

	/** Link click callback */
	onLinkClick?: MouseEventHandler<HTMLAnchorElement>;

	/** The main price to display */
	price: PriceModel | undefined;
	/** Link to the product sheet */
	productSheetLink?: string;
	/** Link text for the product sheet link */
	productSheetLinkDescription?: string;

	/** Sets the number of reviews */
	reviews?: number;

	/** Sets the numbers of score/stars */
	score?: number;

	/** Show and hide options for the button */
	showAddToCartButton?: boolean;

	/** The sub heading in the component */
	subHeading?: string;

	/** Product tags in the Product or Price sections */
	tags?: ProductTagModel[];

	/** Vat info price text */
	vatPriceText?: string;
}

/** Product card with a horizontal layout. */
export default function ProductCardRow({
	actionButtonState,
	buttonText,
	className,
	disabled,
	energySymbolAlt = '',
	energySymbolSrc,
	flag,
	heading,
	href,
	imageAlt = '',
	imageSrc,
	onAddToCartClick,
	onLinkClick,
	price,
	productSheetLink,
	productSheetLinkDescription,
	reviews,
	score,
	showAddToCartButton = false,
	subHeading,
	tags,
	vatPriceText,
}: Props) {
	const { t } = useI18n();

	// Enlarge the click area for some targets.
	const enlargedTargetClasses =
		'relative z-3 before:absolute before:inset-x-0 before:-inset-y-2';

	return (
		<article
			className={cn(className, 'relative flex w-full pb-8')}
			aria-label={subHeading}
		>
			<OptionalContainer
				// Normally an unnecessary optimization to be stringent about a
				// wrapping div, but there can be a lot of product cards on a page
				// and the site's DOM size is a problem.
				as="div"
				hasContainer={Boolean(flag)}
				className="relative shrink-0"
			>
				{flag && (
					<ProductTag
						type={flag.type}
						text={flag.text}
						size="small"
						className="absolute left-0 top-0 z-1"
					/>
				)}
				<Link
					href={href}
					onClick={onLinkClick}
					className="mr-4 block shrink-0"
					isScreenReaderHidden
				>
					<Img
						src={imageSrc}
						alt={imageAlt}
						width={96}
						height={96}
						service="nextjs"
						useFallbackOnError
						className="size-24 object-contain"
					/>
				</Link>
			</OptionalContainer>

			<div className="grow">
				{tags && <ProductCardTags tags={tags} className="mb-2" />}
				<header className={cn('relative', !score && 'mb-4')}>
					{heading && (
						<Text as="p" styleAs="h6" className="mb-2">
							<Link
								href={href}
								onClick={onLinkClick}
								cover
								className="hover:underline"
							>
								{heading}
							</Link>
						</Text>
					)}
					{subHeading && (
						<Text as="pXSmall" className="mb-1">
							{subHeading}
						</Text>
					)}
				</header>

				{Boolean(score && score > 0) && (
					<Rating
						size="small"
						score={score}
						reviewCount={reviews}
						href={`${href}#${PRODUCT_REVIEWS_ID}`}
						onClick={onLinkClick}
						className={enlargedTargetClasses}
					/>
				)}

				<div className="mt-2 flex items-center">
					{price && (
						<div className="flex flex-col">
							<Price price={price} size="small" />
							<Text text={vatPriceText} as="pXSmall" />
						</div>
					)}
					{showAddToCartButton && (
						<ActionButton
							size="medium"
							variant="cta"
							disabled={disabled}
							onClick={onAddToCartClick}
							customState={actionButtonState}
							minimunLoadingTime={1000}
							// Avoid layout shifting when the text is changed to a spinner.
							className="ml-auto min-w-[5em]"
						>
							{buttonText || t('product_buy_button')}
						</ActionButton>
					)}
				</div>

				{Boolean(energySymbolSrc || productSheetLink) && (
					<div className="relative z-3 mt-6 flex items-center gap-3 text-xs">
						{energySymbolSrc && (
							<Link
								href={`${href}#${PRODUCT_TECHNICAL_ATTRIBUTES_ID}`}
								onClick={onLinkClick}
								className={cn(enlargedTargetClasses, 'hover:opacity-80')}
							>
								<Img
									src={energySymbolSrc}
									alt={
										energySymbolAlt ||
										t('product_details_technical_specification_heading')
									}
									className="h-6 object-contain"
								/>
							</Link>
						)}
						{productSheetLink && (
							<Link
								href={productSheetLink}
								target="_blank"
								rel="nofollow"
								underline
								className={enlargedTargetClasses}
							>
								{productSheetLinkDescription ||
									t('product_details_product_sheet_button')}
							</Link>
						)}
					</div>
				)}
			</div>
		</article>
	);
}
ProductCardRow.displayName = 'ProductCardRow';
