import { COLORS } from 'constants/colors';
import { ignorePromiseRejection, sendGlobalEvent } from 'utils/helpers';
import { log } from 'utils/log';

// A hack suggested by Zendesk to get the desired customer service flow; these
// phrases are used via macros by customer service agents to trigger a sort
// of custom 'chat has ended' state.
const CHAT_RESET_PHRASES = [
	'Vi avslutar nu chatten och återkommer till dig via mejl',
	'Den här chatten är nu avslutad',
];
const CHAT_RESET_USER_MESSAGE = 'Kunden har avslutat chatten';

// Keep in sync with globals.css
const CHAT_IS_OPEN_CLASS = 'zendesk-chat-open';

// Session storage keys are saved as `<integrationId>.<suffix>`.
const SESSION_KEY_SUFFIXES = [
	// Keep clientId first, it's the only one that is set as soon as the chat
	// widget is loaded. The others are set after a conversation has started.
	// It's used below when getting the ID.
	'.clientId',
	'.appUserId',
	'.sessionToken',
	'.conversationStartedAt',
] as const;

const GLOBAL_RESET_FUNCTION_NAME = 'julaResetSmoochChat';
const GLOBAL_EARLY_RESET_FUNCTION_NAME = 'julaResetSmoochChatEarly';
const RESET_BUTTON_CLASS = 'jula-chat-reset-button';

export interface ChatGlobalEvents {
	'chat-widget-close': undefined;
	'chat-widget-init': undefined;
	'chat-widget-open': undefined;
	'chat-widget-ready': undefined;
	'chat-widget-reset': undefined;
	'chat-widget-unread-count': CustomEvent<{ count: number }>;
}

function messageHasResetPhrase(message: string) {
	return CHAT_RESET_PHRASES.some((phrase) =>
		message.toLowerCase().includes(phrase.toLowerCase()),
	);
}

function getZendeskIframeDocument() {
	const iframe = document.getElementById(
		'web-messenger-container',
	) as HTMLIFrameElement | null;
	return iframe?.contentDocument;
}

function injectResetButton(
	buttonText: string,
	injectionType: 'addNextToTextField' | 'replaceTextField',
) {
	const footer = getZendeskIframeDocument()?.getElementById('footer');
	if (!footer) {
		return;
	}
	const funcName =
		injectionType === 'addNextToTextField'
			? GLOBAL_EARLY_RESET_FUNCTION_NAME
			: GLOBAL_RESET_FUNCTION_NAME;
	const html = `<button type="button" onclick="parent.${funcName}();" class="${RESET_BUTTON_CLASS}">${buttonText}</button>`;
	if (
		injectionType === 'addNextToTextField' &&
		// Don't want multiple buttons.
		!footer.querySelector(`.${RESET_BUTTON_CLASS}`)
	) {
		footer.insertAdjacentHTML('afterbegin', html);
	} else if (injectionType === 'replaceTextField') {
		footer.innerHTML = html;
	}
}

export function getZendeskSessionId() {
	return globalThis.sessionStorage
		? Object.keys(globalThis.sessionStorage)
				.find((key) => key.endsWith(SESSION_KEY_SUFFIXES[0]))
				?.split('.')[0]
		: undefined;
}

export function hasZendeskSession() {
	return Boolean(getZendeskSessionId());
}

export function clearZendeskSession() {
	const id = getZendeskSessionId();
	if (id) {
		SESSION_KEY_SUFFIXES.forEach((key) => {
			sessionStorage.removeItem(`${id}${key}`);
		});
	}
}

function getOptionColor(color: keyof typeof COLORS) {
	return COLORS[color].replace('#', '');
}

export interface InitOptions {
	resetButtonText: string;
	Smooch: typeof globalThis.Smooch;
	title: string;
	zendeskKey: string;
}

export function initSmooch({
	resetButtonText,
	Smooch,
	title,
	zendeskKey,
}: InitOptions) {
	if (!Smooch) {
		return;
	}

	// Insert a reset button when certain messages are received.
	Smooch.on?.('message:received', (message) => {
		if (message.type === 'text' && messageHasResetPhrase(message.text)) {
			injectResetButton(resetButtonText, 'replaceTextField');
		}
	});

	Smooch.on?.('widget:opened', () => {
		document.body.classList.add(CHAT_IS_OPEN_CLASS);
		sendGlobalEvent('chat-widget-open');

		// Check for reset messages. A user could received one and reload the
		// page without pressing the injected button.
		const hasResetMessage = Smooch.getDisplayedConversation?.()
			?.messages.filter((msg) => msg.type === 'text' && msg.role === 'business')
			.some((msg) => messageHasResetPhrase(msg.text));
		injectResetButton(
			resetButtonText,
			hasResetMessage ? 'replaceTextField' : 'addNextToTextField',
		);

		// Create an initial conversation if there are none.
		if (!Smooch.getConversations?.().length) {
			ignorePromiseRejection(Smooch.createConversation?.());
		}
	});

	Smooch.on?.('widget:closed', () => {
		document.body.classList.remove(CHAT_IS_OPEN_CLASS);
		sendGlobalEvent('chat-widget-close');
	});

	Smooch.on?.('unreadCount', (count) => {
		sendGlobalEvent('chat-widget-unread-count', { count });
	});

	Smooch.init?.({
		integrationId: zendeskKey,
		region: 'eu-1',
		integrationOrder: [],
		browserStorage: 'sessionStorage',
		notificationChannelPromptEnabled: false,
		soundNotificationEnabled: false,
		canUserSeeConversationList: false,
		businessName: title,
		customColors: {
			brandColor: getOptionColor('julaRed'),
			conversationColor: getOptionColor('greyLighter'),
			actionColor: getOptionColor('cta'),
		},
	})
		.then(() => {
			sendGlobalEvent('chat-widget-init');
			// Add custom styles to the iframe.
			getZendeskIframeDocument()
				?.getElementsByTagName('head')?.[0]
				// `#messenger-button` is the floating chat button, we have our own.
				// `#conversation .logo` is the 'powered by' text.
				// Reset button mimics Zendesk styles, set `canUserSeeConversationList`
				// to true to see their 'new conversation' button.
				// The green button style for only-child is when the reset button has
				// replaced the chat text input, otherwise it's more subtle like Jula
				// secondary buttons to grab less attention.
				?.insertAdjacentHTML(
					'beforeend',
					`<style>
						#messenger-button,
						#conversation .logo {
							display: none;
						}
						#footer {
							flex-wrap: wrap;
						}
						.${RESET_BUTTON_CLASS} {
							background: ${COLORS.white};
							color: inherit;
							border: 1px solid ${COLORS.greyDark};
							border-radius: 20px;
							font-size: 14px;
							margin: 10px 10px 0;
							min-height: 40px;
							width: 100%;
						}
						.${RESET_BUTTON_CLASS}:hover {
							background: ${COLORS.white};
							outline: 1px solid ${COLORS.greyDark};
						}
						.${RESET_BUTTON_CLASS}:only-child {
							border-color: transparent;
							margin: 10px;
							background: ${COLORS.cta};
							color: ${COLORS.white};
						}
						.${RESET_BUTTON_CLASS}:only-child:hover {
							background: ${COLORS.ctaDarker};
							outline-color: transparent;
						}
					</style>`,
				);
		})
		.catch((error) => {
			log.error('Chat init error', error);
		});

	const resetChat = () => {
		Smooch.close?.();
		setTimeout(() => {
			Smooch.destroy?.();
			clearZendeskSession();
			sendGlobalEvent('chat-widget-reset');
		}, 500);
	};
	globalThis[GLOBAL_EARLY_RESET_FUNCTION_NAME] = () => {
		if (!hasZendeskSession()) {
			return;
		}
		// Send a message to the agent to let them know the user has left.
		// Delay before reset to give the message time to send.
		const convoId = Smooch.getDisplayedConversation?.()?.id;
		if (convoId) {
			Smooch.close?.();
			Smooch.sendMessage?.(CHAT_RESET_USER_MESSAGE, convoId);
			setTimeout(() => {
				resetChat();
			}, 500);
		} else {
			resetChat();
		}
	};
	globalThis[GLOBAL_RESET_FUNCTION_NAME] = () => {
		if (hasZendeskSession()) {
			resetChat();
		}
	};
}
