import { useCallback, useEffect, useState } from 'react';
import { useSelector } from '@xstate/react';
import { EmittedFrom } from 'xstate';
import { waitFor } from 'xstate/lib/waitFor';

import { useCheckoutContext, useGlobalMachinesContext } from 'contexts';
import { useTemporaryStoredValue } from 'hooks/useTemporaryStoredValue';
import { CartMachineActor } from 'state-machines/cart';
import {
	CheckoutMachineActor,
	CheckoutMachineState,
	selectCartData,
	selectCartHasLoaded,
} from 'state-machines/checkout';
import { isPaymentTypeWithComponentState } from 'state-machines/checkout/helpers';
import { WishlistMachineActor } from 'state-machines/wishlist';
import {
	type GTMItemListId,
	type GTMItemListName,
	type GTMListProduct,
	type GTMPriceProduct,
	pushToGTM,
} from 'utils/GoogleTagManager';
import { ignorePromiseRejection, sleep } from 'utils/helpers';

async function waitForServiceIdle<
	TActorRef extends
		| CartMachineActor
		| CheckoutMachineActor
		| WishlistMachineActor,
>(service: TActorRef, stateName = 'idle'): Promise<EmittedFrom<TActorRef>> {
	// Wait a bit in case this helper gets called before the machine moves
	// away from idle, e.g.
	// sendAddToCartEvent(...);
	// cartService.send(...)
	await sleep(50);
	// @ts-expect-error: I have no idea why EmittedFrom, for these specific actors,
	// does not have the 'matches' method.
	return waitFor(service, (state) => state.matches(stateName));
}

/**
 * Helpers for sending view_item_list, select_item and add_to_cart events
 * for product lists.
 */
export function useProductListGTMEvents(
	itemListId: GTMItemListId,
	itemListName: GTMItemListName,
) {
	const { cartService, wishlistService } = useGlobalMachinesContext();
	const { setStoredValue } = useTemporaryStoredValue('GTM_LIST_DATA');

	const sendViewItemListEvent = useCallback(
		(products: GTMListProduct[], pageSize: number) => {
			if (products.length > 0 && pageSize > 0) {
				pushToGTM({
					type: 'view_item_list',
					payload: {
						itemListId,
						itemListName,
						pageSize,
						products,
					},
				});
			}
		},
		[itemListId, itemListName],
	);

	const sendSelectItemEvent = useCallback(
		(product: GTMListProduct, productIndex: number) => {
			pushToGTM({
				type: 'select_item',
				payload: {
					itemListId,
					itemListName,
					product,
					productIndex,
				},
			});
			setStoredValue({
				gtmItemListId: itemListId,
				gtmItemListName: itemListName,
			});
		},
		[itemListId, itemListName, setStoredValue],
	);

	const sendAddToCartEventOnIdle = useCallback(
		async (product: GTMPriceProduct, quantity: number = 1) => {
			const cartState = await waitForServiceIdle(cartService);
			pushToGTM({
				type: 'add_to_cart',
				payload: {
					cartId: cartState.context.id,
					itemListId,
					itemListName,
					product,
					quantity,
				},
			});
		},
		[cartService, itemListId, itemListName],
	);
	// Wrapper to keep the async internal.
	const sendAddToCartEvent = useCallback(
		(product: GTMPriceProduct, quantity: number = 1) => {
			ignorePromiseRejection(sendAddToCartEventOnIdle(product, quantity));
		},
		[sendAddToCartEventOnIdle],
	);

	const sendAddToWishlistEventOnIdle = useCallback(
		async (product: GTMPriceProduct, quantity: number = 1) => {
			await waitForServiceIdle(wishlistService, 'addOneToWishlist.idle');

			pushToGTM({
				type: 'add_to_wishlist',
				payload: {
					itemListId,
					itemListName,
					product,
					quantity,
				},
			});
		},
		[itemListId, itemListName, wishlistService],
	);
	// Wrapper to keep the async internal.
	const sendAddToWishlistEvent = useCallback(
		(product: GTMPriceProduct, quantity: number = 1) => {
			ignorePromiseRejection(sendAddToWishlistEventOnIdle(product, quantity));
		},
		[sendAddToWishlistEventOnIdle],
	);

	return {
		sendViewItemListEvent,
		sendSelectItemEvent,
		sendAddToCartEvent,
		sendAddToWishlistEvent,
		// Send them back out for convenience, here they're typed instead of
		// just plain strings.
		gtmItemListId: itemListId,
		gtmItemListName: itemListName,
	};
}

export function useProductGTMEvents() {
	const { cartService } = useGlobalMachinesContext();

	const sendAddToCartEventOnIdle = useCallback(
		async ({
			itemListId,
			itemListName,
			product,
			quantity = 1,
		}: {
			itemListId?: GTMItemListId;
			itemListName?: GTMItemListName;
			product: GTMPriceProduct;
			quantity: number;
		}) => {
			const cartState = await waitForServiceIdle(cartService);

			pushToGTM({
				type: 'add_to_cart',
				payload: {
					cartId: cartState.context.id,
					itemListId,
					itemListName,
					product,
					quantity,
				},
			});
		},
		[cartService],
	);
	// Wrapper to keep the async internal.
	const sendAddToCartEvent = useCallback(
		({
			itemListId,
			itemListName,
			product,
			quantity = 1,
		}: {
			itemListId?: GTMItemListId;
			itemListName?: GTMItemListName;
			product: GTMPriceProduct;
			quantity: number;
		}) => {
			ignorePromiseRejection(
				sendAddToCartEventOnIdle({
					product,
					quantity,
					itemListId,
					itemListName,
				}),
			);
		},
		[sendAddToCartEventOnIdle],
	);

	const sendRemoveFromCartEventOnIdle = useCallback(
		async (product: GTMPriceProduct, quantity: number) => {
			const cartState = await waitForServiceIdle(cartService);

			pushToGTM({
				type: 'remove_from_cart',
				payload: {
					cartId: cartState.context.id,
					product,
					quantity,
				},
			});
		},
		[cartService],
	);
	// Wrapper to keep the async internal.
	const sendRemoveFromCartEvent = useCallback(
		(product: GTMPriceProduct, quantity: number) => {
			ignorePromiseRejection(sendRemoveFromCartEventOnIdle(product, quantity));
		},
		[sendRemoveFromCartEventOnIdle],
	);

	const sendAddMultipleToCartEventOnIdle = useCallback(
		async ({
			products,
		}: {
			products: { product: GTMPriceProduct; quantity: number }[];
		}) => {
			const cartState = await waitForServiceIdle(cartService);

			pushToGTM({
				type: 'add_multiple_to_cart',
				payload: {
					cartId: cartState.context.id,
					productsData: products,
				},
			});
		},
		[cartService],
	);
	// Wrapper to keep the async internal.
	const sendAddMultipleToCartEvent = useCallback(
		({
			products,
		}: {
			products: { product: GTMPriceProduct; quantity: number }[];
		}) => {
			ignorePromiseRejection(
				sendAddMultipleToCartEventOnIdle({
					products,
				}),
			);
		},
		[sendAddMultipleToCartEventOnIdle],
	);

	return {
		sendAddToCartEvent,
		sendRemoveFromCartEvent,
		sendAddMultipleToCartEvent,
	};
}

function hasValidShippingInfo(checkoutState: CheckoutMachineState) {
	return !checkoutState.context?.data?.errorList?.some(
		(error) =>
			error.type === 'PickupLocationNotSelected' ||
			error.type === 'PickupLocationInvalid' ||
			error.type === 'DeliveryMethodNotSelected' ||
			error.type === 'DeliveryMethodInvalid',
	);
}

/**
 * The idea is to capture checkout events in as a funnel but send them once and when they have valid data.
 * since the checkout is open to changes in all parts right away
 * we have to make some assumptions to attempt to capture the events in the right order
 * if a valid shipping choice is done add_shipping_info is sent
 * if a valid payment choice is done add_payment_info is sent
 * if a default valid shipping choice is present and a payment choice is made we attempt to send both
 * if none are sent and payment is initiated we send both
 */
export function useCheckoutGTMEvents() {
	const { checkoutService } = useCheckoutContext();
	const isInitiatingPayment = useSelector(checkoutService, (state) =>
		state.hasTag('initiatingPayment'),
	);

	const hasCartLoaded = useSelector(checkoutService, selectCartHasLoaded);
	const cartData = useSelector(checkoutService, selectCartData);
	const shouldSendBeginCheckout = useSelector(
		checkoutService,
		(state) =>
			state.context.data?.checkoutStatus === 'Valid' ||
			state.context.data?.checkoutStatus === 'Invalid',
	);

	const [hasSentAddShippingInfo, setHasSentAddShippingInfo] = useState(false);
	const [hasSentAddPaymentInfo, setHasSentAddPaymentInfo] = useState(false);
	const [hasSentBeginCheckout, setHasSentBeginCheckout] = useState(false);

	useEffect(() => {
		if (
			hasCartLoaded &&
			cartData &&
			shouldSendBeginCheckout &&
			!hasSentBeginCheckout
		) {
			pushToGTM({ type: 'begin_checkout', payload: { cart: cartData } });
			setHasSentBeginCheckout(true);
		}
	}, [cartData, hasCartLoaded, hasSentBeginCheckout, shouldSendBeginCheckout]);

	const sendAddShippingInfoEvent = useCallback(
		(checkoutState: CheckoutMachineState) => {
			if (
				!hasSentAddShippingInfo &&
				hasValidShippingInfo(checkoutState) &&
				checkoutState.context.data
			) {
				pushToGTM({
					type: 'add_shipping_info',
					payload: {
						cart: checkoutState.context.data,
					},
				});
				setHasSentAddShippingInfo(true);
			}
		},
		[hasSentAddShippingInfo],
	);

	const sendAddShippingInfoOnIdle = useCallback(async () => {
		const checkoutState = await waitForServiceIdle(checkoutService);
		sendAddShippingInfoEvent(checkoutState);
	}, [checkoutService, sendAddShippingInfoEvent]);

	const sendAddShippingInfo = useCallback(() => {
		ignorePromiseRejection(sendAddShippingInfoOnIdle());
	}, [sendAddShippingInfoOnIdle]);

	const sendAddPaymentInfoOnIdle = useCallback(async () => {
		const checkoutState = await waitForServiceIdle(checkoutService);

		sendAddShippingInfoEvent(checkoutState);

		const selectedPaymentType =
			checkoutState.context.data?.selectedPayment?.selectedPaymentType;
		// since payment methods with ComponentState
		// means we would need to go through the pain of getting the valid state for
		// the payment component before we can send the add_payment_info event
		// we defer it to be sent when the payment is initiated since now or later is the same thing
		if (
			!hasSentAddPaymentInfo &&
			!isPaymentTypeWithComponentState(selectedPaymentType) &&
			checkoutState.context.data
		) {
			pushToGTM({
				type: 'add_payment_info',
				payload: {
					cart: checkoutState.context.data,
				},
			});
			setHasSentAddPaymentInfo(true);
		}
	}, [checkoutService, hasSentAddPaymentInfo, sendAddShippingInfoEvent]);

	const sendAddPaymentInfo = useCallback(() => {
		ignorePromiseRejection(sendAddPaymentInfoOnIdle());
	}, [sendAddPaymentInfoOnIdle]);

	const cart = useSelector(checkoutService, selectCartData);

	useEffect(() => {
		if (isInitiatingPayment) {
			if (!hasSentAddShippingInfo && cart) {
				pushToGTM({
					type: 'add_shipping_info',
					payload: {
						cart,
					},
				});
			}
			if (!hasSentAddPaymentInfo && cart) {
				pushToGTM({
					type: 'add_payment_info',
					payload: {
						cart,
					},
				});
			}
		}
	}, [
		cart,
		hasSentAddPaymentInfo,
		hasSentAddShippingInfo,
		isInitiatingPayment,
	]);

	return {
		sendAddShippingInfo,
		sendAddPaymentInfo,
	};
}
