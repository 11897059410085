import React, { type ButtonHTMLAttributes } from 'react';

type Props = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'aria-disabled'>;

/** Unstyled button that handles accessible disabling. */
const PlainButton = React.forwardRef<HTMLButtonElement, Props>(
	({ children, disabled, onClick, type = 'button', ...attrs }, ref) => (
		<button
			ref={ref}
			// Lint rule doesn't understand that the type only allows valid values.
			// eslint-disable-next-line react/button-has-type
			type={type}
			onClick={disabled ? undefined : onClick}
			aria-disabled={disabled ? 'true' : undefined}
			{...attrs}
		>
			{children}
		</button>
	),
);
PlainButton.displayName = 'PlainButton';

export default PlainButton;
