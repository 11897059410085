/**
 * Skeleton
 */

import React, { type CSSProperties } from 'react';

import type { UnionWithString } from 'types';
import { cn } from 'utils/classNames';
import { useI18n } from 'utils/i18n';
import { ENTITIES } from 'utils/string';

interface Props {
	/** Children (probably SkeletonItem) */
	children: React.ReactNode;

	/** Additional class names */
	className?: string;
}

/** A loading skeleton. */
export function Skeleton({ children, className }: Props) {
	const { t } = useI18n();

	return (
		<div
			role="progressbar"
			aria-busy="true"
			aria-label={t('general_loading_text')}
			className={className}
		>
			{children}
		</div>
	);
}
Skeleton.displayName = 'Skeleton';

interface ItemProps {
	/** Additional class names */
	className?: string;

	/** Height of skeleton item, CSS value or 'text' to make it follow text size */
	height?: UnionWithString<'text'>;

	/** Width of skeleton item, CSS value */
	width?: string;
}

export function SkeletonItem({ className, height, width }: ItemProps) {
	const style: CSSProperties = {};
	if (height && height !== 'text') {
		style.height = height;
	}
	if (width) {
		style.width = width;
	}

	const content = height === 'text' ? ENTITIES.noBreakSpace : null;

	return (
		<div
			className={cn(
				'block animate-shimmer rounded-border',
				'bg-greyLight bg-[size:200px_100%] bg-no-repeat text-transparent',
				'bg-gradient-to-r from-greyLight via-greyLighter to-greyLight',
				// Non-url background images like gradients are disabled
				// in forced colors mode.
				'forced-colors:animate-pulse forced-colors:bg-[CanvasText] forced-colors:opacity-20',
				height !== 'text' && 'h-full leading-none',
				className,
			)}
			style={style}
		>
			{content}
		</div>
	);
}
SkeletonItem.displayName = 'SkeletonItem';
