import React, { useEffect, useMemo } from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';

import Chip from 'components/Chip';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import LoadMoreList from 'components/LoadMoreList';
import Meter from 'components/Meter';
import { MicroContentMedia } from 'components/MicroContent';
import SwipeWrapper from 'components/SwipeWrapper';
import Text from 'components/Text';
import { withRequiredProps } from 'hoc';
import { useIsEditing, usePagination } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { InspirationPage } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';
import { asArray, empty, getAsset, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

const OFFSET_QUERY_VAR = 'io-offset';
const PAGE_SIZE_QUERY_VAR = 'io-pageSize';
const TAG_QUERY_VAR = 'io-tag';

interface Tag {
	id: string;
	name: string;
}

interface Fields {
	hasNextPage: boolean;
	heading?: Field<string>;
	items: InspirationPage[];
	nextPageOffset: number;
	tags: Tag[];
	total: number;
}

type Props = JulaComponentProps & {
	fields: Fields;
};

function InspirationOverview({ fields: initialFields, params }: Props) {
	const { t, tPlural } = useI18n();
	const isEditing = useIsEditing();
	const router = useRouter();

	const urlTags = asArray(router.query[TAG_QUERY_VAR]).filter(is.truthy);

	const {
		component: inspirationFields,
		isLoading: isLoadingMoreItems,
		items: rawItems,
		loadMore: loadMoreItems,
		queryVarItems,
		updateQueryVars,
	} = usePagination<InspirationPage, Fields>({
		initialComponent: initialFields,
		initialItems: initialFields.items,
		initialNextPageOffset: initialFields.nextPageOffset,
		initialQueryVars: urlTags.map((tag) => [TAG_QUERY_VAR, tag]),
		itemsKey: 'items',
		offsetQueryVarName: OFFSET_QUERY_VAR,
		pageSizeQueryVarName: PAGE_SIZE_QUERY_VAR,
		placeholderComponentName: 'InspirationOverview',
	});

	const tags = inspirationFields?.tags ?? empty.array;
	const activeTagIds = queryVarItems
		.filter(([key]) => key === TAG_QUERY_VAR)
		.map(([, val]) => val);
	const items = filterTruthy(
		rawItems.map((item) => ({
			href: item.url,
			imgSrc: getAsset('Templated 2:1', item.fields?.landscapeImage)?.src,
			key: item.id,
			text: item.fields?.navigationTitle?.value,
		})),
		'href',
		'imgSrc',
		'text',
	);

	const itemsWithMissingData = useMemo(
		() =>
			rawItems
				.filter(
					(item) =>
						!item.url ||
						!item.fields?.navigationTitle?.value ||
						!getAsset('Templated 2:1', item.fields?.landscapeImage)?.src,
				)
				.map((item) => ({
					id: item.id,
					displayName: item.displayName,
				})),
		[rawItems],
	);

	useEffect(() => {
		if (is.arrayWithLength(itemsWithMissingData)) {
			console.warn(
				'The total number of items is greater than the number of visible items',
				itemsWithMissingData,
			);
		}
	}, [itemsWithMissingData]);

	// Remove the missing items from the total for the last page, when we know the total missing
	// to make the meter more accurate.
	const totalItemsCount = inspirationFields?.hasNextPage
		? inspirationFields?.total
		: (inspirationFields?.total ?? 0) - itemsWithMissingData.length;
	const visibleItemsCount = items.length;

	if (isEditing && visibleItemsCount === 0 && !initialFields.heading?.value) {
		return (
			<ComponentPlaceholder
				componentName="InspirationOverview"
				className={getEditorMargin(params)}
			/>
		);
	}

	const toggleTag = (tagId: string) => {
		updateQueryVars((vars) => {
			if (vars.has(TAG_QUERY_VAR, tagId)) {
				vars.delete(TAG_QUERY_VAR, tagId);
			} else {
				vars.add([TAG_QUERY_VAR, tagId]);
			}
		});
	};

	const clearAllTags = () => {
		updateQueryVars((vars) => {
			vars.delete(TAG_QUERY_VAR);
		});
	};

	const isTagActive = (tagId: string) => {
		if (!tagId && activeTagIds.length === 0) {
			return true;
		}
		return activeTagIds.includes(tagId);
	};

	const filterTags: Tag[] = [
		{ id: '', name: t('inspiration_filter_all_chip_text') },
		...tags,
	];

	return (
		<LayoutContainer id={params?.anchor} className={getEditorMargin(params)}>
			<Text as={params?.heading ?? 'h2'} field={inspirationFields?.heading} />

			<SwipeWrapper
				activeClassName="sm:hidden mb-4 mt-2"
				inactiveClassName="max-sm:hidden mb-6 mt-4 flex flex-wrap gap-4"
				pullGutters
			>
				{filterTags.map((tag) => (
					<li key={`${tag.name}-${tag.id}`}>
						<Chip
							color={isTagActive(tag.id) ? 'red' : 'white'}
							aria-pressed={isTagActive(tag.id)}
							text={tag.name}
							onClick={() => {
								if (tag.id) {
									toggleTag(tag.id);
								} else {
									clearAllTags();
								}
							}}
						/>
					</li>
				))}
			</SwipeWrapper>

			<LoadMoreList
				isLoading={isLoadingMoreItems}
				onLoadMoreClick={loadMoreItems}
				hasLoadMoreButton={inspirationFields?.hasNextPage}
				buttonAlignment="center"
				buttonClassName="mt-4 max-sm:w-full sm:min-w-72"
				itemCountScreenReaderText={tPlural(
					'inspiration_item_count_text',
					visibleItemsCount,
				)}
				buttonText={t('load_more_inspiration_items_button')}
				listTag="ul"
				listClassName="grid items-start gap-4 md:gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-4"
				afterListContent={
					<Meter
						alignment="center"
						className="mt-14"
						current={visibleItemsCount}
						max={totalItemsCount}
						labelHasProgress
						label={t('inspiration_overview_page_indicator_text', {
							numShown: visibleItemsCount,
							numTotal: totalItemsCount,
						})}
					/>
				}
			>
				{items.map((item) => (
					<li key={item.key} className="col-span-1">
						<MicroContentMedia
							href={item.href}
							imgSrc={item.imgSrc}
							imgWidth={393}
							imgHeight={197}
							text={item.text}
						/>
					</li>
				))}
			</LoadMoreList>
		</LayoutContainer>
	);
}
InspirationOverview.displayName = 'InspirationOverview';

export default withRequiredProps(InspirationOverview, 'fields');
