/**
 * Hr
 */

import React from 'react';

import { cn } from 'utils/classNames';

interface Props {
	className?: string;
}

/** Styled Hr. */
export default function Hr({ className }: Props) {
	return <hr className={cn('h-px w-full border-grey', className)} />;
}
Hr.displayName = 'Hr';
