/**
 * ErrorBoundary
 */

import React, { Component, type ErrorInfo, type ReactNode } from 'react';

import { cnm } from 'utils/classNames';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	/** Components to catch errors for. */
	children: ReactNode;

	/** Class name for the default fallback component. */
	className?: string;

	/** Custom fallback component, or function that renders a component. */
	fallback?: ReactNode | ((error: unknown) => ReactNode);

	/** If the default fallback should fill out the standard page width. */
	isPageWidth?: boolean;

	/** Callback that runs when an error is caught. */
	onError?: (error: Error, info: ErrorInfo) => void;
}

interface HandlerProps extends Props {
	defaultErrorText: string;
}
type State =
	| {
			error: unknown;
			hasError: true;
	  }
	| {
			error: null;
			hasError: false;
	  };

class ErrorBoundaryHandler extends Component<HandlerProps, State> {
	static displayName = 'ErrorBoundaryHandler';

	state: State = {
		error: null,
		hasError: false,
	};

	// `error` should most often be an Error instance, but since JS allows
	// throwing any value it can't be guaranteed.
	static getDerivedStateFromError(error: unknown): State {
		return { error, hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.props.onError?.(error, errorInfo);
	}

	render() {
		const { children, className, defaultErrorText, fallback, isPageWidth } =
			this.props;
		const { error, hasError } = this.state;

		if (hasError) {
			if (is.func(fallback)) {
				return fallback(error);
			}
			if (fallback !== undefined) {
				return fallback;
			}
			return (
				<span
					className={cnm(
						'self-start bg-informationLighter px-3 py-2 text-sm text-greyDarker',
						!isPageWidth && 'inline-block',
						isPageWidth && 'mx-auto my-2 block max-w-pageStandard',
						className,
					)}
				>
					{defaultErrorText}
				</span>
			);
		}

		return children;
	}
}

export default function ErrorBoundary(props: Props) {
	const { t } = useI18n();

	return (
		<ErrorBoundaryHandler
			{...props}
			defaultErrorText={t('rendering_error_text')}
		/>
	);
}
ErrorBoundary.displayName = 'ErrorBoundary_Internal';
