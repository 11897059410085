/**
 * Review
 */

import React, { useState } from 'react';

import Button from 'components/Button';
import Count from 'components/Count';
import Icon, { type IconName } from 'components/Icon';
import Img from 'components/Img';
import PlainButton from 'components/PlainButton';
import Rating from 'components/Rating';
import Text from 'components/Text';
import { publicRuntimeConfig } from 'config';
import { getReviewImageId, type ReviewImageId } from 'hooks/product-details';
import type { Review as ReviewModel } from 'models/api';
import { cn } from 'utils/classNames';
import { formatElapsedTime, formatLanguage, formatRegion } from 'utils/format';
import { ignorePromiseRejection, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';
import { ENTITIES, upperFirst } from 'utils/string';

const MARKET_LANG = (
	publicRuntimeConfig?.NEXT_PUBLIC_JULA_MARKET_LANGUAGE || 'sv'
).toLowerCase();

type FeedbackType = 'up' | 'down' | 'bad';

async function sendFeedback(url: string, type: FeedbackType): Promise<boolean> {
	try {
		await fetch(`${url}?type=${type}`);
		return true;
	} catch {
		// Continue to default return
	}

	return false;
}

interface BaseProps {
	className?: string;
	marketLang?: string;
	review: ReviewModel;
}
interface PropsWithImages extends BaseProps {
	onReviewImageClick: (id: ReviewImageId) => void;
	showImages?: true;
}
interface PropsWithoutImages extends BaseProps {
	onReviewImageClick?: never;
	showImages: false;
}
type Props = PropsWithImages | PropsWithoutImages;

/** A single product review. */
export default function Review({
	className,
	marketLang = MARKET_LANG,
	onReviewImageClick,
	review,
	showImages = true,
}: Props) {
	const {
		author,
		cons,
		date,
		extract,
		feedbackUrl,
		images,
		lang,
		market,
		original,
		pros,
		questions,
		recommended,
		responses,
		score,
		votesUp,
	} = review;
	const { t } = useI18n();
	const [showOriginal, setShowOriginal] = useState(false);
	const [isUpvoting, setIsUpvoting] = useState(false);
	const [isReporting, setIsReporting] = useState(false);
	const [hasLeftFeedback, setHasLeftFeedback] = useState(false);

	const handleTranslationToggleClick = () => {
		setShowOriginal((value) => !value);
	};

	const feedbackAction = async (
		activeState: boolean,
		activeStateSetter: (newState: boolean) => void,
		feedback: FeedbackType,
	) => {
		if (activeState) {
			return;
		}
		activeStateSetter(true);
		const wasSent = await sendFeedback(feedbackUrl, feedback);
		activeStateSetter(false);
		if (wasSent) {
			setHasLeftFeedback(true);
		}
	};

	const handleUpvoteClick = () => {
		ignorePromiseRejection(feedbackAction(isUpvoting, setIsUpvoting, 'up'));
	};

	const handleReportClick = () => {
		ignorePromiseRejection(feedbackAction(isReporting, setIsReporting, 'bad'));
	};

	const isLeavingFeedback = isUpvoting || isReporting;
	const hasOriginalLang =
		original?.lang && (original.extract || original.pros || original.cons);
	let mainText = extract;
	let prosText = pros;
	let consText = cons;
	if (showOriginal && hasOriginalLang) {
		mainText = original.extract;
		prosText = original.pros;
		consText = original.cons;
	}

	const starCountText = t('product_review_stars_screen_reader_text', {
		starCount: score,
	});

	return (
		<article className={className} aria-label={`${author}, ${starCountText}`}>
			<div className="flex items-center gap-x-2">
				{market && (
					<Icon
						name={`flag${upperFirst(market.toLowerCase())}` as IconName}
						role="img"
						aria-hidden={false}
						aria-label={t(`review_flag_alt_text`, {
							country: formatRegion(market),
						})}
					/>
				)}
				<Text as="p" styleAs="h6">
					{author}
				</Text>
			</div>
			<Text as="pSmall" className="mt-4">
				{`${upperFirst(formatElapsedTime(date))}. `}
				{hasOriginalLang && (
					<>
						{!showOriginal &&
							t('review_translated_from_text', {
								language: formatLanguage(original.lang),
							})}{' '}
						<Button
							onClick={handleTranslationToggleClick}
							variant="text"
							size="xSmall"
						>
							{showOriginal
								? t('review_show_translation_button', {
										language: formatLanguage(lang || marketLang),
									})
								: t('review_show_original_language_button')}
						</Button>
					</>
				)}
			</Text>

			<Rating
				score={score}
				className="my-4"
				reviewCountVariant="hidden"
				displayMode="block"
			/>

			{mainText && <p className="mb-4">{mainText}</p>}

			{(prosText || consText) && (
				<div className="my-6 flex flex-col gap-2">
					{prosText && (
						<div className="flex">
							<Icon
								name="plus"
								role="img"
								aria-label="+"
								aria-hidden={false}
								className="-mt-[0.125em] mr-2 shrink-0"
							/>
							<Text as="p">{prosText}</Text>
						</div>
					)}
					{consText && (
						<div className="flex">
							<Icon
								name="minus"
								role="img"
								aria-label={ENTITIES.minus}
								aria-hidden={false}
								className="-mt-[0.125em] mr-2 shrink-0"
							/>
							<Text as="p">{consText}</Text>
						</div>
					)}
				</div>
			)}

			{showImages && onReviewImageClick && is.arrayWithLength(images) && (
				<div className="my-4 flex flex-wrap gap-4">
					{images.map((image) => (
						<PlainButton
							key={image.icon}
							onClick={() => {
								onReviewImageClick(getReviewImageId(review, image));
							}}
							aria-label={t('review_image_button')}
							aria-haspopup="dialog"
						>
							<Img
								key={image.icon}
								src={image.icon}
								className="size-16"
								width={image.iconWidth}
								height={image.iconHeight}
							/>
						</PlainButton>
					))}
				</div>
			)}

			{is.arrayWithLength(questions) && (
				<div className="mt-4 space-y-1">
					{questions.map((question) => (
						<p key={question.name}>
							{t(`review_questions_${question.name}_heading`)}
							{': '}
							{question.options
								.filter((option) => option.selected)
								.map((option, index, array) => (
									<React.Fragment key={option.value}>
										{t(
											`review_questions_${question.name}_${option.value}_text`,
										)}
										{index < array.length - 1 ? ', ' : '.'}
									</React.Fragment>
								))}
						</p>
					))}
				</div>
			)}

			{is.defined(recommended) && (
				<Text className="mt-1" as="p">
					{t(
						recommended
							? 'product_details_review_recommendation_recommended_text'
							: 'product_details_review_recommendation_not_recommended_text',
						{
							name: author,
						},
					)}
				</Text>
			)}

			{is.arrayWithLength(responses) && (
				<div className="mt-6 rounded-lg bg-greyLighter p-4 forced-colors:border">
					<div className="flex flex-col gap-2">
						{responses.map((response) => (
							<React.Fragment key={`${response.date}-${response.reply}`}>
								<div className="flex items-center">
									<Icon
										name="julaJ"
										size={16}
										color="white"
										backgroundColor="julaRed"
										hasBackgroundPadding
										className="mr-2"
									/>
									<Text as="p" styleAs="h6" text={response.name} />
								</div>
								<Text as="pSmall" className="ml-8">
									{response.reply}
								</Text>
							</React.Fragment>
						))}
					</div>
				</div>
			)}

			<div className="mt-6 flex min-h-[2rem] items-center">
				{hasLeftFeedback && (
					<span className="text-sm">
						{t('product_details_review_feedback_done_text')}
					</span>
				)}
				{!hasLeftFeedback && (
					<>
						<Icon
							name="thumbDown"
							size={16}
							className={cn('rotate-180', isUpvoting && 'hidden')}
						/>
						<Icon
							name="spinner"
							size={16}
							className={cn('animate-spinFast', !isUpvoting && 'hidden')}
						/>
						<Count
							amount={votesUp}
							size="regular"
							margin="none"
							color="inherit"
							className="ml-1.5 font-bold"
						/>
						<Button
							variant="text"
							size="medium"
							className="ml-2"
							hasExpandedHitArea
							onClick={handleUpvoteClick}
							disabled={isLeavingFeedback}
						>
							{t('product_details_review_upvote_button')}
						</Button>

						<Button
							variant="text"
							size="medium"
							hasExpandedHitArea
							className="ml-6"
							onClick={handleReportClick}
							disabled={isLeavingFeedback}
						>
							<span>{t('product_details_review_report_button')}</span>
							<Icon
								name="spinner"
								size={16}
								className={cn(
									'relative top-px ml-1 animate-spinFast',
									!isReporting && 'hidden',
								)}
							/>
						</Button>
					</>
				)}
			</div>
		</article>
	);
}
Review.displayName = 'Review';
