import {
	type FocusEventHandler,
	type KeyboardEventHandler,
	useCallback,
	useRef,
} from 'react';

import { HTMLAttributesWithRef } from 'types';
import { getFocusedElement, selectTabbable } from 'utils/dom';
import { afterNextPaint, is } from 'utils/helpers';

import { useEffectOnce } from './useEffectOnce';
import { useRouteChange } from './useRouteChange';
import { useUnmount } from './useUnmount';
import { useValueChangeEffect } from './useValueChangeEffect';

interface BaseOptions<T> {
	/** The dialog ID. */
	id?: string;

	/** If the dialog is open. */
	isOpen: boolean;

	/** Closing callback, where `isOpen` must be set to false. */
	onClose: () => void;

	/** Keydown handler. Dialog already handles Escape for closing. */
	onKeyDown?: KeyboardEventHandler<T>;

	/** Opening callback. */
	onOpen?: () => void;

	/** Dialog role. */
	role?: 'dialog' | 'alertdialog';

	/** If the dialog should close when the escape key is pressed. */
	shouldCloseOnEscape?: boolean;

	/** If the dialog should close when the URL is updated. */
	shouldCloseOnNavigation?: boolean;
}

interface OptionsWithLabel<T> extends BaseOptions<T> {
	'aria-label': string;
	'aria-labelledby'?: never;
}
interface OptionsWithLabelledBy<T> extends BaseOptions<T> {
	'aria-label'?: never;
	'aria-labelledby': string;
}

type Options<T> = OptionsWithLabel<T> | OptionsWithLabelledBy<T>;

/**
 * Get the attributes and behavior needed for an accessible modal dialog.
 *
 * The explicit focus traps are technically optional but highly recommended.
 * Without them focus can leave the dialog for a few frames before it's captured
 * back, causing scrolling in the background. They can also handle cases that
 * are otherwise broken like having an iframe as the last focusable element
 * (e.g. an embedded YouTube video) - the blur event in this case is triggered
 * when entering the iframe and focus moves from that directly to outside of
 * the dialog. With the trap there is a guaranteed stop before that can happen.
 *
 * @example
 *
 * function UserAddress() {
 *   const [isOpen, setIsOpen] = useState(false);
 *   const open = () => setIsOpen(true);
 *   const close = () => setIsOpen(false);
 *   const { dialogProps, focusTrapEndProps, focusTrapStartProps } = useDialog({
 *     'aria-label': 'Edit address',
 *     isOpen,
 *     onClose: close,
 *   });
 *
 *   return (
 *     <>
 *       <button onClick={open}>Edit my address</button>
 *       <div {...focusTrapStartProps} />
 *       <div {...dialogProps} className={!isOpen && 'hidden'}>
 *         <button onClick={close}>Abort editing</button>
 *         <form>Address form</form>
 *       </div>
 *       <div {...focusTrapEndProps} />
 *     <>
 *   );
 * }
 */
export function useDialog<
	DialogT extends HTMLElement = HTMLDivElement,
	FocusTrapT extends HTMLElement = HTMLDivElement,
>({
	'aria-label': ariaLabel,
	'aria-labelledby': ariaLabelledby,
	id,
	isOpen,
	'onClose': onCloseProp,
	onKeyDown,
	'onOpen': onOpenProp,
	role = 'dialog',
	shouldCloseOnEscape = true,
	shouldCloseOnNavigation = true,
}: Options<DialogT>) {
	const dialogRef = useRef<DialogT>(null);

	const focusTrapStartRef = useRef<FocusTrapT>(null);
	const focusTrapEndRef = useRef<FocusTrapT>(null);

	// aria-modal is included in the returned dialogProps so a dialog root
	// will always be selectable through that attribute.
	const dialogSelector = '[aria-modal="true"]';
	// Attribute to make node selection based on open status possible.
	const openDataAttr = 'data-dialog-open';
	const openerElementKey = id || 'latestDialogWithoutId';
	// Root in this case means outside of a dialog.
	const rootOpenerElementKey = 'rootDialog';

	const isFocusTrap = useCallback(
		(el: HTMLElement) => el.dataset.focusTrap !== undefined,
		[],
	);

	// Get tabbable elements within the dialog, excluding focus traps.
	const getDialogTabbable = useCallback(
		() =>
			dialogRef.current
				? selectTabbable(dialogRef.current, { checkSize: true }).filter(
						(el) => !isFocusTrap(el),
					)
				: [],
		[isFocusTrap],
	);

	// Save trigger element and move focus when dialog is opened.
	const onOpen = useCallback(() => {
		globalThis.dialogOpenerElements = globalThis.dialogOpenerElements ?? {};
		globalThis.dialogOpenerElements[openerElementKey] = [];

		onOpenProp?.();

		// Opener elements must be saved globally since multiple dialogs can
		// interact and be opened from very different places, making refs unfeasible.
		// Elements are saved for each dialog, so two dialogs where A opens B could
		// have something like: { A: [buttonOnPage], B: [buttonInA, dialogA] }.
		const focused = getFocusedElement();
		if (is.instance(focused, HTMLElement)) {
			globalThis.dialogOpenerElements[openerElementKey]?.push(focused);
			// If opening from within a dialog, also save that dialog's root as a
			// fallback in case the opener element gets removed while the nested
			// dialog is open (e.g. dialog A has button to open dialog B and that
			// button is replaced with a spinner while dialog B finishes). Otherwise
			// focus will be lost to the body when the nested dialog is closed even
			// if the first one is still open.
			// If NOT opening from within a dialog, save the element with a root
			// key used for fallback cases.
			const modalParent = focused.closest(dialogSelector);
			if (is.instance(modalParent, HTMLElement)) {
				globalThis.dialogOpenerElements[openerElementKey]?.push(modalParent);
			} else {
				globalThis.dialogOpenerElements[rootOpenerElementKey] = [focused];
			}
		}

		// Wait for paint twice — first for React to finish its stuff and then
		// for a potential opening transition to start. Assuming the dialog is
		// hidden with display/visibility and that said property is changed as
		// soon as the opening starts. Trying to set focus while it's still
		// hidden won't work.
		afterNextPaint(() => {
			if (dialogRef.current) {
				// Focus the first focusable element if available.
				(getDialogTabbable()[0] ?? dialogRef.current).focus();
			}
		}, 2);
	}, [getDialogTabbable, onOpenProp, openerElementKey]);

	// Restore focus to the trigger when the dialog is closed.
	const onClose = useCallback(() => {
		onCloseProp?.();
		// Waiting to make the blur handler get the updated `isOpen` state before
		// a blur is triggered due to focus moving to the outside trigger.
		// Otherwise it will react and keep the focus inside the now closed dialog.
		setTimeout(() => {
			// First try to select an opener saved for the current dialog.
			const didFocus = globalThis.dialogOpenerElements?.[
				openerElementKey
			]?.some((el) => {
				// Connected to the DOM (i.e. not a saved node that has now been
				// removed in a new render) and not inside a closed dialog (can still
				// exist in the DOM).
				if (el.isConnected && !el.closest(`[${openDataAttr}="false"]`)) {
					el.focus();
					return true;
				}
				return false;
			});
			const openDialogs = document.querySelectorAll(`[${openDataAttr}="true"]`);
			const hasOpen = openDialogs.length > 0;
			// Fall back to other candidates.
			if (!didFocus) {
				// If there are still open dialogs, focus the last one found - in case
				// there are multiple ones open, any nested ones should appear later
				// in the DOM.
				if (hasOpen) {
					const dialog = openDialogs[openDialogs.length - 1] as
						| HTMLElement
						| undefined;
					dialog?.focus();
				} else {
					globalThis.dialogOpenerElements?.[rootOpenerElementKey]?.[0]?.focus();
				}
			}
			// Empty saved nodes.
			if (!hasOpen) {
				globalThis.dialogOpenerElements = {};
			}
		}, 10);
	}, [onCloseProp, openerElementKey]);

	// Restore focus to the dialog if it leaves it.
	const handleBlur: FocusEventHandler<DialogT> = (e) => {
		// If seems focus can't be set to the same element that was just blurred,
		// for cases where the dialog only has a single tabbable element. A hacky
		// timeout appears to do the trick.
		// Would be nice to just preventDefault on the blur instead, but focus
		// and blur events are not cancelable.
		// `relatedTarget` on a blur event is the element receiving focus, if any.
		setTimeout(() => {
			if (
				isOpen &&
				dialogRef.current &&
				is.instance(e.relatedTarget, HTMLElement) &&
				// The focus traps will handle focus moving to them, ignore here.
				!isFocusTrap(e.relatedTarget) &&
				!dialogRef.current.contains(e.relatedTarget) &&
				// Don't capture focus if it was moved into another dialog.
				!e.relatedTarget.closest(dialogSelector)
			) {
				const tabbables = getDialogTabbable();
				// No tabbable elements in the dialog, just keep focus on the root.
				if (tabbables.length === 0) {
					dialogRef.current.focus();
					return;
				}
				const blurredIndex = tabbables.indexOf(e.target);
				// Losing focus from the dialog root should mean the user shift + tabbed
				// backwards, since going forwards should move focus to an element
				// inside the dialog. Same if losing focus from the first tabbable
				// element. Loop around to the last element in those cases.
				// Otherwise if focus is lost from the last tabbable or from a
				// an element not detected as tabbable (e.g. a scroll container with
				// large enough content), focus the first tabbable.
				if (e.target === dialogRef.current || blurredIndex === 0) {
					tabbables[tabbables.length - 1]?.focus();
				} else if (
					blurredIndex === tabbables.length - 1 ||
					blurredIndex === -1
				) {
					tabbables[0]?.focus();
				}
			}
		}, 0);
	};

	const handleKeyDown: KeyboardEventHandler<DialogT> = (e) => {
		// Stop all bubbling to parent dialogs.
		e.stopPropagation();
		if (e.key === 'Escape' && shouldCloseOnEscape) {
			onClose();
		}
		if (onKeyDown) {
			onKeyDown(e);
		}
	};

	// Close any open dialog on navigation.
	const onRouteChange = useCallback(() => {
		if (isOpen && shouldCloseOnNavigation) {
			onClose();
		}
	}, [isOpen, onClose, shouldCloseOnNavigation]);
	useRouteChange('routeChangeStart', onRouteChange);

	// Trigger opening/closing logic when the state is changed for a dialog that
	// exists in the DOM.
	useValueChangeEffect(isOpen, (prevIsOpen) => {
		if (!prevIsOpen && isOpen) {
			onOpen();
		}
		if (prevIsOpen && !isOpen) {
			onClose();
		}
	});

	// Trigger opening/closing logic for a dialog that is added and removed
	// from the DOM.
	useEffectOnce(() => {
		if (isOpen) {
			onOpen();
		}
	});
	useUnmount(() => {
		if (isOpen) {
			onClose();
		}
	});

	const dialogProps: HTMLAttributesWithRef<DialogT> = {
		'aria-label': ariaLabel,
		'aria-labelledby': ariaLabelledby,
		'aria-modal': 'true',
		[openDataAttr]: String(isOpen),
		id,
		'onBlur': handleBlur,
		'onKeyDown': handleKeyDown,
		'ref': dialogRef,
		role,
		'tabIndex': -1,
	} as const;

	const focusTrapBaseProps: HTMLAttributesWithRef<FocusTrapT> = {
		'className': 'outline-0',
		'data-focus-trap': '',
		'tabIndex': isOpen ? 0 : -1,
		'onFocus': (e) => {
			const tabbables = getDialogTabbable();
			// If arriving on the start trap, move focus to the last element and
			// vice versa.
			const newTargetIndex =
				e.target === focusTrapStartRef.current ? tabbables.length - 1 : 0;
			// Fall back to the dialog root if there are no tabbables available.
			(tabbables[newTargetIndex] ?? dialogRef.current)?.focus();
		},
	} as const;
	const focusTrapStartProps: HTMLAttributesWithRef<FocusTrapT> = {
		...focusTrapBaseProps,
		ref: focusTrapStartRef,
	} as const;
	const focusTrapEndProps: HTMLAttributesWithRef<FocusTrapT> = {
		...focusTrapBaseProps,
		ref: focusTrapEndRef,
	} as const;

	return {
		dialogProps,
		dialogRef,
		focusTrapEndProps,
		focusTrapStartProps,
	};
}
