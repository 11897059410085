// GENERATED, DON'T EDIT MANUALLY, SEE icons SCRIPT

import React from 'react';
import type { SVGAttributes } from 'react';

export default function IconFlagFi(props: SVGAttributes<SVGElement>) {
	return (
		<svg {...props} width="24" height="24" viewBox="0 0 24 24">
			<circle cx="12" cy="12" r="12" fill="#fff" />
			<path
				d="M10.5 9.16h13.16a12 12 0 0 1-.1 6.04H10.5v8.7a11.94 11.94 0 0 1-5.97-2.51V15.2H.43a12 12 0 0 1-.1-6.04h4.2V2.61A11.94 11.94 0 0 1 10.5.1z"
				fill="#002b6c"
			/>
		</svg>
	);
}
IconFlagFi.displayName = 'IconFlagFi';
