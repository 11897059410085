import React, { useState } from 'react';

import Accordion from 'components/Accordion';
import Button from 'components/Button';
import LoadMoreList from 'components/LoadMoreList';
import { PartialPopover } from 'components/Popover';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import { useGlobalLinks } from 'hooks';
import { QuestionsAndAnswersFormPartialProps } from 'jsscomponents/QuestionsAndAnswersForm';
import { Question } from 'models/api';
import { range } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import ProductQuestion from './ProductQuestion';

interface Props {
	hasNextPage: boolean;
	isLoading: boolean;
	isLoadingMore: boolean;
	isOpen: boolean;
	newQuestionsAllowed: boolean;
	onClick: () => void;
	onLoadMoreClick: () => void;
	productId: string;
	productName: string;
	questions: Question[] | undefined;
}

export default function ProductQuestions({
	hasNextPage,
	isLoading,
	isLoadingMore,
	isOpen,
	newQuestionsAllowed,
	onClick,
	onLoadMoreClick,
	productId,
	productName,
	questions,
}: Props) {
	const { t, tPlural } = useI18n();
	const { questionsAndAnswers } = useGlobalLinks();
	const [isFormOpen, setIsFormOpen] = useState(false);

	return (
		<Accordion
			isOpen={isOpen}
			onClick={onClick}
			title={t('product_questions_title')}
			id="product-questions"
		>
			<p>{t('product_questions_intro_text', { productName })}</p>
			{newQuestionsAllowed && (
				<Button
					className="mt-8"
					onClick={() => setIsFormOpen((current) => !current)}
					aria-haspopup="dialog"
					variant="primary"
				>
					{t('product_questions_ask_question_button')}
				</Button>
			)}

			{isLoading && (
				<div className="mt-8 divide-y divide-greyDark">
					{range(2).map((index) => (
						<Skeleton key={index} className="py-8">
							{/* Name and date */}
							<SkeletonItem height="1rem" width="5rem" />
							<SkeletonItem height="1rem" width="13rem" className="mt-1" />
							{/* Question */}
							<SkeletonItem height="1.5rem" width="90%" className="my-4" />
							{/* Answer box */}
							<SkeletonItem height="5rem" width="100%" className="mt-6" />
							{/* Like/report buttons */}
							<SkeletonItem height="2rem" width="12rem" className="mt-6" />
						</Skeleton>
					))}
				</div>
			)}
			{!isLoading && (
				<LoadMoreList
					onLoadMoreClick={onLoadMoreClick}
					isLoading={isLoadingMore}
					buttonVariant="secondary"
					buttonText={t('load_more_product_questions_button')}
					itemCountScreenReaderText={tPlural(
						'product_questions_item_count_text',
						questions?.length ?? 0,
					)}
					className="my-4"
					listClassName="divide-y divide-greyDark"
					buttonClassName="min-w-[50%] mb-6 mt-10 mx-auto flex"
					hasLoadMoreButton={hasNextPage}
				>
					{questions?.map((question) => (
						<ProductQuestion
							key={`${question.author}-${question.date}`}
							author={question.author}
							date={question.date}
							extract={question.extract}
							feedbackUrl={question.feedbackUrl}
							responses={question.responses}
							votesUp={question.votesUp}
						/>
					))}
				</LoadMoreList>
			)}
			{questionsAndAnswers && (
				<PartialPopover<QuestionsAndAnswersFormPartialProps>
					heading={t('question_answer_form_heading')}
					isOpen={isFormOpen}
					item={questionsAndAnswers}
					onClose={() => setIsFormOpen(false)}
					partialProps={{ productId }}
				/>
			)}
		</Accordion>
	);
}
ProductQuestions.displayName = 'ProductQuestions';
