import React, { InputHTMLAttributes, useRef } from 'react';

import type { IconName } from 'components/Icon';
import Icon from 'components/Icon';
import PlainButton from 'components/PlainButton';
import { cn } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

import FloatingLabel from './FloatingLabel';
import { getInputClassName, isLabelFloating } from './helpers';
import InlineLabel from './InlineLabel';
import InputInfo from './InputInfo';

// React has HTMLInputTypeAttribute but several of those (like 'button' and
// 'checkbox') are not relevant here since this is specifically a text field.
export type InputType =
	| 'date'
	| 'datetime-local'
	| 'email'
	| 'month'
	| 'number'
	| 'password'
	| 'search'
	| 'tel'
	| 'text'
	| 'time'
	| 'url'
	| 'week';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
	type?: InputType;
	errorMessage?: string | string[];
	helpText?: string;
	hiddenLabel?: boolean;
	icon?: IconName;
	id: string;
	inputClassName?: string;
	invalid?: boolean;
	label: string;
	labelClassName?: string;
	labelType?: 'floating' | 'inline';
	onClearClick?: () => void;
	valid?: boolean;
}

export default function Input({
	className,
	defaultValue,
	disabled,
	errorMessage,
	helpText,
	hiddenLabel = false,
	icon,
	id,
	inputClassName,
	inputMode,
	invalid = false,
	label,
	labelClassName,
	labelType = 'floating',
	onClearClick,
	required,
	type = 'text',
	valid = false,
	value,
	...rest
}: Props) {
	const { t } = useI18n();
	const inputRef = useRef<HTMLInputElement>(null);

	const errorMessageId = `${id}-error`;
	const helpTextId = `${id}-help`;

	let inputType = type;
	let mode = inputMode;
	let pattern: string | undefined;
	if (type === 'number') {
		inputType = 'text';
		pattern = String.raw`\d*`;
		mode = 'numeric';
	}

	// Stringify to make zero truthy.
	const hasClearButton = Boolean(onClearClick && value?.toString());

	return (
		<div className={className}>
			{labelType === 'inline' && (
				<InlineLabel
					className={cn('pb-1', labelClassName)}
					htmlFor={id}
					invalid={invalid}
					isValid={valid}
					isHidden={hiddenLabel}
					required={required}
				>
					{label}
				</InlineLabel>
			)}
			<div className={cn('relative', disabled && 'opacity-50')}>
				<input
					{...rest}
					ref={inputRef}
					className={getInputClassName({
						disabled,
						extra: cn(
							'h-14 py-0',
							// Match right padding with clear button width further down.
							(icon || hasClearButton) && 'pr-12',
							inputClassName,
						),
						invalid,
						valid,
					})}
					aria-describedby={[errorMessageId, helpTextId].join(' ')}
					// Support for aria-errormessage isn't good enough as of autumn 2024
					// so stick with aria-describedby for now.
					// aria-errormessage={errorMessageId}
					aria-invalid={invalid}
					defaultValue={defaultValue}
					disabled={disabled}
					id={id}
					inputMode={mode}
					pattern={pattern}
					required={required}
					type={inputType}
					value={value}
				/>
				{labelType === 'floating' && (
					<FloatingLabel
						className={labelClassName}
						htmlFor={id}
						invalid={invalid}
						isValid={valid}
						isFloating={isLabelFloating(value, defaultValue)}
						isHidden={hiddenLabel}
						required={required}
					>
						{label}
					</FloatingLabel>
				)}
				{icon && !hasClearButton && (
					<Icon
						name={icon}
						className="absolute right-3 top-1/2 -translate-y-1/2"
					/>
				)}
				{hasClearButton && (
					<PlainButton
						className={cn(
							// Match width with right padding on the input further up.
							'w-12',
							'absolute right-0 top-1/2 flex h-14 -translate-y-1/2 items-center justify-center border-none bg-none hover:opacity-80',
						)}
						onClick={() => {
							inputRef.current?.focus();
							onClearClick?.();
						}}
					>
						<span className="sr-only">{t('screenreader_text_clear')}</span>
						<Icon name="clear" color="grey" />
					</PlainButton>
				)}
			</div>
			<InputInfo
				errorMessage={errorMessage}
				errorMessageId={errorMessageId}
				helpText={helpText}
				helpTextId={helpTextId}
				invalid={invalid}
			/>
		</div>
	);
}
Input.displayName = 'FormUI_Input';
