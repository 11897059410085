import React from 'react';

import { cn } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

import ProgressStepsHorizontal from './ProgressStepsHorizontal';

function formatText(text: string) {
	// If the text matches <char><space><one or two chars><space> then replace
	// the first space with a non-breaking one.
	if (/\S \S{1,2} \S/.test(text)) {
		return text.replace(/ /, '\u00A0');
	}
	return text;
}

interface Props {
	className?: string;
	currentStep: 1 | 2 | 3;
}

export default function CheckoutProgressSteps({
	className,
	currentStep = 1,
}: Props) {
	const { t } = useI18n();

	// Some semi-hardcoded stuff here based on the current strings for these keys
	// as of 2025-03-10. Some languages like Swedish and Norwegian have a longer
	// middle text and shorter end texts - for these the middle texts base size
	// is increased to reduce the situations where it wraps. Polish has a longer
	// end text with a single character in the middle - for this a no-break space
	// is added to prevent it from falling on its own line.
	const text1 = formatText(t('checkout_stepper_first_step_label'));
	const text2 = formatText(t('checkout_stepper_second_step_label'));
	const text3 = formatText(t('checkout_stepper_third_step_label'));
	const middleStepClassName =
		text1.length < 12 && text2.length > 12 && text3.length < 12
			? 'basis-16'
			: undefined;

	return (
		<ProgressStepsHorizontal
			className={cn('mx-auto max-w-screen-sm', className)}
			steps={[
				{
					text: text1,
					completed: currentStep > 1,
				},
				{
					text: text2,
					completed: currentStep > 2,
					className: middleStepClassName,
				},
				{
					text: text3,
					completed: currentStep > 3,
				},
			]}
		/>
	);
}
CheckoutProgressSteps.displayName = 'CheckoutProgressSteps';
