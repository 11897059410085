/**
 * Toast
 */

import React from 'react';
import { useMachine, useSelector } from '@xstate/react';

import InfoBox from 'components/InfoBox';
import ScreenReaderAnnouncementText from 'components/ScreenReaderAnnouncementText';
import { toastMachine, type Variants } from 'state-machines/toast.machine';
import {
	selectIsMovingIn,
	selectIsMovingOut,
	type ToastItemMachineActor,
} from 'state-machines/toastItem.machine';
import { cn } from 'utils/classNames';
import { sendGlobalEvent } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

export const sendToast = (
	message: string,
	variant: Variants,
	link?: string,
	linkText?: string,
) => {
	sendGlobalEvent('toast', {
		message,
		variant,
		link,
		linkText,
	});
};

interface ToastItemProps {
	toastActor: ToastItemMachineActor;
}

function ToastItem({ toastActor }: ToastItemProps) {
	const { link, linkText, message, variant } =
		toastActor.getSnapshot()?.context ?? {};
	const isMovingIn = useSelector(toastActor, selectIsMovingIn);
	const isMovingOut = useSelector(toastActor, selectIsMovingOut);
	const key = `${message}-${variant}-${link}`;

	const { t } = useI18n();
	return (
		<InfoBox
			variant={variant}
			message={t(message ?? '')}
			icon="info"
			textSize="base"
			link={link}
			linkText={linkText}
			// Screen readers can likely not access the toast so the
			// link text is useless for them.
			linkIsScreenReaderHidden
			linkDataCy={`toast-link-${variant}`}
			key={key}
			type="toast"
			className={cn(
				isMovingIn && 'max-sm:animate-moveInTop sm:animate-moveInRight',
				isMovingOut && 'animate-fadeOut',
			)}
		/>
	);
}
ToastItem.displayName = 'ToastItem';

/** Handles toast messages. */
export default function Toast() {
	const [state] = useMachine(toastMachine, {
		devTools: true,
	});

	return (
		<ScreenReaderAnnouncementText
			as="div"
			atomic
			className={cn(
				'fixed left-0 right-0 top-0 z-toast space-y-4 sm:left-auto',
				state.context.toasts.length > 0 && 'sm:p-4',
			)}
			text={state.context.toasts.map((actor) => (
				<ToastItem key={actor.id} toastActor={actor} />
			))}
		/>
	);
}
Toast.displayName = 'Toast';
