/**
 * ProductCardColumn
 */

import React, { type MouseEventHandler } from 'react';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import Icon from 'components/Icon';
import Img from 'components/Img';
import Link from 'components/Link';
import OptionalContainer from 'components/OptionalContainer';
import Price, { BasePriceText } from 'components/Price';
import {
	PRODUCT_REVIEWS_ID,
	PRODUCT_TECHNICAL_ATTRIBUTES_ID,
} from 'components/ProductDetails/';
import ProductTag from 'components/ProductTag';
import Rating from 'components/Rating';
import Text from 'components/Text';
import type { Price as PriceModel } from 'models/price';
import type { ProductTag as ProductTagModel } from 'models/product';
import { cn } from 'utils/classNames';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import ProductCardStockInfo, { type StockInfo } from './ProductCardStockInfo';
import ProductCardTags from './ProductCardTags';

export interface Props {
	actionButtonState?: ActionButtonState;

	/** Base price if the main price has a discount */
	basePrice?: PriceModel;

	/** The list of items from checklist */
	checklist?: string[];

	/** Extra container class names */
	className?: string;

	/** Is product disabled? */
	disabled?: boolean;

	/** Energy Symbol */
	energySymbolAlt?: string;

	/** Energy Symbol */
	energySymbolSrc?: string;

	/** Product tag in the Flag section */
	flag?: ProductTagModel;

	/** Product has multiple variants? */
	hasMultipleVariants?: boolean;

	/** The heading in the component */
	heading?: string;

	/** The link to the product page */
	href: string;

	/** Image alt text */
	imageAlt?: string;

	/** Image src attribute */
	imageSrc: string;

	/** Add to cart callback */
	onAddToCartClick?: MouseEventHandler<HTMLButtonElement>;

	/** Link click callback */
	onLinkClick?: MouseEventHandler<HTMLAnchorElement>;

	/** The main price to display */
	price: PriceModel | undefined;
	/** The product's unique ID */
	productId: string;
	/** Link to product sheet */
	productSheetLink?: string;

	/** Product sheet link text */
	productSheetLinkDescription?: string;

	/** Sets the number of reviews */
	reviews?: number;

	/** Sets the numbers of score/stars */
	score?: number;

	/** Show and hide options for the button */
	showAddToCartButton?: boolean;

	/** Stock object for stock section stock info */
	stockInfo: StockInfo | undefined;

	/** Display information related to store */
	storesEnabled: boolean;

	/** The sub heading in the component */
	subHeading?: string;

	/** Product tags in the Product or Price sections */
	tags?: ProductTagModel[];

	/** Product tags in the Usp section */
	uspTags?: ProductTagModel[];

	/** Vat info price text */
	vatPriceText?: string;
}

/** Product card with a vertical layout. */
export default function ProductCardColumn({
	actionButtonState,
	basePrice,
	checklist,
	className,
	disabled,
	energySymbolAlt,
	energySymbolSrc,
	flag,
	hasMultipleVariants = false,
	heading,
	href,
	imageAlt = '',
	imageSrc,
	onAddToCartClick,
	onLinkClick,
	price,
	productId,
	productSheetLink,
	productSheetLinkDescription,
	reviews,
	score = 0,
	showAddToCartButton = false,
	stockInfo,
	storesEnabled,
	subHeading,
	tags,
	uspTags,
	vatPriceText,
}: Props) {
	const { t } = useI18n();
	const hasReviews = score > 0;
	const hasTags = is.arrayWithLength(tags);
	const customisableUspTag = uspTags?.find(
		({ type }) => type === 'Customizable',
	);
	// The primary link covers the entire card, place any other clickable
	// components above it and expand their hit area to hopefully reduce
	// accidental clicks on the below primary link.
	const innerTargetClasses =
		'relative z-3 before:absolute before:-inset-2 before:-left-1';

	return (
		<article
			className={cn(className, 'relative w-full md:w-auto')}
			aria-label={subHeading}
		>
			{flag && (
				<ProductTag
					type={flag.type}
					text={flag.text}
					size="medium"
					className="absolute left-0 top-0 z-1"
				/>
			)}
			<OptionalContainer
				// Normally an unnecessary optimization to be stringent about a
				// wrapping div, but there can be a lot of product cards on a page
				// and the site's DOM size is a problem.
				as="div"
				hasContainer={hasTags}
				className="relative mb-2"
			>
				<Img
					src={imageSrc}
					alt={imageAlt}
					width={160}
					height={160}
					service="nextjs"
					useFallbackOnError
					className={cn(
						'block object-contain sm:max-w-[60%] md:h-40 md:max-w-none',
						!hasTags && 'mb-2',
					)}
				/>
				{tags && (
					<ProductCardTags
						tags={tags}
						className="absolute bottom-0 left-0 z-1"
					/>
				)}
			</OptionalContainer>

			<header className="mt-2">
				{heading && (
					<Text as="p" styleAs="h6" className="mb-2">
						<Link
							href={href}
							onClick={onLinkClick}
							cover
							className="hover:underline"
						>
							{heading}
						</Link>
					</Text>
				)}
				{subHeading && (
					<Text as="pXSmall" className="mb-2">
						{subHeading}
					</Text>
				)}
			</header>
			{hasReviews && (
				<div className="mt-2 flex items-start">
					<Rating
						size="small"
						score={score}
						reviewCount={reviews}
						href={`${href}#${PRODUCT_REVIEWS_ID}`}
						onClick={onLinkClick}
						className={innerTargetClasses}
					/>
				</div>
			)}

			{price && (
				<div className="my-2">
					<Price price={price} size="small" />
					<Text text={vatPriceText} as="pXSmall" />
					<BasePriceText
						basePrice={basePrice}
						displayedPriceType={price.priceType}
						recentLowestPriceKey="product_list_recent_lowest_price_text"
						className="mt-1 text-xs"
					/>
				</div>
			)}
			{customisableUspTag?.text && (
				<Text as="pSmall" className="mt-3 flex items-center gap-1">
					<Icon name="addPrint" />
					{customisableUspTag.text}
				</Text>
			)}
			{checklist && (
				<ul className="mt-3 flex list-inside list-disc flex-col gap-1 text-sm">
					{checklist.map((item) => (
						<li key={item}>{item}</li>
					))}
				</ul>
			)}

			{(energySymbolSrc || productSheetLink) && (
				<div
					className={cn(
						'mt-4 flex items-center gap-3 text-xs',
						// The energy symbol as some size to it but the sheet link is small
						// and feels a bit flimsy to hit even with an expanded hit area.
						// Place the entire container as a blocking layer above the primary
						// link when there is a sheet link.
						productSheetLink && 'relative z-3',
					)}
				>
					{energySymbolSrc && (
						<Link
							href={`${href}#${PRODUCT_TECHNICAL_ATTRIBUTES_ID}`}
							onClick={onLinkClick}
							className={cn(innerTargetClasses, 'hover:opacity-80')}
						>
							<Img
								src={energySymbolSrc}
								alt={
									energySymbolAlt ||
									t('product_details_technical_specification_heading')
								}
								className="h-6 object-contain"
							/>
						</Link>
					)}
					{productSheetLink && (
						<Link
							href={productSheetLink}
							target="_blank"
							rel="nofollow"
							underline
							className={innerTargetClasses}
						>
							{productSheetLinkDescription ||
								t('product_details_product_sheet_button')}
						</Link>
					)}
				</div>
			)}

			{stockInfo && !hasMultipleVariants && (
				<ProductCardStockInfo
					storeStockVisible={storesEnabled}
					stockInfo={stockInfo}
					productId={productId}
					className="mt-4"
				/>
			)}
			{hasMultipleVariants && (
				<p className="mt-4 flex items-center text-xs">
					<Icon
						size={16}
						name="arrow"
						color="white"
						backgroundColor="julaRed"
						className="mr-1"
					/>
					{t('product_list_multiple_variants_label')}
				</p>
			)}

			{showAddToCartButton && (
				<ActionButton
					className={cn('mt-4', innerTargetClasses)}
					size="small"
					variant="cta"
					customState={actionButtonState}
					disabled={disabled}
					onClick={onAddToCartClick}
				>
					{t('product_buy_button')}
				</ActionButton>
			)}
		</article>
	);
}
ProductCardColumn.displayName = 'ProductCardColumn';
