import React from 'react';

import Icon from 'components/Icon';
import { cn } from 'utils/classNames';

interface Step {
	completed?: boolean;
	text: string;
}

interface Props {
	className?: string;
	steps: Step[];
}

export default function ProgressStepsVertical({ className, steps }: Props) {
	const currentStep = steps.findIndex((s) => !s.completed);
	const totalStepCount = steps.length;

	return (
		<ol className={cn('relative flex flex-col text-left', className)}>
			{steps.map(({ completed, text }, index) => {
				const isLastItem = index === totalStepCount - 1;
				const isCurrentStep =
					index === currentStep - 1 && index !== totalStepCount - 1;

				return (
					<li
						className={cn(
							'relative pb-6 pl-10 font-bold',
							isCurrentStep && 'min-h-20',
							// before are the bullets in front of unfinished steps,
							// after are the lines between bullets.
							!completed && [
								'before:absolute',
								'before:left-2',
								'before:top-1',
								'before:z-2',
								'before:inline-flex',
								'before:size-4',
								'before:justify-center',
								'before:rounded-full',
								'before:bg-grey',
								'forced-colors:before:bg-[CanvasText]',
							],
							!isLastItem && [
								'after:absolute',
								'after:left-[calc(1rem-1px)]',
								'after:top-1',
								'after:inline-block',
								'after:h-full',
								'after:w-[2px]',
								'after:bg-grey',
								'forced-colors:after:bg-[CanvasText]',
							],
							completed && !isCurrentStep && 'after:bg-julaRed',
							completed && 'pt-1',
						)}
						// Could also be that the next step should be set as aria-current,
						// to indicate that's where work is currently happening.
						// The design has some examples like 'package ready for pickup'
						// though, where it could give the impression from aria-current
						// that a package is ready when it isn't. Probably safest to have
						// the last completed step as the current.
						aria-current={isCurrentStep ? 'step' : undefined}
						key={text}
					>
						{completed && (
							<Icon
								name="check"
								backgroundColor="julaRed"
								hasBackgroundPadding
								className="absolute left-0 top-0 z-2 text-white forced-colors:border forced-colors:border-[CanvasText] forced-colors:bg-[Canvas] forced-colors:text-[CanvasText]"
							/>
						)}
						{isCurrentStep && (
							<>
								{/* Pulsating bullet */}
								<span className="absolute left-2 top-1/2 z-1 block size-4 translate-y-1/2 animate-pulse rounded-full bg-julaRed/80 forced-color-adjust-none" />
								<span className="absolute left-3 top-1/2 z-2 mt-1 block size-2 translate-y-full rounded-full bg-julaRed forced-color-adjust-none" />
								{/* Line to icon */}
								<span className="absolute left-[calc(1rem-1px)] top-4 z-1 block h-1/2 w-[2px] bg-julaRed forced-colors:bg-[CanvasText]" />
							</>
						)}
						{text}
					</li>
				);
			})}
		</ol>
	);
}
ProgressStepsVertical.displayName = 'ProgressStepsVertical';
