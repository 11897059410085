import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import Img from 'components/Img';
import { LayoutContainer } from 'components/Layout';
import RichText from 'components/RichText';
import Text from 'components/Text';
import type { ColorField, JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import type { HTMLString } from 'types';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		backgroundColor?: ColorField;
		items: {
			content: {
				value: HTMLString;
			};
			heading: {
				value: string;
			};
			squareImage: Field<DigizuiteAsset[]>;
		}[];
	};
};

export default function USP({ fields, params }: Props) {
	if (!fields) {
		return null;
	}
	const backgroundColor = fields.backgroundColor?.fields?.colorHex?.value;

	const usps = filterTruthy(
		fields.items?.map((item) => ({
			content: item?.content?.value,
			heading: item?.heading?.value,
			image: getAsset('Source Copy', item?.squareImage),
		})),
		'content',
		'heading',
		'image',
	);

	if (usps.length === 0) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="Usp"
				description="Requires at least one usp item with heading, content and square image"
			/>
		);
	}

	return (
		<LayoutContainer
			className="grid grid-cols-4 md:grid-cols-12 md:gap-4 lg:gap-6"
			outerClassName={getEditorMargin(params)}
		>
			{usps.map((usp) => (
				<div
					key={`${usp.image.src}-${usp.heading}`}
					className="col-span-full flex gap-4 p-4 md:forced-colors-outline first:rounded-t-lg last:rounded-b-lg first:max-md:pt-4 last:max-md:pb-4 md:col-span-5 md:flex-col md:items-center md:rounded-lg md:p-8 lg:col-span-4 lg:gap-6 lg:px-12 lg:py-20"
					style={backgroundColor ? { backgroundColor } : undefined}
				>
					<Img
						service="nextjs"
						height={232}
						width={232}
						className="aspect-square size-16 shrink-0 md:size-56"
						src={usp.image.src}
					/>
					<div>
						<Text as="h2" styleAs="h3" text={usp.heading} />
						<RichText className="mt-2 md:mt-4" html={usp.content} />
					</div>
				</div>
			))}
		</LayoutContainer>
	);
}
USP.displayName = 'USP';
