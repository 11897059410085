import React, { forwardRef, type ReactNode, useId } from 'react';

import { Radio } from 'components/FormUi';
import { cnm } from 'utils/classNames';

interface Props {
	checked?: boolean;
	children?: ReactNode;
	className?: string;
	label: string;
	name: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	value?: string;
}

const RadioListRow = forwardRef<HTMLLIElement, Props>(
	({ checked, children, className, label, name, onChange, value }, ref) => {
		const id = useId();
		return (
			<li
				ref={ref}
				className={cnm('border-b border-grey py-4 first:border-t', className)}
			>
				<Radio
					id={id}
					label={label}
					labelClassName="font-bold"
					name={name}
					checked={checked}
					value={value}
					onChange={onChange}
					color="green"
				/>
				{Boolean(children) && <div className="ml-8 md:ml-7">{children}</div>}
			</li>
		);
	},
);

RadioListRow.displayName = 'RadioListRow';

export default RadioListRow;
