import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import Img from 'components/Img';
import type { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import { getEditorMargin } from 'utils/business-logic';
import { cn } from 'utils/classNames';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		primaryImage: Field<DigizuiteAsset[]>;
		secondaryImage: Field<DigizuiteAsset[]>;
	};
};

export default function FocusGridMedia({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const primaryImage = getAsset('Templated 2:1', fields.primaryImage);
	const secondaryImage = getAsset('Templated 2:1', fields.secondaryImage);

	if (!primaryImage || !secondaryImage) {
		return (
			<ComponentPlaceholder
				componentName="FocusGridMedia"
				className={getEditorMargin(params)}
				description="Requires a primary and secondary image"
			/>
		);
	}

	return (
		<div id={params?.anchor} className={cn('flex', getEditorMargin(params))}>
			<Img
				src={primaryImage.src}
				alt={primaryImage.alt}
				width={822}
				height={411}
				service="nextjs"
				className="h-auto w-1/2 object-cover max-sm:w-full"
			/>
			<Img
				src={secondaryImage.src}
				alt={secondaryImage.alt}
				width={822}
				height={411}
				service="nextjs"
				className="h-auto w-1/2 object-cover max-sm:hidden"
			/>
		</div>
	);
}
FocusGridMedia.displayName = 'FocusGridMedia';
