/**
 * ExpandableItems
 */

import React, { type ReactNode, useId, useState } from 'react';

import Button from 'components/Button';
import { useI18n } from 'utils/i18n';

interface Props {
	/** Class names for the container. */
	className?: string;

	/** Number of items to render in the collapsed state. */
	collapsedItemsCount?: number;

	/** If all items should be visible initially. */
	isInitiallyExpanded?: boolean;

	/** Items to render. */
	items: ReactNode[];
}

/** Toggle if all or only the first X number of items should render. */
export default function ExpandableItems({
	className,
	collapsedItemsCount = 4,
	isInitiallyExpanded = false,
	items,
}: Props) {
	const { t } = useI18n();
	const id = useId();
	const itemsId = `expandable-items-${id}`;
	const [isExpanded, setIsExpanded] = useState<boolean>(isInitiallyExpanded);
	const truthyItems = items.filter((item) => Boolean(item || item === 0));
	const visibleItems = isExpanded
		? truthyItems
		: truthyItems.slice(0, collapsedItemsCount);

	if (visibleItems.length === 0) {
		return null;
	}

	return (
		<div className={className}>
			<div id={itemsId}>{visibleItems}</div>
			{items.length > collapsedItemsCount && (
				<Button
					variant="text"
					onClick={() => {
						setIsExpanded((current) => !current);
					}}
					aria-controls={itemsId}
					aria-expanded={isExpanded}
				>
					{isExpanded
						? t('product_list_filter_checkbox_hide_button')
						: t('product_list_filter_checkbox_show_button')}
				</Button>
			)}
		</div>
	);
}
ExpandableItems.displayName = 'ExpandableItems';
