import React from 'react';

import Text from 'components/Text';
import type { HeadingLevel } from 'lib/component-props';

interface Props {
	headingLevel?: HeadingLevel;
	subTitle: string | undefined;
	title: string | undefined;
	titleExclModel: string | undefined;
}

export default function ProductPopoverTitle({
	headingLevel = 'h2',
	subTitle,
	title,
	titleExclModel,
}: Props) {
	const heading =
		subTitle && titleExclModel
			? `${titleExclModel} ${subTitle}`
			: (titleExclModel ?? title);
	return <Text as={headingLevel} styleAs="h2" text={heading} />;
}
ProductPopoverTitle.displayName = 'ProductPopoverTitle';
