/**
 * LinkOrButton
 */

import React, {
	type AnchorHTMLAttributes,
	type ButtonHTMLAttributes,
	type ReactNode,
} from 'react';

import Link from 'components/Link';
import PlainButton from 'components/PlainButton';
import type { HTMLAttributes } from 'types';

type AnchorAttrs = AnchorHTMLAttributes<HTMLAnchorElement>;
type ButtonAttrs = ButtonHTMLAttributes<HTMLButtonElement>;

interface Props extends HTMLAttributes<HTMLAnchorElement | HTMLButtonElement> {
	/** Button type if `href` is NOT set */
	type?: ButtonAttrs['type'];

	/** Link/button text */
	children: ReactNode;

	/** If the button is disabled, or without href for links */
	disabled?: boolean;

	/** Link URL, results in an anchor instead of a button */
	href: string | undefined;

	/** Link rel if `href` is set */
	rel?: AnchorAttrs['rel'];

	/** Link rel if `href` is set */
	tabIndex?: AnchorAttrs['tabIndex'];

	/** Link target if `href` is set */
	target?: AnchorAttrs['target'];
}

/** Base container for a link or button, depending on if `href` is set. */
const LinkOrButton = React.forwardRef<
	HTMLAnchorElement | HTMLButtonElement,
	Props
>(
	(
		{
			children,
			disabled,
			href,
			onClick,
			rel,
			target,
			type = 'button',
			...attrs
		},
		ref,
	) => {
		// The ref type issue is that a button ref can't be assigned to an anchor
		// and vice versa. Since the resulting ref type is dependant on the runtime
		// value of href, it can't be solved statically.
		if (href) {
			if (disabled) {
				return (
					// https://www.scottohara.me/blog/2021/05/28/disabled-links.html
					<a
						{...attrs}
						// @ts-expect-error See top of component
						ref={ref}
						role="link"
						aria-disabled="true"
						// Make it focusable, by default it isn't when href is missing.
						// Unfortunately this will not keep existing focus (e.g. gets
						// disabled while loading); this anchor and the Link component
						// are different enough that React replaces the DOM element which
						// clears focus. Hopefully such dynamic actions will mostly be done
						// with buttons rather than links.
						tabIndex={0}
					>
						{children}
					</a>
				);
			}
			return (
				<Link
					{...attrs}
					// @ts-expect-error See top of component
					ref={ref}
					href={href}
					rel={rel}
					target={target}
					onClick={onClick}
				>
					{children}
				</Link>
			);
		}

		return (
			<PlainButton
				{...attrs}
				// @ts-expect-error See top of component
				ref={ref}
				disabled={disabled}
				onClick={onClick}
				type={type}
			>
				{children}
			</PlainButton>
		);
	},
);
LinkOrButton.displayName = 'LinkOrButton';

export default LinkOrButton;
