import React, { useCallback, useRef } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import { ImagePopover } from 'components/Popover';
import type { ReviewImage, ReviewImageId } from 'hooks/product-details';
import { useI18n } from 'utils/i18n';

import ReviewImagesView from './ReviewImagesView';

interface Props {
	images: ReviewImage[];
	isOpen: boolean;
	onClose: () => void;
	onSlideChange: (slideIndex: number) => void;
	selectedAllReviewImageId?: ReviewImageId | undefined;
}

export default function AllReviewImagesPopover({
	images,
	isOpen,
	onClose: onCloseProp,
	onSlideChange,
	selectedAllReviewImageId,
}: Props) {
	const { t } = useI18n();
	const reviewImagesScrollRef = useRef<HTMLDivElement>(null);
	const onOpen = () => {
		if (reviewImagesScrollRef.current) {
			disableBodyScroll(reviewImagesScrollRef.current, {
				reserveScrollBarGap: true,
			});
		}
	};
	const onClose = useCallback(() => {
		onCloseProp();
		clearAllBodyScrollLocks();
	}, [onCloseProp]);

	return (
		<ImagePopover
			label={t('all_review_images_popover_label')}
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
		>
			<ReviewImagesView
				ref={reviewImagesScrollRef}
				images={images}
				isVisible={isOpen}
				onSlideChange={onSlideChange}
				selectedReviewImageId={selectedAllReviewImageId}
			/>
		</ImagePopover>
	);
}
AllReviewImagesPopover.displayName = 'AllReviewImagesPopover';
