import { IntrospectionResponse } from 'pages/api/token/introspection';

export async function requestSessionEndpoint() {
	const response = await fetch('/api/signin/session/');
	if (!response.ok) {
		throw new Error('requestSessionEndpoint failed');
	}
	const data = (await response.json()) as { tokenExpiry: number };
	return {
		statusCode: response.status,
		tokenData: {
			tokenExpiry: data.tokenExpiry,
		},
	};
}

export async function requestTokenData() {
	const response = await fetch('/api/token/introspection/');
	if (!response.ok) {
		throw new Error('requestTokenData failed');
	}
	if (response.status === 204) {
		return { statusCode: response.status };
	}
	const data = (await response.json()) as IntrospectionResponse;
	return {
		statusCode: response.status,
		tokenData: { ...data },
	};
}
