import React from 'react';

import Icon from 'components/Icon';
import PlainButton from 'components/PlainButton';
import { cn, cnm } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

interface Props {
	className?: string;
	onGradeClick: (grade: number) => void;
	onGradeResetClick: () => void;
	ratingsPerGrade: number[];
	selectedGradeIndex: number;
	totalReviews: number;
}

export default function ReviewsRatingFilter({
	className,
	onGradeClick,
	onGradeResetClick,
	ratingsPerGrade,
	selectedGradeIndex,
	totalReviews,
}: Props) {
	const { t } = useI18n();
	// A min-width value to ensure the number of reviews per grade are displayed
	// at the same size. Without it a '123' next to the 5-star count and a '7'
	// next to the 1-star will have different widths and things will look uneven.
	const ratingCountMinWidthClass = [
		'min-w-[2em]',
		'min-w-[2.5em]',
		'min-w-[3em]',
		'min-w-[3.5em]',
		'min-w-[4em]',
	][String(Math.max(...ratingsPerGrade)).length - 1];

	return (
		<div className={cn('flex flex-col gap-3', className)}>
			{ratingsPerGrade.map((ratingCount, i) => {
				const ratingBarPercent =
					ratingCount === 0 || totalReviews === 0
						? 0
						: (ratingCount / totalReviews) * 100;
				return (
					<PlainButton
						// Will not be sorted and there is no meaningful key.
						// eslint-disable-next-line react/no-array-index-key
						key={i}
						onClick={() => {
							// Unset if currently selected
							const newSelectedGradeIndex = selectedGradeIndex === i ? -1 : i;
							if (newSelectedGradeIndex === -1) {
								onGradeResetClick();
							} else {
								onGradeClick(5 - i);
							}
						}}
						aria-label={`${t('product_review_stars_screen_reader_text', {
							starCount: 5 - i,
						})}, ${t('reviews_grade_count_text', { ratingCount })}`}
						aria-pressed={selectedGradeIndex === i}
						className={cnm(
							'group/grade-button -mx-3 flex h-8 items-center justify-center rounded-full border border-transparent px-3 forced-colors:border-0',
							i === selectedGradeIndex &&
								'forced-colors-outline border-greyLight bg-greyLighter',
						)}
					>
						{/* A 1 is less wide than a 5, set min-width to align stars */}
						<span className="inline-block min-w-[0.75em] text-center font-bold">
							{5 - i}
						</span>
						<Icon name="star" size={16} className="ml-1" />
						<div
							className={cnm(
								'relative mx-6 h-4 grow overflow-hidden rounded-full border border-greyDark group-hover/grade-button:border-black group-hover/grade-button:outline group-hover/grade-button:outline-1 group-hover/grade-button:outline-black',
								i === selectedGradeIndex &&
									'border-2 border-black group-hover/grade-button:outline-2',
							)}
						>
							{Boolean(ratingBarPercent) && (
								<div
									className={cn(
										'h-full bg-black forced-colors:bg-[CanvasText]',
										ratingBarPercent < 3 ? 'rounded-border' : 'rounded-full',
									)}
									style={{ width: `${ratingBarPercent}%` }}
								/>
							)}
						</div>
						<span
							className={cn(
								'inline-block text-right underline',
								ratingCountMinWidthClass,
							)}
						>
							{t('reviews_grade_count_text', { ratingCount })}
						</span>
					</PlainButton>
				);
			})}
		</div>
	);
}
ReviewsRatingFilter.displayName = 'ReviewsRatingFilter';
