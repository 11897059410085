import React, {
	createContext,
	type ReactNode,
	useContext,
	useEffect,
	useMemo,
} from 'react';

import { useRerender } from 'hooks';

import { type Dictionary, I18n } from './I18n';

export const i18n = new I18n({});

export const I18nContext = createContext<I18n>(new I18n({}));

interface ProviderProps {
	children: ReactNode;
	dictionary: Dictionary | undefined;
}

export function I18nProvider({ children, dictionary }: ProviderProps) {
	const rerender = useRerender();

	// Ugly and weird to use and mutate an outside instance but this is what
	// next-localization did (though as a custom instance prop). There are a few
	// cases where language strings are used outside of React components, i.e.
	// no place for context, so it has to be exposed in some way. The dictionary
	// comes from a prop in the root component though, so it can't just be a
	// regular global variable.
	const i18nValue = useMemo(() => {
		i18n.setDictionary(dictionary ?? {});
		rerender();
		return i18n;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		i18n.setDictionary(dictionary ?? {});
		rerender();
	}, [dictionary, rerender]);

	return (
		<I18nContext.Provider value={i18nValue}>{children}</I18nContext.Provider>
	);
}
I18nProvider.displayName = 'I18nProvider';

export function useI18n() {
	return useContext<I18n>(I18nContext);
}
