import React from 'react';

import type { WithOptional } from 'types';
import { is } from 'utils/helpers';

/**
 * Create a component where the specified props are required for rendering.
 *
 * The props are made optional to accept undefined but if any of them are
 * undefined or null, the component doesn't render.
 */
export function withRequiredProps<
	T extends object,
	K extends keyof T,
	FilteredT extends WithOptional<T, K>,
>(
	Component: React.FunctionComponent<T>,
	...propKeys: [K, ...K[]]
): React.FunctionComponent<FilteredT> {
	function Required(props: FilteredT) {
		if (propKeys.some((propKey) => !is.defined(props[propKey]))) {
			return null;
		}
		// @ts-expect-error `props` isn't properly refined here and it feels
		// unnecessary to do so.
		return <Component {...props} />;
	}
	Required.displayName = `${Component.displayName}WithRequiredFields`;
	return Required;
}
