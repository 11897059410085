import React from 'react';

import Button from 'components/Button';
import ListItem from 'components/ListItem';
import { PriceText } from 'components/Price';
import Text from 'components/Text';
import type { FormattedPriceResponse } from 'models/api';
import { useI18n } from 'utils/i18n';

interface Props {
	buttonText: string | undefined;
	logotypeName: string | undefined;
	onClick: () => void;
	placement: string;
	price: FormattedPriceResponse | undefined;
	size: string | undefined;
}
export default function PrintPlacement({
	buttonText,
	logotypeName,
	onClick,
	placement,
	price,
	size,
}: Props) {
	const { t } = useI18n();
	return (
		<ListItem
			as="li"
			title={placement}
			alignment="start"
			endContent={<PriceText price={price} className="font-bold" />}
		>
			<div className="flex flex-col">
				<Text as="p" text={size} />
				<Text
					as="pSmall"
					text={`${t('product_print_logo_label')} ${logotypeName}`}
				/>
				<Button className="mt-2" variant="text" onClick={onClick}>
					{buttonText}
				</Button>
			</div>
		</ListItem>
	);
}
PrintPlacement.displayName = 'ProductPrint_PrintPlacement';
