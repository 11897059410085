import React from 'react';
import { Field } from 'react-final-form';

import { composeValidators, type FieldValidator } from 'components/FinalForm';
import {
	Input as StyledInput,
	type InputProps as StyledInputProps,
	type InputType,
} from 'components/FormUi';

interface Props extends StyledInputProps {
	className?: string;
	name: string;
	validate?: FieldValidator | FieldValidator[];
}

export default function TextInput({
	className,
	name,
	validate,
	...rest
}: Props) {
	return (
		<Field
			name={name}
			validate={composeValidators(validate)}
			render={({ input, meta }) => {
				const hasStaleError = meta.dirtySinceLastSubmit && meta.submitError;
				const { type, ...fieldInputProps } = input;
				return (
					<StyledInput
						invalid={meta.invalid && meta.touched && !hasStaleError}
						valid={meta.valid && meta.touched && !hasStaleError}
						errorMessage={meta.error || meta.submitError}
						type={type as InputType}
						className={className}
						{...fieldInputProps}
						{...rest}
					/>
				);
			}}
		/>
	);
}
TextInput.displayName = 'FinalForm_TextInput';
