import React from 'react';

import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenReaderAnnouncementText from 'components/ScreenReaderAnnouncementText';

interface Props {
	errorOpeningSignWindowText: string;
	hasErrorOpeningSignWindow: boolean;
	loadingText: string;
	onRetryOpenSignWindow: () => void;
	onRetryOpenSignWindowButtonText: string;
}

export default function LoadingView({
	errorOpeningSignWindowText,
	hasErrorOpeningSignWindow,
	loadingText,
	onRetryOpenSignWindow,
	onRetryOpenSignWindowButtonText,
}: Props) {
	return (
		<div className="pt-8 text-center">
			<p className="font-bold">{loadingText}</p>
			<LoadingSpinner
				variant="dashing"
				spinnerColor="julaRed"
				trackColor="transparent"
				size="medium"
				className="mx-auto mt-4"
			/>
			<ScreenReaderAnnouncementText
				as="p"
				text={hasErrorOpeningSignWindow ? errorOpeningSignWindowText : ''}
				className="mt-8 font-bold"
			/>
			{hasErrorOpeningSignWindow && (
				<Button
					onClick={onRetryOpenSignWindow}
					className="mt-4"
					variant="primary"
				>
					{onRetryOpenSignWindowButtonText}
				</Button>
			)}
		</div>
	);
}
LoadingView.displayName = 'JulaClubSignupForm_LoadingView';
