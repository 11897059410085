/**
 * AdyenPayment
 */

import React, { useEffect, useRef, useState } from 'react';
import type { UIElementProps } from '@adyen/adyen-web/dist/types/components/types';
import type UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import type Core from '@adyen/adyen-web/dist/types/core';
import type { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import type { PaymentAction } from '@adyen/adyen-web/dist/types/types';

import { publicRuntimeConfig } from 'config';
import { useEffectOnce, usePrevious } from 'hooks';
import { scrollIntoViewIfNeeded } from 'utils/dom';

interface Props {
	action?: PaymentAction;
	adyenPaymentMethodInfo?: any;
	onAdditionalDetails?: UIElementProps['onAdditionalDetails'];
	onChange?: UIElementProps['onChange'];
	onSubmit?: UIElementProps['onSubmit'];
	paymentType: string;
}

/** Adyen component for the specified payment type. */
export default function AdyenPayment({
	action,
	adyenPaymentMethodInfo,
	onAdditionalDetails,
	onChange,
	onSubmit,
	paymentType,
}: Props) {
	const adyenElementRef = useRef<HTMLDivElement | null>(null);
	const adyenInstanceRef = useRef<Core | null>(null);
	const config: CoreOptions = {
		locale: publicRuntimeConfig?.ADYEN_LOCALE,
		environment:
			publicRuntimeConfig?.ENVIRONMENT_TYPE === 'production' ||
			publicRuntimeConfig?.ENVIRONMENT_TYPE === 'stage'
				? 'live'
				: 'test',
		clientKey: publicRuntimeConfig?.ADYEN_CLIENT_KEY,
		enableStoreDetails: true,
		onAdditionalDetails,
		onChange,
		onSubmit,
	};

	const cardConfiguration = {
		hasHolderName: true,
		holderNameRequired: true,
		brands: adyenPaymentMethodInfo?.brands,
	};

	const focusAdyenElement = () => {
		if (adyenElementRef.current) {
			scrollIntoViewIfNeeded(adyenElementRef.current, {
				onDone: () => {
					adyenElementRef.current?.focus();
				},
			});
		}
	};

	useEffectOnce(() => {
		import('@adyen/adyen-web')
			.then(async (AdyenCheckoutImport) => {
				const AdyenCheckout = AdyenCheckoutImport.default;
				adyenInstanceRef.current = await AdyenCheckout(config);
				if (adyenElementRef.current) {
					if (action) {
						focusAdyenElement();
						adyenInstanceRef.current
							.createFromAction(action)
							.mount(adyenElementRef.current);
					} else {
						adyenInstanceRef.current
							.create(
								paymentType === 'scheme' ? 'card' : paymentType,
								paymentType === 'scheme' && cardConfiguration,
							)
							.mount(adyenElementRef.current);
					}
				}
			})
			.catch((error) => {
				console.error('Adyen import failed', error);
			});
	});

	// Received an action for existing instance, handle it.
	const prevAction = usePrevious(action);
	const [didHandleAction, setDidHandleAction] = useState(false);
	useEffect(() => {
		if (action && !prevAction && !didHandleAction && adyenInstanceRef.current) {
			// Since a new Adyen instance is created for every AdyenPayment mount,
			// it should only ever have a single component in the components array.
			const component = adyenInstanceRef.current.components[0] as
				| UIElement
				| undefined;
			if (component) {
				component.handleAction(action);
				setDidHandleAction(true);
				// Additional action status will display inline in the component
				// so ensure the user will see ut.
				focusAdyenElement();
			}
		}
	}, [action, didHandleAction, prevAction]);

	return (
		<div
			ref={adyenElementRef}
			id={`${paymentType === 'scheme' ? 'card' : paymentType}-container`}
			tabIndex={-1}
			className="relative z-10 outline-none"
		/>
	);
}
AdyenPayment.displayName = 'AdyenPayment';
