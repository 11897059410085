import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import RichText from 'components/RichText';
import Table from 'components/Table';
import type { JulaComponentProps } from 'lib/component-props';
import type { HTMLString } from 'types';
import { getEditorMargin } from 'utils/business-logic';
import { is } from 'utils/helpers';

interface ClothingSizeColumn {
	isHeader: boolean;
	value: string;
}

interface ClothingSizeRow {
	columns: ClothingSizeColumn[];
	isHeader: boolean;
}

interface ClothingSize {
	type: string;
	numberOfColumns: number;
	numberOfRows: number;
	rows: ClothingSizeRow[];
}

type Props = JulaComponentProps & {
	fields?: {
		clothingSizes?: ClothingSize[];
		fallbackTable?: Field<HTMLString>;
	};
};

export default function ClothingSizes({ fields, params }: Props) {
	if (!fields) {
		return null;
	}
	const hasSizes = is.arrayWithLength(fields.clothingSizes);

	if (!hasSizes && !fields.fallbackTable?.value) {
		return (
			<ComponentPlaceholder
				componentName="ClothingSizes"
				className={getEditorMargin(params)}
				description="Requires size data or a fallback table"
			/>
		);
	}

	return (
		<LayoutContainer
			outerClassName={getEditorMargin(params)}
			withPopoverLayout
			className={hasSizes ? 'space-y-6' : undefined}
		>
			{hasSizes &&
				fields.clothingSizes?.map((table) => (
					<Table
						key={table.type}
						fullWidth
						rows={table.rows.map((row) => ({
							isHeader: row.isHeader,
							cells: row.columns.map((col) => ({
								isHeader: col.isHeader,
								content: col.value,
							})),
						}))}
					/>
				))}
			{!hasSizes && <RichText field={fields.fallbackTable} />}
		</LayoutContainer>
	);
}
ClothingSizes.displayName = 'ClothingSizes';
