/**
 * Meter
 */

import React, { useId } from 'react';

import type { HTMLAttributes } from 'types';
import { cn } from 'utils/classNames';

interface Props {
	alignment?: 'left' | 'center';
	className?: string;
	current: number;
	label: string;
	/**
	 * If the `label` text contains a description of the progress, e.g. the
	 * `current` and `max` values, in which case the meter bar is purely visual
	 * with no accessibility attributes since that just duplicates the information.
	 */
	labelHasProgress?: boolean;
	max: number;
	min?: number;
}

/**
 * Progress indicator for a known quantity.
 *
 * Unlike the progressbar role/element, a meter always has a minimum, maximum
 * and current value:
 *
 * - "A meter should not be used to represent a value like the current world
 *   population since it does not have a meaningful maximum limit."
 * - "The meter should not be used to indicate progress, such as loading or
 *   percent completion of a task. To communicate progress, use the progressbar
 *   role instead."
 *
 * https://www.w3.org/WAI/ARIA/apg/patterns/meter/
 */
export default function Meter({
	alignment = 'left',
	className,
	current,
	label,
	labelHasProgress,
	max,
	min = 0,
}: Props) {
	const id = `meter-${useId()}`;
	const labelId = `${id}-label`;

	// Guard against zero division.
	const progress =
		current && max ? Math.min(100, Math.round((current / max) * 100)) : 0;

	const meterAttrs: HTMLAttributes<HTMLDivElement> = labelHasProgress
		? {}
		: {
				'role': 'meter',
				'aria-valuemin': min,
				'aria-valuemax': max,
				'aria-valuenow': current,
				'aria-labelledby': labelId,
			};

	return (
		<div
			className={cn(
				'flex flex-col gap-2',
				alignment === 'center' && 'items-center',
				className,
			)}
		>
			{/* Set aria-live - helpful if the text updates and nothing happens if it doesn't. */}
			<p className="text-sm" id={labelId} aria-live="polite">
				{label}
			</p>
			<div
				{...meterAttrs}
				className="forced-colors-outline h-1 w-48 rounded-full bg-greyLight"
			>
				<div
					style={{ width: `${progress}%` }}
					className="h-full rounded-l-full bg-julaRed forced-color-adjust-none forced-colors:bg-[CanvasText]"
				/>
			</div>
		</div>
	);
}
Meter.displayName = 'Meter';
