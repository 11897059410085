/**
 * GridOverlay
 */

import React from 'react';

import { cn } from 'utils/classNames';
import { range } from 'utils/helpers';

import { getContainerMaxWidthClass, type MaxWidth } from './helpers';

interface Props {
	maxWidth?: MaxWidth;
}

/** An overlay to visulize the grid columns. */
export default function GridOverlay({ maxWidth = 'standard' }: Props) {
	return (
		<div className="pointer-events-none absolute inset-0">
			<div
				className={cn(
					'mx-auto grid h-full grid-flow-col grid-cols-4 gap-4 md:grid-cols-12 md:gap-6',
					getContainerMaxWidthClass(maxWidth),
				)}
			>
				{range(12).map((i) => (
					<div
						key={i}
						className={cn(
							'col-span-1 h-full bg-[rgba(238,103,35,0.25)]',
							i >= 4 && 'max-md:hidden',
						)}
					>
						&nbsp;
					</div>
				))}
			</div>
		</div>
	);
}
GridOverlay.displayName = 'GridOverlay';
