import React, { useEffect } from 'react';
import { useSelector } from '@xstate/react';
import { useRouter } from 'next/router';

import ErrorBoundary from 'components/ErrorBoundary';
import Placeholder from 'components/Placeholder';
import { useGlobalMachinesContext } from 'contexts';
import { useIsEditing } from 'hooks';
import type { CustomLayoutServiceData } from 'lib/page-props';
import {
	selectIsAuthenticatedAnonymous,
	selectIsAuthenticatedUser,
} from 'state-machines/authentication';
import { ignorePromiseRejection } from 'utils/helpers';

import BaseLayout from './BaseLayout';

interface Props {
	layoutData: CustomLayoutServiceData;
}
export const ACCOUNT_RETURN_PATH_QUERY_PARAM = 'returnPath';

export default function LayoutAccount({ layoutData }: Props) {
	const { route } = layoutData.sitecore;
	const { userService } = useGlobalMachinesContext();
	const router = useRouter();
	const isEditing = useIsEditing();

	const isAuthenticatedUser = useSelector(
		userService,
		selectIsAuthenticatedUser,
	);
	const isAuthenticated = useSelector(
		userService,
		selectIsAuthenticatedAnonymous,
	);

	useEffect(() => {
		if (!isEditing && isAuthenticated && !isAuthenticatedUser) {
			ignorePromiseRejection(
				router.push(`/?${ACCOUNT_RETURN_PATH_QUERY_PARAM}=${router.asPath}`),
			);
		}
	}, [isAuthenticated, isAuthenticatedUser, isEditing, router]);

	if (!isEditing && !isAuthenticatedUser) {
		return null;
	}

	return (
		<BaseLayout
			forceNoindexNofollow
			layoutData={layoutData}
			route={route}
			renderMain={false}
		>
			{route && (
				<Placeholder
					name="jula-main"
					rendering={route}
					render={(components) => (
						<ErrorBoundary isPageWidth>{components}</ErrorBoundary>
					)}
				/>
			)}
		</BaseLayout>
	);
}
LayoutAccount.displayName = 'LayoutAccount';
