import { parse as parseCookie } from 'cookie';
import { waitFor } from 'xstate/lib/waitFor';

import { publicRuntimeConfig } from 'config';
import { ResponseError, ValidationError } from 'errors';
import type { JulaValidationError } from 'models/api';
import type { JSONValue } from 'types';
import { mapKeys } from 'utils/collection';
import {
	generateConsentHeader,
	is,
	isClient,
	sendGlobalEvent,
} from 'utils/helpers';
import { log } from 'utils/log';
import { failure, type Result, success } from 'utils/result';

export const JSON_MIME_TYPE = 'application/json';
// @deprecated use the on in api.ts
export const API_URL: string =
	(process.env.NODE_ENV === 'development'
		? process.env.NEXT_PUBLIC_LOCAL_API_URL
		: publicRuntimeConfig?.NEXT_PUBLIC_DIGITAL_PLATFORM_API_URL) ?? '/';

/** Ensure the user has a valid token. */
export async function ensureFreshToken() {
	if (isClient() && globalThis.globalFetchLockInterpreter) {
		const tokenExpiry = globalThis.localStorage.getItem('tokenExpiry');
		if (!tokenExpiry || Date.now() > Number.parseInt(tokenExpiry, 10)) {
			sendGlobalEvent('refresh-token');
		}
		await waitFor(
			globalThis.globalFetchLockInterpreter,
			(state) => state.matches('unlocked'),
			{ timeout: 20_000 },
		);
	}
}

/** Check if a request or response has a JSON content type. */
function isJson(headers: HeadersInit): boolean {
	const contentType =
		// Can't use instanceof due to tests, the global Headers here isn't
		// the same as the one used for mocking.
		// eslint-disable-next-line @typescript-eslint/unbound-method
		'get' in headers && is.func(headers.get)
			? headers.get('Content-Type')
			: headers['Content-Type'];
	// Use includes since Content-Type can contain things like charset as well.
	return is.string(contentType) && contentType.includes(JSON_MIME_TYPE);
}

/** Lowercase object keys. */
function lowerKeys(obj: Record<string, string>) {
	return mapKeys(obj, (val, key) => key.toLowerCase());
}

interface RequestOptions extends Omit<RequestInit, 'body'> {
	/** The request body. JSON-valid values will be stringified. */
	body?: BodyInit | JSONValue;
	/** How many times to attempt a request in case it fails. */
	maxAttempts?: number;
}

async function fetcher<T = unknown>(
	input: string | URL | Request,
	options?: RequestOptions,
	attempt = 1,
): Promise<T | undefined> {
	const isReqInput = is.instance(input, Request);
	const maxAttempts = options?.maxAttempts ?? 3;

	// The Headers object is case insensitive, probably to match the HTTP spec
	// https://www.rfc-editor.org/rfc/rfc7230#section-3.2, but it's sort of case
	// sensitive on creation in that the same header with different case will be
	// merged instead of overwritten:
	// new Headers({ 'Content-Type': 'text/plain', 'content-type': 'text/html' });
	// => Headers { "content-type": "text/plain, text/html" }
	// Making both input and defaults lowercase before merging will avoid this.
	const inputHeaders = lowerKeys({
		...(isReqInput ? Object.fromEntries(input.headers.entries()) : null),
		...(is.instance(options?.headers, Headers)
			? Object.fromEntries(options.headers.entries())
			: is.array(options?.headers)
				? Object.fromEntries(options.headers)
				: options?.headers),
	});
	const defaultHeaders = lowerKeys({
		'Pragma': inputHeaders.pragma || 'no-cache',
		'Cache-Control': inputHeaders['cache-control'] || 'no-cache',
		'Content-Type': inputHeaders['content-type'] || JSON_MIME_TYPE,
		'CF-Access-Client-Id': String(
			publicRuntimeConfig?.NEXT_PUBLIC_CF_ACCESS_CLIENT_ID,
		),
		'CF-Access-Client-Secret': String(
			publicRuntimeConfig?.NEXT_PUBLIC_CF_ACCESS_CLIENT_SECRET,
		),
		...generateConsentHeader(
			typeof document === 'undefined'
				? undefined
				: parseCookie(document.cookie).OptanonConsent,
		),
	});
	const headers = new Headers({
		...inputHeaders,
		...defaultHeaders,
	});

	const bodyInput = options?.body ?? (isReqInput ? input.body : null);
	// When sending form data, the browser itself has to set the Content-Type
	// header for a proper boundary to be included.
	// https://stackoverflow.com/q/39280438
	if (bodyInput instanceof FormData) {
		headers.delete('content-type');
	}

	let body: BodyInit | undefined;
	if (is.defined(bodyInput)) {
		// Exclude string to not double stringify.
		const isJsonValue =
			is.plainObject(bodyInput) ||
			is.array(bodyInput) ||
			is.bool(bodyInput) ||
			is.number(bodyInput);
		body = isJsonValue ? JSON.stringify(bodyInput) : bodyInput;
	}

	await ensureFreshToken();

	const response = await fetch(input, {
		...options,
		cache: options?.cache ?? (isReqInput ? input.cache : null) ?? 'no-store',
		credentials:
			options?.credentials ??
			(isReqInput ? input.credentials : null) ??
			'include',
		headers,
		body,
	});

	// response.json() will throw a parse error for empty responses so always
	// read as text and manually parse to JSON to handle it.
	const responseText = await response.text();
	let responseData: JSONValue | undefined;
	if (isJson(response.headers)) {
		responseData =
			!responseText || responseText === 'null'
				? undefined
				: JSON.parse(responseText);
	} else {
		responseData = responseText || undefined;
	}

	if (response.ok) {
		// HTTP 204 is No Content. Also resolve any empty values as undefined.
		if (response.status === 204 || is.nonZeroFalsy(responseData)) {
			return undefined;
		}
		if (is.numeric(responseData)) {
			return Number(responseData) as T;
		}
		return responseData as T;
	}

	if (
		response.status === 400 &&
		responseData &&
		is.object(responseData) &&
		'errors' in responseData &&
		is.array(responseData.errors)
	) {
		throw new ValidationError(responseData as JulaValidationError);
	}

	// For 401 Unauthorized responses, try to refresh the user's access token
	// and run the request again.
	if (response.status === 401) {
		if (maxAttempts > 0 && attempt < maxAttempts) {
			sendGlobalEvent('refresh-token');
			return fetcher(input, options, attempt + 1);
		}
		sendGlobalEvent('logout');
		log.warning(
			// Unlikely to be anything other than a string.
			// eslint-disable-next-line @typescript-eslint/no-base-to-string
			`401 Unauthorized at ${input.toString()}: refreshing token and retrying request failed after ${maxAttempts} attempts, logging out user`,
		);
	}

	throw new ResponseError(
		response.headers,
		response.status,
		response.statusText,
		responseData,
		'Unknown error',
	);
}

/** Do a request and get the parsed response. */
export async function fetchData<T = unknown>(
	input: string | URL | Request,
	options?: RequestOptions,
): Promise<T | undefined> {
	return fetcher(input, options);
}

/** Do a request and get a result type with the data or error. */
export async function fetchResult<T = unknown>(
	input: string | URL | Request,
	options?: RequestOptions,
): Promise<Result<T | undefined, ResponseError | ValidationError | Error>> {
	try {
		const response = await fetcher<T>(input, options);
		return success(response);
	} catch (error) {
		if (error instanceof Error) {
			return failure(error);
		}
	}
	return failure(new Error('Unknown error'));
}
