import React from 'react';

import Link from 'components/Link';
import { hasReducedPrice } from 'components/Price';
import Text from 'components/Text';
import type {
	DiscountCode,
	GiftCard,
	Summary,
	TotalSummary,
	Variant,
} from 'models/api';
import { getProductImageSrc } from 'utils/business-logic';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import PriceSummaries from './PriceSummaries';
import SummaryProduct from './SummaryProduct';

interface Props {
	bonusDiscounts: DiscountCode[] | undefined;
	className?: string;
	giftCards: GiftCard[] | undefined;
	hasCartEditLink?: boolean;
	heading?: string;
	savingTotalSumSummary: Summary | undefined;
	totalSummary: TotalSummary | undefined;
	variants: Variant[] | undefined;
}

export default function PurchaseSummary({
	bonusDiscounts,
	className,
	giftCards,
	hasCartEditLink,
	heading,
	savingTotalSumSummary,
	totalSummary,
	variants,
}: Props) {
	const { t } = useI18n();

	return (
		<div className={className}>
			<div className="mb-2 flex items-center justify-between">
				<Text as="h2">{heading}</Text>
				{hasCartEditLink && (
					<Text as="pSmall">
						<Link href="/cart" underline>
							{t('checkout_change_cart_button')}
						</Link>
					</Text>
				)}
			</div>
			{variants?.map((variant) => (
				<SummaryProduct
					key={variant.id}
					link={variant.url}
					heading={variant.title}
					placements={
						variant.customization
							? variant.customization.placements
									?.map((placement) => placement.title)
									.filter(is.defined)
							: undefined
					}
					imageSrc={getProductImageSrc(variant.listImageFormats, true)}
					articleNumber={variant.id}
					rowSummary={variant.rowSummary}
					amount={variant.qty}
					discount={hasReducedPrice(variant.rowSummary.priceType)}
				/>
			))}
			<PriceSummaries
				className="mt-4"
				totalSummary={totalSummary}
				bonusDiscounts={bonusDiscounts}
				savingTotalSumSummary={savingTotalSumSummary}
				giftCards={giftCards}
			/>
		</div>
	);
}
PurchaseSummary.displayName = 'PurchaseSummary';
