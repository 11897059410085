import { useCallback } from 'react';
import { Fetcher } from 'swr';
import useSWRInfinite from 'swr/infinite';

import type { BasePaginatedResponse } from 'models/api';
import { fetchData } from 'utils/fetch';
import { empty, is } from 'utils/helpers';
import { createUrl } from 'utils/url';

interface PagedDataOptions<K, R> {
	baseUrl: string;
	fetcher?: Fetcher<R | undefined>;
	isActive?: boolean;
	itemsKey: K;
	pageOffsetQueryKey?: string;
	pageSize?: number;
	pageSizeQueryKey?: string;
	queryParams?: Record<string, string>;
}

export function usePagedData<
	R extends BasePaginatedResponse,
	K extends Exclude<keyof R, keyof BasePaginatedResponse> = Exclude<
		keyof R,
		keyof BasePaginatedResponse
	>,
>({
	baseUrl,
	fetcher,
	isActive = true,
	itemsKey,
	pageOffsetQueryKey = 'pageOffset',
	pageSize,
	pageSizeQueryKey = 'pageSize',
	queryParams,
}: PagedDataOptions<K, R>) {
	const getKey = (pageIndex: number, previousPageData: R | undefined) => {
		if (!isActive || (previousPageData && !previousPageData.hasNextPage)) {
			return null;
		}

		if (pageIndex === 0) {
			return createUrl(`${baseUrl}`, {
				...queryParams,
				[pageSizeQueryKey]: pageSize,
			});
		}

		// add the cursor to the API endpoint
		return createUrl(`${baseUrl}`, {
			...queryParams,
			[pageSizeQueryKey]: pageSize,
			[pageOffsetQueryKey]: previousPageData?.nextPageOffset,
		});
	};
	const { data, error, isLoading, setSize, size } = useSWRInfinite(
		getKey,
		fetcher ?? fetchData<R>,
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
		},
	);
	const loadMore = useCallback(() => setSize(size + 1), [setSize, size]);
	const lastResponse = data?.at(-1);
	const {
		hasNextPage,
		[itemsKey]: excluded,
		nextPageOffset,
		...rest
	} = lastResponse ?? ({} as Partial<R>);

	return {
		items:
			data
				?.filter(is.truthy)
				.map((response) => response[itemsKey])
				.filter(is.truthy)
				.flat() ?? empty.array,
		isLoading,
		// swr-infinite doesn't have a built-in way to say if we're loading when the first load is done
		isLoadingMore:
			isLoading || (size > 0 && data && data[size - 1] === undefined),
		error,
		loadMore,
		hasNextPage: Boolean(hasNextPage),
		...rest,
	};
}
