import React from 'react';

import type { ActionButtonState } from 'components/ActionButton';
import type { FormFields, Section } from 'models/sitecore';
import type {
	CustomerInfo,
	Organization,
	Worksite,
} from 'state-machines/createJulaProCustomer';

import StepOneContent from './StepOneContent';
import StepThreeContent from './StepThreeContent';
import StepTwoContent from './StepTwoContent';

interface JulaProStepperProps {
	creditFunctionsEnabled: boolean;
	customerInfo: CustomerInfo | undefined;
	isInSearchOrganizationSuccess: boolean;
	isInWaitingForNewOrganizationSearch: boolean;
	isSearchingOrganizations: boolean;
	isStepOne: boolean;
	isStepThree: boolean;
	isStepTwo: boolean;
	organizationList?: Organization[] | null;
	resetFromStepThree: () => void;
	resetToSearchOrganization: () => void;
	resetToSelectOrganization: () => void;
	searchingOrganizationFailed: boolean;
	sections: Section[];
	selectCredit: (credit: number) => void;
	selectedCredit: number | undefined;
	selectedWorksite: Worksite | undefined;
	selectOrganization: (id: string) => void;
	selectOrganizationButtonState: ActionButtonState;
	sendNewSearchInProgress: () => void;
	showCustomerIdForm: boolean;
	submitBecomeProMemberForm: (
		form: FormFields,
		values: Record<string, unknown>,
	) => void;
	submitCustomerId: ({ customerId }: { customerId: string }) => void;
	submitOrganizationSearch: (searchSstring: string) => void;
}

export default function JulaProStepper({
	creditFunctionsEnabled,
	customerInfo,
	isInSearchOrganizationSuccess,
	isInWaitingForNewOrganizationSearch,
	isSearchingOrganizations,
	isStepOne,
	isStepThree,
	isStepTwo,
	organizationList,
	resetFromStepThree,
	resetToSearchOrganization,
	resetToSelectOrganization,
	searchingOrganizationFailed,
	sections,
	selectCredit,
	selectedCredit,
	selectedWorksite,
	selectOrganization,
	selectOrganizationButtonState,
	sendNewSearchInProgress,
	showCustomerIdForm,
	submitBecomeProMemberForm,
	submitCustomerId,
	submitOrganizationSearch,
}: JulaProStepperProps) {
	return (
		<>
			{isStepOne && (
				<StepOneContent
					resetToSearchOrganization={resetToSearchOrganization}
					isSearchingOrganizations={isSearchingOrganizations}
					creditFunctionsEnabled={creditFunctionsEnabled}
					isInWaitingForNewOrganizationSearch={
						isInWaitingForNewOrganizationSearch
					}
					submitOrganizationSearch={submitOrganizationSearch}
					sendNewSearchInProgress={sendNewSearchInProgress}
					resetToSelectOrganization={resetToSelectOrganization}
					selectOrganization={selectOrganization}
					selectedWorksite={selectedWorksite}
					isInSearchOrganizationSuccess={isInSearchOrganizationSuccess}
					organizationList={organizationList}
					showCustomerIdForm={showCustomerIdForm}
					submitCustomerId={submitCustomerId}
					searchingOrganizationFailed={searchingOrganizationFailed}
					selectOrganizationButtonState={selectOrganizationButtonState}
					companySearchSection={sections[0]}
					customerIdInputSection={sections[1]}
				/>
			)}
			{isStepTwo && (
				<StepTwoContent
					resetToSelectOrganization={resetToSelectOrganization}
					selectCredit={selectCredit}
					selectedCredit={selectedCredit}
					selectedWorksite={selectedWorksite}
					section={sections[2]}
				/>
			)}
			{isStepThree && (
				<StepThreeContent
					creditFunctionsEnabled={creditFunctionsEnabled}
					selectedWorksite={selectedWorksite}
					selectedCredit={selectedCredit}
					submitBecomeProMemberForm={submitBecomeProMemberForm}
					resetFromStepThree={resetFromStepThree}
					customerInfo={customerInfo}
					section={sections[3]}
				/>
			)}
		</>
	);
}
JulaProStepper.displayName = 'AccountJulaProSignupForm_JulaProStepper';
