import React from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import IconLink from 'components/IconLink';
import { LayoutContainer } from 'components/Layout';
import { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		document: Field<DigizuiteAsset[]>;
		name: Field<string>;
	};
};
export default function DocumentLink({ fields, params }: Props) {
	if (!fields) {
		return null;
	}
	const { document, name } = fields;
	const doc = getAsset('Source Copy', document);
	if (!doc) return null;
	return (
		<LayoutContainer
			outerClassName={getEditorMargin(params)}
			id={params?.anchor}
		>
			<IconLink href={doc.src} target="_blank" field={name} icon="file" />
		</LayoutContainer>
	);
}
DocumentLink.displayName = 'DocumentLink';
