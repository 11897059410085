import React, { useState } from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useInterpret } from '@xstate/react';
import { waitFor } from 'xstate/lib/waitFor';

import GenericForm, { getValuesForSubmit } from 'components/GenericForm';
import { ErrorMessageInfoBox } from 'components/InfoBox';
import Text from 'components/Text';
import { isError } from 'errors';
import type {
	JulaComponentProps,
	PartialPopoverProps,
} from 'lib/component-props';
import type { BusinessLogicError } from 'models/api';
import type { FormFields, Section } from 'models/sitecore';
import { userManagementMachine } from 'state-machines/userManagement';
import { getFieldErrors } from 'utils/formHelpers';
import { is } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields: {
		description: Field<string>;
		form: FormFields;
		heading: Field<string>;
		name: Field<string>;
		sections: Section[];
		submitText: Field<string>;
	};
	partialProps?: PartialPopoverProps;
};

export default function AccountJulaProAddUserForm({
	fields,
	partialProps,
}: Props) {
	const [businessLogicErrors, setBusinessLogicErrors] = useState<
		BusinessLogicError[] | undefined
	>(undefined);
	const { description, form, heading, submitText } = fields;
	const userManagementService = useInterpret(userManagementMachine, {
		devTools: true,
		id: 'julaProAddUserFormUserManagementService',
	});
	const submitAddUser = async (values: Record<string, unknown>) => {
		setBusinessLogicErrors(undefined);
		userManagementService.send({
			type: 'ADD_USER',
			...getValuesForSubmit(form, values),
		});

		const doneData = await waitFor(
			userManagementService,
			(state) => state.hasTag('addUserRequestCompleted'),
			{ timeout: 120_000 },
		);

		if (doneData.context?.errors) {
			if (
				isError(doneData.context.errors, 'ValidationError') &&
				is.arrayWithLength(doneData.context.errors.generalErrors)
			) {
				setBusinessLogicErrors(doneData.context.errors.generalErrors);
			}
			return getFieldErrors(doneData.context.errors);
		}

		partialProps?.onPopoverClose();
		setBusinessLogicErrors(undefined);
		return undefined;
	};

	return (
		<div>
			{is.arrayWithLength(businessLogicErrors) && (
				<ErrorMessageInfoBox
					errors={businessLogicErrors}
					className="sticky top-0 z-99 mb-4 text-sm"
				/>
			)}
			<div className="mb-4">
				<Text className="mb-2 font-bold" as="p" field={heading} />
				<Text as="p" field={description} />
			</div>

			<GenericForm
				fields={form}
				submitButtonText={submitText.value}
				onSubmitCallback={submitAddUser}
				className="space-y-6"
			/>
		</div>
	);
}
AccountJulaProAddUserForm.displayName = 'AccountJulaProAddUserForm';
