import React from 'react';

import Button from 'components/Button';
import Img from 'components/Img';

interface Props {
	membershipCreationErrorButtonText: string;
	membershipCreationErrorHeading: string;
	membershipCreationErrorText: string;
	onTryAgain: () => void;
}

export default function CustomerNotCreatedView({
	membershipCreationErrorButtonText,
	membershipCreationErrorHeading,
	membershipCreationErrorText,
	onTryAgain,
}: Props) {
	return (
		<>
			<Img
				src="/assets/images/graphic-congrats-julaclub.png"
				width={80}
				height={80}
				className="mx-auto mt-8 block size-20"
			/>
			<p className="mt-8 font-bold">{membershipCreationErrorHeading}</p>
			<p className="mt-2">{membershipCreationErrorText}</p>

			<Button
				variant="primary"
				displayWidth="full"
				className="mt-8"
				onClick={onTryAgain}
			>
				{membershipCreationErrorButtonText}
			</Button>
		</>
	);
}
CustomerNotCreatedView.displayName =
	'JulaClubSignupForm_CustomerNotCreatedView';
