import type { OpeningHours } from 'models/openingHours';

interface Store {
	city: string;
	id: string;
	latitude: string | number;
	longitude: string | number;
	name: string;
	postalCode?: string;
	regularOpeningHours?: OpeningHours[];
	specialOpeningHours?: OpeningHours[];
	storeArea?: string;
	streetAddress?: string;
	todaysOpeningHours: OpeningHours;
	url?: string;
}

interface StoreWithStock extends Store {
	availableStockLevel: number;
	inStock: boolean;
}

export function mapStoreMarker({
	isSelected,
	onSelectClick,
	store,
}: {
	isSelected: boolean;
	onSelectClick: () => void;
	store: Store;
}) {
	return {
		id: store.id,
		name: store.name,
		openHours: store.todaysOpeningHours?.description || '',
		isOpen: store?.todaysOpeningHours?.state?.toUpperCase() === 'OPEN',
		address: `${store.streetAddress}, ${store.postalCode}, ${store.city}`,
		storeArea: store.storeArea || '',
		url: store.url,
		latitude: Number(store.latitude),
		longitude: Number(store.longitude),
		isSelected,
		onSelectClick,
	};
}

export function mapStockStoreMarker({
	isSelected,
	onSelectClick,
	store,
}: {
	isSelected: boolean;
	onSelectClick: () => void;
	store: StoreWithStock;
}) {
	return {
		id: store.id || '',
		name: store.name || '',
		availableStockLevel: store.availableStockLevel,
		inStock: store.inStock,
		address: `${store.streetAddress}, ${store.postalCode}, ${store.city}`,
		storeArea: store.storeArea || '',
		url: store.url,
		latitude: Number(store.latitude),
		longitude: Number(store.longitude),
		isSelected,
		onSelectClick,
	};
}
