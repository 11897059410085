import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ActionLink, { isValidPhoneNumberText } from 'components/ActionLink';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import type { JulaComponentProps } from 'lib/component-props';
import type { ActionLink as ActionLinkModel } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';

interface Item {
	action?: ActionLinkModel;
	isPrimary?: Field<boolean>;
}

type Props = JulaComponentProps & {
	fields?: {
		items?: Item[];
	};
};

export default function ActionButtonDouble({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const actions = filterTruthy(
		fields.items?.map((item) => ({
			...item.action,
			isPrimary: Boolean(item.isPrimary?.value),
		})),
		'type',
		'text',
	);

	if (actions.length < 2) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="ActionButtonDouble"
				description="Requires two action buttons, both with a type and text"
			/>
		);
	}

	const phoneAction = actions.find((item) => item.type === 'Phone');
	if (phoneAction && !isValidPhoneNumberText(phoneAction.text)) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="ActionButtonDouble with Phone"
				description="Phone action text must contain a phone number of at least 5 digits"
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			className="flex gap-4 max-sm:flex-col md:gap-6"
		>
			{actions.map((action) => (
				<ActionLink
					key={action.text}
					{...action}
					buttonVariant={action.isPrimary ? 'primary' : undefined}
					className="min-w-64 max-sm:w-full"
				/>
			))}
		</LayoutContainer>
	);
}
ActionButtonDouble.displayName = 'ActionButtonDouble';
