import React from 'react';

import RichText from 'components/RichText';
import Text from 'components/Text';
import type { Product } from 'models/product';
import { is } from 'utils/helpers';

import { filterAdditionalTexts } from './helpers/productDataHelpers';

interface Props {
	className?: string;
	hasChemicalStatements: boolean;
	product: Product;
}

export default function ProductDetailsImportantInfo({
	className,
	hasChemicalStatements,
	product,
}: Props) {
	const { chemicalInformation } = product;
	const additionalTexts = filterAdditionalTexts(product.additionalTexts);

	return (
		<div className={className}>
			{is.arrayWithLength(additionalTexts) && (
				<div className="richtext">
					{additionalTexts.map((text) => (
						<React.Fragment key={text.id}>
							{text.title && (
								<Text as="h3" styleAs="h4" className="pb-2">
									{text.title}
								</Text>
							)}
							{text.textFormatted && (
								<RichText html={text.textFormatted} className="text-base" />
							)}
						</React.Fragment>
					))}
				</div>
			)}
			{hasChemicalStatements && chemicalInformation && (
				<>
					{chemicalInformation.hazardStatements && (
						<Text as="h3" styleAs="h6" className="mb-4">
							{chemicalInformation.hazardStatements}
						</Text>
					)}
					{chemicalInformation.precationaryStatements && (
						<Text as="p" className="mt-3">
							{chemicalInformation.precationaryStatements}
						</Text>
					)}
					{chemicalInformation.supplementalHazardStatements && (
						<Text as="p" className="mt-3">
							{chemicalInformation.supplementalHazardStatements}
						</Text>
					)}
				</>
			)}
		</div>
	);
}
ProductDetailsImportantInfo.displayName = 'ProductDetailsImportantInfo';
