export type MaxWidth = 'standard' | 'narrow' | 'extraNarrow' | 'none';

export function getContainerMaxWidthClass(maxWidth: MaxWidth): string {
	switch (maxWidth) {
		case 'narrow':
			return 'max-w-pageNarrow';
		case 'extraNarrow':
			return 'max-w-pageExtraNarrow';
		case 'none':
			return '';
		default:
			return 'max-w-pageStandard';
	}
}
