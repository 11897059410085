import React from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useInterpret, useSelector } from '@xstate/react';

import AuthJulaConnect from 'components/AuthJulaConnect';
import JulaClubSignupForm from 'components/JulaClubSignupForm';
import { LoginOptions } from 'components/Login';
import { useGlobalMachinesContext } from 'contexts';
import type {
	JulaComponentProps,
	PartialPopoverProps,
} from 'lib/component-props';
import { LoginMethod } from 'models/connect/oauth';
import type { Section } from 'models/sitecore';
import {
	selectIsAwaitingAuthAction,
	selectUnregisteredToken,
} from 'state-machines/authentication';
import {
	createJulaClubCustomerMachine,
	selectCurrentSection,
} from 'state-machines/createJulaClubCustomer';
import { getLoginOptions } from 'utils/business-logic';
import { is } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		'require e-identification': Field<boolean>;
		'sections': Section[];
	};
	partialProps?: PartialPopoverProps;
};

function getSection(sections: Section[], currentSection?: string) {
	if (!is.arrayWithLength(sections)) {
		return undefined;
	}
	if (!currentSection) {
		return sections[0];
	}
	return sections.find((s) => s.identifier.value === currentSection);
}

export default function AccountJulaClubSignupForm({
	fields,
	partialProps,
}: Props) {
	const { authService } = useGlobalMachinesContext();

	const createCustomerService = useInterpret(createJulaClubCustomerMachine, {
		devTools: true,
	});
	const isAwaitingAuthAction = useSelector(
		authService,
		selectIsAwaitingAuthAction,
	);
	const unregisteredToken = useSelector(authService, selectUnregisteredToken);

	const currentSection = useSelector(
		createCustomerService,
		selectCurrentSection,
	);

	if (!fields) {
		return null;
	}

	const { 'require e-identification': requireEId, sections } = fields;
	const section = getSection(sections, currentSection);

	if (!section) {
		return null;
	}

	const onSelectLoginMethod = (loginMethod: LoginMethod) => {
		authService.send({
			type: 'SELECT_AUTH_JULA_CLUB_BECOME_MEMBER',
			loginMethod,
		});
	};

	const { form } = section;
	const heading = section.heading?.value;
	const description = section.description.value;
	const submitText = section.submitText.value;
	const formSection = section.identifier.value;
	const identificationSection = requireEId.value
		? getSection(sections, 'Identification')
		: undefined;
	if (identificationSection && !unregisteredToken) {
		const {
			description: identificationDescription,
			heading: identificationHeading,
			submitText: identificationSubmitText,
		} = identificationSection;
		return (
			<div className="flex h-full flex-col">
				{isAwaitingAuthAction && (
					<LoginOptions
						introHeading={identificationHeading?.value}
						introText={identificationDescription.value}
						memberType="club"
						loginOptions={getLoginOptions('Club Staff', [
							{
								method: 'bank_id_se',
								text: identificationSubmitText.value,
								icon: 'bankIdSe',
							},
							{
								method: 'bank_id_fi',
								text: identificationSubmitText.value,
							},
							{
								method: 'bank_id_no',
								text: identificationSubmitText.value,
								icon: 'bankIdNo',
							},
						])}
						onSelectLoginMethod={onSelectLoginMethod}
					/>
				)}
				{!isAwaitingAuthAction && <AuthJulaConnect className="grow" />}
			</div>
		);
	}

	return (
		<JulaClubSignupForm
			onClose={() => {
				partialProps?.onPopoverClose();
				authService.send({ type: 'RESET_UNREGISTERED_TOKEN' });
			}}
			key={formSection}
			formSection={formSection}
			unregisteredToken={unregisteredToken}
			heading={heading}
			description={description}
			submitText={submitText}
			form={form}
			createJulaClubCustomerActor={createCustomerService}
		/>
	);
}
AccountJulaClubSignupForm.displayName = 'AccountJulaClubSignupForm';
